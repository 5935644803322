import { Colors, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import styled from "@emotion/styled";
import type { SmcEntitlementRequirement } from "../../entitlements/types";
import { BoxCol } from "../containers/Box";
import { SmcEntitlementCard } from "./entitlementCard";

const DescriptionText = styled.span`
  font-size: 14px;
  color: ${Colors.GRAY1};

  .bp3-dark & {
    color: ${Colors.GRAY4};
  }
`;

const DescriptionContainer = styled(BoxCol)`
  margin: 4px 0 12px 0;
`;

const CardsContainer = styled(BoxCol)`
  width: 100%;
  max-width: 400px;
`;

export interface NotEntitledWithItemsProps {
  missingEntitlements: SmcEntitlementRequirement[];
  contactEmail?: string;
  className?: string;
}

export function NotEntitledWithItems({ missingEntitlements, contactEmail, className }: NotEntitledWithItemsProps) {
  return (
    <NonIdealState icon={IconNames.BLOCKED_PERSON} title="Access Denied" className={className}>
      <BoxCol alignItems="center">
        <DescriptionContainer alignItems="center" justifyContent="center" gap={4}>
          <DescriptionText>
            To access this application or resource, you must have the proper entitlements.
          </DescriptionText>
          {contactEmail && (
            <DescriptionText>
              For assistance, please contact <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
            </DescriptionText>
          )}
        </DescriptionContainer>
        <CardsContainer gap={12}>
          {missingEntitlements.map((entitlement) => (
            <SmcEntitlementCard key={entitlement.entitlement} entitlement={entitlement} />
          ))}
        </CardsContainer>
      </BoxCol>
    </NonIdealState>
  );
}
