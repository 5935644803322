import type { RequestHandler } from "@apollo/client";
import { ApolloLink, Observable } from "@apollo/client";
// TODO[CDX-1399]: GA Security Retirement - https://wiki/x/v0QoIg
// eslint-disable-next-line no-restricted-imports
// TODO[CDX-1399]: GA Security Retirement - https://wiki/x/v0QoIg
// eslint-disable-next-line no-restricted-imports
import {
  CDX_OAUTH2_TMP_GLOBAL_USAGE_KEY,
  getSessionDataAsync,
  verifyAndResetIfNeeded,
  isAuthenticated,
} from "@citadel/cdx-provider-oauth2";
import { addAuthHeaders } from "../utils";
import { logger } from "../logger";
import { getOperationAuthenticationSession } from "./authenticationSessionLink";

// TODO: remove this when all apps are onboard with OAuth2
/**
 * @deprecated
 */
export const isUsingOAuth2 = () => (globalThis as any)[CDX_OAUTH2_TMP_GLOBAL_USAGE_KEY];

async function pingFederateCheckRequest(): Promise<void | Error> {
  // check backward compatibility
  const hasSessionCookies = isAuthenticated();
  if (!hasSessionCookies) {
    logger.warn("invalid session; ga is not applied, please refresh the page");
  }

  const sessionData = await getSessionDataAsync(!hasSessionCookies);
  if (sessionData) {
    return Promise.resolve();
  }

  await verifyAndResetIfNeeded();
  return Promise.reject(Error("invalid session; should redirect to ping federate"));
}

async function authCheckRequest(): Promise<void | Error> {
  return pingFederateCheckRequest();
}

const createAuthHandler: (ra: boolean) => RequestHandler = () => (operation, forward) => {
  // Skip the auth handler if we're using an authentication session instead. We currrently use this auth link by default
  // in all our apps. We need to automatically switch it off for apps which are using the newer auth provider.
  if (getOperationAuthenticationSession(operation)) {
    return forward(operation);
  }

  return new Observable((observer) => {
    let handle: any;

    Promise.resolve(operation)
      .then(() => authCheckRequest())
      .then(() => {
        addAuthHeaders(operation);
        handle = forward?.(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) {
        handle.unsubscribe();
      }
    };
  });
};

/**
 * Creates a new auth link on setup
 *
 * @deprecated Functoinality superceded
 *
 * This has been superceded by an embedded authentication mechanism. There is no
 * longer a need to
 *
 * @param requiresAuthenticate - Uses the more modern authenticate token when set to true
 */
export function createAuthLink(requiresAuthenticate: boolean) {
  const link = new ApolloLink(createAuthHandler(requiresAuthenticate));

  return link;
}
