// TODO[CDX-1399]: https://jira.citadelgroup.com/browse/CDX-1468
import decode from "jwt-decode";
import type { SessionData, CdxGaSessionData, CdxSessionData, AuthenticateJwtDataRaw } from "@citadel/cdx-lib-auth";
import { getCdxSessionCookies, getSessionCookies } from "./getSessionCookies";

/**
 * @deprecated Use "getAccessToken()" from "\@citadel/cdx-auth-browser".
 * {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @public
 */
export const getSessionData = (): SessionData => {
  const { authenticateJwt } = getSessionCookies();
  return {
    authenticateData: authenticateJwt ? decode<AuthenticateJwtDataRaw>(authenticateJwt) : null,
  };
};

/**
 * @deprecated Use "getAccessToken()" from "\@citadel/cdx-auth-browser".
 * {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @public
 */
export const getCdxSessionData = (): CdxSessionData => {
  const { authenticateJwt, gaSessionJwt } = getCdxSessionCookies();
  const authenticateData = authenticateJwt ? decode<AuthenticateJwtDataRaw>(authenticateJwt) : null;
  const gaSessionData = gaSessionJwt ? decode<CdxGaSessionData>(gaSessionJwt) : null;
  return {
    authenticateData,
    authenticateJwt,
    gaToken: gaSessionData?.gaToken ?? "",
    gaTokenEncrypted: gaSessionData?.gaTokenEncrypted ?? "",
    username: gaSessionData?.username ?? authenticateData?.sAMAccountName ?? "",
    firstname: gaSessionData?.firstname ?? "",
    lastname: gaSessionData?.lastname ?? "",
  };
};
