import type { RawLogOptions, Logger, LogLine, LogAdaptor, ChildFromLoggerOptions } from "./Logger";
import { LogLevel } from "./Logger";
import { ChildLogger } from "./childLogger";
import { concatContexts } from "./concatContext";
import type { DebugOverrideSerialized, DebugOverride } from "./DebugOverride";
import { parseDebugOverride, contextMatchesDebugOverride } from "./DebugOverride";

// TODO(@rdavis): Vitest has an issue with its traspiling (or TBD) that causes an issue with the inline enum reference here
const defaultLogLevel = LogLevel.INFO;

/**
 * @public
 */
export interface LoggerOptions {
  context?: string;
  adaptors: LogAdaptor[];
  hostname?: string;
  pid?: number;
  /**
   * Minimum level to send logs lines at - lines at lower levels
   * will NOT be logged
   *
   * @defaultValue {@link LogLevel.INFO}
   */
  level?: LogLevel;

  /**
   * Optional argument to allow contexts to always be logged, regardless of
   * the level option above.
   *
   * Intended to be used only in development.
   *
   * Pass true to allow all log lines to be logged.
   *
   * Alternatively, filter on specific contexts by passing in a string of
   * patterns to match them. he patterns use the same syntax as the [debug] npm module:
   *
   * - Use commas to match multiple patterns: "foo,bar" will match all logs with the context
   *   "foo" or "bar"
   * - Use * as a wildcard: "foo*" will match all logs with context starting with "foo"
   * - Use - to ignore contexts: "foo*,-foobar" will math all logs with context starting
   *   with "foo" except "foobar"
   *
   * [debug]: https://www.npmjs.com/package/debug
   */
  debugOverride?: DebugOverrideSerialized;
}

/**
 * Create a new {@link Logger}
 *
 * @public
 * @param options - Options to use when creating the logger
 * @returns A logger instance
 */
export const createLogger = (options: LoggerOptions): Logger => new ConcreteLogger(options);

/**
 * Concrete logger that uses given adaptors to transport logs
 */
class ConcreteLogger implements Logger {
  private _context: string;
  private _adaptors: LogAdaptor[];
  private _hostname?: string;
  private _pid?: number;
  private _level: LogLevel;
  private _debugOverride?: DebugOverride;

  constructor({ adaptors, context = "", hostname, pid, level = defaultLogLevel, debugOverride }: LoggerOptions) {
    this._adaptors = adaptors;
    this._context = context;
    this._hostname = hostname;
    this._pid = pid;
    this._level = level;

    if (debugOverride) {
      this._debugOverride = parseDebugOverride(debugOverride);
    }
  }

  raw({ level, data, time, context }: RawLogOptions) {
    const loggedContext = concatContexts([this._context, context]);
    const shouldLog =
      level >= this._level ||
      !!(this._debugOverride && contextMatchesDebugOverride(loggedContext, this._debugOverride));

    if (!shouldLog) {
      return;
    }

    const line: LogLine = {
      level,
      time: time ?? Date.now(),
      pid: this._pid,
      hostname: this._hostname,
      msg: {
        context: loggedContext,
        data: data ? (Array.isArray(data) ? data : [data]) : [],
      },
    };

    for (const adaptor of this._adaptors) {
      adaptor(line);
    }
  }

  trace(...data: any[]) {
    this.raw({ level: LogLevel.TRACE, data });
  }

  debug(...data: any[]) {
    this.raw({ level: LogLevel.DEBUG, data });
  }

  log(...data: any[]) {
    this.info(...data);
  }

  info(...data: any[]) {
    this.raw({ level: LogLevel.INFO, data });
  }

  warn(...data: any[]) {
    this.raw({ level: LogLevel.WARN, data });
  }

  error(...data: any[]) {
    this.raw({ level: LogLevel.ERROR, data });
  }

  fatal(...data: any[]) {
    this.raw({ level: LogLevel.FATAL, data });
  }

  child({ context }: ChildFromLoggerOptions = {}): Logger {
    return new ChildLogger({ parentLogger: this, context });
  }
}
