import { PingFederateSession } from "../ping-federate/PingFederateSession";
import type { AuthenticationSession } from "./AuthenticationSession";

/**
 * Use this to get a reference to the active authentication session. Ideally prefer to use the other functions and hooks
 * listed below. Only use this if your desired functionality isn't provided in one of those.
 *
 * @see Use {@link getAccessToken} anywhere to get an access token (JWT)
 * @see Use {@link useAccessToken} in react to get the access token and subscribe to updates
 *
 * @public
 */
export function getAuthenticationSession(): AuthenticationSession {
  return PingFederateSession.activeSession;
}
