import { useEffect } from "react";
import { Intent } from "@blueprintjs/core";
import type { Tooltip2Props } from "@blueprintjs/popover2";
import { Tooltip2 } from "@blueprintjs/popover2";
import { faCheckCircle, faExclamationCircle, faSync } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addAction } from "@citadel/cdx-provider-user-monitoring";

export enum SaveState {
  SAVED,
  SAVING,
  ERROR,
}

const SaveStateMetadata = {
  [SaveState.SAVED]: {
    content: "Saved",
    intent: Intent.SUCCESS,
    icon: faCheckCircle,
  },
  [SaveState.SAVING]: {
    content: "Syncing...",
    intent: Intent.WARNING,
    icon: faSync,
  },
  [SaveState.ERROR]: {
    content: "Not Saved",
    intent: Intent.DANGER,
    icon: faExclamationCircle,
  },
};

export type SaveIndicatorProps = {
  saveState: SaveState;
  placement?: Tooltip2Props["placement"];
};

export const SaveIndicator = ({ saveState = SaveState.ERROR, placement }: SaveIndicatorProps) => {
  const { content, intent, icon } = SaveStateMetadata[saveState];

  useEffect(() => {
    // update datadog with the reset action taken
    addAction("save state update", { state: content });
  }, [content]);

  return (
    <Tooltip2 content={content} intent={intent} placement={placement}>
      <FontAwesomeIcon icon={icon} size="lg" />
    </Tooltip2>
  );
};
