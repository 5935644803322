import type { CdxServiceDependencyGraphQLResolution, CdxServiceDependencyGraphQLDeployment } from "../graphqlServices";
import dataframeworkIntrospection from "../../_generated/introspection-dataframework.json";
import preferencesIntrospection from "../../_generated/introspection-preferences.json";
import userIntrospection from "../../_generated/introspection-user.json";
import { WELL_KNOWN_GRAPHQL_SERVICE_HEALTH_LOCATION } from "../../constants";

export interface CdxPlatformServices {
  dataframework: CdxServiceDependencyGraphQLResolution;
  preferences: CdxServiceDependencyGraphQLResolution;
  user: CdxServiceDependencyGraphQLResolution;
}

interface CreateCdxPlatformServiceConfigOptions {
  name: string;
  prefix?: string;
  path: string;
  introspection: any;
  hasSubscriptions?: boolean;
  hasSsa?: boolean;
}

const createCdxPlatformServiceDeployment = (
  hostname: string,
  path: string,
  hasSubscriptions?: boolean
): CdxServiceDependencyGraphQLDeployment => {
  const host = `https://${hostname}.citadelgroup.com${path}`;
  return {
    healthcheckUrlOrPath: `${host}${WELL_KNOWN_GRAPHQL_SERVICE_HEALTH_LOCATION}`,
    httpUrlOrPath: host,
    wsUrlOrPath: hasSubscriptions ? host.replace("https", "wss") : undefined,
  };
};

const createCdxPlatformServiceConfig = ({
  name,
  prefix,
  path,
  introspection,
  hasSubscriptions,
  hasSsa,
}: CreateCdxPlatformServiceConfigOptions): CdxServiceDependencyGraphQLResolution => ({
  name,
  prefix,
  introspection,
  targets: {
    nrc: createCdxPlatformServiceDeployment("cdx", path, hasSubscriptions),
    prod: createCdxPlatformServiceDeployment("cdx", path, hasSubscriptions),
    "staging-nrc": createCdxPlatformServiceDeployment("cdx", path, hasSubscriptions),
    staging: createCdxPlatformServiceDeployment("cdx", path, hasSubscriptions),
    ssa: hasSsa ? createCdxPlatformServiceDeployment("cdx-ssa", path, hasSubscriptions) : null,
    "stabledev-nrc": createCdxPlatformServiceDeployment("cdx-stabledev", path, hasSubscriptions),
    stabledev: createCdxPlatformServiceDeployment("cdx-stabledev", path, hasSubscriptions),
  },
  requireXGaSession: true,
});

export const cdxPlatformServices: CdxPlatformServices = {
  dataframework: createCdxPlatformServiceConfig({
    name: "dataframework",
    prefix: "",
    path: "/data-framework",
    introspection: dataframeworkIntrospection,
  }),
  preferences: createCdxPlatformServiceConfig({
    name: "preferences",
    prefix: "core",
    path: "/preferences",
    introspection: preferencesIntrospection,
  }),
  user: createCdxPlatformServiceConfig({
    name: "user",
    prefix: "core",
    path: "/user",
    introspection: userIntrospection,
    hasSsa: true,
  }),
};
