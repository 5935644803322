import { getShallow } from "./getShallow";

type Tail<K extends readonly any[]> = K extends [any, ...infer R] ? R : [];

export type PathSegment = string | number;
export type Path = readonly PathSegment[] | PathSegment;

export type PathValue<T, K extends readonly PathSegment[]> = K extends []
  ? T
  : K[0] extends keyof T
  ? K extends [K[0]]
    ? T[K[0]]
    : PathValue<T[K[0]], Tail<K>>
  : undefined;

export function getDeep<T>(value: T): T;
export function getDeep<T, K extends PathSegment>(value: T, path: K): PathValue<T, [K]>;
export function getDeep<T, K extends readonly PathSegment[]>(value: T, path: K): PathValue<T, K>;
export function getDeep(value: any, path: Path = []): unknown {
  if (!Array.isArray(path)) {
    return getDeep(value, [path as PathSegment]);
  }

  let deepValue = value;

  for (const key of path) {
    deepValue = getShallow(deepValue, key);

    if (deepValue === undefined) break;
  }

  return deepValue;
}
