export const WORKSPACE_CHANNEL_STORAGE = "workspace";
export const WIDGET_CHANNEL_STORAGE = "widget";

export const NONE_CHANNEL = "none";
export const BLUE_CHANNEL = "blue";
export const LIME_CHANNEL = "lime";
export const ORANGE_CHANNEL = "orange";
export const PURPLE_CHANNEL = "purple";
export const RED_CHANNEL = "red";
export const YELLOW_CHANNEL = "yellow";

export type NoneChannel = typeof NONE_CHANNEL;

export type StandardWorkspaceChannels =
  | typeof BLUE_CHANNEL
  | typeof LIME_CHANNEL
  | typeof ORANGE_CHANNEL
  | typeof PURPLE_CHANNEL
  | typeof RED_CHANNEL
  | typeof YELLOW_CHANNEL;
