export const replacePlaceholder = (text: string, placeholder: string, item: JSX.Element) => {
  const urlIdx = text.indexOf(`{{${placeholder}}}`);
  // replace the {{placeholder}} in the text with the item
  return (
    <>
      {text.slice(0, urlIdx)}
      {item}
      {text.slice(urlIdx + placeholder.length + 4)}
    </>
  );
};
