import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from "graphql";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & {
  [P in K]-?: NonNullable<T[P]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  _FieldSet: any;
};

export type AdminToken = {
  __typename?: "AdminToken";
  expiresAt: Scalars["DateTime"];
  token: Scalars["String"];
  username: Scalars["String"];
};

export type Assessment = {
  __typename?: "Assessment";
  candidate: Candidate;
  columns: Array<Scalars["String"]>;
  expiresAt?: Maybe<Scalars["DateTime"]>;
  groups: Array<Group>;
  id: Scalars["Int"];
  score?: Maybe<Scalars["Float"]>;
  status?: Maybe<AssessmentStatus>;
  template: AssessmentTemplate;
};

export type AssessmentEntry = {
  __typename?: "AssessmentEntry";
  column: Scalars["Int"];
  editable: Scalars["Boolean"];
  formula?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  solutions?: Maybe<Array<Solution>>;
  value?: Maybe<Scalars["Float"]>;
};

export type AssessmentEntryInput = {
  answerValues?: Maybe<Array<Scalars["Float"]>>;
  column: Scalars["Int"];
  defaultFormula?: Maybe<Scalars["String"]>;
  row: Scalars["Int"];
  value?: Maybe<Scalars["Float"]>;
};

export type AssessmentEventInput = {
  timestamp: Scalars["DateTime"];
  token: Scalars["String"];
};

/** Common type used to look up candidate assessments */
export type AssessmentInput = {
  /** ID of the candidate assessment you want to look up */
  id: Scalars["Int"];
};

export type AssessmentRowInput = {
  cells: Array<AssessmentEntryInput>;
  comment?: Maybe<Scalars["String"]>;
  formatter?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
  labelStyle: Scalars["String"];
  parentRowNumber?: Maybe<Scalars["Int"]>;
  precision?: Maybe<Scalars["Int"]>;
  rowNumber: Scalars["Int"];
};

export type AssessmentSearchInput = {
  /**
   * String to match against candidates names OR emails. The string 'Michael' will match
   * a candidate with name 'Michael' and a candidate with email 'michael@foo.com'
   */
  search: Scalars["String"];
};

export enum AssessmentStatus {
  Canceled = "CANCELED",
  Expired = "EXPIRED",
  Finished = "FINISHED",
  InProgress = "IN_PROGRESS",
  Sent = "SENT",
}

export type AssessmentTemplate = {
  __typename?: "AssessmentTemplate";
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  passThreshold: Scalars["Float"];
};

export type AssessmentTemplateInput = {
  columns: Array<Scalars["String"]>;
  name: Scalars["String"];
  rows: Array<AssessmentRowInput>;
};

export type Candidate = {
  __typename?: "Candidate";
  createdAt: Scalars["DateTime"];
  email: Scalars["String"];
  hiringTeam: Array<HiringTeamMember>;
  id: Scalars["Int"];
  name: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type CreateAssessmentInput = {
  assessmentId: Scalars["Int"];
  candidateEmail: Scalars["String"];
  candidateName: Scalars["String"];
  hiringTeam: Array<Scalars["String"]>;
};

export type CreateCandidateInput = {
  email: Scalars["String"];
  name: Scalars["String"];
};

export type EntryEventInput = {
  assessmentEntryId: Scalars["Int"];
  newFormula: Scalars["String"];
  newValue: Scalars["Float"];
  timestamp: Scalars["DateTime"];
  token: Scalars["String"];
  uniqueId: Scalars["String"];
};

export type Group = {
  __typename?: "Group";
  candidate: Candidate;
  comment?: Maybe<Scalars["String"]>;
  entries: Array<AssessmentEntry>;
  formatter: Scalars["String"];
  groups: Array<Group>;
  id: Scalars["Int"];
  label?: Maybe<Scalars["String"]>;
  labelStyle: Scalars["String"];
  precision: Scalars["Int"];
  rowNumber: Scalars["Int"];
};

export type GroupEntriesArgs = {
  candidateId?: Maybe<Scalars["Int"]>;
};

export type HiringTeamMember = {
  __typename?: "HiringTeamMember";
  username: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  /** Set a candidate's assessment as canceled. The candidate will no longer be able to take the assessment. Cannot cancel expired assessments. */
  cancelAssessment: Assessment;
  createAdminToken: AdminToken;
  createAssessment: Assessment;
  createCandidates: Array<Candidate>;
  /** Edit the candidate's name. Email cannot be updated. */
  editCandidate: Candidate;
  /** Re-triggers the invite email to the candidate. Returns true, if successful, false otherwise. NOT IMPLEMENTED */
  resendEmail?: Maybe<Scalars["Boolean"]>;
  /** Sets an assessment's expiration time to the given deadline. Returns the updated assessment. */
  setAssessmentExpiration: Assessment;
  startAssessment?: Maybe<Assessment>;
  submitAssessment?: Maybe<Assessment>;
  updateEntries?: Maybe<Scalars["Boolean"]>;
  uploadAssessmentTemplate: AssessmentTemplate;
};

export type MutationCancelAssessmentArgs = {
  assessment: AssessmentInput;
};

export type MutationCreateAdminTokenArgs = {
  assessment: AssessmentInput;
};

export type MutationCreateAssessmentArgs = {
  input: CreateAssessmentInput;
};

export type MutationCreateCandidatesArgs = {
  input: Array<CreateCandidateInput>;
};

export type MutationEditCandidateArgs = {
  candidateId: Scalars["Int"];
  newName: Scalars["String"];
};

export type MutationResendEmailArgs = {
  assessment: AssessmentInput;
};

export type MutationSetAssessmentExpirationArgs = {
  assessment: AssessmentInput;
  deadline?: Maybe<Scalars["DateTime"]>;
};

export type MutationStartAssessmentArgs = {
  input: AssessmentEventInput;
};

export type MutationSubmitAssessmentArgs = {
  input: AssessmentEventInput;
};

export type MutationUpdateEntriesArgs = {
  entries: Array<EntryEventInput>;
};

export type MutationUploadAssessmentTemplateArgs = {
  input: AssessmentTemplateInput;
};

export type Query = {
  __typename?: "Query";
  allAssessments: Array<Assessment>;
  assessment?: Maybe<Assessment>;
  assessmentByAdminToken?: Maybe<Assessment>;
  /** Do not use this to fetch an assessment that has been completed/expired. Use <not_yet_created> assessmentByAdminToken instead */
  assessmentByToken?: Maybe<Assessment>;
  getCandidate?: Maybe<Candidate>;
  /** Search candidate assessments by name or email. Only returns candidates where the current user is on the hiring team */
  searchAssessments: Array<Assessment>;
  templates: Array<AssessmentTemplate>;
};

export type QueryAssessmentArgs = {
  assessmentId: Scalars["Int"];
  candidateId: Scalars["Int"];
};

export type QueryAssessmentByAdminTokenArgs = {
  token: Scalars["String"];
};

export type QueryAssessmentByTokenArgs = {
  token: Scalars["String"];
};

export type QueryGetCandidateArgs = {
  id: Scalars["Int"];
};

export type QuerySearchAssessmentsArgs = {
  input: AssessmentSearchInput;
};

export type Solution = {
  __typename?: "Solution";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
  value: Scalars["Float"];
};

export type BaseGroupFieldsFragment = { __typename?: "Group" } & Pick<
  Group,
  "id" | "label" | "labelStyle" | "formatter" | "precision" | "comment"
>;

export type EntryFieldsFragment = { __typename?: "AssessmentEntry" } & Pick<
  AssessmentEntry,
  "id" | "column" | "formula" | "value" | "editable"
>;

export type CandidateGroupFieldsFragment = { __typename?: "Group" } & {
  entries: Array<{ __typename?: "AssessmentEntry" } & EntryFieldsFragment>;
} & BaseGroupFieldsFragment;

export type CandidateGroupsRecursiveFragment = { __typename?: "Group" } & {
  groups: Array<
    { __typename?: "Group" } & {
      groups: Array<
        { __typename?: "Group" } & {
          groups: Array<
            { __typename?: "Group" } & {
              groups: Array<
                { __typename?: "Group" } & {
                  groups: Array<{ __typename?: "Group" } & CandidateGroupFieldsFragment>;
                } & CandidateGroupFieldsFragment
              >;
            } & CandidateGroupFieldsFragment
          >;
        } & CandidateGroupFieldsFragment
      >;
    } & CandidateGroupFieldsFragment
  >;
} & CandidateGroupFieldsFragment;

export type AssessmentQueryVariables = Exact<{
  token: Scalars["String"];
}>;

export type AssessmentQuery = { __typename?: "Query" } & {
  assessmentByToken?: Maybe<
    { __typename?: "Assessment" } & Pick<Assessment, "columns" | "expiresAt"> & {
        groups: Array<{ __typename?: "Group" } & CandidateGroupsRecursiveFragment>;
      }
  >;
};

export type AdminGroupFieldsFragment = { __typename?: "Group" } & {
  entries: Array<
    { __typename?: "AssessmentEntry" } & {
      solutions?: Maybe<Array<{ __typename?: "Solution" } & Pick<Solution, "id" | "value">>>;
    } & EntryFieldsFragment
  >;
} & BaseGroupFieldsFragment;

export type AdminGroupsRecursiveFragment = { __typename?: "Group" } & {
  groups: Array<
    { __typename?: "Group" } & {
      groups: Array<
        { __typename?: "Group" } & {
          groups: Array<
            { __typename?: "Group" } & {
              groups: Array<
                { __typename?: "Group" } & {
                  groups: Array<{ __typename?: "Group" } & AdminGroupFieldsFragment>;
                } & AdminGroupFieldsFragment
              >;
            } & AdminGroupFieldsFragment
          >;
        } & AdminGroupFieldsFragment
      >;
    } & AdminGroupFieldsFragment
  >;
} & AdminGroupFieldsFragment;

export type AdminAssessmentQueryVariables = Exact<{
  token: Scalars["String"];
}>;

export type AdminAssessmentQuery = { __typename?: "Query" } & {
  assessmentByAdminToken?: Maybe<
    { __typename?: "Assessment" } & Pick<Assessment, "columns"> & {
        groups: Array<{ __typename?: "Group" } & AdminGroupsRecursiveFragment>;
      }
  >;
};

export type StartAssessmentMutationVariables = Exact<{
  input: AssessmentEventInput;
}>;

export type StartAssessmentMutation = { __typename?: "Mutation" } & {
  startAssessment?: Maybe<{ __typename?: "Assessment" } & Pick<Assessment, "id">>;
};

export type SubmitAssessmentMutationVariables = Exact<{
  input: AssessmentEventInput;
}>;

export type SubmitAssessmentMutation = { __typename?: "Mutation" } & {
  submitAssessment?: Maybe<{ __typename?: "Assessment" } & Pick<Assessment, "id">>;
};

export type UpdateAssessmentEntryMutationVariables = Exact<{
  entries: Array<EntryEventInput> | EntryEventInput;
}>;

export type UpdateAssessmentEntryMutation = { __typename?: "Mutation" } & Pick<Mutation, "updateEntries">;

export const BaseGroupFieldsFragmentDoc = gql`
  fragment BaseGroupFields on Group {
    id
    label
    labelStyle
    formatter
    precision
    comment
  }
`;
export const EntryFieldsFragmentDoc = gql`
  fragment EntryFields on AssessmentEntry {
    id
    column
    formula
    value
    editable
  }
`;
export const CandidateGroupFieldsFragmentDoc = gql`
  fragment CandidateGroupFields on Group {
    ...BaseGroupFields
    entries {
      ...EntryFields
    }
  }
  ${BaseGroupFieldsFragmentDoc}
  ${EntryFieldsFragmentDoc}
`;
export const CandidateGroupsRecursiveFragmentDoc = gql`
  fragment CandidateGroupsRecursive on Group {
    ...CandidateGroupFields
    groups {
      ...CandidateGroupFields
      groups {
        ...CandidateGroupFields
        groups {
          ...CandidateGroupFields
          groups {
            ...CandidateGroupFields
            groups {
              ...CandidateGroupFields
            }
          }
        }
      }
    }
  }
  ${CandidateGroupFieldsFragmentDoc}
`;
export const AdminGroupFieldsFragmentDoc = gql`
  fragment AdminGroupFields on Group {
    ...BaseGroupFields
    entries {
      ...EntryFields
      solutions {
        id
        value
      }
    }
  }
  ${BaseGroupFieldsFragmentDoc}
  ${EntryFieldsFragmentDoc}
`;
export const AdminGroupsRecursiveFragmentDoc = gql`
  fragment AdminGroupsRecursive on Group {
    ...AdminGroupFields
    groups {
      ...AdminGroupFields
      groups {
        ...AdminGroupFields
        groups {
          ...AdminGroupFields
          groups {
            ...AdminGroupFields
            groups {
              ...AdminGroupFields
            }
          }
        }
      }
    }
  }
  ${AdminGroupFieldsFragmentDoc}
`;
export const AssessmentDocument = gql`
  query Assessment($token: String!) {
    assessmentByToken(token: $token) {
      columns
      groups {
        ...CandidateGroupsRecursive
      }
      expiresAt
    }
  }
  ${CandidateGroupsRecursiveFragmentDoc}
`;

/**
 * __useAssessmentQuery__
 *
 * To run a query within a React component, call `useAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAssessmentQuery(baseOptions: Apollo.QueryHookOptions<AssessmentQuery, AssessmentQueryVariables>) {
  return Apollo.useQuery<AssessmentQuery, AssessmentQueryVariables>(AssessmentDocument, baseOptions);
}
export function useAssessmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AssessmentQuery, AssessmentQueryVariables>
) {
  return Apollo.useLazyQuery<AssessmentQuery, AssessmentQueryVariables>(AssessmentDocument, baseOptions);
}
export type AssessmentQueryHookResult = ReturnType<typeof useAssessmentQuery>;
export type AssessmentLazyQueryHookResult = ReturnType<typeof useAssessmentLazyQuery>;
export type AssessmentQueryResult = Apollo.QueryResult<AssessmentQuery, AssessmentQueryVariables>;
export const AdminAssessmentDocument = gql`
  query AdminAssessment($token: String!) {
    assessmentByAdminToken(token: $token) {
      columns
      groups {
        ...AdminGroupsRecursive
      }
    }
  }
  ${AdminGroupsRecursiveFragmentDoc}
`;

/**
 * __useAdminAssessmentQuery__
 *
 * To run a query within a React component, call `useAdminAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminAssessmentQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAdminAssessmentQuery(
  baseOptions: Apollo.QueryHookOptions<AdminAssessmentQuery, AdminAssessmentQueryVariables>
) {
  return Apollo.useQuery<AdminAssessmentQuery, AdminAssessmentQueryVariables>(AdminAssessmentDocument, baseOptions);
}
export function useAdminAssessmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminAssessmentQuery, AdminAssessmentQueryVariables>
) {
  return Apollo.useLazyQuery<AdminAssessmentQuery, AdminAssessmentQueryVariables>(AdminAssessmentDocument, baseOptions);
}
export type AdminAssessmentQueryHookResult = ReturnType<typeof useAdminAssessmentQuery>;
export type AdminAssessmentLazyQueryHookResult = ReturnType<typeof useAdminAssessmentLazyQuery>;
export type AdminAssessmentQueryResult = Apollo.QueryResult<AdminAssessmentQuery, AdminAssessmentQueryVariables>;
export const StartAssessmentDocument = gql`
  mutation StartAssessment($input: AssessmentEventInput!) {
    startAssessment(input: $input) {
      id
    }
  }
`;
export type StartAssessmentMutationFn = Apollo.MutationFunction<
  StartAssessmentMutation,
  StartAssessmentMutationVariables
>;

/**
 * __useStartAssessmentMutation__
 *
 * To run a mutation, you first call `useStartAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startAssessmentMutation, { data, loading, error }] = useStartAssessmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<StartAssessmentMutation, StartAssessmentMutationVariables>
) {
  return Apollo.useMutation<StartAssessmentMutation, StartAssessmentMutationVariables>(
    StartAssessmentDocument,
    baseOptions
  );
}
export type StartAssessmentMutationHookResult = ReturnType<typeof useStartAssessmentMutation>;
export type StartAssessmentMutationResult = Apollo.MutationResult<StartAssessmentMutation>;
export type StartAssessmentMutationOptions = Apollo.BaseMutationOptions<
  StartAssessmentMutation,
  StartAssessmentMutationVariables
>;
export const SubmitAssessmentDocument = gql`
  mutation submitAssessment($input: AssessmentEventInput!) {
    submitAssessment(input: $input) {
      id
    }
  }
`;
export type SubmitAssessmentMutationFn = Apollo.MutationFunction<
  SubmitAssessmentMutation,
  SubmitAssessmentMutationVariables
>;

/**
 * __useSubmitAssessmentMutation__
 *
 * To run a mutation, you first call `useSubmitAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAssessmentMutation, { data, loading, error }] = useSubmitAssessmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitAssessmentMutation, SubmitAssessmentMutationVariables>
) {
  return Apollo.useMutation<SubmitAssessmentMutation, SubmitAssessmentMutationVariables>(
    SubmitAssessmentDocument,
    baseOptions
  );
}
export type SubmitAssessmentMutationHookResult = ReturnType<typeof useSubmitAssessmentMutation>;
export type SubmitAssessmentMutationResult = Apollo.MutationResult<SubmitAssessmentMutation>;
export type SubmitAssessmentMutationOptions = Apollo.BaseMutationOptions<
  SubmitAssessmentMutation,
  SubmitAssessmentMutationVariables
>;
export const UpdateAssessmentEntryDocument = gql`
  mutation UpdateAssessmentEntry($entries: [EntryEventInput!]!) {
    updateEntries(entries: $entries)
  }
`;
export type UpdateAssessmentEntryMutationFn = Apollo.MutationFunction<
  UpdateAssessmentEntryMutation,
  UpdateAssessmentEntryMutationVariables
>;

/**
 * __useUpdateAssessmentEntryMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentEntryMutation, { data, loading, error }] = useUpdateAssessmentEntryMutation({
 *   variables: {
 *      entries: // value for 'entries'
 *   },
 * });
 */
export function useUpdateAssessmentEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAssessmentEntryMutation, UpdateAssessmentEntryMutationVariables>
) {
  return Apollo.useMutation<UpdateAssessmentEntryMutation, UpdateAssessmentEntryMutationVariables>(
    UpdateAssessmentEntryDocument,
    baseOptions
  );
}
export type UpdateAssessmentEntryMutationHookResult = ReturnType<typeof useUpdateAssessmentEntryMutation>;
export type UpdateAssessmentEntryMutationResult = Apollo.MutationResult<UpdateAssessmentEntryMutation>;
export type UpdateAssessmentEntryMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssessmentEntryMutation,
  UpdateAssessmentEntryMutationVariables
>;
export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> =
  | LegacyStitchingResolver<TResult, TParent, TContext, TArgs>
  | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  AdminToken: ResolverTypeWrapper<AdminToken>;
  String: ResolverTypeWrapper<Scalars["String"]>;
  Assessment: ResolverTypeWrapper<Assessment>;
  Int: ResolverTypeWrapper<Scalars["Int"]>;
  Float: ResolverTypeWrapper<Scalars["Float"]>;
  AssessmentEntry: ResolverTypeWrapper<AssessmentEntry>;
  Boolean: ResolverTypeWrapper<Scalars["Boolean"]>;
  AssessmentEntryInput: AssessmentEntryInput;
  AssessmentEventInput: AssessmentEventInput;
  AssessmentInput: AssessmentInput;
  AssessmentRowInput: AssessmentRowInput;
  AssessmentSearchInput: AssessmentSearchInput;
  AssessmentStatus: AssessmentStatus;
  AssessmentTemplate: ResolverTypeWrapper<AssessmentTemplate>;
  AssessmentTemplateInput: AssessmentTemplateInput;
  Candidate: ResolverTypeWrapper<Candidate>;
  CreateAssessmentInput: CreateAssessmentInput;
  CreateCandidateInput: CreateCandidateInput;
  DateTime: ResolverTypeWrapper<Scalars["DateTime"]>;
  EntryEventInput: EntryEventInput;
  Group: ResolverTypeWrapper<Group>;
  HiringTeamMember: ResolverTypeWrapper<HiringTeamMember>;
  JSON: ResolverTypeWrapper<Scalars["JSON"]>;
  Mutation: ResolverTypeWrapper<{}>;
  Query: ResolverTypeWrapper<{}>;
  Solution: ResolverTypeWrapper<Solution>;
  _FieldSet: ResolverTypeWrapper<Scalars["_FieldSet"]>;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  AdminToken: AdminToken;
  String: Scalars["String"];
  Assessment: Assessment;
  Int: Scalars["Int"];
  Float: Scalars["Float"];
  AssessmentEntry: AssessmentEntry;
  Boolean: Scalars["Boolean"];
  AssessmentEntryInput: AssessmentEntryInput;
  AssessmentEventInput: AssessmentEventInput;
  AssessmentInput: AssessmentInput;
  AssessmentRowInput: AssessmentRowInput;
  AssessmentSearchInput: AssessmentSearchInput;
  AssessmentTemplate: AssessmentTemplate;
  AssessmentTemplateInput: AssessmentTemplateInput;
  Candidate: Candidate;
  CreateAssessmentInput: CreateAssessmentInput;
  CreateCandidateInput: CreateCandidateInput;
  DateTime: Scalars["DateTime"];
  EntryEventInput: EntryEventInput;
  Group: Group;
  HiringTeamMember: HiringTeamMember;
  JSON: Scalars["JSON"];
  Mutation: {};
  Query: {};
  Solution: Solution;
  _FieldSet: Scalars["_FieldSet"];
}>;

export type AdminTokenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AdminToken"] = ResolversParentTypes["AdminToken"]
> = ResolversObject<{
  expiresAt?: Resolver<ResolversTypes["DateTime"], ParentType, ContextType>;
  token?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  username?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AssessmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Assessment"] = ResolversParentTypes["Assessment"]
> = ResolversObject<{
  candidate?: Resolver<ResolversTypes["Candidate"], ParentType, ContextType>;
  columns?: Resolver<Array<ResolversTypes["String"]>, ParentType, ContextType>;
  expiresAt?: Resolver<Maybe<ResolversTypes["DateTime"]>, ParentType, ContextType>;
  groups?: Resolver<Array<ResolversTypes["Group"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes["AssessmentStatus"]>, ParentType, ContextType>;
  template?: Resolver<ResolversTypes["AssessmentTemplate"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AssessmentEntryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AssessmentEntry"] = ResolversParentTypes["AssessmentEntry"]
> = ResolversObject<{
  column?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  editable?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  formula?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  solutions?: Resolver<Maybe<Array<ResolversTypes["Solution"]>>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes["Float"]>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AssessmentTemplateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AssessmentTemplate"] = ResolversParentTypes["AssessmentTemplate"]
> = ResolversObject<{
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  passThreshold?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CandidateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Candidate"] = ResolversParentTypes["Candidate"]
> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes["DateTime"], ParentType, ContextType>;
  email?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  hiringTeam?: Resolver<Array<ResolversTypes["HiringTeamMember"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes["DateTime"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes["DateTime"], any> {
  name: "DateTime";
}

export type GroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Group"] = ResolversParentTypes["Group"]
> = ResolversObject<{
  candidate?: Resolver<ResolversTypes["Candidate"], ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  entries?: Resolver<
    Array<ResolversTypes["AssessmentEntry"]>,
    ParentType,
    ContextType,
    RequireFields<GroupEntriesArgs, never>
  >;
  formatter?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  groups?: Resolver<Array<ResolversTypes["Group"]>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  labelStyle?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  precision?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  rowNumber?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HiringTeamMemberResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["HiringTeamMember"] = ResolversParentTypes["HiringTeamMember"]
> = ResolversObject<{
  username?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes["JSON"], any> {
  name: "JSON";
}

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Mutation"] = ResolversParentTypes["Mutation"]
> = ResolversObject<{
  cancelAssessment?: Resolver<
    ResolversTypes["Assessment"],
    ParentType,
    ContextType,
    RequireFields<MutationCancelAssessmentArgs, "assessment">
  >;
  createAdminToken?: Resolver<
    ResolversTypes["AdminToken"],
    ParentType,
    ContextType,
    RequireFields<MutationCreateAdminTokenArgs, "assessment">
  >;
  createAssessment?: Resolver<
    ResolversTypes["Assessment"],
    ParentType,
    ContextType,
    RequireFields<MutationCreateAssessmentArgs, "input">
  >;
  createCandidates?: Resolver<
    Array<ResolversTypes["Candidate"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateCandidatesArgs, "input">
  >;
  editCandidate?: Resolver<
    ResolversTypes["Candidate"],
    ParentType,
    ContextType,
    RequireFields<MutationEditCandidateArgs, "candidateId" | "newName">
  >;
  resendEmail?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType,
    RequireFields<MutationResendEmailArgs, "assessment">
  >;
  setAssessmentExpiration?: Resolver<
    ResolversTypes["Assessment"],
    ParentType,
    ContextType,
    RequireFields<MutationSetAssessmentExpirationArgs, "assessment">
  >;
  startAssessment?: Resolver<
    Maybe<ResolversTypes["Assessment"]>,
    ParentType,
    ContextType,
    RequireFields<MutationStartAssessmentArgs, "input">
  >;
  submitAssessment?: Resolver<
    Maybe<ResolversTypes["Assessment"]>,
    ParentType,
    ContextType,
    RequireFields<MutationSubmitAssessmentArgs, "input">
  >;
  updateEntries?: Resolver<
    Maybe<ResolversTypes["Boolean"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateEntriesArgs, "entries">
  >;
  uploadAssessmentTemplate?: Resolver<
    ResolversTypes["AssessmentTemplate"],
    ParentType,
    ContextType,
    RequireFields<MutationUploadAssessmentTemplateArgs, "input">
  >;
}>;

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Query"] = ResolversParentTypes["Query"]
> = ResolversObject<{
  allAssessments?: Resolver<Array<ResolversTypes["Assessment"]>, ParentType, ContextType>;
  assessment?: Resolver<
    Maybe<ResolversTypes["Assessment"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAssessmentArgs, "assessmentId" | "candidateId">
  >;
  assessmentByAdminToken?: Resolver<
    Maybe<ResolversTypes["Assessment"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAssessmentByAdminTokenArgs, "token">
  >;
  assessmentByToken?: Resolver<
    Maybe<ResolversTypes["Assessment"]>,
    ParentType,
    ContextType,
    RequireFields<QueryAssessmentByTokenArgs, "token">
  >;
  getCandidate?: Resolver<
    Maybe<ResolversTypes["Candidate"]>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCandidateArgs, "id">
  >;
  searchAssessments?: Resolver<
    Array<ResolversTypes["Assessment"]>,
    ParentType,
    ContextType,
    RequireFields<QuerySearchAssessmentsArgs, "input">
  >;
  templates?: Resolver<Array<ResolversTypes["AssessmentTemplate"]>, ParentType, ContextType>;
}>;

export type SolutionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Solution"] = ResolversParentTypes["Solution"]
> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes["DateTime"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["Int"], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes["DateTime"], ParentType, ContextType>;
  value?: Resolver<ResolversTypes["Float"], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface FieldSetScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes["_FieldSet"], any> {
  name: "_FieldSet";
}

export type Resolvers<ContextType = any> = ResolversObject<{
  AdminToken?: AdminTokenResolvers<ContextType>;
  Assessment?: AssessmentResolvers<ContextType>;
  AssessmentEntry?: AssessmentEntryResolvers<ContextType>;
  AssessmentTemplate?: AssessmentTemplateResolvers<ContextType>;
  Candidate?: CandidateResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Group?: GroupResolvers<ContextType>;
  HiringTeamMember?: HiringTeamMemberResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  Mutation?: MutationResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Solution?: SolutionResolvers<ContextType>;
  _FieldSet?: GraphQLScalarType;
}>;

/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
