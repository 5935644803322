import { useHistory } from "react-router-dom";
import { useSubmitAssessmentMutation } from "../../_generated/graphql";
import { routes } from "../../constants/routes";
import { globalActions, useGlobal } from "../../stores/global/global-store";
import { BaseDialog } from "./base-dialog";

export type SubmissionDialogProps = {
  token: string;
};

export const SubmissionDialog = ({ token }: SubmissionDialogProps) => {
  const [submitAssessmentMutation] = useSubmitAssessmentMutation();
  const history = useHistory();

  const {
    state: { isSubmissionDialogOpen },
    dispatch,
  } = useGlobal();

  const submitAssessment = async () => {
    await submitAssessmentMutation({
      variables: {
        input: {
          token,
          timestamp: Date.now(),
        },
      },
    });

    history.push(routes.submitted.path());
  };

  const closeDialog = () => {
    dispatch({ type: globalActions.SET_IS_SUBMISSION_DIALOG_OPEN, payload: false });
  };

  return (
    <BaseDialog
      title="Submit Assessment"
      isOpen={isSubmissionDialogOpen}
      onClose={() => closeDialog()}
      rightButtonText="Submit"
      rightButtonOnClick={() => submitAssessment()}
    >
      {/*TODO: Implement Incomplete Cell Warning */}
      <p>
        <strong>Once you submit, you cannot resume the assessment.</strong>
      </p>
    </BaseDialog>
  );
};
