import type { CSSProperties } from "react";
import { forwardRef } from "react";
import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { Div } from "./Div";
import type { BoxColProps, BoxProps } from "./types";

export const Box = styled(Div, {
  shouldForwardProp: isPropValid,
})<BoxProps>({}, ({ column, wrap, reverse, justifyContent, alignItems, gap }) => {
  const display = "flex";
  const flexDirection = `${column ? "column" : "row"}${reverse ? "-reverse" : ""}` as CSSProperties["flexDirection"];
  const flexWrap = (wrap ? `${wrap === "reverse" ? "wrap-reverse" : "wrap"}` : "nowrap") as CSSProperties["flexWrap"];

  return {
    display,
    flexDirection,
    flexWrap,
    alignItems,
    justifyContent,
    gap,
  };
});

export const BoxCol = forwardRef<HTMLDivElement, BoxColProps>(({ children, ...otherFlexProps }: BoxColProps, ref) => (
  <Box column {...otherFlexProps} ref={ref}>
    {children}
  </Box>
));
