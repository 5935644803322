import type { ReactNode } from "react";
import { Button, Dialog, Intent } from "@blueprintjs/core";
import classes from "./base-dialog.less";

export type BaseDialogProps = {
  children: ReactNode;
  isOpen: boolean | undefined;
  onClose: () => void;
  title: string;
  className?: string;
  isCloseButtonShown?: boolean;
  showCancelButton?: boolean;
  cancelButtonText?: string;
  cancelButtonIntent?: Intent;
  showRightButton?: boolean;
  rightButtonOnClick: () => void;
  rightButtonText: string;
  rightButtonIntent?: Intent;
  portalClassName?: string;
  icon?: JSX.Element;
  large?: boolean;
};

export const BaseDialog = ({
  isCloseButtonShown,
  isOpen,
  onClose,
  title,
  children,
  showCancelButton = true,
  cancelButtonText,
  cancelButtonIntent,
  showRightButton = true,
  rightButtonOnClick,
  rightButtonText,
  rightButtonIntent,
  portalClassName,
  icon,
  large,
}: BaseDialogProps) => {
  return (
    <Dialog
      className={large ? classes.dialogLarge : classes.dialog}
      canEscapeKeyClose
      canOutsideClickClose
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      isCloseButtonShown={isCloseButtonShown ?? false}
      portalClassName={portalClassName}
      icon={icon}
    >
      <div>
        <div className={classes.dialogText}>{children}</div>
        <div className={classes.dialogFooter}>
          <div className={classes.dialogButtons}>
            {showCancelButton && (
              <Button
                className={classes.cancelButton}
                text={cancelButtonText ?? "Cancel"}
                minimal
                intent={cancelButtonIntent ?? Intent.PRIMARY}
                onClick={onClose}
              />
            )}
            {showRightButton && (
              <Button
                className={classes.rightButton}
                text={rightButtonText}
                intent={rightButtonIntent ?? Intent.SUCCESS}
                onClick={rightButtonOnClick}
              />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
