import type { ApolloError } from "@apollo/client";
import type { ItemRenderer } from "@blueprintjs/select";
import { MultiSelect } from "@blueprintjs/select";
import { isEqual } from "lodash";
import debounce from "lodash/debounce";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import type { SearchResult as InstrumentResult } from "../../../_generated/graphql";
import { useInstruments } from "../hooks/useInstruments";
import { SelectMenuItem } from "../SelectMenuItem";
import { highlightText } from "../textHighlight";
import { useAllFinancialTypes } from "../hooks/useAllFinancialTypes";
import { createItemListRenderer } from "./itemListRenderer";

const InstrumentSuggest = MultiSelect.ofType<InstrumentResult>();

const renderInstrumentList: ItemRenderer<InstrumentResult> = (instrument, { modifiers, handleClick, query }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <SelectMenuItem
      active={modifiers.active}
      key={instrument.id as number}
      label={instrument.description || ""}
      onClick={handleClick}
      text={highlightText(instrument.mSymbol || "", query)}
      shouldDismissPopover={false}
    />
  );
};

const areUsersEqual = (a: InstrumentResult, b: InstrumentResult): boolean => {
  return a.id === b.id;
};

const renderDefaultTag = (item: InstrumentResult): React.ReactNode => {
  return <div className="tag">{item.mSymbol}</div>;
};

export type { InstrumentResult };

export interface InstrumentSelectorProps {
  className?: string;
  fill?: boolean;
  placeholder?: string;
  initialQuery?: string;
  /**
   * Default financial types to filter the search by.
   *
   * @default [7] - options
   */
  financialTypes?: number[];

  /**
   * If true, displays a selector at the top of the instrument selector input
   * that allows changing which financial types should be used to filter the
   * search.
   *
   * @default false
   */
  allowModifyFinancialTypes?: boolean;

  maxSearchResult?: number;
  selectedItems: InstrumentResult[];
  debounceDelay?: number;
  resetOnSelect?: boolean;
  onItemSelect: (item: InstrumentResult, event?: React.SyntheticEvent<HTMLElement>) => void;
  onTagRemove: (value: React.ReactNode, index: number) => void;
  renderTag?: (item: InstrumentResult) => React.ReactNode;
  onError?: (error: ApolloError) => void;
  autoFocus?: boolean;
}

export const InstrumentSelector = ({
  className = "",
  fill = true,
  initialQuery = "",
  financialTypes: financialTypesProp = [7],
  allowModifyFinancialTypes = false,
  maxSearchResult = 10,
  placeholder = "Search by ticker",
  debounceDelay = 200,
  resetOnSelect = true,
  selectedItems,
  onItemSelect,
  renderTag,
  onTagRemove,
  onError,
  autoFocus,
}: InstrumentSelectorProps) => {
  const [isFtSelectorOpen, setIsFtSelectorOpen] = useState(false);
  const [selectedFinancialTypes, setSelectedFinancialTypes] = useState(() => new Set(financialTypesProp));
  const [skip, setSkip] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>(initialQuery);
  const {
    data: instrumentList,
    loading,
    error,
  } = useInstruments({
    searchText,
    maxSearchResult,
    financialTypes: [...selectedFinancialTypes],
    skip,
  });
  const debounceSetSearchText = useRef(debounce(setSearchText, debounceDelay)).current;
  const availableFinancialTypes = useAllFinancialTypes();

  // If the props passed in to InstrumentSelector change, we want to reset our
  // selected financial types to those props
  const prevFinancialTypesProp = useRef(selectedFinancialTypes);
  useEffect(() => {
    const financialTypesPropSet = new Set(financialTypesProp);
    if (isEqual(prevFinancialTypesProp.current, financialTypesPropSet)) return;
    prevFinancialTypesProp.current = new Set(financialTypesPropSet);
    setSelectedFinancialTypes(prevFinancialTypesProp.current);
  }, [financialTypesProp]);

  // Report up errors from fetching instruments
  useEffect(() => {
    if (error) {
      onError?.(error);
    }
  }, [error, onError]);

  // Report up errors from fetching financial types
  useEffect(() => {
    if (availableFinancialTypes.error) {
      onError?.(availableFinancialTypes.error);
    }
  }, [availableFinancialTypes.error, onError]);

  const handleQueryChange = (newQuery: string) => {
    if (newQuery) {
      debounceSetSearchText(newQuery);
      setSkip(false);
    } else {
      setSkip(true);
    }
  };

  const itemListRenderer = useMemo(() => {
    if (!allowModifyFinancialTypes) {
      return undefined;
    }

    return createItemListRenderer({
      isFtSelectorOpen,
      setIsFtSelectorOpen,
      loadingResults: loading,
      availableFinancialTypes: availableFinancialTypes.result ?? [],
      selectedFinancialTypes,
      setSelectedFinancialTypes,
    });
  }, [isFtSelectorOpen, allowModifyFinancialTypes, loading, availableFinancialTypes.result, selectedFinancialTypes]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  useLayoutEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
      inputRef.current?.select();
    }
  }, [autoFocus]);

  return (
    <InstrumentSuggest
      fill={fill}
      resetOnQuery
      query={searchText}
      resetOnSelect={resetOnSelect}
      popoverProps={{ usePortal: false, minimal: true }}
      className={className}
      itemListRenderer={itemListRenderer}
      itemRenderer={renderInstrumentList}
      itemsEqual={areUsersEqual}
      items={instrumentList}
      onQueryChange={handleQueryChange}
      tagRenderer={renderTag ? renderTag : renderDefaultTag}
      onItemSelect={onItemSelect}
      selectedItems={selectedItems}
      tagInputProps={{
        onRemove: onTagRemove,
        placeholder,
        inputRef: (ref) => {
          inputRef.current = ref;
        },
      }}
    />
  );
};
