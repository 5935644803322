import type { Workflow } from "./workflow";

export enum CompType {
  SALARY = "SALARY",
  BONUS = "BONUS",
}

export enum HistoricalCompType {
  BASE_SALARY = "BASE_SALARY",
  CASH_BONUS = "CASH_BONUS",
  OTHER_COMPENSATION = "OTHER_COMPENSATION",
  EQUITY_VALUE_GRANTED = "EQUITY_VALUE_GRANTED",
  EQUITY_VESTING_BY_YEAR = "EQUITY_VESTING_BY_YEAR",
  OPTIONS_VALUE_GRANTED = "OPTIONS_VALUE_GRANTED",
  OPTIONS_VESTING_BY_YEAR = "OPTIONS_VESTING_BY_YEAR",
}

export enum GuaranteeType {
  TOTAL_COMPENSATION = "TOTAL_COMPENSATION",
  INCENTIVE_AWARDS = "INCENTIVE_AWARDS",
  TARGET_BONUS = "TARGET_BONUS",
  PLANNED_BONUS = "PLANNED_BONUS",
}

export enum HiringAwardType {
  SIGNING_BONUS = "SIGNING_BONUS",
  CONTINGENCY_SIGNING_BONUS = "CONTINGENCY_SIGNING_BONUS",
  MAKE_WHOLE_CASH = "MAKE_WHOLE_CASH",
  MAKE_WHOLE_UMI = "MAKE_WHOLE_UMI",
  MAKE_WHOLE_NOTIONAL = "MAKE_WHOLE_NOTIONAL",
  MAKE_WHOLE_CSEPR = "MAKE_WHOLE_CSEPR",
  BUYOUT_CASH = "BUYOUT_CASH",
  BUYOUT_UMI = "BUYOUT_UMI",
  BUYOUT_NOTIONAL = "BUYOUT_NOTIONAL",
  BUYOUT_CSEPR = "BUYOUT_CSEPR",
  CONTINGENCY_MAKE_WHOLE_CASH = "CONTINGENCY_MAKE_WHOLE_CASH",
  CONTINGENCY_MAKE_WHOLE_UMI = "CONTINGENCY_MAKE_WHOLE_UMI",
  CONTINGENCY_MAKE_WHOLE_NOTIONAL = "CONTINGENCY_MAKE_WHOLE_NOTIONAL",
  CONTINGENCY_MAKE_WHOLE_CSEPR = "CONTINGENCY_MAKE_WHOLE_CSEPR",
  CONTINGENCY_BUYOUT_CASH = "CONTINGENCY_BUYOUT_CASH",
  CONTINGENCY_BUYOUT_UMI = "CONTINGENCY_BUYOUT_UMI",
  CONTINGENCY_BUYOUT_NOTIONAL = "CONTINGENCY_BUYOUT_NOTIONAL",
  CONTINGENCY_BUYOUT_CSEPR = "CONTINGENCY_BUYOUT_CSEPR",
  EQUITY_GRANT_CEP = "EQUITY_GRANT_CEP",
  EQUITY_GRANT_CSEP = "EQUITY_GRANT_CSEP",
  EQUITY_GRANT_CSEPR = "EQUITY_GRANT_CSEPR",
}

export enum HiringAwardClawbackType {
  NONE = "NONE",
  SIX_MONTHS = "SIX_MONTHS",
  TWELVE_MONTHS = "TWELVE_MONTHS",
  TWELVE_MONTHS_AFTER_START_DATE = "TWELVE_MONTHS_AFTER_START_DATE",
  EIGHTEEN_MONTHS = "EIGHTEEN_MONTHS",
  TWENTY_FOUR_MONTHS = "TWENTY_FOUR_MONTHS",
}

export enum PayableDateType {
  FIRST_PAY_PERIOD_AFTER_HIRE_DATE = "FIRST_PAY_PERIOD_AFTER_HIRE_DATE",
  FIRST_PAY_PERIOD_OF_YEAR = "FIRST_PAY_PERIOD_OF_YEAR",
  UPON_RECEIPT_OF_DOCUMENTATION = "UPON_RECEIPT_OF_DOCUMENTATION",
  OTHER = "OTHER",
}

export enum IssuanceDateType {
  FIRST_DAY_OF_MONTH_AFTER_HIRE_DATE = "FIRST_DAY_OF_MONTH_AFTER_HIRE_DATE",
  FIRST_DAY_OF_QUARTER_AFTER_HIRE_DATE = "FIRST_DAY_OF_QUARTER_AFTER_HIRE_DATE",
  OTHER = "OTHER",
}

export enum ServiceDateType {
  SIX_MONTHS_FROM_ISSUANCE_DATE = "SIX_MONTHS_FROM_ISSUANCE_DATE",
  TWELVE_MONTHS_FROM_ISSUANCE_DATE = "TWELVE_MONTHS_FROM_ISSUANCE_DATE",
  EIGHTEEN_MONTHS_FROM_ISSUANCE_DATE = "EIGHTEEN_MONTHS_FROM_ISSUANCE_DATE",
  TWENTY_FOUR_MONTHS_FROM_ISSUANCE_DATE = "TWENTY_FOUR_MONTHS_FROM_ISSUANCE_DATE",
  THIRTY_SIX_MONTHS_FROM_ISSUANCE_DATE = "THIRTY_SIX_MONTHS_FROM_ISSUANCE_DATE",
  OTHER = "OTHER",
}

export enum VestingDateType {
  SIX_MONTHS_FROM_ISSUANCE_DATE = "SIX_MONTHS_FROM_ISSUANCE_DATE",
  TWELVE_MONTHS_FROM_ISSUANCE_DATE = "TWELVE_MONTHS_FROM_ISSUANCE_DATE",
  EIGHTEEN_MONTHS_FROM_ISSUANCE_DATE = "EIGHTEEN_MONTHS_FROM_ISSUANCE_DATE",
  TWENTY_FOUR_MONTHS_FROM_ISSUANCE_DATE = "TWENTY_FOUR_MONTHS_FROM_ISSUANCE_DATE",
  THIRTY_SIX_MONTHS_FROM_ISSUANCE_DATE = "THIRTY_SIX_MONTHS_FROM_ISSUANCE_DATE",
  OTHER = "OTHER",
}

export type PayableDate = {
  type: PayableDateType;
  year?: number;
  other_date?: string;
};

export type IssuanceDate = {
  type: IssuanceDateType;
  other_date?: string;
};

export type ServiceDate = {
  type: ServiceDateType;
  other_date?: string;
};

export type VestingDate = {
  type: VestingDateType;
  other_date?: string;
};

export type CompPackageDetail = {
  comp_type: CompType;
  amount: string;
};

export type HistoricalCompPackageDetail = {
  comp_type: HistoricalCompType;
  amount: string;
};

export type HiringAward = {
  award_type: HiringAwardType;
  clawback_type: HiringAwardClawbackType;
  planned_amount: string;
  payable_date?: PayableDate;
  issuance_date?: IssuanceDate;
  service_date?: ServiceDate;
  vesting_date?: VestingDate;
  contingent_award_date?: string;
  notes?: string;
};

export type MarketDataReference = {
  role_name: string;
  headcount_classification: string;
  level: number;
  location: string;
  tc_range_min: string;
  tc_range_max: string;
};

export type OfferCompPackage = {
  year: number;
  base: string;
  bonus: string;
  guarantee_type: GuaranteeType;
  is_prorated: boolean;
};

export type HistoricalCompPackage = {
  year: number;
  company_name: string;
  source: HistoricalCompPackageSource;
  details: HistoricalCompPackageDetail[];
};

export enum HistoricalCompPackageSource {
  VERBAL_REQUESTED = "VERBAL_REQUESTED",
  VERBAL_VOLUNTEERED = "VERBAL_VOLUNTEERED",
  INSIGHT_DOCUMENT_REQUESTED = "INSIGHT_DOCUMENT_REQUESTED",
  INSIGHT_DOCUMENT_VOLUNTEERED = "INSIGHT_DOCUMENT_VOLUNTEERED",
}

export enum NoticePeriod {
  NONE = "NONE",
  TWO_WEEKS = "TWO_WEEKS",
  ONE_MONTH = "ONE_MONTH",
  TWO_MONTHS = "TWO_MONTHS",
  THREE_MONTHS = "THREE_MONTHS",
  FOUR_MONTHS = "FOUR_MONTHS",
  FIVE_MONTHS = "FIVE_MONTHS",
  SIX_MONTHS = "SIX_MONTHS",
  TWELVE_MONTHS = "TWELVE_MONTHS",
  GE_TIERED = "GE_TIERED",
  MILAN_TIERED = "MILAN_TIERED",
}

export enum NoticePeriodEmployer {
  NONE = "NONE",
  STATUTORY = "STATUTORY",
  STATUTORY_AND_POLICY = "STATUTORY_AND_POLICY",
  ONE_MONTH = "ONE_MONTH",
  TWO_MONTHS = "TWO_MONTHS",
  THREE_MONTHS = "THREE_MONTHS",
  SAME_AS_NOTICE_PERIOD = "SAME_AS_NOTICE_PERIOD",
}

export enum NonCompeteDuration {
  NONE = "NONE",
  THREE_MONTHS = "THREE_MONTHS",
  SIX_MONTHS = "SIX_MONTHS",
  SEVEN_MONTHS = "SEVEN_MONTHS",
  EIGHT_MONTHS = "EIGHT_MONTHS",
  NINE_MONTHS = "NINE_MONTHS",
  TEN_MONTHS = "TEN_MONTHS",
  TWELVE_MONTHS = "TWELVE_MONTHS",
  FIFTEEN_MONTHS = "FIFTEEN_MONTHS",
  TWENTY_ONE_MONTHS = "TWENTY_ONE_MONTHS",
  TWENTY_FOUR_MONTHS = "TWENTY_FOUR_MONTHS",
}

export enum SupplementalNonCompeteDuration {
  NONE = "NONE",
  ONE_MONTH = "ONE_MONTH",
  TWO_MONTHS = "TWO_MONTHS",
  THREE_MONTHS = "THREE_MONTHS",
  FOUR_MONTHS = "FOUR_MONTHS",
  FIVE_MONTHS = "FIVE_MONTHS",
  SIX_MONTHS = "SIX_MONTHS",
  SEVEN_MONTHS = "SEVEN_MONTHS",
  EIGHT_MONTHS = "EIGHT_MONTHS",
  NINE_MONTHS = "NINE_MONTHS",
  TEN_MONTHS = "TEN_MONTHS",
  ELEVEN_MONTHS = "ELEVEN_MONTHS",
  TWELVE_MONTHS = "TWELVE_MONTHS",
}

export enum EscalatedNonCompeteDuration {
  NONE = "NONE",
  EIGHT_MONTHS = "EIGHT_MONTHS",
  TWELVE_MONTHS = "TWELVE_MONTHS",
  FIFTEEN_MONTHS = "FIFTEEN_MONTHS",
  EIGHTEEN_MONTHS = "EIGHTEEN_MONTHS",
  TWENTY_MONTHS = "TWENTY_MONTHS",
  TWENTY_FOUR_MONTHS = "TWENTY_FOUR_MONTHS",
}

export enum PopulationType {
  CAMPUS_HIRES_EX_COMMODITIES = "CAMPUS_HIRES_EX_COMMODITIES",
  CAMPUS_HIRES_COMMODITIES_GFI = "CAMPUS_HIRES_COMMODITIES_GFI",
  EXPERIENCED_HIRES_EX_COMMODITIES = "EXPERIENCED_HIRES_EX_COMMODITIES",
  EXPERIENCED_HIRES_COMMODITIES = "EXPERIENCED_HIRES_COMMODITIES",
  EXPERIENCED_HIRES_GFI_EX_QR = "EXPERIENCED_HIRES_GFI_EX_QR",
  EXPERIENCED_HIRES_GFI_QR_ONLY = "EXPERIENCED_HIRES_GFI_QR_ONLY",
  FRANCE = "FRANCE",
  INDIA = "INDIA",
  CUSTOM = "CUSTOM",
}

export type RestrictedPeriodPayment = {
  index: number;
  number_of_months: number;
  amount: string;
};

export type NonCompeteTerms = {
  notice_period: NoticePeriod;
  notice_period_employer: NoticePeriodEmployer;
  non_compete_duration: NonCompeteDuration;
  supplemental_non_compete_duration: SupplementalNonCompeteDuration;
  prior_employer_non_compete_length_months: number;
  population_type: PopulationType;
  restricted_period_payments: RestrictedPeriodPayment[];
  escalated_non_compete_duration: EscalatedNonCompeteDuration;
};

export enum DeferredCompensationType {
  STOCK_OPTION = "STOCK_OPTION",
  RESTRICTED_STOCK_UNIT = "RESTRICTED_STOCK_UNIT",
  DEFERRED_CASH = "DEFERRED_CASH",
}

export type BaseDeferredCompPackage = {
  type: DeferredCompensationType;
  grant_date: string;
  vesting_duration_years: number;
  vesting_frequency_months: number;
  first_vesting_date: string;
};

export type StockOptionDeferredCompPackage = BaseDeferredCompPackage & {
  granted: number;
  strike_price: string;
};

export type RestrictedStockUnitDeferredCompPackage = BaseDeferredCompPackage & {
  granted: number;
  cliff_vest_months: number;
};

export type CashDeferredCompPackage = BaseDeferredCompPackage & {
  amount: string;
};

export type DeferredCompPackage =
  | StockOptionDeferredCompPackage
  | RestrictedStockUnitDeferredCompPackage
  | CashDeferredCompPackage;

export type HistoricalCompensation = {
  deferred: DeferredCompPackage[];
};

export type InternCompPackage = {
  pay_rate: string;
  pay_frequency: InternPayFrequency;
  corporate_housing: boolean;
};

export enum InternPayFrequency {
  WEEKLY_STIPEND = "WEEKLY_STIPEND",
  MONTHLY_STIPEND = "MONTHLY_STIPEND",
  PER_HOUR = "PER_HOUR",
}

export enum NewJoinerCompensationType {
  FULL_TIME = "FULL_TIME",
  INTERN = "INTERN",
}

type NewJoinerCompensationBase = {
  notes: string;
  comparables: string;
  hiring_awards: HiringAward[];
  market_data_references: MarketDataReference[];
  historical_comp_packages: HistoricalCompPackage[];
  non_compete_terms?: NonCompeteTerms;
  historical_compensation?: HistoricalCompensation;
};

export type InternNewJoinerCompensation = NewJoinerCompensationBase & {
  type: NewJoinerCompensationType.INTERN;
  comp_packages: InternCompPackage[];
};
export type FullTimeNewJoinerCompensation = NewJoinerCompensationBase & {
  type: NewJoinerCompensationType.FULL_TIME;
  comp_packages: OfferCompPackage[];
};

/**
 * Type for a New Joiner Compensation summary.
 * @see https://go/pseapi#/New%20Joiner%20Compensation
 */
export type NewJoinerCompensation = InternNewJoinerCompensation | FullTimeNewJoinerCompensation;

export type NewJoinerCompensationWorkflow = Workflow<NewJoinerCompensation> & { insight_job_offer_id: string };
