import type { CSSProperties } from "react";
import styled from "@emotion/styled";
import classnames from "classnames";

export interface CitadelLogoProps {
  className?: string;
  height?: CSSProperties["height"];
}

export const CitadelLogo = ({ className, height }: CitadelLogoProps) => {
  return (
    <LogoSvg
      style={{ height }}
      className={classnames("citadel-logo", className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 61.31 30.66"
      data-testid="citadel-logo"
    >
      <path d="M0,30.66H61.31V21.84H0v8.82" />
      <path d="M0,8.82H12.26V0H0V8.82" />
      <path d="M24.52,8.82H36.78V0H24.52V8.82" />
      <path d="M49,8.82H61.31V0H49V8.82" />
      <path d="M29.6,19.74H0V10.92H29.6v8.82" />
      <path d="M61.31,19.74H31.71V10.92h29.6v8.82" />
    </LogoSvg>
  );
};

const LogoSvg = styled.svg`
  path {
    fill: #002f6c;

    .bp3-dark & {
      fill: white;
    }
  }
`;
