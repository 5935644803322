// TODO[CDX-1399]: https://jira.citadelgroup.com/browse/CDX-1468
import { createAuthHeaders } from "@citadel/cdx-lib-auth";
import { getCdxSessionData } from "./getSessionData";
import { getCdxSessionCookies } from "./getSessionCookies";

/**
 * @deprecated Use "getAuthHeaders()" from "\@citadel/cdx-auth-browser".
 * {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @public
 */
export const getAuthHeaders = () => {
  const { gaSessionJwt } = getCdxSessionCookies();
  const { gaToken, authenticateJwt } = getCdxSessionData();
  return createAuthHeaders({ authenticateJwt, gaSessionJwt, gaToken });
};
