import { getErrorMessage } from "./getErrorMessage";

const invalidCredentialError = /invalid credential/i;
const invalidSessionError = /invalid session/i;
const missingEntitlementError = /forbidden/i;
const missingTokenError = /DecryptedToken empty/i;

// NOTE: the regex list is not exhaustive
const errorList = [invalidCredentialError, invalidSessionError, missingEntitlementError, missingTokenError];

export function isLikelyOAuth2TokenError(maybeError: unknown): boolean {
  return errorList.some((regex) => regex.test(getErrorMessage(maybeError)));
}
