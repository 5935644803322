import type { AssessmentGroup, AssessmentItemCoords } from "../../types/assessment";
import { BDGridLine } from "./bd-grid-line";

export type BDGridGroupProps = {
  group: AssessmentGroup;
  coords: AssessmentItemCoords;
  depth?: number;
};

export const BDGridGroup = ({ group, coords, depth = 0 }: BDGridGroupProps) => {
  return (
    <>
      <BDGridLine group={group} coords={coords} depth={depth} />
      {group.groups.map((g, idx) => (
        <BDGridGroup key={g.id} group={g as AssessmentGroup} coords={[...coords, idx]} depth={depth + 1} />
      ))}
    </>
  );
};
