import type { ReactElement } from "react";
import { noop } from "lodash";
import { Timer } from "../timer";
import { Icons } from "../../images";
import classes from "./header-bar.less";

export type HeaderBarProps = {
  label: string;
  endDate?: Date;
  rightElement?: ReactElement;
  onTimerEnd?: () => void;
};

export const HeaderBar = ({ label, endDate, rightElement, onTimerEnd = noop }: HeaderBarProps) => (
  <div className={`${classes.headerBar} bp3-dark`}>
    <div className={classes.left}>
      <img className={classes.logo} src={Icons.citadel.dark} />
      <h1 className={classes.headerText}>{label}</h1>
    </div>
    <div className={classes.center}>{endDate && <Timer endDate={endDate} onEnd={onTimerEnd} />}</div>
    <div className={classes.right}>{rightElement}</div>
  </div>
);
