import type { ApolloClient } from "@apollo/client";
import { ApolloProvider as Provider } from "@apollo/client";
import type { ReactElement } from "react";
import { useState, useEffect } from "react";
import { _useAuthenticationSession } from "@citadel/cdx-auth-browser";
import { AttachAuthenticationSessionLink } from "./components/AttachAuthenticationSessionLink";

type Promisable<T> = Promise<T> | T;
type Client = ApolloClient<any>;
export type InitApolloClient = (client: Client) => Promise<void>;

interface ApolloProviderProps {
  children: ReactElement;
  client?: Promise<Client> | Client;
  init?: InitApolloClient;
  /**
   * shows simple text otherwise
   */
  LoadingComponent?: () => ReactElement | null;
}

const loading = () => <span>Loading...</span>;
const noop = async () => {};
const waitForClientAndInit = async (init: InitApolloClient = noop, client: Promisable<Client>): Promise<Client> => {
  const resolvedClient = await client;
  await init(resolvedClient);

  return resolvedClient;
};

// apollo is a unique case where we have to block on client availability due to the apollo providers
export const ApolloProvider = ({ children, client, init, LoadingComponent = loading }: ApolloProviderProps) => {
  // client or resolved promised client
  const [resolvedClient, setResolvedClient] = useState<Client>();
  const session = _useAuthenticationSession();

  // initialize client and await resolution if necessary
  useEffect(() => {
    // We don't bother resolving if no apollo client has been specified
    if (client === undefined) return;

    (async () => {
      const resolved = await waitForClientAndInit(init, client);
      setResolvedClient(resolved);
    })();
  }, [client, init]);

  if (client === undefined) {
    return null;
  }

  if (!resolvedClient) {
    return <LoadingComponent />;
  }

  return (
    <>
      {session ? (
        <AttachAuthenticationSessionLink apolloClient={resolvedClient} authenticationSession={session} />
      ) : null}
      <Provider client={resolvedClient}>{children}</Provider>
    </>
  );
};
