// TODO[CDX-1399]: Remove ga security dependencies
import tracer from "dd-trace";
import type { LogAdaptor, LogLine } from "@citadel/cdx-logger";
import { logLevelToName } from "@citadel/cdx-logger";
import { getEnvironment } from "@citadel/cdx-environment";
import type { CdxSessionData } from "@citadel/cdx-lib-auth-browser";
import { getCdxSessionData } from "@citadel/cdx-lib-auth-browser";
import type { Config, SendContext } from "./splunk-logging/splunkLogging";
import { Logger } from "./splunk-logging/splunkLogging";

export const DEFAULT_SPLUNK_CONFIG: Partial<Config> = {
  batchInterval: 1000,
  maxBatchCount: 10,
  maxBatchSize: 1024, // 1kb
};

export const DEFAULT_SPLUNK_URL = "https://splunk.citadelgroup.com:8088/services/collector/event";

export interface SplunkLogAdaptorOptions {
  /**
   * proper name of the app
   */
  appName: string;
  /**
   * defaults to true when not local
   * you can override this value as-needed for debugging locally
   */
  enableSplunkLogging?: boolean;
  /**
   * which splunk index to use
   */
  index: string;
  /**
   * name of package associated with the app
   */
  packageName: string;
  /**
   * authorization token for splunk
   */
  token: string;
  /**
   * url for splunk collection, default already set
   */
  url?: string;

  /**
   * config for slunk if you wish to override our defaults
   */
  splunkConfig?: Partial<Config>;
}

let userData: Partial<CdxSessionData> | {};
const getUserData = (): Partial<CdxSessionData> | {} => {
  if (!userData) {
    const data = getCdxSessionData();
    if (data) {
      userData = {
        firstname: data.firstname,
        lastname: data.lastname,
        username: data.username,
      };
    }
  }
  return userData;
};

let traceId: string | undefined;
const getTraceId = () => {
  if (!traceId) {
    traceId = tracer.scope().active()?.context()?.toTraceId() ?? undefined;
  }
  return traceId;
};

export const createSplunkLogAdaptor = ({
  appName,
  enableSplunkLogging = !getEnvironment().isLocal,
  index,
  token,
  url = DEFAULT_SPLUNK_URL,
  splunkConfig = DEFAULT_SPLUNK_CONFIG,
}: SplunkLogAdaptorOptions): LogAdaptor => {
  const splunk = new Logger({
    token,
    url,
    ...splunkConfig,
  });

  return (logLine: LogLine) => {
    if (!enableSplunkLogging) {
      return;
    }
    const splunkLog: SendContext = {
      message: {
        ...logLine,
        ...getUserData(),
        traceId: getTraceId,
      },
      severity: logLevelToName[logLine.level],
      metadata: {
        host: (window as any).origin,
        index,
        source: appName,
        sourcetype: "httpevent",
      },
    };

    splunk.send(splunkLog, (err) => err && console.warn("Unable to send splunk logs", err));
  };
};
