import type { Company } from "./company";

/**
 * Insight Job Offer.
 * @see https://go/pseapi#/Insight%20Job%20Offers
 */
export type JobOffer = {
  id: string;
  creator_id: string;
  job_application_id: string;
  name: string;
  status: JobOfferStatus;
  level: number;
  education: any[];
  companies: Company[];
  business_justification: string;
  created_at: string;
  deleted_at: string;
  start_date: string;
  end_date: string;
  location_title: string;
  department_title: string;
  non_compete_duration: string;
  supplemental_non_compete_duration: string;
  citadel_notice_period: string;
};

/** Enum of statuses of a Job Offer. */
export enum JobOfferStatus {
  ACCEPTED_OFFER = "accepted_offer",
  APPROVED = "approved",
  CONDITIONALLY_APPROVED = "conditionally_approved",
  DECLINED = "declined",
  DECLINED_OFFER = "declined_offer",
  DISPOSITIONED = "dispositioned",
  DRAFT = "draft",
  PENDING = "pending",
  STOPPED = "stopped",
}
