import getPkce from "oauth-pkce";
import { logger } from "../logger";
import { getPingFederateCodeRequestState, getSessionData, storePingFederateCodeRequestState } from "./localStorage";
import { exchangeCodeForTokensFromPingFederate, refreshTokensFromPingFederate } from "./requests";
import type { SessionData, SessionResolveResult } from "./types";
import { randomBase64UrlString } from "./crypto";
import { getConfig } from "./env";
import {
  cleanUpSearchParams,
  constructUrl,
  replaceHistoryState,
  replaceHistoryEntry,
  pluckHash,
  constructRedirectUrl,
  getLatestAuthStateFromUrl,
} from "./url";
import { isTokenValid, isIdTokenValid } from "./verifyToken";
import { resolvePingFederateTokens, resolveSessionTokens } from "./resolveToken";
import { shouldBecomeLeaderForRefresh } from "./shouldBecomeLeaderForRefresh";

/**
 * Should request and store updated session data
 */
export const handleTokenRefresh = async (tokens: SessionData | null): Promise<SessionResolveResult> => {
  if (!tokens) {
    return {};
  }
  const refreshedTokens = await refreshTokensFromPingFederate(tokens.refreshToken);
  return resolvePingFederateTokens(refreshedTokens);
};

/**
 * Generates and stores code request state, then redirects to ping federate
 * Returns the error if any
 */
const handleNoRefreshTokenOrCode = async (): Promise<SessionResolveResult> => {
  const { clientId, url } = getConfig();
  const state = randomBase64UrlString();
  const nonce = randomBase64UrlString();

  return new Promise((resolve) => {
    getPkce(43, (error, pkce) => {
      if (error) {
        return resolve({ errorMessage: `Error generating PKCE values: ${error}` });
      }

      const { verifier, challenge } = pkce;

      storePingFederateCodeRequestState({
        codeVerifier: verifier,
        nonce,
        state,
      });

      const params = new URLSearchParams({
        client_id: clientId,
        response_type: "code",
        redirect_uri: constructRedirectUrl(),
        scope: "openid",
        state,
        nonce,
        code_challenge: challenge,
        code_challenge_method: "S256",
      });

      replaceHistoryEntry(`${url.authz}?${params.toString()}`);
      return resolve({ redirecting: true });
    });
  });
};

/**
 * Combines the URL search params and the stored request state to create the code -> token exchange request
 * After retrieving the tokens, it validates and stores them
 */
const handleCodeForTokenExchange = async (): Promise<SessionResolveResult> => {
  const requestState = getPingFederateCodeRequestState();
  if (!requestState) {
    logger.warn(`PingFederate request state could not be retrieved for code exchange`);
    return {};
  }

  const urlParams = new URLSearchParams(window.location.search);
  const { state: stateFromQueryParam = "", code: codeFromQueryParam = "" } = getLatestAuthStateFromUrl(urlParams) || {};
  const stateFromLocalStorage = requestState.state;
  if (stateFromQueryParam !== stateFromLocalStorage) {
    logger.warn(`PingFederate original request state does not match the query state for code exchange`);
    return {};
  }

  const cleanedUrl = constructUrl({ search: cleanUpSearchParams(urlParams), hash: pluckHash(urlParams) });
  replaceHistoryState({}, cleanedUrl);

  const exchangedTokens = await exchangeCodeForTokensFromPingFederate({
    code: codeFromQueryParam,
    codeVerifier: requestState.codeVerifier,
    redirectUri: constructRedirectUrl(),
  });
  if (!exchangedTokens) {
    logger.warn(`PingFederate exchange did not return a valid result`);
    return {};
  }

  const idTokenValid = await isIdTokenValid(exchangedTokens.id_token, requestState.nonce);
  if (!idTokenValid) {
    logger.warn(`PingFederate exchange token result failed verification`);
    return {};
  }

  return resolvePingFederateTokens(exchangedTokens);
};

/**
 * Attempt to resolve new tokens from:
 * - refresh token
 * - code param
 * - fresh redirect
 *
 * and return error message in the presence of:
 * - error param
 */
export const resolveCurrentState = async (): Promise<SessionResolveResult> => {
  logger.debug("Attempting a full refresh to get the current authentication state");

  // 1. token
  // retrieve tokens and return if we have non-expired
  const tokens = getSessionData();
  if (await isTokenValid(tokens)) {
    logger.debug("Successfully resolved access token from local storage");
    return resolveSessionTokens(tokens);
  }
  // check if we have a refresh token and return if we successfully refresh
  if (tokens?.refreshToken) {
    logger.debug("Attempting refresh token to get a new access token");
    const refreshResult = await handleTokenRefresh(tokens);
    if (refreshResult.sessionData) {
      logger.debug("Successfully refreshed to get a new access token");
      return refreshResult;
    }
  }

  // 2. code
  // check if we have a code and exchange it for a token if we do
  const urlParams = new URLSearchParams(window.location.search);
  const hasAuthCode = urlParams.has("code");
  if (hasAuthCode) {
    logger.debug("Attempting exchange code for access token");
    const exchangeResult = await handleCodeForTokenExchange();
    if (exchangeResult.sessionData) {
      logger.debug("Successfully exchanged code for access token");
      return exchangeResult;
    }
  }

  // 3. error
  // If we have error'd out during the auth process, return the error
  const errorSearchParam = urlParams.get("error_description");
  if (errorSearchParam) {
    logger.debug("Error message found in ping federate redirect; unable to authenticate.");
    const errorMessage = decodeURI(errorSearchParam).replace(/\+/gi, "");
    return { errorMessage, sessionData: null };
  }

  // 4. become leader for populate action
  // see should-become-leader-for-refresh.ts for details

  logger.debug("Should we become the leader for refresh?");
  const isLeader = await shouldBecomeLeaderForRefresh();
  if (!isLeader) {
    logger.debug("Waiting for another leader to perform authentication.");
    // if we are not the leader, retry this flow as we expect another window has performed the refresh
    return resolveCurrentState();
  }

  // 5. fresh populate
  // Populate PingFederated request state and redirect
  logger.debug("Redirecting to perform authentication");
  const redirectResult = await handleNoRefreshTokenOrCode();
  return redirectResult;
};
