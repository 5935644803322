import { Intent, ProgressBar } from "@blueprintjs/core";
import { showToast } from "@citadel/cdx-provider-toasts";

interface ProgressToast {
  id: number;
  completed: number;
  total: number;
  error: boolean;
  domId: string;
  dismissed: boolean;
}
const TOASTS = new Map<number, ProgressToast>();
const DISMISSABLE_TOAST_TIMEOUT = 3000;

export function createToast(id: number): number {
  const toast = {
    id,
    completed: 0,
    total: 10,
    error: false,
    domId: `REPORT_ISSUE_TOAST_UNIQUE_ID_${id}`,
    dismissed: false,
  };
  TOASTS.set(id, toast);
  return updateToast(id, toast);
}

interface ProgressToastStatus {
  completed: number;
  total: number;
  error: boolean;
  onClick?: () => void;
}
export function updateToast(id: number, status: ProgressToastStatus): number {
  let toast = TOASTS.get(id);
  if (!toast || toast.error) {
    return id;
  }

  toast = {
    ...toast,
    ...status,
  };
  if (toast.error) {
    showToast({
      id: toast.domId,
      icon: "error",
      timeout: DISMISSABLE_TOAST_TIMEOUT,
      intent: Intent.DANGER,
      message: "Error uploading report",
      action: {
        onClick: status.onClick,
        text: "Details",
      },
    });
  } else {
    const complete = toast.completed >= toast.total;

    if (complete || !toast.dismissed) {
      const intent = complete ? Intent.SUCCESS : Intent.NONE;
      showToast({
        id: toast.domId,
        icon: "upload",
        timeout: complete ? DISMISSABLE_TOAST_TIMEOUT : 0,
        intent,
        message: complete ? "Report Uploaded" : <ProgressBar value={toast.completed / toast.total} intent={intent} />,
        onDismiss: () => {
          if (!toast) {
            return;
          }
          toast.dismissed = true;
          TOASTS.set(toast.id, toast);
        },
      });
    }
  }

  TOASTS.set(toast.id, toast);
  return id;
}
