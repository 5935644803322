import { NonIdealState, Spinner, SpinnerSize } from "@blueprintjs/core";

interface LoadingStateProps {
  /**
   * Optional text to render beneath the loading spinner.
   * @example "Verifying authorization..."
   */
  text?: string;
}

/** Component rendering a centered spinner to indicate that something is loading. */
export const LoadingState = ({ text }: LoadingStateProps): JSX.Element => {
  return (
    <NonIdealState>
      <Spinner size={SpinnerSize.LARGE} />
      {text && <div>{text}</div>}
    </NonIdealState>
  );
};
