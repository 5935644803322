import { useEffect, useState } from "react";
import { noop, padStart } from "lodash";

const dateDiffInSeconds = (a: Date, b: Date) => Math.floor((b.getTime() - a.getTime()) / 1000);

export type TimerProps = {
  endDate: Date;
  onEnd?: () => void;
};

export const Timer = ({ endDate, onEnd = noop }: TimerProps) => {
  const [remaining, setRemaining] = useState<number>();

  useEffect(() => {
    const interval = setInterval(() => {
      const diff = dateDiffInSeconds(new Date(), endDate);

      setRemaining(diff);

      if (diff < 0) {
        clearInterval(interval);
        onEnd();
      }
    }, 500);

    return () => clearInterval(interval);
  }, [endDate, onEnd]);

  if (!remaining || remaining < 0) {
    return null;
  }

  const mins = `${Math.floor(remaining / 60)}`;
  const paddedSecs = padStart(`${Math.floor(remaining % 60)}`, 2, "0");

  return <span>{`${mins}:${paddedSecs}`}</span>;
};
