import type { Logger, LoggerOptions, CreateInspectorConsoleLogAdaptorOptions } from "@citadel/cdx-logger";
import { createLogger, createInspectorConsoleLogAdaptor } from "@citadel/cdx-logger";
import type { SplunkLogAdaptorOptions } from "@citadel/cdx-logger-splunk";
import { createSplunkLogAdaptor } from "@citadel/cdx-logger-splunk";
import { createDatadogTraceLogAdaptor } from "@citadel/cdx-logger-datadog";

type CreateBrowserAdaptorsOptions = {
  consoleLogOptions?: CreateInspectorConsoleLogAdaptorOptions;
  splunkOptions?: SplunkLogAdaptorOptions;
} & Partial<LoggerOptions>;

/**
 * Convenient defaults for logging in the browser.
 *
 * Currently just logs to the console.
 *
 * Given adaptors are merged into built-in adaptors.
 *
 * Uses the CDX_DEBUG key from localStorage environment variable which can either
 * be set to 1 or true to log all debug level logs. Alternatively, you can set it
 * to a pattern to match specific contexts, similar to that of the [debug] npm module.
 * See the docs of createLogger in @citadel/cdx-logger for more details.
 *
 * [debug]: https://www.npmjs.com/package/debug
 */
export const createBrowserLogger = ({
  consoleLogOptions,
  adaptors: givenAdaptors = [],
  debugOverride = debugOverrideFromEnvironment(),
  splunkOptions,
  ...options
}: CreateBrowserAdaptorsOptions = {}): Logger => {
  const adaptors = [
    ...givenAdaptors,
    createInspectorConsoleLogAdaptor(consoleLogOptions),
    createDatadogTraceLogAdaptor(),
  ];
  if (splunkOptions) {
    adaptors.push(createSplunkLogAdaptor(splunkOptions));
  }

  return createLogger({ adaptors, debugOverride, ...options });
};

const debugOverrideFromEnvironment = () => {
  const debugEnvironmentVariable = localStorage.getItem("CDX_DEBUG");

  if (debugEnvironmentVariable && ["true", "1"].includes(debugEnvironmentVariable.toLowerCase())) {
    return true;
  }

  return debugEnvironmentVariable ?? undefined;
};
