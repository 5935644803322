import type * as React from "react";
import { Colors } from "@blueprintjs/core";
import styled from "@emotion/styled";

// from blueprint example: https://github.com/palantir/blueprint/blob/6906ffce4482d9910cbe311ed9937a2c84e829b8/packages/docs-app/src/examples/select-examples/films.tsx#L208

function escapeRegExpChars(text: string) {
  return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}

const Highlighted = styled.strong`
  color: ${Colors.BLUE5};
`;

export function highlightText(text: string, query: string) {
  if (!text) {
    return "";
  }

  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter((word) => word.length > 0)
    .map(escapeRegExpChars);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join("|"), "gi");
  const tokens: React.ReactNode[] = [];
  while (true) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<Highlighted key={lastIndex}>{match[0]}</Highlighted>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
}
