import type { ENVIRONMENT_KEY } from "@citadel/cdx-environment";
import {
  CITADEL_ENV_STABLEDEV,
  CITADEL_ENV_PROD,
  CITADEL_ENV_SSA_PROD,
  getEnvironment,
} from "@citadel/cdx-environment";
import { logger } from "../logger";

export interface AuthenticationServerUrls {
  base: string;
  authz: string;
  token: string;
  jwks: string;
  user: string;
}
export interface AuthenticationServerConfig {
  clientId: string;
  url: AuthenticationServerUrls;
}

const configByEnv: Record<ENVIRONMENT_KEY, AuthenticationServerConfig | null> = {
  [CITADEL_ENV_STABLEDEV]: {
    clientId: "dev-playground-code",
    // when using short ttl for testing
    // clientId: "dev-playground-code-short-ttl",
    url: {
      base: "https://sso-dev.citadelgroup.com",
      authz: "https://sso-dev.citadelgroup.com/as/authorization.oauth2",
      token: "https://sso-dev.citadelgroup.com/as/token.oauth2",
      jwks: "https://sso-dev.citadelgroup.com/pf/JWKS",
      user: "https://sso-dev.citadelgroup.com/idp/userinfo.openid",
    },
  },
  [CITADEL_ENV_PROD]: {
    clientId: "prod-cdx-code",
    url: {
      base: "https://sso.citadelgroup.com",
      authz: "https://sso.citadelgroup.com/as/authorization.oauth2",
      token: "https://sso.citadelgroup.com/as/token.oauth2",
      jwks: "https://sso.citadelgroup.com/pf/JWKS",
      user: "https://sso.citadelgroup.com/idp/userinfo.openid",
    },
  },
  [CITADEL_ENV_SSA_PROD]: {
    clientId: "ssa-cdx-code",
    url: {
      base: "https://sso-ssa.citadelgroup.com",
      authz: "https://sso-ssa.citadelgroup.com/as/authorization.oauth2",
      token: "https://sso-ssa.citadelgroup.com/as/token.oauth2",
      jwks: "https://sso-ssa.citadelgroup.com/pf/JWKS",
      user: "https://sso-ssa.citadelgroup.com/idp/userinfo.openid",
    },
  },
};

/**
 * Get auth provider config based on current CITADEL_ENV
 */
function getConfig(): AuthenticationServerConfig {
  const { CITADEL_ENV } = getEnvironment();
  const config = configByEnv[CITADEL_ENV];

  if (!config) {
    const msg = `Authentication not supported in environment: ${CITADEL_ENV}`;
    logger.warn(msg);
    throw Error(msg);
  }

  return config;
}

export { getConfig };
