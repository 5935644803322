import styled from "@emotion/styled";
import { Spinner } from "@blueprintjs/core";
import type { MediaProps } from "../types/Media";
import { pluralText } from "../plural";
import { Editor } from "./Editor";

const Wrapper = styled.div<{ completed: boolean }>`
  width: 100%;
  margin-right: 5%;
  position: relative;

  p {
    display: ${(props) => (props.completed ? "block" : "none")};
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0.5em;
    margin-bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    pointer-events: none;
    color: #fff;
  }

  .bp3-dark & p {
    color: #000;
  }

  &:hover {
    p {
      display: block;
    }
  }
`;

const Placeholder = styled.div(({ width, height }: { width: number; height: number }) => ({
  position: "relative",
  maxWidth: "100%",
  maxHeight: "60vh",
  width: width + "px",
  aspectRatio: `${width}/${height}`,
  margin: "0 auto",
  display: "flex",
  alignContent: "center",
  justifyContent: "center",

  "& > h3": {
    display: "block",
    position: "absolute",
    bottom: "10%",
    width: "100%",
    textAlign: "center",
  },
}));

export function Preview({ report, mediaIndex, dropping, onRegionsChange, onRegionRemove, onEditorChange }: MediaProps) {
  const media = report.media?.[mediaIndex];
  if (!media) {
    const { clientWidth, clientHeight } = document.body;
    return (
      <Placeholder data-testid="screenshot-placeholder" width={clientWidth} height={clientHeight}>
        <Spinner />
        <h3>Taking Screenshot</h3>
      </Placeholder>
    );
  }

  if (!media.usable) {
    return null;
  }

  return (
    <Wrapper
      data-testid="media-image-wrapper"
      style={{ cursor: report.completed ? "not-allowed" : "auto" }}
      completed={report.completed}
    >
      <Editor
        report={report}
        mediaIndex={mediaIndex}
        dropping={dropping}
        onRegionsChange={onRegionsChange}
        onRegionRemove={onRegionRemove}
        onEditorChange={onEditorChange}
      />
      {media.annotations.length > 0 && !dropping ? (
        <p data-testid="annotation-info">{pluralText(media.annotations.length, "annotation", "annotations")}</p>
      ) : null}
    </Wrapper>
  );
}
