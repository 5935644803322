import { filter, flatMap, isFinite, isNil, map, replace } from "lodash";
import type {
  Assessment,
  AssessmentGroup,
  AssessmentGroupLookup,
  AssessmentItem,
  AssessmentItemCoords,
  CellMoveDirection,
} from "../types/assessment";
import { CELL_REF_REGEX_PARTS, CELL_REF_REGEX_WHOLE } from "../constants/grid";

const CAP_A = "A".charCodeAt(0);

export const GROUP_COORDS_DEFINED_ERROR = "group and coords must be defined and non-empty";

export const findItem = (group: AssessmentGroup, coords: AssessmentItemCoords): AssessmentItem | undefined => {
  if (group === undefined || !coords?.length) {
    throw GROUP_COORDS_DEFINED_ERROR;
  }

  const [headCoord, ...restCoords] = coords;
  if (!restCoords?.length) {
    return group.entries.find((item: any) => item?.column === headCoord);
  }
  // we have more than 1 coord, so we dig deeper into the groups
  const nextGroup = group.groups[headCoord];
  return nextGroup && findItem(nextGroup as AssessmentGroup, restCoords);
};

export const findItemInAssessment = (assessment: Assessment) => (coords: AssessmentItemCoords) => {
  const [headCoord, ...tailCoords] = coords;
  return findItem(assessment.groups[headCoord], tailCoords) as AssessmentItem;
};

export const findEmptyCells = (group: AssessmentGroup, coords: AssessmentItemCoords = []): AssessmentItemCoords[] => {
  return [
    ...map(
      filter(group?.entries, (entry) => isNil(entry.formula) || entry.formula === ""),
      (entry) => [...coords, entry.column]
    ),
    ...flatMap(group?.groups, (g, idx) => findEmptyCells(g as AssessmentGroup, [...coords, Number(idx)])),
  ];
};

export const findEmptyCellsInAssessment = (assessment: Assessment) => {
  return flatMap(assessment?.groups, (g, idx) => findEmptyCells(g, [idx]));
};

export const toAlpha = (coord: number) => {
  if (coord < 0 || coord > 25) {
    return undefined;
  }
  return String.fromCharCode(CAP_A + coord);
};

export const fromAlpha = (letter: string) => {
  const letterCap = letter.toUpperCase();
  if (letterCap < "A" || letterCap > "Z") {
    return undefined;
  }
  return letterCap.charCodeAt(0) - CAP_A;
};

export const getAssessmentLookup = (assessment: Assessment): AssessmentGroupLookup => {
  let nextLineNumber = 0;

  const toLineNumber = {} as Record<string, number>;
  const toCoords = {} as Record<number, AssessmentItemCoords>;

  const loop = (group: AssessmentGroup, coords: AssessmentItemCoords): void => {
    const isLine = !!(group as AssessmentGroup)?.entries?.length;
    const lineNumber = nextLineNumber;
    if (isLine) {
      nextLineNumber = nextLineNumber + 1;
      toLineNumber[coords.join(":")] = lineNumber;
      toCoords[lineNumber] = coords;
    }

    group?.groups?.forEach((g: any, i: any) => loop(g as AssessmentGroup, [...coords, i]));
  };

  loop(assessment as unknown as AssessmentGroup, []);

  return { toLineNumber, toCoords };
};

export const toCoordsString = (assessmentLookup: AssessmentGroupLookup) => {
  return (coords: AssessmentItemCoords) => {
    if ((coords?.length || 0) < 2) {
      return undefined;
    }
    const reverseCoords = [...coords].reverse();
    const [colCoord, ...reverseGroupCoords] = reverseCoords;
    const groupCoords = reverseGroupCoords.reverse();

    const lineNumber = assessmentLookup.toLineNumber[groupCoords.join(":")];
    if (isNil(lineNumber)) {
      return undefined;
    }

    return `${toAlpha(colCoord)}${lineNumber}`;
  };
};

export const toCoords = (
  assessment: Assessment,
  assessmentLookup: AssessmentGroupLookup = getAssessmentLookup(assessment)
) => {
  return (coords: string) => {
    const matches = coords.match(CELL_REF_REGEX_PARTS);
    if (!matches?.length) {
      return undefined;
    }
    // prettier-ignore
    const [/* fullMatch */, /* dollarSign */, colLetter, rowNumberStr] = matches;
    const rowNumber = parseInt(rowNumberStr);
    const colNumber = fromAlpha(colLetter);
    if (isNil(colNumber) || colNumber >= assessment.columns.length) {
      return undefined;
    }

    const groupCoords = assessmentLookup.toCoords[rowNumber];

    if (isNil(groupCoords)) {
      return undefined;
    }

    return [...groupCoords, colNumber] as AssessmentItemCoords;
  };
};

export const moveSelection = (
  assessment: Assessment,
  assessmentLookup: AssessmentGroupLookup = getAssessmentLookup(assessment)
) => {
  const moveInDirection = (
    coords: AssessmentItemCoords,
    direction: CellMoveDirection
  ): AssessmentItemCoords | undefined => {
    if ((coords?.length || 0) < 2) {
      return undefined;
    }

    const reverseCoords = [...coords].reverse();
    const [colCoord, ...reverseGroupCoords] = reverseCoords;
    const groupCoords = reverseGroupCoords.reverse();

    const lineNumber = assessmentLookup.toLineNumber[groupCoords.join(":")];
    if (isNil(lineNumber)) {
      return undefined;
    }

    let newLineCoords: AssessmentItemCoords = groupCoords;
    let newColumn: number = colCoord;
    switch (direction) {
      case "up":
        newLineCoords = assessmentLookup.toCoords[lineNumber - 1];
        break;
      case "right":
        newColumn = colCoord + 1;
        break;
      case "down":
        newLineCoords = assessmentLookup.toCoords[lineNumber + 1];
        break;
      case "left":
        newColumn = colCoord - 1;
        break;
      default:
        break;
    }

    if (!newLineCoords || !isFinite(newColumn) || newColumn < 0 || newColumn >= assessment.columns.length) {
      return undefined;
    }

    const newCoords = [...newLineCoords, newColumn];
    // if a cell is found, return the new coords
    if (findItemInAssessment(assessment)(newCoords)) {
      return newCoords;
    }
    // otherwise keep moving in the direction until a cell is found
    return moveInDirection(newCoords, direction);
  };

  const tryMoveInDirection = (
    coords: AssessmentItemCoords,
    direction: CellMoveDirection
  ): AssessmentItemCoords | undefined => {
    try {
      return moveInDirection(coords, direction) || coords;
    } catch {
      // if we hit an error, such as getting out of bounds, return the original coords
      return coords;
    }
  };

  return tryMoveInDirection;
};

export const translateReference = (cellRef: string, rowDiff: number, colDiff: number) => {
  const matches = cellRef.match(CELL_REF_REGEX_PARTS);
  if (!matches?.length) {
    return cellRef;
  }
  // prettier-ignore
  const [/* fullMatch */, dollarSign, colLetter, rowNumberStr] = matches;
  if (dollarSign) {
    return cellRef;
  }
  return `${String.fromCharCode(colLetter.charCodeAt(0) + colDiff)}${parseInt(rowNumberStr) + rowDiff}`;
};

export const translateFormula = (
  formula: string,
  srcCell: AssessmentItemCoords,
  destCell: AssessmentItemCoords,
  assessmentLookup: AssessmentGroupLookup
) => {
  const toCoordsStr = toCoordsString(assessmentLookup);
  const srcStr = toCoordsStr(srcCell);
  const destStr = toCoordsStr(destCell);

  if (!srcStr || !destStr) {
    return "";
  }

  const srcMatches = srcStr.match(CELL_REF_REGEX_PARTS);
  const destMatches = destStr.match(CELL_REF_REGEX_PARTS);
  if (!srcMatches?.length || !destMatches?.length) {
    return "";
  }
  // prettier-ignore
  const [/* fullMatch */, /* dollarSign */, srcColLetter, srcRowNumberStr] = srcMatches;
  // prettier-ignore
  const [/* fullMatch */, /* dollarSign */, destColLetter, destRowNumberStr] = destMatches;

  const colDiff = destColLetter.charCodeAt(0) - srcColLetter.charCodeAt(0);
  const rowDiff = parseInt(destRowNumberStr) - parseInt(srcRowNumberStr);

  return replace(formula, CELL_REF_REGEX_WHOLE, (cRef: string) => translateReference(cRef, rowDiff, colDiff));
};

const keyDirectionMap: Record<string, string> = {
  ArrowUp: "up",
  ArrowRight: "right",
  ArrowDown: "down",
  ArrowLeft: "left",
};

export const mapKeyToDirection = (key: string) => keyDirectionMap[key];
