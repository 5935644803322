import type { ParsedToken, RawToken } from "./types";

export const isTokenExpired = (token: ParsedToken | null): boolean => {
  return !token || Date.now() > token.expirationInMs;
};

export const parseJwtTokenInFull = (rawToken: RawToken | null): any => {
  if (!rawToken) {
    return null;
  }
  try {
    const parsed = JSON.parse(atob(rawToken.split(".")[1]));
    return {
      ...parsed,
      expirationInMs: parsed.exp * 1000,
      token: rawToken,
    };
  } catch (e) {
    return null;
  }
};

export const parseJwtToken = (rawToken: RawToken | null): ParsedToken | null => {
  const parsed = parseJwtTokenInFull(rawToken);
  return isTokenExpired(parsed) ? null : parsed;
};
