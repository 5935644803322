import type { ChangeEvent, KeyboardEvent } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import * as React from "react";
import { InputGroup, Intent } from "@blueprintjs/core";
import classnames from "classnames";
import { isEqual, isUndefined } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { v4 as uuidV4 } from "uuid";
import type {
  AssessmentItem,
  AdminAssessmentItem,
  AssessmentItemCoords,
  AssessmentItemFormatter,
} from "../../types/assessment";
import { CELL_REF_REGEX_END, CELL_REF_REGEX_WHOLE } from "../../constants/grid";
import { mapKeyToDirection, toCoordsString } from "../../utils/assessment";
import { calcEquation, parseCellReferences } from "../../utils/equations";
import { formatItem } from "../../utils/value-formatters";
import {
  getAssessment,
  getAssessmentGroupLookup,
  getCellReference,
} from "../../stores/assessment/assessment-selectors";
import { assessmentActions } from "../../stores/assessment/assessment-actions";
import { BDGridSolutionTooltip } from "./bd-grid-solution-tooltip";
import classes from "./bd-grid.less";

export type BDGridItemProps = {
  item: AssessmentItem;
  coords: AssessmentItemCoords;
  formatter?: AssessmentItemFormatter;
  precision?: number;
  selected: boolean;
  editing: boolean;
  focused: boolean;
  referencedIndex?: number;
  pasteable: boolean;
  solutions?: AdminAssessmentItem["solutions"];
};

const UnmemoedBDGridItem = ({
  item: { id, formula, editable, scratch },
  coords,
  formatter = "numeric",
  precision = 0,
  selected,
  editing,
  focused,
  referencedIndex,
  pasteable,
  solutions,
}: BDGridItemProps) => {
  const [value, setValue] = useState(null as number | null | undefined);
  const [error, setError] = useState("");
  const [localFormula, setLocalFormula] = useState(formula ?? "");
  const tdRef = useRef(null as HTMLTableDataCellElement | null);
  const [inputRef, setInputRef] = useState(null as HTMLInputElement | null);
  const { token } = useParams<{ token: string }>();

  const assessment = useSelector(getAssessment);
  const assessmentGroupLookup = useSelector(getAssessmentGroupLookup);
  const cellReference = useSelector(getCellReference);

  const dispatch = useDispatch();

  useEffect(() => {
    if (inputRef && localFormula !== inputRef.value) {
      inputRef.value = localFormula;
    }
  }, [inputRef, localFormula]);

  useEffect(() => {
    setLocalFormula(formula ?? "");
  }, [formula, setLocalFormula]);

  useEffect(() => {
    if (value === null || !editable || scratch) {
      return;
    }

    dispatch({
      type: assessmentActions.QUEUE_UPDATE_BATCH,
      payload: {
        assessmentEntryId: parseInt(id),
        timestamp: Date.now(),
        uniqueId: uuidV4(),
        // using || instead of ?? to account for NaN values
        newValue: value === Infinity ? 0 : value || 0,
        newFormula: formula ?? "",
        token,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, formula]);

  // focus
  useEffect(() => {
    if (editing) {
      inputRef?.focus();
    } else if (focused) {
      tdRef.current?.focus();
    }
  }, [inputRef, editing, focused]);

  // ensure scroll into view
  useEffect(() => {
    if (selected) {
      tdRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [selected]);

  // consume the cell refernce in the store if we are the selected item
  useEffect(() => {
    if (!editing || !inputRef || !cellReference) {
      return;
    }

    // remove cell reference if it is self
    if (isEqual(cellReference, coords)) {
      dispatch({ type: assessmentActions.SET_CELL_REFERENCE, payload: undefined });
      return;
    }

    // otherwise, consume the cell reference
    // if nothing is selected, try to get Cell Reference in front of cursor
    let selStart = inputRef.selectionStart ?? 0;
    const selEnd = inputRef.selectionEnd ?? 0;

    if (selStart === selEnd) {
      const preSel = inputRef.value.slice(0, selStart);
      const matches = preSel.match(CELL_REF_REGEX_END);
      if (matches?.length) {
        // prettier-ignore
        const [/* fullMatch */, prevCellRef] = matches;
        selStart = selStart - prevCellRef.length;
      }
    }

    inputRef.setRangeText(
      toCoordsString(assessmentGroupLookup)(cellReference) ?? "",
      selStart,
      selEnd as number,
      "end"
    );
    inputRef.focus();
    setLocalFormula(inputRef.value);
    dispatch({ type: assessmentActions.SET_CELL_REFERENCE, payload: undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing, inputRef, cellReference, assessment, dispatch]);

  // update the value
  // TODO: We are updating EVERY cell when the assessment changes (which is at every change)
  useEffect(() => {
    if (!editing) {
      try {
        const calcedValue = calcEquation(coords, assessment, assessmentGroupLookup);
        setError("");
        setValue(calcedValue);
      } catch (err) {
        setError((err as Error).toString());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing, assessment]);

  useEffect(() => {
    if (editing) {
      const cells = parseCellReferences(assessment, assessmentGroupLookup)(localFormula);
      dispatch({ type: assessmentActions.SET_REFERENCED_CELLS, payload: cells });
    }
  }, [editing, localFormula, dispatch, assessment, assessmentGroupLookup]);

  const selectSelf = useCallback(
    (multiSelect: boolean) => {
      dispatch({ type: assessmentActions.SET_SELECTED_CELL, payload: { cell: coords, multiSelect } });
    },
    [coords, dispatch]
  );

  const setEditing = useCallback(
    // TODO: CTOTECH-2046 Fix this the next time this file is edited.
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (editing: boolean) => {
      dispatch({ type: assessmentActions.SET_EDITING_CELL, payload: editing ? coords : undefined });
      selectSelf(false);
    },
    [coords, dispatch, selectSelf]
  );

  const handleClick = useCallback(
    (evt: React.MouseEvent) => {
      // stop default mouse click behavior
      evt.preventDefault();
      evt.stopPropagation();

      // set the selected cell
      selectSelf(evt.shiftKey);
    },
    [selectSelf]
  );

  const handleDoubleClick = useCallback(
    (evt: React.MouseEvent) => {
      // stop default mouse click behavior
      evt.preventDefault();
      evt.stopPropagation();

      if (!editing && editable) {
        setEditing(true);
      }
    },
    [editable, editing, setEditing]
  );

  const handleKeyDown = useCallback(
    (evt: React.KeyboardEvent) => {
      if (!focused) {
        return;
      }

      const isAlphaNum = !evt.metaKey && !evt.altKey && !evt.ctrlKey && /^[a-z0-9]$/i.test(evt.key);

      if (
        evt.key === "Enter" ||
        evt.key === "F2" ||
        evt.key === "=" ||
        evt.key === "+" ||
        evt.key === "-" ||
        isAlphaNum
      ) {
        setEditing(true);
      }
      if (evt.key === "=" || evt.key === "+" || evt.key === "-" || isAlphaNum) {
        // prevent the same key from triggering twice, stop the default and propagation of the evt
        evt.preventDefault();
        evt.stopPropagation();
        setLocalFormula(evt.key);
      }
    },
    [focused, setEditing, setLocalFormula]
  );

  const handleSubmit = useCallback(() => {
    dispatch({
      type: assessmentActions.UPDATE_VALUES,
      payload: [{ coords, formula: localFormula }],
    });
    setEditing(false);
  }, [coords, dispatch, localFormula, setEditing]);

  const hasReferences = !!formula?.match(CELL_REF_REGEX_WHOLE);

  const hasSolutions = !!solutions && solutions.length > 0;
  let isCorrect: boolean = !!solutions?.some((solution) => solution.value.toFixed(4) === value?.toFixed(4));

  const cell =
    editing || error ? (
      <InputGroup
        inputRef={setInputRef}
        intent={error ? Intent.DANGER : Intent.PRIMARY}
        defaultValue={formula ?? ""}
        onChange={(evt: ChangeEvent<HTMLInputElement>) => {
          setLocalFormula(evt.target.value);
        }}
        onFocus={() => setEditing(true)}
        onKeyDown={(evt: KeyboardEvent<HTMLInputElement>) => {
          // stop propagation of key down events
          evt.stopPropagation();

          const direction = mapKeyToDirection(evt.key);
          const key = evt.key.toLowerCase();
          if (key === "enter") {
            handleSubmit();
          } else if (key === "escape") {
            setLocalFormula(formula ?? "");
            setEditing(false);
          } else if (direction) {
            // if we received a direction, don't move the cursor around,
            // instead, allow the bd - grid to handle the cell selection movement
            evt.preventDefault();
          } else {
            selectSelf(evt.shiftKey);
          }
        }}
      />
    ) : (
      <div className={classes.innerCell}>
        {hasSolutions && <BDGridSolutionTooltip correct={isCorrect} solutions={solutions} />}
        {formatItem(value, formatter, precision)}
      </div>
    );

  return (
    <td
      className={classnames({
        [classes.referenceable]: true,
        [classes.selected]: selected,
        [classes.editable]: editable,
        [classes.error]: error,
        [classes.pasteable]: pasteable,
        [classes.hasReferences]: hasReferences,
        [classes.correct]: hasSolutions && isCorrect,
        [classes.incorrect]: hasSolutions && !isCorrect,
      })}
      ref={tdRef}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      onKeyDown={handleKeyDown}
      tabIndex={-1}
      {...(!isUndefined(referencedIndex) && referencedIndex >= 0 && { "data-referenced": (referencedIndex % 10) + 1 })} // mod 10 to loop through the limited data-referenced styles defined in the less file
    >
      {cell}
    </td>
  );
};

export const BDGridItem = React.memo(UnmemoedBDGridItem, isEqual);
