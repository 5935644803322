import { useState } from "react";
import type * as React from "react";
import { ButtonGroup } from "@blueprintjs/core";
import { TooltipButton } from "../tooltip-button";
import classes from "./numeric-dimension.module.less";

export type NumericOption = {
  /**
   * Value of the option, e.g., "N/A", "1", "2" etc.
   * This value will also be rendered as the label of option.
   */
  value: string;
  /**
   * Description of the option.
   * @example "Exceptional - Showing next level capabilities. Expected to be in the top 5% of similar talent level at Citadel"
   */
  description?: string;
  /** Background color of the option. */
  color?: React.CSSProperties["backgroundColor"];
};

const DEFAULT_OPTIONS = Object.freeze<NumericOption[]>([
  {
    value: "1",
    color: "#e08080",
    description: "Poor - Expected to be a below average performer in the industry overall",
  },
  {
    value: "2",
    color: "#ffdf80",
    description: "Average - Expected to be an average performer in the industry overall",
  },
  {
    value: "3",
    color: "#bbe9d4",
    description: "Good - Expected to be an average performer amonst their peers at Citadel",
  },
  {
    value: "4",
    color: "#80d8a8",
    description:
      "Strong - Impressive, even for a Citadel team member. Expected to be in the 25% of similar talent level at Citadel",
  },
  {
    value: "5",
    color: "#80c080",
    description:
      "Exceptional - Showing next level capabilities. Expected to be in the top 5% of similar talent level at Citadel",
  },
]);

interface NumericDimensionProps {
  /** If true, component will be disabled and unable to be edited. Default is false. */
  disabled?: boolean;
  /** Callback when the user changes their rating. */
  onChange: (value: string) => void;
  value: string | null | undefined;
  options?: readonly NumericOption[];
}

/**
 * Controlled component rendering a numeric rating scale,
 * e.g., 1 for Poor, 2 for OK, 3 for Good, 4 for Great, 5 for Exceptional.
 */
export const NumericDimension = ({
  disabled = false,
  onChange,
  value,
  options = DEFAULT_OPTIONS,
}: NumericDimensionProps): JSX.Element => {
  const [hoverIndex, setHoverIndex] = useState<number | undefined>();

  let activeIndex: number | undefined = options.findIndex((option) => option.value === value);
  activeIndex = hoverIndex !== undefined ? hoverIndex : activeIndex !== -1 ? activeIndex : undefined;

  const halfwayIndex = Math.ceil((options.length - 1) / 2);

  return (
    <ButtonGroup className={classes.numericDimension}>
      {options.map((option, index) => {
        let backgroundColor: React.CSSProperties["backgroundColor"] | undefined = undefined;
        if (activeIndex !== undefined && index <= activeIndex) {
          backgroundColor = options[activeIndex].color;
        }

        return (
          <TooltipButton
            key={option.value}
            className={classes.scoreButton}
            onClick={() => {
              if (option.value !== value) {
                onChange(option.value);
              }
            }}
            disabled={disabled}
            onMouseEnter={() => setHoverIndex(index)}
            onMouseLeave={() => setHoverIndex(undefined)}
            style={{
              backgroundColor,
            }}
            content={option.description && <div dangerouslySetInnerHTML={{ __html: option.description }} />}
            placement={halfwayIndex === index ? "bottom" : index < halfwayIndex ? "bottom-start" : "bottom-end"}
          >
            {option.value}
          </TooltipButton>
        );
      })}
    </ButtonGroup>
  );
};
