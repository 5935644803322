import styled from "@emotion/styled";
import type { ChannelDefinitionMap } from "../../utils/channelDefinition";

export interface ChannelDefinitionsCssVariablesProps {
  themeMode: "light" | "dark";
  activeChannel: string;
  channelMap: ChannelDefinitionMap;
}

export const ChannelDefinitionsCssVariables = styled.div(
  ({ activeChannel, channelMap, themeMode }: ChannelDefinitionsCssVariablesProps) => `
  display: contents;
  --cdx-channel-color: ${channelMap[activeChannel].color[themeMode]};

  ${Object.entries(channelMap).map(
    ([
      channel,
      {
        color: { [themeMode]: color },
      },
    ]) => `
    --cdx-channel--${channel}: ${color};
    --cdx-ui-state-channel-${channel}: ${color};
  `
  ).join(`
  `)}
`
);
