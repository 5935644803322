import type { ReportIssueConfig } from "../types/config";
import { ReportIssueOption } from "../types/config";
import type { Log, PublishableLog } from "../types/logs";
import type { PublishableReport, Report } from "../types/Report";

// This singleton is used by all DataAdapters.
// It stores all errors to upload data within a browsing session, and is
// leveraged by the Reporting Issue Configuration View to assist developers
// setting up the Error Reporting system.
export const dataAdapterErrors: AdapterStep[] = [];
export interface AdapterStep {
  timestamp: Date;
  success: boolean;
  description?: string;
  error?: Error;
  fields?: { [key: string]: string | null };
}

export class DataAdapterBase {
  protected config: ReportIssueConfig;
  protected report: Report;
  public static service: string = "unknown";

  protected operations: {
    description: string;
    method: (previousOperationValue: AdapterStep | null) => Promise<AdapterStep> | AdapterStep;
  }[] = [];

  get steps() {
    return this.operations.map((item) => item.description);
  }

  constructor(config: ReportIssueConfig, report: Report) {
    this.config = config;
    this.report = report;
  }

  /**
   * Is there content in the log?
   * @param log Log to examine.
   * @returns {boolean}
   */
  static #hasValue(log: Log): boolean {
    for (const content in log.content) {
      return true;
    }
    return false;
  }

  /**
   * Find and format logs to usable values for output by adapters.
   * @param log
   * @returns {PublishableLog[]}
   */
  logsWithStringifiedValue(): PublishableLog[] {
    const validValues: PublishableLog[] = [];

    if (this.config.logs === ReportIssueOption.DISALLOW) {
      return validValues;
    }

    for (const log of this.report.logs) {
      if (!DataAdapterBase.#hasValue(log)) {
        continue;
      }

      try {
        const stringified = JSON.stringify(log.content);
        validValues.push({
          ...log,
          content: stringified,
        });
      } catch (e) {
        // Ignore errors and exclude the logs from report.
      }
    }

    return validValues;
  }

  get publishableReport(): PublishableReport {
    const returnable: any = structuredClone(this.report);
    returnable.logs = this.logsWithStringifiedValue();
    return returnable as PublishableReport;
  }

  async *upload(): AsyncGenerator<AdapterStep, AdapterStep, unknown> {
    let previousOperation: AdapterStep | null = null;
    for await (const operation of this.operations) {
      const operationExecuted: AdapterStep = await operation.method.apply(this, [previousOperation]);

      if (!operationExecuted.success) {
        dataAdapterErrors.push(operationExecuted);
      }
      yield operationExecuted;

      // If an operation failed, then all subsequent operations are cancelled.
      // This means we should return from the function with the failure case.
      if (!operationExecuted.success) {
        return {
          timestamp: new Date(),
          success: false,
        };
      }

      previousOperation = operationExecuted;
    }

    return {
      timestamp: new Date(),
      success: false,
    };
  }
}
