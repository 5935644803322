import { NonIdealState } from "@blueprintjs/core";
import { useTitle } from "@citadel/cdx-lib-react";
import type { RequiredKey } from "../utils/RequiredKey";

export type InsufficientChannelDataProps = {
  missingLinks: RequiredKey[];
  presentLinks: RequiredKey[];
};

const requiredKeysSummary = (requiredKeys: RequiredKey[]) => requiredKeys.map((rq) => rq.label).join(", ");

const LinksReport = ({ links, intro }: { links: RequiredKey[]; intro: string }) => {
  if (links.length === 0) {
    return null;
  }

  return (
    <p>
      {intro}: {requiredKeysSummary(links)}
    </p>
  );
};

export const InsufficientChannelData = ({ missingLinks, presentLinks }: InsufficientChannelDataProps) => {
  const [widgetTitle] = useTitle();

  const title = (
    <>
      <LinksReport links={presentLinks} intro="We have the required linking from" />
      <LinksReport
        links={missingLinks}
        intro={presentLinks.length ? `But ${widgetTitle} also requires` : `To view ${widgetTitle} please select from`}
      />
    </>
  );

  return <NonIdealState title={title} />;
};
