import ashlerWhiteIcon from "./ashler-white.png";
import ashlerIcon from "./ashler.png";
import citadelGlobalEquitiesWhiteIcon from "./citadel-global-equities-white.png";
import citadelGlobalEquitiesIcon from "./citadel-global-equities.png";
import citadelWhiteIcon from "./citadel-white.png";
import citadelIcon from "./citadel.png";
import surveyorWhiteIcon from "./surveyor-white.png";
import surveyorIcon from "./surveyor.png";

export const Icons = {
  citadel: {
    light: citadelIcon,
    dark: citadelWhiteIcon,
  },
  citadelGlobalEquities: {
    light: citadelGlobalEquitiesIcon,
    dark: citadelGlobalEquitiesWhiteIcon,
  },
  ashler: {
    light: ashlerIcon,
    dark: ashlerWhiteIcon,
  },
  surveyor: {
    light: surveyorIcon,
    dark: surveyorWhiteIcon,
  },
};
