import { Intent } from "@blueprintjs/core";
import { Icons } from "../../../images";
import { globalActions, useGlobal } from "../../../stores/global/global-store";
import { replacePlaceholder } from "../../../utils/placeholders";
import { BaseDialog } from "../base-dialog";
import data from "./disclaimer.json";
import classes from "./disclaimer-dialog.less";

export const DisclaimerDialog = () => {
  const {
    state: { isDisclaimerOpen, isDisclaimerAcknowledged },
    dispatch,
  } = useGlobal();

  const {
    texts: { sections },
  } = data;

  const closeDialog = (accept: boolean) => {
    if (accept) {
      dispatch({ type: globalActions.SET_IS_DISCLAIMER_ACKNOWLEDGED, payload: true });
      dispatch({ type: globalActions.SET_IS_DISCLAIMER_OPEN, payload: false });
    } else {
      dispatch({ type: globalActions.SET_IS_DISCLAIMER_ACKNOWLEDGED, payload: false });
    }
  };

  const renderSection = ({ title, text }: { title: JSX.Element | string; text: JSX.Element | string }) => (
    <>
      <b>{title}.</b> {text}
      <br />
      <br />
    </>
  );

  const prepareComplianceText = ({ text, acknowledges }: { text: string; acknowledges: string[] }) =>
    replacePlaceholder(
      text,
      "acknowledges",
      <>
        <br />
        <div className={classes.acknowledges}>
          <b>
            <ol>
              {acknowledges.map((acknowledge: string, idx: number) => (
                <li key={idx}>{acknowledge}</li>
              ))}
            </ol>
          </b>
        </div>
      </>
    );

  const preparePrivacyInformationText = ({ text, url }: { text: string; url: string }) =>
    replacePlaceholder(text, "url", <a href={url}>{url}</a>);

  const logo = Icons.citadel.dark;

  return (
    <BaseDialog
      isOpen={isDisclaimerOpen}
      className={classes.dialog}
      portalClassName={classes.disclaimerPortal}
      icon={<img className={classes.logo} src={logo} />}
      title="Legal Disclaimer"
      showCancelButton={!isDisclaimerAcknowledged}
      cancelButtonText="Reject"
      cancelButtonIntent={Intent.DANGER}
      onClose={() => closeDialog(false)}
      rightButtonText={isDisclaimerAcknowledged ? "Close" : "Acknowledge"}
      rightButtonIntent={isDisclaimerAcknowledged ? Intent.PRIMARY : Intent.SUCCESS}
      rightButtonOnClick={() => closeDialog(true)}
      large
    >
      {data.texts.title}
      <br />
      <br />
      {renderSection(sections.termsOfUse)}
      {renderSection({
        ...sections.compliance,
        text: prepareComplianceText(sections.compliance),
      })}
      {renderSection(sections.useAndSharingOfInformation)}
      {renderSection(sections.propietaryInformation)}
      {renderSection(sections.passwordProtection)}
      {renderSection(sections.rightToModify)}
      {renderSection(sections.jurisdictionalMatters)}
      {renderSection({
        ...sections.privacyInformation,
        text: preparePrivacyInformationText(sections.privacyInformation),
      })}
      {renderSection(sections.disclaimer)}
      <br />
    </BaseDialog>
  );
};
