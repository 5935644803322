import type { RequestHandler } from "@apollo/client";
import { ApolloLink, Observable } from "@apollo/client";
import { createApolloLocalGatewayClient } from "@citadel/cdx-provider-apollo";
import { services } from "../_generated/services";

const skipAuth: RequestHandler = (operation, forward) => {
  return new Observable((observer) => {
    let handle: any;

    Promise.resolve(operation)
      .then(() => {
        handle = forward?.(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) {
        handle.unsubscribe();
      }
    };
  });
};

export const client = createApolloLocalGatewayClient({
  authLink: new ApolloLink(skipAuth),
  requiresAuthenticate: false,
  services,
});
