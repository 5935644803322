import { createContext, useContext } from "react";
import * as React from "react";
import { useLocalStorage } from "@citadel/pse-lib-ui";
import type { GlobalStore, GlobalStoreState } from "../../types/stores/global-store";
import { middleware } from "./global-middleware";
import { stateReducer } from "./global-reducer";

export const globalActions = {
  SET_IS_DISCLAIMER_OPEN: "SET_IS_DISCLAIMER_OPEN",
  SET_IS_DISCLAIMER_ACKNOWLEDGED: "SET_IS_DISCLAIMER_ACKNOWLEDGED",
  SET_TUTORIAL_ASSESSMENTS: "SET_TUTORIAL_ASSESSMENTS",
  SET_ASSESSMENT: "SET_ASSESSMENT",
  SET_IS_SUBMISSION_DIALOG_OPEN: "SET_IS_SUBMISSION_DIALOG_OPEN",
  SET_IS_SAVING: "SET_IS_SAVING",
  SET_SAVE_ERROR: "SET_SAVE_ERRROR",
  SET_IS_START_ASSESSMENT_DIALOG_OPEN: "SET_IS_START_ASSESSMENT_DIALOG_OPEN",
};

const defaultState: GlobalStoreState = {
  isDisclaimerOpen: true,
  isDisclaimerAcknowledged: false,
  tutorialAssessments: [],
  assessment: undefined,
  isSubmissionDialogOpen: false,
  isSaving: false,
  saveError: undefined,
  isStartAssessmentDialogOpen: false,
};

// the type definitions are not lining up as expected
export const globalStore: React.Context<GlobalStore> = createContext(
  defaultState
) as unknown as React.Context<GlobalStore>;
const { Provider } = globalStore;

export type GlobalStateProviderProps = {
  children: React.ReactNode;
  initialState?: GlobalStoreState;
};

export const GlobalStateProvider = ({ initialState, children }: GlobalStateProviderProps) => {
  const [isDisclaimerAcknowledged, setIsDisclaimerAcknowledged] = useLocalStorage("isDisclaimerAcknowledged", false);

  const localStorageState: GlobalStoreState = {
    isDisclaimerAcknowledged,
    isDisclaimerOpen: !isDisclaimerAcknowledged,
  } as GlobalStoreState;

  const [state, dispatch_] = React.useReducer(stateReducer, { ...defaultState, ...localStorageState, ...initialState });

  const dispatch = middleware(setIsDisclaimerAcknowledged)(state, dispatch_);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export const useGlobal = () => useContext(globalStore);
