import type { Logger, RawLogOptions, RawLogger, ChildFromLoggerOptions } from "./Logger";
import { LogLevel } from "./Logger";
import { concatContexts } from "./concatContext";

export interface ChildLoggerOptions {
  parentLogger: RawLogger;
  context?: string;
}

/**
 * Child logger that delegates all logging to its parent.
 */
export class ChildLogger implements Logger {
  private _parentLogger: RawLogger;
  private _context: string;

  constructor({ parentLogger, context = "" }: ChildLoggerOptions) {
    this._parentLogger = parentLogger;
    this._context = context;
  }

  raw(options: RawLogOptions) {
    this._parentLogger.raw({ ...options, context: concatContexts([this._context, options.context]) });
  }

  trace(...data: any[]) {
    this.raw({ level: LogLevel.TRACE, data });
  }

  debug(...data: any[]) {
    this.raw({ level: LogLevel.DEBUG, data });
  }

  log(...data: any[]) {
    this.info(...data);
  }

  info(...data: any[]) {
    this.raw({ level: LogLevel.INFO, data });
  }

  warn(...data: any[]) {
    this.raw({ level: LogLevel.WARN, data });
  }

  error(...data: any[]) {
    this.raw({ level: LogLevel.ERROR, data });
  }

  fatal(...data: any[]) {
    this.raw({ level: LogLevel.FATAL, data });
  }

  child({ context }: ChildFromLoggerOptions = {}): Logger {
    return new ChildLogger({ parentLogger: this, context });
  }
}
