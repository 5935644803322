import type { UiReportIssueConfig, UiReportIssueEmailConfig } from "@citadel/cdx-config";
import type { RequireAtLeastOne } from "@citadel/cdx-lib-tsconfig";
import type { PublishableReport } from "./Report";

export const enum ReportIssueOption {
  ALLOW = 0,
  DISALLOW = 1,
  REQUIRE = 2,
}

export interface IReportIssueEmailConfig {
  subject?: (emailConfig: ReportIssueEmailConfig, report: PublishableReport) => string;
  body: (emailConfig: ReportIssueEmailConfig, report: PublishableReport) => string;
}
interface ReportIssueEmailConfig extends UiReportIssueEmailConfig, IReportIssueEmailConfig {}

interface IReportIssueConfig extends UiReportIssueConfig {
  Email?: RequireAtLeastOne<ReportIssueEmailConfig, "toUserNames" | "toGroupNames">;
  screenshot: ReportIssueOption;
  logs: ReportIssueOption;
}
export type ReportIssueConfig = RequireAtLeastOne<IReportIssueConfig, "Slack" | "Email">;
