import { Popover2 } from "@blueprintjs/popover2";
import styled from "@emotion/styled";
import {
  useContextBrokerInDashboardGatekeeper,
  ChannelSelector as ContextBrokerChannelSelector,
} from "@citadel/cdx-mainframe";
import { GlobalStyle } from "../../styles";
import { ChannelTrigger } from "./ChannelTrigger";
import { ChannelList } from "./ChannelList";

const MenuContainer = styled.div`
  min-width: 10em;
  padding: 0.2em;
`;

function ChannelMenu() {
  return (
    <MenuContainer>
      <ChannelList />
    </MenuContainer>
  );
}

export const ChannelSelector = () => {
  const isContextBrokerEnabled = useContextBrokerInDashboardGatekeeper();
  if (isContextBrokerEnabled) {
    return <ContextBrokerChannelSelector />;
  }

  return (
    <div>
      <GlobalStyle />
      <Popover2 content={<ChannelMenu />} position="bottom">
        <ChannelTrigger />
      </Popover2>
    </div>
  );
};
