import { useContext, useRef } from "react";
import * as React from "react";
import { Toaster } from "@blueprintjs/core";

const ToasterContext = React.createContext<React.RefObject<Toaster> | null>(null);

/**
 * Wrapper component used to provide a value to be consumed by the `useToaster` hook.
 * @example
 * <ToastApp>
 *  <YourComponentWithHook />
 * </ToastApp>
 * @see https://blueprintjs.com/docs/#core/components/toast.toaster
 */
export const ToastApp = ({ children }: React.PropsWithChildren<{}>): JSX.Element => {
  const toasterRef = useRef<Toaster>(null);

  return (
    <ToasterContext.Provider value={toasterRef}>
      {/* TODO: Add support for IToasterProps if configuration of Toaster behavior is required. */}
      <Toaster ref={toasterRef} />
      {children}
    </ToasterContext.Provider>
  );
};

/**
 * Hook used to source a `Toaster` from the current context value.\
 * This hook must be called from a component that is a descendant of a `ToastApp` component.
 * @example
 * <ToastApp>
 *  <YourComponentWithHook />
 * </ToastApp>
 * @see https://blueprintjs.com/docs/#core/components/toast.toaster
 * @see https://reactjs.org/docs/hooks-reference.html#usecontext
 */
export const useToaster = () => {
  const toaster = useContext(ToasterContext);
  if (!toaster) {
    throw new Error(
      "Invalid useToaster hook call. useToaster can only be called inside the body of a function component that is a descendant of a <ToastApp> component."
    );
  }
  return toaster;
};
