export const stripIntrospectionDefaultValues = (input: any): any => {
  if (input && typeof input === "object") {
    if (Array.isArray(input)) {
      return input.map((item) => stripIntrospectionDefaultValues(item));
    } else {
      if (`${input.name}`.startsWith("_")) {
        return input;
      }
      const entries = Object.entries(input).filter(([k, v]) => k !== "defaultValue" || v === null);
      return Object.fromEntries(entries.map(([k, v]) => [k, stripIntrospectionDefaultValues(v)]));
    }
  }

  return input;
};

/**
 * @param data the schema data, undefined is an acceptable typing
 * should be introspection, graphql, or nil
 */
export const validateSchemaData = (data?: any) => {
  if (!data) {
    return;
  }
};
