export type DebugOverrideSerialized = boolean | string | DebugOverride;

export interface DebugOverride {
  allowAll: boolean;
  matches: RegExp[];
  skips: RegExp[];
}

export const parseDebugOverride = (input: DebugOverrideSerialized): DebugOverride => {
  if (typeof input === "object") {
    return input;
  }

  if (typeof input === "boolean") {
    return {
      allowAll: input,
      matches: [],
      skips: [],
    };
  }

  const matches: RegExp[] = [];
  const skips: RegExp[] = [];

  for (const pattern of input.split(/[\s,]+/)) {
    if (!pattern) {
      continue;
    }

    const namespace = pattern.replace(/\*/g, ".*?");

    try {
      if (namespace[0] === "-") {
        skips.push(new RegExp("^" + namespace.substr(1) + "$"));
      } else {
        matches.push(new RegExp("^" + namespace + "$"));
      }
    } catch (error: any) {
      console.warn(`Unable to parse debug pattern ${pattern}: ${error?.message ?? error}`);
    }
  }

  return {
    allowAll: false,
    matches,
    skips,
  };
};

export const contextMatchesDebugOverride = (context: string, debugOverride: DebugOverride) => {
  if (debugOverride.allowAll) {
    return true;
  }

  for (const skip of debugOverride.skips) {
    if (skip.test(context)) {
      return false;
    }
  }

  for (const match of debugOverride.matches) {
    if (match.test(context)) {
      return true;
    }
  }

  return false;
};
