import { useContext } from "react";
import { AuthenticationSessionContext } from "../react/context/AuthenticationSessionContext";
import type { AuthenticationSession } from "./AuthenticationSession";

/**
 * Use this to get the authentication session from React context. The session will be undefined when the
 * {@link AuthenticationProvider} isn't somewhere in the component tree.
 *
 * @remarks
 *
 * This hook has been introduced to facilitate the decom of ga security. It should only be used in contexts where we
 * want to temporarily enable the old auth when the new one hasn't been specifically setup in an app.
 *
 * For this reason, **dont' use this in app code**.
 *
 * @deprecated Will be removed once GA Security has been retired
 *
 * @internal
 */
export function _useAuthenticationSession(): AuthenticationSession | undefined {
  return useContext(AuthenticationSessionContext);
}
