import { useEffect } from "react";
import { Intent, Spinner, Divider } from "@blueprintjs/core";
import { addError } from "@citadel/cdx-provider-user-monitoring";
import { useAssessmentQuery } from "../../_generated/graphql";
import type { Assessment } from "../../types/assessment";
import { BDGrid } from "../bd-grid";
import { HelpButton } from "../buttons/help-button";
import { StartAssessmentButton } from "../buttons/start-assessment-button/start-assessment-button";
import { DisclaimerDialog } from "../dialogs/disclaimer-dialog";
import { StartAssessmentDialog } from "../dialogs/start-assessment-dialog";
import { HeaderBar } from "../header-bar";
import type { InfoSectionData } from "../info-section";
import { InfoSection, InfoSubsection } from "../info-section";
import classes from "./tutorial-view.less";
import instructions from "./tutorial-instructions.json";

export const TutorialView = () => {
  const { data, loading, error } = useAssessmentQuery({
    variables: {
      token: "087113D2-11CE-EB11-8A26-005056941A01", // Hardcoded tutorial assessment
    },
  });

  useEffect(() => addError("Tutorial View error", { message: error?.message }), [error]);

  return (
    <>
      <HeaderBar
        label="Financial Concepts Assessment Test"
        rightElement={
          <>
            <StartAssessmentButton />
            <HelpButton />
          </>
        }
      />
      <div className={classes.container}>
        <p className={classes.welcomeText}>Welcome to the Citadel FCAT (Financial Concepts Assessment Test).</p>
        {loading ? (
          <Spinner intent={Intent.PRIMARY} />
        ) : (
          <>
            {(instructions as InfoSectionData[]).map(({ header, sections }) => (
              <InfoSection key={header} header={header}>
                {...sections.map(({ subheader, text, examples }) => (
                  <InfoSubsection key={subheader} subheader={subheader} examples={examples}>
                    {text}
                  </InfoSubsection>
                ))}
              </InfoSection>
            ))}
            <Divider />
            <BDGrid
              disableUpdates={true}
              assessment={data?.assessmentByToken as Assessment}
              scratchLines={["numeric", "accountingDollar", "accountingEuro", "percentage"]}
            />
          </>
        )}
      </div>
      <DisclaimerDialog />
      <StartAssessmentDialog />
    </>
  );
};
