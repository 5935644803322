import { createContext } from "react";
import type { AuthenticationSession } from "../../api/AuthenticationSession";

/**
 * Use this inside React to detemine the authentication session.
 *
 * @privateRemarks
 *
 * We dont use {@link getAuthenticationSession} to set the default here because some folks may still be using the old
 * auth. This context serves as a proxy for enabling this session based authentication in their apps.
 */
export const AuthenticationSessionContext = createContext<AuthenticationSession | undefined>(undefined);
