import { getProcessEnv } from "./process";

export const CdxEnvironmentVariables = {
  /**
   * Should be set to "true" when running in local development
   *
   * Currently this is driven by CITADEL_ENV = ""
   * This gives limited flexibility in running "local" with different environment configurations.
   * We are decoupling by using the following `CDX_IS_LOCAL=true`, but it is not broadly used just yet.
   */
  CDX_IS_LOCAL: "CDX_IS_LOCAL",
  /**
   * Secret key used by our authenticate service when signing our ga jwts
   * We are moving to ping federate and this will be no longer required
   *
   * @deprecated
   */
  CDX_JWT_SECRET: "CDX_JWT_SECRET",
  /**
   * The current project.
   *
   * This is a historical concept used to support multiple targets in
   * a single consul. We now use as tags in kubernetes, but it is a dervied value based
   * on target + ?subdomain.
   */
  CDX_PROJECT: "CDX_PROJECT",
  /**
   * The current target. Indicates which set of services to hit.
   *
   * This is the concept that replaces CDX_PROJECT
   */
  CDX_TARGET: "CDX_TARGET",
  /**
   * Well known CITADEL_ENV.
   *
   * prod, ssa, stabledev are the well-known values. We will replicate some (e.g. empty)
   * other special (developer) values into the well known value
   */
  CITADEL_ENV: "CITADEL_ENV",
  /**
   * Max file size the gateway supports
   *
   * @deprecated
   */
  MAX_FILE_SIZE_MB: "MAX_FILE_SIZE_MB",
  /**
   * DD value to control trace logs
   * e.g. false
   */
  DD_TRACE_STARTUP_LOGS: "DD_TRACE_STARTUP_LOGS",
  /**
   * DD value to control tracing
   * e.g. false
   */
  DD_TRACE_ENABLED: "DD_TRACE_ENABLED",
  /**
   * Node value to control how unauthorized cert verfication works by default
   * e.g. 0
   *
   * Notes (we use this):
   * This is not a security vulnerability as it may seem.
   * Service-to-service communication does not need to be strictly trusted,
   * as it is all internal. Requiring strict trust is difficult, as cert bundles
   * can be (and have been) out of date.
   */
  NODE_TLS_REJECT_UNAUTHORIZED: "NODE_TLS_REJECT_UNAUTHORIZED",
  /**
   * http (non tls) development used by the service gateway
   * e.g. true
   *
   * if you wish to do this, you may need to change your chrome settings to enable
   * cross-site cookies for http (requires https by default)
   * you will need to go to
   * chrome://flags
   * and disable
   * #same-site-by-default-cookies (SameSite by default cookies)
   * #cookies-without-same-site-must-be-secure (Cookies without same site must be secure)
   *
   */
  CDX_DEVELOPMENT_HTTPS: "CDX_DEVELOPMENT_HTTPS",
  /**
   * Origin to use for gateway (controls service -> service requests)
   * e.g. https://localhost.citadelgroup.com:50000
   *
   * This is a bit historical and harkens back to SSO ticket flow via gasec
   * where CIG_ORIGIN was the origin you had registered to allow SSO on
   * Currently it is only used for the above purpose (gateway determination)
   */
  CIG_ORIGIN: "CIG_ORIGIN",
  /**
   * Enables debug logging (tons of log output will show up if set to 1)
   * e.g. 1
   */
  CDX_DEBUG: "CDX_DEBUG",
};

export const getEnvironmentVariable = (
  variable: keyof typeof CdxEnvironmentVariables,
  fallback?: string
): string | undefined => {
  return getProcessEnv()?.[CdxEnvironmentVariables[variable]] ?? fallback;
};
