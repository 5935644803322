import { useUserSetting } from "@citadel/cdx-ui-state";
import { packageLogger } from "../logger";
import type { Theme } from "../types";
import { DEFAULT_THEME_NAME } from "../types";
import { useAppTheme } from "./useAppTheme";

const logger = packageLogger.createFileLogger(__filename);

export const useTheme = (defaultTheme: Theme = DEFAULT_THEME_NAME) => {
  const userTheme = useUserSetting<Theme>("cdxTheme", { defaultValue: defaultTheme });
  const [appTheme] = useAppTheme();

  if (appTheme) {
    return [appTheme, () => logger.warn("App theme should only be set via <CdxApp />")] as const;
  }

  return userTheme;
};
