import { Button, Card, Colors } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import styled from "@emotion/styled";
import type * as React from "react";
import { getEnvironment } from "@citadel/cdx-environment";
import type { SmcEntitlementRequirement } from "../../entitlements/types";
import { BoxCol } from "../containers/Box";
import { isProd, isSsa, SMC_URL_DEV, SMC_URL_PROD, SMC_URL_SSA } from "./constants";

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;

  .bp3-dark & {
    background-color: ${Colors.DARK_GRAY5} !important;
  }
`;

const SMCButton = styled(Button)`
  width: 100%;

  a {
    width: 100%;
    color: white;
  }
`;

const Strong = styled.span`
  font-weight: 700;
`;

export function SmcEntitlementCard({ entitlement }: { entitlement: SmcEntitlementRequirement }) {
  const { entitlement: name, service, suggestedGroupId } = entitlement;
  const env = getEnv();
  return (
    <SmcCard key={name} url={getSmcUrl(suggestedGroupId?.[env])}>
      <BoxCol alignItems="flex-start" margin="0 0 12px 0">
        <div>
          <Strong>Service</Strong>: {service}
        </div>
        <div>
          <Strong>Entitlement</Strong>: {name}
        </div>
      </BoxCol>
    </SmcCard>
  );
}

function SmcCard({ url, children }: React.PropsWithChildren<{ url: string }>) {
  return (
    <StyledCard elevation={1}>
      {children}
      <a href={url} target="_blank" style={{ textDecoration: "none" }}>
        <SMCButton intent="primary" rightIcon={IconNames.SHARE}>
          View on SMC
        </SMCButton>
      </a>
    </StyledCard>
  );
}

function getSmcUrl(groupId: number | undefined) {
  const SMC_URL = isProd() ? SMC_URL_PROD : isSsa() ? SMC_URL_SSA : SMC_URL_DEV;
  if (!groupId) return SMC_URL;
  return `${SMC_URL}/groups/${groupId}`;
}

function getEnv() {
  return getEnvironment().target === "local" ? "stabledev" : (getEnvironment().target as "stabledev" | "ssa" | "prod");
}
