/**
 * @deprecated {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @public
 */
export const AUTHENTICATE_API_PATHS = {
  getGasecToken: () => "/exchange/ga",
  login: (username: string) => `/login/${username}`,
  sso: () => "/login",
  validate: () => "/validate",
};

/**
 * @deprecated {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @public
 */
export const AUTHENTICATE_URL = "https://authenticate.citadelgroup.com";

/**
 * @deprecated {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @internal
 */
export const AUTHENTICATE_DEV_URL = "https://authenticate-dev.citadelgroup.com";

/**
 * @deprecated {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @public
 */
export const getAuthenticateUrl = (isDev: boolean): string => {
  return isDev ? AUTHENTICATE_DEV_URL : AUTHENTICATE_URL;
};
