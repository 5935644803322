import { Button } from "@blueprintjs/core";
import { globalActions, useGlobal } from "../../stores/global/global-store";

export const DisclaimerButton = () => {
  const { dispatch } = useGlobal();

  return (
    <Button
      outlined
      text="Disclaimer"
      icon="manual"
      onClick={() => dispatch({ type: globalActions.SET_IS_DISCLAIMER_OPEN, payload: true })}
    />
  );
};
