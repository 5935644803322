import { Button, ControlGroup, Menu, MenuItem, TagInput } from "@blueprintjs/core";
import styled from "@emotion/styled";
import type { MouseEvent } from "react";
import { useMemo, useState } from "react";
import type { FinancialType } from "../../../_generated/graphql";

export interface FinancialTypeSelectorProps {
  isOpen: boolean;
  setIsOpen(to: boolean): void;

  availableFinancialTypes: FinancialType[];
  selectedFinancialTypes: Set<number>;
  setSelectedFinancialTypes(to: Set<number>): void;
}

/**
 * Allows selecting which financial types should be used in the instrument
 * selector search.
 */
export function FinancialTypeSelector({
  isOpen,
  setIsOpen,
  availableFinancialTypes,
  selectedFinancialTypes,
  setSelectedFinancialTypes,
}: FinancialTypeSelectorProps) {
  const [query, setQuery] = useState("");

  // Returns the subset of financial types which are currently selected
  const selectedItems = useMemo(() => {
    const ftsById = Object.fromEntries(availableFinancialTypes.map((ft) => [ft.id, ft.name]));
    return [...selectedFinancialTypes].map((id): FinancialType => ({ id, name: ftsById[id] ?? "" }));
  }, [availableFinancialTypes, selectedFinancialTypes]);

  // Returns all available financial types that match the query (if set). This sorts alphabetically, and also
  // sorts so that the selected items are displayed at the top of the list
  const items = useMemo(() => {
    const sortedItems = availableFinancialTypes.sort((a, b) => {
      const aIsSelected = selectedFinancialTypes.has(a.id);
      const bIsSelected = selectedFinancialTypes.has(b.id);

      if (aIsSelected && !bIsSelected) {
        return -1;
      }

      if (!aIsSelected && bIsSelected) {
        return 1;
      }

      return a.name.localeCompare(b.name);
    });

    if (query.length === 0) {
      return sortedItems;
    }

    const filteredItems = sortedItems.filter((ft) => ft.name.toLowerCase().includes(query.toLowerCase()));
    return filteredItems;
  }, [query, availableFinancialTypes, selectedFinancialTypes]);

  // Toggles whether a financial type is selected or not
  function onFtToggle(ft: FinancialType) {
    const next = new Set(selectedFinancialTypes);

    if (next.has(ft.id)) {
      next.delete(ft.id);
    } else {
      next.add(ft.id);
    }

    setSelectedFinancialTypes(next);
  }

  // Checks if the financial type is selected
  function isFtSelected(ft: FinancialType): boolean {
    return selectedFinancialTypes.has(ft.id);
  }

  function onTagChange(to: string) {
    setIsOpen(true);
    setQuery(to);
  }

  function onDoneClick(e: MouseEvent) {
    e.stopPropagation();
    setIsOpen(false);
    setQuery("");
  }

  return (
    // We block keydown here to prevent the parent multiselect from operating
    // while we are modifying the financial types
    <Container onKeyDown={(e) => e.stopPropagation()}>
      <TagContainer>
        <ControlGroup>
          <MaxWidthTagInput
            fill
            placeholder="Filter by Financial Type"
            values={selectedItems.map((ft) => ft.name)}
            tagProps={{
              onClick: (e) => e.stopPropagation(),
            }}
            inputProps={{
              value: query,
              onChange: (e) => onTagChange(e.target.value),
              onFocus: () => setIsOpen(true),
            }}
            onRemove={(_, i) => onFtToggle(selectedItems[i])}
            rightElement={isOpen ? <Button icon="tick" intent="primary" onClick={(e) => onDoneClick(e)} /> : undefined}
          />
        </ControlGroup>
      </TagContainer>

      {isOpen ? (
        <ItemContainer>
          <Menu>
            {items.map((ft) => (
              <MenuItem
                key={ft.id}
                icon={isFtSelected(ft) ? "tick" : "blank"}
                text={ft.name}
                shouldDismissPopover={false}
                onClick={() => onFtToggle(ft)}
              />
            ))}
          </Menu>
        </ItemContainer>
      ) : null}
    </Container>
  );
}

const Container = styled.div``;

const TagContainer = styled.div`
  padding: 8px;
`;

const MaxWidthTagInput = styled(TagInput)`
  min-width: 300px;
  max-width: 500px;
`;

const ItemContainer = styled.div`
  max-height: 300px;
  overflow-y: scroll;
`;
