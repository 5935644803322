import type { ReactElement, ReactNode } from "react";
import { useEffect, useState } from "react";
import type { SessionState } from "./ping-federate/store";
import { SessionStore } from "./ping-federate/store";
import type { SessionData } from "./ping-federate/types";
import { CDX_OAUTH2_TMP_GLOBAL_USAGE_KEY } from "./constants";
import { assignBackwardsCompatibleValues } from "./backwardsCompatibility";

const Loading = () => <span>Loading...</span>;

let singletonStore: SessionStore | null;

/**
 * In synchronous contexts, this can be used to get the current authentication
 * information; this should not be used in api request code
 */
export const getSessionData = (): SessionData | null => {
  return singletonStore?.getSessionData() ?? null;
};

/**
 * In asynchronous contexts, this should be used to wait and extract the current
 * session information. If a refresh is required, this will await that refresh meaning
 * apis should rely on this method
 */
export const getSessionDataAsync = async (reassignCookies?: boolean): Promise<SessionData | null> => {
  if (!singletonStore) {
    return null;
  }

  const sessionData = await singletonStore?.getSessionDataAsync();
  const accessToken = sessionData?.accessToken?.token;
  if (accessToken && reassignCookies) {
    await assignBackwardsCompatibleValues(accessToken);
  }

  return sessionData;
};

/**
 * Verify session and optionally reset the session
 */
export const verifyAndResetIfNeeded = async (): Promise<SessionData | null> => {
  return singletonStore?.verifySessionAndResetIfNeeded() ?? null;
};

/**
 * Set/reset singleton store
 */
export function setSingletonStore(store: SessionStore | null) {
  singletonStore = store;
}

export interface AuthProviderProps {
  /**
   * TODO(@rdavis): Experimental - added to support multi cluster
   */
  cdxAuthOrigin?: string;
  /**
   * rendered children
   */
  children: ReactNode;
  /**
   * shows simple text otherwise
   */
  LoadingComponent?: () => ReactElement | null;
  /**
   * if there is a required protocol, specify here
   */
  requiredProtocol?: "http:" | "https:" | undefined;
  /**
   * if authenticate jwt is required
   * defaults to true
   */
  requiresAuthenticate?: boolean;
}

export const CdxProviderOAuth2 = ({ children, LoadingComponent = Loading }: AuthProviderProps): ReactElement => {
  const [state, setState] = useState<SessionState | null>(null);

  useEffect(() => {
    // TODO: remove this when all apps are onboard with OAuth2
    (globalThis as any)[CDX_OAUTH2_TMP_GLOBAL_USAGE_KEY] = true;
    // initialize the store and perform initial verification
    const store = singletonStore || new SessionStore();
    if (!singletonStore) {
      setSingletonStore(store);
    }
    let cancelled = false;

    const verifyInitial = async () => {
      const sessionState = await store.waitForInitialized();
      if (!cancelled) {
        setState(sessionState);
      }
    };
    verifyInitial();

    return () => {
      cancelled = true;
    };
  }, []);

  if (!state) {
    return <LoadingComponent />;
  }
  if (state.errorMessage) {
    return <span>There has been an authentication error: {state.errorMessage}</span>;
  }
  if (state.redirecting) {
    return <span>Redirecting...</span>;
  }

  return <>{children}</>;
};
