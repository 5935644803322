import { cdxPlatformServices } from "./CdxPlatformServices";
import { cgServices } from "./CgServices";

export const platformServicesSet = new Set(
  Object.keys(cdxPlatformServices).map((k) => `@citadel/cdx-service-graphql-${k}`)
);

export const wellKnownServices = {
  ...cdxPlatformServices,
  ...cgServices,
};
