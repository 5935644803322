/**
 * Convert the specified number to a percentage with the specified precision.
 * @param num A number.
 * @param precision Precision of the resulting percentage, i.e., the number of digits after the decimal place.
 * @returns A percentage, e.g., "12.34%"
 */
export const toPercentage = (num: number | null | undefined, precision: number = 0) => {
  if ((!num && num !== 0) || !isFinite(num)) {
    return "";
  }

  const formatter = new Intl.NumberFormat(undefined, {
    style: "percent",
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

  return formatter.format(num as number);
};
