import { isEqual } from "lodash";
import { useDerivedValue } from "@citadel/cdx-lib-react";

export const useMemoValue = <T>(value: T, comparator: (prev: T, next: T) => boolean = isEqual) => {
  return useDerivedValue(
    // TODO: CTOTECH-2046 Fix this the next time this file is edited.
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (value) => value,
    [value],
    ([prev], [next]) => comparator(prev, next)
  );
};
