import { Classes, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import { Fragment } from "react";
import { DateTime } from "luxon";
import useId from "@mui/utils/useId";
import styled from "@emotion/styled";
import type { CurrentUser } from "../../hooks";
import { useCurrentUser } from "../../hooks";
import type { ReportUUID } from "./types/base";
import type { Report } from "./types/Report";

const StyledMenuItem = styled(MenuItem)`
  .bp3-text-overflow-ellipsis {
    max-width: 100px;
  }
`;

interface ReportedIssuesMenuProps {
  reports: Record<ReportUUID, Report>;
  openReport: (reportId: ReportUUID | null, currentUser: CurrentUser, filterNode: Element | null) => void;
  openReportConfiguration: () => void;
}

/**
 * Return either a usable Element for filtering purposes or nothing.
 *
 * @param id -
 *
 * @returns
 */
function getMenuOverlay(id: string | undefined): Element | null {
  return (id && document.getElementById(id)?.closest("." + Classes.OVERLAY)) || null;
}

export function ReportedIssuesMenu({ reports, openReport, openReportConfiguration }: ReportedIssuesMenuProps) {
  const currentUser = useCurrentUser();
  const MenuItems: JSX.Element[] = [];
  const sortedReports = Object.values(reports).reverse();
  const menuId = useId();
  const menuOverlay = getMenuOverlay(menuId);
  let allReportsComplete = true;
  let someReportsComplete = false;
  let continueEditingId: string | null = null;

  for (const report of sortedReports) {
    if (!report.completed) {
      allReportsComplete = false;
      continueEditingId = report.id;
      continue;
    }

    MenuItems.push(
      <StyledMenuItem
        icon="saved"
        key={report.id}
        text={report.description || "Finished Report"}
        labelElement={DateTime.fromJSDate(new Date(report.activity)).toRelative()}
        onClick={() => openReport(report.id, currentUser.data!, menuOverlay)}
        disabled={!currentUser.data}
      />
    );
    someReportsComplete = true;
  }

  return (
    <Menu id={menuId}>
      {allReportsComplete ? (
        <MenuItem
          text="Report a new issue"
          onClick={() => openReport(null, currentUser.data!, menuOverlay)}
          disabled={!currentUser.data}
        />
      ) : (
        <MenuItem
          text="Continue editing issue"
          onClick={() => openReport(continueEditingId, currentUser.data!, menuOverlay)}
          disabled={!currentUser.data}
        />
      )}
      {someReportsComplete ? (
        <Fragment>
          <MenuDivider title="Completed Reports" />
          {MenuItems}
        </Fragment>
      ) : null}
      <MenuDivider />
      <MenuItem icon="build" text="Developer Info" onClick={openReportConfiguration} />
    </Menu>
  );
}
