import { WorkspaceSettingsContext } from "../context/WorkspaceSettingsContext";
import { createStoredValueHook } from "./createStoredValueHook";

/**
 * A Workspace Setting is information which can be accessed everywhere within a workspace.
 *
 * Workspace settings are stored within a workspace.
 *
 * Some examples of workspace settings:
 *  - Desk Selection to be propagated to entire dashboard
 *  - A list of selected instruments to used throughout a dashboard
 *  - Selected time period across workspace
 */
export const useWorkspaceSetting = createStoredValueHook(WorkspaceSettingsContext);
