import type { ChannelName } from "./ChannelName";
import type { ChannelTopic } from "./ChannelTopic";
import { NO_TOPIC } from "./ChannelTopic";

/**
 * {@inheritdoc USER_CHANNEL_NONE}
 *
 * @public
 */
export type NoUserChannel = typeof USER_CHANNEL_NONE;

/**
 * A user selectable value which is used in conjuction with one or more {@link ChannelTopic} to derive a list of
 * {@link ChannelName | channel names} which the user has selected to subscribe to.
 *
 * @public
 */
export type UserChannel =
  | typeof USER_CHANNEL_1
  | typeof USER_CHANNEL_2
  | typeof USER_CHANNEL_3
  | typeof USER_CHANNEL_4
  | typeof USER_CHANNEL_5
  | typeof USER_CHANNEL_6;

/**
 * Used to indicate that no channel as been selected by the user.
 *
 * @public
 */
export const USER_CHANNEL_NONE = 0;

/**
 * Channel #1 of the user selectable channels
 *
 * @public
 */
export const USER_CHANNEL_1 = 1;

/**
 * Channel #2 of the user selectable channels
 *
 * @public
 */
export const USER_CHANNEL_2 = 2;

/**
 * Channel #3 of the user selectable channels
 *
 * @public
 */
export const USER_CHANNEL_3 = 3;

/**
 * Channel #4 of the user selectable channels
 *
 * @public
 */
export const USER_CHANNEL_4 = 4;

/**
 * Channel #5 of the user selectable channels
 *
 * @public
 */
export const USER_CHANNEL_5 = 5;

/**
 * Channel #5 of the user selectable channels
 *
 * @public
 */
export const USER_CHANNEL_6 = 6;

/**
 * A set containing all the user selectable channels
 */
export const USER_CHANNEL_SET = new Set([
  USER_CHANNEL_1,
  USER_CHANNEL_2,
  USER_CHANNEL_3,
  USER_CHANNEL_4,
  USER_CHANNEL_5,
  USER_CHANNEL_6,
] as const);

const USER_CHANNEL_PREFIX = `CH`;

/**
 * A {@link ChannelName} which represents a user channel
 */
export type UserChannelName<U extends UserChannel = UserChannel> = `CH${U}`;

/**
 * A {@link ChannelName} which represents a user channel with a topic
 */
export type UserChannelWithTopicName<
  U extends UserChannel = UserChannel,
  T extends ChannelTopic = ChannelTopic
> = `CH${U}.${T}`;

/**
 * Generates a {@link ChannelName} from a user channel and topic
 *
 * @param ch    - The {@link UserChannel}
 * @param topic - The {@link ChannelTopic}; omit this or use {@link NO_TOPIC} to get the bare channel name
 */
export function getUserChannelName<U extends UserChannel>(ch: U): UserChannelName<U>;
export function getUserChannelName<U extends UserChannel, T extends ChannelTopic>(
  ch: U,
  topic: T
): UserChannelWithTopicName<U, T>;
export function getUserChannelName<U extends UserChannel, T extends ChannelTopic>(
  ch: U,
  topic?: T
): UserChannelName<U> | UserChannelWithTopicName<U, T> {
  const prefix: UserChannelName<U> = `${USER_CHANNEL_PREFIX}${ch}`;
  if (topic === undefined || topic === NO_TOPIC) {
    return prefix;
  }

  return `${prefix}.${topic}`;
}

/**
 * Extract a {@link UserChannel} and {@link ChannelTopic} from a {@link ChannelName}
 *
 * @param channelName - The {@link ChannelName}
 */
export function extractUserChannelFromChannelName(
  channelName: ChannelName
): [userChannel: UserChannel, topic: string] | [userChannel: undefined, topic: undefined] {
  if (!channelName.startsWith(USER_CHANNEL_PREFIX)) return [undefined, undefined];

  // This is probably a user channel, let's pull out the topic
  for (const ch of USER_CHANNEL_SET) {
    const prefix = getUserChannelName(ch);
    if (!channelName.startsWith(prefix)) continue;

    if (channelName === prefix) {
      return [ch, NO_TOPIC];
    }

    const topic = channelName.substring(prefix.length + 1) as ChannelTopic;
    return [ch, topic];
  }

  return [undefined, undefined];
}
