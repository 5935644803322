import styled from "@emotion/styled";
import type { ReactNode, PropsWithChildren } from "react";
import { HeaderBar } from "../header-bar";
import { HeaderLink } from "./header-link";

const HEADER_BAR_HEIGHT = "60px";

const Page = styled.div({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  overflow: "hidden",
});

const PageHeaderBar = styled(HeaderBar)({
  minHeight: HEADER_BAR_HEIGHT,
  maxHeight: HEADER_BAR_HEIGHT,
  position: "fixed",
  zIndex: 1,
});

const LinkGroup = styled.div({
  display: "flex",
  height: "100%",
});

const PageContent = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  height: `calc(100% - ${HEADER_BAR_HEIGHT})`,
  marginTop: HEADER_BAR_HEIGHT,
  overflow: "auto",
});

/**
 * Component intended for use as the "root" component of a page.
 *
 * @example
 * <PageLayout>
 *  <PageRoot>
 *    // ...
 *  </PageRoot>
 * </PageLayout>
 */
export const PageRoot = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
});

export type PageLink = {
  icon?: ReactNode;
  /** Title of the link, e.g., "Home", "Settings" */
  title: string;
  /** Relative path to the resource, e.g., "/", "/settings" */
  path: string;
};

export type PageLayoutProps = PropsWithChildren<{
  /** Navigation links to render in the header bar. */
  links?: readonly PageLink[];
}>;

/**
 * Component rendering a page with a header bar.
 *
 * @example
 * <PageLayout>
 *     <YourPageComponent />
 * </PageLayout>
 */
export const PageLayout = ({ children, links }: PageLayoutProps): JSX.Element => {
  return (
    <Page>
      <PageHeaderBar>
        <LinkGroup>
          {links?.map((link, index) => (
            <HeaderLink key={`${link.title}-${index}`} icon={link.icon} title={link.title} path={link.path} />
          ))}
        </LinkGroup>
      </PageHeaderBar>
      <PageContent>{children}</PageContent>
    </Page>
  );
};
