import { prefixRoutes } from "@citadel/cdx-ui";
import { AdminAssessmentView } from "../components/admin-assessment-view";
import { AssessmentView } from "../components/assessment-view";
import { PostSubmissionView } from "../components/post-submission-view";
import { TutorialView } from "../components/tutorial-view";

const APP_PREFIX = "/pse-pub/bd-assessment";

interface TokenRouteParams {
  token: string;
}

interface AdminTokenRouteParams {
  adminToken: string;
}

export const routes = prefixRoutes(APP_PREFIX, {
  home: {
    title: "Tutorial",
    isHome: true,
    component: TutorialView,
    path: ({ token }: TokenRouteParams = { token: ":token" }) => `/${token}`,
  },
  assessment: {
    title: "Assessment",
    isHome: false,
    component: AssessmentView,
    path: ({ token }: TokenRouteParams = { token: ":token" }) => `/assessment/${token}`, // TODO: find some way to protect this route so a user can't go here without doing tutorial
  },
  submitted: {
    title: "Submitted",
    isHome: false,
    component: PostSubmissionView,
    path: () => "/thank_you",
  },
  adminAssessment: {
    title: "Completed Assessment",
    isHome: false,
    component: AdminAssessmentView,
    path: ({ adminToken }: AdminTokenRouteParams = { adminToken: ":adminToken" }) => `/admin-assessment/${adminToken}`,
  },
});

export const routesList = [routes.adminAssessment, routes.submitted, routes.assessment, routes.home];
