import { omit } from "lodash";
import type { PathValue } from "./getDeep";
import { isScalar } from "./isScalar";

export function setDeep<T, K extends readonly any[]>(node: T, path: K, value: PathValue<T, K>): T;
export function setDeep(node: any, path: string[], value: any): any {
  if (!path.length) return value;

  let newNode = node;
  const [key, ...subpath] = path;

  if (isScalar(node) || node === null) {
    newNode = typeof key === "number" ? [] : {};
  }

  if (Array.isArray(node)) {
    if (typeof key === "number") {
      newNode = [...node];
      const newValue = setDeep(newNode[key], subpath, value);
      newNode.splice(key, 1, ...(newNode === undefined ? [] : [newValue]));
      return newNode;
    } else {
      newNode = {};
    }
  }

  const newValue = setDeep(newNode && newNode[key], subpath, value);
  if (newValue === undefined) {
    return omit(newNode, key);
  }

  return {
    ...newNode,
    [key]: setDeep(newNode && newNode[key], subpath, value),
  };
}
