import { useCallback } from "react";
import { isEqual } from "lodash";
import { applyChange } from "@citadel/cdx-lib-react";
import { useMemoValue } from "./useMemoValue";

export const useStateWithDefault = <T>(
  [value, setValue]: [T, (change: T | ((prev: T) => T)) => void],
  defaultValue: T
): [T, (change: T | ((prev: T) => T)) => void] => {
  const mDefault = useMemoValue(defaultValue, isEqual);

  return [
    value === undefined ? mDefault : value,
    useCallback((change) => setValue((prev = mDefault) => applyChange(prev, change as any)), [setValue, mDefault]),
  ];
};
