import { useEffect, useMemo, useState } from "react";
import { Intent, NonIdealState, Spinner, Icon } from "@blueprintjs/core";
import { useParams } from "react-router-dom";
import { useSystemConfigs } from "@citadel/pse-lib-ui";
import { addError } from "@citadel/cdx-provider-user-monitoring";
import { useAssessmentQuery } from "../../_generated/graphql";
import { BDGrid } from "../bd-grid";
import { DisclaimerButton, SubmitButton } from "../buttons";
import { HelpButton } from "../buttons/help-button";
import { DisclaimerDialog } from "../dialogs/disclaimer-dialog";
import { SubmissionDialog } from "../dialogs/submission-dialog";
import { GlobalSaveIndicator } from "../global-save-indicator";
import { HeaderBar } from "../header-bar";
import { InfoSubsection } from "../info-section/info-subsection";
import classes from "./assessment-view.less";

interface AssessmentViewParamTypes {
  token: string;
}

export const AssessmentView = () => {
  const { token } = useParams<AssessmentViewParamTypes>();
  const [ignoreTimer] = useSystemConfigs(["ignoreTimer"], [false]);
  const [isTimerEnded, setIsTimerEnded] = useState(false);

  const { data, loading, error } = useAssessmentQuery({
    variables: {
      token,
    },
  });

  useEffect(() => addError("Assessment View error", { message: error?.message }), [error]);

  const expiresAt = useMemo(
    () => data?.assessmentByToken?.expiresAt && new Date(data?.assessmentByToken?.expiresAt),
    [data?.assessmentByToken?.expiresAt]
  );

  const isExpired = !ignoreTimer && !loading && (expiresAt < new Date(Date.now()) || isTimerEnded);

  return (
    <>
      <HeaderBar
        label="Financial Concepts Assessment Test"
        {...(!isExpired && { endDate: expiresAt })}
        rightElement={
          <>
            {!isTimerEnded && <SubmitButton />}
            <DisclaimerButton />
            <HelpButton />
            <GlobalSaveIndicator />
          </>
        }
        onTimerEnd={() => setIsTimerEnded(true)}
      />
      <div className={classes.container}>
        {(isExpired || error) && (
          <NonIdealState
            icon="info-sign"
            title="Expired Assessment"
            description="This assessment has expired or has already been completed. Please reach out to your recruiter if this is a mistake."
          />
        )}
        {loading && <Spinner intent={Intent.PRIMARY} />}
        {!loading && !isExpired && !error && data?.assessmentByToken && (
          <>
            <div className={classes.assessmentInstructions}>
              <InfoSubsection subheader="Instructions">
                <div>
                  You have 45 minutes to complete the Citadel FCAT. Time remaining is shown at the top of the page.
                </div>
                <p>
                  Fill out all blank yellow cells in the spreadsheet. Populated cells will be marked in blue as they are
                  filled, and can be edited at any time.
                </p>
                <p>
                  Click the <Icon icon="help" /> button in the top right of the page to reference the tutorial
                  information, hotkeys, and other details.
                </p>
              </InfoSubsection>
            </div>
            <BDGrid
              assessment={data?.assessmentByToken}
              scratchLines={["numeric", "accountingDollar", "accountingEuro", "percentage"]}
            />
          </>
        )}
      </div>
      <DisclaimerDialog />
      <SubmissionDialog token={token} />
    </>
  );
};
