import { parseAndSaveSessionData } from "./localStorage";
import type { SessionResolveResult, PingFederateTokens, SessionData } from "./types";

const THIRTY_SECOND = 30;
const FIVE_MINUTE_IN_SECOND = 5 * 60;
const BUFFER_TIME_IN_SECOND = FIVE_MINUTE_IN_SECOND;

const MAX_DELAY_IN_SECOND = 10; // max jitter to add to the retry
const MIN_TIME_IN_SECOND_TO_INDICATE_FRESHNESS = BUFFER_TIME_IN_SECOND + MAX_DELAY_IN_SECOND;

/**
 * Predicate to tell the refresh has already happened if the time left is still further away
 * @param expInMs ms left to run the scheduled task
 * @returns if it is already refreshed
 */
export function alreadyRefreshed(expInMs: number | undefined): expInMs is number {
  return Boolean(typeof expInMs === "number" && expInMs > MIN_TIME_IN_SECOND_TO_INDICATE_FRESHNESS * 1000);
}

/**
 * Get randomize delay (jitter) in the range of 0 to maxDelay to prevent thundering herd problem
 * @returns number of seconds
 */
function getRandomizedDelay(maxDelay = MAX_DELAY_IN_SECOND) {
  return Math.floor(Math.random() * maxDelay);
}

/**
 * Get interval time to schedule the refresh,
 * offset by ~5 minutes ahead of token expiration,
 * minimum 30 seconds
 * @param expiresIn number of seconds to expiration
 * @returns interval time in milliseconds
 */
export function calcRefreshInterval(expiresIn: number): number {
  const maxInterval = expiresIn - BUFFER_TIME_IN_SECOND - getRandomizedDelay(); // min 86090, max 86100
  const minInterval = THIRTY_SECOND;
  return Math.max(maxInterval, minInterval) * 1000;
}

/**
 * Parse session jwt and derive refresh interval from raw data
 */
export function resolvePingFederateTokens(tokens: PingFederateTokens | null): SessionResolveResult {
  const refreshIntervalInMillisecond = tokens ? calcRefreshInterval(tokens.expires_in) : undefined;
  const sessionData = parseAndSaveSessionData(tokens);

  return { refreshIntervalInMillisecond, sessionData };
}

/**
 * Derive additional info, e.g. refresh interval, from parsed session data
 */
export function resolveSessionTokens(tokens: SessionData | null): SessionResolveResult {
  if (!tokens) return {};

  const timeToExpInSeconds = ((tokens?.accessToken?.expirationInMs || Date.now()) - Date.now()) / 1000;
  const refreshIntervalInMillisecond = calcRefreshInterval(timeToExpInSeconds);

  return {
    sessionData: tokens,
    refreshIntervalInMillisecond,
  };
}
