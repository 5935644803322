import { gql } from "@apollo/client";
import * as React from "react";
import * as Apollo from "@apollo/client";
import * as ApolloReactComponents from "@apollo/client/react/components";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An RFC-3339 compliant Full Date Scalar */
  Date: any;
  _FieldSet: any;
  JSON: any;
  ISO8601Timestamp: any;
  DataURL: any;
  /** Built-in java.math.BigDecimal */
  BigDecimal: any;
  /** Built-in java.math.BigInteger */
  BigInteger: any;
  /** Long type */
  Long: any;
  /** Built-in Short as Int */
  Short: any;
};

export type Country = {
  __typename?: "Country";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type FinancialType = {
  __typename?: "FinancialType";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type Group = {
  __typename?: "Group";
  creationDate?: Maybe<Scalars["Date"]>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  distinguishedName?: Maybe<Scalars["String"]>;
  dynamic?: Maybe<Scalars["Boolean"]>;
  email?: Maybe<Scalars["String"]>;
  lastUpdated?: Maybe<Scalars["Date"]>;
  managedBy?: Maybe<Scalars["String"]>;
  managementAccount?: Maybe<Scalars["String"]>;
  members?: Maybe<Array<Maybe<Scalars["String"]>>>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  objectGuid?: Maybe<Scalars["String"]>;
  outlookDisplayName?: Maybe<Scalars["String"]>;
  proxyAddress?: Maybe<Array<Maybe<Scalars["String"]>>>;
  sid?: Maybe<Scalars["String"]>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

export type Query = {
  __typename?: "Query";
  cdx_ADGroupMembers?: Maybe<Array<Maybe<User>>>;
  cdx_allGroups?: Maybe<Array<Maybe<Group>>>;
  cdx_allUsers?: Maybe<Array<Maybe<User>>>;
  cdx_countries?: Maybe<Array<Maybe<Country>>>;
  cdx_currentUser?: Maybe<User>;
  cdx_financialTypes?: Maybe<Array<Maybe<FinancialType>>>;
  cdx_findGroupsFuzzy?: Maybe<Array<Maybe<Group>>>;
  cdx_findUser?: Maybe<User>;
  cdx_instrument: Results;
  core_app: Core_App;
  core_ldapUsers: Array<Core_LdapUser>;
  core_link: Core_Link;
  core_loggedInUser?: Maybe<Core_UserAuthenticated>;
  /**
   * Noop operation for UIs that do not have a graphql request; this allows
   * codegen to pass before developers have configured an actual query
   */
  core_noop: Scalars["Boolean"];
  core_user?: Maybe<Core_UserLookup>;
  dataFrame?: Maybe<DataFrame>;
  skynet_AddMobileDevice?: Maybe<Scalars["String"]>;
  skynet_AddMobileUser?: Maybe<Skynet_MobileUser>;
  skynet_AddUsersToSymphonyRoom?: Maybe<Skynet_SymphonyRoomResponse>;
  skynet_AddWindowsManagedServiceAccountPrincipals?: Maybe<Array<Maybe<Skynet_WindowsUserPrincipal>>>;
  skynet_AddWindowsUserPrincipals?: Maybe<Array<Maybe<Skynet_WindowsUserPrincipal>>>;
  skynet_AddZabbixGroupMember?: Maybe<Array<Maybe<Skynet_ZabbixUser>>>;
  skynet_CreateAirwatchMessage?: Maybe<Skynet_MessageResponse>;
  skynet_CreateBastionAccount?: Maybe<Skynet_BastionAccount>;
  skynet_CreateBastionDevice?: Maybe<Skynet_BastionDevice>;
  skynet_CreateBastionUserGroup?: Maybe<Skynet_BastionUserGroup>;
  skynet_CreateCCureUser?: Maybe<Skynet_CCureUser>;
  skynet_CreateCertificateRequest?: Maybe<Skynet_CertificateResponse>;
  skynet_CreateDataPrivilegeDirectPermissionRequest?: Maybe<Skynet_DataPrivilegeDirectPermissionRequest>;
  skynet_CreateDataPrivilegeMembershipRequest?: Maybe<Skynet_DataPrivilegeMembershipRequest>;
  skynet_CreateDfsRoot?: Maybe<Skynet_DfsRoot>;
  skynet_CreateDfsRootFolder?: Maybe<Skynet_DfsFolder>;
  skynet_CreateDfsRootFolderTarget?: Maybe<Skynet_DfsFolder>;
  skynet_CreateDhcpReservation?: Maybe<Skynet_DhcpReservation>;
  skynet_CreateDhcpReservationOption?: Maybe<Skynet_DhcpOption>;
  skynet_CreateDhcpScope?: Maybe<Skynet_DhcpScope>;
  skynet_CreateDhcpScopeOption?: Maybe<Skynet_DhcpOption>;
  skynet_CreateDhcpServerOption?: Maybe<Skynet_DhcpOption>;
  skynet_CreateDnsARecord?: Maybe<Array<Maybe<Skynet_DnsResourceRecord>>>;
  skynet_CreateDnsCnameRecord?: Maybe<Skynet_DnsResourceRecord>;
  skynet_CreateDnsMxRecord?: Maybe<Skynet_DnsResourceRecord>;
  skynet_CreateDnsNsRecord?: Maybe<Skynet_DnsResourceRecord>;
  skynet_CreateDnsPtrRecord?: Maybe<Skynet_DnsResourceRecord>;
  skynet_CreateDnsRequest?: Maybe<Skynet_DnsRequest>;
  skynet_CreateDnsSrvRecord?: Maybe<Skynet_DnsResourceRecord>;
  skynet_CreateDnsTxtRecord?: Maybe<Skynet_DnsResourceRecord>;
  skynet_CreateDnsZone?: Maybe<Skynet_DnsZone>;
  skynet_CreateExternalCertificate?: Maybe<Skynet_ExternalCertificateResponse>;
  skynet_CreateFunction?: Maybe<Skynet_Function>;
  skynet_CreateGroupMember?: Maybe<Array<Maybe<Skynet_WindowsGroupMember>>>;
  skynet_CreateHomeDirectory?: Maybe<Skynet_CreateHomeDirResponse>;
  skynet_CreateLinuxAutoMount?: Maybe<Skynet_LinuxAutoMount>;
  skynet_CreateLinuxAutoMountMap?: Maybe<Skynet_LinuxAutoMountMap>;
  skynet_CreateLinuxGroup?: Maybe<Skynet_LinuxGroup>;
  skynet_CreateLinuxNetGroup?: Maybe<Skynet_LinuxNetgroup>;
  skynet_CreateLinuxNetgroupMember?: Maybe<Array<Maybe<Skynet_LinuxGroupMember>>>;
  skynet_CreateLinuxSudoRole?: Maybe<Array<Maybe<Skynet_Job>>>;
  skynet_CreateLinuxUser?: Maybe<Skynet_LinuxUser>;
  skynet_CreateMacUser?: Maybe<Skynet_MacUser>;
  skynet_CreateMailboxAccess?: Maybe<Skynet_ExchangeMailboxAccess>;
  skynet_CreateMailboxEmail?: Maybe<Skynet_ExchangeMailboxEmail>;
  skynet_CreatePagerDutyEvent?: Maybe<Skynet_PagerDutyEventResponse>;
  skynet_CreatePagerDutyUser?: Maybe<Skynet_PagerDutyUser>;
  skynet_CreateRandomPassword?: Maybe<Skynet_PasswordResponse>;
  skynet_CreateSccmCollectionMembership?: Maybe<Scalars["String"]>;
  skynet_CreateServerName?: Maybe<Skynet_ServerName>;
  skynet_CreateServiceNowAdHocRequest?: Maybe<Skynet_AdHocTicketResponse>;
  skynet_CreateSlackMessage?: Maybe<Skynet_SlackMessageResponse>;
  skynet_CreateSlackUpload?: Maybe<Skynet_SlackUploadResponse>;
  skynet_CreateSnowflakeSecurityRequest?: Maybe<Skynet_SnowflakeSecurityRequest>;
  skynet_CreateSymphonyMessage?: Maybe<Array<Maybe<Skynet_MessageResponse>>>;
  skynet_CreateSymphonyRoom?: Maybe<Skynet_SymphonyRoomResponse>;
  skynet_CreateUcsWindowsWorkflow?: Maybe<Skynet_Job>;
  skynet_CreateUsbBios?: Maybe<Skynet_UsbBios>;
  skynet_CreateVaultSecret?: Maybe<Skynet_VaultSecretResponse>;
  skynet_CreateWindowsComputerHeartbeat?: Maybe<Skynet_WindowsComputerHeartbeat>;
  skynet_CreateWindowsGroup?: Maybe<Skynet_WindowsGroup>;
  skynet_CreateWindowsLocalGroupMember?: Maybe<Array<Maybe<Skynet_WindowsGroupMember>>>;
  skynet_CreateWindowsManagedServiceAccount?: Maybe<Skynet_WindowsManagedServiceAccount>;
  skynet_CreateWindowsUser?: Maybe<Skynet_WindowsUser>;
  skynet_CreateWindowsUserGroup?: Maybe<Skynet_WindowsUserGroup>;
  skynet_CreateWindowsUsersRestrictedHost?: Maybe<Array<Maybe<Scalars["String"]>>>;
  skynet_CreateZabbixMaintenance?: Maybe<Skynet_ZabbixMaintenance>;
  skynet_CreateZabbixUser?: Maybe<Skynet_ZabbixUser>;
  skynet_DeleteHomeDirectory?: Maybe<Skynet_Job>;
  skynet_DeleteSccmCollectionMembership?: Maybe<Scalars["String"]>;
  skynet_DeleteWindowsUserPrincipals?: Maybe<Array<Maybe<Skynet_WindowsUserPrincipal>>>;
  skynet_DisableHomeDirectory?: Maybe<Skynet_Job>;
  skynet_DisableLinuxUser?: Maybe<Skynet_LinuxUser>;
  skynet_DisableMobileUser?: Maybe<Skynet_MobileUser>;
  skynet_DisableWindowsGroup?: Maybe<Skynet_WindowsGroup>;
  skynet_EnableContact?: Maybe<Skynet_ExchangeContact>;
  skynet_EnableWindowsGroup?: Maybe<Skynet_WindowsGroup>;
  skynet_GetActiveDirectoryComputer?: Maybe<Skynet_ActiveDirectoryComputer>;
  skynet_GetActiveDirectoryComputerChangeHistory?: Maybe<Array<Maybe<Skynet_WindowsObjectChangeEvent>>>;
  skynet_GetActiveDirectoryComputerGroups?: Maybe<Array<Maybe<Skynet_WindowsGroup>>>;
  skynet_GetActiveDirectoryComputers?: Maybe<Array<Maybe<Skynet_ActiveDirectoryComputer>>>;
  skynet_GetAmLinuxComputerMounts?: Maybe<Array<Maybe<Skynet_LinuxComputerMountPoint>>>;
  skynet_GetAmLinuxComputerProcesses?: Maybe<Array<Maybe<Skynet_LinuxComputerProcess>>>;
  skynet_GetAmLinuxComputerSoftware?: Maybe<Array<Maybe<Skynet_LinuxComputerSoftware>>>;
  skynet_GetAmLinuxComputers?: Maybe<Array<Maybe<Skynet_AmLinuxComputer>>>;
  skynet_GetBastionAccount?: Maybe<Skynet_BastionAccount>;
  skynet_GetBastionAccounts?: Maybe<Array<Maybe<Skynet_BastionAccount>>>;
  skynet_GetBastionAuthorizations?: Maybe<Array<Maybe<Skynet_BastionAuthorization>>>;
  skynet_GetBastionDevice?: Maybe<Skynet_BastionDevice>;
  skynet_GetBastionDevices?: Maybe<Array<Maybe<Skynet_BastionDevice>>>;
  skynet_GetBastionLdapMappings?: Maybe<Array<Maybe<Skynet_BastionLdapMapping>>>;
  skynet_GetBastionTargetGroup?: Maybe<Skynet_BastionTargetGroup>;
  skynet_GetBastionTargetGroups?: Maybe<Array<Maybe<Skynet_BastionTargetGroup>>>;
  skynet_GetBastionUserGroups?: Maybe<Array<Maybe<Skynet_BastionUserGroup>>>;
  skynet_GetBusinessResourceGroup?: Maybe<Skynet_BusinessResourceGroup>;
  skynet_GetBusinessResourceGroups?: Maybe<Array<Maybe<Skynet_BusinessResourceGroup>>>;
  skynet_GetCCureUsers?: Maybe<Array<Maybe<Skynet_CCureUser>>>;
  skynet_GetContact?: Maybe<Skynet_ExchangeContact>;
  skynet_GetCsLinuxComptuerMounts?: Maybe<Array<Maybe<Skynet_LinuxComputerMountPoint>>>;
  skynet_GetCsLinuxComputers?: Maybe<Array<Maybe<Skynet_CsLinuxComputer>>>;
  skynet_GetDataPrivilegeMembershipRequestStatus?: Maybe<Skynet_DataPrivilegeMembershipRequest>;
  skynet_GetDfsRoot?: Maybe<Skynet_DfsRoot>;
  skynet_GetDfsRootFolderTargets?: Maybe<Array<Maybe<Skynet_DfsTarget>>>;
  skynet_GetDfsRootFolders?: Maybe<Array<Maybe<Skynet_DfsFolder>>>;
  skynet_GetDfsRootTargets?: Maybe<Array<Maybe<Skynet_DfsTarget>>>;
  skynet_GetDfsRoots?: Maybe<Array<Maybe<Skynet_DfsRoot>>>;
  skynet_GetDhcpFreeIp?: Maybe<Array<Maybe<Scalars["String"]>>>;
  skynet_GetDhcpLeases?: Maybe<Array<Maybe<Skynet_DhcpLease>>>;
  skynet_GetDhcpReservations?: Maybe<Array<Maybe<Skynet_DhcpReservation>>>;
  skynet_GetDhcpScopes?: Maybe<Array<Maybe<Skynet_DhcpScope>>>;
  skynet_GetDhcpServerLeases?: Maybe<Array<Maybe<Skynet_DhcpLease>>>;
  skynet_GetDhcpServers?: Maybe<Array<Maybe<Skynet_DhcpServer>>>;
  skynet_GetDhcpServersByIp?: Maybe<Array<Maybe<Skynet_DhcpServer>>>;
  skynet_GetDnsARecord?: Maybe<Array<Maybe<Skynet_DnsResourceRecord>>>;
  skynet_GetDnsARecords?: Maybe<Array<Maybe<Skynet_DnsResourceRecord>>>;
  skynet_GetDnsCnameRecord?: Maybe<Array<Maybe<Skynet_DnsResourceRecord>>>;
  skynet_GetDnsCnameRecords?: Maybe<Array<Maybe<Skynet_DnsResourceRecord>>>;
  skynet_GetDnsMxRecord?: Maybe<Array<Maybe<Skynet_DnsResourceRecord>>>;
  skynet_GetDnsMxRecords?: Maybe<Array<Maybe<Skynet_DnsResourceRecord>>>;
  skynet_GetDnsNsRecord?: Maybe<Array<Maybe<Skynet_DnsResourceRecord>>>;
  skynet_GetDnsNsRecords?: Maybe<Array<Maybe<Skynet_DnsResourceRecord>>>;
  skynet_GetDnsPtrRecord?: Maybe<Array<Maybe<Skynet_DnsResourceRecord>>>;
  skynet_GetDnsPtrRecords?: Maybe<Array<Maybe<Skynet_DnsResourceRecord>>>;
  skynet_GetDnsRequestDetails?: Maybe<Array<Maybe<Skynet_DnsRequestDetail>>>;
  skynet_GetDnsSrvRecord?: Maybe<Array<Maybe<Skynet_DnsResourceRecord>>>;
  skynet_GetDnsSrvRecords?: Maybe<Array<Maybe<Skynet_DnsResourceRecord>>>;
  skynet_GetDnsTxtRecord?: Maybe<Array<Maybe<Skynet_DnsResourceRecord>>>;
  skynet_GetDnsTxtRecords?: Maybe<Array<Maybe<Skynet_DnsResourceRecord>>>;
  skynet_GetDnsZone?: Maybe<Skynet_DnsZone>;
  skynet_GetDnsZones?: Maybe<Array<Maybe<Skynet_DnsZone>>>;
  skynet_GetEmployeeSeatLocations?: Maybe<Array<Maybe<Skynet_EmployeeSeatLocation>>>;
  skynet_GetEnvironment?: Maybe<Skynet_Environment>;
  skynet_GetEnvironments?: Maybe<Array<Maybe<Skynet_Environment>>>;
  skynet_GetExchangeMailbox?: Maybe<Skynet_ExchangeMailbox>;
  skynet_GetExchangeMailboxRules?: Maybe<Array<Maybe<Skynet_ExchangeMailboxRule>>>;
  skynet_GetExchangeMailboxes?: Maybe<Array<Maybe<Skynet_ExchangeMailbox>>>;
  skynet_GetFunction?: Maybe<Skynet_Function>;
  skynet_GetFunctions?: Maybe<Array<Maybe<Skynet_Function>>>;
  skynet_GetHomeDirectories?: Maybe<Array<Maybe<Skynet_HomeDir>>>;
  skynet_GetIssuedCertificates?: Maybe<Array<Maybe<Skynet_CertificateInfo>>>;
  skynet_GetJob?: Maybe<Skynet_Job>;
  skynet_GetJobHistory?: Maybe<Array<Maybe<Skynet_JobHistory>>>;
  skynet_GetJobs?: Maybe<Array<Maybe<Skynet_Job>>>;
  skynet_GetKvstoreColllection?: Maybe<Scalars["String"]>;
  skynet_GetKvstoreColllectionRecord?: Maybe<Scalars["String"]>;
  skynet_GetKvstoreColllectionRecords?: Maybe<Scalars["String"]>;
  skynet_GetKvstoreColllections?: Maybe<Scalars["String"]>;
  skynet_GetLinuxAutoMount?: Maybe<Skynet_LinuxAutoMount>;
  skynet_GetLinuxAutoMountMap?: Maybe<Skynet_LinuxAutoMountMap>;
  skynet_GetLinuxAutoMountMaps?: Maybe<Array<Maybe<Skynet_LinuxAutoMountMap>>>;
  skynet_GetLinuxAutoMounts?: Maybe<Array<Maybe<Skynet_LinuxAutoMount>>>;
  skynet_GetLinuxGroup?: Maybe<Skynet_LinuxGroup>;
  skynet_GetLinuxGroupMembers?: Maybe<Array<Maybe<Skynet_LinuxGroupMember>>>;
  skynet_GetLinuxGroups?: Maybe<Array<Maybe<Skynet_LinuxGroup>>>;
  skynet_GetLinuxNetgroup?: Maybe<Skynet_LinuxNetgroup>;
  skynet_GetLinuxNetgroupMembers?: Maybe<Array<Maybe<Skynet_LinuxGroupMember>>>;
  skynet_GetLinuxNetgroups?: Maybe<Array<Maybe<Skynet_LinuxNetgroup>>>;
  skynet_GetLinuxSudoRole?: Maybe<Skynet_LinuxSudoRole>;
  skynet_GetLinuxSudoRoles?: Maybe<Array<Maybe<Skynet_LinuxSudoRole>>>;
  skynet_GetLinuxUser?: Maybe<Skynet_LinuxUser>;
  skynet_GetLinuxUserGroups?: Maybe<Array<Maybe<Skynet_LinuxGroup>>>;
  skynet_GetLinuxUserNetGroups?: Maybe<Array<Maybe<Skynet_LinuxNetgroup>>>;
  skynet_GetLinuxUsers?: Maybe<Array<Maybe<Skynet_LinuxUser>>>;
  skynet_GetLocation?: Maybe<Skynet_Location>;
  skynet_GetLocations?: Maybe<Array<Maybe<Skynet_Location>>>;
  skynet_GetMacUsers?: Maybe<Array<Maybe<Skynet_MacUser>>>;
  skynet_GetMailboxAccess?: Maybe<Array<Maybe<Skynet_ExchangeMailboxAccess>>>;
  skynet_GetMailboxEmail?: Maybe<Array<Maybe<Skynet_ExchangeMailboxEmail>>>;
  skynet_GetMobileDevices?: Maybe<Array<Maybe<Skynet_MobileDevice>>>;
  skynet_GetMobileUser?: Maybe<Skynet_MobileUser>;
  skynet_GetMobileUsers?: Maybe<Array<Maybe<Skynet_MobileUser>>>;
  skynet_GetMobileUsersDevice?: Maybe<Array<Maybe<Skynet_MobileDevice>>>;
  skynet_GetNetworkAdapter?: Maybe<Skynet_ComputerNetworkAdapter>;
  skynet_GetNetworkAdapters?: Maybe<Array<Maybe<Skynet_ComputerNetworkAdapter>>>;
  skynet_GetNextServerNames?: Maybe<Array<Maybe<Skynet_ServerName>>>;
  skynet_GetNlyteAuthToken?: Maybe<Scalars["String"]>;
  skynet_GetNlyteChassis?: Maybe<Array<Maybe<Skynet_NlyteChassis>>>;
  skynet_GetNlyteKvmSwitches?: Maybe<Array<Maybe<Skynet_NlyteKvmSwitch>>>;
  skynet_GetNlyteNetworks?: Maybe<Array<Maybe<Skynet_NlyteNetwork>>>;
  skynet_GetNlytePeripherals?: Maybe<Array<Maybe<Skynet_NlytePeripheral>>>;
  skynet_GetNlytePowerStrips?: Maybe<Array<Maybe<Skynet_NlytePowerStrip>>>;
  skynet_GetNlyteServers?: Maybe<Array<Maybe<Skynet_NlyteServer>>>;
  skynet_GetOktaGroup?: Maybe<Skynet_OktaGroup>;
  skynet_GetOktaGroupMembers?: Maybe<Array<Maybe<Skynet_OktaUser>>>;
  skynet_GetOktaGroups?: Maybe<Array<Maybe<Skynet_OktaGroup>>>;
  skynet_GetOktaUser?: Maybe<Skynet_OktaUser>;
  skynet_GetOktaUserFactors?: Maybe<Array<Maybe<Skynet_OktaFactor>>>;
  skynet_GetOktaUsers?: Maybe<Array<Maybe<Skynet_OktaUser>>>;
  skynet_GetOperatingSystem?: Maybe<Skynet_OperatingSystem>;
  skynet_GetOperatingSystems?: Maybe<Array<Maybe<Skynet_OperatingSystem>>>;
  skynet_GetPagerDutyContactMethods?: Maybe<Array<Maybe<Skynet_PagerDutyContactMethod>>>;
  skynet_GetPagerDutyService?: Maybe<Skynet_PagerDutyService>;
  skynet_GetPagerDutyServiceIncidents?: Maybe<Array<Maybe<Skynet_PagerDutyIncident>>>;
  skynet_GetPagerDutyServices?: Maybe<Array<Maybe<Skynet_PagerDutyService>>>;
  skynet_GetPagerDutyTeam?: Maybe<Skynet_PagerDutyTeam>;
  skynet_GetPagerDutyTeamIncidents?: Maybe<Array<Maybe<Skynet_PagerDutyIncident>>>;
  skynet_GetPagerDutyTeams?: Maybe<Array<Maybe<Skynet_PagerDutyTeam>>>;
  skynet_GetPagerDutyUser?: Maybe<Skynet_PagerDutyUser>;
  skynet_GetPagerDutyUsers?: Maybe<Array<Maybe<Skynet_PagerDutyUser>>>;
  skynet_GetPeoplesoftFinancialsAccounts?: Maybe<Array<Maybe<Skynet_Account>>>;
  skynet_GetPeoplesoftFinancialsDepartments?: Maybe<Array<Maybe<Skynet_Department>>>;
  skynet_GetReservationDhcpOption?: Maybe<Array<Maybe<Skynet_DhcpOption>>>;
  skynet_GetSccmAddRemove?: Maybe<Array<Maybe<Skynet_SccmAddRemove>>>;
  skynet_GetSccmApplications?: Maybe<Array<Maybe<Skynet_SccmApplication>>>;
  skynet_GetSccmAutoRuns?: Maybe<Array<Maybe<Skynet_SccmAutoruns>>>;
  skynet_GetSccmAutostartInfo?: Maybe<Array<Maybe<Skynet_SccmAutostart>>>;
  skynet_GetSccmBho?: Maybe<Array<Maybe<Skynet_SccmBho>>>;
  skynet_GetSccmBios?: Maybe<Array<Maybe<Skynet_SccmBios>>>;
  skynet_GetSccmBloombergInfo?: Maybe<Array<Maybe<Skynet_SccmBloomberg>>>;
  skynet_GetSccmCollection?: Maybe<Skynet_SccmCollection>;
  skynet_GetSccmCollectionMembership?: Maybe<Array<Maybe<Skynet_SccmCollectionMember>>>;
  skynet_GetSccmCollections?: Maybe<Array<Maybe<Skynet_SccmCollection>>>;
  skynet_GetSccmDisks?: Maybe<Array<Maybe<Skynet_SccmDisk>>>;
  skynet_GetSccmLocalGroupMembership?: Maybe<Array<Maybe<Skynet_SccmLocalGroupMembers>>>;
  skynet_GetSccmLocalGroupReport?: Maybe<Array<Maybe<Skynet_SccmLocalGroupReport>>>;
  skynet_GetSccmLogicalDisks?: Maybe<Array<Maybe<Skynet_SccmLogicalDisk>>>;
  skynet_GetSccmMappedDrives?: Maybe<Array<Maybe<Skynet_SccmMappedDrive>>>;
  skynet_GetSccmMappedPrinters?: Maybe<Array<Maybe<Skynet_SccmMappedPrinter>>>;
  skynet_GetSccmNetworkAdapters?: Maybe<Array<Maybe<Skynet_SccmNetworkAdapter>>>;
  skynet_GetSccmOperatingSystems?: Maybe<Array<Maybe<Skynet_SccmOperatingSystem>>>;
  skynet_GetSccmPhysicalDisks?: Maybe<Array<Maybe<Skynet_SccmPhysicalDisk>>>;
  skynet_GetSccmPhysicalMemory?: Maybe<Array<Maybe<Skynet_SccmPhysicalMemory>>>;
  skynet_GetSccmPnpDeviceDrivers?: Maybe<Array<Maybe<Skynet_SccmPnpDeviceDriver>>>;
  skynet_GetSccmProcessors?: Maybe<Array<Maybe<Skynet_SccmProcessor>>>;
  skynet_GetSccmRecentlyUsedApplications?: Maybe<Array<Maybe<Skynet_SccmRecentlyUsedApplications>>>;
  skynet_GetSccmScsiControllers?: Maybe<Array<Maybe<Skynet_SccmScsiController>>>;
  skynet_GetSccmServerFeatures?: Maybe<Array<Maybe<Skynet_SccmServerFeature>>>;
  skynet_GetSccmSoftware?: Maybe<Array<Maybe<Skynet_SccmSoftware>>>;
  skynet_GetSccmSoftwareUpdates?: Maybe<Array<Maybe<Skynet_SccmSoftwareUpdate>>>;
  skynet_GetSccmSystem?: Maybe<Skynet_SccmSystem>;
  skynet_GetSccmSystems?: Maybe<Array<Maybe<Skynet_SccmSystem>>>;
  skynet_GetSccmUsbDevices?: Maybe<Array<Maybe<Skynet_SccmUsbDevice>>>;
  skynet_GetSccmUserProfiles?: Maybe<Array<Maybe<Skynet_SccmUserProfile>>>;
  skynet_GetSccmVideoControllers?: Maybe<Array<Maybe<Skynet_SccmVideoController>>>;
  skynet_GetScopeDhcpOption?: Maybe<Array<Maybe<Skynet_DhcpOption>>>;
  skynet_GetServerDhcpOption?: Maybe<Array<Maybe<Skynet_DhcpOption>>>;
  skynet_GetServerName?: Maybe<Skynet_ServerName>;
  skynet_GetServerNames?: Maybe<Array<Maybe<Skynet_ServerName>>>;
  skynet_GetServiceNowAdHocRequest?: Maybe<Skynet_AdHocTicketResponse>;
  skynet_GetServiceNowAllActiveHostsRollup?: Maybe<Array<Maybe<Skynet_ServiceNowHost>>>;
  skynet_GetServiceNowAllHostsRollup?: Maybe<Array<Maybe<Skynet_ServiceNowHost>>>;
  skynet_GetServiceNowAssets?: Maybe<Array<Maybe<Skynet_ServiceNowAsset>>>;
  skynet_GetServiceNowDevUsers?: Maybe<Array<Maybe<Skynet_ServiceNowUser>>>;
  skynet_GetSlackChannel?: Maybe<Skynet_SlackConversationInfo>;
  skynet_GetSlackChannels?: Maybe<Array<Maybe<Skynet_SlackChannel>>>;
  skynet_GetSlackGroup?: Maybe<Skynet_SlackConversationInfo>;
  skynet_GetSlackGroups?: Maybe<Array<Maybe<Skynet_SlackChannel>>>;
  skynet_GetSlackIms?: Maybe<Array<Maybe<Skynet_SlackChannel>>>;
  skynet_GetSlackMpims?: Maybe<Array<Maybe<Skynet_SlackChannel>>>;
  skynet_GetSlackUserChannels?: Maybe<Array<Maybe<Skynet_SlackChannel>>>;
  skynet_GetSlackUserGroups?: Maybe<Array<Maybe<Skynet_SlackChannel>>>;
  skynet_GetSlackUsers?: Maybe<Array<Maybe<Skynet_SlackUser>>>;
  skynet_GetSlackWorkspaceChannels?: Maybe<Array<Maybe<Skynet_SlackChannel>>>;
  skynet_GetSlackWorkspaceGroups?: Maybe<Array<Maybe<Skynet_SlackChannel>>>;
  skynet_GetSlackWorkspaces?: Maybe<Array<Maybe<Skynet_SlackWorkspace>>>;
  skynet_GetSnowflakeSecurityRequest?: Maybe<Skynet_SnowflakeSecurityRequest>;
  skynet_GetSnowflakeSecurityRequests?: Maybe<Array<Maybe<Skynet_SnowflakeSecurityRequest>>>;
  skynet_GetSoftwareInstallations?: Maybe<Array<Maybe<Skynet_SccmSystem>>>;
  skynet_GetSqlDatabaseLogin?: Maybe<Skynet_SqlLogin>;
  skynet_GetSqlDatabaseLoginMappings?: Maybe<Array<Maybe<Skynet_SqlLoginDatabaseMapping>>>;
  skynet_GetSqlDatabaseLogins?: Maybe<Array<Maybe<Skynet_SqlLogin>>>;
  skynet_GetSybaseLogins?: Maybe<Array<Maybe<Skynet_SybaseLogin>>>;
  skynet_GetSymphonyRooms?: Maybe<Array<Maybe<Skynet_SymphonyRoom>>>;
  skynet_GetSymphonyUser?: Maybe<Skynet_SymphonyUser>;
  skynet_GetSymphonyUsers?: Maybe<Array<Maybe<Skynet_SymphonyUser>>>;
  skynet_GetTidalWorkgroup?: Maybe<Array<Maybe<Skynet_Workgroup>>>;
  skynet_GetUcsIpAddress?: Maybe<Skynet_UcsAddressResponse>;
  skynet_GetUcsIpAddressPools?: Maybe<Array<Maybe<Skynet_UcsIpAddressPool>>>;
  skynet_GetUcsTemplate?: Maybe<Skynet_UcsTemplate>;
  skynet_GetUcsTemplates?: Maybe<Array<Maybe<Skynet_UcsTemplate>>>;
  skynet_GetUsbBiosList?: Maybe<Array<Maybe<Skynet_UsbBios>>>;
  skynet_GetVirusTotalInfo?: Maybe<Skynet_Virustotal>;
  skynet_GetWindowsAcl?: Maybe<Array<Maybe<Skynet_WindowsAcl>>>;
  skynet_GetWindowsComputer?: Maybe<Skynet_WindowsComputer>;
  skynet_GetWindowsComputerGroupMembership?: Maybe<Array<Maybe<Skynet_WindowsGroupMember>>>;
  skynet_GetWindowsComputerGroups?: Maybe<Array<Maybe<Skynet_WindowsLocalGroup>>>;
  skynet_GetWindowsComputerHeartbeat?: Maybe<Skynet_WindowsComputerHeartbeat>;
  skynet_GetWindowsComputerHeartbeats?: Maybe<Array<Maybe<Skynet_WindowsComputerHeartbeat>>>;
  skynet_GetWindowsComputerLogins?: Maybe<Array<Maybe<Skynet_WindowsUserLoginSession>>>;
  skynet_GetWindowsComputerNetworkAdapterStatistics?: Maybe<Skynet_PowerShellNetAdapterStatistics>;
  skynet_GetWindowsComputerPowerShellNetAdapters?: Maybe<Array<Maybe<Skynet_PowerShellNetworkAdapter>>>;
  skynet_GetWindowsComputerProcessDetail?: Maybe<Skynet_WindowsComputerProcessDetail>;
  skynet_GetWindowsComputerProcesses?: Maybe<Array<Maybe<Skynet_WindowsComputerProcess>>>;
  skynet_GetWindowsComputerServiceDetail?: Maybe<Skynet_WindowsComputerServiceDetail>;
  skynet_GetWindowsComputerServices?: Maybe<Array<Maybe<Skynet_WindowsComputerService>>>;
  skynet_GetWindowsComputerTerminalServerSettings?: Maybe<Skynet_WindowsComputerTerminalServiceSettings>;
  skynet_GetWindowsComputerUsers?: Maybe<Array<Maybe<Scalars["String"]>>>;
  skynet_GetWindowsComputers?: Maybe<Array<Maybe<Skynet_WindowsComputer>>>;
  skynet_GetWindowsConferenceRooms?: Maybe<Array<Maybe<Skynet_WindowsConferenceRoom>>>;
  skynet_GetWindowsDisabledUsers?: Maybe<Array<Maybe<Skynet_WindowsUser>>>;
  skynet_GetWindowsDynamicGroup?: Maybe<Skynet_WindowsDynamicGroup>;
  skynet_GetWindowsDynamicGroupMembers?: Maybe<Array<Maybe<Skynet_WindowsGroupMember>>>;
  skynet_GetWindowsDynamicGroups?: Maybe<Array<Maybe<Skynet_WindowsDynamicGroup>>>;
  skynet_GetWindowsEASDevices?: Maybe<Array<Maybe<Skynet_WindowsUserEasDevice>>>;
  skynet_GetWindowsGroup?: Maybe<Skynet_WindowsGroup>;
  skynet_GetWindowsGroupChangeHistory?: Maybe<Array<Maybe<Skynet_WindowsObjectChangeEvent>>>;
  skynet_GetWindowsGroupMembers?: Maybe<Array<Maybe<Skynet_WindowsGroupMember>>>;
  skynet_GetWindowsGroups?: Maybe<Array<Maybe<Skynet_WindowsGroup>>>;
  skynet_GetWindowsLocalGroup?: Maybe<Skynet_WindowsLocalGroup>;
  skynet_GetWindowsLocalGroupMembership?: Maybe<Array<Maybe<Skynet_WindowsGroupMember>>>;
  skynet_GetWindowsLocalGroups?: Maybe<Array<Maybe<Skynet_WindowsLocalGroup>>>;
  skynet_GetWindowsManagedServiceAccount?: Maybe<Skynet_WindowsManagedServiceAccount>;
  skynet_GetWindowsManagedServiceAccounts?: Maybe<Array<Maybe<Skynet_WindowsManagedServiceAccount>>>;
  skynet_GetWindowsRdpInformation?: Maybe<Array<Maybe<Skynet_WindowsComputerSessionInformation>>>;
  skynet_GetWindowsRdpMetrics?: Maybe<Array<Maybe<Skynet_WindowsPerformanceMetric>>>;
  skynet_GetWindowsUser?: Maybe<Skynet_WindowsUser>;
  skynet_GetWindowsUserChangeHistory?: Maybe<Array<Maybe<Skynet_WindowsObjectChangeEvent>>>;
  skynet_GetWindowsUserComputers?: Maybe<Array<Maybe<Skynet_WindowsUserComputer>>>;
  skynet_GetWindowsUserGatewaySessions?: Maybe<Array<Maybe<Skynet_WindowsUserGatewaySession>>>;
  skynet_GetWindowsUserGroups?: Maybe<Array<Maybe<Skynet_WindowsUserGroup>>>;
  skynet_GetWindowsUserLocalGroups?: Maybe<Array<Maybe<Skynet_WindowsUserLocalGroupResponse>>>;
  skynet_GetWindowsUserLogins?: Maybe<Array<Maybe<Skynet_WindowsUserLoginSession>>>;
  skynet_GetWindowsUserPrincipals?: Maybe<Array<Maybe<Skynet_WindowsUserPrincipal>>>;
  skynet_GetWindowsUserRestrictedHosts?: Maybe<Array<Maybe<Scalars["String"]>>>;
  skynet_GetWindowsUsers?: Maybe<Array<Maybe<Skynet_WindowsUser>>>;
  skynet_GetWindowsUsersById?: Maybe<Array<Maybe<Skynet_WindowsUser>>>;
  skynet_GetWorkdayComapnies?: Maybe<Array<Maybe<Skynet_WorkdayCompany>>>;
  skynet_GetWorkdayCorporateSensitivity?: Maybe<Array<Maybe<Skynet_WorkdayCorporateSensitivity>>>;
  skynet_GetWorkdayDepartments?: Maybe<Array<Maybe<Skynet_WorkdayDepartment>>>;
  skynet_GetWorkdayFutureTransfers?: Maybe<Array<Maybe<Skynet_WorkdayFutureTransfer>>>;
  skynet_GetWorkdayJobFamilyAndParents?: Maybe<Array<Maybe<Skynet_WorkdayJobFamilyAndParents>>>;
  skynet_GetWorkdayLocations?: Maybe<Array<Maybe<Skynet_WorkdayLocation>>>;
  skynet_GetWorkdayPeople?: Maybe<Array<Maybe<Skynet_WorkdayPerson>>>;
  skynet_GetWorkdayQuarterlyRoleDescriptions?: Maybe<Array<Maybe<Skynet_WorkdayQuarterlyRoleDescription>>>;
  skynet_GetWorkdayUpdate?: Maybe<Array<Maybe<Skynet_WorkdayUpdate>>>;
  skynet_GetZabbixGroupMembership?: Maybe<Array<Maybe<Skynet_ZabbixUser>>>;
  skynet_GetZabbixGroups?: Maybe<Array<Maybe<Skynet_ZabbixGroup>>>;
  skynet_GetZabbixHostGroups?: Maybe<Array<Maybe<Skynet_ZabbixHostGroup>>>;
  skynet_GetZabbixHostGroupsHosts?: Maybe<Array<Maybe<Skynet_ZabbixHost>>>;
  skynet_GetZabbixHosts?: Maybe<Array<Maybe<Skynet_ZabbixHost>>>;
  skynet_GetZabbixMaintenance?: Maybe<Skynet_ZabbixMaintenance>;
  skynet_GetZabbixMaintenances?: Maybe<Array<Maybe<Skynet_ZabbixMaintenance>>>;
  skynet_GetZabbixTrigger?: Maybe<Skynet_ZabbixTrigger>;
  skynet_GetZabbixTriggers?: Maybe<Array<Maybe<Skynet_ZabbixTrigger>>>;
  skynet_GetZabbixUser?: Maybe<Skynet_ZabbixUser>;
  skynet_GetZabbixUsers?: Maybe<Array<Maybe<Skynet_ZabbixUser>>>;
  skynet_ListSymphonyProtocols?: Maybe<Array<Maybe<Scalars["String"]>>>;
  skynet_ListSymphonyRoomMembership?: Maybe<Array<Maybe<Skynet_SymphonyRoomResponse>>>;
  skynet_LiveGetLinuxGroup?: Maybe<Skynet_LinuxGroup>;
  skynet_LiveGetLinuxGroupMembers?: Maybe<Array<Maybe<Skynet_LinuxGroupMember>>>;
  skynet_LiveGetLinuxNetgroup?: Maybe<Skynet_LinuxNetgroup>;
  skynet_LiveGetLinuxNetgroupMembers?: Maybe<Array<Maybe<Skynet_LinuxGroupMember>>>;
  skynet_LiveGetLinuxUser?: Maybe<Skynet_LinuxUser>;
  skynet_LiveGetWindowsDynamicGroup?: Maybe<Skynet_WindowsDynamicGroup>;
  skynet_LiveGetWindowsDynamicGroupMembers?: Maybe<Array<Maybe<Skynet_WindowsGroupMember>>>;
  skynet_LiveGetWindowsDynamicGroups?: Maybe<Array<Maybe<Skynet_WindowsDynamicGroup>>>;
  skynet_LiveGetWindowsGroup?: Maybe<Skynet_WindowsGroup>;
  skynet_LiveGetWindowsGroupMembers?: Maybe<Array<Maybe<Skynet_WindowsGroupMember>>>;
  skynet_LiveGetWindowsUser?: Maybe<Skynet_WindowsUser>;
  skynet_LiveGetWindowsUserGroups?: Maybe<Array<Maybe<Skynet_WindowsUserGroup>>>;
  skynet_LiveSearchLinuxGroups?: Maybe<Array<Maybe<Skynet_LinuxGroup>>>;
  skynet_LiveSearchLinuxNetgroups?: Maybe<Array<Maybe<Skynet_LinuxNetgroup>>>;
  skynet_LiveSearchLinuxUsers?: Maybe<Array<Maybe<Skynet_LinuxUser>>>;
  skynet_LiveSearchWindowsGroups?: Maybe<Array<Maybe<Skynet_WindowsGroup>>>;
  skynet_LiveSearchWindowsUsers?: Maybe<Array<Maybe<Skynet_WindowsUser>>>;
  skynet_MailDisableWindowsGroup?: Maybe<Skynet_WindowsGroup>;
  skynet_MigrateSymphonyRoom?: Maybe<Skynet_Job>;
  skynet_RemoveUsersFromSymphonyRoom?: Maybe<Skynet_SymphonyRoomResponse>;
  skynet_RemoveWindowsManagedServiceAccountPrincipals?: Maybe<Array<Maybe<Skynet_WindowsUserPrincipal>>>;
  skynet_ReserveSolwarwindsIp?: Maybe<Skynet_SolarwindsAvailableIpResponse>;
  skynet_RestartWindowsComputerService?: Maybe<Skynet_WindowsComputerService>;
  skynet_ScheduleDnsRequest?: Maybe<Skynet_Job>;
  skynet_SearchActiveDirectoryComputers?: Maybe<ActiveDirectoryComputerQueryResponse>;
  skynet_SearchAmLinuxComputers?: Maybe<AmLinuxComputerQueryResponse>;
  skynet_SearchAssetLocation?: Maybe<AssetLocationQueryResponse>;
  skynet_SearchAssets?: Maybe<AssetQueryResponse>;
  skynet_SearchCsLinuxComputers?: Maybe<CsLinuxComputerQueryResponse>;
  skynet_SearchDhcpLeases?: Maybe<DhcpLeaseQueryResponse>;
  skynet_SearchDhcpReservations?: Maybe<DhcpReservationQueryResponse>;
  skynet_SearchDhcpScopes?: Maybe<DhcpScopeQueryResponse>;
  skynet_SearchDhcpServerLeases?: Maybe<DhcpLeaseQueryResponse>;
  skynet_SearchDnsARecords?: Maybe<DnsResourceRecordQueryResponse>;
  skynet_SearchDnsCnameRecords?: Maybe<DnsResourceRecordQueryResponse>;
  skynet_SearchDnsNsRecords?: Maybe<DnsResourceRecordQueryResponse>;
  skynet_SearchDnsPtrRecords?: Maybe<DnsResourceRecordQueryResponse>;
  skynet_SearchDnsSrvRecords?: Maybe<DnsResourceRecordQueryResponse>;
  skynet_SearchDnsTxtRecords?: Maybe<DnsResourceRecordQueryResponse>;
  skynet_SearchDnsZones?: Maybe<DnsZoneQueryResponse>;
  skynet_SearchExchangeMailboxes?: Maybe<ExchangeMailboxQueryResponse>;
  skynet_SearchFunctions?: Maybe<FunctionQueryResponse>;
  skynet_SearchIssuedCertificates?: Maybe<CertificateInfoQueryResponse>;
  skynet_SearchJobs?: Maybe<JobQueryResponse>;
  skynet_SearchLinuxGroup?: Maybe<LinuxGroupQueryResponse>;
  skynet_SearchLinuxNetgroups?: Maybe<LinuxNetgroupQueryResponse>;
  skynet_SearchLinuxSudoRoles?: Maybe<LinuxSudoRoleQueryResponse>;
  skynet_SearchLinuxUsers?: Maybe<LinuxUserQueryResponse>;
  skynet_SearchMobileDevices?: Maybe<MobileDeviceQueryResponse>;
  skynet_SearchMobileUsers?: Maybe<MobileUserQueryResponse>;
  skynet_SearchOktaUsers?: Maybe<OktaUserQueryResponse>;
  skynet_SearchOrgMap?: Maybe<OrgMapQueryResponse>;
  skynet_SearchPagerDutyServiceIncidents?: Maybe<PagerDutyIncidentQueryResponse>;
  skynet_SearchPagerDutyServices?: Maybe<PagerDutyServiceQueryResponse>;
  skynet_SearchPagerDutyTeamIncidents?: Maybe<PagerDutyIncidentQueryResponse>;
  skynet_SearchPagerDutyTeams?: Maybe<PagerDutyTeamQueryResponse>;
  skynet_SearchPagerDutyUsers?: Maybe<PagerDutyUserQueryResponse>;
  skynet_SearchSccmApplications?: Maybe<SccmApplicationQueryResponse>;
  skynet_SearchSccmCollections?: Maybe<SccmCollectionQueryResponse>;
  skynet_SearchSccmLocalGroupReport?: Maybe<SccmLocalGroupReportQueryResponse>;
  skynet_SearchSccmSoftware?: Maybe<SccmSoftwareQueryResponse>;
  skynet_SearchSccmSystems?: Maybe<SccmSystemQueryResponse>;
  skynet_SearchServerName?: Maybe<ServerNameQueryResponse>;
  skynet_SearchServiceNowUsers?: Maybe<ServiceNowUserQueryResponse>;
  skynet_SearchSlackChannels?: Maybe<SlackChannelQueryResponse>;
  skynet_SearchSlackGroups?: Maybe<SlackChannelQueryResponse>;
  skynet_SearchSlackIms?: Maybe<SlackChannelQueryResponse>;
  skynet_SearchSlackMpims?: Maybe<SlackChannelQueryResponse>;
  skynet_SearchSlackUsers?: Maybe<SlackUserQueryResponse>;
  skynet_SearchSlackWorkspaces?: Maybe<SlackWorkspaceQueryResponse>;
  skynet_SearchUcsIpAddressPools?: Maybe<UcsIpAddressPoolQueryResponse>;
  skynet_SearchWindowsComputers?: Maybe<WindowsComputerQueryResponse>;
  skynet_SearchWindowsDisabledUsers?: Maybe<WindowsUserQueryResponse>;
  skynet_SearchWindowsDynamicGroups?: Maybe<WindowsDynamicGroupQueryResponse>;
  skynet_SearchWindowsGroups?: Maybe<WindowsGroupQueryResponse>;
  skynet_SearchWindowsUsers?: Maybe<WindowsUserQueryResponse>;
  skynet_SearchWindowsUsersById?: Maybe<WindowsUserQueryResponse>;
  skynet_SearchWorkdayDepartments?: Maybe<WorkdayDepartmentQueryResponse>;
  skynet_SearchWorkdayLocations?: Maybe<WorkdayLocationQueryResponse>;
  skynet_SearchWorkdayPeople?: Maybe<WorkdayPersonQueryResponse>;
  skynet_SearchWorkdayQuarterlyRoleDescriptions?: Maybe<WorkdayQuarterlyRoleDescriptionQueryResponse>;
  skynet_SearchZabbixHostGroups?: Maybe<ZabbixHostGroupQueryResponse>;
  skynet_SearchZabbixHosts?: Maybe<ZabbixHostQueryResponse>;
  skynet_SearchZabbixTriggers?: Maybe<ZabbixTriggerQueryResponse>;
  skynet_SearchZabbixUsers?: Maybe<ZabbixUserQueryResponse>;
  skynet_SearchtDnsMxRecords?: Maybe<DnsResourceRecordQueryResponse>;
  skynet_SetExchangeMailbox?: Maybe<Skynet_ExchangeMailbox>;
  skynet_SetHomeDirectoryLegalHold?: Maybe<Skynet_LegalHoldResponse>;
  skynet_StartWindowsComputerService?: Maybe<Skynet_WindowsComputerService>;
  skynet_StopWindowsComputerService?: Maybe<Skynet_WindowsComputerService>;
  skynet_SuggestWindowsGroups?: Maybe<WindowsGroupQueryResponse>;
  skynet_SuggestWindowsUsers?: Maybe<WindowsUserQueryResponse>;
  skynet_SuggestWorkdayPeople?: Maybe<WorkdayPersonQueryResponse>;
  skynet_SuppressSymphonyMessage?: Maybe<Skynet_SymphonySuppressionResponse>;
  skynet_UpdateBastionAccount?: Maybe<Skynet_BastionAccount>;
  skynet_UpdateCCureUser?: Maybe<Skynet_CCureUser>;
  skynet_UpdateDfsFolderTarget?: Maybe<Skynet_DfsTarget>;
  skynet_UpdateDfsRoot?: Maybe<Skynet_DfsRoot>;
  skynet_UpdateDfsRootFolder?: Maybe<Skynet_DfsFolder>;
  skynet_UpdateDhcpReservationOption?: Maybe<Skynet_DhcpOption>;
  skynet_UpdateDhcpScope?: Maybe<Skynet_DhcpScope>;
  skynet_UpdateDhcpScopeOption?: Maybe<Skynet_DhcpOption>;
  skynet_UpdateDhcpServerOption?: Maybe<Skynet_DhcpOption>;
  skynet_UpdateLinuxAutoMount?: Maybe<Skynet_LinuxAutoMount>;
  skynet_UpdateLinuxAutoMountMap?: Maybe<Skynet_LinuxAutoMountMap>;
  skynet_UpdateLinuxGroup?: Maybe<Skynet_LinuxGroup>;
  skynet_UpdateLinuxNetGroup?: Maybe<Skynet_LinuxNetgroup>;
  skynet_UpdateLinuxSudoRole?: Maybe<Skynet_LinuxSudoRole>;
  skynet_UpdateLinuxUser?: Maybe<Skynet_LinuxUser>;
  skynet_UpdateMacUser?: Maybe<Skynet_MacUser>;
  skynet_UpdateSnowflakeSecurityRequest?: Maybe<Skynet_SnowflakeSecurityRequest>;
  skynet_UpdateWindowsGroup?: Maybe<Skynet_WindowsGroup>;
  skynet_UpdateWindowsManagedServiceAccount?: Maybe<Skynet_WindowsManagedServiceAccount>;
  skynet_UpdateWindowsUser?: Maybe<Skynet_WindowsUser>;
  skynet_UpdateWindowsUserRestrictedHosts?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type QueryCdx_AdGroupMembersArgs = {
  groupname?: Maybe<Scalars["String"]>;
};

export type QueryCdx_FindGroupsFuzzyArgs = {
  groupName?: Maybe<Scalars["String"]>;
};

export type QueryCdx_FindUserArgs = {
  username?: Maybe<Scalars["String"]>;
};

export type QueryCdx_InstrumentArgs = {
  where?: Maybe<InputSecurityFilter>;
  numDocs?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type QueryCore_AppArgs = {
  id: Scalars["ID"];
};

export type QueryCore_LdapUsersArgs = {
  filter: Core_LdapUserFilter;
  limit?: Maybe<Scalars["Int"]>;
};

export type QueryCore_LinkArgs = {
  id: Scalars["ID"];
  appName: Scalars["ID"];
};

export type QueryCore_UserArgs = {
  username: Scalars["String"];
};

export type QueryDataFrameArgs = {
  id: Scalars["String"];
  params?: Maybe<Array<DataFrameParamsInput>>;
};

export type QuerySkynet_AddMobileDeviceArgs = {
  request?: Maybe<Inputskynet_AddMobileDevice>;
};

export type QuerySkynet_AddMobileUserArgs = {
  request?: Maybe<Inputskynet_AddMobileUser>;
};

export type QuerySkynet_AddUsersToSymphonyRoomArgs = {
  request?: Maybe<Inputskynet_AddUsersToSymphonyRoom>;
};

export type QuerySkynet_AddWindowsManagedServiceAccountPrincipalsArgs = {
  request?: Maybe<Inputskynet_AddWindowsManagedServiceAccountPrincipals>;
};

export type QuerySkynet_AddWindowsUserPrincipalsArgs = {
  request?: Maybe<Inputskynet_AddWindowsUserPrincipals>;
};

export type QuerySkynet_AddZabbixGroupMemberArgs = {
  request?: Maybe<Inputskynet_AddZabbixGroupMember>;
};

export type QuerySkynet_CreateAirwatchMessageArgs = {
  request?: Maybe<Inputskynet_CreateAirwatchMessage>;
};

export type QuerySkynet_CreateBastionAccountArgs = {
  request?: Maybe<Inputskynet_CreateBastionAccount>;
};

export type QuerySkynet_CreateBastionDeviceArgs = {
  request?: Maybe<Inputskynet_CreateBastionDevice>;
};

export type QuerySkynet_CreateBastionUserGroupArgs = {
  request?: Maybe<Inputskynet_CreateBastionUserGroup>;
};

export type QuerySkynet_CreateCCureUserArgs = {
  request?: Maybe<Inputskynet_CreateCCureUser>;
};

export type QuerySkynet_CreateCertificateRequestArgs = {
  request?: Maybe<Inputskynet_CreateCertificateRequest>;
};

export type QuerySkynet_CreateDataPrivilegeDirectPermissionRequestArgs = {
  request?: Maybe<Inputskynet_CreateDataPrivilegeDirectPermissionRequest>;
};

export type QuerySkynet_CreateDataPrivilegeMembershipRequestArgs = {
  request?: Maybe<Inputskynet_CreateDataPrivilegeMembershipRequest>;
};

export type QuerySkynet_CreateDfsRootArgs = {
  request?: Maybe<Inputskynet_CreateDfsRoot>;
};

export type QuerySkynet_CreateDfsRootFolderArgs = {
  request?: Maybe<Inputskynet_CreateDfsRootFolder>;
};

export type QuerySkynet_CreateDfsRootFolderTargetArgs = {
  request?: Maybe<Inputskynet_CreateDfsRootFolderTarget>;
};

export type QuerySkynet_CreateDhcpReservationArgs = {
  request?: Maybe<Inputskynet_CreateDhcpReservation>;
};

export type QuerySkynet_CreateDhcpReservationOptionArgs = {
  request?: Maybe<Inputskynet_CreateDhcpReservationOption>;
};

export type QuerySkynet_CreateDhcpScopeArgs = {
  request?: Maybe<Inputskynet_CreateDhcpScope>;
};

export type QuerySkynet_CreateDhcpScopeOptionArgs = {
  request?: Maybe<Inputskynet_CreateDhcpScopeOption>;
};

export type QuerySkynet_CreateDhcpServerOptionArgs = {
  request?: Maybe<Inputskynet_CreateDhcpServerOption>;
};

export type QuerySkynet_CreateDnsARecordArgs = {
  request?: Maybe<Inputskynet_CreateDnsARecord>;
};

export type QuerySkynet_CreateDnsCnameRecordArgs = {
  request?: Maybe<Inputskynet_CreateDnsCnameRecord>;
};

export type QuerySkynet_CreateDnsMxRecordArgs = {
  request?: Maybe<Inputskynet_CreateDnsMxRecord>;
};

export type QuerySkynet_CreateDnsNsRecordArgs = {
  request?: Maybe<Inputskynet_CreateDnsNsRecord>;
};

export type QuerySkynet_CreateDnsPtrRecordArgs = {
  request?: Maybe<Inputskynet_CreateDnsPtrRecord>;
};

export type QuerySkynet_CreateDnsRequestArgs = {
  request?: Maybe<Inputskynet_CreateDnsRequest>;
};

export type QuerySkynet_CreateDnsSrvRecordArgs = {
  request?: Maybe<Inputskynet_CreateDnsSrvRecord>;
};

export type QuerySkynet_CreateDnsTxtRecordArgs = {
  request?: Maybe<Inputskynet_CreateDnsTxtRecord>;
};

export type QuerySkynet_CreateDnsZoneArgs = {
  request?: Maybe<Inputskynet_CreateDnsZone>;
};

export type QuerySkynet_CreateExternalCertificateArgs = {
  request?: Maybe<Inputskynet_CreateExternalCertificate>;
};

export type QuerySkynet_CreateFunctionArgs = {
  request?: Maybe<Inputskynet_CreateFunction>;
};

export type QuerySkynet_CreateGroupMemberArgs = {
  request?: Maybe<Inputskynet_CreateGroupMember>;
};

export type QuerySkynet_CreateHomeDirectoryArgs = {
  request?: Maybe<Inputskynet_CreateHomeDirectory>;
};

export type QuerySkynet_CreateLinuxAutoMountArgs = {
  request?: Maybe<Inputskynet_CreateLinuxAutoMount>;
};

export type QuerySkynet_CreateLinuxAutoMountMapArgs = {
  request?: Maybe<Inputskynet_CreateLinuxAutoMountMap>;
};

export type QuerySkynet_CreateLinuxGroupArgs = {
  request?: Maybe<Inputskynet_CreateLinuxGroup>;
};

export type QuerySkynet_CreateLinuxNetGroupArgs = {
  request?: Maybe<Inputskynet_CreateLinuxNetGroup>;
};

export type QuerySkynet_CreateLinuxNetgroupMemberArgs = {
  request?: Maybe<Inputskynet_CreateLinuxNetgroupMember>;
};

export type QuerySkynet_CreateLinuxSudoRoleArgs = {
  request?: Maybe<Inputskynet_CreateLinuxSudoRole>;
};

export type QuerySkynet_CreateLinuxUserArgs = {
  request?: Maybe<Inputskynet_CreateLinuxUser>;
};

export type QuerySkynet_CreateMacUserArgs = {
  request?: Maybe<Inputskynet_CreateMacUser>;
};

export type QuerySkynet_CreateMailboxAccessArgs = {
  request?: Maybe<Inputskynet_CreateMailboxAccess>;
};

export type QuerySkynet_CreateMailboxEmailArgs = {
  request?: Maybe<Inputskynet_CreateMailboxEmail>;
};

export type QuerySkynet_CreatePagerDutyEventArgs = {
  request?: Maybe<Inputskynet_CreatePagerDutyEvent>;
};

export type QuerySkynet_CreatePagerDutyUserArgs = {
  request?: Maybe<Inputskynet_CreatePagerDutyUser>;
};

export type QuerySkynet_CreateRandomPasswordArgs = {
  request?: Maybe<Inputskynet_CreateRandomPassword>;
};

export type QuerySkynet_CreateSccmCollectionMembershipArgs = {
  request?: Maybe<Inputskynet_CreateSccmCollectionMembership>;
};

export type QuerySkynet_CreateServerNameArgs = {
  request?: Maybe<Inputskynet_CreateServerName>;
};

export type QuerySkynet_CreateServiceNowAdHocRequestArgs = {
  request?: Maybe<Inputskynet_CreateServiceNowAdHocRequest>;
};

export type QuerySkynet_CreateSlackMessageArgs = {
  request?: Maybe<Inputskynet_CreateSlackMessage>;
};

export type QuerySkynet_CreateSlackUploadArgs = {
  request?: Maybe<Inputskynet_CreateSlackUpload>;
};

export type QuerySkynet_CreateSnowflakeSecurityRequestArgs = {
  request?: Maybe<Inputskynet_CreateSnowflakeSecurityRequest>;
};

export type QuerySkynet_CreateSymphonyMessageArgs = {
  request?: Maybe<Inputskynet_CreateSymphonyMessage>;
};

export type QuerySkynet_CreateSymphonyRoomArgs = {
  request?: Maybe<Inputskynet_CreateSymphonyRoom>;
};

export type QuerySkynet_CreateUcsWindowsWorkflowArgs = {
  request?: Maybe<Inputskynet_CreateUcsWindowsWorkflow>;
};

export type QuerySkynet_CreateUsbBiosArgs = {
  request?: Maybe<Inputskynet_CreateUsbBios>;
};

export type QuerySkynet_CreateVaultSecretArgs = {
  request?: Maybe<Inputskynet_CreateVaultSecret>;
};

export type QuerySkynet_CreateWindowsComputerHeartbeatArgs = {
  request?: Maybe<Inputskynet_CreateWindowsComputerHeartbeat>;
};

export type QuerySkynet_CreateWindowsGroupArgs = {
  request?: Maybe<Inputskynet_CreateWindowsGroup>;
};

export type QuerySkynet_CreateWindowsLocalGroupMemberArgs = {
  request?: Maybe<Inputskynet_CreateWindowsLocalGroupMember>;
};

export type QuerySkynet_CreateWindowsManagedServiceAccountArgs = {
  request?: Maybe<Inputskynet_CreateWindowsManagedServiceAccount>;
};

export type QuerySkynet_CreateWindowsUserArgs = {
  request?: Maybe<Inputskynet_CreateWindowsUser>;
};

export type QuerySkynet_CreateWindowsUserGroupArgs = {
  request?: Maybe<Inputskynet_CreateWindowsUserGroup>;
};

export type QuerySkynet_CreateWindowsUsersRestrictedHostArgs = {
  request?: Maybe<Inputskynet_CreateWindowsUsersRestrictedHost>;
};

export type QuerySkynet_CreateZabbixMaintenanceArgs = {
  request?: Maybe<Inputskynet_CreateZabbixMaintenance>;
};

export type QuerySkynet_CreateZabbixUserArgs = {
  request?: Maybe<Inputskynet_CreateZabbixUser>;
};

export type QuerySkynet_DeleteHomeDirectoryArgs = {
  request?: Maybe<Inputskynet_DeleteHomeDirectory>;
};

export type QuerySkynet_DeleteSccmCollectionMembershipArgs = {
  request?: Maybe<Inputskynet_DeleteSccmCollectionMembership>;
};

export type QuerySkynet_DeleteWindowsUserPrincipalsArgs = {
  request?: Maybe<Inputskynet_DeleteWindowsUserPrincipals>;
};

export type QuerySkynet_DisableHomeDirectoryArgs = {
  request?: Maybe<Inputskynet_DisableHomeDirectory>;
};

export type QuerySkynet_DisableLinuxUserArgs = {
  request?: Maybe<Inputskynet_DisableLinuxUser>;
};

export type QuerySkynet_DisableMobileUserArgs = {
  request?: Maybe<Inputskynet_DisableMobileUser>;
};

export type QuerySkynet_DisableWindowsGroupArgs = {
  request?: Maybe<Inputskynet_DisableWindowsGroup>;
};

export type QuerySkynet_EnableContactArgs = {
  request?: Maybe<Inputskynet_EnableContact>;
};

export type QuerySkynet_EnableWindowsGroupArgs = {
  request?: Maybe<Inputskynet_EnableWindowsGroup>;
};

export type QuerySkynet_GetActiveDirectoryComputerArgs = {
  request?: Maybe<Inputskynet_GetActiveDirectoryComputer>;
};

export type QuerySkynet_GetActiveDirectoryComputerChangeHistoryArgs = {
  request?: Maybe<Inputskynet_GetActiveDirectoryComputerChangeHistory>;
};

export type QuerySkynet_GetActiveDirectoryComputerGroupsArgs = {
  request?: Maybe<Inputskynet_GetActiveDirectoryComputerGroups>;
};

export type QuerySkynet_GetAmLinuxComputerMountsArgs = {
  request?: Maybe<Inputskynet_GetAmLinuxComputerMounts>;
};

export type QuerySkynet_GetAmLinuxComputerProcessesArgs = {
  request?: Maybe<Inputskynet_GetAmLinuxComputerProcesses>;
};

export type QuerySkynet_GetAmLinuxComputerSoftwareArgs = {
  request?: Maybe<Inputskynet_GetAmLinuxComputerSoftware>;
};

export type QuerySkynet_GetBastionAccountArgs = {
  request?: Maybe<Inputskynet_GetBastionAccount>;
};

export type QuerySkynet_GetBastionAccountsArgs = {
  request?: Maybe<Inputskynet_GetBastionAccounts>;
};

export type QuerySkynet_GetBastionAuthorizationsArgs = {
  request?: Maybe<Inputskynet_GetBastionAuthorizations>;
};

export type QuerySkynet_GetBastionDeviceArgs = {
  request?: Maybe<Inputskynet_GetBastionDevice>;
};

export type QuerySkynet_GetBastionDevicesArgs = {
  request?: Maybe<Inputskynet_GetBastionDevices>;
};

export type QuerySkynet_GetBastionLdapMappingsArgs = {
  request?: Maybe<Inputskynet_GetBastionLdapMappings>;
};

export type QuerySkynet_GetBastionTargetGroupArgs = {
  request?: Maybe<Inputskynet_GetBastionTargetGroup>;
};

export type QuerySkynet_GetBastionTargetGroupsArgs = {
  request?: Maybe<Inputskynet_GetBastionTargetGroups>;
};

export type QuerySkynet_GetBastionUserGroupsArgs = {
  request?: Maybe<Inputskynet_GetBastionUserGroups>;
};

export type QuerySkynet_GetBusinessResourceGroupArgs = {
  request?: Maybe<Inputskynet_GetBusinessResourceGroup>;
};

export type QuerySkynet_GetBusinessResourceGroupsArgs = {
  request?: Maybe<Inputskynet_GetBusinessResourceGroups>;
};

export type QuerySkynet_GetContactArgs = {
  request?: Maybe<Inputskynet_GetContact>;
};

export type QuerySkynet_GetCsLinuxComptuerMountsArgs = {
  request?: Maybe<Inputskynet_GetCsLinuxComptuerMounts>;
};

export type QuerySkynet_GetDataPrivilegeMembershipRequestStatusArgs = {
  request?: Maybe<Inputskynet_GetDataPrivilegeMembershipRequestStatus>;
};

export type QuerySkynet_GetDfsRootArgs = {
  request?: Maybe<Inputskynet_GetDfsRoot>;
};

export type QuerySkynet_GetDfsRootFolderTargetsArgs = {
  request?: Maybe<Inputskynet_GetDfsRootFolderTargets>;
};

export type QuerySkynet_GetDfsRootFoldersArgs = {
  request?: Maybe<Inputskynet_GetDfsRootFolders>;
};

export type QuerySkynet_GetDfsRootTargetsArgs = {
  request?: Maybe<Inputskynet_GetDfsRootTargets>;
};

export type QuerySkynet_GetDhcpFreeIpArgs = {
  request?: Maybe<Inputskynet_GetDhcpFreeIp>;
};

export type QuerySkynet_GetDhcpLeasesArgs = {
  request?: Maybe<Inputskynet_GetDhcpLeases>;
};

export type QuerySkynet_GetDhcpReservationsArgs = {
  request?: Maybe<Inputskynet_GetDhcpReservations>;
};

export type QuerySkynet_GetDhcpScopesArgs = {
  request?: Maybe<Inputskynet_GetDhcpScopes>;
};

export type QuerySkynet_GetDhcpServerLeasesArgs = {
  request?: Maybe<Inputskynet_GetDhcpServerLeases>;
};

export type QuerySkynet_GetDhcpServersByIpArgs = {
  request?: Maybe<Inputskynet_GetDhcpServersByIp>;
};

export type QuerySkynet_GetDnsARecordArgs = {
  request?: Maybe<Inputskynet_GetDnsARecord>;
};

export type QuerySkynet_GetDnsARecordsArgs = {
  request?: Maybe<Inputskynet_GetDnsARecords>;
};

export type QuerySkynet_GetDnsCnameRecordArgs = {
  request?: Maybe<Inputskynet_GetDnsCnameRecord>;
};

export type QuerySkynet_GetDnsCnameRecordsArgs = {
  request?: Maybe<Inputskynet_GetDnsCnameRecords>;
};

export type QuerySkynet_GetDnsMxRecordArgs = {
  request?: Maybe<Inputskynet_GetDnsMxRecord>;
};

export type QuerySkynet_GetDnsMxRecordsArgs = {
  request?: Maybe<Inputskynet_GetDnsMxRecords>;
};

export type QuerySkynet_GetDnsNsRecordArgs = {
  request?: Maybe<Inputskynet_GetDnsNsRecord>;
};

export type QuerySkynet_GetDnsNsRecordsArgs = {
  request?: Maybe<Inputskynet_GetDnsNsRecords>;
};

export type QuerySkynet_GetDnsPtrRecordArgs = {
  request?: Maybe<Inputskynet_GetDnsPtrRecord>;
};

export type QuerySkynet_GetDnsPtrRecordsArgs = {
  request?: Maybe<Inputskynet_GetDnsPtrRecords>;
};

export type QuerySkynet_GetDnsRequestDetailsArgs = {
  request?: Maybe<Inputskynet_GetDnsRequestDetails>;
};

export type QuerySkynet_GetDnsSrvRecordArgs = {
  request?: Maybe<Inputskynet_GetDnsSrvRecord>;
};

export type QuerySkynet_GetDnsSrvRecordsArgs = {
  request?: Maybe<Inputskynet_GetDnsSrvRecords>;
};

export type QuerySkynet_GetDnsTxtRecordArgs = {
  request?: Maybe<Inputskynet_GetDnsTxtRecord>;
};

export type QuerySkynet_GetDnsTxtRecordsArgs = {
  request?: Maybe<Inputskynet_GetDnsTxtRecords>;
};

export type QuerySkynet_GetDnsZoneArgs = {
  request?: Maybe<Inputskynet_GetDnsZone>;
};

export type QuerySkynet_GetEnvironmentArgs = {
  request?: Maybe<Inputskynet_GetEnvironment>;
};

export type QuerySkynet_GetExchangeMailboxArgs = {
  request?: Maybe<Inputskynet_GetExchangeMailbox>;
};

export type QuerySkynet_GetExchangeMailboxRulesArgs = {
  request?: Maybe<Inputskynet_GetExchangeMailboxRules>;
};

export type QuerySkynet_GetFunctionArgs = {
  request?: Maybe<Inputskynet_GetFunction>;
};

export type QuerySkynet_GetHomeDirectoriesArgs = {
  request?: Maybe<Inputskynet_GetHomeDirectories>;
};

export type QuerySkynet_GetIssuedCertificatesArgs = {
  request?: Maybe<Inputskynet_GetIssuedCertificates>;
};

export type QuerySkynet_GetJobArgs = {
  request?: Maybe<Inputskynet_GetJob>;
};

export type QuerySkynet_GetJobHistoryArgs = {
  request?: Maybe<Inputskynet_GetJobHistory>;
};

export type QuerySkynet_GetKvstoreColllectionArgs = {
  request?: Maybe<Inputskynet_GetKvstoreColllection>;
};

export type QuerySkynet_GetKvstoreColllectionRecordArgs = {
  request?: Maybe<Inputskynet_GetKvstoreColllectionRecord>;
};

export type QuerySkynet_GetKvstoreColllectionRecordsArgs = {
  request?: Maybe<Inputskynet_GetKvstoreColllectionRecords>;
};

export type QuerySkynet_GetLinuxAutoMountArgs = {
  request?: Maybe<Inputskynet_GetLinuxAutoMount>;
};

export type QuerySkynet_GetLinuxAutoMountMapArgs = {
  request?: Maybe<Inputskynet_GetLinuxAutoMountMap>;
};

export type QuerySkynet_GetLinuxAutoMountsArgs = {
  request?: Maybe<Inputskynet_GetLinuxAutoMounts>;
};

export type QuerySkynet_GetLinuxGroupArgs = {
  request?: Maybe<Inputskynet_GetLinuxGroup>;
};

export type QuerySkynet_GetLinuxGroupMembersArgs = {
  request?: Maybe<Inputskynet_GetLinuxGroupMembers>;
};

export type QuerySkynet_GetLinuxNetgroupArgs = {
  request?: Maybe<Inputskynet_GetLinuxNetgroup>;
};

export type QuerySkynet_GetLinuxNetgroupMembersArgs = {
  request?: Maybe<Inputskynet_GetLinuxNetgroupMembers>;
};

export type QuerySkynet_GetLinuxSudoRoleArgs = {
  request?: Maybe<Inputskynet_GetLinuxSudoRole>;
};

export type QuerySkynet_GetLinuxUserArgs = {
  request?: Maybe<Inputskynet_GetLinuxUser>;
};

export type QuerySkynet_GetLinuxUserGroupsArgs = {
  request?: Maybe<Inputskynet_GetLinuxUserGroups>;
};

export type QuerySkynet_GetLinuxUserNetGroupsArgs = {
  request?: Maybe<Inputskynet_GetLinuxUserNetGroups>;
};

export type QuerySkynet_GetLocationArgs = {
  request?: Maybe<Inputskynet_GetLocation>;
};

export type QuerySkynet_GetMailboxAccessArgs = {
  request?: Maybe<Inputskynet_GetMailboxAccess>;
};

export type QuerySkynet_GetMailboxEmailArgs = {
  request?: Maybe<Inputskynet_GetMailboxEmail>;
};

export type QuerySkynet_GetMobileUserArgs = {
  request?: Maybe<Inputskynet_GetMobileUser>;
};

export type QuerySkynet_GetMobileUsersDeviceArgs = {
  request?: Maybe<Inputskynet_GetMobileUsersDevice>;
};

export type QuerySkynet_GetNetworkAdapterArgs = {
  request?: Maybe<Inputskynet_GetNetworkAdapter>;
};

export type QuerySkynet_GetNetworkAdaptersArgs = {
  request?: Maybe<Inputskynet_GetNetworkAdapters>;
};

export type QuerySkynet_GetNextServerNamesArgs = {
  request?: Maybe<Inputskynet_GetNextServerNames>;
};

export type QuerySkynet_GetNlyteAuthTokenArgs = {
  request?: Maybe<Inputskynet_GetNlyteAuthToken>;
};

export type QuerySkynet_GetNlyteChassisArgs = {
  request?: Maybe<Inputskynet_GetNlyteChassis>;
};

export type QuerySkynet_GetNlyteKvmSwitchesArgs = {
  request?: Maybe<Inputskynet_GetNlyteKvmSwitches>;
};

export type QuerySkynet_GetNlyteNetworksArgs = {
  request?: Maybe<Inputskynet_GetNlyteNetworks>;
};

export type QuerySkynet_GetNlytePeripheralsArgs = {
  request?: Maybe<Inputskynet_GetNlytePeripherals>;
};

export type QuerySkynet_GetNlytePowerStripsArgs = {
  request?: Maybe<Inputskynet_GetNlytePowerStrips>;
};

export type QuerySkynet_GetNlyteServersArgs = {
  request?: Maybe<Inputskynet_GetNlyteServers>;
};

export type QuerySkynet_GetOktaGroupArgs = {
  request?: Maybe<Inputskynet_GetOktaGroup>;
};

export type QuerySkynet_GetOktaGroupMembersArgs = {
  request?: Maybe<Inputskynet_GetOktaGroupMembers>;
};

export type QuerySkynet_GetOktaUserArgs = {
  request?: Maybe<Inputskynet_GetOktaUser>;
};

export type QuerySkynet_GetOktaUserFactorsArgs = {
  request?: Maybe<Inputskynet_GetOktaUserFactors>;
};

export type QuerySkynet_GetOperatingSystemArgs = {
  request?: Maybe<Inputskynet_GetOperatingSystem>;
};

export type QuerySkynet_GetPagerDutyContactMethodsArgs = {
  request?: Maybe<Inputskynet_GetPagerDutyContactMethods>;
};

export type QuerySkynet_GetPagerDutyServiceArgs = {
  request?: Maybe<Inputskynet_GetPagerDutyService>;
};

export type QuerySkynet_GetPagerDutyServiceIncidentsArgs = {
  request?: Maybe<Inputskynet_GetPagerDutyServiceIncidents>;
};

export type QuerySkynet_GetPagerDutyTeamArgs = {
  request?: Maybe<Inputskynet_GetPagerDutyTeam>;
};

export type QuerySkynet_GetPagerDutyTeamIncidentsArgs = {
  request?: Maybe<Inputskynet_GetPagerDutyTeamIncidents>;
};

export type QuerySkynet_GetPagerDutyUserArgs = {
  request?: Maybe<Inputskynet_GetPagerDutyUser>;
};

export type QuerySkynet_GetReservationDhcpOptionArgs = {
  request?: Maybe<Inputskynet_GetReservationDhcpOption>;
};

export type QuerySkynet_GetSccmAddRemoveArgs = {
  request?: Maybe<Inputskynet_GetSccmAddRemove>;
};

export type QuerySkynet_GetSccmAutoRunsArgs = {
  request?: Maybe<Inputskynet_GetSccmAutoRuns>;
};

export type QuerySkynet_GetSccmAutostartInfoArgs = {
  request?: Maybe<Inputskynet_GetSccmAutostartInfo>;
};

export type QuerySkynet_GetSccmBhoArgs = {
  request?: Maybe<Inputskynet_GetSccmBho>;
};

export type QuerySkynet_GetSccmBiosArgs = {
  request?: Maybe<Inputskynet_GetSccmBios>;
};

export type QuerySkynet_GetSccmBloombergInfoArgs = {
  request?: Maybe<Inputskynet_GetSccmBloombergInfo>;
};

export type QuerySkynet_GetSccmCollectionArgs = {
  request?: Maybe<Inputskynet_GetSccmCollection>;
};

export type QuerySkynet_GetSccmCollectionMembershipArgs = {
  request?: Maybe<Inputskynet_GetSccmCollectionMembership>;
};

export type QuerySkynet_GetSccmDisksArgs = {
  request?: Maybe<Inputskynet_GetSccmDisks>;
};

export type QuerySkynet_GetSccmLocalGroupMembershipArgs = {
  request?: Maybe<Inputskynet_GetSccmLocalGroupMembership>;
};

export type QuerySkynet_GetSccmLogicalDisksArgs = {
  request?: Maybe<Inputskynet_GetSccmLogicalDisks>;
};

export type QuerySkynet_GetSccmMappedDrivesArgs = {
  request?: Maybe<Inputskynet_GetSccmMappedDrives>;
};

export type QuerySkynet_GetSccmMappedPrintersArgs = {
  request?: Maybe<Inputskynet_GetSccmMappedPrinters>;
};

export type QuerySkynet_GetSccmNetworkAdaptersArgs = {
  request?: Maybe<Inputskynet_GetSccmNetworkAdapters>;
};

export type QuerySkynet_GetSccmOperatingSystemsArgs = {
  request?: Maybe<Inputskynet_GetSccmOperatingSystems>;
};

export type QuerySkynet_GetSccmPhysicalDisksArgs = {
  request?: Maybe<Inputskynet_GetSccmPhysicalDisks>;
};

export type QuerySkynet_GetSccmPhysicalMemoryArgs = {
  request?: Maybe<Inputskynet_GetSccmPhysicalMemory>;
};

export type QuerySkynet_GetSccmPnpDeviceDriversArgs = {
  request?: Maybe<Inputskynet_GetSccmPnpDeviceDrivers>;
};

export type QuerySkynet_GetSccmProcessorsArgs = {
  request?: Maybe<Inputskynet_GetSccmProcessors>;
};

export type QuerySkynet_GetSccmRecentlyUsedApplicationsArgs = {
  request?: Maybe<Inputskynet_GetSccmRecentlyUsedApplications>;
};

export type QuerySkynet_GetSccmScsiControllersArgs = {
  request?: Maybe<Inputskynet_GetSccmScsiControllers>;
};

export type QuerySkynet_GetSccmServerFeaturesArgs = {
  request?: Maybe<Inputskynet_GetSccmServerFeatures>;
};

export type QuerySkynet_GetSccmSoftwareUpdatesArgs = {
  request?: Maybe<Inputskynet_GetSccmSoftwareUpdates>;
};

export type QuerySkynet_GetSccmSystemArgs = {
  request?: Maybe<Inputskynet_GetSccmSystem>;
};

export type QuerySkynet_GetSccmUsbDevicesArgs = {
  request?: Maybe<Inputskynet_GetSccmUsbDevices>;
};

export type QuerySkynet_GetSccmUserProfilesArgs = {
  request?: Maybe<Inputskynet_GetSccmUserProfiles>;
};

export type QuerySkynet_GetSccmVideoControllersArgs = {
  request?: Maybe<Inputskynet_GetSccmVideoControllers>;
};

export type QuerySkynet_GetScopeDhcpOptionArgs = {
  request?: Maybe<Inputskynet_GetScopeDhcpOption>;
};

export type QuerySkynet_GetServerDhcpOptionArgs = {
  request?: Maybe<Inputskynet_GetServerDhcpOption>;
};

export type QuerySkynet_GetServerNameArgs = {
  request?: Maybe<Inputskynet_GetServerName>;
};

export type QuerySkynet_GetServiceNowAdHocRequestArgs = {
  request?: Maybe<Inputskynet_GetServiceNowAdHocRequest>;
};

export type QuerySkynet_GetSlackChannelArgs = {
  request?: Maybe<Inputskynet_GetSlackChannel>;
};

export type QuerySkynet_GetSlackChannelsArgs = {
  request?: Maybe<Inputskynet_GetSlackChannels>;
};

export type QuerySkynet_GetSlackGroupArgs = {
  request?: Maybe<Inputskynet_GetSlackGroup>;
};

export type QuerySkynet_GetSlackGroupsArgs = {
  request?: Maybe<Inputskynet_GetSlackGroups>;
};

export type QuerySkynet_GetSlackImsArgs = {
  request?: Maybe<Inputskynet_GetSlackIms>;
};

export type QuerySkynet_GetSlackMpimsArgs = {
  request?: Maybe<Inputskynet_GetSlackMpims>;
};

export type QuerySkynet_GetSlackUserChannelsArgs = {
  request?: Maybe<Inputskynet_GetSlackUserChannels>;
};

export type QuerySkynet_GetSlackUserGroupsArgs = {
  request?: Maybe<Inputskynet_GetSlackUserGroups>;
};

export type QuerySkynet_GetSlackUsersArgs = {
  request?: Maybe<Inputskynet_GetSlackUsers>;
};

export type QuerySkynet_GetSlackWorkspaceChannelsArgs = {
  request?: Maybe<Inputskynet_GetSlackWorkspaceChannels>;
};

export type QuerySkynet_GetSlackWorkspaceGroupsArgs = {
  request?: Maybe<Inputskynet_GetSlackWorkspaceGroups>;
};

export type QuerySkynet_GetSlackWorkspacesArgs = {
  request?: Maybe<Inputskynet_GetSlackWorkspaces>;
};

export type QuerySkynet_GetSnowflakeSecurityRequestArgs = {
  request?: Maybe<Inputskynet_GetSnowflakeSecurityRequest>;
};

export type QuerySkynet_GetSoftwareInstallationsArgs = {
  request?: Maybe<Inputskynet_GetSoftwareInstallations>;
};

export type QuerySkynet_GetSqlDatabaseLoginArgs = {
  request?: Maybe<Inputskynet_GetSqlDatabaseLogin>;
};

export type QuerySkynet_GetSqlDatabaseLoginMappingsArgs = {
  request?: Maybe<Inputskynet_GetSqlDatabaseLoginMappings>;
};

export type QuerySkynet_GetSqlDatabaseLoginsArgs = {
  request?: Maybe<Inputskynet_GetSqlDatabaseLogins>;
};

export type QuerySkynet_GetSymphonyUserArgs = {
  request?: Maybe<Inputskynet_GetSymphonyUser>;
};

export type QuerySkynet_GetUcsIpAddressArgs = {
  request?: Maybe<Inputskynet_GetUcsIpAddress>;
};

export type QuerySkynet_GetUcsIpAddressPoolsArgs = {
  request?: Maybe<Inputskynet_GetUcsIpAddressPools>;
};

export type QuerySkynet_GetUcsTemplateArgs = {
  request?: Maybe<Inputskynet_GetUcsTemplate>;
};

export type QuerySkynet_GetUcsTemplatesArgs = {
  request?: Maybe<Inputskynet_GetUcsTemplates>;
};

export type QuerySkynet_GetVirusTotalInfoArgs = {
  request?: Maybe<Inputskynet_GetVirusTotalInfo>;
};

export type QuerySkynet_GetWindowsAclArgs = {
  request?: Maybe<Inputskynet_GetWindowsAcl>;
};

export type QuerySkynet_GetWindowsComputerArgs = {
  request?: Maybe<Inputskynet_GetWindowsComputer>;
};

export type QuerySkynet_GetWindowsComputerGroupMembershipArgs = {
  request?: Maybe<Inputskynet_GetWindowsComputerGroupMembership>;
};

export type QuerySkynet_GetWindowsComputerGroupsArgs = {
  request?: Maybe<Inputskynet_GetWindowsComputerGroups>;
};

export type QuerySkynet_GetWindowsComputerHeartbeatArgs = {
  request?: Maybe<Inputskynet_GetWindowsComputerHeartbeat>;
};

export type QuerySkynet_GetWindowsComputerLoginsArgs = {
  request?: Maybe<Inputskynet_GetWindowsComputerLogins>;
};

export type QuerySkynet_GetWindowsComputerNetworkAdapterStatisticsArgs = {
  request?: Maybe<Inputskynet_GetWindowsComputerNetworkAdapterStatistics>;
};

export type QuerySkynet_GetWindowsComputerPowerShellNetAdaptersArgs = {
  request?: Maybe<Inputskynet_GetWindowsComputerPowerShellNetAdapters>;
};

export type QuerySkynet_GetWindowsComputerProcessDetailArgs = {
  request?: Maybe<Inputskynet_GetWindowsComputerProcessDetail>;
};

export type QuerySkynet_GetWindowsComputerProcessesArgs = {
  request?: Maybe<Inputskynet_GetWindowsComputerProcesses>;
};

export type QuerySkynet_GetWindowsComputerServiceDetailArgs = {
  request?: Maybe<Inputskynet_GetWindowsComputerServiceDetail>;
};

export type QuerySkynet_GetWindowsComputerServicesArgs = {
  request?: Maybe<Inputskynet_GetWindowsComputerServices>;
};

export type QuerySkynet_GetWindowsComputerTerminalServerSettingsArgs = {
  request?: Maybe<Inputskynet_GetWindowsComputerTerminalServerSettings>;
};

export type QuerySkynet_GetWindowsComputerUsersArgs = {
  request?: Maybe<Inputskynet_GetWindowsComputerUsers>;
};

export type QuerySkynet_GetWindowsComputersArgs = {
  request?: Maybe<Inputskynet_GetWindowsComputers>;
};

export type QuerySkynet_GetWindowsDynamicGroupArgs = {
  request?: Maybe<Inputskynet_GetWindowsDynamicGroup>;
};

export type QuerySkynet_GetWindowsDynamicGroupMembersArgs = {
  request?: Maybe<Inputskynet_GetWindowsDynamicGroupMembers>;
};

export type QuerySkynet_GetWindowsEasDevicesArgs = {
  request?: Maybe<Inputskynet_GetWindowsEasDevices>;
};

export type QuerySkynet_GetWindowsGroupArgs = {
  request?: Maybe<Inputskynet_GetWindowsGroup>;
};

export type QuerySkynet_GetWindowsGroupChangeHistoryArgs = {
  request?: Maybe<Inputskynet_GetWindowsGroupChangeHistory>;
};

export type QuerySkynet_GetWindowsGroupMembersArgs = {
  request?: Maybe<Inputskynet_GetWindowsGroupMembers>;
};

export type QuerySkynet_GetWindowsGroupsArgs = {
  request?: Maybe<Inputskynet_GetWindowsGroups>;
};

export type QuerySkynet_GetWindowsLocalGroupArgs = {
  request?: Maybe<Inputskynet_GetWindowsLocalGroup>;
};

export type QuerySkynet_GetWindowsLocalGroupMembershipArgs = {
  request?: Maybe<Inputskynet_GetWindowsLocalGroupMembership>;
};

export type QuerySkynet_GetWindowsLocalGroupsArgs = {
  request?: Maybe<Inputskynet_GetWindowsLocalGroups>;
};

export type QuerySkynet_GetWindowsManagedServiceAccountArgs = {
  request?: Maybe<Inputskynet_GetWindowsManagedServiceAccount>;
};

export type QuerySkynet_GetWindowsRdpInformationArgs = {
  request?: Maybe<Inputskynet_GetWindowsRdpInformation>;
};

export type QuerySkynet_GetWindowsRdpMetricsArgs = {
  request?: Maybe<Inputskynet_GetWindowsRdpMetrics>;
};

export type QuerySkynet_GetWindowsUserArgs = {
  request?: Maybe<Inputskynet_GetWindowsUser>;
};

export type QuerySkynet_GetWindowsUserChangeHistoryArgs = {
  request?: Maybe<Inputskynet_GetWindowsUserChangeHistory>;
};

export type QuerySkynet_GetWindowsUserComputersArgs = {
  request?: Maybe<Inputskynet_GetWindowsUserComputers>;
};

export type QuerySkynet_GetWindowsUserGatewaySessionsArgs = {
  request?: Maybe<Inputskynet_GetWindowsUserGatewaySessions>;
};

export type QuerySkynet_GetWindowsUserGroupsArgs = {
  request?: Maybe<Inputskynet_GetWindowsUserGroups>;
};

export type QuerySkynet_GetWindowsUserLocalGroupsArgs = {
  request?: Maybe<Inputskynet_GetWindowsUserLocalGroups>;
};

export type QuerySkynet_GetWindowsUserLoginsArgs = {
  request?: Maybe<Inputskynet_GetWindowsUserLogins>;
};

export type QuerySkynet_GetWindowsUserPrincipalsArgs = {
  request?: Maybe<Inputskynet_GetWindowsUserPrincipals>;
};

export type QuerySkynet_GetWindowsUserRestrictedHostsArgs = {
  request?: Maybe<Inputskynet_GetWindowsUserRestrictedHosts>;
};

export type QuerySkynet_GetZabbixGroupMembershipArgs = {
  request?: Maybe<Inputskynet_GetZabbixGroupMembership>;
};

export type QuerySkynet_GetZabbixHostGroupsHostsArgs = {
  request?: Maybe<Inputskynet_GetZabbixHostGroupsHosts>;
};

export type QuerySkynet_GetZabbixMaintenanceArgs = {
  request?: Maybe<Inputskynet_GetZabbixMaintenance>;
};

export type QuerySkynet_GetZabbixTriggerArgs = {
  request?: Maybe<Inputskynet_GetZabbixTrigger>;
};

export type QuerySkynet_GetZabbixUserArgs = {
  request?: Maybe<Inputskynet_GetZabbixUser>;
};

export type QuerySkynet_ListSymphonyRoomMembershipArgs = {
  request?: Maybe<Inputskynet_ListSymphonyRoomMembership>;
};

export type QuerySkynet_LiveGetLinuxGroupArgs = {
  request?: Maybe<Inputskynet_LiveGetLinuxGroup>;
};

export type QuerySkynet_LiveGetLinuxGroupMembersArgs = {
  request?: Maybe<Inputskynet_LiveGetLinuxGroupMembers>;
};

export type QuerySkynet_LiveGetLinuxNetgroupArgs = {
  request?: Maybe<Inputskynet_LiveGetLinuxNetgroup>;
};

export type QuerySkynet_LiveGetLinuxNetgroupMembersArgs = {
  request?: Maybe<Inputskynet_LiveGetLinuxNetgroupMembers>;
};

export type QuerySkynet_LiveGetLinuxUserArgs = {
  request?: Maybe<Inputskynet_LiveGetLinuxUser>;
};

export type QuerySkynet_LiveGetWindowsDynamicGroupArgs = {
  request?: Maybe<Inputskynet_LiveGetWindowsDynamicGroup>;
};

export type QuerySkynet_LiveGetWindowsDynamicGroupMembersArgs = {
  request?: Maybe<Inputskynet_LiveGetWindowsDynamicGroupMembers>;
};

export type QuerySkynet_LiveGetWindowsGroupArgs = {
  request?: Maybe<Inputskynet_LiveGetWindowsGroup>;
};

export type QuerySkynet_LiveGetWindowsGroupMembersArgs = {
  request?: Maybe<Inputskynet_LiveGetWindowsGroupMembers>;
};

export type QuerySkynet_LiveGetWindowsUserArgs = {
  request?: Maybe<Inputskynet_LiveGetWindowsUser>;
};

export type QuerySkynet_LiveGetWindowsUserGroupsArgs = {
  request?: Maybe<Inputskynet_LiveGetWindowsUserGroups>;
};

export type QuerySkynet_LiveSearchLinuxGroupsArgs = {
  request?: Maybe<Inputskynet_LiveSearchLinuxGroups>;
};

export type QuerySkynet_LiveSearchLinuxNetgroupsArgs = {
  request?: Maybe<Inputskynet_LiveSearchLinuxNetgroups>;
};

export type QuerySkynet_LiveSearchLinuxUsersArgs = {
  request?: Maybe<Inputskynet_LiveSearchLinuxUsers>;
};

export type QuerySkynet_LiveSearchWindowsGroupsArgs = {
  request?: Maybe<Inputskynet_LiveSearchWindowsGroups>;
};

export type QuerySkynet_LiveSearchWindowsUsersArgs = {
  request?: Maybe<Inputskynet_LiveSearchWindowsUsers>;
};

export type QuerySkynet_MailDisableWindowsGroupArgs = {
  request?: Maybe<Inputskynet_MailDisableWindowsGroup>;
};

export type QuerySkynet_MigrateSymphonyRoomArgs = {
  request?: Maybe<Inputskynet_MigrateSymphonyRoom>;
};

export type QuerySkynet_RemoveUsersFromSymphonyRoomArgs = {
  request?: Maybe<Inputskynet_RemoveUsersFromSymphonyRoom>;
};

export type QuerySkynet_RemoveWindowsManagedServiceAccountPrincipalsArgs = {
  request?: Maybe<Inputskynet_RemoveWindowsManagedServiceAccountPrincipals>;
};

export type QuerySkynet_ReserveSolwarwindsIpArgs = {
  request?: Maybe<Inputskynet_ReserveSolwarwindsIp>;
};

export type QuerySkynet_RestartWindowsComputerServiceArgs = {
  request?: Maybe<Inputskynet_RestartWindowsComputerService>;
};

export type QuerySkynet_ScheduleDnsRequestArgs = {
  request?: Maybe<Inputskynet_ScheduleDnsRequest>;
};

export type QuerySkynet_SearchActiveDirectoryComputersArgs = {
  request?: Maybe<Inputskynet_SearchActiveDirectoryComputers>;
};

export type QuerySkynet_SearchAmLinuxComputersArgs = {
  request?: Maybe<Inputskynet_SearchAmLinuxComputers>;
};

export type QuerySkynet_SearchAssetLocationArgs = {
  request?: Maybe<Inputskynet_SearchAssetLocation>;
};

export type QuerySkynet_SearchAssetsArgs = {
  request?: Maybe<Inputskynet_SearchAssets>;
};

export type QuerySkynet_SearchCsLinuxComputersArgs = {
  request?: Maybe<Inputskynet_SearchCsLinuxComputers>;
};

export type QuerySkynet_SearchDhcpLeasesArgs = {
  request?: Maybe<Inputskynet_SearchDhcpLeases>;
};

export type QuerySkynet_SearchDhcpReservationsArgs = {
  request?: Maybe<Inputskynet_SearchDhcpReservations>;
};

export type QuerySkynet_SearchDhcpScopesArgs = {
  request?: Maybe<Inputskynet_SearchDhcpScopes>;
};

export type QuerySkynet_SearchDhcpServerLeasesArgs = {
  request?: Maybe<Inputskynet_SearchDhcpServerLeases>;
};

export type QuerySkynet_SearchDnsARecordsArgs = {
  request?: Maybe<Inputskynet_SearchDnsARecords>;
};

export type QuerySkynet_SearchDnsCnameRecordsArgs = {
  request?: Maybe<Inputskynet_SearchDnsCnameRecords>;
};

export type QuerySkynet_SearchDnsNsRecordsArgs = {
  request?: Maybe<Inputskynet_SearchDnsNsRecords>;
};

export type QuerySkynet_SearchDnsPtrRecordsArgs = {
  request?: Maybe<Inputskynet_SearchDnsPtrRecords>;
};

export type QuerySkynet_SearchDnsSrvRecordsArgs = {
  request?: Maybe<Inputskynet_SearchDnsSrvRecords>;
};

export type QuerySkynet_SearchDnsTxtRecordsArgs = {
  request?: Maybe<Inputskynet_SearchDnsTxtRecords>;
};

export type QuerySkynet_SearchDnsZonesArgs = {
  request?: Maybe<Inputskynet_SearchDnsZones>;
};

export type QuerySkynet_SearchExchangeMailboxesArgs = {
  request?: Maybe<Inputskynet_SearchExchangeMailboxes>;
};

export type QuerySkynet_SearchFunctionsArgs = {
  request?: Maybe<Inputskynet_SearchFunctions>;
};

export type QuerySkynet_SearchIssuedCertificatesArgs = {
  request?: Maybe<Inputskynet_SearchIssuedCertificates>;
};

export type QuerySkynet_SearchJobsArgs = {
  request?: Maybe<Inputskynet_SearchJobs>;
};

export type QuerySkynet_SearchLinuxGroupArgs = {
  request?: Maybe<Inputskynet_SearchLinuxGroup>;
};

export type QuerySkynet_SearchLinuxNetgroupsArgs = {
  request?: Maybe<Inputskynet_SearchLinuxNetgroups>;
};

export type QuerySkynet_SearchLinuxSudoRolesArgs = {
  request?: Maybe<Inputskynet_SearchLinuxSudoRoles>;
};

export type QuerySkynet_SearchLinuxUsersArgs = {
  request?: Maybe<Inputskynet_SearchLinuxUsers>;
};

export type QuerySkynet_SearchMobileDevicesArgs = {
  request?: Maybe<Inputskynet_SearchMobileDevices>;
};

export type QuerySkynet_SearchMobileUsersArgs = {
  request?: Maybe<Inputskynet_SearchMobileUsers>;
};

export type QuerySkynet_SearchOktaUsersArgs = {
  request?: Maybe<Inputskynet_SearchOktaUsers>;
};

export type QuerySkynet_SearchOrgMapArgs = {
  request?: Maybe<Inputskynet_SearchOrgMap>;
};

export type QuerySkynet_SearchPagerDutyServiceIncidentsArgs = {
  request?: Maybe<Inputskynet_SearchPagerDutyServiceIncidents>;
};

export type QuerySkynet_SearchPagerDutyServicesArgs = {
  request?: Maybe<Inputskynet_SearchPagerDutyServices>;
};

export type QuerySkynet_SearchPagerDutyTeamIncidentsArgs = {
  request?: Maybe<Inputskynet_SearchPagerDutyTeamIncidents>;
};

export type QuerySkynet_SearchPagerDutyTeamsArgs = {
  request?: Maybe<Inputskynet_SearchPagerDutyTeams>;
};

export type QuerySkynet_SearchPagerDutyUsersArgs = {
  request?: Maybe<Inputskynet_SearchPagerDutyUsers>;
};

export type QuerySkynet_SearchSccmApplicationsArgs = {
  request?: Maybe<Inputskynet_SearchSccmApplications>;
};

export type QuerySkynet_SearchSccmCollectionsArgs = {
  request?: Maybe<Inputskynet_SearchSccmCollections>;
};

export type QuerySkynet_SearchSccmLocalGroupReportArgs = {
  request?: Maybe<Inputskynet_SearchSccmLocalGroupReport>;
};

export type QuerySkynet_SearchSccmSoftwareArgs = {
  request?: Maybe<Inputskynet_SearchSccmSoftware>;
};

export type QuerySkynet_SearchSccmSystemsArgs = {
  request?: Maybe<Inputskynet_SearchSccmSystems>;
};

export type QuerySkynet_SearchServerNameArgs = {
  request?: Maybe<Inputskynet_SearchServerName>;
};

export type QuerySkynet_SearchServiceNowUsersArgs = {
  request?: Maybe<Inputskynet_SearchServiceNowUsers>;
};

export type QuerySkynet_SearchSlackChannelsArgs = {
  request?: Maybe<Inputskynet_SearchSlackChannels>;
};

export type QuerySkynet_SearchSlackGroupsArgs = {
  request?: Maybe<Inputskynet_SearchSlackGroups>;
};

export type QuerySkynet_SearchSlackImsArgs = {
  request?: Maybe<Inputskynet_SearchSlackIms>;
};

export type QuerySkynet_SearchSlackMpimsArgs = {
  request?: Maybe<Inputskynet_SearchSlackMpims>;
};

export type QuerySkynet_SearchSlackUsersArgs = {
  request?: Maybe<Inputskynet_SearchSlackUsers>;
};

export type QuerySkynet_SearchSlackWorkspacesArgs = {
  request?: Maybe<Inputskynet_SearchSlackWorkspaces>;
};

export type QuerySkynet_SearchUcsIpAddressPoolsArgs = {
  request?: Maybe<Inputskynet_SearchUcsIpAddressPools>;
};

export type QuerySkynet_SearchWindowsComputersArgs = {
  request?: Maybe<Inputskynet_SearchWindowsComputers>;
};

export type QuerySkynet_SearchWindowsDisabledUsersArgs = {
  request?: Maybe<Inputskynet_SearchWindowsDisabledUsers>;
};

export type QuerySkynet_SearchWindowsDynamicGroupsArgs = {
  request?: Maybe<Inputskynet_SearchWindowsDynamicGroups>;
};

export type QuerySkynet_SearchWindowsGroupsArgs = {
  request?: Maybe<Inputskynet_SearchWindowsGroups>;
};

export type QuerySkynet_SearchWindowsUsersArgs = {
  request?: Maybe<Inputskynet_SearchWindowsUsers>;
};

export type QuerySkynet_SearchWindowsUsersByIdArgs = {
  request?: Maybe<Inputskynet_SearchWindowsUsersById>;
};

export type QuerySkynet_SearchWorkdayDepartmentsArgs = {
  request?: Maybe<Inputskynet_SearchWorkdayDepartments>;
};

export type QuerySkynet_SearchWorkdayLocationsArgs = {
  request?: Maybe<Inputskynet_SearchWorkdayLocations>;
};

export type QuerySkynet_SearchWorkdayPeopleArgs = {
  request?: Maybe<Inputskynet_SearchWorkdayPeople>;
};

export type QuerySkynet_SearchWorkdayQuarterlyRoleDescriptionsArgs = {
  request?: Maybe<Inputskynet_SearchWorkdayQuarterlyRoleDescriptions>;
};

export type QuerySkynet_SearchZabbixHostGroupsArgs = {
  request?: Maybe<Inputskynet_SearchZabbixHostGroups>;
};

export type QuerySkynet_SearchZabbixHostsArgs = {
  request?: Maybe<Inputskynet_SearchZabbixHosts>;
};

export type QuerySkynet_SearchZabbixTriggersArgs = {
  request?: Maybe<Inputskynet_SearchZabbixTriggers>;
};

export type QuerySkynet_SearchZabbixUsersArgs = {
  request?: Maybe<Inputskynet_SearchZabbixUsers>;
};

export type QuerySkynet_SearchtDnsMxRecordsArgs = {
  request?: Maybe<Inputskynet_SearchtDnsMxRecords>;
};

export type QuerySkynet_SetExchangeMailboxArgs = {
  request?: Maybe<Inputskynet_SetExchangeMailbox>;
};

export type QuerySkynet_SetHomeDirectoryLegalHoldArgs = {
  request?: Maybe<Inputskynet_SetHomeDirectoryLegalHold>;
};

export type QuerySkynet_StartWindowsComputerServiceArgs = {
  request?: Maybe<Inputskynet_StartWindowsComputerService>;
};

export type QuerySkynet_StopWindowsComputerServiceArgs = {
  request?: Maybe<Inputskynet_StopWindowsComputerService>;
};

export type QuerySkynet_SuggestWindowsGroupsArgs = {
  request?: Maybe<Inputskynet_SuggestWindowsGroups>;
};

export type QuerySkynet_SuggestWindowsUsersArgs = {
  request?: Maybe<Inputskynet_SuggestWindowsUsers>;
};

export type QuerySkynet_SuggestWorkdayPeopleArgs = {
  request?: Maybe<Inputskynet_SuggestWorkdayPeople>;
};

export type QuerySkynet_SuppressSymphonyMessageArgs = {
  request?: Maybe<Inputskynet_SuppressSymphonyMessage>;
};

export type QuerySkynet_UpdateBastionAccountArgs = {
  request?: Maybe<Inputskynet_UpdateBastionAccount>;
};

export type QuerySkynet_UpdateCCureUserArgs = {
  request?: Maybe<Inputskynet_UpdateCCureUser>;
};

export type QuerySkynet_UpdateDfsFolderTargetArgs = {
  request?: Maybe<Inputskynet_UpdateDfsFolderTarget>;
};

export type QuerySkynet_UpdateDfsRootArgs = {
  request?: Maybe<Inputskynet_UpdateDfsRoot>;
};

export type QuerySkynet_UpdateDfsRootFolderArgs = {
  request?: Maybe<Inputskynet_UpdateDfsRootFolder>;
};

export type QuerySkynet_UpdateDhcpReservationOptionArgs = {
  request?: Maybe<Inputskynet_UpdateDhcpReservationOption>;
};

export type QuerySkynet_UpdateDhcpScopeArgs = {
  request?: Maybe<Inputskynet_UpdateDhcpScope>;
};

export type QuerySkynet_UpdateDhcpScopeOptionArgs = {
  request?: Maybe<Inputskynet_UpdateDhcpScopeOption>;
};

export type QuerySkynet_UpdateDhcpServerOptionArgs = {
  request?: Maybe<Inputskynet_UpdateDhcpServerOption>;
};

export type QuerySkynet_UpdateLinuxAutoMountArgs = {
  request?: Maybe<Inputskynet_UpdateLinuxAutoMount>;
};

export type QuerySkynet_UpdateLinuxAutoMountMapArgs = {
  request?: Maybe<Inputskynet_UpdateLinuxAutoMountMap>;
};

export type QuerySkynet_UpdateLinuxGroupArgs = {
  request?: Maybe<Inputskynet_UpdateLinuxGroup>;
};

export type QuerySkynet_UpdateLinuxNetGroupArgs = {
  request?: Maybe<Inputskynet_UpdateLinuxNetGroup>;
};

export type QuerySkynet_UpdateLinuxSudoRoleArgs = {
  request?: Maybe<Inputskynet_UpdateLinuxSudoRole>;
};

export type QuerySkynet_UpdateLinuxUserArgs = {
  request?: Maybe<Inputskynet_UpdateLinuxUser>;
};

export type QuerySkynet_UpdateMacUserArgs = {
  request?: Maybe<Inputskynet_UpdateMacUser>;
};

export type QuerySkynet_UpdateSnowflakeSecurityRequestArgs = {
  request?: Maybe<Inputskynet_UpdateSnowflakeSecurityRequest>;
};

export type QuerySkynet_UpdateWindowsGroupArgs = {
  request?: Maybe<Inputskynet_UpdateWindowsGroup>;
};

export type QuerySkynet_UpdateWindowsManagedServiceAccountArgs = {
  request?: Maybe<Inputskynet_UpdateWindowsManagedServiceAccount>;
};

export type QuerySkynet_UpdateWindowsUserArgs = {
  request?: Maybe<Inputskynet_UpdateWindowsUser>;
};

export type QuerySkynet_UpdateWindowsUserRestrictedHostsArgs = {
  request?: Maybe<Inputskynet_UpdateWindowsUserRestrictedHosts>;
};

export type User = {
  __typename?: "User";
  adjustedTenure?: Maybe<Scalars["Int"]>;
  badgeId?: Maybe<Scalars["String"]>;
  company?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["Date"]>;
  department?: Maybe<Scalars["String"]>;
  departmentDescription?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  disabled?: Maybe<Scalars["Boolean"]>;
  displayName?: Maybe<Scalars["String"]>;
  distinguishedName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  emailRestrictionGroup?: Maybe<Scalars["String"]>;
  employeeId?: Maybe<Scalars["String"]>;
  employeeType?: Maybe<Scalars["String"]>;
  entityName?: Maybe<Scalars["String"]>;
  faxNumber?: Maybe<Scalars["String"]>;
  firewallGroup?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  hideFromAddressLists?: Maybe<Scalars["Boolean"]>;
  homePhone?: Maybe<Scalars["String"]>;
  horizonAccessGroup?: Maybe<Scalars["String"]>;
  hrBusinessUnit?: Maybe<Scalars["String"]>;
  initials?: Maybe<Scalars["String"]>;
  intern?: Maybe<Scalars["Boolean"]>;
  ipPhone?: Maybe<Scalars["String"]>;
  jobCode?: Maybe<Scalars["String"]>;
  lastDateWorked?: Maybe<Scalars["Date"]>;
  lastLogon?: Maybe<Scalars["Date"]>;
  lastName?: Maybe<Scalars["String"]>;
  lastUpdated?: Maybe<Scalars["Date"]>;
  location?: Maybe<Scalars["String"]>;
  lockedOut?: Maybe<Scalars["Boolean"]>;
  logonRestricted?: Maybe<Scalars["Boolean"]>;
  manager?: Maybe<Scalars["String"]>;
  managerEmployeeId?: Maybe<Scalars["String"]>;
  managerUserName?: Maybe<Scalars["String"]>;
  middleName?: Maybe<Scalars["String"]>;
  mobilePhone?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  objectGuid?: Maybe<Scalars["String"]>;
  officeLocation?: Maybe<Scalars["String"]>;
  owner?: Maybe<Scalars["String"]>;
  passwordLastSet?: Maybe<Scalars["Date"]>;
  personalMobile?: Maybe<Scalars["String"]>;
  proxyAddresses?: Maybe<Array<Maybe<Scalars["String"]>>>;
  sid?: Maybe<Scalars["String"]>;
  sipAddress?: Maybe<Scalars["String"]>;
  userAccountControl?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
  userPrincipalName?: Maybe<Scalars["String"]>;
  userProfilePath?: Maybe<Scalars["String"]>;
  userType?: Maybe<Scalars["String"]>;
  voiceRecordingIndicator?: Maybe<Scalars["String"]>;
  workPhone?: Maybe<Scalars["String"]>;
};

export type FinancialTypeById = {
  __typename?: "FinancialTypeById";
  count?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

export type FinancialTypeByName = {
  __typename?: "FinancialTypeByName";
  count?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type InputSecurityFilter = {
  country?: Maybe<Inputcountry>;
  financialType?: Maybe<InputfinancialType>;
  id?: Maybe<Inputid>;
  includeInactive?: Maybe<Scalars["Boolean"]>;
  search?: Maybe<Inputsearch>;
  ticker?: Maybe<Inputid>;
};

export type Inputcountry = {
  _eq?: Maybe<Scalars["Int"]>;
  _in?: Maybe<Array<Maybe<Scalars["Int"]>>>;
};

export type InputfinancialType = {
  _eq?: Maybe<Scalars["Int"]>;
  _in?: Maybe<Array<Maybe<Scalars["Int"]>>>;
};

export type Inputid = {
  _eq?: Maybe<Scalars["String"]>;
};

export type Inputsearch = {
  _beginsWith?: Maybe<Scalars["String"]>;
  _bloombergBeginsWith?: Maybe<Scalars["String"]>;
  _fuzzy?: Maybe<Scalars["String"]>;
  _msymbolBeginsWith?: Maybe<Scalars["String"]>;
  _tickerBeginsWith?: Maybe<Scalars["String"]>;
};

export type Results = {
  __typename?: "Results";
  countByFinancialType?: Maybe<Array<Maybe<FinancialTypeById>>>;
  countByFinancialTypeName?: Maybe<Array<Maybe<FinancialTypeByName>>>;
  queryTimeMs?: Maybe<Scalars["Int"]>;
  searchResults?: Maybe<Array<Maybe<SearchResult>>>;
  spelling?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type SearchResult = {
  __typename?: "SearchResult";
  active?: Maybe<Scalars["Boolean"]>;
  bloombergId?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["Int"]>;
  cusip?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  financialType?: Maybe<Scalars["Int"]>;
  financialTypeName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  isin?: Maybe<Scalars["String"]>;
  issuer?: Maybe<Scalars["String"]>;
  mSymbol?: Maybe<Scalars["String"]>;
  ticker?: Maybe<Scalars["String"]>;
};

export type DataFrame = {
  __typename?: "DataFrame";
  cacheKey: Scalars["ID"];
  id: Scalars["String"];
  format: Scalars["String"];
  meta?: Maybe<Scalars["JSON"]>;
  links?: Maybe<Array<DataFrame>>;
  data?: Maybe<Scalars["JSON"]>;
};

export type DataFrameParamsInput = {
  id: Scalars["String"];
  params?: Maybe<Scalars["JSON"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  dataFrame?: Maybe<DataFrame>;
};

export type SubscriptionDataFrameArgs = {
  id: Scalars["String"];
  params?: Maybe<Array<DataFrameParamsInput>>;
};

export type Mutation = {
  __typename?: "Mutation";
  core_linkCreate: Core_Link;
  core_settingDelete: Core_App;
  /** Performs update or create operation */
  core_settingUpdate: Core_App;
  core_workspaceDelete: Core_App;
  /** Performs update or create operation */
  core_workspaceUpdate: Core_App;
};

export type MutationCore_LinkCreateArgs = {
  appName: Scalars["ID"];
  data: Scalars["JSON"];
};

export type MutationCore_SettingDeleteArgs = {
  appName: Scalars["ID"];
  settingName: Scalars["String"];
};

export type MutationCore_SettingUpdateArgs = {
  appName: Scalars["ID"];
  settingName: Scalars["String"];
  data: Scalars["JSON"];
};

export type MutationCore_WorkspaceDeleteArgs = {
  appName: Scalars["ID"];
  workspaceName: Scalars["String"];
};

export type MutationCore_WorkspaceUpdateArgs = {
  appName: Scalars["ID"];
  workspaceName: Scalars["String"];
  data: Scalars["JSON"];
};

/** Artificial object to wrap user workspaces and settings for an app */
export type Core_App = {
  __typename?: "core_App";
  /** App name */
  id: Scalars["ID"];
  /**
   * Retrieve a single setting
   *
   * fallbackData: data to return if the setting has not yet been set (instead of 404)
   */
  setting: Core_Setting;
  settings: Array<Maybe<Core_Setting>>;
  /**
   * Retrieve a single workspace
   *
   * fallbackData: data to return if the workspace has not yet been set (instead of 404)
   */
  workspace: Core_Workspace;
  workspaces: Array<Maybe<Core_WorkspaceThin>>;
};

/** Artificial object to wrap user workspaces and settings for an app */
export type Core_AppSettingArgs = {
  name: Scalars["String"];
  fallbackData?: Maybe<Scalars["JSON"]>;
};

/** Artificial object to wrap user workspaces and settings for an app */
export type Core_AppSettingsArgs = {
  names?: Maybe<Array<Scalars["String"]>>;
};

/** Artificial object to wrap user workspaces and settings for an app */
export type Core_AppWorkspaceArgs = {
  name: Scalars["String"];
  fallbackData?: Maybe<Scalars["JSON"]>;
};

/** Artificial object to wrap user workspaces and settings for an app */
export type Core_AppWorkspacesArgs = {
  names?: Maybe<Array<Scalars["String"]>>;
};

export type Core_Link = {
  __typename?: "core_Link";
  id: Scalars["ID"];
  data: Scalars["JSON"];
};

export type Core_Setting = {
  __typename?: "core_Setting";
  id: Scalars["ID"];
  name: Scalars["String"];
  data: Scalars["JSON"];
  username: Scalars["String"];
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type Core_Workspace = {
  __typename?: "core_Workspace";
  id: Scalars["ID"];
  name: Scalars["String"];
  data: Scalars["JSON"];
  username: Scalars["String"];
  entitlements: Array<Scalars["String"]>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

/**
 * Workspace without the data - when retrieving workspace list you cannot get the data;
 * to get workspace data you must retrieve an individual workspace
 */
export type Core_WorkspaceThin = {
  __typename?: "core_WorkspaceThin";
  id: Scalars["ID"];
  name: Scalars["String"];
  username: Scalars["String"];
  entitlements: Array<Scalars["String"]>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

/**
 * NOTE: Here are illustrations of the operands
 * { c1: ..., AND: [{ c2: ... }, { OR: [{ c3: ... }, { c4: ... }] }] }
 * =>
 * c1 && (c2 && (c3 || c4))
 */
export type Core_LdapUserFilter = {
  AND?: Maybe<Array<Core_LdapUserFilter>>;
  OR?: Maybe<Array<Core_LdapUserFilter>>;
  sAMAccountName?: Maybe<Scalars["String"]>;
  sAMAccountNameStartsWith?: Maybe<Scalars["String"]>;
  sAMAccountNameEndsWith?: Maybe<Scalars["String"]>;
  sAMAccountNameContains?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  displayNameStartsWith?: Maybe<Scalars["String"]>;
  displayNameEndsWith?: Maybe<Scalars["String"]>;
  displayNameContains?: Maybe<Scalars["String"]>;
  givenName?: Maybe<Scalars["String"]>;
  givenNameStartsWith?: Maybe<Scalars["String"]>;
  givenNameEndsWith?: Maybe<Scalars["String"]>;
  givenNameContains?: Maybe<Scalars["String"]>;
  sn?: Maybe<Scalars["String"]>;
  snStartsWith?: Maybe<Scalars["String"]>;
  snEndsWith?: Maybe<Scalars["String"]>;
  snContains?: Maybe<Scalars["String"]>;
};

export type Core_UserAuthenticated = {
  __typename?: "core_UserAuthenticated";
  gasec: Core_GaSecUser;
  ldap: Core_LdapUser;
  username: Scalars["String"];
};

export type Core_UserLookup = {
  __typename?: "core_UserLookup";
  ldap?: Maybe<Core_LdapUser>;
  username: Scalars["String"];
};

export type Core_GaSecUser = {
  __typename?: "core_GASecUser";
  companyId?: Maybe<Scalars["Int"]>;
  department?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
  storedUserName?: Maybe<Scalars["String"]>;
  groups: Array<Maybe<Scalars["String"]>>;
  services: Array<Maybe<Scalars["String"]>>;
  entitlements: Array<Maybe<Core_GaSecEntitlement>>;
  hasPermission: Scalars["Boolean"];
  hasEntitlement: Scalars["Boolean"];
  hasGroup: Scalars["Boolean"];
};

export type Core_GaSecUserEntitlementsArgs = {
  service: Scalars["String"];
};

export type Core_GaSecUserHasPermissionArgs = {
  entitlements?: Maybe<Array<Core_GaSecEntitlementInput>>;
  groups?: Maybe<Array<Scalars["String"]>>;
};

export type Core_GaSecUserHasEntitlementArgs = {
  service: Scalars["String"];
  entitlement: Scalars["String"];
  value: Scalars["String"];
};

export type Core_GaSecUserHasGroupArgs = {
  group: Scalars["String"];
};

export type Core_GaSecEntitlementInput = {
  entitlement: Scalars["String"];
  service: Scalars["String"];
  value: Scalars["String"];
};

export type Core_GaSecEntitlement = {
  __typename?: "core_GASecEntitlement";
  name?: Maybe<Scalars["String"]>;
  values?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Core_LdapUser = {
  __typename?: "core_LdapUser";
  dn?: Maybe<Scalars["String"]>;
  objectClass?: Maybe<Array<Maybe<Scalars["String"]>>>;
  cn?: Maybe<Scalars["String"]>;
  sn?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  physicalDeliveryOfficeName?: Maybe<Scalars["String"]>;
  telephoneNumber?: Maybe<Scalars["String"]>;
  mail?: Maybe<Scalars["String"]>;
  givenName?: Maybe<Scalars["String"]>;
  instanceType?: Maybe<Scalars["String"]>;
  whenCreated?: Maybe<Scalars["ISO8601Timestamp"]>;
  whenChanged?: Maybe<Scalars["ISO8601Timestamp"]>;
  displayName?: Maybe<Scalars["String"]>;
  info?: Maybe<Array<Maybe<Scalars["String"]>>>;
  department?: Maybe<Scalars["String"]>;
  company?: Maybe<Scalars["String"]>;
  proxyAddresses?: Maybe<Array<Maybe<Scalars["String"]>>>;
  otherHomePhone?: Maybe<Scalars["String"]>;
  mailNickname?: Maybe<Scalars["String"]>;
  protocolSettings?: Maybe<Scalars["String"]>;
  employeeType?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  userAccountControl?: Maybe<Scalars["String"]>;
  objectGUID?: Maybe<Scalars["ID"]>;
  objectSid?: Maybe<Scalars["ID"]>;
  badPwdCount?: Maybe<Scalars["Int"]>;
  badPwdTime?: Maybe<Scalars["ISO8601Timestamp"]>;
  pwdLastSet?: Maybe<Scalars["ISO8601Timestamp"]>;
  lastLogon?: Maybe<Scalars["ISO8601Timestamp"]>;
  logonCount?: Maybe<Scalars["Int"]>;
  primaryGroupID?: Maybe<Scalars["String"]>;
  sAMAccountName?: Maybe<Scalars["String"]>;
  sAMAccountType?: Maybe<Scalars["String"]>;
  ipPhone?: Maybe<Scalars["String"]>;
  manager?: Maybe<Core_LdapUser>;
  directReports?: Maybe<Array<Maybe<Core_LdapUser>>>;
  departmentNumber?: Maybe<Scalars["String"]>;
  thumbnailPhoto?: Maybe<Scalars["DataURL"]>;
};

export type ActiveDirectoryComputerQueryResponse = {
  __typename?: "ActiveDirectoryComputerQueryResponse";
  activeDirectoryComputers?: Maybe<Array<Maybe<Skynet_ActiveDirectoryComputer>>>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type AmLinuxComputerQueryResponse = {
  __typename?: "AmLinuxComputerQueryResponse";
  amLinuxComputers?: Maybe<Array<Maybe<Skynet_AmLinuxComputer>>>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export enum AssetItemStatus {
  /** InUse */
  InUse = "InUse",
  /** InStock */
  InStock = "InStock",
  /** RetiredConsumed */
  RetiredConsumed = "RetiredConsumed",
  /** AwaitingReceipt */
  AwaitingReceipt = "AwaitingReceipt",
  /** MaintenanceReturn */
  MaintenanceReturn = "MaintenanceReturn",
  /** SupplierReturn */
  SupplierReturn = "SupplierReturn",
  /** Missing */
  Missing = "Missing",
}

export type AssetLocationQueryResponse = {
  __typename?: "AssetLocationQueryResponse";
  assetLocations?: Maybe<Array<Maybe<Skynet_AssetLocation>>>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type AssetQueryResponse = {
  __typename?: "AssetQueryResponse";
  assets?: Maybe<Array<Maybe<Skynet_Asset>>>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type CertificateInfoQueryResponse = {
  __typename?: "CertificateInfoQueryResponse";
  certificateInfos?: Maybe<Array<Maybe<Skynet_CertificateInfo>>>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export enum ConnectionState {
  /** Active */
  Active = "Active",
  /** Connected */
  Connected = "Connected",
  /** ConnectQuery */
  ConnectQuery = "ConnectQuery",
  /** Shadow */
  Shadow = "Shadow",
  /** Disconnected */
  Disconnected = "Disconnected",
  /** Idle */
  Idle = "Idle",
  /** Listen */
  Listen = "Listen",
  /** Reset */
  Reset = "Reset",
  /** Down */
  Down = "Down",
  /** Init */
  Init = "Init",
}

export type CsLinuxComputerQueryResponse = {
  __typename?: "CsLinuxComputerQueryResponse";
  csLinuxComputers?: Maybe<Array<Maybe<Skynet_CsLinuxComputer>>>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type DhcpLeaseQueryResponse = {
  __typename?: "DhcpLeaseQueryResponse";
  dhcpLeases?: Maybe<Array<Maybe<Skynet_DhcpLease>>>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type DhcpReservationQueryResponse = {
  __typename?: "DhcpReservationQueryResponse";
  dhcpReservations?: Maybe<Array<Maybe<Skynet_DhcpReservation>>>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type DhcpScopeQueryResponse = {
  __typename?: "DhcpScopeQueryResponse";
  dhcpScopes?: Maybe<Array<Maybe<Skynet_DhcpScope>>>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export enum DhcpSubnetClientType {
  /** Dhcp */
  Dhcp = "Dhcp",
  /** Bootp */
  Bootp = "Bootp",
  /** Both */
  Both = "Both",
}

export type DnsResourceRecordQueryResponse = {
  __typename?: "DnsResourceRecordQueryResponse";
  dnsResourceRecords?: Maybe<Array<Maybe<Skynet_DnsResourceRecord>>>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type DnsZoneQueryResponse = {
  __typename?: "DnsZoneQueryResponse";
  dnsZones?: Maybe<Array<Maybe<Skynet_DnsZone>>>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export enum DynamicUpdateValues {
  /** None */
  None = "None",
  /** Secure */
  Secure = "Secure",
  /** NonsecureAndSecure */
  NonsecureAndSecure = "NonsecureAndSecure",
}

export type ExchangeMailboxQueryResponse = {
  __typename?: "ExchangeMailboxQueryResponse";
  exchangeMailboxs?: Maybe<Array<Maybe<Skynet_ExchangeMailbox>>>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export enum FallbackPrintDriver {
  /** None */
  None = "None",
  /** BestGuess */
  BestGuess = "BestGuess",
  /** BestGuessPcl */
  BestGuessPcl = "BestGuessPcl",
  /** BestGuessPs */
  BestGuessPs = "BestGuessPs",
  /** BestGuessPclPs */
  BestGuessPclPs = "BestGuessPclPs",
}

export type FunctionQueryResponse = {
  __typename?: "FunctionQueryResponse";
  functions?: Maybe<Array<Maybe<Skynet_Function>>>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type GqlDate = {
  __typename?: "GQLDate";
  /** @deprecated Deprecated */
  day?: Maybe<Scalars["Int"]>;
  /** @deprecated Deprecated */
  hour?: Maybe<Scalars["Int"]>;
  /** @deprecated Deprecated */
  minute?: Maybe<Scalars["Int"]>;
  /** @deprecated Deprecated */
  month?: Maybe<Scalars["Int"]>;
  /** @deprecated Deprecated */
  second?: Maybe<Scalars["Int"]>;
  /** @deprecated Deprecated */
  year?: Maybe<Scalars["Int"]>;
};

export enum GatewayTransportProtocol {
  /** RpcOverHttp */
  RpcOverHttp = "RpcOverHttp",
  /** Http */
  Http = "Http",
  /** Udp */
  Udp = "Udp",
}

export enum GroupType {
  /** GlobalDistribution */
  GlobalDistribution = "GlobalDistribution",
  /** DomainLocalDistribution */
  DomainLocalDistribution = "DomainLocalDistribution",
  /** UniversalDistribution */
  UniversalDistribution = "UniversalDistribution",
  /** GlobalSecurity */
  GlobalSecurity = "GlobalSecurity",
  /** DomainLocalSecurity */
  DomainLocalSecurity = "DomainLocalSecurity",
  /** BuiltIn */
  BuiltIn = "BuiltIn",
  /** UniversalSecurity */
  UniversalSecurity = "UniversalSecurity",
}

export type InputFilter = {
  above?: Maybe<Scalars["Float"]>;
  below?: Maybe<Scalars["Float"]>;
  between?: Maybe<Scalars["String"]>;
  contains?: Maybe<Scalars["String"]>;
  endsWith?: Maybe<Scalars["String"]>;
  in?: Maybe<Scalars["String"]>;
  startsWith?: Maybe<Scalars["String"]>;
};

export type InputGqlDate = {
  day?: Maybe<Scalars["Int"]>;
  hour?: Maybe<Scalars["Int"]>;
  minute?: Maybe<Scalars["Int"]>;
  month?: Maybe<Scalars["Int"]>;
  second?: Maybe<Scalars["Int"]>;
  year?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_AddMobileDevice = {
  email?: Maybe<Scalars["String"]>;
  friendlyName?: Maybe<Scalars["String"]>;
  locationGroupId?: Maybe<Scalars["Int"]>;
  modelId?: Maybe<Scalars["Int"]>;
  ownership?: Maybe<Scalars["String"]>;
  platformId?: Maybe<Scalars["Int"]>;
  serialNumber?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_AddMobileUser = {
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_AddUsersToSymphonyRoom = {
  roomId?: Maybe<Scalars["String"]>;
  users?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Inputskynet_AddWindowsManagedServiceAccountPrincipals = {
  principal?: Maybe<Array<Maybe<Scalars["String"]>>>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_AddWindowsUserPrincipals = {
  principals?: Maybe<Array<Maybe<Scalars["String"]>>>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_AddZabbixGroupMember = {
  groupId?: Maybe<Scalars["Int"]>;
  userId?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_CCureUser = {
  timeStamp?: Maybe<InputGqlDate>;
  birthDate?: Maybe<Scalars["String"]>;
  cellPhone?: Maybe<Scalars["String"]>;
  department?: Maybe<Scalars["String"]>;
  disabled?: Maybe<Scalars["Int"]>;
  displayName?: Maybe<Scalars["String"]>;
  employeeId?: Maybe<Scalars["String"]>;
  employeeKey?: Maybe<Scalars["Int"]>;
  employeeType?: Maybe<Scalars["Int"]>;
  fax?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  hireDate?: Maybe<Scalars["String"]>;
  homeAddress?: Maybe<Scalars["String"]>;
  homeAddress2?: Maybe<Scalars["String"]>;
  homeCity?: Maybe<Scalars["String"]>;
  homePhone?: Maybe<Scalars["String"]>;
  homeState?: Maybe<Scalars["String"]>;
  homeZip?: Maybe<Scalars["String"]>;
  lastDateWorked?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  managerName?: Maybe<Scalars["String"]>;
  personId?: Maybe<Scalars["String"]>;
  seats?: Maybe<Scalars["String"]>;
  termDate?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateAirwatchMessage = {
  message?: Maybe<Scalars["String"]>;
  users?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Inputskynet_CreateBastionAccount = {
  accountName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  environment?: Maybe<Scalars["String"]>;
  manager?: Maybe<Scalars["String"]>;
  resources?: Maybe<Array<Maybe<Scalars["String"]>>>;
  users?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Inputskynet_CreateBastionDevice = {
  alias?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  environment?: Maybe<Scalars["String"]>;
  hostName?: Maybe<Scalars["String"]>;
  operatingSystem?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateBastionUserGroup = {
  description?: Maybe<Scalars["String"]>;
  environment?: Maybe<Scalars["String"]>;
  groupName?: Maybe<Scalars["String"]>;
  manager?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateCCureUser = {
  user?: Maybe<Inputskynet_CCureUser>;
};

export type Inputskynet_CreateCertificateRequest = {
  hostNames?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Inputskynet_CreateDataPrivilegeDirectPermissionRequest = {
  expirationDate?: Maybe<InputGqlDate>;
  dirId?: Maybe<Scalars["Long"]>;
  entityName?: Maybe<Scalars["String"]>;
  folderPath?: Maybe<Scalars["String"]>;
  isMonitored?: Maybe<Scalars["Boolean"]>;
  opType?: Maybe<Scalars["String"]>;
  permTypeName?: Maybe<Scalars["String"]>;
  reason?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateDataPrivilegeMembershipRequest = {
  autoApprove?: Maybe<Scalars["Boolean"]>;
  groupName?: Maybe<Scalars["String"]>;
  operationType?: Maybe<Scalars["String"]>;
  reason?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateDfsRoot = {
  accessBasedEnumeration?: Maybe<Scalars["Boolean"]>;
  additionalRootAdmins?: Maybe<Array<Maybe<Scalars["String"]>>>;
  description?: Maybe<Scalars["String"]>;
  insiteReferrals?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  overrideTargetServers?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Inputskynet_CreateDfsRootFolder = {
  additionalTargets?: Maybe<Array<Maybe<Scalars["String"]>>>;
  description?: Maybe<Scalars["String"]>;
  insiteReferrals?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  root?: Maybe<Scalars["String"]>;
  targetPath?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateDfsRootFolderTarget = {
  folderName?: Maybe<Scalars["String"]>;
  root?: Maybe<Scalars["String"]>;
  targets?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Inputskynet_CreateDhcpReservation = {
  clientId?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  ipAddress?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  scopeId?: Maybe<Scalars["String"]>;
  serverName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateDhcpReservationOption = {
  optionId?: Maybe<Scalars["Long"]>;
  reservedIp?: Maybe<Scalars["String"]>;
  scopeId?: Maybe<Scalars["String"]>;
  serverName?: Maybe<Scalars["String"]>;
  userClass?: Maybe<Scalars["String"]>;
  value?: Maybe<Array<Maybe<Scalars["String"]>>>;
  vendorClass?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateDhcpScope = {
  description?: Maybe<Scalars["String"]>;
  endRange?: Maybe<Scalars["String"]>;
  leaseDuration?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  serverName?: Maybe<Scalars["String"]>;
  startRange?: Maybe<Scalars["String"]>;
  state?: Maybe<SubnetState>;
  subnetMask?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateDhcpScopeOption = {
  optionId?: Maybe<Scalars["Long"]>;
  scopeId?: Maybe<Scalars["String"]>;
  serverName?: Maybe<Scalars["String"]>;
  userClass?: Maybe<Scalars["String"]>;
  value?: Maybe<Array<Maybe<Scalars["String"]>>>;
  vendorClass?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateDhcpServerOption = {
  optionId?: Maybe<Scalars["Long"]>;
  serverName?: Maybe<Scalars["String"]>;
  userClass?: Maybe<Scalars["String"]>;
  value?: Maybe<Array<Maybe<Scalars["String"]>>>;
  vendorClass?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateDnsARecord = {
  createPtr?: Maybe<Scalars["Boolean"]>;
  ipAddress?: Maybe<Array<Maybe<Scalars["String"]>>>;
  name?: Maybe<Scalars["String"]>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateDnsCnameRecord = {
  hostNameAlias?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateDnsMxRecord = {
  mailExchange?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  preference?: Maybe<Scalars["Int"]>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateDnsNsRecord = {
  name?: Maybe<Scalars["String"]>;
  nameServer?: Maybe<Scalars["String"]>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateDnsPtrRecord = {
  name?: Maybe<Scalars["String"]>;
  ptrDomainName?: Maybe<Scalars["String"]>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateDnsRequest = {
  processTime?: Maybe<InputGqlDate>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateDnsSrvRecord = {
  domainName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  port?: Maybe<Scalars["Int"]>;
  priority?: Maybe<Scalars["Int"]>;
  weight?: Maybe<Scalars["Int"]>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateDnsTxtRecord = {
  descriptiveText?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateDnsZone = {
  networkId?: Maybe<Scalars["String"]>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateExternalCertificate = {
  csr?: Maybe<Scalars["String"]>;
  orderNumber?: Maybe<Scalars["String"]>;
  thumbprint?: Maybe<Scalars["String"]>;
  validityLength?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_CreateFunction = {
  category?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateGroupMember = {
  expirationTime?: Maybe<Scalars["Int"]>;
  memberName?: Maybe<Scalars["String"]>;
  members?: Maybe<Array<Maybe<Scalars["String"]>>>;
  name?: Maybe<Scalars["String"]>;
  replace?: Maybe<Scalars["Boolean"]>;
};

export type Inputskynet_CreateHomeDirectory = {
  csUser?: Maybe<Scalars["Boolean"]>;
  excludeLinux?: Maybe<Scalars["Boolean"]>;
  excludeWindows?: Maybe<Scalars["Boolean"]>;
  location?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
  userType?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateLinuxAutoMount = {
  automountName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  information?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateLinuxAutoMountMap = {
  description?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateLinuxGroup = {
  description?: Maybe<Scalars["String"]>;
  manager?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateLinuxNetGroup = {
  description?: Maybe<Scalars["String"]>;
  manager?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateLinuxNetgroupMember = {
  expirationTime?: Maybe<Scalars["Int"]>;
  members?: Maybe<Array<Maybe<Scalars["String"]>>>;
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateLinuxSudoRole = {
  endTime?: Maybe<InputGqlDate>;
  startTime?: Maybe<InputGqlDate>;
  command?: Maybe<Scalars["String"]>;
  expirationTime?: Maybe<Scalars["Int"]>;
  hosts?: Maybe<Array<Maybe<Scalars["String"]>>>;
  user?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateLinuxUser = {
  user?: Maybe<Inputskynet_LinuxUser>;
};

export type Inputskynet_CreateMacUser = {
  user?: Maybe<Inputskynet_MacUser>;
};

export type Inputskynet_CreateMailboxAccess = {
  alias?: Maybe<Scalars["String"]>;
  expirationTime?: Maybe<Scalars["Int"]>;
  fullAccessUser?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateMailboxEmail = {
  alias?: Maybe<Scalars["String"]>;
  emailAddress?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreatePagerDutyEvent = {
  event_action?: Maybe<Scalars["String"]>;
  payload?: Maybe<Inputskynet_PagerDutyEventPayload>;
  routing_key?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreatePagerDutyUser = {
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateRandomPassword = {
  length?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_CreateSccmCollectionMembership = {
  collectionId?: Maybe<Scalars["String"]>;
  computerNames?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Inputskynet_CreateServerName = {
  environmentCode?: Maybe<Scalars["String"]>;
  functionCode?: Maybe<Scalars["String"]>;
  identifier?: Maybe<Scalars["Short"]>;
  locationCode?: Maybe<Scalars["String"]>;
  operatingSystemCode?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateServiceNowAdHocRequest = {
  approver?: Maybe<Scalars["String"]>;
  approverType?: Maybe<Scalars["String"]>;
  assignmentGroup?: Maybe<Scalars["String"]>;
  correlationId?: Maybe<Scalars["String"]>;
  details?: Maybe<Scalars["String"]>;
  requestedBy?: Maybe<Scalars["String"]>;
  requestedFor?: Maybe<Scalars["String"]>;
  secondaryApprover?: Maybe<Scalars["String"]>;
  secondaryApproverType?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateSlackMessage = {
  sendAt?: Maybe<InputGqlDate>;
  blocks?: Maybe<Array<Maybe<Inputskynet_SlackBlock>>>;
  iconUrl?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  sendAtString?: Maybe<Scalars["String"]>;
  target?: Maybe<Scalars["String"]>;
  ts?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
  workspaceName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateSlackUpload = {
  message?: Maybe<Scalars["String"]>;
  target?: Maybe<Scalars["String"]>;
  ts?: Maybe<Scalars["String"]>;
  workspaceName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateSnowflakeSecurityRequest = {
  processTime?: Maybe<InputGqlDate>;
  account?: Maybe<Scalars["String"]>;
  action?: Maybe<Scalars["String"]>;
  approver?: Maybe<Scalars["String"]>;
  approverEmail?: Maybe<Scalars["String"]>;
  authType?: Maybe<Scalars["String"]>;
  databaseList?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  loginName?: Maybe<Scalars["String"]>;
  loginType?: Maybe<Scalars["String"]>;
  modelAfterLoginName?: Maybe<Scalars["String"]>;
  originalRequestId?: Maybe<Scalars["Int"]>;
  requestor?: Maybe<Scalars["String"]>;
  requestorEmail?: Maybe<Scalars["String"]>;
  roleList?: Maybe<Scalars["String"]>;
  schemaList?: Maybe<Scalars["String"]>;
  sourceRequestId?: Maybe<Scalars["String"]>;
  sourceSystem?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateSymphonyMessage = {
  certificatePassword?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  roomId?: Maybe<Scalars["String"]>;
  roomName?: Maybe<Scalars["String"]>;
  users?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Inputskynet_CreateSymphonyRoom = {
  description?: Maybe<Scalars["String"]>;
  isCopyProtected?: Maybe<Scalars["Boolean"]>;
  isDiscoverable?: Maybe<Scalars["Boolean"]>;
  isPublic?: Maybe<Scalars["Boolean"]>;
  isReadOnly?: Maybe<Scalars["Boolean"]>;
  roomName?: Maybe<Scalars["String"]>;
  users?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Inputskynet_CreateUcsWindowsWorkflow = {
  catalogId?: Maybe<Scalars["String"]>;
  costCode?: Maybe<Scalars["Int"]>;
  cpuReservation?: Maybe<Scalars["Boolean"]>;
  environment?: Maybe<Scalars["String"]>;
  function?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  memory?: Maybe<Scalars["Int"]>;
  memoryReservation?: Maybe<Scalars["Boolean"]>;
  numberOfCpus?: Maybe<Scalars["Int"]>;
  resourceGroup?: Maybe<Scalars["String"]>;
  ucsEnvironment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateUsbBios = {
  id?: Maybe<Scalars["String"]>;
  rearUsb?: Maybe<Scalars["String"]>;
  usbFront?: Maybe<Scalars["String"]>;
  usbPort?: Maybe<Scalars["String"]>;
  usbProvision?: Maybe<Scalars["String"]>;
  usbRearQuad?: Maybe<Scalars["String"]>;
  usbwake?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateVaultSecret = {
  secret?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateWindowsComputerHeartbeat = {
  lastHeartBeat?: Maybe<InputGqlDate>;
  hostname?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateWindowsGroup = {
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  groupCategory?: Maybe<Scalars["String"]>;
  mailEnable?: Maybe<Scalars["Boolean"]>;
  managementAccount?: Maybe<Scalars["String"]>;
  manager?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateWindowsLocalGroupMember = {
  computerName?: Maybe<Scalars["String"]>;
  expirationTime?: Maybe<Scalars["Int"]>;
  groupName?: Maybe<Scalars["String"]>;
  memberName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateWindowsManagedServiceAccount = {
  fullControlUsers?: Maybe<Array<Maybe<Scalars["String"]>>>;
  passwordAccess?: Maybe<Array<Maybe<Scalars["String"]>>>;
  passwordInterval?: Maybe<Scalars["Int"]>;
  principals?: Maybe<Array<Maybe<Scalars["String"]>>>;
  unconstrainedDelegation?: Maybe<Scalars["Boolean"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateWindowsUser = {
  createMailbox?: Maybe<Scalars["Boolean"]>;
  restrictedUser?: Maybe<Scalars["Boolean"]>;
  user?: Maybe<Inputskynet_WindowsUser>;
};

export type Inputskynet_CreateWindowsUserGroup = {
  expirationTime?: Maybe<Scalars["Int"]>;
  groupName?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateWindowsUsersRestrictedHost = {
  hostName?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_CreateZabbixMaintenance = {
  activeSince?: Maybe<InputGqlDate>;
  activeTill?: Maybe<InputGqlDate>;
  startDate?: Maybe<InputGqlDate>;
  collectData?: Maybe<Scalars["Boolean"]>;
  description?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Int"]>;
  hostname?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  period?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_CreateZabbixUser = {
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_DeleteHomeDirectory = {
  delayInDays?: Maybe<Scalars["Int"]>;
  disabledUser?: Maybe<Scalars["Boolean"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_DeleteSccmCollectionMembership = {
  collectionId?: Maybe<Scalars["String"]>;
  computerNames?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Inputskynet_DeleteWindowsUserPrincipals = {
  principals?: Maybe<Array<Maybe<Scalars["String"]>>>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_DisableHomeDirectory = {
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_DisableLinuxUser = {
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_DisableMobileUser = {
  email?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_DisableWindowsGroup = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_EnableContact = {
  alias?: Maybe<Scalars["String"]>;
  externalEmailAddress?: Maybe<Scalars["String"]>;
  hiddenFromAddressList?: Maybe<Scalars["Boolean"]>;
};

export type Inputskynet_EnableWindowsGroup = {
  distinguishedName?: Maybe<Scalars["String"]>;
  members?: Maybe<Array<Maybe<Scalars["String"]>>>;
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetActiveDirectoryComputer = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetActiveDirectoryComputerChangeHistory = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetActiveDirectoryComputerGroups = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetAmLinuxComputerMounts = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetAmLinuxComputerProcesses = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetAmLinuxComputerSoftware = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetBastionAccount = {
  accountName?: Maybe<Scalars["String"]>;
  environment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetBastionAccounts = {
  environment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetBastionAuthorizations = {
  environment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetBastionDevice = {
  environment?: Maybe<Scalars["String"]>;
  hostName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetBastionDevices = {
  environment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetBastionLdapMappings = {
  environment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetBastionTargetGroup = {
  environment?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetBastionTargetGroups = {
  environment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetBastionUserGroups = {
  environment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetBusinessResourceGroup = {
  brgName?: Maybe<Scalars["String"]>;
  environment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetBusinessResourceGroups = {
  environment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetContact = {
  alias?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetCsLinuxComptuerMounts = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDataPrivilegeMembershipRequestStatus = {
  requestId?: Maybe<Scalars["Long"]>;
};

export type Inputskynet_GetDfsRoot = {
  root?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDfsRootFolderTargets = {
  folderName?: Maybe<Scalars["String"]>;
  root?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDfsRootFolders = {
  includeTargets?: Maybe<Scalars["Boolean"]>;
  root?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDfsRootTargets = {
  root?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDhcpFreeIp = {
  endAddress?: Maybe<Scalars["String"]>;
  numAddress?: Maybe<Scalars["Long"]>;
  scopeId?: Maybe<Scalars["String"]>;
  serverName?: Maybe<Scalars["String"]>;
  startAddress?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDhcpLeases = {
  scopeId?: Maybe<Scalars["String"]>;
  serverName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDhcpReservations = {
  scopeId?: Maybe<Scalars["String"]>;
  serverName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDhcpScopes = {
  serverName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDhcpServerLeases = {
  serverName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDhcpServersByIp = {
  ipAddress?: Maybe<Scalars["String"]>;
  subnetMask?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDnsARecord = {
  name?: Maybe<Scalars["String"]>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDnsARecords = {
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDnsCnameRecord = {
  name?: Maybe<Scalars["String"]>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDnsCnameRecords = {
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDnsMxRecord = {
  name?: Maybe<Scalars["String"]>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDnsMxRecords = {
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDnsNsRecord = {
  name?: Maybe<Scalars["String"]>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDnsNsRecords = {
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDnsPtrRecord = {
  name?: Maybe<Scalars["String"]>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDnsPtrRecords = {
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDnsRequestDetails = {
  id?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_GetDnsSrvRecord = {
  name?: Maybe<Scalars["String"]>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDnsSrvRecords = {
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDnsTxtRecord = {
  name?: Maybe<Scalars["String"]>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDnsTxtRecords = {
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetDnsZone = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetEnvironment = {
  code?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetExchangeMailbox = {
  alias?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetExchangeMailboxRules = {
  alias?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetFunction = {
  code?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetHomeDirectories = {
  includeDates?: Maybe<Scalars["Boolean"]>;
};

export type Inputskynet_GetIssuedCertificates = {
  name?: Maybe<Scalars["String"]>;
  server?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetJob = {
  jobId?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_GetJobHistory = {
  jobId?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_GetKvstoreColllection = {
  collectionId?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetKvstoreColllectionRecord = {
  collectionId?: Maybe<Scalars["String"]>;
  recordId?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetKvstoreColllectionRecords = {
  collectionId?: Maybe<Scalars["String"]>;
  fields?: Maybe<Scalars["String"]>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetLinuxAutoMount = {
  automountName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetLinuxAutoMountMap = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetLinuxAutoMounts = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetLinuxGroup = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetLinuxGroupMembers = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetLinuxNetgroup = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetLinuxNetgroupMembers = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetLinuxSudoRole = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetLinuxUser = {
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetLinuxUserGroups = {
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetLinuxUserNetGroups = {
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetLocation = {
  code?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetMailboxAccess = {
  alias?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetMailboxEmail = {
  alias?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetMobileUser = {
  email?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetMobileUsersDevice = {
  email?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetNetworkAdapter = {
  computerName?: Maybe<Scalars["String"]>;
  macAddress?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetNetworkAdapters = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetNextServerNames = {
  environmentCode?: Maybe<Scalars["String"]>;
  functionCode?: Maybe<Scalars["String"]>;
  identifier?: Maybe<Scalars["Short"]>;
  locationCode?: Maybe<Scalars["String"]>;
  numberOfNames?: Maybe<Scalars["Int"]>;
  operatingSystemCode?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetNlyteAuthToken = {
  environment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetNlyteChassis = {
  environment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetNlyteKvmSwitches = {
  environment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetNlyteNetworks = {
  environment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetNlytePeripherals = {
  environment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetNlytePowerStrips = {
  environment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetNlyteServers = {
  environment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetOktaGroup = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetOktaGroupMembers = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetOktaUser = {
  userId?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetOktaUserFactors = {
  userId?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetOperatingSystem = {
  code?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetPagerDutyContactMethods = {
  userId?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetPagerDutyService = {
  serviceId?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetPagerDutyServiceIncidents = {
  serviceId?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetPagerDutyTeam = {
  teamId?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetPagerDutyTeamIncidents = {
  teamId?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetPagerDutyUser = {
  userId?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetReservationDhcpOption = {
  reservedIp?: Maybe<Scalars["String"]>;
  scopeId?: Maybe<Scalars["String"]>;
  serverName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmAddRemove = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmAutoRuns = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmAutostartInfo = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmBho = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmBios = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmBloombergInfo = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmCollection = {
  collectionId?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmCollectionMembership = {
  collectionId?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmDisks = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmLocalGroupMembership = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmLogicalDisks = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmMappedDrives = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmMappedPrinters = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmNetworkAdapters = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmOperatingSystems = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmPhysicalDisks = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmPhysicalMemory = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmPnpDeviceDrivers = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmProcessors = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmRecentlyUsedApplications = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmScsiControllers = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmServerFeatures = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmSoftwareUpdates = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmSystem = {
  systemName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmUsbDevices = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmUserProfiles = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSccmVideoControllers = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetScopeDhcpOption = {
  scopeId?: Maybe<Scalars["String"]>;
  serverName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetServerDhcpOption = {
  serverName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetServerName = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetServiceNowAdHocRequest = {
  ticketId?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSlackChannel = {
  channelId?: Maybe<Scalars["String"]>;
  includeMembership?: Maybe<Scalars["Boolean"]>;
  useSandbox?: Maybe<Scalars["Boolean"]>;
};

export type Inputskynet_GetSlackChannels = {
  includeDetailedInfo?: Maybe<Scalars["Boolean"]>;
  includeMembership?: Maybe<Scalars["Boolean"]>;
  useSandbox?: Maybe<Scalars["Boolean"]>;
};

export type Inputskynet_GetSlackGroup = {
  groupId?: Maybe<Scalars["String"]>;
  includeDetailedInfo?: Maybe<Scalars["Boolean"]>;
  includeMembership?: Maybe<Scalars["Boolean"]>;
  useSandbox?: Maybe<Scalars["Boolean"]>;
};

export type Inputskynet_GetSlackGroups = {
  includeDetailedInfo?: Maybe<Scalars["Boolean"]>;
  includeMembership?: Maybe<Scalars["Boolean"]>;
  useSandbox?: Maybe<Scalars["Boolean"]>;
};

export type Inputskynet_GetSlackIms = {
  includeDetailedInfo?: Maybe<Scalars["Boolean"]>;
  includeMembership?: Maybe<Scalars["Boolean"]>;
  useSandbox?: Maybe<Scalars["Boolean"]>;
};

export type Inputskynet_GetSlackMpims = {
  includeDetailedInfo?: Maybe<Scalars["Boolean"]>;
  includeMembership?: Maybe<Scalars["Boolean"]>;
  useSandbox?: Maybe<Scalars["Boolean"]>;
};

export type Inputskynet_GetSlackUserChannels = {
  mpim?: Maybe<Scalars["Boolean"]>;
  shared?: Maybe<Scalars["Boolean"]>;
  useSandbox?: Maybe<Scalars["Boolean"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSlackUserGroups = {
  mpim?: Maybe<Scalars["Boolean"]>;
  shared?: Maybe<Scalars["Boolean"]>;
  useSandbox?: Maybe<Scalars["Boolean"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSlackUsers = {
  useSandbox?: Maybe<Scalars["Boolean"]>;
};

export type Inputskynet_GetSlackWorkspaceChannels = {
  includeDetailedInfo?: Maybe<Scalars["Boolean"]>;
  includeMembership?: Maybe<Scalars["Boolean"]>;
  shared?: Maybe<Scalars["Boolean"]>;
  useSandbox?: Maybe<Scalars["Boolean"]>;
  workspaceId?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSlackWorkspaceGroups = {
  includeDetailedInfo?: Maybe<Scalars["Boolean"]>;
  includeMembership?: Maybe<Scalars["Boolean"]>;
  shared?: Maybe<Scalars["Boolean"]>;
  useSandbox?: Maybe<Scalars["Boolean"]>;
  workspaceId?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSlackWorkspaces = {
  includeDetailedInfo?: Maybe<Scalars["Boolean"]>;
  includeMembership?: Maybe<Scalars["Boolean"]>;
  useSandbox?: Maybe<Scalars["Boolean"]>;
};

export type Inputskynet_GetSnowflakeSecurityRequest = {
  requestId?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_GetSoftwareInstallations = {
  softwareId?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_GetSqlDatabaseLogin = {
  environment?: Maybe<Scalars["String"]>;
  instanceName?: Maybe<Scalars["String"]>;
  loginId?: Maybe<Scalars["Int"]>;
  serverName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSqlDatabaseLoginMappings = {
  environment?: Maybe<Scalars["String"]>;
  instanceName?: Maybe<Scalars["String"]>;
  loginId?: Maybe<Scalars["Int"]>;
  serverName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSqlDatabaseLogins = {
  environment?: Maybe<Scalars["String"]>;
  instanceName?: Maybe<Scalars["String"]>;
  serverName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetSymphonyUser = {
  id?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_GetUcsIpAddress = {
  policyId?: Maybe<Scalars["Int"]>;
  ucsEnvironment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetUcsIpAddressPools = {
  ucsEnvironment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetUcsTemplate = {
  catalogId?: Maybe<Scalars["String"]>;
  environment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetUcsTemplates = {
  environment?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetVirusTotalInfo = {
  domain?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsAcl = {
  path?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsComputer = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsComputerGroupMembership = {
  computerName?: Maybe<Scalars["String"]>;
  groupName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsComputerGroups = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsComputerHeartbeat = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsComputerLogins = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsComputerNetworkAdapterStatistics = {
  adapterName?: Maybe<Scalars["String"]>;
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsComputerPowerShellNetAdapters = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsComputerProcessDetail = {
  computerName?: Maybe<Scalars["String"]>;
  processId?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_GetWindowsComputerProcesses = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsComputerServiceDetail = {
  computerName?: Maybe<Scalars["String"]>;
  serviceName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsComputerServices = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsComputerTerminalServerSettings = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsComputerUsers = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsComputers = {
  includePrimaryUsers?: Maybe<Scalars["Boolean"]>;
};

export type Inputskynet_GetWindowsDynamicGroup = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsDynamicGroupMembers = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsEasDevices = {
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsGroup = {
  includeMembership?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsGroupChangeHistory = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsGroupMembers = {
  indirect?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsGroups = {
  includeMembership?: Maybe<Scalars["Boolean"]>;
};

export type Inputskynet_GetWindowsLocalGroup = {
  computerName?: Maybe<Scalars["String"]>;
  groupName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsLocalGroupMembership = {
  computerName?: Maybe<Scalars["String"]>;
  groupName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsLocalGroups = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsManagedServiceAccount = {
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsRdpInformation = {
  computerName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsRdpMetrics = {
  computerName?: Maybe<Scalars["String"]>;
  sessionId?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_GetWindowsUser = {
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsUserChangeHistory = {
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsUserComputers = {
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsUserGatewaySessions = {
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsUserGroups = {
  indirect?: Maybe<Scalars["Boolean"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsUserLocalGroups = {
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsUserLogins = {
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsUserPrincipals = {
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetWindowsUserRestrictedHosts = {
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetZabbixGroupMembership = {
  groupId?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_GetZabbixHostGroupsHosts = {
  groupId?: Maybe<Scalars["String"]>;
};

export type Inputskynet_GetZabbixMaintenance = {
  maintenanceId?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_GetZabbixTrigger = {
  triggerId?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_GetZabbixUser = {
  userId?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_LinuxAutoMount = {
  description?: Maybe<Scalars["String"]>;
  information?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_LinuxAutoMountMap = {
  description?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_LinuxUser = {
  creationDate?: Maybe<InputGqlDate>;
  lastUpdated?: Maybe<InputGqlDate>;
  passwordLastSet?: Maybe<InputGqlDate>;
  description?: Maybe<Scalars["String"]>;
  destinationIndicator?: Maybe<Scalars["String"]>;
  disabled?: Maybe<Scalars["Boolean"]>;
  displayName?: Maybe<Scalars["String"]>;
  distinguishedName?: Maybe<Scalars["String"]>;
  employeeType?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  homeDirectory?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  loginShell?: Maybe<Scalars["String"]>;
  owner?: Maybe<Scalars["String"]>;
  primaryGroup?: Maybe<Scalars["Int"]>;
  uidNumber?: Maybe<Scalars["Long"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_ListSymphonyRoomMembership = {
  roomId?: Maybe<Scalars["String"]>;
};

export type Inputskynet_LiveGetLinuxGroup = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_LiveGetLinuxGroupMembers = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_LiveGetLinuxNetgroup = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_LiveGetLinuxNetgroupMembers = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_LiveGetLinuxUser = {
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_LiveGetWindowsDynamicGroup = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_LiveGetWindowsDynamicGroupMembers = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_LiveGetWindowsGroup = {
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_LiveGetWindowsGroupMembers = {
  indirect?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_LiveGetWindowsUser = {
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_LiveGetWindowsUserGroups = {
  indirect?: Maybe<Scalars["Boolean"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_LiveSearchLinuxGroups = {
  modifiedSince?: Maybe<InputGqlDate>;
};

export type Inputskynet_LiveSearchLinuxNetgroups = {
  modifiedSince?: Maybe<InputGqlDate>;
};

export type Inputskynet_LiveSearchLinuxUsers = {
  modifiedSince?: Maybe<InputGqlDate>;
};

export type Inputskynet_LiveSearchWindowsGroups = {
  modifiedSince?: Maybe<InputGqlDate>;
  includeMembership?: Maybe<Scalars["Boolean"]>;
};

export type Inputskynet_LiveSearchWindowsUsers = {
  modifiedSince?: Maybe<InputGqlDate>;
};

export type Inputskynet_MacUser = {
  department?: Maybe<Scalars["String"]>;
  departmentNumber?: Maybe<Scalars["String"]>;
  eMail?: Maybe<Scalars["String"]>;
  employeeId?: Maybe<Scalars["String"]>;
  fax?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  jobCode?: Maybe<Scalars["String"]>;
  manager?: Maybe<Scalars["Int"]>;
  managerName?: Maybe<Scalars["String"]>;
  modem?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  personStatus?: Maybe<Scalars["String"]>;
  personType?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
};

export type Inputskynet_MailDisableWindowsGroup = {
  name?: Maybe<Scalars["String"]>;
  reason?: Maybe<Scalars["String"]>;
};

export type Inputskynet_MigrateSymphonyRoom = {
  slackChannelId?: Maybe<Scalars["String"]>;
  symphonyRoomId?: Maybe<Scalars["String"]>;
};

export type Inputskynet_PagerDutyEventPayload = {
  custom_details?: Maybe<Scalars["String"]>;
  severity?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
};

export type Inputskynet_RemoveUsersFromSymphonyRoom = {
  roomId?: Maybe<Scalars["String"]>;
  users?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Inputskynet_RemoveWindowsManagedServiceAccountPrincipals = {
  principal?: Maybe<Array<Maybe<Scalars["String"]>>>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_ReserveSolwarwindsIp = {
  subnetAddress?: Maybe<Scalars["String"]>;
  subnetMask?: Maybe<Scalars["String"]>;
  systemName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_RestartWindowsComputerService = {
  computerName?: Maybe<Scalars["String"]>;
  serviceName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_ScheduleDnsRequest = {
  requestDate?: Maybe<InputGqlDate>;
  id?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchActiveDirectoryComputers = {
  accountName?: Maybe<InputFilter>;
  costCode?: Maybe<InputFilter>;
  distinguishedName?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  location?: Maybe<InputFilter>;
  name?: Maybe<InputFilter>;
  objectGuid?: Maybe<InputFilter>;
  operatingSystem?: Maybe<InputFilter>;
  operatingSystemVersion?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  reportingUnit?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  userAccountControl?: Maybe<InputFilter>;
};

export type Inputskynet_SearchAmLinuxComputers = {
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchAssetLocation = {
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchAssets = {
  computerName?: Maybe<Scalars["String"]>;
  costCode?: Maybe<Scalars["String"]>;
  fields?: Maybe<Scalars["String"]>;
  hardwareType?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  itemStatus?: Maybe<AssetItemStatus>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchCsLinuxComputers = {
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchDhcpLeases = {
  description?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  hostName?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  ipAddress?: Maybe<InputFilter>;
  macAddress?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  scopeId?: Maybe<Scalars["String"]>;
  serverName?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchDhcpReservations = {
  clientId?: Maybe<InputFilter>;
  description?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  reservedIp?: Maybe<InputFilter>;
  scopeId?: Maybe<Scalars["String"]>;
  serverName?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchDhcpScopes = {
  description?: Maybe<InputFilter>;
  endRange?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  leaseDuration?: Maybe<InputFilter>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  scopeId?: Maybe<InputFilter>;
  serverName?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  startRange?: Maybe<InputFilter>;
  subnetMask?: Maybe<InputFilter>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchDhcpServerLeases = {
  description?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  hostName?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  ipAddress?: Maybe<InputFilter>;
  macAddress?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  serverName?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchDnsARecords = {
  distinguishedName?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  hostName?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  recordDetails?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  type?: Maybe<InputFilter>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_SearchDnsCnameRecords = {
  distinguishedName?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  hostName?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  recordDetails?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  type?: Maybe<InputFilter>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_SearchDnsNsRecords = {
  distinguishedName?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  hostName?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  recordDetails?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  type?: Maybe<InputFilter>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_SearchDnsPtrRecords = {
  distinguishedName?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  hostName?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  recordDetails?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  type?: Maybe<InputFilter>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_SearchDnsSrvRecords = {
  distinguishedName?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  hostName?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  recordDetails?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  type?: Maybe<InputFilter>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_SearchDnsTxtRecords = {
  distinguishedName?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  hostName?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  recordDetails?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  type?: Maybe<InputFilter>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_SearchDnsZones = {
  directoryPartitionName?: Maybe<InputFilter>;
  distinguishedName?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  zoneName?: Maybe<InputFilter>;
};

export type Inputskynet_SearchExchangeMailboxes = {
  alias?: Maybe<InputFilter>;
  database?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  retentionPolicy?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchFunctions = {
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchIssuedCertificates = {
  certificateTemplate?: Maybe<InputFilter>;
  commonName?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  organization?: Maybe<InputFilter>;
  organizationUnit?: Maybe<InputFilter>;
  serialNumber?: Maybe<InputFilter>;
  server?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  userPrincipalName?: Maybe<InputFilter>;
};

export type Inputskynet_SearchJobs = {
  duration?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  id?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  state?: Maybe<InputFilter>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchLinuxGroup = {
  description?: Maybe<InputFilter>;
  distinguishedName?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  gidNumber?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  manager?: Maybe<InputFilter>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchLinuxNetgroups = {
  description?: Maybe<InputFilter>;
  distinguishedName?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  manager?: Maybe<InputFilter>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchLinuxSudoRoles = {
  command?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  host?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  user?: Maybe<InputFilter>;
};

export type Inputskynet_SearchLinuxUsers = {
  description?: Maybe<InputFilter>;
  destinationIndicator?: Maybe<InputFilter>;
  displayName?: Maybe<InputFilter>;
  distinguishedName?: Maybe<InputFilter>;
  employeeType?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  firstName?: Maybe<InputFilter>;
  homeDirectory?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  lastName?: Maybe<InputFilter>;
  location?: Maybe<InputFilter>;
  loginShell?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  owner?: Maybe<InputFilter>;
  primaryGroup?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  userName?: Maybe<InputFilter>;
};

export type Inputskynet_SearchMobileDevices = {
  acLineStatus?: Maybe<InputFilter>;
  assetNumber?: Maybe<InputFilter>;
  complianceStatus?: Maybe<InputFilter>;
  deviceFriendlyName?: Maybe<InputFilter>;
  easId?: Maybe<InputFilter>;
  enrollmentStatus?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  id?: Maybe<InputFilter>;
  imei?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  locationGroupName?: Maybe<InputFilter>;
  macAddress?: Maybe<InputFilter>;
  model?: Maybe<InputFilter>;
  operatingSystem?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  ownership?: Maybe<InputFilter>;
  phoneNumber?: Maybe<InputFilter>;
  platform?: Maybe<InputFilter>;
  serialNumber?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  udid?: Maybe<InputFilter>;
  userEmailAddress?: Maybe<InputFilter>;
  userName?: Maybe<InputFilter>;
  virtualMemory?: Maybe<InputFilter>;
};

export type Inputskynet_SearchMobileUsers = {
  contactNumber?: Maybe<InputFilter>;
  customAttribute1?: Maybe<InputFilter>;
  email?: Maybe<InputFilter>;
  emailUserName?: Maybe<InputFilter>;
  enrolledDevicesCount?: Maybe<InputFilter>;
  externalId?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  firstName?: Maybe<InputFilter>;
  group?: Maybe<InputFilter>;
  id?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  lastName?: Maybe<InputFilter>;
  locationGroupId?: Maybe<InputFilter>;
  messageType?: Maybe<InputFilter>;
  mobileNumber?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  role?: Maybe<InputFilter>;
  securityType?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  userName?: Maybe<InputFilter>;
};

export type Inputskynet_SearchOktaUsers = {
  email?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  firstName?: Maybe<InputFilter>;
  id?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  lastName?: Maybe<InputFilter>;
  login?: Maybe<InputFilter>;
  mobilePhone?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  secondaryEmail?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  status?: Maybe<InputFilter>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchOrgMap = {
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchPagerDutyServiceIncidents = {
  fields?: Maybe<Scalars["String"]>;
  html_Url?: Maybe<InputFilter>;
  id?: Maybe<InputFilter>;
  incident_Key?: Maybe<InputFilter>;
  incident_Number?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  self?: Maybe<InputFilter>;
  serviceId?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  status?: Maybe<InputFilter>;
  summary?: Maybe<InputFilter>;
  take?: Maybe<Scalars["Int"]>;
  title?: Maybe<InputFilter>;
};

export type Inputskynet_SearchPagerDutyServices = {
  acknowledgement_Timeout?: Maybe<InputFilter>;
  auto_Resolve_Timeout?: Maybe<InputFilter>;
  description?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  html_Url?: Maybe<InputFilter>;
  id?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  self?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  status?: Maybe<InputFilter>;
  summary?: Maybe<InputFilter>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchPagerDutyTeamIncidents = {
  fields?: Maybe<Scalars["String"]>;
  html_Url?: Maybe<InputFilter>;
  id?: Maybe<InputFilter>;
  incident_Key?: Maybe<InputFilter>;
  incident_Number?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  self?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  status?: Maybe<InputFilter>;
  summary?: Maybe<InputFilter>;
  take?: Maybe<Scalars["Int"]>;
  teamId?: Maybe<Scalars["String"]>;
  title?: Maybe<InputFilter>;
};

export type Inputskynet_SearchPagerDutyTeams = {
  description?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  html_Url?: Maybe<InputFilter>;
  id?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  self?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  summary?: Maybe<InputFilter>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchPagerDutyUsers = {
  avatar_Url?: Maybe<InputFilter>;
  color?: Maybe<InputFilter>;
  email?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  html_Url?: Maybe<InputFilter>;
  id?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  invitation_Sent?: Maybe<InputFilter>;
  job_Title?: Maybe<InputFilter>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  password?: Maybe<InputFilter>;
  role?: Maybe<InputFilter>;
  self?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  summary?: Maybe<InputFilter>;
  take?: Maybe<Scalars["Int"]>;
  timeZone?: Maybe<InputFilter>;
  type?: Maybe<InputFilter>;
};

export type Inputskynet_SearchSccmApplications = {
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchSccmCollections = {
  collectionID?: Maybe<InputFilter>;
  comment?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  memberCount?: Maybe<InputFilter>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchSccmLocalGroupReport = {
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchSccmSoftware = {
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchSccmSystems = {
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchServerName = {
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchServiceNowUsers = {
  building?: Maybe<InputFilter>;
  email?: Maybe<InputFilter>;
  employeeNumber?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  firstName?: Maybe<InputFilter>;
  id?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  jobDescription?: Maybe<InputFilter>;
  jobTitle?: Maybe<InputFilter>;
  lastName?: Maybe<InputFilter>;
  manager?: Maybe<InputFilter>;
  mobilePhone?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  phone?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  userName?: Maybe<InputFilter>;
};

export type Inputskynet_SearchSlackChannels = {
  creator?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  id?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  includeMembership?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  useSandbox?: Maybe<Scalars["Boolean"]>;
  workspace?: Maybe<InputFilter>;
};

export type Inputskynet_SearchSlackGroups = {
  creator?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  id?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  includeDetailedInfo?: Maybe<Scalars["Boolean"]>;
  includeMembership?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  useSandbox?: Maybe<Scalars["Boolean"]>;
  workspace?: Maybe<InputFilter>;
};

export type Inputskynet_SearchSlackIms = {
  creator?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  id?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  includeDetailedInfo?: Maybe<Scalars["Boolean"]>;
  includeMembership?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  useSandbox?: Maybe<Scalars["Boolean"]>;
  workspace?: Maybe<InputFilter>;
};

export type Inputskynet_SearchSlackMpims = {
  creator?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  id?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  includeDetailedInfo?: Maybe<Scalars["Boolean"]>;
  includeMembership?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  useSandbox?: Maybe<Scalars["Boolean"]>;
  workspace?: Maybe<InputFilter>;
};

export type Inputskynet_SearchSlackUsers = {
  email?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  id?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  realName?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  updated?: Maybe<InputFilter>;
  useSandbox?: Maybe<Scalars["Boolean"]>;
};

export type Inputskynet_SearchSlackWorkspaces = {
  domain?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  id?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  includeDetailedInfo?: Maybe<Scalars["Boolean"]>;
  includeMembership?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  useSandbox?: Maybe<Scalars["Boolean"]>;
};

export type Inputskynet_SearchUcsIpAddressPools = {
  allowIPOverlap?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  gatewayIPAddress?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  policyDescription?: Maybe<InputFilter>;
  policyID?: Maybe<InputFilter>;
  policyName?: Maybe<InputFilter>;
  scope?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  staticIPPool?: Maybe<InputFilter>;
  subnetMask?: Maybe<InputFilter>;
  take?: Maybe<Scalars["Int"]>;
  ucsEnvironment?: Maybe<Scalars["String"]>;
  usedBy?: Maybe<InputFilter>;
  vlanid?: Maybe<InputFilter>;
};

export type Inputskynet_SearchWindowsComputers = {
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchWindowsDisabledUsers = {
  adjustedTenure?: Maybe<InputFilter>;
  badgeId?: Maybe<InputFilter>;
  company?: Maybe<InputFilter>;
  country?: Maybe<InputFilter>;
  department?: Maybe<InputFilter>;
  departmentDescription?: Maybe<InputFilter>;
  description?: Maybe<InputFilter>;
  displayName?: Maybe<InputFilter>;
  distinguishedName?: Maybe<InputFilter>;
  email?: Maybe<InputFilter>;
  emailRestrictionGroup?: Maybe<InputFilter>;
  employeeId?: Maybe<InputFilter>;
  employeeType?: Maybe<InputFilter>;
  entityName?: Maybe<InputFilter>;
  faxNumber?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  firewallGroup?: Maybe<InputFilter>;
  firstName?: Maybe<InputFilter>;
  homePhone?: Maybe<InputFilter>;
  horizonAccessGroup?: Maybe<InputFilter>;
  hrBusinessUnit?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  initials?: Maybe<InputFilter>;
  ipPhone?: Maybe<InputFilter>;
  jobCode?: Maybe<InputFilter>;
  lastName?: Maybe<InputFilter>;
  location?: Maybe<InputFilter>;
  manager?: Maybe<InputFilter>;
  managerEmployeeId?: Maybe<InputFilter>;
  managerUserName?: Maybe<InputFilter>;
  middleName?: Maybe<InputFilter>;
  mobilePhone?: Maybe<InputFilter>;
  notes?: Maybe<InputFilter>;
  objectGuid?: Maybe<InputFilter>;
  officeLocation?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  owner?: Maybe<InputFilter>;
  personalMobile?: Maybe<InputFilter>;
  sid?: Maybe<InputFilter>;
  sipAddress?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  userAccountControl?: Maybe<InputFilter>;
  userName?: Maybe<InputFilter>;
  userPrincipalName?: Maybe<InputFilter>;
  userProfilePath?: Maybe<InputFilter>;
  userType?: Maybe<InputFilter>;
  voiceRecordingIndicator?: Maybe<InputFilter>;
  workPhone?: Maybe<InputFilter>;
};

export type Inputskynet_SearchWindowsDynamicGroups = {
  displayName?: Maybe<InputFilter>;
  email?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  filter?: Maybe<InputFilter>;
  guid?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  managedBy?: Maybe<InputFilter>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  recipientType?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchWindowsGroups = {
  description?: Maybe<InputFilter>;
  displayName?: Maybe<InputFilter>;
  distinguishedName?: Maybe<InputFilter>;
  email?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  includeMembership?: Maybe<Scalars["Boolean"]>;
  managedBy?: Maybe<InputFilter>;
  managementAccount?: Maybe<InputFilter>;
  name?: Maybe<InputFilter>;
  notes?: Maybe<InputFilter>;
  objectGuid?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  outlookDisplayName?: Maybe<InputFilter>;
  sid?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchWindowsUsers = {
  adjustedTenure?: Maybe<InputFilter>;
  badgeId?: Maybe<InputFilter>;
  company?: Maybe<InputFilter>;
  country?: Maybe<InputFilter>;
  department?: Maybe<InputFilter>;
  departmentDescription?: Maybe<InputFilter>;
  description?: Maybe<InputFilter>;
  displayName?: Maybe<InputFilter>;
  distinguishedName?: Maybe<InputFilter>;
  email?: Maybe<Scalars["String"]>;
  emailRestrictionGroup?: Maybe<InputFilter>;
  employeeId?: Maybe<InputFilter>;
  employeeType?: Maybe<InputFilter>;
  entityName?: Maybe<InputFilter>;
  faxNumber?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  firewallGroup?: Maybe<InputFilter>;
  firstName?: Maybe<InputFilter>;
  homePhone?: Maybe<InputFilter>;
  horizonAccessGroup?: Maybe<InputFilter>;
  hrBusinessUnit?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  initials?: Maybe<InputFilter>;
  ipPhone?: Maybe<InputFilter>;
  jobCode?: Maybe<InputFilter>;
  lastName?: Maybe<InputFilter>;
  location?: Maybe<InputFilter>;
  manager?: Maybe<InputFilter>;
  managerEmployeeId?: Maybe<InputFilter>;
  managerUserName?: Maybe<InputFilter>;
  middleName?: Maybe<InputFilter>;
  mobilePhone?: Maybe<InputFilter>;
  notes?: Maybe<InputFilter>;
  objectGuid?: Maybe<InputFilter>;
  officeLocation?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  owner?: Maybe<InputFilter>;
  personalMobile?: Maybe<InputFilter>;
  sid?: Maybe<Scalars["String"]>;
  sipAddress?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  userAccountControl?: Maybe<InputFilter>;
  userName?: Maybe<Scalars["String"]>;
  userPrincipalName?: Maybe<InputFilter>;
  userProfilePath?: Maybe<InputFilter>;
  userType?: Maybe<InputFilter>;
  voiceRecordingIndicator?: Maybe<InputFilter>;
  workPhone?: Maybe<InputFilter>;
};

export type Inputskynet_SearchWindowsUsersById = {
  adjustedTenure?: Maybe<InputFilter>;
  badgeId?: Maybe<InputFilter>;
  company?: Maybe<InputFilter>;
  country?: Maybe<InputFilter>;
  department?: Maybe<InputFilter>;
  departmentDescription?: Maybe<InputFilter>;
  description?: Maybe<InputFilter>;
  displayName?: Maybe<InputFilter>;
  distinguishedName?: Maybe<InputFilter>;
  email?: Maybe<InputFilter>;
  emailRestrictionGroup?: Maybe<InputFilter>;
  employeeId?: Maybe<InputFilter>;
  employeeType?: Maybe<InputFilter>;
  entityName?: Maybe<InputFilter>;
  faxNumber?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  firewallGroup?: Maybe<InputFilter>;
  firstName?: Maybe<InputFilter>;
  homePhone?: Maybe<InputFilter>;
  horizonAccessGroup?: Maybe<InputFilter>;
  hrBusinessUnit?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  initials?: Maybe<InputFilter>;
  ipPhone?: Maybe<InputFilter>;
  jobCode?: Maybe<InputFilter>;
  lastName?: Maybe<InputFilter>;
  location?: Maybe<InputFilter>;
  manager?: Maybe<InputFilter>;
  managerEmployeeId?: Maybe<InputFilter>;
  managerUserName?: Maybe<InputFilter>;
  middleName?: Maybe<InputFilter>;
  mobilePhone?: Maybe<InputFilter>;
  notes?: Maybe<InputFilter>;
  objectGuid?: Maybe<InputFilter>;
  officeLocation?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  owner?: Maybe<InputFilter>;
  personalMobile?: Maybe<InputFilter>;
  sid?: Maybe<InputFilter>;
  sipAddress?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  userAccountControl?: Maybe<InputFilter>;
  userName?: Maybe<InputFilter>;
  userPrincipalName?: Maybe<InputFilter>;
  userProfilePath?: Maybe<InputFilter>;
  userType?: Maybe<InputFilter>;
  voiceRecordingIndicator?: Maybe<InputFilter>;
  workPhone?: Maybe<InputFilter>;
};

export type Inputskynet_SearchWorkdayDepartments = {
  business?: Maybe<InputFilter>;
  businessCode?: Maybe<InputFilter>;
  departmentBusinessUnit?: Maybe<InputFilter>;
  departmentCooEmployeeId?: Maybe<InputFilter>;
  departmentCooName?: Maybe<InputFilter>;
  departmentCooPreferredName?: Maybe<InputFilter>;
  departmentFunction?: Maybe<InputFilter>;
  departmentId?: Maybe<InputFilter>;
  departmentItManagerEmployeeId?: Maybe<InputFilter>;
  departmentItManagerName?: Maybe<InputFilter>;
  departmentItManagerPreferredName?: Maybe<InputFilter>;
  departmentName?: Maybe<InputFilter>;
  departmentSubFunction?: Maybe<InputFilter>;
  entityId?: Maybe<InputFilter>;
  entityName?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  functionHeadEmployeeId?: Maybe<InputFilter>;
  functionHeadName?: Maybe<InputFilter>;
  functionHeadPreferredName?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  workdayId?: Maybe<InputFilter>;
};

export type Inputskynet_SearchWorkdayLocations = {
  addressLine1?: Maybe<InputFilter>;
  addressLine2?: Maybe<InputFilter>;
  addressLine3?: Maybe<InputFilter>;
  addressLine4?: Maybe<InputFilter>;
  city?: Maybe<InputFilter>;
  countryCode?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  locationId?: Maybe<InputFilter>;
  locationName?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  peopleSoftLocationId?: Maybe<InputFilter>;
  postalCode?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  state?: Maybe<InputFilter>;
  stateDescription?: Maybe<InputFilter>;
  take?: Maybe<Scalars["Int"]>;
  workdayId?: Maybe<InputFilter>;
};

export type Inputskynet_SearchWorkdayPeople = {
  business?: Maybe<InputFilter>;
  businessTitle?: Maybe<InputFilter>;
  businessUnit?: Maybe<InputFilter>;
  businessUnitDescription?: Maybe<InputFilter>;
  citadelVoiceRecordingIndicator?: Maybe<InputFilter>;
  company?: Maybe<InputFilter>;
  companyDescription?: Maybe<InputFilter>;
  contractEndDate?: Maybe<InputFilter>;
  department?: Maybe<InputFilter>;
  departmentFunction?: Maybe<InputFilter>;
  departmentId?: Maybe<InputFilter>;
  emailAddress?: Maybe<InputFilter>;
  emailRestrictionGroup?: Maybe<InputFilter>;
  employeeId?: Maybe<InputFilter>;
  employeeStatus?: Maybe<InputFilter>;
  employeeTypeDescription?: Maybe<InputFilter>;
  employeeTypeId?: Maybe<InputFilter>;
  entityTypeName?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  firstName?: Maybe<InputFilter>;
  fullPartTime?: Maybe<InputFilter>;
  functionId?: Maybe<InputFilter>;
  headcountClass?: Maybe<InputFilter>;
  homeAddressEffectiveStatus?: Maybe<InputFilter>;
  homeAddressLine1?: Maybe<InputFilter>;
  homeAddressLine2?: Maybe<InputFilter>;
  homeAddressLine3?: Maybe<InputFilter>;
  homeAddressLine4?: Maybe<InputFilter>;
  homeCity?: Maybe<InputFilter>;
  homeCountry?: Maybe<InputFilter>;
  homePostalCode?: Maybe<InputFilter>;
  homeState?: Maybe<InputFilter>;
  horizonAccessGroup?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  jobAction?: Maybe<InputFilter>;
  jobCodeDescription?: Maybe<InputFilter>;
  lastName?: Maybe<InputFilter>;
  location?: Maybe<InputFilter>;
  locationDescription?: Maybe<InputFilter>;
  managerId?: Maybe<InputFilter>;
  middleName?: Maybe<InputFilter>;
  miisguid?: Maybe<InputFilter>;
  name?: Maybe<InputFilter>;
  networkStatus?: Maybe<InputFilter>;
  nonEmployeeHasBadge?: Maybe<InputFilter>;
  nonEmployeeHasNetworkAccess?: Maybe<InputFilter>;
  nonEmployeeSiteClassification?: Maybe<InputFilter>;
  nonEmployeeVendorName?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  otherEmail?: Maybe<InputFilter>;
  personStatus?: Maybe<InputFilter>;
  personType?: Maybe<InputFilter>;
  preferredFirstName?: Maybe<InputFilter>;
  preferredLastName?: Maybe<InputFilter>;
  preferredMiddleName?: Maybe<InputFilter>;
  program?: Maybe<InputFilter>;
  programYear?: Maybe<InputFilter>;
  regularTemporary?: Maybe<InputFilter>;
  regulatoryRegion?: Maybe<InputFilter>;
  rehireDate?: Maybe<InputFilter>;
  rowCategory?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  standardHours?: Maybe<InputFilter>;
  take?: Maybe<Scalars["Int"]>;
  tenure?: Maybe<InputFilter>;
  totalEmploymentTenureMonths?: Maybe<InputFilter>;
  userName?: Maybe<InputFilter>;
  workdayId?: Maybe<InputFilter>;
};

export type Inputskynet_SearchWorkdayQuarterlyRoleDescriptions = {
  comment0?: Maybe<InputFilter>;
  comment1?: Maybe<InputFilter>;
  comment2?: Maybe<InputFilter>;
  comment3?: Maybe<InputFilter>;
  comment4?: Maybe<InputFilter>;
  comment5?: Maybe<InputFilter>;
  comment6?: Maybe<InputFilter>;
  comment7?: Maybe<InputFilter>;
  comment8?: Maybe<InputFilter>;
  company?: Maybe<InputFilter>;
  companyDescription?: Maybe<InputFilter>;
  department?: Maybe<InputFilter>;
  departmentId?: Maybe<InputFilter>;
  employeeId?: Maybe<InputFilter>;
  employmentStatus?: Maybe<InputFilter>;
  employmentType?: Maybe<InputFilter>;
  employmentTypeDescription?: Maybe<InputFilter>;
  employmentTypeId?: Maybe<InputFilter>;
  evaluatedByManager?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  firstName?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  lastName?: Maybe<InputFilter>;
  lastRefreshedOn?: Maybe<InputFilter>;
  location?: Maybe<InputFilter>;
  locationDescription?: Maybe<InputFilter>;
  managerId?: Maybe<InputFilter>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  preferredFirstName?: Maybe<InputFilter>;
  preferredLastName?: Maybe<InputFilter>;
  preferredName?: Maybe<InputFilter>;
  question0?: Maybe<InputFilter>;
  question1?: Maybe<InputFilter>;
  question2?: Maybe<InputFilter>;
  question3?: Maybe<InputFilter>;
  question4?: Maybe<InputFilter>;
  question5?: Maybe<InputFilter>;
  question6?: Maybe<InputFilter>;
  question7?: Maybe<InputFilter>;
  question8?: Maybe<InputFilter>;
  reviewPeriodEndDate?: Maybe<InputFilter>;
  reviewPeriodStartDate?: Maybe<InputFilter>;
  reviewSectionsGroup?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  templateName?: Maybe<InputFilter>;
  username?: Maybe<InputFilter>;
  workdayId?: Maybe<InputFilter>;
};

export type Inputskynet_SearchZabbixHostGroups = {
  fields?: Maybe<Scalars["String"]>;
  groupId?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchZabbixHosts = {
  description?: Maybe<InputFilter>;
  error?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  friendlyName?: Maybe<InputFilter>;
  hostId?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  maintenanceId?: Maybe<InputFilter>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  proxyHostId?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SearchZabbixTriggers = {
  comments?: Maybe<InputFilter>;
  correlationTag?: Maybe<InputFilter>;
  description?: Maybe<InputFilter>;
  error?: Maybe<InputFilter>;
  expression?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  recoveryExpression?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  templateId?: Maybe<InputFilter>;
  triggerId?: Maybe<InputFilter>;
  url?: Maybe<InputFilter>;
};

export type Inputskynet_SearchZabbixUsers = {
  alias?: Maybe<InputFilter>;
  attemptFailed?: Maybe<InputFilter>;
  attemptIp?: Maybe<InputFilter>;
  autoLogout?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  lang?: Maybe<InputFilter>;
  name?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  refresh?: Maybe<InputFilter>;
  rowsPerPage?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  surname?: Maybe<InputFilter>;
  take?: Maybe<Scalars["Int"]>;
  theme?: Maybe<InputFilter>;
  url?: Maybe<InputFilter>;
  userId?: Maybe<InputFilter>;
};

export type Inputskynet_SearchtDnsMxRecords = {
  distinguishedName?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  hostName?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  recordDetails?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  type?: Maybe<InputFilter>;
  zoneName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_SetExchangeMailbox = {
  addEmailAddresses?: Maybe<Array<Maybe<Scalars["String"]>>>;
  alias?: Maybe<Scalars["String"]>;
  removeEmailAddresses?: Maybe<Array<Maybe<Scalars["String"]>>>;
  retentionPolicy?: Maybe<Scalars["String"]>;
};

export type Inputskynet_SetHomeDirectoryLegalHold = {
  enable?: Maybe<Scalars["Boolean"]>;
  homedirType?: Maybe<Scalars["String"]>;
  userDisabled?: Maybe<Scalars["Boolean"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_SlackBlock = {
  accessory?: Maybe<Inputskynet_SlackElement>;
  alt_text?: Maybe<Scalars["String"]>;
  block_id?: Maybe<Scalars["String"]>;
  fields?: Maybe<Array<Maybe<Inputskynet_SlackText>>>;
  image_url?: Maybe<Scalars["String"]>;
  text?: Maybe<Inputskynet_SlackText>;
  title?: Maybe<Inputskynet_SlackText>;
  type?: Maybe<Scalars["String"]>;
};

export type Inputskynet_SlackConfirm = {
  confirm?: Maybe<Inputskynet_SlackText>;
  deny?: Maybe<Inputskynet_SlackText>;
  text?: Maybe<Inputskynet_SlackText>;
  title?: Maybe<Inputskynet_SlackText>;
};

export type Inputskynet_SlackElement = {
  action_id?: Maybe<Scalars["String"]>;
  alt_text?: Maybe<Scalars["String"]>;
  confirm?: Maybe<Inputskynet_SlackConfirm>;
  image_url?: Maybe<Scalars["String"]>;
  initial_channel?: Maybe<Scalars["String"]>;
  initial_date?: Maybe<Scalars["String"]>;
  initial_user?: Maybe<Scalars["String"]>;
  option_groups?: Maybe<Array<Maybe<Inputskynet_SlackOptionGroups>>>;
  options?: Maybe<Array<Maybe<Inputskynet_SlackOption>>>;
  placeholder?: Maybe<Inputskynet_SlackText>;
  style?: Maybe<Scalars["String"]>;
  text?: Maybe<Inputskynet_SlackText>;
  type?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type Inputskynet_SlackOption = {
  text?: Maybe<Inputskynet_SlackText>;
  value?: Maybe<Scalars["String"]>;
};

export type Inputskynet_SlackOptionGroups = {
  label?: Maybe<Inputskynet_SlackText>;
  options?: Maybe<Array<Maybe<Inputskynet_SlackOption>>>;
};

export type Inputskynet_SlackText = {
  emoji?: Maybe<Scalars["Boolean"]>;
  text?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  verbatim?: Maybe<Scalars["Boolean"]>;
};

export type Inputskynet_StartWindowsComputerService = {
  computerName?: Maybe<Scalars["String"]>;
  serviceName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_StopWindowsComputerService = {
  computerName?: Maybe<Scalars["String"]>;
  serviceName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_SuggestWindowsGroups = {
  description?: Maybe<InputFilter>;
  displayName?: Maybe<Scalars["String"]>;
  distinguishedName?: Maybe<InputFilter>;
  email?: Maybe<Scalars["String"]>;
  fields?: Maybe<Scalars["String"]>;
  include?: Maybe<Scalars["String"]>;
  managedBy?: Maybe<InputFilter>;
  managementAccount?: Maybe<InputFilter>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<InputFilter>;
  objectGuid?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  outlookDisplayName?: Maybe<InputFilter>;
  sid?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
};

export type Inputskynet_SuggestWindowsUsers = {
  adjustedTenure?: Maybe<InputFilter>;
  badgeId?: Maybe<InputFilter>;
  company?: Maybe<InputFilter>;
  country?: Maybe<InputFilter>;
  department?: Maybe<InputFilter>;
  departmentDescription?: Maybe<InputFilter>;
  description?: Maybe<InputFilter>;
  displayName?: Maybe<InputFilter>;
  distinguishedName?: Maybe<InputFilter>;
  email?: Maybe<Scalars["String"]>;
  emailRestrictionGroup?: Maybe<InputFilter>;
  employeeId?: Maybe<InputFilter>;
  employeeType?: Maybe<InputFilter>;
  entityName?: Maybe<InputFilter>;
  faxNumber?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  firewallGroup?: Maybe<InputFilter>;
  firstName?: Maybe<Scalars["String"]>;
  homePhone?: Maybe<InputFilter>;
  horizonAccessGroup?: Maybe<InputFilter>;
  hrBusinessUnit?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  initials?: Maybe<InputFilter>;
  ipPhone?: Maybe<InputFilter>;
  jobCode?: Maybe<InputFilter>;
  lastName?: Maybe<Scalars["String"]>;
  location?: Maybe<InputFilter>;
  manager?: Maybe<InputFilter>;
  managerEmployeeId?: Maybe<InputFilter>;
  managerUserName?: Maybe<InputFilter>;
  middleName?: Maybe<InputFilter>;
  mobilePhone?: Maybe<InputFilter>;
  notes?: Maybe<InputFilter>;
  objectGuid?: Maybe<InputFilter>;
  officeLocation?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  owner?: Maybe<InputFilter>;
  personalMobile?: Maybe<InputFilter>;
  sid?: Maybe<InputFilter>;
  sipAddress?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  take?: Maybe<Scalars["Int"]>;
  userAccountControl?: Maybe<InputFilter>;
  userName?: Maybe<Scalars["String"]>;
  userPrincipalName?: Maybe<InputFilter>;
  userProfilePath?: Maybe<InputFilter>;
  userType?: Maybe<InputFilter>;
  voiceRecordingIndicator?: Maybe<InputFilter>;
  workPhone?: Maybe<InputFilter>;
};

export type Inputskynet_SuggestWorkdayPeople = {
  business?: Maybe<InputFilter>;
  businessTitle?: Maybe<InputFilter>;
  businessUnit?: Maybe<InputFilter>;
  businessUnitDescription?: Maybe<InputFilter>;
  citadelVoiceRecordingIndicator?: Maybe<InputFilter>;
  company?: Maybe<InputFilter>;
  companyDescription?: Maybe<InputFilter>;
  contractEndDate?: Maybe<InputFilter>;
  department?: Maybe<Scalars["String"]>;
  departmentFunction?: Maybe<InputFilter>;
  departmentId?: Maybe<InputFilter>;
  emailAddress?: Maybe<InputFilter>;
  emailRestrictionGroup?: Maybe<InputFilter>;
  employeeId?: Maybe<Scalars["String"]>;
  employeeStatus?: Maybe<InputFilter>;
  employeeTypeDescription?: Maybe<InputFilter>;
  employeeTypeId?: Maybe<InputFilter>;
  entityTypeName?: Maybe<InputFilter>;
  fields?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  fullPartTime?: Maybe<InputFilter>;
  functionId?: Maybe<InputFilter>;
  headcountClass?: Maybe<InputFilter>;
  homeAddressEffectiveStatus?: Maybe<InputFilter>;
  homeAddressLine1?: Maybe<InputFilter>;
  homeAddressLine2?: Maybe<InputFilter>;
  homeAddressLine3?: Maybe<InputFilter>;
  homeAddressLine4?: Maybe<InputFilter>;
  homeCity?: Maybe<InputFilter>;
  homeCountry?: Maybe<InputFilter>;
  homePostalCode?: Maybe<InputFilter>;
  homeState?: Maybe<InputFilter>;
  horizonAccessGroup?: Maybe<InputFilter>;
  include?: Maybe<Scalars["String"]>;
  jobAction?: Maybe<InputFilter>;
  jobCodeDescription?: Maybe<InputFilter>;
  lastName?: Maybe<Scalars["String"]>;
  location?: Maybe<InputFilter>;
  locationDescription?: Maybe<InputFilter>;
  managerId?: Maybe<InputFilter>;
  middleName?: Maybe<InputFilter>;
  miisguid?: Maybe<InputFilter>;
  name?: Maybe<InputFilter>;
  networkStatus?: Maybe<InputFilter>;
  nonEmployeeHasBadge?: Maybe<InputFilter>;
  nonEmployeeHasNetworkAccess?: Maybe<InputFilter>;
  nonEmployeeSiteClassification?: Maybe<InputFilter>;
  nonEmployeeVendorName?: Maybe<InputFilter>;
  orderBy?: Maybe<Scalars["String"]>;
  orderByDesc?: Maybe<Scalars["String"]>;
  otherEmail?: Maybe<InputFilter>;
  personStatus?: Maybe<InputFilter>;
  personType?: Maybe<InputFilter>;
  preferredFirstName?: Maybe<Scalars["String"]>;
  preferredLastName?: Maybe<Scalars["String"]>;
  preferredMiddleName?: Maybe<InputFilter>;
  program?: Maybe<InputFilter>;
  programYear?: Maybe<InputFilter>;
  regularTemporary?: Maybe<InputFilter>;
  regulatoryRegion?: Maybe<InputFilter>;
  rehireDate?: Maybe<InputFilter>;
  rowCategory?: Maybe<InputFilter>;
  skip?: Maybe<Scalars["Int"]>;
  standardHours?: Maybe<InputFilter>;
  take?: Maybe<Scalars["Int"]>;
  tenure?: Maybe<InputFilter>;
  totalEmploymentTenureMonths?: Maybe<InputFilter>;
  userName?: Maybe<InputFilter>;
  workdayId?: Maybe<InputFilter>;
};

export type Inputskynet_SuppressSymphonyMessage = {
  id?: Maybe<Scalars["String"]>;
};

export type Inputskynet_UpdateBastionAccount = {
  accountName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  environment?: Maybe<Scalars["String"]>;
  manager?: Maybe<Scalars["String"]>;
  resources?: Maybe<Array<Maybe<Scalars["String"]>>>;
  users?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Inputskynet_UpdateCCureUser = {
  employeeKey?: Maybe<Scalars["String"]>;
  user?: Maybe<Inputskynet_CCureUser>;
};

export type Inputskynet_UpdateDfsFolderTarget = {
  enable?: Maybe<Scalars["Boolean"]>;
  folderName?: Maybe<Scalars["String"]>;
  root?: Maybe<Scalars["String"]>;
  targetPath?: Maybe<Scalars["String"]>;
};

export type Inputskynet_UpdateDfsRoot = {
  accessBasedEnumeration?: Maybe<Scalars["Boolean"]>;
  description?: Maybe<Scalars["String"]>;
  grantAdmins?: Maybe<Array<Maybe<Scalars["String"]>>>;
  insiteReferrals?: Maybe<Scalars["Boolean"]>;
  revokeAdmins?: Maybe<Array<Maybe<Scalars["String"]>>>;
  root?: Maybe<Scalars["String"]>;
};

export type Inputskynet_UpdateDfsRootFolder = {
  description?: Maybe<Scalars["String"]>;
  folderName?: Maybe<Scalars["String"]>;
  insiteReferrals?: Maybe<Scalars["Boolean"]>;
  root?: Maybe<Scalars["String"]>;
};

export type Inputskynet_UpdateDhcpReservationOption = {
  optionId?: Maybe<Scalars["Long"]>;
  reservedIp?: Maybe<Scalars["String"]>;
  scopeId?: Maybe<Scalars["String"]>;
  serverName?: Maybe<Scalars["String"]>;
  userClass?: Maybe<Scalars["String"]>;
  value?: Maybe<Array<Maybe<Scalars["String"]>>>;
  vendorClass?: Maybe<Scalars["String"]>;
};

export type Inputskynet_UpdateDhcpScope = {
  description?: Maybe<Scalars["String"]>;
  endRange?: Maybe<Scalars["String"]>;
  leaseDuration?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  scopeId?: Maybe<Scalars["String"]>;
  serverName?: Maybe<Scalars["String"]>;
  startRange?: Maybe<Scalars["String"]>;
  state?: Maybe<SubnetState>;
};

export type Inputskynet_UpdateDhcpScopeOption = {
  optionId?: Maybe<Scalars["Long"]>;
  scopeId?: Maybe<Scalars["String"]>;
  serverName?: Maybe<Scalars["String"]>;
  userClass?: Maybe<Scalars["String"]>;
  value?: Maybe<Array<Maybe<Scalars["String"]>>>;
  vendorClass?: Maybe<Scalars["String"]>;
};

export type Inputskynet_UpdateDhcpServerOption = {
  optionId?: Maybe<Scalars["Long"]>;
  serverName?: Maybe<Scalars["String"]>;
  userClass?: Maybe<Scalars["String"]>;
  value?: Maybe<Array<Maybe<Scalars["String"]>>>;
  vendorClass?: Maybe<Scalars["String"]>;
};

export type Inputskynet_UpdateLinuxAutoMount = {
  autoMount?: Maybe<Inputskynet_LinuxAutoMount>;
  automountName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_UpdateLinuxAutoMountMap = {
  map?: Maybe<Inputskynet_LinuxAutoMountMap>;
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_UpdateLinuxGroup = {
  description?: Maybe<Scalars["String"]>;
  manager?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_UpdateLinuxNetGroup = {
  description?: Maybe<Scalars["String"]>;
  manager?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Inputskynet_UpdateLinuxSudoRole = {
  command?: Maybe<Scalars["String"]>;
  host?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  user?: Maybe<Scalars["String"]>;
};

export type Inputskynet_UpdateLinuxUser = {
  delayGroupRemoval?: Maybe<Scalars["Boolean"]>;
  user?: Maybe<Inputskynet_LinuxUser>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_UpdateMacUser = {
  id?: Maybe<Scalars["Int"]>;
  user?: Maybe<Inputskynet_MacUser>;
};

export type Inputskynet_UpdateSnowflakeSecurityRequest = {
  processTime?: Maybe<InputGqlDate>;
  account?: Maybe<Scalars["String"]>;
  action?: Maybe<Scalars["String"]>;
  approver?: Maybe<Scalars["String"]>;
  approverEmail?: Maybe<Scalars["String"]>;
  authType?: Maybe<Scalars["String"]>;
  databaseList?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  loginName?: Maybe<Scalars["String"]>;
  loginType?: Maybe<Scalars["String"]>;
  modelAfterLoginName?: Maybe<Scalars["String"]>;
  originalRequestId?: Maybe<Scalars["Int"]>;
  requestId?: Maybe<Scalars["String"]>;
  requestor?: Maybe<Scalars["String"]>;
  requestorEmail?: Maybe<Scalars["String"]>;
  roleList?: Maybe<Scalars["String"]>;
  schemaList?: Maybe<Scalars["String"]>;
  sourceRequestId?: Maybe<Scalars["String"]>;
  sourceSystem?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Inputskynet_UpdateWindowsGroup = {
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  groupType?: Maybe<Scalars["String"]>;
  mailEnable?: Maybe<Scalars["Boolean"]>;
  managementAccount?: Maybe<Scalars["String"]>;
  manager?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
};

export type Inputskynet_UpdateWindowsManagedServiceAccount = {
  passwordAccess?: Maybe<Array<Maybe<Scalars["String"]>>>;
  principals?: Maybe<Array<Maybe<Scalars["String"]>>>;
  replacePrincipals?: Maybe<Scalars["Boolean"]>;
  unconstrainedDelegation?: Maybe<Scalars["Boolean"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_UpdateWindowsUser = {
  createMailbox?: Maybe<Scalars["Boolean"]>;
  delayGroupRemoval?: Maybe<Scalars["Boolean"]>;
  restrictedUser?: Maybe<Scalars["Boolean"]>;
  user?: Maybe<Inputskynet_WindowsUser>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_UpdateWindowsUserRestrictedHosts = {
  hostsToAdd?: Maybe<Array<Maybe<Scalars["String"]>>>;
  hostsToRemove?: Maybe<Array<Maybe<Scalars["String"]>>>;
  userName?: Maybe<Scalars["String"]>;
};

export type Inputskynet_WindowsUser = {
  creationDate?: Maybe<InputGqlDate>;
  lastDateWorked?: Maybe<InputGqlDate>;
  lastLogon?: Maybe<InputGqlDate>;
  lastUpdated?: Maybe<InputGqlDate>;
  passwordLastSet?: Maybe<InputGqlDate>;
  adjustedTenure?: Maybe<Scalars["Int"]>;
  badgeId?: Maybe<Scalars["String"]>;
  company?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  department?: Maybe<Scalars["String"]>;
  departmentDescription?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  disabled?: Maybe<Scalars["Boolean"]>;
  displayName?: Maybe<Scalars["String"]>;
  distinguishedName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  emailRestrictionGroup?: Maybe<Scalars["String"]>;
  employeeId?: Maybe<Scalars["String"]>;
  employeeType?: Maybe<Scalars["String"]>;
  entityName?: Maybe<Scalars["String"]>;
  faxNumber?: Maybe<Scalars["String"]>;
  firewallGroup?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  hideFromAddressLists?: Maybe<Scalars["Boolean"]>;
  homePhone?: Maybe<Scalars["String"]>;
  horizonAccessGroup?: Maybe<Scalars["String"]>;
  hrBusinessUnit?: Maybe<Scalars["String"]>;
  initials?: Maybe<Scalars["String"]>;
  intern?: Maybe<Scalars["Boolean"]>;
  ipPhone?: Maybe<Scalars["String"]>;
  jobCode?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  lockedOut?: Maybe<Scalars["Boolean"]>;
  logonRestricted?: Maybe<Scalars["Boolean"]>;
  manager?: Maybe<Scalars["String"]>;
  managerEmployeeId?: Maybe<Scalars["String"]>;
  managerUserName?: Maybe<Scalars["String"]>;
  middleName?: Maybe<Scalars["String"]>;
  mobilePhone?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  objectGuid?: Maybe<Scalars["String"]>;
  officeLocation?: Maybe<Scalars["String"]>;
  owner?: Maybe<Scalars["String"]>;
  personalMobile?: Maybe<Scalars["String"]>;
  proxyAddresses?: Maybe<Array<Maybe<Scalars["String"]>>>;
  sid?: Maybe<Scalars["String"]>;
  sipAddress?: Maybe<Scalars["String"]>;
  userAccountControl?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
  userPrincipalName?: Maybe<Scalars["String"]>;
  userProfilePath?: Maybe<Scalars["String"]>;
  userType?: Maybe<Scalars["String"]>;
  voiceRecordingIndicator?: Maybe<Scalars["String"]>;
  workPhone?: Maybe<Scalars["String"]>;
};

export type JobQueryResponse = {
  __typename?: "JobQueryResponse";
  jobs?: Maybe<Array<Maybe<Skynet_Job>>>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export enum LeaseDnsRr {
  /** A */
  A = "A",
  /** Ptr */
  Ptr = "Ptr",
  /** AandPTR */
  AandPtr = "AandPTR",
  /** NoRegistration */
  NoRegistration = "NoRegistration",
}

export enum LeaseDnsRegistration {
  /** Complete */
  Complete = "Complete",
  /** Pending */
  Pending = "Pending",
  /** NotApplicable */
  NotApplicable = "NotApplicable",
}

export enum LeaseState {
  /** Offered */
  Offered = "Offered",
  /** Active */
  Active = "Active",
  /** Declined */
  Declined = "Declined",
  /** Expired */
  Expired = "Expired",
  /** ActiveReservation */
  ActiveReservation = "ActiveReservation",
  /** InactiveReservation */
  InactiveReservation = "InactiveReservation",
}

export type LinuxGroupQueryResponse = {
  __typename?: "LinuxGroupQueryResponse";
  linuxGroups?: Maybe<Array<Maybe<Skynet_LinuxGroup>>>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type LinuxNetgroupQueryResponse = {
  __typename?: "LinuxNetgroupQueryResponse";
  linuxNetgroups?: Maybe<Array<Maybe<Skynet_LinuxNetgroup>>>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type LinuxSudoRoleQueryResponse = {
  __typename?: "LinuxSudoRoleQueryResponse";
  linuxSudoRoles?: Maybe<Array<Maybe<Skynet_LinuxSudoRole>>>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type LinuxUserQueryResponse = {
  __typename?: "LinuxUserQueryResponse";
  linuxUsers?: Maybe<Array<Maybe<Skynet_LinuxUser>>>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export enum LogonTypeValue {
  /** Local */
  Local = "Local",
  /** Remote */
  Remote = "Remote",
  /** Runas */
  Runas = "Runas",
}

export enum MemberType {
  /** User */
  User = "User",
  /** Computer */
  Computer = "Computer",
  /** Group */
  Group = "Group",
  /** Contact */
  Contact = "Contact",
}

export type MobileDeviceQueryResponse = {
  __typename?: "MobileDeviceQueryResponse";
  mobileDevices?: Maybe<Array<Maybe<Skynet_MobileDevice>>>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type MobileUserQueryResponse = {
  __typename?: "MobileUserQueryResponse";
  mobileUsers?: Maybe<Array<Maybe<Skynet_MobileUser>>>;
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export enum NetgroupType {
  /** Host */
  Host = "Host",
  /** User */
  User = "User",
}

export enum NotifyValues {
  /** NoNotify */
  NoNotify = "NoNotify",
  /** Notify */
  Notify = "Notify",
  /** NotifyServers */
  NotifyServers = "NotifyServers",
}

export type OktaUserQueryResponse = {
  __typename?: "OktaUserQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  oktaUsers?: Maybe<Array<Maybe<Skynet_OktaUser>>>;
  total?: Maybe<Scalars["Int"]>;
};

export enum OperationalStatusEnum {
  /** PreProcurement */
  PreProcurement = "PreProcurement",
  /** Procurement */
  Procurement = "Procurement",
  /** Operational */
  Operational = "Operational",
  /** Recycling */
  Recycling = "Recycling",
  /** Decommissioning */
  Decommissioning = "Decommissioning",
  /** Available */
  Available = "Available",
  /** Moving */
  Moving = "Moving",
  /** Decommissioned */
  Decommissioned = "Decommissioned",
  /** Cancelled */
  Cancelled = "Cancelled",
}

export enum OptionDataTypes {
  /** Byte */
  Byte = "Byte",
  /** Word */
  Word = "Word",
  /** DWord */
  DWord = "DWord",
  /** DwordDword */
  DwordDword = "DwordDword",
  /** Ipv4Address */
  Ipv4Address = "Ipv4Address",
  /** String */
  String = "String",
  /** BinaryData */
  BinaryData = "BinaryData",
  /** EncapsulatedData */
  EncapsulatedData = "EncapsulatedData",
}

export type OrgMapQueryResponse = {
  __typename?: "OrgMapQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  orgMaps?: Maybe<Array<Maybe<Skynet_OrgMap>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type PagerDutyIncidentQueryResponse = {
  __typename?: "PagerDutyIncidentQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  pagerDutyIncidents?: Maybe<Array<Maybe<Skynet_PagerDutyIncident>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type PagerDutyServiceQueryResponse = {
  __typename?: "PagerDutyServiceQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  pagerDutyServices?: Maybe<Array<Maybe<Skynet_PagerDutyService>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type PagerDutyTeamQueryResponse = {
  __typename?: "PagerDutyTeamQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  pagerDutyTeams?: Maybe<Array<Maybe<Skynet_PagerDutyTeam>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type PagerDutyUserQueryResponse = {
  __typename?: "PagerDutyUserQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  pagerDutyUsers?: Maybe<Array<Maybe<Skynet_PagerDutyUser>>>;
  total?: Maybe<Scalars["Int"]>;
};

export enum PowerStateEnum {
  /** Off */
  Off = "Off",
  /** On */
  On = "On",
}

export enum RecordClassValue {
  /** In */
  In = "In",
  /** Csnet */
  Csnet = "Csnet",
  /** Chaos */
  Chaos = "Chaos",
  /** Hesiod */
  Hesiod = "Hesiod",
}

export enum RecordStatusEnum {
  /** Planned */
  Planned = "Planned",
  /** Active */
  Active = "Active",
  /** Recycled */
  Recycled = "Recycled",
  /** Decommissioned */
  Decommissioned = "Decommissioned",
  /** Cancelled */
  Cancelled = "Cancelled",
}

export enum RecordTypeValue {
  /** A */
  A = "A",
  /** Ns */
  Ns = "Ns",
  /** Md */
  Md = "Md",
  /** Mf */
  Mf = "Mf",
  /** Cname */
  Cname = "Cname",
  /** Soa */
  Soa = "Soa",
  /** Mb */
  Mb = "Mb",
  /** Mg */
  Mg = "Mg",
  /** Mr */
  Mr = "Mr",
  /** Null */
  Null = "Null",
  /** Wks */
  Wks = "Wks",
  /** Ptr */
  Ptr = "Ptr",
  /** Hinfo */
  Hinfo = "Hinfo",
  /** Minfo */
  Minfo = "Minfo",
  /** Mx */
  Mx = "Mx",
  /** Txt */
  Txt = "Txt",
  /** Rp */
  Rp = "Rp",
  /** Afsdb */
  Afsdb = "Afsdb",
  /** X25 */
  X25 = "X25",
  /** Isdn */
  Isdn = "Isdn",
  /** Rt */
  Rt = "Rt",
  /** Nsap */
  Nsap = "Nsap",
  /** Nsapptr */
  Nsapptr = "Nsapptr",
  /** Sig */
  Sig = "Sig",
  /** Key */
  Key = "Key",
  /** Px */
  Px = "Px",
  /** Gpos */
  Gpos = "Gpos",
  /** Aaaa */
  Aaaa = "Aaaa",
  /** Loc */
  Loc = "Loc",
  /** Nxt */
  Nxt = "Nxt",
  /** Eid */
  Eid = "Eid",
  /** Nimloc */
  Nimloc = "Nimloc",
  /** Srv */
  Srv = "Srv",
  /** Atma */
  Atma = "Atma",
  /** Naptr */
  Naptr = "Naptr",
  /** Kx */
  Kx = "Kx",
  /** Cert */
  Cert = "Cert",
  /** A6 */
  A6 = "A6",
  /** Dname */
  Dname = "Dname",
  /** Sink */
  Sink = "Sink",
  /** Opt */
  Opt = "Opt",
  /** Ds */
  Ds = "Ds",
  /** Rrsig */
  Rrsig = "Rrsig",
  /** Nsec */
  Nsec = "Nsec",
  /** Dnskey */
  Dnskey = "Dnskey",
  /** Dhcid */
  Dhcid = "Dhcid",
  /** Wins */
  Wins = "Wins",
  /** Winsr */
  Winsr = "Winsr",
  /** Tlsa */
  Tlsa = "Tlsa",
  /** Unknown */
  Unknown = "Unknown",
}

export enum ReplicationScopeValues {
  /** Forest */
  Forest = "Forest",
  /** Domain */
  Domain = "Domain",
  /** Legacy */
  Legacy = "Legacy",
  /** Custom */
  Custom = "Custom",
}

export enum ReservationState {
  /** ActiveReservation */
  ActiveReservation = "ActiveReservation",
  /** InactiveReservation */
  InactiveReservation = "InactiveReservation",
}

export type SccmApplicationQueryResponse = {
  __typename?: "SccmApplicationQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  sccmApplications?: Maybe<Array<Maybe<Skynet_SccmApplication>>>;
  total?: Maybe<Scalars["Int"]>;
};

export enum SccmCollectionMemberAmtStatus {
  /** NotSupported */
  NotSupported = "NotSupported",
  /** Detected */
  Detected = "Detected",
  /** NotProvisioned */
  NotProvisioned = "NotProvisioned",
  /** Provisioned */
  Provisioned = "Provisioned",
}

export enum SccmCollectionMemberClientType {
  /** Client */
  Client = "Client",
  /** Device */
  Device = "Device",
}

export enum SccmCollectionMemberResourceType {
  /** UserGroup */
  UserGroup = "UserGroup",
  /** User */
  User = "User",
  /** System */
  System = "System",
  /** IpNetwork */
  IpNetwork = "IpNetwork",
}

export type SccmCollectionQueryResponse = {
  __typename?: "SccmCollectionQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  sccmCollections?: Maybe<Array<Maybe<Skynet_SccmCollection>>>;
  total?: Maybe<Scalars["Int"]>;
};

export enum SccmCollectionRefreshType {
  /** Manual */
  Manual = "Manual",
  /** Periodic */
  Periodic = "Periodic",
  /** ConstantUpdate */
  ConstantUpdate = "ConstantUpdate",
  /** ConstantUpdateAndPeriodic */
  ConstantUpdateAndPeriodic = "ConstantUpdateAndPeriodic",
}

export enum SccmCollectionStatus {
  /** None */
  None = "None",
  /** Ready */
  Ready = "Ready",
  /** Refreshing */
  Refreshing = "Refreshing",
  /** Saving */
  Saving = "Saving",
  /** Evaluating */
  Evaluating = "Evaluating",
  /** AwaitingRefresh */
  AwaitingRefresh = "AwaitingRefresh",
  /** Deleting */
  Deleting = "Deleting",
  /** AppendingMember */
  AppendingMember = "AppendingMember",
  /** Querying */
  Querying = "Querying",
}

export enum SccmCollectionType {
  /** Other */
  Other = "Other",
  /** User */
  User = "User",
  /** Device */
  Device = "Device",
}

export type SccmLocalGroupReportQueryResponse = {
  __typename?: "SccmLocalGroupReportQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  sccmLocalGroupReports?: Maybe<Array<Maybe<Skynet_SccmLocalGroupReport>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type SccmSoftwareQueryResponse = {
  __typename?: "SccmSoftwareQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  sccmSoftwares?: Maybe<Array<Maybe<Skynet_SccmSoftware>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type SccmSystemQueryResponse = {
  __typename?: "SccmSystemQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  sccmSystems?: Maybe<Array<Maybe<Skynet_SccmSystem>>>;
  total?: Maybe<Scalars["Int"]>;
};

export enum SecureSecondariesValues {
  /** NoTransfer */
  NoTransfer = "NoTransfer",
  /** TransferAnyServer */
  TransferAnyServer = "TransferAnyServer",
  /** TransferToZoneNameServer */
  TransferToZoneNameServer = "TransferToZoneNameServer",
  /** TransferToSecureServers */
  TransferToSecureServers = "TransferToSecureServers",
}

export type ServerNameQueryResponse = {
  __typename?: "ServerNameQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  serverNames?: Maybe<Array<Maybe<Skynet_ServerName>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type ServiceNowUserQueryResponse = {
  __typename?: "ServiceNowUserQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  serviceNowUsers?: Maybe<Array<Maybe<Skynet_ServiceNowUser>>>;
  total?: Maybe<Scalars["Int"]>;
};

export enum ServiceStartModeValue {
  /** Automatic */
  Automatic = "Automatic",
  /** Auto */
  Auto = "Auto",
  /** Boot */
  Boot = "Boot",
  /** Disabled */
  Disabled = "Disabled",
  /** Manual */
  Manual = "Manual",
  /** System */
  System = "System",
  /** Unknown */
  Unknown = "Unknown",
}

export enum ServiceStatusValue {
  /** Stopped */
  Stopped = "Stopped",
  /** StartPending */
  StartPending = "StartPending",
  /** StopPending */
  StopPending = "StopPending",
  /** Running */
  Running = "Running",
  /** ContinuePending */
  ContinuePending = "ContinuePending",
  /** PausedPending */
  PausedPending = "PausedPending",
  /** Paused */
  Paused = "Paused",
  /** Unknown */
  Unknown = "Unknown",
}

export type SlackChannelQueryResponse = {
  __typename?: "SlackChannelQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  slackChannels?: Maybe<Array<Maybe<Skynet_SlackChannel>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type SlackUserQueryResponse = {
  __typename?: "SlackUserQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  slackUsers?: Maybe<Array<Maybe<Skynet_SlackUser>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type SlackWorkspaceQueryResponse = {
  __typename?: "SlackWorkspaceQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  slackWorkspaces?: Maybe<Array<Maybe<Skynet_SlackWorkspace>>>;
  total?: Maybe<Scalars["Int"]>;
};

export enum SqlLoginType {
  /** WindowsUser */
  WindowsUser = "WindowsUser",
  /** WindowsGroup */
  WindowsGroup = "WindowsGroup",
  /** SqlLogin */
  SqlLogin = "SqlLogin",
  /** Certificate */
  Certificate = "Certificate",
  /** AsymmetricKey */
  AsymmetricKey = "AsymmetricKey",
  /** ExternalUser */
  ExternalUser = "ExternalUser",
  /** ExternalGroup */
  ExternalGroup = "ExternalGroup",
}

export enum SubnetState {
  /** Active */
  Active = "Active",
  /** Inactive */
  Inactive = "Inactive",
}

export enum TerminalServiceDrainMode {
  /** AllowAll */
  AllowAll = "AllowAll",
  /** Drain */
  Drain = "Drain",
  /** ReconnectOnly */
  ReconnectOnly = "ReconnectOnly",
}

export enum TerminalServiceLicensingType {
  /** Personal */
  Personal = "Personal",
  /** AdminRemoteDesktop */
  AdminRemoteDesktop = "AdminRemoteDesktop",
  /** PerDevice */
  PerDevice = "PerDevice",
  /** PerUser */
  PerUser = "PerUser",
  /** NotConfigured */
  NotConfigured = "NotConfigured",
}

export enum TerminalServicePolicySource {
  /** Server */
  Server = "Server",
  /** GroupPolicy */
  GroupPolicy = "GroupPolicy",
}

export type UcsIpAddressPoolQueryResponse = {
  __typename?: "UcsIpAddressPoolQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
  ucsIpAddressPools?: Maybe<Array<Maybe<Skynet_UcsIpAddressPool>>>;
};

export type WindowsComputerQueryResponse = {
  __typename?: "WindowsComputerQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
  windowsComputers?: Maybe<Array<Maybe<Skynet_WindowsComputer>>>;
};

export type WindowsDynamicGroupQueryResponse = {
  __typename?: "WindowsDynamicGroupQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
  windowsDynamicGroups?: Maybe<Array<Maybe<Skynet_WindowsDynamicGroup>>>;
};

export type WindowsGroupQueryResponse = {
  __typename?: "WindowsGroupQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
  windowsGroups?: Maybe<Array<Maybe<Skynet_WindowsGroup>>>;
};

export type WindowsUserQueryResponse = {
  __typename?: "WindowsUserQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
  windowsUsers?: Maybe<Array<Maybe<Skynet_WindowsUser>>>;
};

export type WorkdayDepartmentQueryResponse = {
  __typename?: "WorkdayDepartmentQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
  workdayDepartments?: Maybe<Array<Maybe<Skynet_WorkdayDepartment>>>;
};

export type WorkdayLocationQueryResponse = {
  __typename?: "WorkdayLocationQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
  workdayLocations?: Maybe<Array<Maybe<Skynet_WorkdayLocation>>>;
};

export type WorkdayPersonQueryResponse = {
  __typename?: "WorkdayPersonQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
  workdayPersons?: Maybe<Array<Maybe<Skynet_WorkdayPerson>>>;
};

export type WorkdayQuarterlyRoleDescriptionQueryResponse = {
  __typename?: "WorkdayQuarterlyRoleDescriptionQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
  workdayQuarterlyRoleDescriptions?: Maybe<Array<Maybe<Skynet_WorkdayQuarterlyRoleDescription>>>;
};

export enum ZabbixAutoLogin {
  /** Disabled */
  Disabled = "Disabled",
  /** Enabled */
  Enabled = "Enabled",
}

export enum ZabbixGroupGuiAccess {
  /** Default */
  Default = "Default",
  /** Internal */
  Internal = "Internal",
  /** Disabled */
  Disabled = "Disabled",
}

export enum ZabbixGroupStatus {
  /** Enabled */
  Enabled = "Enabled",
  /** Disabled */
  Disabled = "Disabled",
}

export enum ZabbixHostAvailability {
  /** Unknown */
  Unknown = "Unknown",
  /** Available */
  Available = "Available",
  /** Unavailable */
  Unavailable = "Unavailable",
}

export enum ZabbixHostGroupFlags {
  /** Default */
  Default = "Default",
  /** Discovered */
  Discovered = "Discovered",
}

export enum ZabbixHostGroupInternal {
  /** Default */
  Default = "Default",
  /** Internal */
  Internal = "Internal",
}

export type ZabbixHostGroupQueryResponse = {
  __typename?: "ZabbixHostGroupQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
  zabbixHostGroups?: Maybe<Array<Maybe<Skynet_ZabbixHostGroup>>>;
};

export enum ZabbixHostMaintenanceStatus {
  /** NoMaintenance */
  NoMaintenance = "NoMaintenance",
  /** Maintenance */
  Maintenance = "Maintenance",
}

export type ZabbixHostQueryResponse = {
  __typename?: "ZabbixHostQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
  zabbixHosts?: Maybe<Array<Maybe<Skynet_ZabbixHost>>>;
};

export enum ZabbixHostStatus {
  /** Monitored */
  Monitored = "Monitored",
  /** Unmonitored */
  Unmonitored = "Unmonitored",
}

export enum ZabbixTriggerCorrelationMode {
  /** All */
  All = "All",
  /** IfMatch */
  IfMatch = "IfMatch",
}

export enum ZabbixTriggerFlags {
  /** Default */
  Default = "Default",
  /** Discovered */
  Discovered = "Discovered",
}

export enum ZabbixTriggerManualClose {
  /** No */
  No = "No",
  /** Yes */
  Yes = "Yes",
}

export enum ZabbixTriggerPriority {
  /** Default */
  Default = "Default",
  /** Information */
  Information = "Information",
  /** Warning */
  Warning = "Warning",
  /** Average */
  Average = "Average",
  /** High */
  High = "High",
  /** Disaster */
  Disaster = "Disaster",
}

export type ZabbixTriggerQueryResponse = {
  __typename?: "ZabbixTriggerQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
  zabbixTriggers?: Maybe<Array<Maybe<Skynet_ZabbixTrigger>>>;
};

export enum ZabbixTriggerRecoveryMode {
  /** Expression */
  Expression = "Expression",
  /** RecoveryExpression */
  RecoveryExpression = "RecoveryExpression",
  /** None */
  None = "None",
}

export enum ZabbixTriggerState {
  /** UpToDate */
  UpToDate = "UpToDate",
  /** Unknown */
  Unknown = "Unknown",
}

export enum ZabbixTriggerStatus {
  /** Enabled */
  Enabled = "Enabled",
  /** Disabled */
  Disabled = "Disabled",
}

export enum ZabbixTriggerType {
  /** NoMultipleEvents */
  NoMultipleEvents = "NoMultipleEvents",
  /** MultipleEvents */
  MultipleEvents = "MultipleEvents",
}

export enum ZabbixTriggerValue {
  /** Ok */
  Ok = "Ok",
  /** Problem */
  Problem = "Problem",
}

export type ZabbixUserQueryResponse = {
  __typename?: "ZabbixUserQueryResponse";
  offset?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
  zabbixUsers?: Maybe<Array<Maybe<Skynet_ZabbixUser>>>;
};

export enum ZabbixUserType {
  /** Default */
  Default = "Default",
  /** Admin */
  Admin = "Admin",
  /** SuperAdmin */
  SuperAdmin = "SuperAdmin",
}

export enum ZoneTypeValues {
  /** Cache */
  Cache = "Cache",
  /** Primary */
  Primary = "Primary",
  /** Secondary */
  Secondary = "Secondary",
  /** Stub */
  Stub = "Stub",
  /** Forwarder */
  Forwarder = "Forwarder",
}

export type Skynet_Account = {
  __typename?: "skynet_Account";
  accountName?: Maybe<Scalars["String"]>;
  accountType?: Maybe<Scalars["String"]>;
  accountTypeDescription?: Maybe<Scalars["String"]>;
  altAccountName?: Maybe<Scalars["String"]>;
  audit?: Maybe<Scalars["String"]>;
  auditDescription?: Maybe<Scalars["String"]>;
  cooCategory?: Maybe<Scalars["String"]>;
  cooCategoryDescription?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  effectiveDate?: Maybe<Scalars["String"]>;
  effectiveStatus?: Maybe<Scalars["String"]>;
  fpna?: Maybe<Scalars["String"]>;
  fpnaDescription?: Maybe<Scalars["String"]>;
  investor?: Maybe<Scalars["String"]>;
  investorDescription?: Maybe<Scalars["String"]>;
  setId?: Maybe<Scalars["String"]>;
  shortDescription?: Maybe<Scalars["String"]>;
  statisticsAccount?: Maybe<Scalars["String"]>;
};

export type Skynet_ActiveDirectoryComputer = {
  __typename?: "skynet_ActiveDirectoryComputer";
  creationDate?: Maybe<GqlDate>;
  lastRebootTime?: Maybe<GqlDate>;
  passwordLastSet?: Maybe<GqlDate>;
  skipBitDate?: Maybe<GqlDate>;
  accountName?: Maybe<Scalars["String"]>;
  assetInformation?: Maybe<Skynet_SurveyData>;
  costCode?: Maybe<Scalars["String"]>;
  dayOfWeek?: Maybe<Scalars["Short"]>;
  distinguishedName?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  objectGuid?: Maybe<Scalars["String"]>;
  operatingSystem?: Maybe<Scalars["String"]>;
  operatingSystemVersion?: Maybe<Scalars["String"]>;
  principals?: Maybe<Array<Maybe<Scalars["String"]>>>;
  rebootDaily?: Maybe<Scalars["Boolean"]>;
  rebootEnabled?: Maybe<Scalars["Boolean"]>;
  rebootSkipBit?: Maybe<Scalars["Boolean"]>;
  reportingUnit?: Maybe<Scalars["String"]>;
  userAccountControl?: Maybe<Scalars["String"]>;
  weekOfMonth?: Maybe<Scalars["Short"]>;
  winRmManaged?: Maybe<Scalars["Boolean"]>;
};

export type Skynet_AdHocTicketResponse = {
  __typename?: "skynet_AdHocTicketResponse";
  approver?: Maybe<Scalars["String"]>;
  approverType?: Maybe<Scalars["String"]>;
  assignmentGroup?: Maybe<Scalars["String"]>;
  correlationId?: Maybe<Scalars["String"]>;
  details?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
  rawResponse?: Maybe<Skynet_ServiceNowAdHocTicketResponse>;
  requestedBy?: Maybe<Scalars["String"]>;
  requestedFor?: Maybe<Scalars["String"]>;
  secondaryApprover?: Maybe<Scalars["String"]>;
  secondaryApproverType?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type Skynet_AmLinuxComputer = {
  __typename?: "skynet_AmLinuxComputer";
  name?: Maybe<Scalars["String"]>;
};

export type Skynet_Asset = {
  __typename?: "skynet_Asset";
  address?: Maybe<Scalars["String"]>;
  assetId?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  computerName?: Maybe<Scalars["String"]>;
  costCode?: Maybe<Scalars["String"]>;
  employeeId?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  hardwareType?: Maybe<Scalars["String"]>;
  itemStatus?: Maybe<AssetItemStatus>;
  lastName?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  locationFullName?: Maybe<Scalars["String"]>;
  operatingSystem?: Maybe<Scalars["String"]>;
  reportingUnit?: Maybe<Scalars["String"]>;
  serialNo?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Skynet_AssetLocation = {
  __typename?: "skynet_AssetLocation";
  auditDate?: Maybe<GqlDate>;
  creationDate?: Maybe<GqlDate>;
  operationalDate?: Maybe<GqlDate>;
  purchaseDate?: Maybe<GqlDate>;
  assetID?: Maybe<Scalars["Int"]>;
  assetMaterial?: Maybe<Scalars["String"]>;
  assetMaterialSubType?: Maybe<Scalars["String"]>;
  assetMaterialType?: Maybe<Scalars["String"]>;
  assetName?: Maybe<Scalars["String"]>;
  assetNumber?: Maybe<Scalars["Int"]>;
  assetOperationalStatusID?: Maybe<Scalars["Int"]>;
  assetSerialNumber?: Maybe<Scalars["String"]>;
  assetvueLocation?: Maybe<Scalars["String"]>;
  assetvueRoom?: Maybe<Scalars["String"]>;
  auditStatusCorrect?: Maybe<Scalars["Boolean"]>;
  building?: Maybe<Scalars["String"]>;
  cabinetID?: Maybe<Scalars["Int"]>;
  cabinetName?: Maybe<Scalars["String"]>;
  cabinetSerialNumber?: Maybe<Scalars["String"]>;
  chassisID?: Maybe<Scalars["Int"]>;
  chassisName?: Maybe<Scalars["String"]>;
  chassisPosition?: Maybe<Scalars["String"]>;
  chassisSerialNumber?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  costCenter?: Maybe<Scalars["String"]>;
  floor?: Maybe<Scalars["String"]>;
  lastScanned?: Maybe<Scalars["String"]>;
  locationGroup?: Maybe<Scalars["String"]>;
  locationGroupID?: Maybe<Scalars["Int"]>;
  locationTag?: Maybe<Scalars["String"]>;
  locationfullname?: Maybe<Scalars["String"]>;
  materialID?: Maybe<Scalars["Int"]>;
  nlytePosition?: Maybe<Scalars["String"]>;
  operationalstatus?: Maybe<Scalars["String"]>;
  orientation?: Maybe<Scalars["String"]>;
  placement?: Maybe<Scalars["String"]>;
  placementID?: Maybe<Scalars["Int"]>;
  poNumber?: Maybe<Scalars["String"]>;
  prNumber?: Maybe<Scalars["String"]>;
  province?: Maybe<Scalars["String"]>;
  purchasePrice?: Maybe<Scalars["BigDecimal"]>;
  recordStatus?: Maybe<Scalars["Short"]>;
  recordStatusDescription?: Maybe<Scalars["String"]>;
  rfidTag?: Maybe<Scalars["String"]>;
  room?: Maybe<Scalars["String"]>;
  rotation?: Maybe<Scalars["String"]>;
  ruid?: Maybe<Scalars["Int"]>;
  sapAssetNumber?: Maybe<Scalars["String"]>;
  slotID?: Maybe<Scalars["Int"]>;
  tag?: Maybe<Scalars["String"]>;
  uniqueAssetName?: Maybe<Scalars["String"]>;
  zone?: Maybe<Scalars["String"]>;
};

export type Skynet_BastionAccount = {
  __typename?: "skynet_BastionAccount";
  accountLogin?: Maybe<Scalars["String"]>;
  accountName?: Maybe<Scalars["String"]>;
  application?: Maybe<Scalars["String"]>;
  autoChangePassword?: Maybe<Scalars["Boolean"]>;
  autoChangeSshKey?: Maybe<Scalars["Boolean"]>;
  certificateValidity?: Maybe<Scalars["String"]>;
  checkoutPolicy?: Maybe<Scalars["String"]>;
  credentials?: Maybe<Array<Maybe<Skynet_BastionAccountCredentials>>>;
  description?: Maybe<Scalars["String"]>;
  device?: Maybe<Scalars["String"]>;
  domain?: Maybe<Scalars["String"]>;
  manager?: Maybe<Scalars["String"]>;
  resources?: Maybe<Array<Maybe<Scalars["String"]>>>;
  services?: Maybe<Array<Maybe<Scalars["String"]>>>;
  userGroup?: Maybe<Scalars["String"]>;
  users?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Skynet_BastionAccountCredentials = {
  __typename?: "skynet_BastionAccountCredentials";
  passphrase?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  privateKey?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Skynet_BastionAuthorization = {
  __typename?: "skynet_BastionAuthorization";
  activeQuorum?: Maybe<Scalars["Int"]>;
  approvalRequired?: Maybe<Scalars["Boolean"]>;
  approvers?: Maybe<Array<Maybe<Scalars["String"]>>>;
  authorizationName?: Maybe<Scalars["String"]>;
  authorizePasswordRetrieval?: Maybe<Scalars["Boolean"]>;
  authorizeSessions?: Maybe<Scalars["Boolean"]>;
  description?: Maybe<Scalars["String"]>;
  hasComment?: Maybe<Scalars["Boolean"]>;
  inactiveQuorum?: Maybe<Scalars["Int"]>;
  isCritical?: Maybe<Scalars["Boolean"]>;
  isRecorded?: Maybe<Scalars["Boolean"]>;
  subprotocols?: Maybe<Array<Maybe<Scalars["String"]>>>;
  targetGroup?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  userGroup?: Maybe<Scalars["String"]>;
};

export type Skynet_BastionDevice = {
  __typename?: "skynet_BastionDevice";
  alias?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  deviceName?: Maybe<Scalars["String"]>;
  host?: Maybe<Scalars["String"]>;
  localDomains?: Maybe<Array<Maybe<Skynet_BastionDomain>>>;
  services?: Maybe<Array<Maybe<Skynet_BastionService>>>;
  url?: Maybe<Scalars["String"]>;
};

export type Skynet_BastionDomain = {
  __typename?: "skynet_BastionDomain";
  adminAccount?: Maybe<Scalars["String"]>;
  caPrivateKey?: Maybe<Scalars["String"]>;
  caPublicKey?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  domainName?: Maybe<Scalars["String"]>;
  enablePasswordChange?: Maybe<Scalars["Boolean"]>;
  passwordChangePlugin?: Maybe<Scalars["String"]>;
  passwordChangePluginParameters?: Maybe<Scalars["String"]>;
  passwordChangePolicy?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Skynet_BastionLdapMapping = {
  __typename?: "skynet_BastionLdapMapping";
  domain?: Maybe<Scalars["String"]>;
  isFallback?: Maybe<Scalars["Boolean"]>;
  ldapGroup?: Maybe<Scalars["String"]>;
  userGroup?: Maybe<Scalars["String"]>;
};

export type Skynet_BastionService = {
  __typename?: "skynet_BastionService";
  connectionPolicy?: Maybe<Scalars["String"]>;
  globalDomains?: Maybe<Array<Maybe<Scalars["String"]>>>;
  port?: Maybe<Scalars["Int"]>;
  protocol?: Maybe<Scalars["String"]>;
  serviceName?: Maybe<Scalars["String"]>;
  subprotocols?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Skynet_BastionTargetGroup = {
  __typename?: "skynet_BastionTargetGroup";
  description?: Maybe<Scalars["String"]>;
  groupName?: Maybe<Scalars["String"]>;
  passwordRetrieval?: Maybe<Array<Maybe<Skynet_BastionTargetGroupPasswordRetrieval>>>;
  restrictions?: Maybe<Array<Maybe<Scalars["String"]>>>;
  session?: Maybe<Skynet_BastionTargetGroupSession>;
  url?: Maybe<Scalars["String"]>;
};

export type Skynet_BastionTargetGroupPasswordRetrieval = {
  __typename?: "skynet_BastionTargetGroupPasswordRetrieval";
  accounts?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Skynet_BastionTargetGroupSession = {
  __typename?: "skynet_BastionTargetGroupSession";
  accountMappings?: Maybe<Array<Maybe<Scalars["String"]>>>;
  accounts?: Maybe<Array<Maybe<Scalars["String"]>>>;
  interactiveLogins?: Maybe<Array<Maybe<Scalars["String"]>>>;
  scenarioAccounts?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Skynet_BastionUserGroup = {
  __typename?: "skynet_BastionUserGroup";
  description?: Maybe<Scalars["String"]>;
  groupName?: Maybe<Scalars["String"]>;
  profile?: Maybe<Scalars["String"]>;
  restrictions?: Maybe<Array<Maybe<Skynet_BastionUserGroupRestriction>>>;
  timeframes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  url?: Maybe<Scalars["String"]>;
  users?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Skynet_BastionUserGroupRestriction = {
  __typename?: "skynet_BastionUserGroupRestriction";
  action?: Maybe<Scalars["String"]>;
  rules?: Maybe<Scalars["String"]>;
  subprotocol?: Maybe<Scalars["String"]>;
};

export type Skynet_BusinessResourceGroup = {
  __typename?: "skynet_BusinessResourceGroup";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  storageCluster?: Maybe<Scalars["String"]>;
  subnet?: Maybe<Scalars["String"]>;
  vlanId?: Maybe<Scalars["String"]>;
  vlanName?: Maybe<Scalars["String"]>;
};

export type Skynet_CCureUser = {
  __typename?: "skynet_CCureUser";
  timeStamp?: Maybe<GqlDate>;
  birthDate?: Maybe<Scalars["String"]>;
  cellPhone?: Maybe<Scalars["String"]>;
  department?: Maybe<Scalars["String"]>;
  disabled?: Maybe<Scalars["Int"]>;
  displayName?: Maybe<Scalars["String"]>;
  employeeId?: Maybe<Scalars["String"]>;
  employeeKey?: Maybe<Scalars["Int"]>;
  employeeType?: Maybe<Scalars["Int"]>;
  fax?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  hireDate?: Maybe<Scalars["String"]>;
  homeAddress?: Maybe<Scalars["String"]>;
  homeAddress2?: Maybe<Scalars["String"]>;
  homeCity?: Maybe<Scalars["String"]>;
  homePhone?: Maybe<Scalars["String"]>;
  homeState?: Maybe<Scalars["String"]>;
  homeZip?: Maybe<Scalars["String"]>;
  lastDateWorked?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  managerName?: Maybe<Scalars["String"]>;
  personId?: Maybe<Scalars["String"]>;
  seats?: Maybe<Scalars["String"]>;
  termDate?: Maybe<Scalars["String"]>;
};

export type Skynet_CertificateInfo = {
  __typename?: "skynet_CertificateInfo";
  effectiveDate?: Maybe<GqlDate>;
  expirationDate?: Maybe<GqlDate>;
  aliases?: Maybe<Array<Maybe<Scalars["String"]>>>;
  certificateTemplate?: Maybe<Scalars["String"]>;
  commonName?: Maybe<Scalars["String"]>;
  organization?: Maybe<Scalars["String"]>;
  organizationUnit?: Maybe<Scalars["String"]>;
  serialNumber?: Maybe<Scalars["String"]>;
  userPrincipalName?: Maybe<Scalars["String"]>;
};

export type Skynet_CertificateResponse = {
  __typename?: "skynet_CertificateResponse";
  privateKey?: Maybe<Scalars["String"]>;
  request?: Maybe<Scalars["String"]>;
};

export type Skynet_ComputerNetworkAdapter = {
  __typename?: "skynet_ComputerNetworkAdapter";
  ip?: Maybe<Array<Maybe<Scalars["String"]>>>;
  lastNegotiation?: Maybe<Scalars["String"]>;
  mac?: Maybe<Scalars["String"]>;
  make?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  negotiationMode?: Maybe<Scalars["String"]>;
  speedDuplex?: Maybe<Scalars["String"]>;
};

export type Skynet_CreateHomeDirResponse = {
  __typename?: "skynet_CreateHomeDirResponse";
  path?: Maybe<Scalars["String"]>;
  unixPath?: Maybe<Scalars["String"]>;
};

export type Skynet_CsLinuxComputer = {
  __typename?: "skynet_CsLinuxComputer";
  name?: Maybe<Scalars["String"]>;
};

export type Skynet_DataPrivilegeDirectPermissionRequest = {
  __typename?: "skynet_DataPrivilegeDirectPermissionRequest";
  errorCode?: Maybe<Scalars["Int"]>;
  message?: Maybe<Scalars["String"]>;
  reqId?: Maybe<Scalars["Long"]>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type Skynet_DataPrivilegeMembershipRequest = {
  __typename?: "skynet_DataPrivilegeMembershipRequest";
  requestId?: Maybe<Scalars["Long"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Skynet_Department = {
  __typename?: "skynet_Department";
  cooEmployeeId?: Maybe<Scalars["String"]>;
  deparmentLongDescription?: Maybe<Scalars["String"]>;
  departmentDescription?: Maybe<Scalars["String"]>;
  departmentId?: Maybe<Scalars["String"]>;
  departmentShortDescription?: Maybe<Scalars["String"]>;
  departmentType?: Maybe<Scalars["String"]>;
  effectiveDate?: Maybe<Scalars["String"]>;
  effectiveStatus?: Maybe<Scalars["String"]>;
  function?: Maybe<Scalars["Int"]>;
  functionCategory?: Maybe<Scalars["String"]>;
  functionCategoryDescription?: Maybe<Scalars["String"]>;
  functionCode?: Maybe<Scalars["String"]>;
  functionCodeEmployeeId?: Maybe<Scalars["String"]>;
  generalistId?: Maybe<Scalars["String"]>;
  hrBusinessUnit?: Maybe<Scalars["String"]>;
  hrBusinessUnitDescription?: Maybe<Scalars["String"]>;
  hrEntity?: Maybe<Scalars["String"]>;
  hrEntityName?: Maybe<Scalars["String"]>;
  itMgrEmployeeId?: Maybe<Scalars["String"]>;
  setId?: Maybe<Scalars["String"]>;
  subfunction?: Maybe<Scalars["String"]>;
};

export type Skynet_Detail = {
  __typename?: "skynet_Detail";
  integration_id?: Maybe<Scalars["String"]>;
  requested_by?: Maybe<Scalars["String"]>;
  requested_for?: Maybe<Scalars["String"]>;
  variables?: Maybe<Skynet_Variables>;
};

export type Skynet_DfsFolder = {
  __typename?: "skynet_DfsFolder";
  description?: Maybe<Scalars["String"]>;
  flags?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  targets?: Maybe<Array<Maybe<Skynet_DfsTarget>>>;
  timeToLive?: Maybe<Scalars["Long"]>;
};

export type Skynet_DfsRoot = {
  __typename?: "skynet_DfsRoot";
  description?: Maybe<Scalars["String"]>;
  flags?: Maybe<Scalars["String"]>;
  grantAdminAccess?: Maybe<Array<Maybe<Scalars["String"]>>>;
  name?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  timeToLive?: Maybe<Scalars["Long"]>;
};

export type Skynet_DfsTarget = {
  __typename?: "skynet_DfsTarget";
  priority?: Maybe<Scalars["String"]>;
  priorityRank?: Maybe<Scalars["Long"]>;
  state?: Maybe<Scalars["String"]>;
  targetPath?: Maybe<Scalars["String"]>;
};

export type Skynet_DhcpLease = {
  __typename?: "skynet_DhcpLease";
  leaseExpiryTime?: Maybe<GqlDate>;
  addressState?: Maybe<LeaseState>;
  description?: Maybe<Scalars["String"]>;
  dnsRegistration?: Maybe<LeaseDnsRegistration>;
  dnsRr?: Maybe<LeaseDnsRr>;
  hostName?: Maybe<Scalars["String"]>;
  ipAddress?: Maybe<Scalars["String"]>;
  macAddress?: Maybe<Scalars["String"]>;
};

export type Skynet_DhcpOption = {
  __typename?: "skynet_DhcpOption";
  name?: Maybe<Scalars["String"]>;
  optionId?: Maybe<Scalars["Long"]>;
  policyName?: Maybe<Scalars["String"]>;
  type?: Maybe<OptionDataTypes>;
  userClass?: Maybe<Scalars["String"]>;
  value?: Maybe<Array<Maybe<Scalars["String"]>>>;
  vendorClass?: Maybe<Scalars["String"]>;
};

export type Skynet_DhcpReservation = {
  __typename?: "skynet_DhcpReservation";
  addressState?: Maybe<ReservationState>;
  clientId?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  reservedIp?: Maybe<Scalars["String"]>;
  type?: Maybe<DhcpSubnetClientType>;
};

export type Skynet_DhcpScope = {
  __typename?: "skynet_DhcpScope";
  description?: Maybe<Scalars["String"]>;
  endRange?: Maybe<Scalars["String"]>;
  leaseDuration?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  scopeId?: Maybe<Scalars["String"]>;
  startRange?: Maybe<Scalars["String"]>;
  state?: Maybe<SubnetState>;
  subnetMask?: Maybe<Scalars["String"]>;
};

export type Skynet_DhcpServer = {
  __typename?: "skynet_DhcpServer";
  dnsName?: Maybe<Scalars["String"]>;
  ipAddress?: Maybe<Scalars["String"]>;
};

export type Skynet_DnsRequest = {
  __typename?: "skynet_DnsRequest";
  processTime?: Maybe<GqlDate>;
  requestTime?: Maybe<GqlDate>;
  id?: Maybe<Scalars["Int"]>;
  requestContainer?: Maybe<Scalars["String"]>;
  requestStatus?: Maybe<Scalars["String"]>;
  requestUser?: Maybe<Scalars["String"]>;
};

export type Skynet_DnsRequestDetail = {
  __typename?: "skynet_DnsRequestDetail";
  lastUpdated?: Maybe<GqlDate>;
  id?: Maybe<Scalars["Int"]>;
  ipAddress?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  recordType?: Maybe<Scalars["String"]>;
  requestId?: Maybe<Scalars["Int"]>;
  requestType?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Skynet_DnsResourceRecord = {
  __typename?: "skynet_DnsResourceRecord";
  timestamp?: Maybe<GqlDate>;
  distinguishedName?: Maybe<Scalars["String"]>;
  hostName?: Maybe<Scalars["String"]>;
  recordClass?: Maybe<RecordClassValue>;
  recordData?: Maybe<Skynet_DnsResourceRecordData>;
  recordDetails?: Maybe<Scalars["String"]>;
  recordType?: Maybe<RecordTypeValue>;
  type?: Maybe<Scalars["Int"]>;
};

export type Skynet_DnsResourceRecordData = {
  __typename?: "skynet_DnsResourceRecordData";
  cacheTimeout?: Maybe<GqlDate>;
  expireLimit?: Maybe<GqlDate>;
  lookupTimeout?: Maybe<GqlDate>;
  minimumTimeToLive?: Maybe<GqlDate>;
  originalTtl?: Maybe<GqlDate>;
  refreshInterval?: Maybe<GqlDate>;
  retryDelay?: Maybe<GqlDate>;
  signatureExpiration?: Maybe<GqlDate>;
  signatureInception?: Maybe<GqlDate>;
  address?: Maybe<Scalars["String"]>;
  addressType?: Maybe<Scalars["String"]>;
  base64Data?: Maybe<Scalars["String"]>;
  certificateAssociationData?: Maybe<Scalars["String"]>;
  certificateUsage?: Maybe<Scalars["String"]>;
  coveredRecordTypes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  cpu?: Maybe<Scalars["String"]>;
  cryptoAlgorithm?: Maybe<Scalars["String"]>;
  data?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  descriptiveText?: Maybe<Scalars["String"]>;
  dhcId?: Maybe<Scalars["String"]>;
  digest?: Maybe<Scalars["String"]>;
  digestType?: Maybe<Scalars["String"]>;
  domainName?: Maybe<Scalars["String"]>;
  domainNameAlias?: Maybe<Scalars["String"]>;
  hostNameAlias?: Maybe<Scalars["String"]>;
  iPv4Address?: Maybe<Scalars["String"]>;
  iPv6Addrees?: Maybe<Scalars["String"]>;
  intermediateHost?: Maybe<Scalars["String"]>;
  internetAddress?: Maybe<Scalars["String"]>;
  internetProtocol?: Maybe<Scalars["String"]>;
  isdnNumber?: Maybe<Scalars["String"]>;
  isdnSubAddress?: Maybe<Scalars["String"]>;
  iterations?: Maybe<Scalars["Short"]>;
  keyFlags?: Maybe<Scalars["Int"]>;
  keyProtocol?: Maybe<Scalars["String"]>;
  keyTag?: Maybe<Scalars["Int"]>;
  labelCount?: Maybe<Scalars["Short"]>;
  mailExchange?: Maybe<Scalars["String"]>;
  matchingType?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nameServer?: Maybe<Scalars["String"]>;
  nameSigner?: Maybe<Scalars["String"]>;
  nextHashedOwnerName?: Maybe<Scalars["String"]>;
  operatingSystem?: Maybe<Scalars["String"]>;
  optOut?: Maybe<Scalars["Boolean"]>;
  port?: Maybe<Scalars["Int"]>;
  preference?: Maybe<Scalars["Int"]>;
  primaryServer?: Maybe<Scalars["String"]>;
  priority?: Maybe<Scalars["Int"]>;
  psdnAddress?: Maybe<Scalars["String"]>;
  ptrDomainName?: Maybe<Scalars["String"]>;
  replicate?: Maybe<Scalars["Boolean"]>;
  responsiblePerson?: Maybe<Scalars["String"]>;
  resultDomain?: Maybe<Scalars["String"]>;
  revoked?: Maybe<Scalars["Boolean"]>;
  salt?: Maybe<Scalars["String"]>;
  secureEntryPoint?: Maybe<Scalars["Boolean"]>;
  selector?: Maybe<Scalars["String"]>;
  serialNumber?: Maybe<Scalars["Long"]>;
  serverName?: Maybe<Scalars["String"]>;
  service?: Maybe<Array<Maybe<Scalars["String"]>>>;
  signature?: Maybe<Scalars["String"]>;
  subType?: Maybe<Scalars["Int"]>;
  typeCovered?: Maybe<Scalars["String"]>;
  weight?: Maybe<Scalars["Int"]>;
  winsServers?: Maybe<Array<Maybe<Scalars["String"]>>>;
  zoneKey?: Maybe<Scalars["Boolean"]>;
};

export type Skynet_DnsZone = {
  __typename?: "skynet_DnsZone";
  allowedDcForNsRecordsAutoCreation?: Maybe<Array<Maybe<Scalars["String"]>>>;
  directoryPartitionName?: Maybe<Scalars["String"]>;
  distinguishedName?: Maybe<Scalars["String"]>;
  dynamicUpdate?: Maybe<DynamicUpdateValues>;
  isAutoCreated?: Maybe<Scalars["Boolean"]>;
  isDsIntegrated?: Maybe<Scalars["Boolean"]>;
  isPaused?: Maybe<Scalars["Boolean"]>;
  isReadOnly?: Maybe<Scalars["Boolean"]>;
  isReverseLookupZone?: Maybe<Scalars["Boolean"]>;
  isShutdown?: Maybe<Scalars["Boolean"]>;
  isSigned?: Maybe<Scalars["Boolean"]>;
  isWinsEnabled?: Maybe<Scalars["Boolean"]>;
  notify?: Maybe<NotifyValues>;
  notifyServers?: Maybe<Array<Maybe<Scalars["String"]>>>;
  replicationScope?: Maybe<ReplicationScopeValues>;
  secondaryServers?: Maybe<Array<Maybe<Scalars["String"]>>>;
  secureSecondaries?: Maybe<SecureSecondariesValues>;
  zoneName?: Maybe<Scalars["String"]>;
  zoneType?: Maybe<ZoneTypeValues>;
};

export type Skynet_EmployeeSeatLocation = {
  __typename?: "skynet_EmployeeSeatLocation";
  building?: Maybe<Scalars["String"]>;
  differentLocation?: Maybe<Scalars["Short"]>;
  employeeId?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  psLocId?: Maybe<Scalars["String"]>;
  seatLocation?: Maybe<Scalars["String"]>;
  secondaryLocation?: Maybe<Scalars["Short"]>;
};

export type Skynet_Environment = {
  __typename?: "skynet_Environment";
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Skynet_Error = {
  __typename?: "skynet_Error";
  detail?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  parsedDetail?: Maybe<Skynet_Detail>;
};

export type Skynet_ExchangeContact = {
  __typename?: "skynet_ExchangeContact";
  alias?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  emailAddreses?: Maybe<Array<Maybe<Scalars["String"]>>>;
  externalEmailAddress?: Maybe<Scalars["String"]>;
  hiddenFromAddressListsEnabled?: Maybe<Scalars["Boolean"]>;
};

export type Skynet_ExchangeMailbox = {
  __typename?: "skynet_ExchangeMailbox";
  alias?: Maybe<Scalars["String"]>;
  database?: Maybe<Scalars["String"]>;
  emailAddreses?: Maybe<Array<Maybe<Skynet_ExchangeMailboxEmail>>>;
  retentionPolicy?: Maybe<Scalars["String"]>;
};

export type Skynet_ExchangeMailboxAccess = {
  __typename?: "skynet_ExchangeMailboxAccess";
  userName?: Maybe<Scalars["String"]>;
};

export type Skynet_ExchangeMailboxEmail = {
  __typename?: "skynet_ExchangeMailboxEmail";
  emailAddress?: Maybe<Scalars["String"]>;
  isPrimaryAddress?: Maybe<Scalars["Boolean"]>;
};

export type Skynet_ExchangeMailboxRule = {
  __typename?: "skynet_ExchangeMailboxRule";
  description?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Skynet_ExternalCertificateResponse = {
  __typename?: "skynet_ExternalCertificateResponse";
  expirationDate?: Maybe<GqlDate>;
  certificate?: Maybe<Scalars["String"]>;
  orderNumber?: Maybe<Scalars["String"]>;
  subject?: Maybe<Scalars["String"]>;
  thumbprint?: Maybe<Scalars["String"]>;
};

export type Skynet_Function = {
  __typename?: "skynet_Function";
  category?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Skynet_HomeDir = {
  __typename?: "skynet_HomeDir";
  autoMounts?: Maybe<Array<Maybe<Scalars["String"]>>>;
  paths?: Maybe<Array<Maybe<Skynet_HomeDirPath>>>;
  userName?: Maybe<Scalars["String"]>;
  userStatus?: Maybe<Scalars["String"]>;
};

export type Skynet_HomeDirPath = {
  __typename?: "skynet_HomeDirPath";
  disabledDate?: Maybe<GqlDate>;
  legalHoldDate?: Maybe<GqlDate>;
  name?: Maybe<Scalars["String"]>;
};

export type Skynet_Job = {
  __typename?: "skynet_Job";
  date?: Maybe<GqlDate>;
  arguments?: Maybe<Array<Maybe<Scalars["String"]>>>;
  duration?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

export type Skynet_JobHistory = {
  __typename?: "skynet_JobHistory";
  date?: Maybe<GqlDate>;
  reason?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

export type Skynet_LegalHoldResponse = {
  __typename?: "skynet_LegalHoldResponse";
  enabled?: Maybe<Scalars["Boolean"]>;
};

export type Skynet_LinuxAutoMount = {
  __typename?: "skynet_LinuxAutoMount";
  description?: Maybe<Scalars["String"]>;
  information?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Skynet_LinuxAutoMountMap = {
  __typename?: "skynet_LinuxAutoMountMap";
  description?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Skynet_LinuxComputerMountPoint = {
  __typename?: "skynet_LinuxComputerMountPoint";
  device?: Maybe<Scalars["String"]>;
  fileSystemType?: Maybe<Scalars["String"]>;
  mountPoint?: Maybe<Scalars["String"]>;
  options?: Maybe<Scalars["String"]>;
};

export type Skynet_LinuxComputerProcess = {
  __typename?: "skynet_LinuxComputerProcess";
  command?: Maybe<Scalars["String"]>;
  percentCpu?: Maybe<Scalars["Float"]>;
  percentMemory?: Maybe<Scalars["Float"]>;
  pid?: Maybe<Scalars["Int"]>;
  rss?: Maybe<Scalars["Int"]>;
  started?: Maybe<Scalars["String"]>;
  stat?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["String"]>;
  tt?: Maybe<Scalars["String"]>;
  user?: Maybe<Scalars["String"]>;
  vsz?: Maybe<Scalars["String"]>;
};

export type Skynet_LinuxComputerSoftware = {
  __typename?: "skynet_LinuxComputerSoftware";
  name?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
};

export type Skynet_LinuxGroup = {
  __typename?: "skynet_LinuxGroup";
  creationDate?: Maybe<GqlDate>;
  lastUpdated?: Maybe<GqlDate>;
  description?: Maybe<Scalars["String"]>;
  distinguishedName?: Maybe<Scalars["String"]>;
  gidNumber?: Maybe<Scalars["Int"]>;
  manager?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Skynet_LinuxGroupMember = {
  __typename?: "skynet_LinuxGroupMember";
  uid?: Maybe<Scalars["String"]>;
};

export type Skynet_LinuxNetgroup = {
  __typename?: "skynet_LinuxNetgroup";
  creationDate?: Maybe<GqlDate>;
  lastUpdated?: Maybe<GqlDate>;
  description?: Maybe<Scalars["String"]>;
  distinguishedName?: Maybe<Scalars["String"]>;
  manager?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<NetgroupType>;
  unixOverseen?: Maybe<Scalars["Boolean"]>;
};

export type Skynet_LinuxSudoRole = {
  __typename?: "skynet_LinuxSudoRole";
  command?: Maybe<Scalars["String"]>;
  host?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  user?: Maybe<Scalars["String"]>;
};

export type Skynet_LinuxUser = {
  __typename?: "skynet_LinuxUser";
  creationDate?: Maybe<GqlDate>;
  lastUpdated?: Maybe<GqlDate>;
  passwordLastSet?: Maybe<GqlDate>;
  description?: Maybe<Scalars["String"]>;
  destinationIndicator?: Maybe<Scalars["String"]>;
  disabled?: Maybe<Scalars["Boolean"]>;
  displayName?: Maybe<Scalars["String"]>;
  distinguishedName?: Maybe<Scalars["String"]>;
  employeeType?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  homeDirectory?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  loginShell?: Maybe<Scalars["String"]>;
  owner?: Maybe<Scalars["String"]>;
  primaryGroup?: Maybe<Scalars["Int"]>;
  uidNumber?: Maybe<Scalars["Long"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Skynet_Location = {
  __typename?: "skynet_Location";
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Skynet_MacUser = {
  __typename?: "skynet_MacUser";
  department?: Maybe<Scalars["String"]>;
  departmentNumber?: Maybe<Scalars["String"]>;
  eMail?: Maybe<Scalars["String"]>;
  employeeId?: Maybe<Scalars["String"]>;
  fax?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  jobCode?: Maybe<Scalars["String"]>;
  manager?: Maybe<Scalars["Int"]>;
  managerName?: Maybe<Scalars["String"]>;
  modem?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  personStatus?: Maybe<Scalars["String"]>;
  personType?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
};

export type Skynet_MessageResponse = {
  __typename?: "skynet_MessageResponse";
  timeStamp?: Maybe<GqlDate>;
  badUserNames?: Maybe<Array<Maybe<Scalars["String"]>>>;
  messageID?: Maybe<Scalars["String"]>;
};

export type Skynet_MobileDevice = {
  __typename?: "skynet_MobileDevice";
  lastComplianceCheckOn?: Maybe<GqlDate>;
  lastCompromisedCheckOn?: Maybe<GqlDate>;
  lastEnrolledOn?: Maybe<GqlDate>;
  lastSeen?: Maybe<GqlDate>;
  acLineStatus?: Maybe<Scalars["Int"]>;
  assetNumber?: Maybe<Scalars["String"]>;
  complianceStatus?: Maybe<Scalars["String"]>;
  compromisedStatus?: Maybe<Scalars["Boolean"]>;
  deviceFriendlyName?: Maybe<Scalars["String"]>;
  easId?: Maybe<Scalars["String"]>;
  enrollmentStatus?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  imei?: Maybe<Scalars["String"]>;
  isSupervised?: Maybe<Scalars["Boolean"]>;
  locationGroupName?: Maybe<Scalars["String"]>;
  macAddress?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  operatingSystem?: Maybe<Scalars["String"]>;
  ownership?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  platform?: Maybe<Scalars["String"]>;
  serialNumber?: Maybe<Scalars["String"]>;
  udid?: Maybe<Scalars["String"]>;
  userEmailAddress?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
  virtualMemory?: Maybe<Scalars["Int"]>;
};

export type Skynet_MobileUser = {
  __typename?: "skynet_MobileUser";
  contactNumber?: Maybe<Scalars["String"]>;
  customAttribute1?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  emailUserName?: Maybe<Scalars["String"]>;
  enrolledDevicesCount?: Maybe<Scalars["String"]>;
  externalId?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  group?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  locationGroupId?: Maybe<Scalars["String"]>;
  messageType?: Maybe<Scalars["Int"]>;
  mobileNumber?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
  securityType?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["Boolean"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Skynet_NlyteChassis = {
  __typename?: "skynet_NlyteChassis";
  auditDate?: Maybe<GqlDate>;
  creationDate?: Maybe<GqlDate>;
  operationalDate?: Maybe<GqlDate>;
  purchaseDate?: Maybe<GqlDate>;
  assetID?: Maybe<Scalars["Int"]>;
  assetName?: Maybe<Scalars["String"]>;
  assetNumber?: Maybe<Scalars["Int"]>;
  auditStatusCorrect?: Maybe<Scalars["Boolean"]>;
  cabinetAssetID?: Maybe<Scalars["Int"]>;
  costCenter?: Maybe<Scalars["String"]>;
  currentPowerFactor?: Maybe<Scalars["BigDecimal"]>;
  damagedRFIDTag?: Maybe<Scalars["String"]>;
  gridReference?: Maybe<Scalars["String"]>;
  gridReferenceColumn?: Maybe<Scalars["String"]>;
  gridReferenceLayer?: Maybe<Scalars["String"]>;
  gridReferenceRow?: Maybe<Scalars["String"]>;
  isMasterRedundancyDevice?: Maybe<Scalars["Boolean"]>;
  isStaticSwitchEnabled?: Maybe<Scalars["Boolean"]>;
  isTemplateRelated?: Maybe<Scalars["Boolean"]>;
  lastAuditorAccountID?: Maybe<Scalars["Int"]>;
  lastScanned?: Maybe<Scalars["String"]>;
  locationGroupID?: Maybe<Scalars["Int"]>;
  locationGroupOrChildrenID?: Maybe<Scalars["Int"]>;
  materialID?: Maybe<Scalars["Int"]>;
  operationalStatus?: Maybe<OperationalStatusEnum>;
  outputCount?: Maybe<Scalars["Int"]>;
  poNumber?: Maybe<Scalars["String"]>;
  powerFactorOverride?: Maybe<Scalars["BigDecimal"]>;
  powerState?: Maybe<PowerStateEnum>;
  prNumber?: Maybe<Scalars["String"]>;
  projectID?: Maybe<Scalars["Int"]>;
  purchasePrice?: Maybe<Scalars["BigDecimal"]>;
  realtimePowerFactor?: Maybe<Scalars["BigDecimal"]>;
  recordStatus?: Maybe<RecordStatusEnum>;
  rfidTag?: Maybe<Scalars["String"]>;
  sapAssetNumber?: Maybe<Scalars["String"]>;
  serialNumber?: Maybe<Scalars["String"]>;
  strategyID?: Maybe<Scalars["Int"]>;
  subStatusID?: Maybe<Scalars["Int"]>;
  tag?: Maybe<Scalars["String"]>;
  templateID?: Maybe<Scalars["Int"]>;
  totalPlugs?: Maybe<Scalars["Int"]>;
  totalSystemSpace?: Maybe<Scalars["Int"]>;
  trancheNumber?: Maybe<Scalars["Int"]>;
  uMountingID?: Maybe<Scalars["Int"]>;
  uniqueAssetName?: Maybe<Scalars["String"]>;
};

export type Skynet_NlyteKvmSwitch = {
  __typename?: "skynet_NlyteKvmSwitch";
  auditDate?: Maybe<GqlDate>;
  creationDate?: Maybe<GqlDate>;
  operationalDate?: Maybe<GqlDate>;
  purchaseDate?: Maybe<GqlDate>;
  assetID?: Maybe<Scalars["Int"]>;
  assetName?: Maybe<Scalars["String"]>;
  assetNumber?: Maybe<Scalars["Int"]>;
  auditStatusCorrect?: Maybe<Scalars["Boolean"]>;
  cabinetAssetID?: Maybe<Scalars["Int"]>;
  costCenter?: Maybe<Scalars["String"]>;
  damagedRFIDTag?: Maybe<Scalars["String"]>;
  gridReference?: Maybe<Scalars["String"]>;
  gridReferenceColumn?: Maybe<Scalars["String"]>;
  gridReferenceLayer?: Maybe<Scalars["String"]>;
  gridReferenceRow?: Maybe<Scalars["String"]>;
  isStaticSwitchEnabled?: Maybe<Scalars["Boolean"]>;
  isTemplateRelated?: Maybe<Scalars["Boolean"]>;
  lastAuditorAccountID?: Maybe<Scalars["Int"]>;
  lastScanned?: Maybe<Scalars["String"]>;
  locationGroupID?: Maybe<Scalars["Int"]>;
  locationGroupOrChildrenID?: Maybe<Scalars["Int"]>;
  materialID?: Maybe<Scalars["Int"]>;
  operationalStatus?: Maybe<OperationalStatusEnum>;
  poNumber?: Maybe<Scalars["String"]>;
  powerState?: Maybe<PowerStateEnum>;
  prNumber?: Maybe<Scalars["String"]>;
  purchasePrice?: Maybe<Scalars["BigDecimal"]>;
  recordStatus?: Maybe<RecordStatusEnum>;
  rfidTag?: Maybe<Scalars["String"]>;
  sapAssetNumber?: Maybe<Scalars["String"]>;
  serialNumber?: Maybe<Scalars["String"]>;
  subStatusID?: Maybe<Scalars["Int"]>;
  tag?: Maybe<Scalars["String"]>;
  templateID?: Maybe<Scalars["Int"]>;
  totalPlugs?: Maybe<Scalars["Int"]>;
  uMountingID?: Maybe<Scalars["Int"]>;
  uniqueAssetName?: Maybe<Scalars["String"]>;
};

export type Skynet_NlyteNetwork = {
  __typename?: "skynet_NlyteNetwork";
  auditDate?: Maybe<GqlDate>;
  creationDate?: Maybe<GqlDate>;
  operationalDate?: Maybe<GqlDate>;
  purchaseDate?: Maybe<GqlDate>;
  assetID?: Maybe<Scalars["Int"]>;
  assetName?: Maybe<Scalars["String"]>;
  assetNumber?: Maybe<Scalars["Int"]>;
  auditStatusCorrect?: Maybe<Scalars["Boolean"]>;
  cabinetAssetID?: Maybe<Scalars["Int"]>;
  cardPosition?: Maybe<Scalars["String"]>;
  chassisMountedAssetMapID?: Maybe<Scalars["Int"]>;
  costCenter?: Maybe<Scalars["String"]>;
  damagedRFIDTag?: Maybe<Scalars["String"]>;
  gridReference?: Maybe<Scalars["String"]>;
  gridReferenceColumn?: Maybe<Scalars["String"]>;
  gridReferenceLayer?: Maybe<Scalars["String"]>;
  gridReferenceRow?: Maybe<Scalars["String"]>;
  hostingAssetID?: Maybe<Scalars["Int"]>;
  isStaticSwitchEnabled?: Maybe<Scalars["Boolean"]>;
  isTemplateRelated?: Maybe<Scalars["Boolean"]>;
  lastAuditorAccountID?: Maybe<Scalars["Int"]>;
  lastScanned?: Maybe<Scalars["String"]>;
  locationGroupID?: Maybe<Scalars["Int"]>;
  locationGroupOrChildrenID?: Maybe<Scalars["Int"]>;
  materialID?: Maybe<Scalars["Int"]>;
  networkUsageID?: Maybe<Scalars["Int"]>;
  operationalStatus?: Maybe<OperationalStatusEnum>;
  poNumber?: Maybe<Scalars["String"]>;
  powerState?: Maybe<PowerStateEnum>;
  prNumber?: Maybe<Scalars["String"]>;
  purchasePrice?: Maybe<Scalars["BigDecimal"]>;
  recordStatus?: Maybe<RecordStatusEnum>;
  rfidTag?: Maybe<Scalars["String"]>;
  sapAssetNumber?: Maybe<Scalars["String"]>;
  serialNumber?: Maybe<Scalars["String"]>;
  subStatusID?: Maybe<Scalars["Int"]>;
  tag?: Maybe<Scalars["String"]>;
  templateID?: Maybe<Scalars["Int"]>;
  totalPlugs?: Maybe<Scalars["Int"]>;
  uMountingID?: Maybe<Scalars["Int"]>;
  uniqueAssetName?: Maybe<Scalars["String"]>;
};

export type Skynet_NlytePeripheral = {
  __typename?: "skynet_NlytePeripheral";
  auditDate?: Maybe<GqlDate>;
  creationDate?: Maybe<GqlDate>;
  operationalDate?: Maybe<GqlDate>;
  purchaseDate?: Maybe<GqlDate>;
  assetID?: Maybe<Scalars["Int"]>;
  assetName?: Maybe<Scalars["String"]>;
  assetNumber?: Maybe<Scalars["Int"]>;
  auditStatusCorrect?: Maybe<Scalars["Boolean"]>;
  cabinetAssetID?: Maybe<Scalars["Int"]>;
  costCenter?: Maybe<Scalars["String"]>;
  damagedRFIDTag?: Maybe<Scalars["String"]>;
  diskCapacity?: Maybe<Scalars["Float"]>;
  gridReference?: Maybe<Scalars["String"]>;
  gridReferenceColumn?: Maybe<Scalars["String"]>;
  gridReferenceLayer?: Maybe<Scalars["String"]>;
  gridReferenceRow?: Maybe<Scalars["String"]>;
  isStaticSwitchEnabled?: Maybe<Scalars["Boolean"]>;
  isTemplateRelated?: Maybe<Scalars["Boolean"]>;
  lastAuditorAccountID?: Maybe<Scalars["Int"]>;
  lastScanned?: Maybe<Scalars["String"]>;
  locationGroupID?: Maybe<Scalars["Int"]>;
  locationGroupOrChildrenID?: Maybe<Scalars["Int"]>;
  materialID?: Maybe<Scalars["Int"]>;
  operationalStatus?: Maybe<OperationalStatusEnum>;
  ownerAssetID?: Maybe<Scalars["Int"]>;
  poNumber?: Maybe<Scalars["String"]>;
  powerState?: Maybe<PowerStateEnum>;
  prNumber?: Maybe<Scalars["String"]>;
  purchasePrice?: Maybe<Scalars["BigDecimal"]>;
  recordStatus?: Maybe<RecordStatusEnum>;
  rfidTag?: Maybe<Scalars["String"]>;
  sapAssetNumber?: Maybe<Scalars["String"]>;
  serialNumber?: Maybe<Scalars["String"]>;
  storageUsageID?: Maybe<Scalars["Int"]>;
  subStatusID?: Maybe<Scalars["Int"]>;
  tag?: Maybe<Scalars["String"]>;
  templateID?: Maybe<Scalars["Int"]>;
  totalMemory?: Maybe<Scalars["Float"]>;
  totalPlugs?: Maybe<Scalars["Int"]>;
  uMountingID?: Maybe<Scalars["Int"]>;
  uniqueAssetName?: Maybe<Scalars["String"]>;
};

export type Skynet_NlytePowerStrip = {
  __typename?: "skynet_NlytePowerStrip";
  auditDate?: Maybe<GqlDate>;
  creationDate?: Maybe<GqlDate>;
  operationalDate?: Maybe<GqlDate>;
  purchaseDate?: Maybe<GqlDate>;
  assetID?: Maybe<Scalars["Int"]>;
  assetName?: Maybe<Scalars["String"]>;
  assetNumber?: Maybe<Scalars["Int"]>;
  auditStatusCorrect?: Maybe<Scalars["Boolean"]>;
  cabinetAssetID?: Maybe<Scalars["Int"]>;
  costCenter?: Maybe<Scalars["String"]>;
  currentPowerFactor?: Maybe<Scalars["BigDecimal"]>;
  damagedRFIDTag?: Maybe<Scalars["String"]>;
  freeOutlets?: Maybe<Scalars["Int"]>;
  gridReference?: Maybe<Scalars["String"]>;
  gridReferenceColumn?: Maybe<Scalars["String"]>;
  gridReferenceLayer?: Maybe<Scalars["String"]>;
  gridReferenceRow?: Maybe<Scalars["String"]>;
  isMasterRedundancyDevice?: Maybe<Scalars["Boolean"]>;
  isStaticSwitchEnabled?: Maybe<Scalars["Boolean"]>;
  isTemplateRelated?: Maybe<Scalars["Boolean"]>;
  lastAuditorAccountID?: Maybe<Scalars["Int"]>;
  lastScanned?: Maybe<Scalars["String"]>;
  locationGroupID?: Maybe<Scalars["Int"]>;
  locationGroupOrChildrenID?: Maybe<Scalars["Int"]>;
  materialID?: Maybe<Scalars["Int"]>;
  operationalStatus?: Maybe<OperationalStatusEnum>;
  outputCount?: Maybe<Scalars["Int"]>;
  poNumber?: Maybe<Scalars["String"]>;
  powerFactorOverride?: Maybe<Scalars["BigDecimal"]>;
  powerState?: Maybe<PowerStateEnum>;
  prNumber?: Maybe<Scalars["String"]>;
  purchasePrice?: Maybe<Scalars["BigDecimal"]>;
  realtimePowerFactor?: Maybe<Scalars["BigDecimal"]>;
  recordStatus?: Maybe<RecordStatusEnum>;
  rfidTag?: Maybe<Scalars["String"]>;
  sapAssetNumber?: Maybe<Scalars["String"]>;
  serialNumber?: Maybe<Scalars["String"]>;
  subStatusID?: Maybe<Scalars["Int"]>;
  tag?: Maybe<Scalars["String"]>;
  templateID?: Maybe<Scalars["Int"]>;
  totalPlugs?: Maybe<Scalars["Int"]>;
  uMountingID?: Maybe<Scalars["Int"]>;
  uniqueAssetName?: Maybe<Scalars["String"]>;
};

export type Skynet_NlyteServer = {
  __typename?: "skynet_NlyteServer";
  auditDate?: Maybe<GqlDate>;
  creationDate?: Maybe<GqlDate>;
  operationalDate?: Maybe<GqlDate>;
  purchaseDate?: Maybe<GqlDate>;
  assetID?: Maybe<Scalars["Int"]>;
  assetName?: Maybe<Scalars["String"]>;
  assetNumber?: Maybe<Scalars["Int"]>;
  auditStatusCorrect?: Maybe<Scalars["Boolean"]>;
  cabinetAssetID?: Maybe<Scalars["Int"]>;
  chassisMountedAssetMapID?: Maybe<Scalars["Int"]>;
  costCenter?: Maybe<Scalars["String"]>;
  damagedRFIDTag?: Maybe<Scalars["String"]>;
  fqdn?: Maybe<Scalars["String"]>;
  gridReference?: Maybe<Scalars["String"]>;
  gridReferenceColumn?: Maybe<Scalars["String"]>;
  gridReferenceLayer?: Maybe<Scalars["String"]>;
  gridReferenceRow?: Maybe<Scalars["String"]>;
  hardwareSupportWeighting?: Maybe<Scalars["Float"]>;
  isStaticSwitchEnabled?: Maybe<Scalars["Boolean"]>;
  isTemplateRelated?: Maybe<Scalars["Boolean"]>;
  lastAuditorAccountID?: Maybe<Scalars["Int"]>;
  lastScanned?: Maybe<Scalars["String"]>;
  locationGroupID?: Maybe<Scalars["Int"]>;
  locationGroupOrChildrenID?: Maybe<Scalars["Int"]>;
  managementSoftwareID?: Maybe<Scalars["Int"]>;
  materialID?: Maybe<Scalars["Int"]>;
  operatingSystemID?: Maybe<Scalars["Int"]>;
  operationalStatus?: Maybe<OperationalStatusEnum>;
  poNumber?: Maybe<Scalars["String"]>;
  powerState?: Maybe<PowerStateEnum>;
  prNumber?: Maybe<Scalars["String"]>;
  projectID?: Maybe<Scalars["Int"]>;
  purchasePrice?: Maybe<Scalars["BigDecimal"]>;
  recordStatus?: Maybe<RecordStatusEnum>;
  rfidTag?: Maybe<Scalars["String"]>;
  sapAssetNumber?: Maybe<Scalars["String"]>;
  serialNumber?: Maybe<Scalars["String"]>;
  strategyID?: Maybe<Scalars["Int"]>;
  subStatusID?: Maybe<Scalars["Int"]>;
  tag?: Maybe<Scalars["String"]>;
  templateID?: Maybe<Scalars["Int"]>;
  totalPlugs?: Maybe<Scalars["Int"]>;
  totalSystemSpace?: Maybe<Scalars["Int"]>;
  trancheNumber?: Maybe<Scalars["Int"]>;
  uMountingID?: Maybe<Scalars["Int"]>;
  uniqueAssetName?: Maybe<Scalars["String"]>;
};

export type Skynet_OktaFactor = {
  __typename?: "skynet_OktaFactor";
  created?: Maybe<GqlDate>;
  lastUpdated?: Maybe<GqlDate>;
  factorType?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  profile?: Maybe<Skynet_OktaFactorProfile>;
  provider?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Skynet_OktaFactorProfile = {
  __typename?: "skynet_OktaFactorProfile";
  answer?: Maybe<Scalars["String"]>;
  credentialId?: Maybe<Scalars["String"]>;
  deviceType?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  phoneExtension?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  platform?: Maybe<Scalars["String"]>;
  question?: Maybe<Scalars["String"]>;
  questionText?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
};

export type Skynet_OktaGroup = {
  __typename?: "skynet_OktaGroup";
  created?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  lastMembershipUpdated?: Maybe<Scalars["String"]>;
  lastUpdated?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Skynet_OktaUser = {
  __typename?: "skynet_OktaUser";
  activated?: Maybe<GqlDate>;
  created?: Maybe<GqlDate>;
  lastLogin?: Maybe<GqlDate>;
  lastUpdated?: Maybe<GqlDate>;
  passwordChanged?: Maybe<GqlDate>;
  statusChanged?: Maybe<GqlDate>;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  login?: Maybe<Scalars["String"]>;
  mobilePhone?: Maybe<Scalars["String"]>;
  secondaryEmail?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Skynet_OperatingSystem = {
  __typename?: "skynet_OperatingSystem";
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Skynet_OrgMap = {
  __typename?: "skynet_OrgMap";
  businessArea?: Maybe<Scalars["String"]>;
  businessAreaCode?: Maybe<Scalars["String"]>;
  cooName?: Maybe<Scalars["String"]>;
  cooidNo?: Maybe<Scalars["String"]>;
  costCode?: Maybe<Scalars["String"]>;
  costCodeDesc?: Maybe<Scalars["String"]>;
  costCodeFull?: Maybe<Scalars["String"]>;
  functionHeadIDNo?: Maybe<Scalars["String"]>;
  functionHeadName?: Maybe<Scalars["String"]>;
  itDirectorIDNo?: Maybe<Scalars["String"]>;
  itDirectorName?: Maybe<Scalars["String"]>;
  operatingUnit?: Maybe<Scalars["String"]>;
  operatingUnitCode?: Maybe<Scalars["String"]>;
  reportingUnit?: Maybe<Scalars["String"]>;
  reportingUnitCode?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Skynet_PagerDutyAcknowledgment = {
  __typename?: "skynet_PagerDutyAcknowledgment";
  at?: Maybe<GqlDate>;
  acknowledger?: Maybe<Skynet_PagerDutyReference>;
};

export type Skynet_PagerDutyAssignment = {
  __typename?: "skynet_PagerDutyAssignment";
  at?: Maybe<GqlDate>;
  assignee?: Maybe<Skynet_PagerDutyReference>;
};

export type Skynet_PagerDutyContactMethod = {
  __typename?: "skynet_PagerDutyContactMethod";
  address?: Maybe<Scalars["String"]>;
  html_url?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  self?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Skynet_PagerDutyEventResponse = {
  __typename?: "skynet_PagerDutyEventResponse";
  dedup_key?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Skynet_PagerDutyIncident = {
  __typename?: "skynet_PagerDutyIncident";
  created_At?: Maybe<GqlDate>;
  last_Status_Change_At?: Maybe<GqlDate>;
  acknowledgments?: Maybe<Array<Maybe<Skynet_PagerDutyAcknowledgment>>>;
  assignments?: Maybe<Array<Maybe<Skynet_PagerDutyAssignment>>>;
  escalation_Policy?: Maybe<Skynet_PagerDutyReference>;
  first_Trigger_Log_Entry?: Maybe<Skynet_PagerDutyReference>;
  html_Url?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  incident_Key?: Maybe<Scalars["String"]>;
  incident_Number?: Maybe<Scalars["Int"]>;
  last_Status_Change_By?: Maybe<Skynet_PagerDutyReference>;
  pending_Actions?: Maybe<Array<Maybe<Skynet_PagerDutyIncidentAction>>>;
  priority?: Maybe<Skynet_PagerDutyReference>;
  self?: Maybe<Scalars["String"]>;
  service?: Maybe<Skynet_PagerDutyReference>;
  status?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
  teams?: Maybe<Array<Maybe<Skynet_PagerDutyReference>>>;
  title?: Maybe<Scalars["String"]>;
};

export type Skynet_PagerDutyIncidentAction = {
  __typename?: "skynet_PagerDutyIncidentAction";
  at?: Maybe<GqlDate>;
  type?: Maybe<Scalars["String"]>;
};

export type Skynet_PagerDutyIncidentUrgencyRule = {
  __typename?: "skynet_PagerDutyIncidentUrgencyRule";
  during_Support_Hours?: Maybe<Skynet_PagerDutyIncidentUrgencyType>;
  outside_Support_Hours?: Maybe<Skynet_PagerDutyIncidentUrgencyType>;
  type?: Maybe<Scalars["String"]>;
  urgency?: Maybe<Scalars["String"]>;
};

export type Skynet_PagerDutyIncidentUrgencyType = {
  __typename?: "skynet_PagerDutyIncidentUrgencyType";
  type?: Maybe<Scalars["String"]>;
  urgency?: Maybe<Scalars["String"]>;
};

export type Skynet_PagerDutyInlineModel = {
  __typename?: "skynet_PagerDutyInlineModel";
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Skynet_PagerDutyReference = {
  __typename?: "skynet_PagerDutyReference";
  html_Url?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  self?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Skynet_PagerDutyScheduledAction = {
  __typename?: "skynet_PagerDutyScheduledAction";
  at?: Maybe<Skynet_PagerDutyInlineModel>;
  to_Urgency?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Skynet_PagerDutyService = {
  __typename?: "skynet_PagerDutyService";
  created_At?: Maybe<GqlDate>;
  last_Incident_Timestamp?: Maybe<GqlDate>;
  acknowledgement_Timeout?: Maybe<Scalars["Int"]>;
  auto_Resolve_Timeout?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  escalation_Policy?: Maybe<Skynet_PagerDutyReference>;
  html_Url?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  integerations?: Maybe<Array<Maybe<Skynet_PagerDutyReference>>>;
  name?: Maybe<Scalars["String"]>;
  pagerDutyIncidentUrgencyRule?: Maybe<Skynet_PagerDutyIncidentUrgencyRule>;
  scheduled_Actions?: Maybe<Array<Maybe<Skynet_PagerDutyScheduledAction>>>;
  self?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
  support_Hours?: Maybe<Skynet_PagerDutySupportHours>;
  teams?: Maybe<Array<Maybe<Skynet_PagerDutyReference>>>;
};

export type Skynet_PagerDutySupportHours = {
  __typename?: "skynet_PagerDutySupportHours";
  days_Of_Week?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  end_Time?: Maybe<Scalars["String"]>;
  start_Time?: Maybe<Scalars["String"]>;
  time_Zone?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Skynet_PagerDutyTeam = {
  __typename?: "skynet_PagerDutyTeam";
  description?: Maybe<Scalars["String"]>;
  html_Url?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  self?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
};

export type Skynet_PagerDutyUser = {
  __typename?: "skynet_PagerDutyUser";
  avatar_Url?: Maybe<Scalars["String"]>;
  color?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  html_Url?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  invitation_Sent?: Maybe<Scalars["String"]>;
  job_Title?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
  self?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
  timeZone?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Skynet_PasswordResponse = {
  __typename?: "skynet_PasswordResponse";
  password?: Maybe<Scalars["String"]>;
};

export type Skynet_PowerShellNetAdapterStatistics = {
  __typename?: "skynet_PowerShellNetAdapterStatistics";
  caption?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  elementName?: Maybe<Scalars["String"]>;
  instanceID?: Maybe<Scalars["String"]>;
  interfaceDescription?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  outboundDiscardedPackets?: Maybe<Scalars["BigInteger"]>;
  outboundPacketErrors?: Maybe<Scalars["BigInteger"]>;
  receivedBroadcastBytes?: Maybe<Scalars["BigInteger"]>;
  receivedBroadcastPackets?: Maybe<Scalars["BigInteger"]>;
  receivedBytes?: Maybe<Scalars["BigInteger"]>;
  receivedDiscardedPackets?: Maybe<Scalars["BigInteger"]>;
  receivedMulticastBytes?: Maybe<Scalars["BigInteger"]>;
  receivedMulticastPackets?: Maybe<Scalars["BigInteger"]>;
  receivedPacketErrors?: Maybe<Scalars["BigInteger"]>;
  receivedUnicastBytes?: Maybe<Scalars["BigInteger"]>;
  receivedUnicastPackets?: Maybe<Scalars["BigInteger"]>;
  sentBroadcastBytes?: Maybe<Scalars["BigInteger"]>;
  sentBroadcastPackets?: Maybe<Scalars["BigInteger"]>;
  sentBytes?: Maybe<Scalars["BigInteger"]>;
  sentMulticastBytes?: Maybe<Scalars["BigInteger"]>;
  sentMulticastPackets?: Maybe<Scalars["BigInteger"]>;
  sentUnicastBytes?: Maybe<Scalars["BigInteger"]>;
  sentUnicastPackets?: Maybe<Scalars["BigInteger"]>;
  source?: Maybe<Scalars["Long"]>;
  supportedStatistics?: Maybe<Scalars["Long"]>;
  systemName?: Maybe<Scalars["String"]>;
};

export type Skynet_PowerShellNetworkAdapter = {
  __typename?: "skynet_PowerShellNetworkAdapter";
  installDate?: Maybe<GqlDate>;
  activeMaximumTransmissionUnit?: Maybe<Scalars["BigInteger"]>;
  adminLocked?: Maybe<Scalars["Boolean"]>;
  autoSense?: Maybe<Scalars["Boolean"]>;
  availability?: Maybe<Scalars["Int"]>;
  caption?: Maybe<Scalars["String"]>;
  componentID?: Maybe<Scalars["String"]>;
  configManagerErrorCode?: Maybe<Scalars["Long"]>;
  configManagerUserConfig?: Maybe<Scalars["Boolean"]>;
  connectorPresent?: Maybe<Scalars["Boolean"]>;
  creationClassName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  deviceID?: Maybe<Scalars["String"]>;
  deviceName?: Maybe<Scalars["String"]>;
  deviceWakeUpEnable?: Maybe<Scalars["Boolean"]>;
  driverDate?: Maybe<Scalars["String"]>;
  driverDateData?: Maybe<Scalars["BigInteger"]>;
  driverDescription?: Maybe<Scalars["String"]>;
  driverMajorNdisVersion?: Maybe<Scalars["Short"]>;
  driverMinorNdisVersion?: Maybe<Scalars["Short"]>;
  driverName?: Maybe<Scalars["String"]>;
  driverProvider?: Maybe<Scalars["String"]>;
  driverVersionString?: Maybe<Scalars["String"]>;
  endPointInterface?: Maybe<Scalars["Boolean"]>;
  errorCleared?: Maybe<Scalars["Boolean"]>;
  errorDescription?: Maybe<Scalars["String"]>;
  fullDuplex?: Maybe<Scalars["Boolean"]>;
  hardwareInterface?: Maybe<Scalars["Boolean"]>;
  hidden?: Maybe<Scalars["Boolean"]>;
  higherLayerInterfaceIndices?: Maybe<Array<Maybe<Scalars["Long"]>>>;
  iSCSIInterface?: Maybe<Scalars["Boolean"]>;
  imFilter?: Maybe<Scalars["Boolean"]>;
  interfaceAdminStatus?: Maybe<Scalars["Long"]>;
  interfaceDescription?: Maybe<Scalars["String"]>;
  interfaceGuid?: Maybe<Scalars["String"]>;
  interfaceIndex?: Maybe<Scalars["Long"]>;
  interfaceName?: Maybe<Scalars["String"]>;
  interfaceOperationalStatus?: Maybe<Scalars["Long"]>;
  interfaceType?: Maybe<Scalars["Long"]>;
  lastErrorCode?: Maybe<Scalars["Long"]>;
  linkTechnology?: Maybe<Scalars["Int"]>;
  lowerLayerInterfaceIndices?: Maybe<Array<Maybe<Scalars["Long"]>>>;
  majorDriverVersion?: Maybe<Scalars["Int"]>;
  maxSpeed?: Maybe<Scalars["BigInteger"]>;
  mediaConnectState?: Maybe<Scalars["Long"]>;
  mediaDuplexState?: Maybe<Scalars["Long"]>;
  minorDriverVersion?: Maybe<Scalars["Int"]>;
  mtuSize?: Maybe<Scalars["Long"]>;
  name?: Maybe<Scalars["String"]>;
  ndisMedium?: Maybe<Scalars["Long"]>;
  ndisPhysicalMedium?: Maybe<Scalars["Long"]>;
  netLuid?: Maybe<Scalars["BigInteger"]>;
  netLuidIndex?: Maybe<Scalars["Long"]>;
  networkAddresses?: Maybe<Array<Maybe<Scalars["String"]>>>;
  notUserRemovable?: Maybe<Scalars["Boolean"]>;
  operationalStatusDownDefaultPortNotAuthenticated?: Maybe<Scalars["Boolean"]>;
  operationalStatusDownInterfacePaused?: Maybe<Scalars["Boolean"]>;
  operationalStatusDownLowPowerState?: Maybe<Scalars["Boolean"]>;
  operationalStatusDownMediaDisconnected?: Maybe<Scalars["Boolean"]>;
  otherLinkTechnology?: Maybe<Scalars["String"]>;
  otherNetworkPortType?: Maybe<Scalars["String"]>;
  otherPortType?: Maybe<Scalars["String"]>;
  permanentAddress?: Maybe<Scalars["String"]>;
  pnPDeviceID?: Maybe<Scalars["String"]>;
  pnpDeviceID?: Maybe<Scalars["String"]>;
  portNumber?: Maybe<Scalars["Int"]>;
  portType?: Maybe<Scalars["Int"]>;
  powerManagementCapabilities?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  powerManagementSupported?: Maybe<Scalars["Boolean"]>;
  promiscuousMode?: Maybe<Scalars["Boolean"]>;
  receiveLinkSpeed?: Maybe<Scalars["BigInteger"]>;
  requestedSpeed?: Maybe<Scalars["BigInteger"]>;
  speed?: Maybe<Scalars["BigInteger"]>;
  state?: Maybe<Scalars["Long"]>;
  status?: Maybe<Scalars["String"]>;
  statusInfo?: Maybe<Scalars["Int"]>;
  supportedMaximumTransmissionUnit?: Maybe<Scalars["BigInteger"]>;
  systemCreationClassName?: Maybe<Scalars["String"]>;
  systemName?: Maybe<Scalars["String"]>;
  transmitLinkSpeed?: Maybe<Scalars["BigInteger"]>;
  usageRestriction?: Maybe<Scalars["Int"]>;
  virtual?: Maybe<Scalars["Boolean"]>;
  vlanID?: Maybe<Scalars["Int"]>;
  wdmInterface?: Maybe<Scalars["Boolean"]>;
};

export type Skynet_Purpose = {
  __typename?: "skynet_Purpose";
  dateSet?: Maybe<Scalars["Int"]>;
  setBy?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
};

export type Skynet_Result = {
  __typename?: "skynet_Result";
  active?: Maybe<Scalars["Boolean"]>;
  approval?: Maybe<Scalars["String"]>;
  approvalDv?: Maybe<Scalars["String"]>;
  approvalHistory?: Maybe<Scalars["String"]>;
  catItem?: Maybe<Scalars["String"]>;
  catItemDv?: Maybe<Scalars["String"]>;
  closedAt?: Maybe<Scalars["String"]>;
  closedBy?: Maybe<Scalars["String"]>;
  correlationDisplay?: Maybe<Scalars["String"]>;
  correlationId?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["String"]>;
  openedAt?: Maybe<Scalars["String"]>;
  openedBy?: Maybe<Scalars["String"]>;
  openedByDv?: Maybe<Scalars["String"]>;
  shortDescription?: Maybe<Scalars["String"]>;
  stage?: Maybe<Scalars["String"]>;
  stageDv?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["Float"]>;
  stateDv?: Maybe<Scalars["String"]>;
  sysCreatedBy?: Maybe<Scalars["String"]>;
  sysCreatedOn?: Maybe<Scalars["String"]>;
  sysId?: Maybe<Scalars["String"]>;
  sysModCount?: Maybe<Scalars["Float"]>;
  sysUpdatedBy?: Maybe<Scalars["String"]>;
  sysUpdatedOn?: Maybe<Scalars["String"]>;
  uCustomer?: Maybe<Scalars["String"]>;
  uCustomerDv?: Maybe<Scalars["String"]>;
  uReportedBy?: Maybe<Scalars["String"]>;
  uReportedByDv?: Maybe<Scalars["String"]>;
  uRequestedBy?: Maybe<Scalars["String"]>;
  uRequestedByDv?: Maybe<Scalars["String"]>;
  uRequestedFor?: Maybe<Scalars["String"]>;
  uRequestedForDv?: Maybe<Scalars["String"]>;
  variables?: Maybe<Skynet_Variables>;
  watchList?: Maybe<Scalars["String"]>;
  wfActivity?: Maybe<Scalars["String"]>;
  wfActivityDv?: Maybe<Scalars["String"]>;
};

export type Skynet_Retention = {
  __typename?: "skynet_Retention";
  duration?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmAddRemove = {
  __typename?: "skynet_SccmAddRemove";
  installDate?: Maybe<GqlDate>;
  application?: Maybe<Scalars["String"]>;
  architecture?: Maybe<Scalars["String"]>;
  publisher?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmApplication = {
  __typename?: "skynet_SccmApplication";
  dateCreated?: Maybe<GqlDate>;
  dateLastModified?: Maybe<GqlDate>;
  description?: Maybe<Scalars["String"]>;
  isSuperseded?: Maybe<Scalars["Boolean"]>;
  isSuperseding?: Maybe<Scalars["Boolean"]>;
  logonRequirement?: Maybe<Scalars["Boolean"]>;
  manufacturer?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  numberOfDevicesWithApp?: Maybe<Scalars["Int"]>;
  numberOfDevicesWithFailure?: Maybe<Scalars["Int"]>;
  numberOfUsersWithApp?: Maybe<Scalars["Int"]>;
  numberOfUsersWithFailure?: Maybe<Scalars["Int"]>;
  numberOfUsersWithRequest?: Maybe<Scalars["Int"]>;
  softwareUrl?: Maybe<Scalars["String"]>;
  softwareVersion?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmAutoruns = {
  __typename?: "skynet_SccmAutoruns";
  time?: Maybe<GqlDate>;
  comapny?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  enabled0?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Long"]>;
  imagePath?: Maybe<Scalars["String"]>;
  impHash?: Maybe<Scalars["String"]>;
  itemName?: Maybe<Scalars["String"]>;
  launchString?: Maybe<Scalars["String"]>;
  mD5Hash?: Maybe<Scalars["String"]>;
  peshA1Hash?: Maybe<Scalars["String"]>;
  peshA256Hash?: Maybe<Scalars["String"]>;
  profile?: Maybe<Scalars["String"]>;
  shA1Hash?: Maybe<Scalars["String"]>;
  shA256Hash?: Maybe<Scalars["String"]>;
  signer?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmAutostart = {
  __typename?: "skynet_SccmAutostart";
  description?: Maybe<Scalars["String"]>;
  fileName?: Maybe<Scalars["String"]>;
  product?: Maybe<Scalars["String"]>;
  productVersion?: Maybe<Scalars["String"]>;
  publisher?: Maybe<Scalars["String"]>;
  startupType?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmBho = {
  __typename?: "skynet_SccmBho";
  description?: Maybe<Scalars["String"]>;
  fileName?: Maybe<Scalars["String"]>;
  product?: Maybe<Scalars["String"]>;
  productVersion?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmBios = {
  __typename?: "skynet_SccmBios";
  biosVersion?: Maybe<Scalars["String"]>;
  manufacturer?: Maybe<Scalars["String"]>;
  serialNumber?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmBloomberg = {
  __typename?: "skynet_SccmBloomberg";
  bafp_chg_version0?: Maybe<Scalars["String"]>;
  bafp_rst_version0?: Maybe<Scalars["String"]>;
  bafp_version0?: Maybe<Scalars["String"]>;
  bbus_20180205_verify_version0?: Maybe<Scalars["String"]>;
  bbus_default_version0?: Maybe<Scalars["String"]>;
  bbus_job_info0?: Maybe<Scalars["String"]>;
  bbus_rst_version0?: Maybe<Scalars["String"]>;
  bbus_version0?: Maybe<Scalars["String"]>;
  bcls_chg_version0?: Maybe<Scalars["String"]>;
  bcls_job_info0?: Maybe<Scalars["String"]>;
  bcls_rst_version0?: Maybe<Scalars["String"]>;
  bcls_version0?: Maybe<Scalars["String"]>;
  bfnt_install_version0?: Maybe<Scalars["String"]>;
  bfnt_rst_version0?: Maybe<Scalars["String"]>;
  bfnt_version0?: Maybe<Scalars["String"]>;
  bkbd_chg_version0?: Maybe<Scalars["String"]>;
  bkbd_job_info0?: Maybe<Scalars["String"]>;
  bkbd_rst_version0?: Maybe<Scalars["String"]>;
  bkbd_version0?: Maybe<Scalars["String"]>;
  bnet_chg_version0?: Maybe<Scalars["String"]>;
  bnet_job_info0?: Maybe<Scalars["String"]>;
  bnet_rst_version0?: Maybe<Scalars["String"]>;
  bnet_version0?: Maybe<Scalars["String"]>;
  bvft_chg_version0?: Maybe<Scalars["String"]>;
  bvft_job_info0?: Maybe<Scalars["String"]>;
  bvft_rst_version0?: Maybe<Scalars["String"]>;
  bvft_version0?: Maybe<Scalars["String"]>;
  bxla_installed_version0?: Maybe<Scalars["String"]>;
  bxla_version0?: Maybe<Scalars["String"]>;
  dnet_version0?: Maybe<Scalars["String"]>;
  gsrv_chg_version0?: Maybe<Scalars["String"]>;
  gsrv_job_info0?: Maybe<Scalars["String"]>;
  gsrv_rst_version0?: Maybe<Scalars["String"]>;
  gsrv_version0?: Maybe<Scalars["String"]>;
  keyName0?: Maybe<Scalars["String"]>;
  ot64_chg_version0?: Maybe<Scalars["String"]>;
  ot64_rst_version0?: Maybe<Scalars["String"]>;
  ot64_version0?: Maybe<Scalars["String"]>;
  slv2_install_version0?: Maybe<Scalars["String"]>;
  slv2_version0?: Maybe<Scalars["String"]>;
  sotr_chg_version0?: Maybe<Scalars["String"]>;
  sotr_job_info0?: Maybe<Scalars["String"]>;
  sotr_rst_version0?: Maybe<Scalars["String"]>;
  sotr_version0?: Maybe<Scalars["String"]>;
  tbbc_chg_version0?: Maybe<Scalars["String"]>;
  tbbc_install_version0?: Maybe<Scalars["String"]>;
  tbbc_job_info0?: Maybe<Scalars["String"]>;
  tbbc_launch0?: Maybe<Scalars["Int"]>;
  tbbc_rst_version0?: Maybe<Scalars["String"]>;
  tbbc_version0?: Maybe<Scalars["String"]>;
  tbft_install_version0?: Maybe<Scalars["String"]>;
  tbft_version0?: Maybe<Scalars["String"]>;
  umgr_chg_version0?: Maybe<Scalars["String"]>;
  umgr_job_info0?: Maybe<Scalars["String"]>;
  umgr_rst_version0?: Maybe<Scalars["String"]>;
  umgr_version0?: Maybe<Scalars["String"]>;
  wbvw_chg_version0?: Maybe<Scalars["String"]>;
  wbvw_job_info0?: Maybe<Scalars["String"]>;
  wbvw_rst_version0?: Maybe<Scalars["String"]>;
  wbvw_version0?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmCollection = {
  __typename?: "skynet_SccmCollection";
  lastChangeTime?: Maybe<GqlDate>;
  lastMemberChangeTime?: Maybe<GqlDate>;
  lastRefreshTime?: Maybe<GqlDate>;
  collectionID?: Maybe<Scalars["String"]>;
  collectionType?: Maybe<SccmCollectionType>;
  comment?: Maybe<Scalars["String"]>;
  currentStatus?: Maybe<SccmCollectionStatus>;
  isBuiltIn?: Maybe<Scalars["Boolean"]>;
  isReferenceCollection?: Maybe<Scalars["Boolean"]>;
  memberCount?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  refreshType?: Maybe<SccmCollectionRefreshType>;
};

export type Skynet_SccmCollectionMember = {
  __typename?: "skynet_SccmCollectionMember";
  amtFullVersion?: Maybe<Scalars["String"]>;
  amtStatus?: Maybe<SccmCollectionMemberAmtStatus>;
  clientType?: Maybe<SccmCollectionMemberClientType>;
  domain?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  isApproved?: Maybe<Scalars["Boolean"]>;
  isAssigned?: Maybe<Scalars["Boolean"]>;
  isBlocked?: Maybe<Scalars["Boolean"]>;
  isClient?: Maybe<Scalars["Boolean"]>;
  isDecommissioned?: Maybe<Scalars["Boolean"]>;
  isDirect?: Maybe<Scalars["Boolean"]>;
  isInternetEnabled?: Maybe<Scalars["Boolean"]>;
  isObsolete?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  resourceID?: Maybe<Scalars["Long"]>;
  resourceType?: Maybe<SccmCollectionMemberResourceType>;
};

export type Skynet_SccmDisk = {
  __typename?: "skynet_SccmDisk";
  description?: Maybe<Scalars["String"]>;
  deviceId?: Maybe<Scalars["String"]>;
  interfaceType?: Maybe<Scalars["String"]>;
  manufacturer?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  partitions?: Maybe<Scalars["Int"]>;
  scsiBus?: Maybe<Scalars["Int"]>;
  sizeInMb?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmLocalGroupMembers = {
  __typename?: "skynet_SccmLocalGroupMembers";
  account?: Maybe<Scalars["String"]>;
  domain?: Maybe<Scalars["String"]>;
  groupName?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmLocalGroupReport = {
  __typename?: "skynet_SccmLocalGroupReport";
  accountName?: Maybe<Scalars["String"]>;
  computerName?: Maybe<Scalars["String"]>;
  domain?: Maybe<Scalars["String"]>;
  groupName?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmLogicalDisk = {
  __typename?: "skynet_SccmLogicalDisk";
  deviceId?: Maybe<Scalars["String"]>;
  freeSpace?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["Int"]>;
  volumeName?: Maybe<Scalars["String"]>;
  volumeSerialNumber?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmMappedDrive = {
  __typename?: "skynet_SccmMappedDrive";
  dateScriptRan?: Maybe<Scalars["String"]>;
  drive?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  userDomain?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmMappedPrinter = {
  __typename?: "skynet_SccmMappedPrinter";
  comment?: Maybe<Scalars["String"]>;
  dateScriptRan?: Maybe<Scalars["String"]>;
  driverName?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  printer?: Maybe<Scalars["String"]>;
  userDomain?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmNetworkAdapter = {
  __typename?: "skynet_SccmNetworkAdapter";
  adapterType?: Maybe<Scalars["String"]>;
  dhcpEnabled?: Maybe<Scalars["Boolean"]>;
  ipAddress?: Maybe<Scalars["String"]>;
  macAddress?: Maybe<Scalars["String"]>;
  manufacturer?: Maybe<Scalars["String"]>;
  productName?: Maybe<Scalars["String"]>;
  serviceName?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmOperatingSystem = {
  __typename?: "skynet_SccmOperatingSystem";
  installDate?: Maybe<GqlDate>;
  lastBootUpTime?: Maybe<GqlDate>;
  osName?: Maybe<Scalars["String"]>;
  physicalMemoryInKb?: Maybe<Scalars["Int"]>;
  servicePack?: Maybe<Scalars["String"]>;
  totalVmSizeInKb?: Maybe<Scalars["Int"]>;
};

export type Skynet_SccmPhysicalDisk = {
  __typename?: "skynet_SccmPhysicalDisk";
  firmwareVersion?: Maybe<Scalars["String"]>;
  friendlyName?: Maybe<Scalars["String"]>;
  logicalSectorSize?: Maybe<Scalars["String"]>;
  manufacturer?: Maybe<Scalars["String"]>;
  mediaType?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  objectId?: Maybe<Scalars["String"]>;
  physicalSectorSize?: Maybe<Scalars["String"]>;
  serialNumber?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["String"]>;
  uniqueId?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmPhysicalMemory = {
  __typename?: "skynet_SccmPhysicalMemory";
  capacity?: Maybe<Scalars["Int"]>;
  deviceLocator?: Maybe<Scalars["String"]>;
  speed?: Maybe<Scalars["Int"]>;
};

export type Skynet_SccmPnpDeviceDriver = {
  __typename?: "skynet_SccmPnpDeviceDriver";
  deviceId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmProcessor = {
  __typename?: "skynet_SccmProcessor";
  deviceId?: Maybe<Scalars["String"]>;
  hyperthreadingCapable?: Maybe<Scalars["Boolean"]>;
  maxClockSpeed?: Maybe<Scalars["Int"]>;
  mobile?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  numberOfCores?: Maybe<Scalars["Int"]>;
  numberOfLogicalProcessors?: Maybe<Scalars["Int"]>;
  trustedExecutionCapable?: Maybe<Scalars["Boolean"]>;
  virtualizationCapable?: Maybe<Scalars["Boolean"]>;
  x64Capable?: Maybe<Scalars["Boolean"]>;
};

export type Skynet_SccmRecentlyUsedApplications = {
  __typename?: "skynet_SccmRecentlyUsedApplications";
  description?: Maybe<Scalars["String"]>;
  fileName?: Maybe<Scalars["String"]>;
  fileVersion?: Maybe<Scalars["String"]>;
  folderPath?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
  utcTime?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmScsiController = {
  __typename?: "skynet_SccmScsiController";
  description?: Maybe<Scalars["String"]>;
  deviceId?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmServerFeature = {
  __typename?: "skynet_SccmServerFeature";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  parentId?: Maybe<Scalars["Int"]>;
};

export type Skynet_SccmSoftware = {
  __typename?: "skynet_SccmSoftware";
  architecture?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmSoftwareUpdate = {
  __typename?: "skynet_SccmSoftwareUpdate";
  installedOn?: Maybe<GqlDate>;
  installedBy?: Maybe<Scalars["String"]>;
  updateId?: Maybe<Scalars["String"]>;
  updateType?: Maybe<Scalars["String"]>;
  updateUrl?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmSystem = {
  __typename?: "skynet_SccmSystem";
  name?: Maybe<Scalars["String"]>;
  resourceId?: Maybe<Scalars["Long"]>;
};

export type Skynet_SccmUsbDevice = {
  __typename?: "skynet_SccmUsbDevice";
  caption?: Maybe<Scalars["String"]>;
  deviceId?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmUserProfile = {
  __typename?: "skynet_SccmUserProfile";
  localPath?: Maybe<Scalars["String"]>;
  roamingConfigured?: Maybe<Scalars["Boolean"]>;
  roamingPath?: Maybe<Scalars["String"]>;
};

export type Skynet_SccmVideoController = {
  __typename?: "skynet_SccmVideoController";
  driverDate?: Maybe<GqlDate>;
  description?: Maybe<Scalars["String"]>;
  driverVersion?: Maybe<Scalars["String"]>;
};

export type Skynet_SensitivityType = {
  __typename?: "skynet_SensitivityType";
  id?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type Skynet_ServerName = {
  __typename?: "skynet_ServerName";
  date?: Maybe<GqlDate>;
  environmentCode?: Maybe<Scalars["String"]>;
  functionCode?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  identifier?: Maybe<Scalars["Short"]>;
  locationCode?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  operatingSystemCode?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

export type Skynet_ServiceNowAdHocTicketResponse = {
  __typename?: "skynet_ServiceNowAdHocTicketResponse";
  error?: Maybe<Skynet_Error>;
  result?: Maybe<Skynet_Result>;
  status?: Maybe<Scalars["String"]>;
};

export type Skynet_ServiceNowAsset = {
  __typename?: "skynet_ServiceNowAsset";
  bios?: Maybe<GqlDate>;
  created?: Maybe<GqlDate>;
  hardScan?: Maybe<GqlDate>;
  updated?: Maybe<GqlDate>;
  assetTag?: Maybe<Scalars["String"]>;
  assignment?: Maybe<Scalars["Int"]>;
  assignmentDesc?: Maybe<Scalars["String"]>;
  biosDescr?: Maybe<Scalars["String"]>;
  cigBhome?: Maybe<Scalars["Int"]>;
  cigBuildingId?: Maybe<Scalars["String"]>;
  cigCategory?: Maybe<Scalars["String"]>;
  cigCoo?: Maybe<Scalars["String"]>;
  cigCooId?: Maybe<Scalars["String"]>;
  cigCoreCount?: Maybe<Scalars["Int"]>;
  cigFunctionHead?: Maybe<Scalars["String"]>;
  cigFunctionHeadId?: Maybe<Scalars["String"]>;
  cigHostId?: Maybe<Scalars["String"]>;
  cigHostName?: Maybe<Scalars["String"]>;
  cigHostType?: Maybe<Scalars["String"]>;
  cigIlo?: Maybe<Scalars["String"]>;
  cigIloIp?: Maybe<Scalars["String"]>;
  cigIloName?: Maybe<Scalars["String"]>;
  cigItDirector?: Maybe<Scalars["String"]>;
  cigItDirectorId?: Maybe<Scalars["String"]>;
  cigMachineStatus?: Maybe<Scalars["String"]>;
  cigMachineTypeName?: Maybe<Scalars["String"]>;
  cigPurpose?: Maybe<Scalars["String"]>;
  cigReportingUnit?: Maybe<Scalars["String"]>;
  cigServer?: Maybe<Scalars["Int"]>;
  cigSubCategory?: Maybe<Scalars["String"]>;
  cigTechContact?: Maybe<Scalars["String"]>;
  cigTechContactId?: Maybe<Scalars["String"]>;
  cigUuid?: Maybe<Scalars["String"]>;
  cigVmCluster?: Maybe<Scalars["String"]>;
  cigVmVirtualCenter?: Maybe<Scalars["String"]>;
  computerType?: Maybe<Scalars["String"]>;
  costCenterCode?: Maybe<Scalars["String"]>;
  costCenterDescr?: Maybe<Scalars["String"]>;
  costTypeCode?: Maybe<Scalars["String"]>;
  costTypeName?: Maybe<Scalars["String"]>;
  cpuInternal?: Maybe<Scalars["String"]>;
  cpuNumber?: Maybe<Scalars["Float"]>;
  cpuSpeedMhz?: Maybe<Scalars["Float"]>;
  cpuType?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  locationBarcode?: Maybe<Scalars["String"]>;
  locationFullName?: Maybe<Scalars["String"]>;
  memorySizeMb?: Maybe<Scalars["Int"]>;
  modelBrand?: Maybe<Scalars["String"]>;
  modelFullName?: Maybe<Scalars["String"]>;
  modelLongDescr?: Maybe<Scalars["String"]>;
  modelName?: Maybe<Scalars["String"]>;
  natureName?: Maybe<Scalars["String"]>;
  operatingSystem?: Maybe<Scalars["String"]>;
  osBuildNumber?: Maybe<Scalars["String"]>;
  parentDeviceAssetTag?: Maybe<Scalars["String"]>;
  parentDeviceCigHostName?: Maybe<Scalars["String"]>;
  parentLocation?: Maybe<Scalars["String"]>;
  parentLocationBarcode?: Maybe<Scalars["String"]>;
  parentModelName?: Maybe<Scalars["String"]>;
  seComputerType?: Maybe<Scalars["Int"]>;
  serialNo?: Maybe<Scalars["String"]>;
  siteBarcode?: Maybe<Scalars["String"]>;
  siteName?: Maybe<Scalars["String"]>;
  slot?: Maybe<Scalars["Int"]>;
  stockName?: Maybe<Scalars["String"]>;
  subFunction?: Maybe<Scalars["String"]>;
  swapSizeMb?: Maybe<Scalars["Int"]>;
  sysId?: Maybe<Scalars["String"]>;
  uSize?: Maybe<Scalars["Int"]>;
  uStart?: Maybe<Scalars["Int"]>;
  updatedBy?: Maybe<Scalars["String"]>;
  updates?: Maybe<Scalars["Int"]>;
  userId?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
  vmType?: Maybe<Scalars["String"]>;
};

export type Skynet_ServiceNowHost = {
  __typename?: "skynet_ServiceNowHost";
  assetTag?: Maybe<Scalars["String"]>;
  billWeight?: Maybe<Scalars["String"]>;
  biosDate?: Maybe<Scalars["String"]>;
  biosDescription?: Maybe<Scalars["String"]>;
  buildingId?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  computerId?: Maybe<Scalars["Int"]>;
  coreCount?: Maybe<Scalars["Int"]>;
  costCenterCode?: Maybe<Scalars["String"]>;
  costCenterDescr?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  cpuInternal?: Maybe<Scalars["String"]>;
  cpuNumber?: Maybe<Scalars["String"]>;
  cpuSpeedMhz?: Maybe<Scalars["Int"]>;
  cpuType?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  diskSizeMb?: Maybe<Scalars["Int"]>;
  dr?: Maybe<Scalars["Int"]>;
  gatewayBuild?: Maybe<Scalars["String"]>;
  hardScan?: Maybe<Scalars["String"]>;
  hostId?: Maybe<Scalars["String"]>;
  hostName?: Maybe<Scalars["String"]>;
  hostType?: Maybe<Scalars["String"]>;
  ilo?: Maybe<Scalars["String"]>;
  iloIp?: Maybe<Scalars["String"]>;
  iloName?: Maybe<Scalars["String"]>;
  inServiceDate?: Maybe<Scalars["String"]>;
  isoCode?: Maybe<Scalars["String"]>;
  itDirector?: Maybe<Scalars["String"]>;
  itDirectorId?: Maybe<Scalars["String"]>;
  lastDateBuild?: Maybe<Scalars["String"]>;
  lastScan?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  locationBarcode?: Maybe<Scalars["String"]>;
  locationFullName?: Maybe<Scalars["String"]>;
  locationName?: Maybe<Scalars["String"]>;
  machineStatus?: Maybe<Scalars["String"]>;
  machineTypeName?: Maybe<Scalars["String"]>;
  memorySizeMb?: Maybe<Scalars["Int"]>;
  modelBarcode?: Maybe<Scalars["String"]>;
  modelBrand?: Maybe<Scalars["String"]>;
  modelFullName?: Maybe<Scalars["String"]>;
  modelLongDescription?: Maybe<Scalars["String"]>;
  modelName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  opUnit?: Maybe<Scalars["String"]>;
  operatingSystem?: Maybe<Scalars["String"]>;
  osBuildNumber?: Maybe<Scalars["String"]>;
  parentAssetTag?: Maybe<Scalars["String"]>;
  parentDeviceAssetTag?: Maybe<Scalars["String"]>;
  parentDeviceHostName?: Maybe<Scalars["String"]>;
  parentDeviceSlot?: Maybe<Scalars["Int"]>;
  parentDeviceUSize?: Maybe<Scalars["Int"]>;
  parentDeviceUStart?: Maybe<Scalars["Int"]>;
  parentHostName?: Maybe<Scalars["String"]>;
  parentLocation?: Maybe<Scalars["String"]>;
  parentLocationBarcode?: Maybe<Scalars["String"]>;
  parentModelFullName?: Maybe<Scalars["String"]>;
  parentModelName?: Maybe<Scalars["String"]>;
  purachseOrderPrice?: Maybe<Scalars["String"]>;
  purchaseOrderComments?: Maybe<Scalars["String"]>;
  purchaseOrderNumber?: Maybe<Scalars["String"]>;
  purchaseOrderReference?: Maybe<Scalars["String"]>;
  purpose?: Maybe<Scalars["String"]>;
  rackSize?: Maybe<Scalars["Int"]>;
  receiveDate?: Maybe<Scalars["String"]>;
  reportingEvent?: Maybe<Scalars["String"]>;
  reportingUnit?: Maybe<Scalars["String"]>;
  requestNum?: Maybe<Scalars["String"]>;
  requester?: Maybe<Scalars["String"]>;
  seatAssignment?: Maybe<Scalars["Int"]>;
  serialNumber?: Maybe<Scalars["String"]>;
  serverTypeBuild?: Maybe<Scalars["String"]>;
  siteBarcode?: Maybe<Scalars["String"]>;
  siteName?: Maybe<Scalars["String"]>;
  slot?: Maybe<Scalars["Int"]>;
  start?: Maybe<Scalars["Int"]>;
  state?: Maybe<Scalars["String"]>;
  subCategory?: Maybe<Scalars["String"]>;
  subFunction?: Maybe<Scalars["String"]>;
  supplierId?: Maybe<Scalars["String"]>;
  supplierName?: Maybe<Scalars["String"]>;
  swapSizeMb?: Maybe<Scalars["Int"]>;
  techContact?: Maybe<Scalars["String"]>;
  techContactId?: Maybe<Scalars["String"]>;
  uSize?: Maybe<Scalars["Int"]>;
  uStart?: Maybe<Scalars["Int"]>;
  uuid?: Maybe<Scalars["String"]>;
  vmCluster?: Maybe<Scalars["String"]>;
  vmVirtualCenter?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};

export type Skynet_ServiceNowUser = {
  __typename?: "skynet_ServiceNowUser";
  active?: Maybe<Scalars["Boolean"]>;
  building?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  employeeNumber?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  jobDescription?: Maybe<Scalars["String"]>;
  jobTitle?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  manager?: Maybe<Scalars["String"]>;
  mobilePhone?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
  vip?: Maybe<Scalars["Boolean"]>;
};

export type Skynet_Shared = {
  __typename?: "skynet_Shared";
  connectedTeamIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  internalTeamIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  sharedTeamIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Skynet_SlackChannel = {
  __typename?: "skynet_SlackChannel";
  archived?: Maybe<Scalars["Boolean"]>;
  creator?: Maybe<Scalars["String"]>;
  externallyShared?: Maybe<Scalars["Boolean"]>;
  global?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
  info?: Maybe<Skynet_SlackConversationInfo>;
  members?: Maybe<Array<Maybe<Scalars["String"]>>>;
  mpim?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  orgShared?: Maybe<Scalars["Boolean"]>;
  purpose?: Maybe<Skynet_Purpose>;
  shared?: Maybe<Scalars["Boolean"]>;
  topic?: Maybe<Skynet_Topic>;
  workspace?: Maybe<Scalars["String"]>;
};

export type Skynet_SlackConversationInfo = {
  __typename?: "skynet_SlackConversationInfo";
  created?: Maybe<Scalars["Int"]>;
  creator?: Maybe<Scalars["String"]>;
  hasGuests?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
  isArchived?: Maybe<Scalars["Boolean"]>;
  isDeleted?: Maybe<Scalars["Boolean"]>;
  isGeneral?: Maybe<Scalars["Boolean"]>;
  isGlobalShared?: Maybe<Scalars["Boolean"]>;
  isIm?: Maybe<Scalars["Boolean"]>;
  isMoved?: Maybe<Scalars["Boolean"]>;
  isMpim?: Maybe<Scalars["Boolean"]>;
  isOrgDefault?: Maybe<Scalars["Boolean"]>;
  isOrgMandatory?: Maybe<Scalars["Boolean"]>;
  isOrgShared?: Maybe<Scalars["Boolean"]>;
  isPrivate?: Maybe<Scalars["Boolean"]>;
  isShared?: Maybe<Scalars["Boolean"]>;
  memberCount?: Maybe<Scalars["Int"]>;
  members?: Maybe<Array<Maybe<Scalars["String"]>>>;
  name?: Maybe<Scalars["String"]>;
  nameNormalized?: Maybe<Scalars["String"]>;
  purpose?: Maybe<Skynet_Purpose>;
  retention?: Maybe<Skynet_Retention>;
  shared?: Maybe<Skynet_Shared>;
  topic?: Maybe<Skynet_Topic>;
};

export type Skynet_SlackFile = {
  __typename?: "skynet_SlackFile";
  created?: Maybe<Scalars["String"]>;
  filetype?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  mimetype?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["String"]>;
};

export type Skynet_SlackMessageResponse = {
  __typename?: "skynet_SlackMessageResponse";
  channel?: Maybe<Scalars["String"]>;
  error?: Maybe<Scalars["String"]>;
  ok?: Maybe<Scalars["Boolean"]>;
  ts?: Maybe<Scalars["String"]>;
};

export type Skynet_SlackProfile = {
  __typename?: "skynet_SlackProfile";
  email?: Maybe<Scalars["String"]>;
};

export type Skynet_SlackUploadResponse = {
  __typename?: "skynet_SlackUploadResponse";
  error?: Maybe<Scalars["String"]>;
  file?: Maybe<Skynet_SlackFile>;
  ok?: Maybe<Scalars["Boolean"]>;
};

export type Skynet_SlackUser = {
  __typename?: "skynet_SlackUser";
  deleted?: Maybe<Scalars["Boolean"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  profile?: Maybe<Skynet_SlackProfile>;
  realName?: Maybe<Scalars["String"]>;
  teams?: Maybe<Array<Maybe<Scalars["String"]>>>;
  updated?: Maybe<Scalars["Int"]>;
};

export type Skynet_SlackWorkspace = {
  __typename?: "skynet_SlackWorkspace";
  domain?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Skynet_SnowflakeSecurityRequest = {
  __typename?: "skynet_SnowflakeSecurityRequest";
  modifiedDate?: Maybe<GqlDate>;
  processTime?: Maybe<GqlDate>;
  requestTime?: Maybe<GqlDate>;
  account?: Maybe<Scalars["String"]>;
  action?: Maybe<Scalars["String"]>;
  approver?: Maybe<Scalars["String"]>;
  approverEmail?: Maybe<Scalars["String"]>;
  authType?: Maybe<Scalars["String"]>;
  databaseList?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  loginName?: Maybe<Scalars["String"]>;
  loginType?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  modelAfterLoginName?: Maybe<Scalars["String"]>;
  modifiedBy?: Maybe<Scalars["String"]>;
  originalRequestId?: Maybe<Scalars["Int"]>;
  requestor?: Maybe<Scalars["String"]>;
  requestorEmail?: Maybe<Scalars["String"]>;
  roleList?: Maybe<Scalars["String"]>;
  schemaList?: Maybe<Scalars["String"]>;
  sourceRequestId?: Maybe<Scalars["String"]>;
  sourceSystem?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Skynet_SolarwindsAvailableIpResponse = {
  __typename?: "skynet_SolarwindsAvailableIpResponse";
  ip?: Maybe<Scalars["String"]>;
};

export type Skynet_SqlLogin = {
  __typename?: "skynet_SqlLogin";
  createDate?: Maybe<GqlDate>;
  dateLastModified?: Maybe<GqlDate>;
  defaultDatabase?: Maybe<Scalars["String"]>;
  denyWindowsLogin?: Maybe<Scalars["Boolean"]>;
  hasAccess?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["Int"]>;
  isDisabled?: Maybe<Scalars["Boolean"]>;
  isSystemObject?: Maybe<Scalars["Boolean"]>;
  loginType?: Maybe<SqlLoginType>;
  name?: Maybe<Scalars["String"]>;
};

export type Skynet_SqlLoginDatabaseMapping = {
  __typename?: "skynet_SqlLoginDatabaseMapping";
  dbName?: Maybe<Scalars["String"]>;
  loginName?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Skynet_SurveyData = {
  __typename?: "skynet_SurveyData";
  assetTag?: Maybe<Scalars["String"]>;
  emt64BitCapable?: Maybe<Scalars["Boolean"]>;
  environment?: Maybe<Scalars["String"]>;
  hyperThreadCapable?: Maybe<Scalars["Boolean"]>;
  hyperThreadEnabled?: Maybe<Scalars["Boolean"]>;
  numberOfCores?: Maybe<Scalars["Short"]>;
  physicalProcessors?: Maybe<Scalars["Short"]>;
  serialNumber?: Maybe<Scalars["String"]>;
  totalMemory?: Maybe<Scalars["Int"]>;
};

export type Skynet_SybaseLogin = {
  __typename?: "skynet_SybaseLogin";
  fullName?: Maybe<Scalars["String"]>;
  login?: Maybe<Scalars["String"]>;
  modify?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["Int"]>;
  suid?: Maybe<Scalars["Int"]>;
};

export type Skynet_SymphonyRoom = {
  __typename?: "skynet_SymphonyRoom";
  id?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  isPublic?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Skynet_SymphonyRoomResponse = {
  __typename?: "skynet_SymphonyRoomResponse";
  failedMembers?: Maybe<Array<Maybe<Scalars["String"]>>>;
  members?: Maybe<Array<Maybe<Scalars["String"]>>>;
  roomId?: Maybe<Scalars["String"]>;
};

export type Skynet_SymphonySuppressionResponse = {
  __typename?: "skynet_SymphonySuppressionResponse";
  messageId?: Maybe<Scalars["String"]>;
  suppressed?: Maybe<Scalars["Boolean"]>;
  suppressionDate?: Maybe<Scalars["Int"]>;
};

export type Skynet_SymphonyUser = {
  __typename?: "skynet_SymphonyUser";
  roles?: Maybe<Array<Maybe<Scalars["String"]>>>;
  userAttributes?: Maybe<Skynet_SymphonyUserAttributes>;
  userSystemInfo?: Maybe<Skynet_SymphonyUserInfo>;
};

export type Skynet_SymphonyUserAttributes = {
  __typename?: "skynet_SymphonyUserAttributes";
  displayName?: Maybe<Scalars["String"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Skynet_SymphonyUserInfo = {
  __typename?: "skynet_SymphonyUserInfo";
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Long"]>;
  id?: Maybe<Scalars["Long"]>;
  lastLoginDate?: Maybe<Scalars["Long"]>;
  lastUpdatedDate?: Maybe<Scalars["Long"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Skynet_Topic = {
  __typename?: "skynet_Topic";
  dateSet?: Maybe<Scalars["Int"]>;
  setBy?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
};

export type Skynet_UcsAddressResponse = {
  __typename?: "skynet_UcsAddressResponse";
  gateway?: Maybe<Scalars["String"]>;
  ipAddress?: Maybe<Scalars["String"]>;
  subnetMask?: Maybe<Scalars["String"]>;
};

export type Skynet_UcsIpAddressPool = {
  __typename?: "skynet_UcsIpAddressPool";
  allowIPOverlap?: Maybe<Scalars["String"]>;
  gatewayIPAddress?: Maybe<Scalars["String"]>;
  policyDescription?: Maybe<Scalars["String"]>;
  policyID?: Maybe<Scalars["Int"]>;
  policyName?: Maybe<Scalars["String"]>;
  scope?: Maybe<Scalars["String"]>;
  staticIPPool?: Maybe<Scalars["String"]>;
  subnetMask?: Maybe<Scalars["String"]>;
  usedBy?: Maybe<Scalars["String"]>;
  vlanid?: Maybe<Scalars["String"]>;
};

export type Skynet_UcsTemplate = {
  __typename?: "skynet_UcsTemplate";
  catalog_ID?: Maybe<Scalars["String"]>;
  catalog_Name?: Maybe<Scalars["String"]>;
  image?: Maybe<Scalars["String"]>;
  os?: Maybe<Scalars["String"]>;
};

export type Skynet_UsbBios = {
  __typename?: "skynet_UsbBios";
  id?: Maybe<Scalars["String"]>;
  rearUsb?: Maybe<Scalars["String"]>;
  usbFront?: Maybe<Scalars["String"]>;
  usbPort?: Maybe<Scalars["String"]>;
  usbProvision?: Maybe<Scalars["String"]>;
  usbRearQuad?: Maybe<Scalars["String"]>;
  usbwake?: Maybe<Scalars["String"]>;
};

export type Skynet_Variables = {
  __typename?: "skynet_Variables";
  approvalType1?: Maybe<Scalars["String"]>;
  approvalType1Dv?: Maybe<Scalars["String"]>;
  approvalType2?: Maybe<Scalars["String"]>;
  approvalType2Dv?: Maybe<Scalars["String"]>;
  approver1?: Maybe<Scalars["String"]>;
  approver1Dv?: Maybe<Scalars["String"]>;
  approver2?: Maybe<Scalars["String"]>;
  approver2Dv?: Maybe<Scalars["String"]>;
  approverGroup1?: Maybe<Scalars["String"]>;
  approverGroup1Dv?: Maybe<Scalars["String"]>;
  approverGroup2?: Maybe<Scalars["String"]>;
  approverGroup2Dv?: Maybe<Scalars["String"]>;
  assignmentGroup?: Maybe<Scalars["String"]>;
  assignmentGroupDv?: Maybe<Scalars["String"]>;
  details?: Maybe<Scalars["String"]>;
  detailsDv?: Maybe<Scalars["String"]>;
  requestedBy?: Maybe<Scalars["String"]>;
  requestedByDv?: Maybe<Scalars["String"]>;
  requestedFor?: Maybe<Scalars["String"]>;
  requestedForDv?: Maybe<Scalars["String"]>;
  requestedItemRecordState?: Maybe<Scalars["String"]>;
  requestedItemRecordStateDv?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  titleDv?: Maybe<Scalars["String"]>;
};

export type Skynet_VaultSecretResponse = {
  __typename?: "skynet_VaultSecretResponse";
  url?: Maybe<Scalars["String"]>;
};

export type Skynet_Virustotal = {
  __typename?: "skynet_Virustotal";
  forcePointThreatSeekerCategory?: Maybe<Scalars["String"]>;
  webutationDomainInfo?: Maybe<Skynet_WebutationDomainInfo>;
};

export type Skynet_WebutationDomainInfo = {
  __typename?: "skynet_WebutationDomainInfo";
  adultContent?: Maybe<Scalars["String"]>;
  safetyScore?: Maybe<Scalars["Int"]>;
  verdict?: Maybe<Scalars["String"]>;
};

export type Skynet_WindowsAcl = {
  __typename?: "skynet_WindowsAcl";
  accessControlType?: Maybe<Scalars["String"]>;
  fileSystemRights?: Maybe<Scalars["String"]>;
  identityReference?: Maybe<Scalars["String"]>;
  inheritanceFlags?: Maybe<Scalars["String"]>;
  isInherited?: Maybe<Scalars["Boolean"]>;
  propagationFlags?: Maybe<Scalars["String"]>;
};

export type Skynet_WindowsComputer = {
  __typename?: "skynet_WindowsComputer";
  lastHeartbeat?: Maybe<GqlDate>;
  bloombergBxlaInstalledVersion?: Maybe<Scalars["String"]>;
  bloombergBxlaVersion?: Maybe<Scalars["String"]>;
  bloombergSotrVersion?: Maybe<Scalars["String"]>;
  build?: Maybe<Scalars["String"]>;
  clientVersion?: Maybe<Scalars["String"]>;
  cpuName?: Maybe<Scalars["String"]>;
  department?: Maybe<Scalars["String"]>;
  departmentNumber?: Maybe<Scalars["String"]>;
  distinguishedName?: Maybe<Scalars["String"]>;
  domain?: Maybe<Scalars["String"]>;
  modelName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  numberOfCoresPerCpu?: Maybe<Scalars["Int"]>;
  numberOfCpus?: Maybe<Scalars["Int"]>;
  numberOfLogicalProcessorsPerCpu?: Maybe<Scalars["Int"]>;
  operatingSystem?: Maybe<Scalars["String"]>;
  serialNumber?: Maybe<Scalars["String"]>;
  totalPhysicalMemory?: Maybe<Scalars["Int"]>;
  vendor?: Maybe<Scalars["String"]>;
};

export type Skynet_WindowsComputerHeartbeat = {
  __typename?: "skynet_WindowsComputerHeartbeat";
  lastHeartBeat?: Maybe<GqlDate>;
  hostname?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
};

export type Skynet_WindowsComputerProcess = {
  __typename?: "skynet_WindowsComputerProcess";
  handleCount?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  processName?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
  workingSet?: Maybe<Scalars["Long"]>;
};

export type Skynet_WindowsComputerProcessDetail = {
  __typename?: "skynet_WindowsComputerProcessDetail";
  startTime?: Maybe<GqlDate>;
  basePriority?: Maybe<Scalars["Int"]>;
  commandLine?: Maybe<Scalars["String"]>;
  company?: Maybe<Scalars["String"]>;
  fileDescription?: Maybe<Scalars["String"]>;
  fileVersion?: Maybe<Scalars["String"]>;
  handle?: Maybe<Scalars["Long"]>;
  handleCount?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  mainModule?: Maybe<Skynet_WindowsComputerProcessModule>;
  mainWindowTitle?: Maybe<Scalars["String"]>;
  maxWorkingSet?: Maybe<Scalars["Long"]>;
  minWorkingSet?: Maybe<Scalars["Long"]>;
  modules?: Maybe<Array<Maybe<Skynet_WindowsComputerProcessModule>>>;
  nonpagedSystemMemorySize?: Maybe<Scalars["Long"]>;
  pagedSystemMemorySize?: Maybe<Scalars["Long"]>;
  path?: Maybe<Scalars["String"]>;
  peakPagedMemorySize?: Maybe<Scalars["Long"]>;
  peakVirtualMemorySzie?: Maybe<Scalars["Long"]>;
  peakWorkingSet?: Maybe<Scalars["Long"]>;
  priorityClass?: Maybe<Scalars["String"]>;
  privateMemorySize?: Maybe<Scalars["Long"]>;
  processName?: Maybe<Scalars["String"]>;
  processorAffinity?: Maybe<Scalars["String"]>;
  product?: Maybe<Scalars["String"]>;
  productVersion?: Maybe<Scalars["String"]>;
  responding?: Maybe<Scalars["Boolean"]>;
  sessionId?: Maybe<Scalars["Int"]>;
  userName?: Maybe<Scalars["String"]>;
  virtualMemorySize?: Maybe<Scalars["Long"]>;
  workingSet?: Maybe<Scalars["Long"]>;
};

export type Skynet_WindowsComputerProcessModule = {
  __typename?: "skynet_WindowsComputerProcessModule";
  fileName?: Maybe<Scalars["String"]>;
  fileVersion?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Skynet_WindowsComputerService = {
  __typename?: "skynet_WindowsComputerService";
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  path?: Maybe<Scalars["String"]>;
  startType?: Maybe<ServiceStartModeValue>;
  status?: Maybe<ServiceStatusValue>;
  userName?: Maybe<Scalars["String"]>;
};

export type Skynet_WindowsComputerServiceDetail = {
  __typename?: "skynet_WindowsComputerServiceDetail";
  dependentServices?: Maybe<Array<Maybe<Skynet_WindowsComputerService>>>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  path?: Maybe<Scalars["String"]>;
  servicesDependendOn?: Maybe<Array<Maybe<Skynet_WindowsComputerService>>>;
  startType?: Maybe<ServiceStartModeValue>;
  status?: Maybe<ServiceStatusValue>;
  userName?: Maybe<Scalars["String"]>;
};

export type Skynet_WindowsComputerSessionInformation = {
  __typename?: "skynet_WindowsComputerSessionInformation";
  applicationName?: Maybe<Scalars["String"]>;
  clientAddress?: Maybe<Scalars["String"]>;
  clientBuildNumber?: Maybe<Scalars["Int"]>;
  clientDirectory?: Maybe<Scalars["String"]>;
  clientDisplay?: Maybe<Scalars["String"]>;
  clientHardwareId?: Maybe<Scalars["Int"]>;
  clientName?: Maybe<Scalars["String"]>;
  clientProductId?: Maybe<Scalars["Int"]>;
  clientProtocolType?: Maybe<Scalars["Int"]>;
  connectState?: Maybe<ConnectionState>;
  domainName?: Maybe<Scalars["String"]>;
  initialProgram?: Maybe<Scalars["String"]>;
  oemId?: Maybe<Scalars["String"]>;
  sessionId?: Maybe<Scalars["Int"]>;
  userName?: Maybe<Scalars["String"]>;
  winStationName?: Maybe<Scalars["String"]>;
  workingDirectory?: Maybe<Scalars["String"]>;
};

export type Skynet_WindowsComputerTerminalServiceSettings = {
  __typename?: "skynet_WindowsComputerTerminalServiceSettings";
  installDate?: Maybe<GqlDate>;
  activeDesktop?: Maybe<Scalars["Boolean"]>;
  allowTsConnections?: Maybe<Scalars["Boolean"]>;
  caption?: Maybe<Scalars["String"]>;
  deleteTempFolders?: Maybe<Scalars["Boolean"]>;
  description?: Maybe<Scalars["String"]>;
  disableForcibleLogoff?: Maybe<Scalars["Boolean"]>;
  enableAutomaticReconection?: Maybe<Scalars["Boolean"]>;
  enableDfss?: Maybe<Scalars["Boolean"]>;
  enableNetworkFss?: Maybe<Scalars["Boolean"]>;
  enableRemoteDesktopMsi?: Maybe<Scalars["Boolean"]>;
  fallbackPrintDriverType?: Maybe<FallbackPrintDriver>;
  getCapabilitiesId?: Maybe<Scalars["Long"]>;
  homeDirectory?: Maybe<Scalars["String"]>;
  licensingDescription?: Maybe<Scalars["String"]>;
  licensingName?: Maybe<Scalars["String"]>;
  licensingType?: Maybe<TerminalServiceLicensingType>;
  limitedUserSessions?: Maybe<Scalars["Boolean"]>;
  logons?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  networkFssCatchAllWeight?: Maybe<Scalars["Long"]>;
  networkFssLocalSystemWeight?: Maybe<Scalars["Long"]>;
  networkFssUserSessionWeight?: Maybe<Scalars["Long"]>;
  policySourceAllowTsConnections?: Maybe<TerminalServicePolicySource>;
  policySourceConfiguredLicenseServers?: Maybe<TerminalServicePolicySource>;
  policySourceDeleteTempFolders?: Maybe<TerminalServicePolicySource>;
  policySourceEnableAutomaticReconnection?: Maybe<TerminalServicePolicySource>;
  policySourceEnableDfss?: Maybe<TerminalServicePolicySource>;
  policySourceEnableRemoteDesktopMsi?: Maybe<TerminalServicePolicySource>;
  policySourceFallbackPrintDriverType?: Maybe<TerminalServicePolicySource>;
  policySourceHomeDirectory?: Maybe<TerminalServicePolicySource>;
  policySourceLicensingType?: Maybe<TerminalServicePolicySource>;
  policySourceProfilePath?: Maybe<TerminalServicePolicySource>;
  policySourceRedirectSmartCards?: Maybe<TerminalServicePolicySource>;
  policySourceSingleSession?: Maybe<TerminalServicePolicySource>;
  policySourceTimeZoneRedirection?: Maybe<TerminalServicePolicySource>;
  policySourceUseRdEasyPrintDriver?: Maybe<TerminalServicePolicySource>;
  policySourceUseTempFolders?: Maybe<TerminalServicePolicySource>;
  possibleLicensingTypes?: Maybe<Scalars["String"]>;
  profilePath?: Maybe<Scalars["String"]>;
  redirectSmartCards?: Maybe<Scalars["Boolean"]>;
  serverName?: Maybe<Scalars["String"]>;
  sessionBrokerDrainMode?: Maybe<TerminalServiceDrainMode>;
  singleSessison?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<Scalars["String"]>;
  terminalServerMode?: Maybe<Scalars["Boolean"]>;
  timeZoneRedirection?: Maybe<Scalars["Boolean"]>;
  useRdEasyPrintDriver?: Maybe<Scalars["Boolean"]>;
  useTempFolders?: Maybe<Scalars["Boolean"]>;
  userPermission?: Maybe<Scalars["Boolean"]>;
};

export type Skynet_WindowsConferenceRoom = {
  __typename?: "skynet_WindowsConferenceRoom";
  capacity?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
};

export type Skynet_WindowsDynamicGroup = {
  __typename?: "skynet_WindowsDynamicGroup";
  displayName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  filter?: Maybe<Scalars["String"]>;
  guid?: Maybe<Scalars["String"]>;
  managedBy?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  recipientType?: Maybe<Scalars["String"]>;
};

export type Skynet_WindowsGroup = {
  __typename?: "skynet_WindowsGroup";
  creationDate?: Maybe<GqlDate>;
  lastUpdated?: Maybe<GqlDate>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  distinguishedName?: Maybe<Scalars["String"]>;
  dynamic?: Maybe<Scalars["Boolean"]>;
  email?: Maybe<Scalars["String"]>;
  managedBy?: Maybe<Scalars["String"]>;
  managementAccount?: Maybe<Scalars["String"]>;
  members?: Maybe<Array<Maybe<Scalars["String"]>>>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  objectGuid?: Maybe<Scalars["String"]>;
  outlookDisplayName?: Maybe<Scalars["String"]>;
  proxyAddresses?: Maybe<Array<Maybe<Scalars["String"]>>>;
  sid?: Maybe<Scalars["String"]>;
  type?: Maybe<GroupType>;
};

export type Skynet_WindowsGroupMember = {
  __typename?: "skynet_WindowsGroupMember";
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<MemberType>;
};

export type Skynet_WindowsLocalGroup = {
  __typename?: "skynet_WindowsLocalGroup";
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Skynet_WindowsManagedServiceAccount = {
  __typename?: "skynet_WindowsManagedServiceAccount";
  distinguishedName?: Maybe<Scalars["String"]>;
  passwordAccess?: Maybe<Array<Maybe<Scalars["String"]>>>;
  passwordInterval?: Maybe<Scalars["Int"]>;
  principals?: Maybe<Array<Maybe<Scalars["String"]>>>;
  userAccountControl?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type Skynet_WindowsObjectChangeEvent = {
  __typename?: "skynet_WindowsObjectChangeEvent";
  timeDetected?: Maybe<GqlDate>;
  actionName?: Maybe<Scalars["String"]>;
  eventClassName?: Maybe<Scalars["String"]>;
  serverName?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
  valueNew?: Maybe<Scalars["String"]>;
  valueOld?: Maybe<Scalars["String"]>;
};

export type Skynet_WindowsPerformanceMetric = {
  __typename?: "skynet_WindowsPerformanceMetric";
  metricCategory?: Maybe<Scalars["String"]>;
  metricName?: Maybe<Scalars["String"]>;
  metricValue?: Maybe<Scalars["Float"]>;
};

export type Skynet_WindowsUser = {
  __typename?: "skynet_WindowsUser";
  creationDate?: Maybe<GqlDate>;
  lastDateWorked?: Maybe<GqlDate>;
  lastLogon?: Maybe<GqlDate>;
  lastUpdated?: Maybe<GqlDate>;
  passwordLastSet?: Maybe<GqlDate>;
  adjustedTenure?: Maybe<Scalars["Int"]>;
  badgeId?: Maybe<Scalars["String"]>;
  company?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  department?: Maybe<Scalars["String"]>;
  departmentDescription?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  disabled?: Maybe<Scalars["Boolean"]>;
  displayName?: Maybe<Scalars["String"]>;
  distinguishedName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  emailRestrictionGroup?: Maybe<Scalars["String"]>;
  employeeId?: Maybe<Scalars["String"]>;
  employeeType?: Maybe<Scalars["String"]>;
  entityName?: Maybe<Scalars["String"]>;
  faxNumber?: Maybe<Scalars["String"]>;
  firewallGroup?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  hideFromAddressLists?: Maybe<Scalars["Boolean"]>;
  homePhone?: Maybe<Scalars["String"]>;
  horizonAccessGroup?: Maybe<Scalars["String"]>;
  hrBusinessUnit?: Maybe<Scalars["String"]>;
  initials?: Maybe<Scalars["String"]>;
  intern?: Maybe<Scalars["Boolean"]>;
  ipPhone?: Maybe<Scalars["String"]>;
  jobCode?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  lockedOut?: Maybe<Scalars["Boolean"]>;
  logonRestricted?: Maybe<Scalars["Boolean"]>;
  manager?: Maybe<Scalars["String"]>;
  managerEmployeeId?: Maybe<Scalars["String"]>;
  managerUserName?: Maybe<Scalars["String"]>;
  middleName?: Maybe<Scalars["String"]>;
  mobilePhone?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  objectGuid?: Maybe<Scalars["String"]>;
  officeLocation?: Maybe<Scalars["String"]>;
  owner?: Maybe<Scalars["String"]>;
  personalMobile?: Maybe<Scalars["String"]>;
  proxyAddresses?: Maybe<Array<Maybe<Scalars["String"]>>>;
  sid?: Maybe<Scalars["String"]>;
  sipAddress?: Maybe<Scalars["String"]>;
  userAccountControl?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
  userPrincipalName?: Maybe<Scalars["String"]>;
  userProfilePath?: Maybe<Scalars["String"]>;
  userType?: Maybe<Scalars["String"]>;
  voiceRecordingIndicator?: Maybe<Scalars["String"]>;
  workPhone?: Maybe<Scalars["String"]>;
};

export type Skynet_WindowsUserComputer = {
  __typename?: "skynet_WindowsUserComputer";
  lastHeartbeat?: Maybe<GqlDate>;
  clientVersion?: Maybe<Scalars["String"]>;
  cpuName?: Maybe<Scalars["String"]>;
  department?: Maybe<Scalars["String"]>;
  departmentNumber?: Maybe<Scalars["String"]>;
  distinguishedName?: Maybe<Scalars["String"]>;
  domain?: Maybe<Scalars["String"]>;
  modelName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  numberOfCoresPerCpu?: Maybe<Scalars["Int"]>;
  numberOfCpus?: Maybe<Scalars["Int"]>;
  numberOfLogicalProcessorsPerCpu?: Maybe<Scalars["Int"]>;
  operatingSystem?: Maybe<Scalars["String"]>;
  serialNumber?: Maybe<Scalars["String"]>;
  totalPhysicalMemory?: Maybe<Scalars["Int"]>;
  userName?: Maybe<Scalars["String"]>;
  vendor?: Maybe<Scalars["String"]>;
};

export type Skynet_WindowsUserEasDevice = {
  __typename?: "skynet_WindowsUserEASDevice";
  firstSyncTime?: Maybe<GqlDate>;
  whenChanged?: Maybe<GqlDate>;
  whenChangedUTC?: Maybe<GqlDate>;
  whenCreated?: Maybe<GqlDate>;
  whenCreatedUTC?: Maybe<GqlDate>;
  adminDisplayName?: Maybe<Scalars["String"]>;
  clientType?: Maybe<Scalars["String"]>;
  clientVersion?: Maybe<Scalars["String"]>;
  deviceAccessState?: Maybe<Scalars["String"]>;
  deviceAccessStateReason?: Maybe<Scalars["String"]>;
  deviceId?: Maybe<Scalars["String"]>;
  deviceImei?: Maybe<Scalars["String"]>;
  deviceMobileOperator?: Maybe<Scalars["String"]>;
  deviceModel?: Maybe<Scalars["String"]>;
  deviceOS?: Maybe<Scalars["String"]>;
  deviceOSLanguage?: Maybe<Scalars["String"]>;
  deviceTelephoneNumber?: Maybe<Scalars["String"]>;
  deviceType?: Maybe<Scalars["String"]>;
  deviceUserAgent?: Maybe<Scalars["String"]>;
  distinguishedName?: Maybe<Scalars["String"]>;
  friendlyName?: Maybe<Scalars["String"]>;
  isCompliant?: Maybe<Scalars["Boolean"]>;
  isDisabled?: Maybe<Scalars["Boolean"]>;
  isManaged?: Maybe<Scalars["Boolean"]>;
  isValid?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  objectState?: Maybe<Scalars["String"]>;
  originatingServer?: Maybe<Scalars["String"]>;
  userDisplayName?: Maybe<Scalars["String"]>;
};

export type Skynet_WindowsUserGatewaySession = {
  __typename?: "skynet_WindowsUserGatewaySession";
  connectedTime?: Maybe<GqlDate>;
  connectedComputer?: Maybe<Scalars["String"]>;
  connectedDuration?: Maybe<Scalars["String"]>;
  connectionKey?: Maybe<Scalars["String"]>;
  gatewayServer?: Maybe<Scalars["String"]>;
  idleTime?: Maybe<Scalars["String"]>;
  numberOfKilobytesReceived?: Maybe<Scalars["BigInteger"]>;
  numberOfKilobytesSent?: Maybe<Scalars["BigInteger"]>;
  transportProtocol?: Maybe<GatewayTransportProtocol>;
};

export type Skynet_WindowsUserGroup = {
  __typename?: "skynet_WindowsUserGroup";
  description?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  objectGuid?: Maybe<Scalars["String"]>;
};

export type Skynet_WindowsUserLocalGroupResponse = {
  __typename?: "skynet_WindowsUserLocalGroupResponse";
  computerName?: Maybe<Scalars["String"]>;
  groups?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Skynet_WindowsUserLoginSession = {
  __typename?: "skynet_WindowsUserLoginSession";
  loginDate?: Maybe<GqlDate>;
  computerName?: Maybe<Scalars["String"]>;
  logonType?: Maybe<LogonTypeValue>;
  userName?: Maybe<Scalars["String"]>;
};

export type Skynet_WindowsUserPrincipal = {
  __typename?: "skynet_WindowsUserPrincipal";
  principal?: Maybe<Scalars["String"]>;
};

export type Skynet_WorkdayCompany = {
  __typename?: "skynet_WorkdayCompany";
  company?: Maybe<Scalars["String"]>;
  companyName?: Maybe<Scalars["String"]>;
  entityType?: Maybe<Scalars["String"]>;
  glbu?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Skynet_WorkdayCorporateSensitivity = {
  __typename?: "skynet_WorkdayCorporateSensitivity";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  types?: Maybe<Array<Maybe<Skynet_SensitivityType>>>;
};

export type Skynet_WorkdayDepartment = {
  __typename?: "skynet_WorkdayDepartment";
  effectiveDate?: Maybe<GqlDate>;
  active?: Maybe<Scalars["Boolean"]>;
  business?: Maybe<Scalars["String"]>;
  businessCode?: Maybe<Scalars["String"]>;
  departmentBusinessUnit?: Maybe<Scalars["String"]>;
  departmentCooEmployeeId?: Maybe<Scalars["String"]>;
  departmentCooName?: Maybe<Scalars["String"]>;
  departmentCooPreferredName?: Maybe<Scalars["String"]>;
  departmentFunction?: Maybe<Scalars["String"]>;
  departmentId?: Maybe<Scalars["String"]>;
  departmentItManagerEmployeeId?: Maybe<Scalars["String"]>;
  departmentItManagerName?: Maybe<Scalars["String"]>;
  departmentItManagerPreferredName?: Maybe<Scalars["String"]>;
  departmentName?: Maybe<Scalars["String"]>;
  departmentSubFunction?: Maybe<Scalars["String"]>;
  entityId?: Maybe<Scalars["String"]>;
  entityName?: Maybe<Scalars["String"]>;
  functionHeadEmployeeId?: Maybe<Scalars["String"]>;
  functionHeadName?: Maybe<Scalars["String"]>;
  functionHeadPreferredName?: Maybe<Scalars["String"]>;
  workdayId?: Maybe<Scalars["String"]>;
};

export type Skynet_WorkdayFutureTransfer = {
  __typename?: "skynet_WorkdayFutureTransfer";
  actionEvent?: Maybe<Scalars["String"]>;
  companyAfterEvent?: Maybe<Scalars["String"]>;
  companyBeforeEvent?: Maybe<Scalars["String"]>;
  companyCodeAfterEvent?: Maybe<Scalars["String"]>;
  companyCodeBeforeEvent?: Maybe<Scalars["String"]>;
  costCenterAfterEvent?: Maybe<Scalars["String"]>;
  costCenterBeforeEvent?: Maybe<Scalars["String"]>;
  costCenterCodeAfterEvent?: Maybe<Scalars["String"]>;
  costCenterCodeBeforeEvent?: Maybe<Scalars["String"]>;
  deptBUAfterEvent?: Maybe<Scalars["String"]>;
  deptBUBeforeEvent?: Maybe<Scalars["String"]>;
  effectiveDate?: Maybe<Scalars["String"]>;
  emplid?: Maybe<Scalars["String"]>;
  entityAfterEvent?: Maybe<Scalars["String"]>;
  entityBeforeEvent?: Maybe<Scalars["String"]>;
  functionAfterEvent?: Maybe<Scalars["String"]>;
  functionBeforeEvent?: Maybe<Scalars["String"]>;
  functionCatCodeAfterEvent?: Maybe<Scalars["String"]>;
  functionCatCodeBeforeEvent?: Maybe<Scalars["String"]>;
  functionCatNameAfterEvent?: Maybe<Scalars["String"]>;
  functionCatNameBeforeEvent?: Maybe<Scalars["String"]>;
  functionCodeAfterEvent?: Maybe<Scalars["String"]>;
  functionCodeBeforeEvent?: Maybe<Scalars["String"]>;
  locationAfterEvent?: Maybe<Scalars["String"]>;
  locationBeforeEvent?: Maybe<Scalars["String"]>;
  managerAfterEvent?: Maybe<Scalars["String"]>;
  managerBeforeEvent?: Maybe<Scalars["String"]>;
  managerIDAfterEvent?: Maybe<Scalars["String"]>;
  managerIDBeforeEvent?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  transactionStatus?: Maybe<Scalars["String"]>;
};

export type Skynet_WorkdayJobFamilyAndParents = {
  __typename?: "skynet_WorkdayJobFamilyAndParents";
  jobFamily?: Maybe<Scalars["String"]>;
  jobFamilyGroup?: Maybe<Scalars["String"]>;
  jobFamilyGroupSummary?: Maybe<Scalars["String"]>;
  jobFamilyGroupWorkdayId?: Maybe<Scalars["String"]>;
  jobFamilySummary?: Maybe<Scalars["String"]>;
  jobFamilyWorkdayId?: Maybe<Scalars["String"]>;
  jobProfileName?: Maybe<Scalars["String"]>;
  jobProfileWorkdayId?: Maybe<Scalars["String"]>;
  lastRefreshedOn?: Maybe<Scalars["String"]>;
};

export type Skynet_WorkdayLocation = {
  __typename?: "skynet_WorkdayLocation";
  active?: Maybe<Scalars["Boolean"]>;
  addressLine1?: Maybe<Scalars["String"]>;
  addressLine2?: Maybe<Scalars["String"]>;
  addressLine3?: Maybe<Scalars["String"]>;
  addressLine4?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["String"]>;
  locationName?: Maybe<Scalars["String"]>;
  peopleSoftLocationId?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  stateDescription?: Maybe<Scalars["String"]>;
  workdayId?: Maybe<Scalars["String"]>;
};

export type Skynet_WorkdayPerson = {
  __typename?: "skynet_WorkdayPerson";
  hireDate?: Maybe<GqlDate>;
  homeAddressEffectiveDate?: Maybe<GqlDate>;
  jobEffectiveDate?: Maybe<GqlDate>;
  lastDateWorked?: Maybe<GqlDate>;
  terminationDate?: Maybe<GqlDate>;
  business?: Maybe<Scalars["String"]>;
  businessTitle?: Maybe<Scalars["String"]>;
  businessUnit?: Maybe<Scalars["String"]>;
  businessUnitDescription?: Maybe<Scalars["String"]>;
  citadelVoiceRecordingIndicator?: Maybe<Scalars["String"]>;
  company?: Maybe<Scalars["String"]>;
  companyDescription?: Maybe<Scalars["String"]>;
  contractEndDate?: Maybe<Scalars["String"]>;
  department?: Maybe<Scalars["String"]>;
  departmentFunction?: Maybe<Scalars["String"]>;
  departmentId?: Maybe<Scalars["String"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  emailRestrictionGroup?: Maybe<Scalars["String"]>;
  employeeId?: Maybe<Scalars["String"]>;
  employeeStatus?: Maybe<Scalars["String"]>;
  employeeTypeDescription?: Maybe<Scalars["String"]>;
  employeeTypeId?: Maybe<Scalars["String"]>;
  entityTypeName?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  fullPartTime?: Maybe<Scalars["String"]>;
  functionId?: Maybe<Scalars["String"]>;
  headcountClass?: Maybe<Scalars["String"]>;
  homeAddressEffectiveStatus?: Maybe<Scalars["String"]>;
  homeAddressLine1?: Maybe<Scalars["String"]>;
  homeAddressLine2?: Maybe<Scalars["String"]>;
  homeAddressLine3?: Maybe<Scalars["String"]>;
  homeAddressLine4?: Maybe<Scalars["String"]>;
  homeCity?: Maybe<Scalars["String"]>;
  homeCountry?: Maybe<Scalars["String"]>;
  homePostalCode?: Maybe<Scalars["String"]>;
  homeState?: Maybe<Scalars["String"]>;
  horizonAccessGroup?: Maybe<Scalars["String"]>;
  jobAction?: Maybe<Scalars["String"]>;
  jobCodeDescription?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  locationDescription?: Maybe<Scalars["String"]>;
  managerId?: Maybe<Scalars["String"]>;
  middleName?: Maybe<Scalars["String"]>;
  miisguid?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  networkStatus?: Maybe<Scalars["String"]>;
  nonEmployeeHasBadge?: Maybe<Scalars["String"]>;
  nonEmployeeHasNetworkAccess?: Maybe<Scalars["String"]>;
  nonEmployeeSiteClassification?: Maybe<Scalars["String"]>;
  nonEmployeeVendorName?: Maybe<Scalars["String"]>;
  otherEmail?: Maybe<Scalars["String"]>;
  personStatus?: Maybe<Scalars["String"]>;
  personType?: Maybe<Scalars["String"]>;
  phones?: Maybe<Array<Maybe<Skynet_WorkdayPhone>>>;
  preferredFirstName?: Maybe<Scalars["String"]>;
  preferredLastName?: Maybe<Scalars["String"]>;
  preferredMiddleName?: Maybe<Scalars["String"]>;
  program?: Maybe<Scalars["String"]>;
  programYear?: Maybe<Scalars["String"]>;
  regularTemporary?: Maybe<Scalars["String"]>;
  regulatoryRegion?: Maybe<Scalars["String"]>;
  rehireDate?: Maybe<Scalars["String"]>;
  rowCategory?: Maybe<Scalars["String"]>;
  standardHours?: Maybe<Scalars["Float"]>;
  tenure?: Maybe<Scalars["Int"]>;
  totalEmploymentTenureMonths?: Maybe<Scalars["Int"]>;
  userName?: Maybe<Scalars["String"]>;
  workdayId?: Maybe<Scalars["String"]>;
};

export type Skynet_WorkdayPhone = {
  __typename?: "skynet_WorkdayPhone";
  countryCode?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  phoneTypeCode?: Maybe<Scalars["String"]>;
  phoneTypeDescription?: Maybe<Scalars["String"]>;
};

export type Skynet_WorkdayQuarterlyRoleDescription = {
  __typename?: "skynet_WorkdayQuarterlyRoleDescription";
  comment0?: Maybe<Scalars["String"]>;
  comment1?: Maybe<Scalars["String"]>;
  comment2?: Maybe<Scalars["String"]>;
  comment3?: Maybe<Scalars["String"]>;
  comment4?: Maybe<Scalars["String"]>;
  comment5?: Maybe<Scalars["String"]>;
  comment6?: Maybe<Scalars["String"]>;
  comment7?: Maybe<Scalars["String"]>;
  comment8?: Maybe<Scalars["String"]>;
  company?: Maybe<Scalars["String"]>;
  companyDescription?: Maybe<Scalars["String"]>;
  department?: Maybe<Scalars["String"]>;
  departmentId?: Maybe<Scalars["String"]>;
  employeeId?: Maybe<Scalars["String"]>;
  employmentStatus?: Maybe<Scalars["String"]>;
  employmentType?: Maybe<Scalars["String"]>;
  employmentTypeDescription?: Maybe<Scalars["String"]>;
  employmentTypeId?: Maybe<Scalars["String"]>;
  evaluatedByManager?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  lastRefreshedOn?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  locationDescription?: Maybe<Scalars["String"]>;
  managerId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  preferredFirstName?: Maybe<Scalars["String"]>;
  preferredLastName?: Maybe<Scalars["String"]>;
  preferredName?: Maybe<Scalars["String"]>;
  question0?: Maybe<Scalars["String"]>;
  question1?: Maybe<Scalars["String"]>;
  question2?: Maybe<Scalars["String"]>;
  question3?: Maybe<Scalars["String"]>;
  question4?: Maybe<Scalars["String"]>;
  question5?: Maybe<Scalars["String"]>;
  question6?: Maybe<Scalars["String"]>;
  question7?: Maybe<Scalars["String"]>;
  question8?: Maybe<Scalars["String"]>;
  reviewPeriodEndDate?: Maybe<Scalars["String"]>;
  reviewPeriodStartDate?: Maybe<Scalars["String"]>;
  reviewSectionsGroup?: Maybe<Scalars["String"]>;
  templateName?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
  workdayId?: Maybe<Scalars["String"]>;
};

export type Skynet_WorkdayUpdate = {
  __typename?: "skynet_WorkdayUpdate";
  email?: Maybe<Scalars["String"]>;
  emailRestrictionGroup?: Maybe<Scalars["String"]>;
  employeeId?: Maybe<Scalars["String"]>;
  horizonAccessGroup?: Maybe<Scalars["String"]>;
  officeLocation?: Maybe<Scalars["String"]>;
  serviceNowPersonId?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
  workdayPersonId?: Maybe<Scalars["String"]>;
};

export type Skynet_Workgroup = {
  __typename?: "skynet_Workgroup";
  clientcachelastchangetime?: Maybe<GqlDate>;
  lastchangetime?: Maybe<GqlDate>;
  lastusermodifiedtime?: Maybe<GqlDate>;
  allagents?: Maybe<Scalars["String"]>;
  allowmemberrunusers?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  groups?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  ownerid?: Maybe<Scalars["String"]>;
  ownername?: Maybe<Scalars["String"]>;
  users?: Maybe<Scalars["String"]>;
};

export type Skynet_ZabbixGroup = {
  __typename?: "skynet_ZabbixGroup";
  groupId?: Maybe<Scalars["String"]>;
  guiAccess?: Maybe<ZabbixGroupGuiAccess>;
  name?: Maybe<Scalars["String"]>;
  userStatus?: Maybe<ZabbixGroupStatus>;
};

export type Skynet_ZabbixHost = {
  __typename?: "skynet_ZabbixHost";
  maintenanceFrom?: Maybe<GqlDate>;
  available?: Maybe<ZabbixHostAvailability>;
  description?: Maybe<Scalars["String"]>;
  error?: Maybe<Scalars["String"]>;
  friendlyName?: Maybe<Scalars["String"]>;
  hostId?: Maybe<Scalars["Int"]>;
  maintenanceId?: Maybe<Scalars["Int"]>;
  maintenanceStatus?: Maybe<ZabbixHostMaintenanceStatus>;
  name?: Maybe<Scalars["String"]>;
  proxyHostId?: Maybe<Scalars["Int"]>;
  status?: Maybe<ZabbixHostStatus>;
};

export type Skynet_ZabbixHostGroup = {
  __typename?: "skynet_ZabbixHostGroup";
  flags?: Maybe<ZabbixHostGroupFlags>;
  groupId?: Maybe<Scalars["String"]>;
  internal?: Maybe<ZabbixHostGroupInternal>;
  name?: Maybe<Scalars["String"]>;
};

export type Skynet_ZabbixMaintenance = {
  __typename?: "skynet_ZabbixMaintenance";
  activeSince?: Maybe<GqlDate>;
  activeTill?: Maybe<GqlDate>;
  collectData?: Maybe<Scalars["Boolean"]>;
  description?: Maybe<Scalars["String"]>;
  maintenanceId?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Skynet_ZabbixTrigger = {
  __typename?: "skynet_ZabbixTrigger";
  lastChange?: Maybe<GqlDate>;
  comments?: Maybe<Scalars["String"]>;
  correlationMode?: Maybe<ZabbixTriggerCorrelationMode>;
  correlationTag?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  error?: Maybe<Scalars["String"]>;
  expression?: Maybe<Scalars["String"]>;
  flags?: Maybe<ZabbixTriggerFlags>;
  manualClose?: Maybe<ZabbixTriggerManualClose>;
  priority?: Maybe<ZabbixTriggerPriority>;
  recoveryExpression?: Maybe<Scalars["String"]>;
  recoveryMode?: Maybe<ZabbixTriggerRecoveryMode>;
  state?: Maybe<ZabbixTriggerState>;
  status?: Maybe<ZabbixTriggerStatus>;
  templateId?: Maybe<Scalars["Int"]>;
  triggerId?: Maybe<Scalars["Int"]>;
  type?: Maybe<ZabbixTriggerType>;
  url?: Maybe<Scalars["String"]>;
  value?: Maybe<ZabbixTriggerValue>;
};

export type Skynet_ZabbixUser = {
  __typename?: "skynet_ZabbixUser";
  attemptClock?: Maybe<GqlDate>;
  alias?: Maybe<Scalars["String"]>;
  attemptFailed?: Maybe<Scalars["Int"]>;
  attemptIp?: Maybe<Scalars["String"]>;
  autoLogout?: Maybe<Scalars["String"]>;
  autologin?: Maybe<ZabbixAutoLogin>;
  lang?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  refresh?: Maybe<Scalars["String"]>;
  rowsPerPage?: Maybe<Scalars["Int"]>;
  surname?: Maybe<Scalars["String"]>;
  theme?: Maybe<Scalars["String"]>;
  type?: Maybe<ZabbixUserType>;
  url?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type SearchInstrumentQueryVariables = Exact<{
  searchText?: Maybe<Scalars["String"]>;
  maxSearchResult?: Maybe<Scalars["Int"]>;
  financialTypes?: Maybe<Array<Maybe<Scalars["Int"]>> | Maybe<Scalars["Int"]>>;
}>;

export type SearchInstrumentQuery = { __typename?: "Query" } & {
  cdx_instrument: { __typename?: "Results" } & {
    searchResults?: Maybe<
      Array<
        Maybe<
          { __typename?: "SearchResult" } & Pick<
            SearchResult,
            | "bloombergId"
            | "country"
            | "cusip"
            | "description"
            | "financialType"
            | "financialTypeName"
            | "id"
            | "isin"
            | "issuer"
            | "mSymbol"
            | "ticker"
          >
        >
      >
    >;
  };
};

export type SearchUserQueryVariables = Exact<{
  filter: Scalars["String"];
}>;

export type SearchUserQuery = { __typename?: "Query" } & {
  core_ldapUsers: Array<
    { __typename?: "core_LdapUser" } & Pick<
      Core_LdapUser,
      | "mail"
      | "description"
      | "sAMAccountName"
      | "name"
      | "ipPhone"
      | "telephoneNumber"
      | "sn"
      | "givenName"
      | "department"
      | "departmentNumber"
      | "displayName"
      | "company"
    >
  >;
};

export type GetUserQueryVariables = Exact<{
  filter: Scalars["String"];
}>;

export type GetUserQuery = { __typename?: "Query" } & {
  core_ldapUsers: Array<
    { __typename?: "core_LdapUser" } & Pick<
      Core_LdapUser,
      | "mail"
      | "description"
      | "sAMAccountName"
      | "name"
      | "ipPhone"
      | "telephoneNumber"
      | "sn"
      | "givenName"
      | "department"
      | "departmentNumber"
      | "displayName"
      | "company"
    >
  >;
};

export type SearchWindowsGroupsQueryVariables = Exact<{
  name: Scalars["String"];
}>;

export type SearchWindowsGroupsQuery = { __typename?: "Query" } & {
  skynet_SearchWindowsGroups?: Maybe<
    { __typename?: "WindowsGroupQueryResponse" } & {
      windowsGroups?: Maybe<
        Array<
          Maybe<
            { __typename?: "skynet_WindowsGroup" } & Pick<
              Skynet_WindowsGroup,
              "name" | "email" | "displayName" | "type" | "sid"
            >
          >
        >
      >;
    }
  >;
};

export type FindWindowsGroupsQueryVariables = Exact<{
  name: Scalars["String"];
}>;

export type FindWindowsGroupsQuery = { __typename?: "Query" } & {
  cdx_findGroupsFuzzy?: Maybe<
    Array<Maybe<{ __typename?: "Group" } & Pick<Group, "name" | "email" | "displayName" | "sid">>>
  >;
};

export type GetFinancialTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetFinancialTypesQuery = { __typename?: "Query" } & {
  cdx_financialTypes?: Maybe<Array<Maybe<{ __typename?: "FinancialType" } & Pick<FinancialType, "id" | "name">>>>;
};

export type GetSkynetUserQueryVariables = Exact<{
  username: Scalars["String"];
}>;

export type GetSkynetUserQuery = { __typename?: "Query" } & {
  skynet_GetWindowsUser?: Maybe<
    { __typename?: "skynet_WindowsUser" } & Pick<
      Skynet_WindowsUser,
      "firstName" | "lastName" | "department" | "email" | "userPrincipalName" | "displayName"
    >
  >;
};

export type QueryUserPhotoQueryVariables = Exact<{
  username: Scalars["String"];
}>;

export type QueryUserPhotoQuery = { __typename?: "Query" } & {
  core_user?: Maybe<
    { __typename?: "core_UserLookup" } & {
      ldap?: Maybe<{ __typename?: "core_LdapUser" } & Pick<Core_LdapUser, "sn" | "givenName" | "thumbnailPhoto">>;
    }
  >;
};

export const SearchInstrumentDocument = gql`
  query searchInstrument($searchText: String, $maxSearchResult: Int, $financialTypes: [Int]) {
    cdx_instrument(
      numDocs: $maxSearchResult
      where: { search: { _beginsWith: $searchText }, financialType: { _in: $financialTypes } }
    ) {
      searchResults {
        bloombergId
        country
        cusip
        description
        financialType
        financialTypeName
        id
        isin
        issuer
        mSymbol
        ticker
      }
    }
  }
`;
export type SearchInstrumentComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<SearchInstrumentQuery, SearchInstrumentQueryVariables>,
  "query"
>;

export const SearchInstrumentComponent = (props: SearchInstrumentComponentProps) => (
  <ApolloReactComponents.Query<SearchInstrumentQuery, SearchInstrumentQueryVariables>
    query={SearchInstrumentDocument}
    {...props}
  />
);

/**
 * __useSearchInstrumentQuery__
 *
 * To run a query within a React component, call `useSearchInstrumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchInstrumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchInstrumentQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      maxSearchResult: // value for 'maxSearchResult'
 *      financialTypes: // value for 'financialTypes'
 *   },
 * });
 */
export function useSearchInstrumentQuery(
  baseOptions?: Apollo.QueryHookOptions<SearchInstrumentQuery, SearchInstrumentQueryVariables>
) {
  return Apollo.useQuery<SearchInstrumentQuery, SearchInstrumentQueryVariables>(SearchInstrumentDocument, baseOptions);
}
export function useSearchInstrumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchInstrumentQuery, SearchInstrumentQueryVariables>
) {
  return Apollo.useLazyQuery<SearchInstrumentQuery, SearchInstrumentQueryVariables>(
    SearchInstrumentDocument,
    baseOptions
  );
}
export type SearchInstrumentQueryHookResult = ReturnType<typeof useSearchInstrumentQuery>;
export type SearchInstrumentLazyQueryHookResult = ReturnType<typeof useSearchInstrumentLazyQuery>;
export type SearchInstrumentQueryResult = Apollo.QueryResult<SearchInstrumentQuery, SearchInstrumentQueryVariables>;
export const SearchUserDocument = gql`
  query searchUser($filter: String!) {
    core_ldapUsers(
      filter: {
        OR: [{ givenNameStartsWith: $filter }, { snStartsWith: $filter }, { sAMAccountNameStartsWith: $filter }]
      }
    ) {
      mail
      description
      sAMAccountName
      name
      ipPhone
      telephoneNumber
      sn
      givenName
      department
      departmentNumber
      givenName
      displayName
      company
    }
  }
`;
export type SearchUserComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<SearchUserQuery, SearchUserQueryVariables>,
  "query"
> &
  ({ variables: SearchUserQueryVariables; skip?: boolean } | { skip: boolean });

export const SearchUserComponent = (props: SearchUserComponentProps) => (
  <ApolloReactComponents.Query<SearchUserQuery, SearchUserQueryVariables> query={SearchUserDocument} {...props} />
);

/**
 * __useSearchUserQuery__
 *
 * To run a query within a React component, call `useSearchUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUserQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchUserQuery(baseOptions: Apollo.QueryHookOptions<SearchUserQuery, SearchUserQueryVariables>) {
  return Apollo.useQuery<SearchUserQuery, SearchUserQueryVariables>(SearchUserDocument, baseOptions);
}
export function useSearchUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchUserQuery, SearchUserQueryVariables>
) {
  return Apollo.useLazyQuery<SearchUserQuery, SearchUserQueryVariables>(SearchUserDocument, baseOptions);
}
export type SearchUserQueryHookResult = ReturnType<typeof useSearchUserQuery>;
export type SearchUserLazyQueryHookResult = ReturnType<typeof useSearchUserLazyQuery>;
export type SearchUserQueryResult = Apollo.QueryResult<SearchUserQuery, SearchUserQueryVariables>;
export const GetUserDocument = gql`
  query getUser($filter: String!) {
    core_ldapUsers(filter: { sAMAccountName: $filter }) {
      mail
      description
      sAMAccountName
      name
      ipPhone
      telephoneNumber
      sn
      givenName
      department
      departmentNumber
      givenName
      displayName
      company
    }
  }
`;
export type GetUserComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetUserQuery, GetUserQueryVariables>,
  "query"
> &
  ({ variables: GetUserQueryVariables; skip?: boolean } | { skip: boolean });

export const GetUserComponent = (props: GetUserComponentProps) => (
  <ApolloReactComponents.Query<GetUserQuery, GetUserQueryVariables> query={GetUserDocument} {...props} />
);

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
}
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const SearchWindowsGroupsDocument = gql`
  query searchWindowsGroups($name: String!) {
    skynet_SearchWindowsGroups(request: { name: { contains: $name } }) {
      windowsGroups {
        name
        email
        displayName
        type
        sid
      }
    }
  }
`;
export type SearchWindowsGroupsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<SearchWindowsGroupsQuery, SearchWindowsGroupsQueryVariables>,
  "query"
> &
  ({ variables: SearchWindowsGroupsQueryVariables; skip?: boolean } | { skip: boolean });

export const SearchWindowsGroupsComponent = (props: SearchWindowsGroupsComponentProps) => (
  <ApolloReactComponents.Query<SearchWindowsGroupsQuery, SearchWindowsGroupsQueryVariables>
    query={SearchWindowsGroupsDocument}
    {...props}
  />
);

/**
 * __useSearchWindowsGroupsQuery__
 *
 * To run a query within a React component, call `useSearchWindowsGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchWindowsGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchWindowsGroupsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSearchWindowsGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<SearchWindowsGroupsQuery, SearchWindowsGroupsQueryVariables>
) {
  return Apollo.useQuery<SearchWindowsGroupsQuery, SearchWindowsGroupsQueryVariables>(
    SearchWindowsGroupsDocument,
    baseOptions
  );
}
export function useSearchWindowsGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchWindowsGroupsQuery, SearchWindowsGroupsQueryVariables>
) {
  return Apollo.useLazyQuery<SearchWindowsGroupsQuery, SearchWindowsGroupsQueryVariables>(
    SearchWindowsGroupsDocument,
    baseOptions
  );
}
export type SearchWindowsGroupsQueryHookResult = ReturnType<typeof useSearchWindowsGroupsQuery>;
export type SearchWindowsGroupsLazyQueryHookResult = ReturnType<typeof useSearchWindowsGroupsLazyQuery>;
export type SearchWindowsGroupsQueryResult = Apollo.QueryResult<
  SearchWindowsGroupsQuery,
  SearchWindowsGroupsQueryVariables
>;
export const FindWindowsGroupsDocument = gql`
  query findWindowsGroups($name: String!) {
    cdx_findGroupsFuzzy(groupName: $name) {
      name
      email
      displayName
      sid
    }
  }
`;
export type FindWindowsGroupsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<FindWindowsGroupsQuery, FindWindowsGroupsQueryVariables>,
  "query"
> &
  ({ variables: FindWindowsGroupsQueryVariables; skip?: boolean } | { skip: boolean });

export const FindWindowsGroupsComponent = (props: FindWindowsGroupsComponentProps) => (
  <ApolloReactComponents.Query<FindWindowsGroupsQuery, FindWindowsGroupsQueryVariables>
    query={FindWindowsGroupsDocument}
    {...props}
  />
);

/**
 * __useFindWindowsGroupsQuery__
 *
 * To run a query within a React component, call `useFindWindowsGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindWindowsGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindWindowsGroupsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useFindWindowsGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<FindWindowsGroupsQuery, FindWindowsGroupsQueryVariables>
) {
  return Apollo.useQuery<FindWindowsGroupsQuery, FindWindowsGroupsQueryVariables>(
    FindWindowsGroupsDocument,
    baseOptions
  );
}
export function useFindWindowsGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindWindowsGroupsQuery, FindWindowsGroupsQueryVariables>
) {
  return Apollo.useLazyQuery<FindWindowsGroupsQuery, FindWindowsGroupsQueryVariables>(
    FindWindowsGroupsDocument,
    baseOptions
  );
}
export type FindWindowsGroupsQueryHookResult = ReturnType<typeof useFindWindowsGroupsQuery>;
export type FindWindowsGroupsLazyQueryHookResult = ReturnType<typeof useFindWindowsGroupsLazyQuery>;
export type FindWindowsGroupsQueryResult = Apollo.QueryResult<FindWindowsGroupsQuery, FindWindowsGroupsQueryVariables>;
export const GetFinancialTypesDocument = gql`
  query getFinancialTypes {
    cdx_financialTypes {
      id
      name
    }
  }
`;
export type GetFinancialTypesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetFinancialTypesQuery, GetFinancialTypesQueryVariables>,
  "query"
>;

export const GetFinancialTypesComponent = (props: GetFinancialTypesComponentProps) => (
  <ApolloReactComponents.Query<GetFinancialTypesQuery, GetFinancialTypesQueryVariables>
    query={GetFinancialTypesDocument}
    {...props}
  />
);

/**
 * __useGetFinancialTypesQuery__
 *
 * To run a query within a React component, call `useGetFinancialTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFinancialTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFinancialTypesQuery, GetFinancialTypesQueryVariables>
) {
  return Apollo.useQuery<GetFinancialTypesQuery, GetFinancialTypesQueryVariables>(
    GetFinancialTypesDocument,
    baseOptions
  );
}
export function useGetFinancialTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFinancialTypesQuery, GetFinancialTypesQueryVariables>
) {
  return Apollo.useLazyQuery<GetFinancialTypesQuery, GetFinancialTypesQueryVariables>(
    GetFinancialTypesDocument,
    baseOptions
  );
}
export type GetFinancialTypesQueryHookResult = ReturnType<typeof useGetFinancialTypesQuery>;
export type GetFinancialTypesLazyQueryHookResult = ReturnType<typeof useGetFinancialTypesLazyQuery>;
export type GetFinancialTypesQueryResult = Apollo.QueryResult<GetFinancialTypesQuery, GetFinancialTypesQueryVariables>;
export const GetSkynetUserDocument = gql`
  query getSkynetUser($username: String!) {
    skynet_GetWindowsUser(request: { userName: $username }) {
      firstName
      lastName
      department
      email
      userPrincipalName
      displayName
    }
  }
`;
export type GetSkynetUserComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetSkynetUserQuery, GetSkynetUserQueryVariables>,
  "query"
> &
  ({ variables: GetSkynetUserQueryVariables; skip?: boolean } | { skip: boolean });

export const GetSkynetUserComponent = (props: GetSkynetUserComponentProps) => (
  <ApolloReactComponents.Query<GetSkynetUserQuery, GetSkynetUserQueryVariables>
    query={GetSkynetUserDocument}
    {...props}
  />
);

/**
 * __useGetSkynetUserQuery__
 *
 * To run a query within a React component, call `useGetSkynetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSkynetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSkynetUserQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetSkynetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetSkynetUserQuery, GetSkynetUserQueryVariables>
) {
  return Apollo.useQuery<GetSkynetUserQuery, GetSkynetUserQueryVariables>(GetSkynetUserDocument, baseOptions);
}
export function useGetSkynetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSkynetUserQuery, GetSkynetUserQueryVariables>
) {
  return Apollo.useLazyQuery<GetSkynetUserQuery, GetSkynetUserQueryVariables>(GetSkynetUserDocument, baseOptions);
}
export type GetSkynetUserQueryHookResult = ReturnType<typeof useGetSkynetUserQuery>;
export type GetSkynetUserLazyQueryHookResult = ReturnType<typeof useGetSkynetUserLazyQuery>;
export type GetSkynetUserQueryResult = Apollo.QueryResult<GetSkynetUserQuery, GetSkynetUserQueryVariables>;
export const QueryUserPhotoDocument = gql`
  query queryUserPhoto($username: String!) {
    core_user(username: $username) {
      ldap {
        sn
        givenName
        thumbnailPhoto
      }
    }
  }
`;
export type QueryUserPhotoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<QueryUserPhotoQuery, QueryUserPhotoQueryVariables>,
  "query"
> &
  ({ variables: QueryUserPhotoQueryVariables; skip?: boolean } | { skip: boolean });

export const QueryUserPhotoComponent = (props: QueryUserPhotoComponentProps) => (
  <ApolloReactComponents.Query<QueryUserPhotoQuery, QueryUserPhotoQueryVariables>
    query={QueryUserPhotoDocument}
    {...props}
  />
);

/**
 * __useQueryUserPhotoQuery__
 *
 * To run a query within a React component, call `useQueryUserPhotoQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryUserPhotoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryUserPhotoQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useQueryUserPhotoQuery(
  baseOptions: Apollo.QueryHookOptions<QueryUserPhotoQuery, QueryUserPhotoQueryVariables>
) {
  return Apollo.useQuery<QueryUserPhotoQuery, QueryUserPhotoQueryVariables>(QueryUserPhotoDocument, baseOptions);
}
export function useQueryUserPhotoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryUserPhotoQuery, QueryUserPhotoQueryVariables>
) {
  return Apollo.useLazyQuery<QueryUserPhotoQuery, QueryUserPhotoQueryVariables>(QueryUserPhotoDocument, baseOptions);
}
export type QueryUserPhotoQueryHookResult = ReturnType<typeof useQueryUserPhotoQuery>;
export type QueryUserPhotoLazyQueryHookResult = ReturnType<typeof useQueryUserPhotoLazyQuery>;
export type QueryUserPhotoQueryResult = Apollo.QueryResult<QueryUserPhotoQuery, QueryUserPhotoQueryVariables>;
