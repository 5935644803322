/**
 * @deprecated {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @internal
 */
export const AUTHENTICATE_COOKIE_KEY = "authentication";

/**
 * @deprecated {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @internal
 */
export const CDX_SESSION_COOKIE_KEY = "ga.session_id";

/**
 * @deprecated {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @public
 */
export const AUTHENTICATE_HEADER_KEY = "Authorization";

/**
 * @deprecated {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @public
 */
export const GA_TOKEN_HEADER_KEY = "X-Cig-Token";

/**
 * @deprecated {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @public
 */
export const GA_SESSION_HEADER_KEY = "X-Ga-Session";
