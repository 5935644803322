import type { ReactElement } from "react";
import type { SplitPaneProps } from "react-split-pane";
import SplitPane from "react-split-pane";
import { Global, css } from "@emotion/react";

// Sadly we can't style individual parts of the split using styled components.
// We must use the style names it provides, .e.g Resizer, see: https://github.com/tomkp/react-split-pane
const styles = css`
  .SplitPane {
    position: relative !important;
  }

  .Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }

  .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }

  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }

  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }

  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Resizer.disabled {
    cursor: not-allowed;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }
`;

export interface CdxSplitPaneProps extends SplitPaneProps {
  children: ReactElement<any>[];
}

export const CdxSplitPane = (props: CdxSplitPaneProps) => {
  return (
    <>
      <Global styles={styles} />
      <SplitPane split="horizontal" defaultSize="50%" {...props} />
    </>
  );
};
