import type { LogAdaptor } from "./Logger";
import { LogLevel, logLevelToName } from "./Logger";

const consoleLogAtLevel = (logLevel: LogLevel, logData: any[]) => {
  switch (logLevel) {
    // Why use .info for DEBUG and TRACE? Inspectors in browsers hide these logs by default and the user
    // has to manually change their settings. As we already have mechanisms for this, this behavior
    // is undesirable. Instead we just use console.info so our debug logs don't confusingly get
    // filtered out.
    case LogLevel.DEBUG:
    case LogLevel.TRACE:
    case LogLevel.INFO:
      console.info(...logData);
      break;

    case LogLevel.WARN:
      console.warn(...logData);
      break;

    case LogLevel.ERROR:
    case LogLevel.FATAL:
      console.error(...logData);
      break;
  }
};

/**
 * Creates a {@link LogAdaptor} which outputs to the built in logger which is
 * present in both nodejs and the browser
 *
 * @public
 */
export const consoleLogAdaptor: LogAdaptor = ({ msg, level }) => {
  const logData = [`${msg?.context}`, `${logLevelToName[level]}`, ...(msg?.data || [])];
  consoleLogAtLevel(level, logData);
};

interface InspectorConsoleLogStyles {
  context: string;
  contextAtLogLevel: { [K in LogLevel]?: string };
}

const defaultStyles: InspectorConsoleLogStyles = {
  context: "color: #aeaeae",
  contextAtLogLevel: {
    [LogLevel.WARN]: "color: #ee9933",
    [LogLevel.ERROR]: "color: #ee8888",
    [LogLevel.FATAL]: "color: #ee8888",
  },
};

/**
 * @internal
 * @deprecated To be moved into `@citadel/cdx-browser-logger`
 */
export interface CreateInspectorConsoleLogAdaptorOptions {
  styles?: Partial<InspectorConsoleLogStyles>;
}

/**
 * Console log adaptor for the inspector (e.g. in browsers and when inspecting a node process from Chrome).
 * Adds the context of the current logger and colors that are interpreted by inspectors.
 *
 * @internal
 * @deprecated To be moved into `@citadel/cdx-browser-logger`
 */
export const createInspectorConsoleLogAdaptor = ({
  styles: givenStyles = {},
}: CreateInspectorConsoleLogAdaptorOptions = {}): LogAdaptor => {
  const styles = { ...defaultStyles, ...givenStyles };

  return (logLine) => {
    const prefix = [...(logLine.msg?.context ? [logLine.msg.context] : []), logLevelToName[logLine.level]];
    const logData = [
      `%c${prefix.join(" ")}`,
      styles.contextAtLogLevel[logLine.level] ?? styles.context,
      ...(logLine.msg?.data ?? []),
    ];
    consoleLogAtLevel(logLine.level, logData);
  };
};
