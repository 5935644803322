import { getAuthenticationSession } from "./getAuthenticationSession";

/**
 * Wait for authentication then return the username. Use this to quickly access the username of the current user without
 * needing an additional network request. Waits on authentication if it is not yet complete.
 *
 * @public
 */
export async function getUsername(): Promise<string> {
  const session = getAuthenticationSession();
  return session.getUsername();
}
