import { memoize } from "lodash";
import type { ReactNode } from "react";
import type { AppRoute } from "./AppRoute";

const renderRouteTitle = (route: AppRoute, isCompact: boolean): ReactNode => {
  const { navigationTitle, title, compactTitle } = route;
  if (isCompact) {
    return compactTitle ?? title ?? navigationTitle ?? null;
  }
  return title ?? navigationTitle ?? null;
};

const renderDefaultTitle = (routes: AppRoute[], isCompact: boolean): ReactNode => {
  const route = routes.find((r) => r.isHome);
  if (!route) {
    return null;
  }
  return renderRouteTitle(route, isCompact);
};

export interface RenderRouteDisplayTitleProps {
  routes: AppRoute[];
  activeRoute: AppRoute | null;
  isCompact: boolean;
  fallback?: React.ReactNode;
}

export const renderRouteDisplayTitle = ({
  routes,
  activeRoute,
  isCompact,
  fallback = null,
}: RenderRouteDisplayTitleProps): ReactNode => {
  if (!activeRoute) {
    return renderDefaultTitle(routes, isCompact) ?? fallback;
  }
  return renderRouteTitle(activeRoute, isCompact) ?? fallback;
};

export const getRouteDocumentTitle = memoize((activeRoute: AppRoute | null, fallback: string): string => {
  if (!activeRoute) {
    return fallback;
  }
  const { navigationTitle = fallback, title = navigationTitle, documentTitle = title } = activeRoute;
  if (typeof documentTitle === "string") {
    return documentTitle;
  }
  return fallback;
});
