import type { ReactNode } from "react";
import classes from "./info-section.less";
import type { InfoSubsectionData } from "./info-subsection";

export type InfoSectionData = {
  header: string;
  sections: InfoSubsectionData[];
};

export type InfoSectionProps = {
  children: ReactNode;
  header: string;
};

export const InfoSection = ({ header, children }: InfoSectionProps) => {
  return (
    <div className={classes.infoSection}>
      <div className={classes.infoSectionHeader}>{header}</div>
      <div className={classes.infoSectionBody}>{children}</div>
    </div>
  );
};
