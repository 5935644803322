export function getProcess(): any {
  return (globalThis as any).process;
}

export function getProcessEnv(): any {
  return getProcess()?.env;
}

export function assignProcessEnv(env: any) {
  if (!getProcess()) {
    return;
  }
  getProcess().env = env;
}

export function assignProcessEnvProperty(key: string, value: any) {
  if (!getProcess()) {
    return;
  }
  getProcess().env[key] = value;
}

export function deleteProcessEnvProperty(key: string) {
  if (!getProcess()) {
    return;
  }
  delete getProcess().env[key];
}
