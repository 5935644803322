import type { ParsedToken } from "./types";

export const isTokenExpired = (token: ParsedToken | null): boolean => {
  return !token || Date.now() > token.expirationInMs;
};

export const parseJwtTokenInFull = (rawToken: string | null): any => {
  if (!rawToken) {
    return null;
  }
  try {
    const parsed = JSON.parse(atob(rawToken.split(".")[1]));
    return {
      ...parsed,
      expirationInMs: parsed.exp * 1000,
      token: rawToken,
    };
  } catch (e) {
    return null;
  }
};

export function parseJwtToken<T = {}>(rawToken: string | null): (T & ParsedToken) | null {
  const parsed = parseJwtTokenInFull(rawToken);
  return isTokenExpired(parsed) ? null : parsed;
}
