import styled from "@emotion/styled";
import { Icon, InputGroup, Classes as BlueprintClasses, Colors as BlueprintColors } from "@blueprintjs/core";
import { noop } from "lodash";

export type SelectInputGroupProps = {
  className?: string;
  disabled?: boolean;
  value: string;
  style?: React.CSSProperties;
  placeholder?: string;
};

// Blueprint's style rules apply a margin of 7px to `icon` and `leftIcon`, but not the `rightIcon`
const RightIcon = styled(Icon)`
  cursor: pointer;
  margin: 7px;
`;

/**
 * Styled `InputGroup` intended to be used `children` of a Blueprint.js `Select`.
 *
 * @see https://blueprintjs.com/docs/#select/select2
 * @see https://blueprintjs.com/docs/#core/components/text-inputs.input-group
 */
const StyledInputGroup = styled(InputGroup)`
  input {
    caret-color: transparent;
  }

  input,
  .${BlueprintClasses.INPUT_ACTION} {
    cursor: pointer;
  }

  .${BlueprintClasses.INPUT_ACTION} .${BlueprintClasses.ICON} {
    margin-left: 0;

    path {
      fill: ${BlueprintColors.DARK_GRAY5};
    }
  }
`;

export const SelectInputGroup = ({
  className,
  disabled,
  value,
  style,
  placeholder,
}: SelectInputGroupProps): JSX.Element => {
  return (
    <StyledInputGroup
      onChange={noop}
      rightElement={!disabled ? <RightIcon icon="caret-down" /> : undefined}
      className={className}
      disabled={disabled}
      value={value}
      style={style}
      placeholder={placeholder}
    />
  );
};
