import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ErrorState, LoadingState } from "@citadel/pse-lib-ui";
import { addError } from "@citadel/cdx-provider-user-monitoring";
import type { Assessment } from "../../types/assessment";
import { BDGrid } from "../bd-grid";
import { useAdminAssessmentQuery } from "../../_generated/graphql";
import classes from "./admin-assessment-view.less";

interface AdminAssessmentViewParamTypes {
  adminToken: string;
}

export const AdminAssessmentView = () => {
  const { adminToken } = useParams<AdminAssessmentViewParamTypes>();

  const { data, loading, error } = useAdminAssessmentQuery({
    variables: {
      token: adminToken,
    },
  });

  useEffect(() => {
    if (!error) {
      return;
    }
    console.error(error);
    addError("Admin Assessment View error", { message: error?.message });
  }, [error]);

  return (
    <div className={classes.container}>
      {loading && <LoadingState text="Loading assessment..." />}
      {error && <ErrorState details={error.stack} />}
      {!loading && !data?.assessmentByAdminToken && <ErrorState title="No assessment found" />}
      {!loading && data?.assessmentByAdminToken && (
        <BDGrid disableUpdates={true} assessment={data?.assessmentByAdminToken as Assessment} />
      )}
    </div>
  );
};
