import { useRef, useEffect } from "react";
import { isArgsShallowEqual } from "../utils";

/**
 * The useDerivedValue hook allows you to use a value derived from other
 * parameters and control when it is re-computed.
 *
 * This hook has similar characteristics to useMemo, but is different in the
 * following key ways:
 * - You can supply your own comparison function for the previous and next args
 * - Memoisation is guaranteed until the isSame function returns false
 *
 * @param fn function used to derive the value
 * @param args dependencies (also supplied to function)
 * @param isSame comparison between previous and next args
 */
export function useDerivedValue<TArgs extends readonly any[], TValue>(
  fn: (...args: TArgs) => TValue,
  deps: TArgs,
  isSame?: (prev: TArgs, next: TArgs) => boolean
): TValue;
export function useDerivedValue<TArgs extends readonly any[], TValue>(
  fn: () => TValue,
  args: TArgs,
  isSame?: (prev: TArgs, next: TArgs) => boolean
): TValue;
export function useDerivedValue<TArgs extends readonly any[], TValue>(
  fn: (...args: TArgs) => TValue,
  deps: TArgs,
  isSame: (prev: TArgs, next: TArgs) => boolean = isArgsShallowEqual
): TValue {
  const lastArgsRef = useRef<TArgs | undefined>(undefined);
  const lastValueRef = useRef<TValue>();

  let lastArgs = lastArgsRef.current;
  let lastValue = lastValueRef.current;
  if (!lastArgs || !isSame(lastArgs, deps)) {
    lastArgs = deps;
    lastValue = fn(...deps);
  }

  // We need to update the refs inside an effect to avoid unwanted side-effects
  // it's an edge case but sometimes react will throw out the renders, and we
  // don't want to update the refs in that case.
  useEffect(() => {
    lastArgsRef.current = lastArgs;
    lastValueRef.current = lastValue;
  }, [lastValue, lastArgs]);

  return lastValue!;
}
