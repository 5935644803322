// TODO[CDX-1399]: https://jira.citadelgroup.com/browse/CDX-1469
import { AUTHENTICATE_API_PATHS } from "@citadel/cdx-lib-auth";
import { getEnvironment } from "@citadel/cdx-environment";

const { sso } = AUTHENTICATE_API_PATHS;

/**
 * @deprecated {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @internal
 */
export const createAuthenticateUrlAndQuery = (redirect?: boolean): string => {
  const { authenticateApiOrigin } = getEnvironment();
  const isInsecure = window.location.protocol === "http:";

  const params = redirect ? [`redirect=${encodeURIComponent(window.location.href)}`] : [];
  if (authenticateApiOrigin.includes("-dev") && isInsecure) {
    params.push("disable_secure_flag=true");
  }
  return `${authenticateApiOrigin}${sso()}?${params.join("&")}`;
};

/**
 * @deprecated {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @internal
 */
export const createCdxAuthUrlAndQuery = (redirect?: boolean): string => {
  if (redirect) {
    return new URL(`/auth/client?u=${encodeURIComponent(window.location.href)}`, window.location.origin).href;
  }
  return new URL(`/auth/client?disable_redirect=true`, window.location.origin).href;
};

/**
 * @deprecated {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @internal
 */
export const redirectToAuthenticate = () => {
  const url = createAuthenticateUrlAndQuery(true);
  window.location.replace(url);
};

/**
 * Only available if on CDX (have cdx-service-auth deployed)
 *
 * @deprecated {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @public
 */
export const redirectToCdxAuth = () => {
  const url = createCdxAuthUrlAndQuery(true);
  window.location.replace(url);
};

/**
 * if we require http or https and are not on the correct, this allows redirecting appropriately
 *
 * @internal
 */
export const redirectToProtocol = (protocol: "http:" | "https:") => {
  window.location.protocol = protocol;
};

/**
 * convenience for apps that require citadelgroup.com
 *
 * @internal
 */
export const redirectToLocalCitadelgroupCom = () => {
  const href = window.location.href;
  if (href.includes("://localhost.citadelgroup.com") || !href.includes("://localhost")) {
    return;
  }
  window.location.replace(window.location.href.replace("://localhost", "://localhost.citadelgroup.com"));
};
