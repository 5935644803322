import { faThumbsDown, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import classes from "./thumb-rating.module.less";

export enum ThumbSignal {
  THUMBS_DOWN = "THUMBS_DOWN",
  ONE_THUMBS_UP = "ONE_THUMBS_UP",
  TWO_THUMBS_UP = "TWO_THUMBS_UP",
}

interface ThumbRatingProps {
  className?: string;
  disabled?: boolean;
  onChange: (value: ThumbSignal) => void;
  value: ThumbSignal | null | undefined;
}

const signalToClassMap: { [key in ThumbSignal]: string } = {
  THUMBS_DOWN: classes.thumbsDown,
  ONE_THUMBS_UP: classes.thumbsUp,
  TWO_THUMBS_UP: classes.twoThumbsUp,
};
const signalToIconMap: { [key in ThumbSignal]: JSX.Element } = {
  THUMBS_DOWN: <FontAwesomeIcon className={classes.thumbsDownIcon} icon={faThumbsDown} size="2x" transform="down-1" />,
  ONE_THUMBS_UP: <FontAwesomeIcon className={classes.thumbsUpIcon} icon={faThumbsUp} size="2x" transform="up-1" />,
  TWO_THUMBS_UP: (
    <span>
      <FontAwesomeIcon
        className={classes.twoThumbsUpBackgroundIcon}
        icon={faThumbsUp}
        size="2x"
        transform="up-2 left-3"
      />
      <FontAwesomeIcon
        className={classes.twoThumbsUpForegroundIcon}
        icon={faThumbsUp}
        size="2x"
        transform="up-10 right-3"
      />
    </span>
  ),
};

export const ThumbRating = ({ className, disabled, onChange, value }: ThumbRatingProps): JSX.Element => {
  return (
    <div className={classNames(className, classes.thumbButtons)}>
      {(Object.keys(ThumbSignal) as [ThumbSignal]).map((signal) => (
        <button
          key={signal}
          className={classNames(signalToClassMap[signal], { [classes.selected]: value === signal })}
          onClick={() => onChange(signal)}
          disabled={disabled}
        >
          {signalToIconMap[signal]}
        </button>
      ))}
    </div>
  );
};
