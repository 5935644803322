import IdTokenVerifier from "idtoken-verifier";
import { logger } from "../logger";
import { validateAccessTokenFromPingFederate } from "./requests";
import { isTokenExpired } from "./parseToken";
import type { SessionData } from "./types";
import { getConfig } from "./env";

/**
 * Check for
 * - tokens present
 * - tokens not expired
 * If you wish a more comprehensive validation, use isTokenValid
 */

export const isTokenNotExpired = (tokens: SessionData | null): boolean => {
  if (!tokens) {
    return false;
  }
  const { accessToken, idToken } = tokens;
  return !isTokenExpired(accessToken) && !isTokenExpired(idToken);
};

/**
 * Verifies an access_token
 *
 * Check for
 * - tokens present
 * - tokens not expired
 * - verify against source
 */

export const isTokenValid = async (tokens: SessionData | null): Promise<boolean> => {
  if (!isTokenNotExpired(tokens)) {
    return false;
  }
  const valid = await validateAccessTokenFromPingFederate(tokens?.accessToken?.token);
  return !!valid;
};

/**
 * Verifies an id_token
 *
 * It will validate:
 *
 * - signature according to the algorithm configured in the verifier.
 * - if nonce is present and matches the one provided
 * - if iss and aud claims matches the configured issuer and audience
 * - if token is not expired and valid
 *
 */
export const isIdTokenValid = async (idToken: string | null, nonce: string | null): Promise<boolean> => {
  if (!idToken || !nonce) {
    return false;
  }

  const { clientId, url } = getConfig();
  const verifier = new IdTokenVerifier({
    issuer: url.base,
    audience: clientId,
    jwksURI: url.jwks,
  });

  const verified = await new Promise((resolve) => {
    verifier.verify(idToken, nonce, (error, _payload) => {
      if (error) {
        logger.error(`Unable to verify id token`, error);
      }
      return resolve(!error);
    });
  });
  return !!verified;
};
