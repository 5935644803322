let escapeHTMLPolicy: Pick<TrustedTypePolicy, "name" | "createHTML"> | null = null;
if ("window" in globalThis && window.trustedTypes) {
  escapeHTMLPolicy = window.trustedTypes.createPolicy("myEscapePolicy", {
    createHTML: (string: string) => string.replace(/\</g, "&lt;"),
  });
}

export interface SanitizedTemplate {
  value: string;
  sanitized: boolean;
}

/**
 * TaggedTemplate Helper, sanitizes all parts using the escapeHTML Trusted Types Policy.
 * @param strings
 * @param parts
 * @returns sanitized version of the input Template.
 */
export function sanitize(
  strings: TemplateStringsArray,
  ...parts: Array<string | SanitizedTemplate | SanitizedTemplate[]>
): SanitizedTemplate {
  let returnable = {
    value: "",
    sanitized: true,
  };
  if (escapeHTMLPolicy) {
    for (let index = 0; index < strings.length; index++) {
      returnable.value += strings[index];

      if (!parts[index]) {
        continue;
      }

      if (Array.isArray(parts[index])) {
        returnable.value += (parts[index] as SanitizedTemplate[])
          .map((previousTemplatePart: SanitizedTemplate) => previousTemplatePart.value)
          .join("");
        continue;
      }

      if (typeof parts[index] === "object") {
        returnable.value += (parts[index] as SanitizedTemplate).value;
        continue;
      }

      let part = parts[index] as string;
      const escaped = (escapeHTMLPolicy as Pick<TrustedTypePolicy, "name" | "createHTML">).createHTML(part);
      if (!(escaped instanceof TrustedHTML)) {
        // A value wasn't sanitized, bail on entire template.
        return {
          value: "",
          sanitized: false,
        };
      }
      returnable.value += escaped.toString();
    }
    return returnable;
  }

  return {
    value: "",
    sanitized: false,
  };
}
