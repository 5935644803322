import { groupBy, mapValues } from "lodash";
import { useEffect, useMemo, useState } from "react";
import type { AvailableActionsSmcRequirements, SmcEntitlementRequirement } from "../../entitlements/types";
import { packageLogger } from "../../logger";
import type { CheckEntitlementsRequest, CheckEntitlementsResponse } from "./checkEntitlements";
import { checkEntitlements } from "./checkEntitlements";

const logger = packageLogger.createFileLogger(__filename);

/**
 * Map of entitlement check keys to their resolution values.
 *
 * @public
 */
export type ActionResultMap<T extends AvailableActionsSmcRequirements> = {
  [key in keyof T]: boolean;
};

/**
 * Return type from {@link useEntitlements}. Contains the result of evaluating
 * the map of entitlement checks.
 *
 * @public
 */
export interface UseEntitlementsReturn<T extends AvailableActionsSmcRequirements> {
  /**
   * Map of entitlement check keys to their resolution values. This is
   * initialized to all false values while {@link loading} is true.
   */
  actions: ActionResultMap<T>;

  /**
   * List of all missing entitlements
   */
  missingEntitlements: SmcEntitlementRequirement[];

  /**
   * True while the set of entitlements are being fetched from the gasec
   * endpoint.
   */
  loading: boolean;

  /**
   * Potential error from fetching entitlements from gasec v2.
   */
  error: Error | undefined;
}

/**
 * Evaluates a series of entitlement checks against SMC.
 *
 * @public
 */
export function useEntitlements<T extends AvailableActionsSmcRequirements>(actions: T): UseEntitlementsReturn<T> {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const actionsStable = useMemo(() => actions, [JSON.stringify(actions)]);
  const actionsFallbackFalse = useMemo(() => mapValues(actionsStable, () => false), [actionsStable]);
  const [entitlements, setEntitlements] = useState<ActionResultMap<T>>();
  const [missing, setMissing] = useState<SmcEntitlementRequirement[]>([]);
  const [entitlementsError, setEntitlementsError] = useState<Error>();

  // When the action requests change, re-run the entitlements query
  useEffect(() => {
    let cancelled = false;
    setEntitlements(undefined);
    setMissing([]);
    setEntitlementsError(undefined);

    (async function () {
      try {
        const { entitlements: nextEntitlements, missing: nextMissing } = await getEntitlements(actionsStable);
        if (cancelled) return;
        setEntitlements(nextEntitlements);
        setMissing(nextMissing);
      } catch (e: any) {
        if (cancelled) return;
        setEntitlementsError(e);
      }
    })();

    return function () {
      cancelled = true;
    };
  }, [actionsStable]);

  return {
    actions: entitlements ?? actionsFallbackFalse,
    missingEntitlements: missing,
    loading: !entitlements,
    error: entitlementsError,
  };
}

/**
 * Gets entitlements from gasec v2.
 */
async function getEntitlements<T extends AvailableActionsSmcRequirements>(
  actions: T
): Promise<{ entitlements: ActionResultMap<T>; missing: SmcEntitlementRequirement[] }> {
  // Explode each check into sub groups so we can hit all requested services
  const allActions = Object.entries(actions).flatMap(([key, checks]) =>
    (checks.entitlements ?? []).map((check) => explodeAction(key, check))
  );
  // Prepare request
  const requestBody: CheckEntitlementsRequest = { smc_entitlements: allActions.map((action) => action.check) };

  // Submit request and check response is OK
  let response: CheckEntitlementsResponse;
  try {
    response = await checkEntitlements(requestBody);
  } catch (e) {
    logger.error("Failed to fetch entitlements", e);
    throw new Error("Failed to fetch entitlements " + e);
  }

  // Collapse back into a map of actions to their responses
  const allActionResponses = allActions.map(
    ({ key }, i) => [key, response.smc_entitlements[i].result] as [string, boolean]
  );
  // Group by the action key
  const groupedActionResponses: Record<string, [string, boolean][]> = groupBy(allActionResponses, (x) => x[0]);
  // For each action, check that all results are true and return true if they
  // are or false if they are not
  const actionResponses = mapValues(groupedActionResponses, (actionResponseArray) =>
    actionResponseArray.every((singleResponse) => singleResponse[1])
  ) as ActionResultMap<T>;

  // Also extract out the list of missing entitlements
  const missing: SmcEntitlementRequirement[] = response.smc_entitlements
    .filter((result) => result.result === false)
    .map((res) => ({
      service: res.service,
      entitlement: res.entitlements[0].name,
      value: res.entitlements[0].values[0],
    }));

  return { entitlements: actionResponses, missing };
}

/**
 * Explodes a check into a single action for the SMC request.
 */
function explodeAction(
  key: string,
  check: SmcEntitlementRequirement
): { key: string; check: CheckEntitlementsRequest["smc_entitlements"][number] } {
  return {
    key,
    check: {
      service: check.service,
      entitlements: [
        {
          name: check.entitlement,
          values: [check.value],
        },
      ],
    },
  };
}
