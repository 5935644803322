import { createContext } from "react";
import { Colors } from "@blueprintjs/core";
import {
  USER_CHANNEL_1,
  USER_CHANNEL_2,
  USER_CHANNEL_3,
  USER_CHANNEL_4,
  USER_CHANNEL_5,
  USER_CHANNEL_6,
  USER_CHANNEL_NONE,
} from "@citadel/cdx-mainframe";
import {
  BLUE_CHANNEL,
  LIME_CHANNEL,
  NONE_CHANNEL,
  ORANGE_CHANNEL,
  PURPLE_CHANNEL,
  RED_CHANNEL,
  WIDGET_CHANNEL_STORAGE,
  WORKSPACE_CHANNEL_STORAGE,
  YELLOW_CHANNEL,
} from "../constants/standardChannels";
import type { ChannelDefinitionMap } from "../utils/channelDefinition";

export const DEFAULT_CHANNEL_DEFINITIONS: ChannelDefinitionMap = {
  [NONE_CHANNEL]: {
    storage: WIDGET_CHANNEL_STORAGE,
    userChannel: USER_CHANNEL_NONE,
    color: {
      dark: Colors.GRAY3,
      light: Colors.GRAY5,
    },
  },
  [BLUE_CHANNEL]: {
    storage: WORKSPACE_CHANNEL_STORAGE,
    userChannel: USER_CHANNEL_1,
    color: {
      dark: Colors.BLUE5,
      light: Colors.BLUE3,
    },
  },
  [LIME_CHANNEL]: {
    storage: WORKSPACE_CHANNEL_STORAGE,
    userChannel: USER_CHANNEL_2,
    color: {
      dark: Colors.LIME5,
      light: Colors.LIME3,
    },
  },
  [ORANGE_CHANNEL]: {
    storage: WORKSPACE_CHANNEL_STORAGE,
    userChannel: USER_CHANNEL_3,
    color: {
      dark: Colors.ORANGE5,
      light: Colors.ORANGE3,
    },
  },
  [PURPLE_CHANNEL]: {
    storage: WORKSPACE_CHANNEL_STORAGE,
    userChannel: USER_CHANNEL_4,
    color: {
      dark: Colors.VIOLET5,
      light: Colors.VIOLET3,
    },
  },
  [RED_CHANNEL]: {
    storage: WORKSPACE_CHANNEL_STORAGE,
    userChannel: USER_CHANNEL_5,
    color: {
      dark: Colors.RED5,
      light: Colors.RED3,
    },
  },
  [YELLOW_CHANNEL]: {
    storage: WORKSPACE_CHANNEL_STORAGE,
    userChannel: USER_CHANNEL_6,
    color: {
      dark: Colors.GOLD5,
      light: Colors.GOLD3,
    },
  },
};

/**
 * The ChannelDefinitionContext defines the canonical list of channels available for users to select from
 *
 * @internal
 */
export const ChannelDefinitionContext = createContext(DEFAULT_CHANNEL_DEFINITIONS);
