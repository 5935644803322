import { UserSettingsContext } from "../context/UserSettingsContext";
import { createStoredValueHook } from "./createStoredValueHook";

/**
 * A User Setting is state indicating a setting specific to a user
 *
 * User Settings are available firm wide to apps using CDL and stored against a user.
 *
 * Some examples of user settings:
 *  - Theme (light/dark)
 *  - Preferred number format
 */
export const useUserSetting = createStoredValueHook(UserSettingsContext);
