import { sleep } from "./util";

export const MAX_RETRIES = 3;

/**
 * Retry the function if not resolved successfully
 * @param fn func to retry
 * @param retryCount number of retries
 * @param isComplete predicate to determine if no more retries needed;
 * takes in the fn resolved result
 * @returns resolved fn result
 */
export async function retry<T>(
  fn: () => Promise<T>,
  retryCount = MAX_RETRIES,
  isComplete = (arg: T) => !!arg
): Promise<T> {
  const result = await fn();
  const complete = isComplete(result);

  if (complete) {
    return result;
  } else {
    if (retryCount <= 0) {
      throw result;
    }
    await sleep(2 ** (MAX_RETRIES - retryCount) * 1000);
    return retry(fn, retryCount - 1, isComplete);
  }
}
