import type { ComponentType, ReactNode } from "react";
import { createContext, useContext } from "react";
import type { Change } from "../utils";
import { useDerivedValue } from "../hooks";

export interface StateBoundaryProps<T> {
  value: T;
  onChange(change: Change<T>): void;
  children: ReactNode;
}

export type StateBoundaryPair<T> = [ComponentType<StateBoundaryProps<T>>, () => [T, (value: Change<T>) => void]];

/**
 * The createStateBoundary creates a context for communication between an
 * ancestor component and its deep children.
 *
 * This forms a 2 part piece;
 * - The StateBoundary which defines the context by providing the value and
 *   change behavior
 * - The useBoundaryState hook which allows descendant components to change
 *   values from the context
 *
 * When the descendant component changes values it is up to the context state
 * boundary to respond appropriately (e.g. storing it within a useState
 * variable).
 *
 * A rootValue and onRootChange function must be specified to declare the behavior when there is no boundary.
 */
export const createStateBoundary = <T extends unknown>(
  rootValue: T,
  onRootChange: (change: Change<T>) => void
): StateBoundaryPair<T> => {
  const Context = createContext<[T, (value: Change<T>) => void]>([rootValue, onRootChange]);

  const StateBoundary = ({ children, value, onChange }: StateBoundaryProps<T>) => {
    const context = useDerivedValue<[T, (value: Change<T>) => void], [T, (value: Change<T>) => void]>(
      // TODO: CTOTECH-2046 Fix this the next time this file is edited.
      // eslint-disable-next-line @typescript-eslint/no-shadow
      (value, onChange) => [value, onChange],
      [value, onChange]
    );

    return <Context.Provider value={context}>{children}</Context.Provider>;
  };

  const useBoundaryState = (): [T, (value: Change<T>) => void] => {
    return useContext(Context);
  };

  return [StateBoundary, useBoundaryState];
};
