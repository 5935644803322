import type { CdxFutureEnvironment, Environment } from "./types";

/**
 * Our config initially was served from our auth service specifically because
 * it was used across many different repositories where teams had custom deployments
 * and managed their resources differently. This meant teams had to setup SSO
 * themselves and some used ga, some used authenticate, and others didn't setup at all.
 * We no longer have this constraint and so now the config can be boiled down to
 * a reduced config set. To ease the transition as we deprecate auth, we will
 * enrich the limited config we now use (target, env, isLocal) with the old
 * information. At some point we will remove the current typing entirely and only
 * leverage these for "environment"
 *
 * @deprecated This is exposed for testing during an intermediate backwards compatibility
 * period; do not use this in any product code
 */
export function enrichEnvironmentWithAuthBackwardsCompatibility(currentEnv: CdxFutureEnvironment): Environment {
  const { citadelEnv, target, isLocal } = currentEnv;
  const authenticateApiOrigin = `https://authenticate${citadelEnv === "stabledev" ? "-dev" : ""}.citadelgroup.com`;

  return {
    ...currentEnv,
    cdxTarget: target,
    CITADEL_ENV: citadelEnv,
    CITADEL_ENV_RAW: isLocal && citadelEnv === "stabledev" ? "" : citadelEnv,
    env: citadelEnv,
    isAuthenticateSupported: citadelEnv !== "ssa",
    isDev: citadelEnv === "stabledev",
    authenticateApiOrigin: citadelEnv === "ssa" ? "" : authenticateApiOrigin,
    gaSecurityApiOrigin: `https://gasec-${citadelEnv}.citadelgroup.com`,
    gaSecuritySsoEnabled: !isLocal,
    exchangeAuthenticateJwtForGaSecTokenEnabled:
      !isLocal && !(globalThis as any)?.window?.location?.host?.startsWith("pcgprism"),
  };
}
