import type { TabId } from "@blueprintjs/core";
import type { ReportUUID } from "./base";
import type { Report } from "./Report";

export type onMediaUsableChange = (reportUUID: ReportUUID, usable: boolean) => void;
export type onMediaRemove = (reportUUID: ReportUUID, index: number) => void;
export type onRegionRemove = () => void;
export type onRegionsChange = (reportUUID: ReportUUID, index: number, regions: Annotation[]) => void;
export type onRequestScreenshot = (reportUUID: ReportUUID) => void;
export type onTextUpdate = (text: string, index?: number) => void;
export type onEditUpdate = (editing: EditingState, index?: number) => void;
export type onFillUpdate = (fill: boolean, index?: number) => void;
export type onRemoveUpdate = (remove: boolean, index?: number) => void;
export type onIndexChange = (index: TabId) => void;
export type onEditorChange = (reportUUID: ReportUUID, mediaIndex: number, editor: EditorDimensions) => void;

export interface Annotation {
  text?: string;
  color: string;
  fill: boolean;
  top: number;
  left: number;
  width: number;
  height: number;
  scaleWidth: number;
  scaleHeight: number;
}

export interface ScaledAnnotation extends Annotation {
  lineWidth: number;
  lineHeight: number;
  marker: {
    index: number;
    width: number;
    height: number;
    fontSize: number;
  };
}

export interface EditorDimensions {
  width: number;
  height: number;
  scaleWidth: number;
  scaleHeight: number;
}

export interface Media {
  blob: Blob | null;
  base64: string;
  annotations: Annotation[];
  editor: EditorDimensions | null;
  usable: boolean;
  width: number;
  height: number;
  captureable: boolean;
  name: string | null;
}

export interface MediaProps {
  report: Report;
  mediaIndex: number;
  dropping: boolean;
  onRegionsChange?: onRegionsChange;
  onRegionRemove: onRegionRemove;
  onEditorChange: onEditorChange;
}

export const enum EditingState {
  NONE,
  VISIBLE,
  DURABLE,
}
