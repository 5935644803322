import type { BehaviorSubject } from "rxjs";
import { useState, useEffect, useRef, useCallback } from "react";
import { distinctUntilChanged, map, tap } from "rxjs/operators";
import { isEqual } from "lodash";
import type { Change } from "@citadel/cdx-lib-react";
import { applyChange } from "@citadel/cdx-lib-react";
import { TokenBucket } from "../utils/tokenBucket";
import { packageLogger } from "../logger";
import type { PathValue } from "../utils/getDeep";
import { getDeep } from "../utils/getDeep";
import { setDeep } from "../utils/setDeep";
import { useMemoValue } from "./useMemoValue";

const log = packageLogger.createFileLogger(__filename);

export interface StoredValueOptions<T> {
  store$: BehaviorSubject<T>;
  isEqual?(a: unknown, b: unknown): boolean;
}

export const useStoredValue = <T, K extends readonly string[]>(
  store$: BehaviorSubject<T>,
  ...path: K
): [PathValue<T, K>, (change: Change<PathValue<T, K>>) => void] => {
  const { current: limiter } = useRef(new TokenBucket());
  const mPath = useMemoValue(path);
  const value = getDeep(store$.value, mPath);
  const [, setValue] = useState(() => value);

  useEffect(() => {
    const subscription = store$
      .pipe(
        map((data) => getDeep(data, mPath)),
        distinctUntilChanged(Object.is),
        // TODO: CTOTECH-2046 Fix this the next time this file is edited.
        // eslint-disable-next-line @typescript-eslint/no-shadow
        tap((value) => log.debug(`updated`, mPath.join("."), value))
      ) // TODO: CTOTECH-2046 Fix this the next time this file is edited.
      // eslint-disable-next-line @typescript-eslint/no-shadow
      .subscribe((value) => {
        setValue(value);
      });

    return () => subscription.unsubscribe();
  }, [store$, mPath]);

  const onChange = useCallback(
    (change: Change<PathValue<T, K>>) => {
      const prevValue = getDeep(store$.value, mPath);
      const nextValue = applyChange<PathValue<T, K>>(prevValue, change);

      // If values are structually equal, they are not persisted and do not
      // update the store
      if (isEqual(prevValue, nextValue)) {
        return;
      }

      log.debug(`set`, mPath.join("."), "=", nextValue);
      store$.next(setDeep(store$.value, mPath, nextValue));
    },
    [store$, mPath]
  );

  // Check the rate limiter, if we trip it then we should throw
  if (!limiter.take()) {
    log.warn([
      `UI State rate limiter tripped!`,
      ``,
      `This can occur if you have items in state which are causing a delayed loop.`,
      ``,
      `If this isn't the case and you need a faster rate contact citadel-x@citadel.com`,
    ]);
    throw new Error(`cdx-ui-state rate limit exceeded`);
  }

  return [value, onChange];
};
