import { Button, Intent } from "@blueprintjs/core";
import { globalActions, useGlobal } from "../../../stores/global/global-store";

export const StartAssessmentButton = () => {
  const { dispatch } = useGlobal();

  const openDialog = () => {
    dispatch({ type: globalActions.SET_IS_START_ASSESSMENT_DIALOG_OPEN, payload: true });
  };

  return (
    // TODO: (Zoh) Disable the button only if the assessment is completed or expired.
    // TODO: (Zoh) Skip the dialog if the assessment is already started.
    <>
      <Button intent={Intent.SUCCESS} icon="play" onClick={openDialog}>
        Start Assessment
      </Button>
    </>
  );
};
