import { useMemo } from "react";
import type { Assessment, AssessmentGroup, AssessmentItemFormatter } from "../types/assessment";

const formatterToLabel: Record<AssessmentItemFormatter, string> = {
  numeric: "Numeric",
  accountingDollar: "Dollars",
  accountingEuro: "Euros",
  percentage: "Percentage",
};

export const useAssessmentWithScratch = (assessment: Assessment, scratchLines: AssessmentItemFormatter[]) => {
  const assessmentWithScratch = useMemo(() => {
    const scratchGroup = scratchLines && {
      id: "scratch",
      label: "Scratch Area",
      labelStyle: "D",
      groups: scratchLines.map((formatter, idx) => ({
        id: `scratch-${idx}`,
        label: formatterToLabel[formatter],
        labelStyle: "D",
        formatter,
        precision: 2,
        groups: [],
        // TODO: CTOTECH-2046 Fix this the next time this file is edited.
        // eslint-disable-next-line @typescript-eslint/no-shadow
        entries: assessment.columns.map((column, idx) => ({
          id: `scratch-${idx}-${column}`,
          column: idx,
          formula: null,
          value: null,
          editable: true,
          scratch: true,
        })),
      })),
    };
    return scratchGroup
      ? { ...assessment, groups: [...assessment.groups, scratchGroup as unknown as AssessmentGroup] }
      : assessment;
  }, [assessment, scratchLines]);
  return assessmentWithScratch;
};
