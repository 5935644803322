import { applyMiddleware, createStore } from "redux";
import type { Assessment } from "../../types/assessment";
import type { AssessmentStoreState } from "../../types/stores/assessment-store";
import { getAssessmentLookup } from "../../utils/assessment";
import { reducer } from "./assessment-reducer";
import { buildMiddleware } from "./assessment-middleware";

export const buildAssessmentStore = (assessment: Assessment, updateEntry: (options?: any) => void) => {
  const initialState: AssessmentStoreState = {
    assessment,
    assessmentGroupLookup: getAssessmentLookup(assessment),
    selectedCell: undefined,
    editingCell: undefined,
    copyCell: undefined,
    cellReference: undefined,
    referencedCells: [],
    cellUpdateBatchActive: true,
    pasteableRange: [],
  };

  return createStore(reducer, initialState, applyMiddleware(buildMiddleware(updateEntry)));
};
