import { AnchorButton, Intent } from "@blueprintjs/core";

interface ContactSupportButtonProps {
  body?: string;
  subject?: string;
}

export const ContactSupportButton = ({ body, subject }: ContactSupportButtonProps): JSX.Element => {
  const params = new URLSearchParams();
  if (body) {
    params.append("body", body);
  }
  if (subject) {
    params.append("subject", subject);
  }
  const href = encodeURI(`mailto:HRTechSupport@citadel.com?${params.toString()}`);

  return <AnchorButton icon="envelope" text="Contact Support" intent={Intent.WARNING} href={href} />;
};
