import { Fragment } from "react";
import { H6, Intent, TextArea } from "@blueprintjs/core";
import styled from "@emotion/styled";
import type { ReportUUID } from "./types/base";
import type { Report } from "./types/Report";

interface DescriptionPaneProps {
  report: Report;
  onChange: (reportUUID: ReportUUID, change: string) => void;
}

const StyledH6 = styled(H6)`
  margin-bottom: 5px;
`;

const StyledTextArea = styled(TextArea)`
  width: 100%;
  max-width: 100%;
  min-height: 100px;
  margin-bottom: 20px;
`;

export const DescriptionPane = ({ report, onChange }: DescriptionPaneProps) => {
  return (
    <Fragment>
      <StyledH6>{report.completed ? "Described Issue" : "Describe your issue"}</StyledH6>
      <p className="bp3-text-muted bp3-text-small">
        Be specific when referencing items such as trade ids, symbols, currencies, etc.
      </p>
      <StyledTextArea
        growVertically={true}
        large={true}
        intent={Intent.PRIMARY}
        onChange={(e) => onChange(report.id, e.target.value)}
        value={report.description}
        disabled={report.completed}
        data-testid="screenshot-description"
      />
    </Fragment>
  );
};
