import type * as React from "react";
import { Button, FormGroup, MenuItem } from "@blueprintjs/core";
import type { SelectProps as BlueprintSelectProps, ItemRenderer } from "@blueprintjs/select";
import { Select as BlueprintSelect } from "@blueprintjs/select";
import styled from "@emotion/styled";
import { highlightText } from "./highlightText";

const OuterDiv = styled.div`
  display: flex;
  gap: 10px;
  padding: 0 5px;
`;

export type BaseValueType = string | number | null | undefined;
export interface SelectProps<T extends BaseValueType> extends Partial<BlueprintSelectProps<T>> {
  options: { label: string; value: T }[];
  value: T;
  onChange?: (newVal: T, event?: React.SyntheticEvent<HTMLElement, Event> | undefined) => void;
  disableFilter?: boolean;

  label?: string;
  className?: string;
  formGroupProps?: React.ComponentProps<typeof FormGroup>;
  menuItemProps?: React.ComponentProps<typeof MenuItem>;
  buttonProps?: React.ComponentProps<typeof Button>;
}

/**
 * A thin, extendable Wrapper around Blueprintjs Select component.
 * Simply provide a list of options, and make the component controlled using `value` and `onChange` props.
 */
export const Select = <T extends BaseValueType = string>({
  options,
  value,
  onChange,
  filterable,
  disableFilter,
  className,
  label,
  formGroupProps,
  menuItemProps,
  buttonProps,
  children,
  ...rest
}: React.PropsWithChildren<SelectProps<T>>) => {
  const selectedOption = options.find((option) => option.value === value);

  const itemRenderer: ItemRenderer<T> = (item, { handleClick, modifiers: { active, disabled }, query }) => {
    return (
      <MenuItem
        key={item}
        active={active}
        disabled={disabled}
        text={highlightText(options.find((option) => option.value === item)?.label ?? "", query)}
        onClick={handleClick}
        {...menuItemProps}
      />
    );
  };

  return (
    <OuterDiv className={className}>
      <FormGroup inline label={label} {...formGroupProps}>
        <BlueprintSelect
          // TODO: CTOTECH-2046 Fix this the next time this file is edited.
          // eslint-disable-next-line @typescript-eslint/no-shadow
          items={options.map(({ value }) => value)}
          activeItem={value}
          onItemSelect={onChange ?? (() => {})}
          itemRenderer={itemRenderer}
          popoverProps={{
            // avoid clipping to dashboard widget:
            boundary: "window",
            ...rest.popoverProps,
          }}
          {...rest}
          filterable={filterable ?? !disableFilter}
        >
          {children ?? (
            <Button
              text={selectedOption?.label}
              rightIcon="double-caret-vertical"
              disabled={rest.disabled}
              {...buttonProps}
            />
          )}
        </BlueprintSelect>
      </FormGroup>
    </OuterDiv>
  );
};
