const SECONDS = 100;

export interface TokenBucketOptions {
  rate?: number;
  burst?: number;
  start?: number;
  period?: number;
}

export class TokenBucket {
  readonly #rate: number;

  /**
   * #burst is the maximum number of tokens which can be accrued referred to as
   * the burst rate
   */
  readonly #burst: number;
  readonly #period: number;
  #ts: number;
  #tokens: number;

  constructor({ rate = 1000, burst = 1000, start = Date.now(), period = 1 * SECONDS }: TokenBucketOptions = {}) {
    this.#rate = rate;
    this.#burst = burst;
    this.#period = period;
    this.#ts = start;
    this.#tokens = burst;
  }

  get tokens() {
    const now = Date.now();

    const delay = now - this.#ts;
    const topup = Math.floor((delay / this.#period) * this.#rate);
    const count = Math.min(this.#burst, Math.max(this.#tokens + topup, 0));

    return count;
  }

  take(take = 1, now = Date.now()): boolean {
    const tokens = this.tokens - take;
    if (tokens < 0) {
      return false;
    }

    this.#ts = now;
    this.#tokens = tokens;
    return true;
  }
}
