import styled from "@emotion/styled";
const DEFAULT_SIZE: number = 38;

export interface BaseAvatarProps {
  username: string;
  firstName?: string | null;
  lastName?: string | null;
  size?: number;
}

export interface AvatarProps extends BaseAvatarProps {
  photo: string | undefined | null;
}

export const Avatar = ({ size = DEFAULT_SIZE, firstName, lastName, username, photo }: AvatarProps) => {
  const initials = firstName && lastName ? `${firstName[0]}${lastName[0]}` : `${username[0]}${username[1]}`;
  const imageStyle = photo ? { backgroundImage: `url(${photo})` } : undefined;

  return (
    <CdxAvatar
      className="cdx-avatar"
      onClick={() => {}}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        lineHeight: `${size}px`,
        fontSize: `${size >> 1}px`,
      }}
    >
      <CdxAvatarInitials data-testid="avatar-initials">{initials}</CdxAvatarInitials>
      <CdxAvatarImage data-testid="avatar-image" style={imageStyle} />
    </CdxAvatar>
  );
};

const CdxAvatar = styled.figure`
  display: inline-block;
  flex: none;
  position: relative;
  overflow: hidden;
  margin: 1px;
  padding: 0;
  border-radius: 50%;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.2s linear, transform 0.2s ease-in;
  text-align: center;
  font-weight: bold;
  color: #99bad4; /* blue-c2 */
  background-color: #3475a9; /* blue-c4 */
  outline: none;

  .bp3-dark & {
    color: #9aacc4; /* dark-blue-c2 */
    background-color: #355989; /* dark-blue-c4 */
  }
`;

const CdxAvatarInitials = styled.div`
  text-transform: uppercase;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  margin: 0;
  text-align: center;
`;

const CdxAvatarImage = styled.div`
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  margin: 0;
`;
