import { identity } from "lodash";
import { CELL_REF_REGEX_WHOLE } from "../constants/grid";
import type { Assessment, AssessmentGroupLookup, AssessmentItemCoords } from "../types/assessment";
import { findItemInAssessment, getAssessmentLookup, toCoords, toCoordsString } from "./assessment";

export const COORDS_STR_ERROR = "coordsStr must be defined and non-empty";
export const CIRCULAR_EQUATION_ERROR = "Circular equations not allowed";
export const BAD_CELL_REFERENCE_ERROR = "Bad cell reference for given Assessment";

export const calcEquation = (
  calcCoords: AssessmentItemCoords,
  assessment: Assessment,
  assessmentLookup: AssessmentGroupLookup = getAssessmentLookup(assessment)
): number | undefined => {
  const visitedCoords: Set<string> = new Set();
  // for resolved recoords, numbers are already computed cells, null is a cell that can't be computed, and undefined is a cell that has not yet been computed
  const resolvedCoords: Record<string, number | null | undefined> = {};
  const getItem = findItemInAssessment(assessment);
  const getCoords = toCoords(assessment, assessmentLookup);
  const coordsString = toCoordsString(assessmentLookup)(calcCoords);
  const getEquation = (coords: AssessmentItemCoords) => {
    const item = getItem(coords);
    return item?.formula ?? "";
  };

  // TODO: CTOTECH-2046 Fix this the next time this file is edited.
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const parseEquation = (coordsString: string): number | undefined => {
    if (!coordsString) {
      throw COORDS_STR_ERROR;
    }

    // check if the equation has already been resolved
    const resolved = resolvedCoords[coordsString];
    // if resolved is null, it has already been calculated and should be counted as undefined
    if (resolved !== undefined) {
      return resolved ?? undefined;
    }

    // check if we have already visited. if we have, but we do not have an answer, we are in a loop
    if (visitedCoords.has(coordsString)) {
      throw CIRCULAR_EQUATION_ERROR;
    }

    // add current to the visited set
    visitedCoords.add(coordsString);

    const coords = getCoords(coordsString);
    if (!coords) {
      // TODO: throw new Error("...")
      // eslint-disable-next-line no-throw-literal
      throw `${BAD_CELL_REFERENCE_ERROR}: ${coordsString}`;
    }
    const equation = getEquation(coords);

    // regex to get references in the string to `4:5:0` syntax
    const cellRefs = equation.match(CELL_REF_REGEX_WHOLE);

    // find matches and replace the occurrences with recursive call to parseEquation
    const matchEquations = cellRefs?.map(parseEquation);
    const newEquation = (
      matchEquations
        ? matchEquations.reduce(
            (acc: string, curr, idx) => acc.replace((cellRefs as RegExpMatchArray)[idx], `(${curr ?? 0})`), // default undefines to 0 for calculation purposes
            equation
          )
        : equation
    ).replace(/^=/, "");
    const answer: number | null = eval(newEquation) ?? null;
    resolvedCoords[coordsString] = answer;
    return answer ?? undefined;
  };

  return parseEquation(coordsString as string);
};

export const parseCellReferences = (
  assessment: Assessment,
  assessmentLookup: AssessmentGroupLookup = getAssessmentLookup(assessment)
) => {
  const toCoordsFunc = toCoords(assessment, assessmentLookup);
  return (equation: string) =>
    (equation.match(CELL_REF_REGEX_WHOLE)?.map(toCoordsFunc).filter(identity) as AssessmentItemCoords[]) || [];
};
