import type { ReactNode } from "react";

export type BDGridRowColLabelProps = {
  children: ReactNode;
  className?: string;
};

export const BDGridRowColLabel = ({ className = "", children }: BDGridRowColLabelProps) => (
  <span className={className}>{children}</span>
);
