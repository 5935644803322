import { Fragment, useCallback, useEffect, useRef, useState, useMemo } from "react";
import type { TabId } from "@blueprintjs/core";
import { Dialog, Button, Intent, Classes } from "@blueprintjs/core";
import styled from "@emotion/styled";
import produce from "immer";
import type { FileRejection } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import type { CdxUiReportIssueConfig } from "@citadel/cdx-config";
import { showToast } from "@citadel/cdx-provider-toasts";
import type { CurrentUser } from "../../hooks/useCurrentUser";
import { DescriptionPane } from "./DescriptionPane";
import { AllMedia } from "./media/AllMedia";
import type { screenshotOptions } from "./media/takeScreenshot";
import { takeScreenshot } from "./media/takeScreenshot";
import { ReportIssueButton } from "./ReportIssueButton";
import type { ReportUUID } from "./types/base";
import type { ReportIssueConfig } from "./types/config";
import { ReportIssueOption } from "./types/config";
import { getAdapters } from "./data-adapter/availableDataAdapters";
import type { AdapterStep } from "./data-adapter/dataAdapterBase";
import { createToast, updateToast } from "./progressToast";
import { flattenReportMedia } from "./media/edit";
import { defaultApplicationConfig, defaultDynamicEmailConfig, defaultPermit } from "./defaultConfig";
import { usePersistedReports } from "./hooks/usePersistedReports";
import { DeveloperConfiguration } from "./DeveloperConfiguration";
import type { Annotation, EditorDimensions, Media } from "./types/Media";
import type { Report } from "./types/Report";
import { createMediaFromUpload } from "./media/uploadedMedia";
import { pluralSuffix } from "./plural";

const StyledDialog = styled(Dialog)`
  min-width: 60vw;
`;

const PaneWrapper = styled.div`
  margin: 20px 20px 0;
  min-height: 150px;
`;

const StyledButtonGroup = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

export interface ReportIssueProps {
  name?: string;
  config?: CdxUiReportIssueConfig;
  inSidebar: boolean;
  logs?: () => Record<string, Record<string, any>>;
}

export const ReportIssue = ({ name, config, inSidebar, logs = () => ({}) }: ReportIssueProps) => {
  const [activeReport, setActiveReport] = useState<ReportUUID | null>(null);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState<TabId>(0);
  const [takingScreenshot, setTakingScreenshot] = useState<boolean>(false);
  const [displayDeveloperConfiguration, setDisplayDeveloperConfiguration] = useState<boolean>(false);
  const { reports, createReport, setReports } = usePersistedReports(name || "shared", activeReport, setActiveReport);
  const dialogElementRef = useRef<HTMLDivElement | null>(null);
  const filterElementRef = useRef<Element | null>(null);

  const usableConfig: ReportIssueConfig = useMemo(() => {
    let toReturn: any = structuredClone(config || defaultApplicationConfig);
    if (toReturn.Email) {
      toReturn.Email = { ...toReturn.Email, ...defaultDynamicEmailConfig };
    }
    return { ...toReturn, ...defaultPermit };
  }, [config]);

  const handleOpenReport = useCallback(
    (reportId: ReportUUID | null, currentUser: CurrentUser, filterNode: Element | null) => {
      if (reportId) {
        setActiveReport(reportId);
        return;
      }
      if (activeReport === null) {
        filterElementRef.current = filterNode;
        const newReport = createReport(currentUser, "", logs);
        setActiveReport(newReport.id);
      }
    },
    [activeReport, createReport, logs]
  );

  const onDescriptionChange = useCallback(
    (reportUUID: ReportUUID, newDescription: string) => {
      if (reportUUID !== activeReport) {
        return;
      }

      setReports(
        produce((draft: Record<ReportUUID, Report>) => {
          draft[reportUUID].description = newDescription;
        })
      );
    },
    [activeReport, setReports]
  );

  const onMediaUsableChange = useCallback(
    (reportUUID: ReportUUID, usable: boolean) => {
      if (reportUUID !== activeReport) {
        return;
      }

      setReports(
        produce((draft: Record<ReportUUID, Report>) => {
          // eslint-disable-next-line no-param-reassign
          draft[reportUUID].media?.forEach((mediaItem) => (mediaItem.usable = usable));
        })
      );
    },
    [activeReport, setReports]
  );

  const onMediaRemove = useCallback(
    (reportUUID: ReportUUID, index: number) => {
      if (reportUUID !== activeReport) {
        return;
      }

      const nextReports = produce((draft: Record<ReportUUID, Report>) => {
        draft[reportUUID].media?.splice(index, 1);
      });
      setReports(nextReports);

      const updatedMediaLength = nextReports(reports)[reportUUID].media?.length || 1;
      if (selectedMediaIndex >= updatedMediaLength) {
        setSelectedMediaIndex(updatedMediaLength - 1);
      }
    },
    [activeReport, reports, selectedMediaIndex, setReports]
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (!activeReport || reports[activeReport].completed) {
        return;
      }

      if (rejectedFiles.length > 0) {
        const suffix = pluralSuffix(rejectedFiles.length, "format", "formats");
        const uniqueFileTypes = new Set(rejectedFiles.map((rejectedFile) => rejectedFile.file.type));
        const message =
          uniqueFileTypes.size > 1
            ? `Unsupported ${suffix} (${[...uniqueFileTypes].join(", ")}) uploaded.`
            : `Unsupported '${rejectedFiles[0].file.type}' ${suffix} uploaded.`;
        showToast({
          icon: "error",
          timeout: 3000,
          intent: Intent.WARNING,
          message,
        });
      }

      if (acceptedFiles.length === 0) {
        return;
      }

      const { media: currentMedia } = reports[activeReport];
      const newMedia = await createMediaFromUpload(currentMedia?.slice() || [], acceptedFiles);
      setReports(
        produce((draft: Record<ReportUUID, Report>) => {
          draft[activeReport].media = newMedia;
        })
      );
      setSelectedMediaIndex(newMedia.length - 1);
    },
    [activeReport, reports, setReports]
  );
  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg"],
    },
  });

  /**
   * When a region is removed, keyboard focus needs to be manually returned to
   * the blueprint dialog, otherwise keycommands do not work as expected.
   */
  const onRegionRemove = () => {
    const closestBlueprintDialogContainer: HTMLElement | null = dialogElementRef.current?.closest(
      ".bp3-dialog-container"
    ) as HTMLElement | null;
    closestBlueprintDialogContainer?.focus();
  };

  const onRegionsChange = useCallback(
    (reportUUID: ReportUUID, mediaIndex: number, regions: Annotation[]) => {
      if (reportUUID !== activeReport || !reports[reportUUID].media?.[mediaIndex]) {
        return;
      }

      setReports(
        produce((draft: Record<ReportUUID, Report>) => {
          (draft[reportUUID].media as Media[])[mediaIndex].annotations = regions;
        })
      );
    },
    [activeReport, reports, setReports]
  );

  const onEditorChange = useCallback(
    (reportUUID: ReportUUID, mediaIndex: number, editorDimensions: EditorDimensions) => {
      if (reportUUID !== activeReport) {
        return;
      }

      const { media: allMedia } = reports[activeReport];
      if (allMedia === null || !allMedia[mediaIndex]) {
        return;
      }

      const { editor } = allMedia[mediaIndex];
      if (editor !== null && JSON.stringify(editor) === JSON.stringify(editorDimensions)) {
        return;
      }
      setReports(
        produce((draft: Record<ReportUUID, Report>) => {
          (draft[reportUUID].media as Media[])[mediaIndex].editor = editorDimensions;
        })
      );
    },
    [activeReport, reports, setReports]
  );

  const onRequestScreenshot = useCallback(
    (reportUUID: ReportUUID) => {
      if (takingScreenshot) {
        return;
      }

      setTakingScreenshot(true);
      setReports(
        produce((draft: Record<ReportUUID, Report>) => {
          draft[reportUUID].media = null;
        })
      );

      const toFilterElements: Array<Element | null> = [];
      if (dialogElementRef.current) {
        // If there is a dialogElementRef, then the user is requesting a
        // screenshot while the Report Issue overlay is displayed. As a result
        // we need to filter the overlay from the screenshot by finding its
        // closest portal and excluding the domNode.
        toFilterElements.push(dialogElementRef.current.closest("." + Classes.PORTAL));
      }
      if (filterElementRef.current) {
        // A downstream component has passed a ref to be filtered.
        toFilterElements.push(filterElementRef.current);
      }

      const options: screenshotOptions = {
        additionalFilter: (domNode) => {
          for (const toFilterElement of toFilterElements) {
            if (toFilterElement === domNode) {
              return false;
            }
          }
          return true;
        },
      };

      let allowUpdate: boolean = true;
      // Take Screenshot on new Macrotask, it's computationally intensive.
      setTimeout(async () => {
        const retrievedScreenshot = await takeScreenshot(options);
        if (!allowUpdate) {
          return;
        }
        setReports(
          produce((draft: Record<ReportUUID, Report>) => {
            if (draft[reportUUID].media === null) {
              draft[reportUUID].media = [retrievedScreenshot];
            } else {
              (draft[reportUUID].media as Media[])[0] = retrievedScreenshot;
            }
          })
        );
        setTakingScreenshot(false);
      }, 1);

      return () => {
        allowUpdate = false;
      };
    },
    [setReports, takingScreenshot]
  );

  const handleFormSubmission = useCallback(async () => {
    if (activeReport === null) {
      return;
    }

    // Ensure the current report is submitting, so re-rendering will display the
    // active reporting UI.
    setReports(
      produce((draft: Record<ReportUUID, Report>) => {
        draft[activeReport].submitting = true;
      })
    );

    // Before reportering, modify flatten screenshot in a temporarily copied
    // Report.
    const clonedFlattenedReport = await flattenReportMedia(reports[activeReport]);
    const configuredAdapters = getAdapters(usableConfig, clonedFlattenedReport);
    let totalSteps = 0;
    let completedSteps = 0;
    let errored = false;
    const preparedAdapters = configuredAdapters.map((configuredAdapter) => {
      totalSteps += configuredAdapter.steps.length;
      return configuredAdapter.upload();
    });

    const toastId = createToast(0);
    async function executeOperations(operations: AsyncGenerator<AdapterStep, AdapterStep, unknown>) {
      for await (const progress of operations) {
        completedSteps++;
        updateToast(toastId, {
          completed: completedSteps,
          total: totalSteps,
          error: !progress.success,
          onClick: () => setDisplayDeveloperConfiguration(true),
        });
        if (!progress.success && activeReport) {
          errored = true;
          setActiveReport(null);
          setReports(
            produce((draft: Record<ReportUUID, Report>) => {
              draft[activeReport].submitting = false;
            })
          );
          return;
        }
      }
    }
    await Promise.all(preparedAdapters.map((preparedAdapter) => executeOperations(preparedAdapter)));
    setActiveReport(null);
    setReports(
      produce((draft: Record<ReportUUID, Report>) => {
        const updatedReport = draft[activeReport];
        updatedReport.submitting = false;
        updatedReport.completed = !errored;
        updatedReport.activity = String(new Date());
      })
    );
  }, [activeReport, reports, setReports, usableConfig]);

  useEffect(() => {
    if (activeReport && reports[activeReport].media === null) {
      onRequestScreenshot(reports[activeReport].id);
    }
  }, [activeReport, onRequestScreenshot, reports]);

  if (config?.enable === false) {
    return null;
  }
  const submitting = Object.values(reports).some((report) => report.submitting);
  return (
    <Fragment>
      <ReportIssueButton
        inSidebar={inSidebar}
        disabled={config === undefined}
        openReport={handleOpenReport}
        openDeveloperConfiguration={() => setDisplayDeveloperConfiguration(true)}
        submitting={submitting}
        reports={reports}
      />
      {displayDeveloperConfiguration ? (
        <DeveloperConfiguration onClose={() => setDisplayDeveloperConfiguration(false)} config={config} />
      ) : null}
      {activeReport !== null && reports[activeReport] && !submitting && !displayDeveloperConfiguration ? (
        <StyledDialog
          isOpen={true}
          icon="info-sign"
          title={reports[activeReport].completed ? "Issue Already Reported" : "Report Issue"}
          onClose={() => setActiveReport(null)}
        >
          <PaneWrapper {...getRootProps()} ref={dialogElementRef}>
            <DescriptionPane report={reports[activeReport]} onChange={onDescriptionChange} />
            {!usableConfig.screenshot || usableConfig.screenshot !== ReportIssueOption.DISALLOW ? (
              <AllMedia
                dropping={!reports[activeReport].completed && isDragActive}
                report={reports[activeReport]}
                index={Number(selectedMediaIndex)}
                onMediaUsableChange={onMediaUsableChange}
                onMediaRemove={onMediaRemove}
                onRequestScreenshot={onRequestScreenshot}
                onRegionRemove={onRegionRemove}
                onRegionsChange={onRegionsChange}
                onIndexChange={setSelectedMediaIndex}
                onEditorChange={onEditorChange}
              />
            ) : null}
            {!reports[activeReport].completed ? (
              <StyledButtonGroup>
                <Button text="Cancel" intent={Intent.NONE} onClick={() => setActiveReport(null)} />
                <Button
                  text="Submit"
                  intent={Intent.PRIMARY}
                  disabled={submitting || reports[activeReport].completed || reports[activeReport].description === ""}
                  onClick={handleFormSubmission}
                />
              </StyledButtonGroup>
            ) : null}
          </PaneWrapper>
        </StyledDialog>
      ) : null}
    </Fragment>
  );
};
