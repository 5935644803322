import type { ReactNode, SyntheticEvent } from "react";
import { useState, useRef, useEffect } from "react";
import type { ItemRenderer } from "@blueprintjs/select";
import { MultiSelect } from "@blueprintjs/select";
import debounce from "lodash/debounce";
import type { ApolloError } from "@apollo/client";
import type { IPopoverProps, TagProps } from "@blueprintjs/core";
import { Box } from "../containers/Box";
import { AvatarConnected } from "../avatar";
import type { Core_LdapUser } from "../../_generated/graphql";
import { highlightText } from "./textHighlight";
import { SelectMenuItem } from "./SelectMenuItem";
import { useLdapUsers } from "./hooks/useLdapUsers";
import { useTransformedUsers } from "./hooks/useTransformUserList";

const UserSelect = MultiSelect.ofType<Core_LdapUser>();

const renderUserList: ItemRenderer<Core_LdapUser> = (user, { modifiers, handleClick, query }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <SelectMenuItem
      active={modifiers.active}
      icon={
        <Box alignItems="center" margin="0 5px 0 0">
          <AvatarConnected username={user.sAMAccountName || ""} size={28} />
        </Box>
      }
      key={user.sAMAccountName as string}
      label={user.mail || ""}
      onClick={handleClick}
      text={highlightText(`${user.givenName} ${user.sn}`, query)}
      shouldDismissPopover={false}
    />
  );
};

const areUsersEqual = (a: Core_LdapUser, b: Core_LdapUser): boolean => {
  return a.sAMAccountName === b.sAMAccountName;
};

const renderDefaultTag = (item: Core_LdapUser): ReactNode => {
  return (
    <Box alignItems="center" height={16}>
      <Box alignItems="center" margin="0 5px 0 0">
        <AvatarConnected username={item?.sAMAccountName || ""} size={16} />
      </Box>
      {item ? `${item.givenName} ${item.sn} (${item.sAMAccountName})` : "(unknown)"}
    </Box>
  );
};
export interface UserSelectorProps {
  className?: string;
  fill?: boolean;
  placeholder?: string;
  selectedItems: (Core_LdapUser | string)[];
  resetOnSelect?: boolean;
  maxItemsShow?: number;
  debounceDelay?: number;
  tagProps?: TagProps;
  disabled?: boolean;
  popoverProps?: Partial<IPopoverProps> & object;
  onItemSelect: (item: Core_LdapUser, event?: SyntheticEvent<HTMLElement>) => void;
  onTagRemove: (value: ReactNode, index: number) => void;
  renderTag?: (item: Core_LdapUser) => ReactNode;
  onError?: (error: ApolloError) => void;
}

export const UserSelector = ({
  className,
  fill = true,
  placeholder = "Search by username",
  selectedItems,
  maxItemsShow = 10,
  resetOnSelect = true,
  debounceDelay = 200,
  tagProps = { minimal: false },
  popoverProps = { usePortal: false, minimal: true },
  disabled = false,
  onItemSelect,
  renderTag,
  onTagRemove,
  onError,
}: UserSelectorProps) => {
  const [skip, setSkip] = useState<boolean>(true);
  const [userQuery, setUserQuery] = useState<string>("");
  const debouncedSetUserQuery = useRef(debounce(setUserQuery, debounceDelay)).current;
  const { data: userList, error: ldapError } = useLdapUsers({
    query: userQuery,
    skip,
    slice: maxItemsShow,
  });
  const { data: selectedUsers, error: transformError } = useTransformedUsers(selectedItems);

  const error = ldapError ?? transformError;

  useEffect(() => {
    if (error && onError) {
      onError(error);
    }
  }, [error, onError]);

  const handleQueryChange = (query: string) => {
    if (query) {
      debouncedSetUserQuery(query);
      setSkip(false);
    } else {
      setSkip(true);
    }
  };

  return (
    <UserSelect
      fill={fill}
      resetOnSelect={resetOnSelect}
      popoverProps={popoverProps}
      className={className}
      itemRenderer={renderUserList}
      itemsEqual={areUsersEqual}
      items={userList}
      onQueryChange={handleQueryChange}
      tagRenderer={renderTag ? renderTag : renderDefaultTag}
      onItemSelect={onItemSelect}
      selectedItems={selectedUsers}
      tagInputProps={{
        onRemove: onTagRemove,
        placeholder,
        tagProps,
        disabled,
      }}
    />
  );
};
