import type * as React from "react";
import { Button, Intent, NonIdealState, Pre } from "@blueprintjs/core";
import { ContactSupportButton } from "../contact-support-button";
import classes from "./error-state.module.less";

interface ErrorStateProps {
  /** The title of the error. Default value is "An error occurred". */
  title?: string;
  /** A longer description of the error. */
  description?: string | React.ReactNode;
  /** Technical details of the error, e.g., a stacktrace. Will be wrapped in a codeblock. */
  details?: string;
  /** If true, a button to reload the page will be rendered. */
  reload?: boolean;
  /** If true, a button to contact support will be rendered. */
  support?: boolean;
}

/** Component rendering a centered panel to indicate that an error occurred. */
export const ErrorState = ({
  title = "An error occurred",
  description,
  details,
  reload = false,
  support = false,
}: ErrorStateProps): JSX.Element => {
  const descriptionElement = (
    <>
      {description && <div>{description}</div>}
      {details && <Pre className={classes.codeBlock}>{details}</Pre>}
    </>
  );

  return (
    <NonIdealState
      icon="error"
      title={title}
      description={descriptionElement}
      action={
        <div className={classes.buttons}>
          {reload && (
            <Button
              icon="refresh"
              text="Reload Page"
              intent={Intent.PRIMARY}
              onClick={() => window.location.reload()}
            />
          )}
          {support && <ContactSupportButton />}
        </div>
      }
    />
  );
};
