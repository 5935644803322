// This file is a copy of the original create-schema-link by apollo
// and is copy pasted. I have adapted it to support subscriptions
// which they still do not support.. for some reason unclear to me as someone fixed it for them
// bugs needed to be fixed along the way
// See the thread here:
// https://github.com/apollographql/apollo-link/issues/917
import type { DocumentNode, GraphQLSchema } from "graphql";
import { execute, subscribe, validate } from "graphql";
import type { Operation, FetchResult } from "@apollo/client";
import { ApolloLink } from "@apollo/client";
import { getMainDefinition, Observable } from "@apollo/client/utilities";
import type { SubscriptionObserver } from "zen-observable-ts";
import { createAsyncIterator, forAwaitEach, isAsyncIterable } from "iterall";

const isSubscription = (query: DocumentNode) => {
  const main = getMainDefinition(query);
  return main.kind === "OperationDefinition" && main.operation === "subscription";
};

export interface SchemaLinkOptions {
  /**
   * The schema to generate responses from.
   */
  schema: GraphQLSchema;
  /**
   * The root value to use when generating responses.
   */
  rootValue?: any;
  /**
   * A context to provide to resolvers declared within the schema.
   */
  context?: ResolverContext | ResolverContextFunction;
  /**
   * Validate incoming queries against the given schema, returning
   * validation errors as a GraphQL server would.
   */
  validate?: boolean;
}

type ResolverContext = Record<string, any>;
type ResolverContextFunction = (operation: Operation) => ResolverContext | PromiseLike<ResolverContext>;

const resolveContext = async (
  operation: Operation,
  context?: ResolverContext | ResolverContextFunction
): Promise<ResolverContext> => {
  if (!context) {
    return operation.getContext();
  }
  if (typeof context === "function") {
    return context(operation);
  }

  return {
    ...context,
    ...operation.getContext(),
  };
};

export class SchemaLink extends ApolloLink {
  public schema: SchemaLinkOptions["schema"];
  public rootValue: SchemaLinkOptions["rootValue"];
  public context: SchemaLinkOptions["context"];
  public validate: boolean;

  constructor(options: SchemaLinkOptions) {
    super();
    this.schema = options.schema;
    this.rootValue = options.rootValue;
    this.context = options.context;
    this.validate = !!options.validate;
  }

  private async getResult(operation: Operation): Promise<FetchResult> {
    // context
    const context = await resolveContext(operation, this.context);

    // get result
    if (this.validate) {
      const validationErrors = validate(this.schema, operation.query);
      if (validationErrors.length > 0) {
        return { errors: validationErrors };
      }
    }

    const executor: any = isSubscription(operation.query) ? subscribe : execute;
    return executor(
      this.schema,
      operation.query,
      this.rootValue,
      context,
      operation.variables,
      operation.operationName
    );
  }

  private async observe(operation: Operation, observer: SubscriptionObserver<FetchResult>): Promise<void> {
    try {
      const result = await this.getResult(operation);
      const iterable = isAsyncIterable(result) ? result : createAsyncIterator([result]);
      await forAwaitEach(iterable as any, (value: any) => observer.next(value))
        .then(() => observer.complete())
        .catch((error) => observer.error(error));
    } catch (e) {
      observer.error(e);
    }
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable<FetchResult>((observer) => {
      // fire off async request resolution
      this.observe(operation, observer);
    });
  }
}
