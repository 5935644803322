import { toPercentage } from "@citadel/pse-lib-ui";
import type { AssessmentItemFormatter } from "../types/assessment";
import { toNumeric } from "./to-numeric";

export const formatItem = (value: number | null | undefined, formatter: AssessmentItemFormatter, precision = 0) => {
  if (value === Infinity || Number.isNaN(value)) {
    return "#DIV/0!";
  }

  switch (formatter) {
    case "percentage":
      return toPercentage(value, precision);
    case "accountingDollar":
      return toNumeric(value, "USD", precision);
    case "accountingEuro":
      return toNumeric(value, "EUR", precision);
    default:
      return toNumeric(value, undefined, precision);
  }
};
