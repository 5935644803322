import { createContext } from "react";

/**
 * This is a low level indicator of whether the app should be in dark mode
 *
 * Defaults to dark mode to maintain compatibility with default for cdx-app
 *
 * @internal API subject to change without notice
 */
export const DarkModeContext = createContext<boolean>(true);
