import type { ApolloError } from "@apollo/client";
import { Icon } from "@blueprintjs/core";
import type { ItemRenderer } from "@blueprintjs/select";
import { MultiSelect } from "@blueprintjs/select";
import styled from "@emotion/styled";
import debounce from "lodash/debounce";
import type { ReactNode } from "react";
import { useEffect, useRef } from "react";
import { Box } from "../../containers/Box";
import { SelectMenuItem } from "../SelectMenuItem";
import { highlightText } from "../textHighlight";
import type { WindowsGroup } from "./WindowsGroup";

export interface GroupSelectorBaseProps {
  setGroupQuery: React.Dispatch<React.SetStateAction<string>>;
  debounceDelay: number;
  error: ApolloError | undefined;
  onError: ((error: ApolloError) => void) | undefined;
  setSkip: React.Dispatch<React.SetStateAction<boolean>>;
  fill: boolean;
  resetOnSelect: boolean;
  className: string | undefined;
  groupList: WindowsGroup[];
  renderTag: ((item: WindowsGroup) => ReactNode) | undefined;
  onItemSelect: (item: WindowsGroup, event?: React.SyntheticEvent<HTMLElement>) => void;
  selectedItems: WindowsGroup[];
  onTagRemove: (value: ReactNode, index: number) => void;
  placeholder: string;
}

export function GroupSelectorBase({
  setGroupQuery,
  debounceDelay,
  error,
  onError,
  setSkip,
  fill,
  resetOnSelect,
  className,
  groupList,
  renderTag,
  onItemSelect,
  selectedItems,
  onTagRemove,
  placeholder,
}: GroupSelectorBaseProps) {
  const debouncedSetGroupQuery = useRef(debounce(setGroupQuery, debounceDelay)).current;

  useEffect(() => {
    if (error && onError) {
      onError(error);
    }
  }, [error, onError]);

  const handleQueryChange = (query: string) => {
    if (query) {
      debouncedSetGroupQuery(query);
      setSkip(false);
    } else {
      setSkip(true);
    }
  };

  return (
    <GroupSelect
      fill={fill}
      resetOnSelect={resetOnSelect}
      popoverProps={{ usePortal: false, minimal: true }}
      className={className}
      itemRenderer={renderUserList}
      itemsEqual={areGroupsEqual}
      items={groupList}
      onQueryChange={handleQueryChange}
      tagRenderer={renderTag ? renderTag : renderDefaultTag}
      onItemSelect={onItemSelect}
      selectedItems={selectedItems}
      tagInputProps={{
        onRemove: onTagRemove,
        placeholder,
      }}
    />
  );
}

const GroupSelect = MultiSelect.ofType<WindowsGroup>();

const areGroupsEqual = (a: WindowsGroup, b: WindowsGroup): boolean => {
  return a?.name === b?.name;
};

const renderDefaultTag = (item: WindowsGroup): ReactNode => {
  return (
    <Box alignItems="center">
      <PeopleIcon icon="people" className="icon" iconSize={12} />
      {item.name}
    </Box>
  );
};
const renderUserList: ItemRenderer<WindowsGroup> = (group, { modifiers, handleClick, query }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <SelectMenuItem
      active={modifiers.active}
      key={group.sid as string}
      icon="people"
      label={group.email as string}
      onClick={handleClick}
      text={highlightText(group.name || "", query)}
      shouldDismissPopover={false}
    />
  );
};

const PeopleIcon = styled(Icon)`
  padding-right: 4px;
`;
