import { Colors, Position, Classes, AnchorButton } from "@blueprintjs/core";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import styled from "@emotion/styled";
import { useEffect, useRef } from "react";
import type { CurrentUser } from "../../hooks";
import { useCurrentUser } from "../../hooks";
import { reportsExist } from "./report";
import { ReportedIssuesMenu } from "./ReportedIssuesMenu";
import type { ReportUUID } from "./types/base";
import type { Report } from "./types/Report";

export const TOOLTIP_CONTENT = "Report an issue with this application";

const StyledSidebarReportIssueButton = styled(AnchorButton)`
  padding: 0 8px;
  height: 18px;
  width: 100%;
  justify-content: flex-start;

  .bp3-icon > svg,
  .bp3-spinner-animation > svg {
    width: 13px;
    height: 13px;
  }
  .is-dev & .bp3-icon > svg,
  .is-dev & .bp3-spinner-animation > svg {
    color: ${Colors.INDIGO5};
  }
  .bp3-button-text {
    visibility: visible !important;
  }
  .is-dev & .bp3-button-text {
    color: ${Colors.WHITE};
  }
`;

const StyledReportIssueButton = styled(AnchorButton)`
  .is-dev & .bp3-icon > svg {
    color: ${Colors.WHITE};
  }
`;

interface ReportIssueButtonProps {
  inSidebar: boolean;
  submitting: boolean;
  reports: Record<ReportUUID, Report>;
  openReport: (reportId: ReportUUID | null, currentUser: CurrentUser, filterNode: Element | null) => void;
  openDeveloperConfiguration: () => void;
  disabled: boolean;
}

export function ReportIssueButton({
  submitting,
  reports,
  openReport,
  openDeveloperConfiguration,
  disabled,
  inSidebar,
}: ReportIssueButtonProps) {
  const currentUser = useCurrentUser();
  const tooltipRef = useRef<HTMLSpanElement | null>(null);
  // <noscript> HTMLElement is unnecessary if JavaScript is already executing.
  useEffect(() => {
    const noScriptElements = document.getElementsByTagName("noscript");
    for (const noScriptElement of noScriptElements) {
      noScriptElement.remove();
    }
  }, []);

  const ButtonElement = inSidebar ? StyledSidebarReportIssueButton : StyledReportIssueButton;
  if (disabled) {
    return (
      <Tooltip2
        content="Please ask the developer of this app to configure the Reporting Issue feature."
        position={inSidebar ? Position.LEFT : Position.BOTTOM}
      >
        <ButtonElement disabled={true} minimal icon="error" text={inSidebar ? "Report Issue" : undefined} />
      </Tooltip2>
    );
  }

  if (reportsExist(reports)) {
    return (
      <Popover2
        disabled={submitting}
        content={
          <ReportedIssuesMenu
            reports={reports}
            openReport={openReport}
            openReportConfiguration={openDeveloperConfiguration}
          />
        }
        placement={inSidebar ? Position.LEFT : Position.BOTTOM}
      >
        <ButtonElement
          minimal
          tabIndex={undefined}
          onClick={() => null}
          icon="help"
          loading={submitting}
          text={inSidebar ? (submitting ? "Reporting Issue" : "Report Issue") : undefined}
        />
      </Popover2>
    );
  }

  return (
    <Tooltip2
      content={<span ref={tooltipRef}>{TOOLTIP_CONTENT}</span>}
      position={inSidebar ? Position.LEFT : Position.BOTTOM}
    >
      <ButtonElement
        minimal
        id="testing-id"
        onClick={() => openReport(null, currentUser.data!, tooltipRef.current?.closest("." + Classes.OVERLAY) || null)}
        disabled={!currentUser.data}
        icon="help"
        loading={submitting}
        text={inSidebar ? "Report Issue" : undefined}
      />
    </Tooltip2>
  );
}
