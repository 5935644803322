import { BehaviorSubject, distinctUntilChanged, filter, map } from "rxjs";
import { logger } from "../logger";
import type { JsonWebToken } from "../api/JsonWebToken";
import type { AuthenticationSession } from "../api/AuthenticationSession";
import { isNotNull } from "../utils/isNotNull";
import { handleTokenRefresh, resolveCurrentState } from "./handlers";
import type { SessionResolveResult, SessionData } from "./types";
import { MAX_RETRIES, retry } from "./retry";
import { resolveSessionTokens, alreadyRefreshed } from "./resolveToken";
import { clearCurrentLeader, getSessionData } from "./localStorage";
import { isTokenValid } from "./verifyToken";

/**
 * Use this to determine the status of a ping federate authentication session.
 */
export const enum PingFederateSessionStatus {
  /**
   * The user doesn't have a valid token and we're attempting to get one for
   * them.
   */
  Authenticating,

  /**
   * The user has a valid token, we can start rendering the app on the
   * assumption that things are ok.
   */
  Authenticated,

  /**
   * The authentication has failed with an error, we should show the error
   * message to the app.
   */
  Error,
}

export interface SessionState {
  errorMessage: string | null;
  redirecting: boolean;
  sessionData: SessionData | null;
}

type SetIntervalReturn = ReturnType<typeof setInterval>;

/**
 * there are two mechanisms to update the TokenStore state:
 * - populate (blocking on getters; they will have to await the updated populated state)
 * - refresh (non blocking on any getters; will simply try to refresh the state in the background)
 *
 * populates trigger either on app load or when the session has expired (implies a refresh was missed or failed)
 * refreshes trigger on an interval based on the expiration of the prior resolved result
 */
export class PingFederateSession implements AuthenticationSession {
  // shared result to dedupe requests
  private pendingResult: Promise<SessionResolveResult> | null = null;
  // initialize promise to await the token store loading initial state
  private pendingInitialized: Promise<SessionResolveResult>;

  // public state values
  private errorMessage: string | null = null;
  private redirecting: boolean = false;

  /**
   * A behaviour subject which contains the session data and notifies sources if
   * it is changed.
   */
  #sessionData$ = new BehaviorSubject<SessionData | null>(null);

  /**
   * The current session data, or null if there isn't a current authentication
   * session.
   */
  private get sessionData(): SessionData | null {
    return this.#sessionData$.value;
  }

  /**
   * Set the session data, inform subscribers of the change.
   */
  private set sessionData(value: SessionData | null) {
    this.#sessionData$.next(value);
  }

  // this tracks direct refreshes from a token request and expiration, but not from initializing state
  private refreshing: boolean = false;
  // this tracks manual reset so we can prevent multiple resets kicking off simultaneously
  private resetting: boolean = false;

  // internal state values
  // the interval id for a future token refresh
  private refreshIntervalId: SetIntervalReturn | null = null;

  /**
   * The active ping federate session. Undefined until the active session is
   * first accessed.
   */
  static #activeSession: PingFederateSession | undefined;

  /**
   * The active session for ping federate within this browser window
   */
  static get activeSession(): PingFederateSession {
    if (!PingFederateSession.#activeSession) {
      PingFederateSession.#activeSession = new PingFederateSession();
    }

    return PingFederateSession.#activeSession;
  }

  /**
   * Use this to reset the active sesison during tests. May have unexpected
   * behaviour if used in non test environments.
   */
  static clearActiveSession(): void {
    this.#activeSession = undefined;
  }

  constructor() {
    this.pendingInitialized = this.populateState(0);
  }

  /**
   * Await this to get the current access token. Can be used anywhere and will
   * start the authentication process if it's not already started.
   */
  async getAccessToken(): Promise<JsonWebToken> {
    const sessionData = await this.getSessionDataAsync();
    const accessToken = sessionData?.accessToken?.token ?? undefined;

    if (accessToken === undefined) {
      throw new Error(`Unable to obtain access token: ${this.errorMessage ?? "no error info provided"}`);
    }

    return accessToken;
  }

  /**
   * {@inheritdoc AuthenticationSession.username}
   */
  get username(): string | undefined {
    const sessionData = this.getSessionData();
    return sessionData?.accessToken?.sAMAccountName ?? undefined;
  }

  /**
   * {@inheritdoc AuthenticationSession.getUsername}
   */
  async getUsername(): Promise<string> {
    const sessionData = await this.getSessionDataAsync();
    const username = sessionData?.accessToken?.sAMAccountName ?? undefined;

    if (this.redirecting) {
      logger.debug("Redirecting, returning a promise that will never resolve for getUsername()");
      return new Promise(() => {});
    }

    if (username === undefined) {
      throw new Error(`Unable to get username: ${this.errorMessage ?? "no error info provided"}`);
    }

    return username;
  }

  /**
   * {@inheritDoc AuthenticationSession.subscribeAccessToken}
   */
  subscribeAccessToken(listener: (accessToken: JsonWebToken) => void): { unsubscribe(): void } {
    return this.#sessionData$
      .pipe(
        map((sessionData) => sessionData?.accessToken?.token ?? null),
        filter(isNotNull),
        distinctUntilChanged()
      )
      .subscribe(listener);
  }

  // --------------------------------
  // ------- state management -------
  // --------------------------------

  // populate; this will use the shared pending result as it's a "block on await" call
  // meaning any network requests should wait to perform until the state is updated

  private async populateState(retryCount: number = MAX_RETRIES): Promise<SessionResolveResult> {
    this.pendingResult = this.pendingResult || this.resolveCurrentStateAndUpdateStore(retryCount);
    return this.pendingResult;
  }

  private async resolveCurrentStateAndUpdateStore(retryCount: number): Promise<SessionResolveResult> {
    if (retryCount === 0) {
      return await this.performFullStateResolution();
    }

    return await this.refresh(retryCount, this.sessionData);
  }

  // perform the full state resolution which will redirect in the
  // event that we are unable to resolve the current state
  private async performFullStateResolution(): Promise<SessionResolveResult> {
    const result = await resolveCurrentState();
    await this.updateState(result);

    // after successfully resolving state, we clear the current leader (see should-become-leader-for-refresh.ts for details)
    // if needed so other windows can reperform token validation
    if (result.sessionData?.refreshToken) {
      clearCurrentLeader();
    }
    return result;
  }

  /**
   * Refresh and update store with retry
   * @param sessionData processed jwt
   */
  private async refresh(retryCount: number, sessionData: SessionData | null): Promise<SessionResolveResult> {
    try {
      const refreshResult = await retry(
        () => handleTokenRefresh(sessionData),
        retryCount,
        (result) => !!result.sessionData
      );
      await this.updateState(refreshResult);
      return refreshResult;
    } catch (e) {
      logger.warn("Fail to refresh the token", e);
      return await this.performFullStateResolution();
    }
  }

  // refresh; this will not use the shared pending result as it's an ad hoc refresh
  // and the current session should still be active
  private async refreshState(): Promise<SessionResolveResult | undefined> {
    this.clearRefresh();

    const sessionDataParsed = getSessionData();
    const { sessionData, refreshIntervalInMillisecond } = resolveSessionTokens(sessionDataParsed);

    // if the current time left is still ample, do not refresh just yet
    if (alreadyRefreshed(refreshIntervalInMillisecond)) {
      logger.info("Find token already refreshed, reschedule to ", refreshIntervalInMillisecond);
      this.setNextRefresh(refreshIntervalInMillisecond);
      return;
    }

    this.refreshing = true;
    return await this.refresh(MAX_RETRIES, sessionData || this.sessionData);
  }

  // shared update method to propagate a resolved result to the different state values
  private async updateState(result: SessionResolveResult) {
    // for refreshes we want to be kinder in case there's a hiccup and leave the current auth as-is
    if (!this.refreshing || result.sessionData) {
      this.sessionData = result.sessionData ?? null;
    }

    this.errorMessage = result.errorMessage ?? null;
    this.redirecting = result.redirecting ?? false;
    this.refreshing = false;

    if (result.refreshIntervalInMillisecond) {
      this.setNextRefresh(result.refreshIntervalInMillisecond);
    }
  }

  // Reset the current session by kicking off populating once again
  private async resetSession(): Promise<void> {
    // wait for any ongoing populating to settle down
    await this.getSessionDataAsync();

    // if not already resetting, kick off a reset
    if (!this.resetting || !this.pendingInitialized) {
      // re-init populating, lock resetting
      this.resetting = true;
      this.pendingResult = null;
      this.pendingInitialized = this.populateState(0);

      // clear internal refreshing
      this.clearRefresh();

      // clear resetting lock
      await this.pendingInitialized;
      this.resetting = false;
    } else {
      await this.pendingInitialized;
    }
  }

  // --------------------------------
  // ------- refresh handling -------
  // --------------------------------

  private clearRefresh() {
    if (this.refreshIntervalId !== null) {
      clearInterval(this.refreshIntervalId);
      this.refreshIntervalId = null;
    }
  }

  private setNextRefresh(timeUntilRefreshInMilliseconds: number) {
    this.clearRefresh();

    this.refreshIntervalId = setInterval(async () => {
      if (!this.refreshing) {
        await this.refreshState();
      }
    }, timeUntilRefreshInMilliseconds);
  }

  // --------------------------------
  // ------- public interface -------
  // --------------------------------

  /**
   * In synchronous contexts, this can be used to get the current authentication
   * information; this should not be used in api request code
   */
  public getSessionData(): SessionData | null {
    return this.sessionData;
  }

  /**
   * In asynchronous contexts, this should be used to wait and extract the current
   * session information. If a refresh is required, this will await that refresh meaning
   * apis should rely on this method
   */
  public async getSessionDataAsync(): Promise<SessionData | null> {
    if (!this.sessionData) {
      await this.populateState();
    }
    return this.sessionData;
  }

  /**
   * In asynchronous contexts, this can be used to verify a session if an error occurs,
   * and make an attempt to reset the session.
   */
  public async verifySessionAndResetIfNeeded(): Promise<void> {
    const hasValidSession = await isTokenValid(this.sessionData);

    if (!hasValidSession) {
      await this.resetSession();
    }
  }

  /**
   * This will wait for the initial store population based on the current session state
   */
  public async waitForInitialized(): Promise<SessionState> {
    await this.pendingInitialized;
    return {
      errorMessage: this.errorMessage,
      redirecting: this.redirecting,
      sessionData: this.sessionData,
    };
  }
}
