import type { CdxUiReportIssueConfig } from "@citadel/cdx-config";
import type { SanitizedTemplate } from "../../utils/sanitizeTemplate";
import { sanitize } from "../../utils/sanitizeTemplate";
import type { IReportIssueEmailConfig } from "./types/config";
import { ReportIssueOption } from "./types/config";
import type { PublishableLog } from "./types/logs";
import type { Annotation, Media } from "./types/Media";

/**
 * Format Media object into sanitized html output.
 * @param media
 * @returns
 */
function outputMedia(media: Media): SanitizedTemplate {
  return sanitize`<img style="width: 100%" src="${media.base64}" />
    ${media.annotations.map((annotation: Annotation, index: number) => {
      return sanitize`<p>#${String(index + 1)} – ${annotation.fill ? "Redact" : `${annotation.color} Annotation`} '${
        annotation.text || "No Description Provided"
      }'</p>`;
    })}
  `;
}

/**
 * Format Log object into sanitized html output.
 * @param log
 * @returns
 */
function outputLog(log: PublishableLog): SanitizedTemplate {
  return sanitize`<p>Log: ${log.name}</p><code>${log.content}</code>`;
}

export const defaultDynamicEmailConfig: IReportIssueEmailConfig = {
  subject: (emailConfig, report) => `${report.user || emailConfig.author} Error Report`,
  body: (emailConfig, report) => {
    // [data-ogsc] is applied by some email clients when they are rendering an element in dark mode.
    // We duplicate the styles from the media declaration to ensure these clients are respecting the same
    // dark mode styles.
    let template = sanitize`<html>
      <head>
        <title>${report.user || emailConfig.author} Error Report</title>
        <style>
          html {
            color: #000;
          }
          @media (prefers-color-scheme: dark) {
            html {
              color: #fff;
            }
          }
          [data-ogsc] html {
            color: #fff;
          }
          h1 {
            font-size: 16px;
          }
          code {
            font-size: 10px;
          }
        </style>
      </head>
      <body>
        <h1>${report.user || emailConfig.author} Error Report</h1>
        <p>Application: ${report.application}</p>
        <p>Environment: ${report.environment}</p>
        <p>URL: ${report.url}</p>
        <p>Description: ${report.description}</p>
        ${report.media ? report.media.map(outputMedia) : ""}
        ${report.logs.length > 0 ? report.logs.map(outputLog) : ""}
      </body>
    </html>`;

    if (template.sanitized) {
      return template.value;
    }

    // If the template could not be sanitized, it's important to report some of the data still.
    return `<html>
      <head>
        <title>${report.user || emailConfig.author} Error Report</title>
        <style>
          html {
            color: #000;
          }
          @media (prefers-color-scheme: dark) {
            html {
              color: #fff;
            }
          }
          [data-ogsc] html {
            color: #fff;
          }
          h1 {
            font-size: 16px;
          }
          code {
            font-size: 10px;
          }
        </style>
      </head>
      <body>
        <h1>${report.user || emailConfig.author} Error Report</h1>
        ${report.media ? report.media.map(outputMedia) : ""}
        <p>Additional details provided during report could not be processed due to a sanitization error.</p>
      </body>
    </html>`;
  },
};

export const defaultPermit = {
  screenshot: ReportIssueOption.ALLOW,
  logs: ReportIssueOption.ALLOW,
};

export const defaultApplicationConfig: CdxUiReportIssueConfig = {
  Email: {
    toGroupNames: ["citadel-x-dev"],
    author: "citadel-x-dev-error-report@citadel.com",
  },
  ...defaultPermit,
};
