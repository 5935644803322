import { ApolloLink, split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import type { FragmentDefinitionNode, GraphQLSchema, OperationDefinitionNode } from "graphql";
import type { CdxServiceDependencyGraphQLResolution } from "@citadel/cdx-config";
import type { GraphQLStitchingOptions } from "@citadel/cdx-lib-graphql-stitching";
import { createStitchedServicesSchemaForTarget } from "@citadel/cdx-lib-graphql-stitching";
import { createAuthLink } from "./createAuthLink";
import { createErrorLink } from "./createErrorLink";
import { SchemaLink } from "./createSchemaLink";

export interface GatewayLinkOptions extends GraphQLStitchingOptions {
  authLink?: ApolloLink;
  target: string;
  requiresAuthenticate?: boolean;
  services: CdxServiceDependencyGraphQLResolution[];
  errorLink?: ApolloLink;
  subscriptionLink?: ApolloLink;
  onError: (error: string) => any;
}

export const createGatewayLink = async ({
  authLink,
  target,
  errorLink,
  subscriptionLink,
  onError,
  requiresAuthenticate = true,
  services,
  ...stitchingOpts
}: GatewayLinkOptions): Promise<ApolloLink> => {
  const { schema } = await createStitchedServicesSchemaForTarget(target, services, stitchingOpts);

  const schemaLink = getLink(target, services, schema, onError, subscriptionLink);
  return ApolloLink.from([
    authLink || createAuthLink(requiresAuthenticate),
    errorLink || createErrorLink({ onError }),
    schemaLink,
  ]);
};

/**
 * Uses the stitched schema to create an Apollo SchemaLink.
 * This function also splits to link to handle subscriptions using WebSocketLink.
 */
const getLink = (
  target: string,
  services: GatewayLinkOptions["services"],
  schema: GraphQLSchema,
  onError: (error: string) => any,
  subscriptionLink?: ApolloLink
) => {
  const schemaLink = new SchemaLink({ schema });

  // If the user supplied a subscription link, use it
  if (subscriptionLink) {
    return split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
      },
      subscriptionLink,
      schemaLink
    );
  }

  // For services that use the legacy transport protocol, it is not sufficient to handle the websocket
  // subscription within the SchemaLink. Therefore if the user hasn't supplied a custom subscription
  // link, try to create our own for each service.
  // We will create a WebSocketLink for all the services that has `useLegacyApolloSubscriptionTransport`
  // set to true, and also have a `wsUrlOrPath` defined.
  // Note - to simplify the matching logic, we only match services based on the queried field name.
  // Therefore, users must ensure that services must not have conflicting subscription fields.
  return services.reduce<ApolloLink>((finalLink, service) => {
    // Only create WebsocketLink for legacy graphql protocols
    if (!service.useLegacyApolloSubscriptionTransport) return finalLink;

    // Check for `wsurlOrPath`
    const gqlTarget = service.targets[target];
    if (!gqlTarget || !gqlTarget.wsUrlOrPath) return finalLink;

    const subscriptionSchema =
      typeof service.introspection === "string" ? getSubscriptionSchema(service.introspection) : "";
    const removePrefix = createPrefixRemover(service.prefix);

    return split(
      ({ query }) => {
        // Use the schema link if not a subscription
        const definition = getMainDefinition(query);
        if (definition.kind !== "OperationDefinition" || definition.operation !== "subscription") return false;

        // Remove the prefix, and match field by name
        const fieldName = removePrefix(getMainSelectionName(definition));
        return !!fieldName && subscriptionSchema.includes(fieldName);
      },
      new WebSocketLink({
        uri: gqlTarget.wsUrlOrPath,
        options: {
          lazy: true,
          reconnect: true,
          reconnectionAttempts: 15, // ~1h in total
        },
      }),
      finalLink
    );
  }, schemaLink);
};

/**
 * Assume the main definition is a query or a selection with a single selection set
 */
const getMainSelectionName = (definition: OperationDefinitionNode | FragmentDefinitionNode) => {
  if (definition.selectionSet.kind !== "SelectionSet") return "";
  if (!definition.selectionSet.selections.length) return "";

  const mainSelection = definition.selectionSet.selections[0];
  if (mainSelection.kind !== "Field") return "";

  return mainSelection.name.value;
};

/**
 * Use regex to get the part of the schema that corresponds to subscription.
 * Note: this assumes we do not use fragments inside the subscription schema, otherwise those fields
 * will not be included.
 */
const subscriptionRegex = /type Subscription {[^}]*}/;
const getSubscriptionSchema = (introspection: string) => {
  const match = introspection.match(subscriptionRegex);
  return match ? match[0] : "";
};

/**
 * Returns a function to remove the specified prefix.
 */
const createPrefixRemover = (inputPrefix?: string): ((name: string) => string) => {
  if (!inputPrefix) {
    return (name: string) => name;
  }
  const prefix = `${inputPrefix}${inputPrefix.endsWith("_") ? "" : "_"}`;
  return (name: string) => name.replace(prefix, "");
};
