import { Colors } from "@blueprintjs/core";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { pathJoin } from "../../utils/path-join";
import type { PageLink } from "./page-layout";

const HoverLinkCSS = css({
  backgroundColor: "rgba(0, 0, 0, 0.2)",
  color: Colors.WHITE,
  textDecoration: "none",
});

const LinkCSS = css({
  alignItems: "center",
  color: "rgba(255, 255, 255, 0.8)",
  display: "flex",
  gap: "8px",
  fontSize: "16px",
  height: "100%",
  padding: "0 30px",
  textDecoration: "none",
  transition: "background-color 0.2s ease",

  ["&:hover"]: HoverLinkCSS,
  ["&:active"]: {
    backgroundColor: "rgba(0, 0, 0, 0.33)",
  },
});

const LinkBody = styled.a((props: { selected?: boolean }) => (props.selected ? [LinkCSS, HoverLinkCSS] : LinkCSS));

export const HeaderLink = ({ icon, title, path }: PageLink): JSX.Element => {
  const currentUrl = window.location.origin + window.location.pathname;
  // The first two path members of a CDX application are the team's abberviation, e.g., "pse", "commod",
  // and the project's name, e.g., "intern-reviews", "app-settings-admin".
  const href = pathJoin(window.location.origin, window.location.pathname.split("/").slice(1, 3).join("/"), path);
  return (
    <LinkBody selected={href === currentUrl} href={href}>
      {icon}
      {title}
    </LinkBody>
  );
};
