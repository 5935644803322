import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import { Div } from "./Div";
import type { LayoutGridProps } from "./types";

export const LayoutGrid = styled(Div, {
  shouldForwardProp: isPropValid,
})<LayoutGridProps>({}, ({ columns, rows, gap }) => {
  const display = "grid";
  const gridTemplateColumns = typeof columns === "string" ? columns : `repeat(${columns ?? 0}, auto)`;
  const gridTemplateRows = typeof rows === "string" ? rows : `repeat(${rows ?? 0}, auto)`;
  const columnGap = gap;
  const rowGap = gap;

  return { display, gridTemplateColumns, gridTemplateRows, columnGap, rowGap };
});
