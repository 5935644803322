import styled from "@emotion/styled";
import type { SyntheticEvent } from "react";
import { useRef, useCallback, useState, useEffect } from "react";
import produce from "immer";
import { usePreviousImmediate } from "rooks";
import { H3, Icon } from "@blueprintjs/core";
import isPropValid from "@emotion/is-prop-valid";
import type { Annotation, Media, MediaProps } from "../types/Media";
import { EditingState } from "../types/Media";
import { useResizeEditor } from "../hooks/useResizeEditor";
import type { MediaRegionProps } from "./EditRegion";
import { EditRegion } from "./EditRegion";
import { getAnnotationColor, LARGE_ENOUGH_ANNOTATION_TO_CARE } from "./annotations";

const PositionWrapper = styled.div`
  position: relative;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScreenshotImage = styled.img(({ dropping }: Pick<MediaProps, "dropping">) => ({
  maxWidth: "100%",
  maxHeight: "60vh",
  display: "block",
  userSelect: "none",
  pointerEvents: "none",
  transform: dropping ? "scale(0.3) translateX(-66%)" : undefined,
  border: dropping ? "5px solid #fff" : undefined,
  opacity: dropping ? 0.8 : undefined,
  transition: "transform 200ms linear, opacity 200ms ease-out",
}));

const DropText = styled(H3, { shouldForwardProp: (prop) => isPropValid(prop) && prop !== "dropping" })(
  ({ dropping }: Pick<MediaProps, "dropping">) => ({
    userSelect: "none",
    pointerEvents: "none",
    opacity: dropping ? 1 : 0,
    transition: "opacity 150ms ease-out",
    position: "absolute",
    bottom: "10%",
    width: "100%",
    display: "block",
    textAlign: "center",
  })
);

const DropPreview = styled.div(({ dropping, basis }: Pick<MediaProps, "dropping"> & { basis: Media }) => ({
  userSelect: "none",
  pointerEvents: "none",
  maxWidth: "100%",
  maxHeight: "60vh",
  width: basis.width,
  aspectRatio: `${basis.width}/${basis.height}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: 0,
  opacity: dropping ? 1 : 0,
  visibility: dropping ? "visible" : "hidden",
  transform: dropping ? "scale(0.3) translateX(66%)" : undefined,
  border: "5px solid #fff",
  transition: "opacity 150ms ease-out",
  transitionDelay: "150ms",
}));

interface InitialDragLocation {
  elementX: number;
  elementY: number;
  initialX: number;
  initialY: number;
  imageX: number;
  imageY: number;
}

export function Editor({ report, mediaIndex, dropping, onRegionsChange, onRegionRemove, onEditorChange }: MediaProps) {
  const media = report.media?.[mediaIndex];
  const wrapperElement = useRef<HTMLDivElement>(null);
  const imageElement = useRef<HTMLImageElement>(null);
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [placedRegions, setPlacedRegions] = useState<MediaRegionProps[]>(
    media?.annotations.map((annotation) => ({
      annotation,
      editing: EditingState.NONE,
      disabled: report.completed,
    })) || []
  );
  const previousMediaIndex = usePreviousImmediate<number>(mediaIndex);
  const [currentRect, setCurrentRect] = useState<Annotation & InitialDragLocation>({
    elementX: 0,
    elementY: 0,
    initialX: 0,
    initialY: 0,
    imageX: 0,
    imageY: 0,
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    color: getAnnotationColor(placedRegions.length),
    fill: false,
    text: "",
    scaleWidth: 1,
    scaleHeight: 1,
  });
  useResizeEditor(report, mediaIndex, wrapperElement, imageElement, onEditorChange);

  const mouseDownHandler = useCallback(
    ({ nativeEvent }: SyntheticEvent<HTMLDivElement, MouseEvent>) => {
      nativeEvent.preventDefault();
      if (
        isDrawing ||
        !imageElement.current ||
        !wrapperElement.current ||
        !(nativeEvent.target as Node).isEqualNode(wrapperElement.current)
      ) {
        return;
      }

      setIsDrawing(true);
      const wrapperElementRect = wrapperElement.current.getBoundingClientRect();
      const imageElementRect = imageElement.current.getBoundingClientRect();
      const { pageX, pageY } = nativeEvent;
      const elementX = Math.abs(wrapperElementRect.x);
      const elementY = Math.abs(wrapperElementRect.y);
      const initialX = pageX - elementX;
      const initialY = pageY - elementY;
      setCurrentRect({
        ...currentRect,
        color: getAnnotationColor(placedRegions.length),
        elementX,
        elementY,
        initialX,
        initialY,
        imageX: Math.abs(imageElementRect.x) - elementX,
        imageY: Math.abs(imageElementRect.y) - elementY,
        left: initialX,
        top: initialY,
        width: 0,
        height: 0,
        scaleWidth: imageElement.current.naturalWidth / imageElement.current.width, //body.clientWidth / wrapperElementRect.width,
        scaleHeight: imageElement.current.naturalHeight / imageElement.current.height, // body.clientHeight / wrapperElementRect.height,
      });
    },
    [currentRect, isDrawing, placedRegions.length]
  );

  const mouseMoveHandler = useCallback(
    ({ nativeEvent: { pageX, pageY } }: SyntheticEvent<HTMLDivElement, MouseEvent>) => {
      if (!isDrawing) {
        return;
      }
      const offsetX = pageX - currentRect.elementX;
      const offsetY = pageY - currentRect.elementY;
      setCurrentRect({
        ...currentRect,
        left: Math.min(offsetX, currentRect.initialX),
        top: Math.min(offsetY, currentRect.initialY),
        width: Math.abs(currentRect.initialX - offsetX),
        height: Math.abs(currentRect.initialY - offsetY),
      });
    },
    [currentRect, isDrawing]
  );

  const mouseUpHandler = useCallback(() => {
    if (
      isDrawing &&
      currentRect.width > LARGE_ENOUGH_ANNOTATION_TO_CARE &&
      currentRect.height > LARGE_ENOUGH_ANNOTATION_TO_CARE
    ) {
      setPlacedRegions(
        produce((draft: MediaRegionProps[]) => {
          const currentlyEditing = draft.find((region) => region.editing !== EditingState.NONE);
          if (currentlyEditing) {
            currentlyEditing.editing = EditingState.NONE;
          }
          draft.push({
            annotation: currentRect,
            editing: EditingState.DURABLE,
            disabled: report.completed,
          });
        })
      );
    }
    setIsDrawing(false);
  }, [currentRect, isDrawing, report.completed]);

  const onRegionTextUpdate = (text: string, index?: number) => {
    if (index === undefined) {
      return;
    }

    setPlacedRegions(
      produce((draft: MediaRegionProps[]) => {
        if (draft[index]) {
          draft[index] = {
            ...draft[index],
            annotation: {
              ...draft[index].annotation,
              text,
            },
          };
        }
      })
    );
  };

  const onRegionEditUpdate = (editing: EditingState, index?: number) => {
    if (index === undefined) {
      return;
    }
    setPlacedRegions(
      produce((draft: MediaRegionProps[]) => {
        if (editing === EditingState.DURABLE) {
          const itemsLength = draft.length;
          for (let iterator = 0; iterator < itemsLength; iterator++) {
            const thisEditing = iterator === index ? editing : EditingState.NONE;
            draft[iterator] = {
              ...draft[iterator],
              editing: thisEditing,
            };
          }
          return;
        }

        if (draft[index]) {
          draft[index] = {
            ...draft[index],
            editing,
          };
        }
      })
    );
  };

  const onRegionFillUpdate = (fill: boolean, index?: number) => {
    if (index === undefined) {
      return;
    }

    setPlacedRegions(
      produce((draft: MediaRegionProps[]) => {
        draft[index].annotation.fill = fill;
      })
    );
  };

  const onRegionRemoveUpdate = (remove: boolean, index?: number) => {
    if (index === undefined || !remove) {
      return;
    }

    setPlacedRegions(
      produce((draftRegions: MediaRegionProps[]) => {
        draftRegions.splice(index, 1);
        draftRegions.forEach(
          (draftRegion, remapIndex) => (draftRegion.annotation.color = getAnnotationColor(remapIndex))
        );
      })
    );
    onRegionRemove();
  };

  useEffect(() => {
    // When mediaIndex changes, ensure the placed regions match the new index.
    if (previousMediaIndex !== mediaIndex) {
      const defaultAnnotations =
        media?.annotations.map((annotation) => ({
          annotation,
          editing: EditingState.NONE,
          disabled: report.completed,
        })) || [];
      setPlacedRegions(defaultAnnotations);
      return;
    }

    // When regions change, report them back by updating the screenshot.
    const regions = placedRegions.map((region) => region.annotation);
    if (media && report.media && JSON.stringify(media.annotations) !== JSON.stringify(regions)) {
      onRegionsChange?.(report.id, mediaIndex, regions);
    }
  }, [media, mediaIndex, onEditorChange, onRegionsChange, placedRegions, previousMediaIndex, report]);

  if (!report.media || !media) {
    return null;
  }

  return (
    <PositionWrapper
      ref={wrapperElement}
      data-testid="screenshot-editor-wrapper"
      onMouseDown={!report.completed ? mouseDownHandler : undefined}
      onMouseMove={!report.completed ? mouseMoveHandler : undefined}
      onMouseUp={!report.completed ? mouseUpHandler : undefined}
    >
      {isDrawing && !dropping ? (
        <EditRegion disabled={report.completed} annotation={currentRect} editing={EditingState.NONE} />
      ) : null}
      {!dropping &&
        placedRegions.map((region, index) => (
          <EditRegion
            disabled={report.completed}
            annotation={region.annotation}
            editing={region.editing}
            index={index}
            onTextUpdate={onRegionTextUpdate}
            onEditUpdate={onRegionEditUpdate}
            onFillUpdate={onRegionFillUpdate}
            onRemoveUpdate={onRegionRemoveUpdate}
            key={`region-${index}`}
          />
        ))}
      <DropText dropping={dropping}>Drop an image to improve error reporting quality.</DropText>
      <DropPreview dropping={dropping} basis={media}>
        <Icon icon="plus" iconSize={100} />
      </DropPreview>
      <ScreenshotImage ref={imageElement} src={media.base64} dropping={dropping} />
    </PositionWrapper>
  );
}
