import type { FetchResult, NextLink, Operation } from "@apollo/client";
import { Observable, ApolloLink } from "@apollo/client";
import type { Subscription } from "zen-observable-ts";
import type { AuthenticationSession } from "@citadel/cdx-auth-browser";

const AUTHORIZATION_SESSION = "cdxAuthorizationSession";

/**
 * Use this to get the {@link AuthenticationSession} attached to a GraphQL operation. Requires the
 * {@link AuthenticationSessionLink} to be visited first.
 *
 * @param operation - An operation to probe for an authentication session
 */
export function getOperationAuthenticationSession(operation: Operation): AuthenticationSession | undefined {
  return operation.getContext()[AUTHORIZATION_SESSION];
}

/**
 * Use this to automatically attach a `Authorization: Bearer <token>` header and the {@link AuthenticationSession} to
 * each outgoing GraphQL request.
 *
 * @see Use {@link getOperationAuthenticationSession} to get the {@link AuthenticationSession} from an operation
 */
export class AuthenticationSessionLink extends ApolloLink {
  constructor(session?: AuthenticationSession) {
    super((operation: Operation, forward: NextLink): Observable<FetchResult> | null => {
      // Grab a reference to the session at the start of the operation. This
      // ensures that we can't be caught out by .setSession() being called in
      // the middle of an operation and changing the session.
      const requestSession = this.#session;

      // Skip this link if there's no session
      if (!requestSession) {
        return forward(operation);
      }

      // Add the authorization session to the context, so it can be referenced
      // in other links if needed
      operation.setContext((prevContext: Record<string, any>) => ({
        ...prevContext,
        [AUTHORIZATION_SESSION]: requestSession,
      }));

      // Skip this link if there's already an authorization header, we'll let
      // that one do the job
      const context = operation.getContext();
      if (context.headers?.authorization || context.headers?.Authorization) {
        return forward(operation);
      }

      return new Observable((observer) => {
        let handle: Subscription;

        requestSession
          .getAccessToken()
          .then((accessToken) => {
            // Add the auth token to the request headers
            operation.setContext(({ headers = {}, ...otherContext }) => ({
              headers: {
                ...headers,
                // NOTE: THE HEADER KEY `authorization` MUST BE FULLY LOWER CASE
                // OR APOLLO WILL SILENTLY DROP IT
                authorization: `Bearer ${accessToken}`,
              },
              ...otherContext,
            }));

            // Then continue with the next operation
            handle = forward(operation).subscribe(observer);
          })
          .catch((error) => {
            // Forward any errors from authentication to the observer, don't try
            // to make the request without an access token
            observer.error(error);
          });

        return () => {
          if (handle) {
            handle.unsubscribe();
          }
        };
      });
    });

    // Set the session if it was supplied to the constructor
    if (session) {
      this.setSession(session);
    }
  }

  /**
   * The currently active authentication session
   */
  #session: AuthenticationSession | undefined;

  /**
   * Get the active authentication session or undefined if there is no session.
   */
  getSession(): AuthenticationSession | undefined {
    return this.#session;
  }

  /**
   * Set the active authentication session, or undefined to uneset the session.
   *
   * @param value - An {@link AuthenticationSession} to use
   */
  setSession(value: AuthenticationSession | undefined) {
    this.#session = value;
  }
}
