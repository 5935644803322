import type { CdxFutureEnvironment, CITADEL_ENV } from "./types";
import { CITADEL_ENV_PROD, CITADEL_ENV_STABLEDEV, CITADEL_ENV_SSA_PROD } from "./constants";
import { getEnvironmentVariable } from "./variables";

const computeCitadelEnv = (rawCitadelEnv: string | undefined): CITADEL_ENV => {
  if (rawCitadelEnv === CITADEL_ENV_PROD) {
    return CITADEL_ENV_PROD;
  } else if (rawCitadelEnv === CITADEL_ENV_SSA_PROD) {
    return CITADEL_ENV_SSA_PROD;
  }
  return CITADEL_ENV_STABLEDEV;
};

const computeIsLocal = (rawCitadelEnv: string | undefined, rawIsLocal: string | undefined): boolean => {
  return rawIsLocal === "true" || !rawCitadelEnv;
};

/**
 * Based on environment variables (both in browser and in services) computes the current environment
 *
 * NOTE: while this defaults to the process.env value, this is meant
 * to be availabe in any context, you'd just have to provide the value
 * yourself when process.env isn't available
 */
export const computeEnvironment = (
  rawCitadelEnv: string | undefined = getEnvironmentVariable("CITADEL_ENV"),
  rawTarget: string | undefined = getEnvironmentVariable("CDX_TARGET", "local"),
  rawIsLocal: string | undefined = getEnvironmentVariable("CDX_IS_LOCAL")
): CdxFutureEnvironment => {
  const citadelEnv = computeCitadelEnv(rawCitadelEnv);
  if (citadelEnv === undefined) {
    throw new Error("CITADEL_ENV must be set at runtime");
  }

  return {
    citadelEnv,
    isLocal: computeIsLocal(rawCitadelEnv, rawIsLocal),
    target: rawTarget || "local",
  };
};
