const NO_MERGE = {
  merge: false,
};

const getFieldsObject = (...fields: string[]) => {
  return fields.reduce((acc, f) => ({ ...acc, [f]: NO_MERGE }), {});
};

/**
 * This handling will be deprecated when we migrate to new settings and ws management
 * It allows overwriting of fields on entities that have no underlying id
 */
export const cacheMergeTypePolicy = {
  typePolicies: {
    Query: {
      fields: getFieldsObject(
        "assigningWorkspaceSnapshots",
        "creatingWorkspaceSnapshots",
        "persistedWorkspaces",
        "settings",
        "toasts",
        "workspace"
      ),
    },
    Workspace: {
      fields: getFieldsObject("workspaceGrids"),
    },
  },
};
