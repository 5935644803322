import type { ENVIRONMENT_KEY } from "@citadel/cdx-environment";

/**
 * @deprecated {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @internal
 */
export type AUTHENTICATE_SUPPORTED_ENVIRONMENT_KEY = Exclude<ENVIRONMENT_KEY, "ssa">;

/**
 * @internal
 */
export enum InformationBarrierType {
  Unknown,
  Enterprise_AbovetheWall = "Enterprise_AbovetheWall",
  CitAdv_AbovetheWall = "CitAdv_AbovetheWall",
  CitSec_AbovetheWall = "CitSec_AbovetheWall",
  EquitiesMarketMaking_ClientOperations_FX = "EquitiesMarketMaking_ClientOperations_FX",
  EquitiesMarketMaking_MKT = "EquitiesMarketMaking_MKT",
  EquitiesMarketMaking_LOD = "EquitiesMarketMaking_LOD",
  EquitiesMarketMaking_CES_Institutional = "EquitiesMarketMaking_CES_Institutional",
  OptionsMarketMaking_Institutional = "OptionsMarketMaking_Institutional",
  ETFMarketMaking_Index_Arbitrage_OTC = "ETFMarketMaking_Index_Arbitrage_OTC",
  FixedIncomeTrading_FIMM = "FixedIncomeTrading_FIMM",
  PropTrading = "PropTrading",
  OptionsMarketMaking_Asia = "OptionsMarketMaking_Asia",
}

/**
 * JWT encoded data in raw and parsed formats
 *
 * @deprecated {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @internal
 */
export interface AuthenticateJwtDataRaw {
  /** e.g. rdavis */
  sAMAccountName: string;
  /** e.g. rdavis\@CITADELGROUP.COM */
  userPrincipalName: string;
  /** e.g. Enterprise_AbovetheWall */
  informationBarrier: string;
  /** e.g. 1602705787 */
  iat: number;
  /** e.g. 1602748987 */
  exp: number;
}
/**
 * @deprecated {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @internal
 */
export interface AuthenticateJwtData {
  username: string;
  email: string;
  informationBarrierRaw: string;
  informationBarrier: InformationBarrierType;
  createdAt: Date;
  expiresAt: Date;
}

/**
 * Raw Api Results
 */

/**
 * @deprecated {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @internal
 */
export interface AuthenticateApiGaExchange {
  environment: AUTHENTICATE_SUPPORTED_ENVIRONMENT_KEY;
  token: string;
}

/**
 * @deprecated {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @internal
 */
export interface AuthenticateApiToken {
  token: string;
}

/**
 * @deprecated {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @internal
 */
export interface AuthenticateApiValidate {
  valid: boolean;
}
