import type { ENVIRONMENT_KEY } from "@citadel/cdx-environment";
import type { CdxServiceDependencyGraphQLResolution, CdxServiceDependencyGraphQLDeployment } from "../graphqlServices";
import { WELL_KNOWN_GRAPHQL_SERVICE_HEALTH_LOCATION } from "../../constants";

const CG_GRAPHQL_PATH = "/graphql";

export interface CgServices {
  "app-state": CdxServiceDependencyGraphQLResolution;
  "gfin-search": CdxServiceDependencyGraphQLResolution;
  "service-router": CdxServiceDependencyGraphQLResolution;
  skynet: CdxServiceDependencyGraphQLResolution;
  cdxgraphql: CdxServiceDependencyGraphQLResolution;
}

interface CreateCgServiceConfigOptions {
  name: string;
  prefix?: string;
  hostPrefix: string;
  useLegacyApolloSubscriptionTransport?: boolean;
}

const createCgServiceDeployment = (hostPrefix: string, env: ENVIRONMENT_KEY): CdxServiceDependencyGraphQLDeployment => {
  const host = `https://${hostPrefix}-${env}.citadelgroup.com`;
  return {
    healthcheckUrlOrPath: `${host}${WELL_KNOWN_GRAPHQL_SERVICE_HEALTH_LOCATION}`,
    httpUrlOrPath: `${host}${CG_GRAPHQL_PATH}`,
  };
};

const createCgServiceConfig = ({
  name,
  prefix = "",
  hostPrefix,
  useLegacyApolloSubscriptionTransport,
}: CreateCgServiceConfigOptions): CdxServiceDependencyGraphQLResolution => ({
  name,
  prefix,
  useLegacyApolloSubscriptionTransport,
  targets: {
    nrc: createCgServiceDeployment(hostPrefix, "prod"),
    prod: createCgServiceDeployment(hostPrefix, "prod"),
    "staging-nrc": createCgServiceDeployment(hostPrefix, "prod"),
    staging: createCgServiceDeployment(hostPrefix, "prod"),
    "stabledev-nrc": createCgServiceDeployment(hostPrefix, "stabledev"),
    stabledev: createCgServiceDeployment(hostPrefix, "stabledev"),
  },
});

/**
 * Introspection is omitted as a part of shared and should be passed in
 * This is because these services are not tightly coupled to this library and the running
 * version may differ from when the library was built
 */
export const cgServices: CgServices = {
  "app-state": createCgServiceConfig({
    name: "app-state",
    hostPrefix: "appstate",
    useLegacyApolloSubscriptionTransport: true,
  }),
  "gfin-search": createCgServiceConfig({
    name: "gfin-search",
    hostPrefix: "gfinsearch",
    useLegacyApolloSubscriptionTransport: true,
  }),
  "service-router": createCgServiceConfig({
    name: "service-router",
    hostPrefix: "srgraphql",
    useLegacyApolloSubscriptionTransport: true,
  }),
  skynet: createCgServiceConfig({
    name: "skynet",
    hostPrefix: "skynetgraphql",
    useLegacyApolloSubscriptionTransport: true,
  }),
  cdxgraphql: createCgServiceConfig({
    name: "cdxgraphql",
    hostPrefix: "cdxgraphql",
    useLegacyApolloSubscriptionTransport: true,
  }),
};
