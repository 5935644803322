import { MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import type { SelectInputGroupProps } from "../select-input-group";
import { SelectInputGroup } from "../select-input-group";

const BlueprintStringSelect = Select.ofType<string>();

export type StringSelectProps = {
  onChange: (option: string) => void;
  options: string[];
  /** Callback to get the label of an option, i.e., what text will be displayed to the user. */
  getLabel?: (option: string) => string;
} & SelectInputGroupProps;

/**
 * Component rendering a `select` for strings.
 * @see https://blueprintjs.com/docs/#select/select-component
 */
export const StringSelect = ({
  className,
  disabled,
  value,
  onChange,
  options,
  style,
  getLabel,
  placeholder,
}: StringSelectProps): JSX.Element => {
  return (
    <BlueprintStringSelect
      activeItem={value}
      disabled={disabled}
      items={options}
      itemRenderer={(item, { handleClick, modifiers }) => (
        <MenuItem key={item} text={getLabel ? getLabel(item) : item} active={modifiers.active} onClick={handleClick} />
      )}
      noResults={<MenuItem text="No options available." disabled />}
      onItemSelect={onChange}
      filterable={false}
      popoverProps={{ minimal: true }}
    >
      <SelectInputGroup
        disabled={disabled}
        className={className}
        style={style}
        value={getLabel ? getLabel(value) : value}
        placeholder={placeholder}
      />
    </BlueprintStringSelect>
  );
};
