import type { ReactNode } from "react";
import styled from "@emotion/styled";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Icon } from "@blueprintjs/core";

const Conainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  gap: 10px;
`;

const Handle = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
`;

const Content = styled.div`
  flex-grow: 1;
`;

export interface ListEditorItemProps {
  id: string;
  className?: string;
  children: ReactNode;
}

export const ListEditorItem = ({ id, className, children }: ListEditorItemProps) => {
  const { attributes, listeners, setNodeRef, setDraggableNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Conainer ref={setNodeRef} style={style} {...attributes} className={className}>
      <Handle ref={setDraggableNodeRef} {...listeners}>
        <Icon icon="drag-handle-vertical" />
      </Handle>
      <Content>{children}</Content>
    </Conainer>
  );
};
