// TODO[CDX-1399]: https://jira.citadelgroup.com/browse/CDX-1468
import Cookies from "js-cookie";
import type { CdxSessionCookies, SessionCookies } from "@citadel/cdx-lib-auth";
import { AUTHENTICATE_COOKIE_KEY, CDX_SESSION_COOKIE_KEY } from "@citadel/cdx-lib-auth";

/**
 * @deprecated Use "getAccessToken()" from "\@citadel/cdx-auth-browser".
 * {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @internal
 */
export const getSessionCookies = (): SessionCookies => {
  return {
    authenticateJwt: Cookies.get(AUTHENTICATE_COOKIE_KEY) || null,
  };
};

/**
 * @deprecated Use "getAccessToken()" from "\@citadel/cdx-auth-browser".
 * {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @public
 */
export const getCdxSessionCookies = (): CdxSessionCookies => {
  return {
    ...getSessionCookies(),
    gaSessionJwt: Cookies.get(CDX_SESSION_COOKIE_KEY) || null,
  };
};
