import type { AppRoute } from "./AppRoute";

export type AppRoutePrefixInput = AppRoute[] | { [key: string]: AppRoute };

export const getPath = (route?: AppRoute, path?: string): string => {
  if (!route) {
    return `/?unable-to-route-to=${path}`;
  }

  return route.path();
};

const SLUG_REGEX = "[A-Za-z0-9]*";
const SLUG_REGEX_ARGS = new Array(5).fill(SLUG_REGEX);

export const prefixRoute = <TParams extends {}>(inputPrefix: string, route: AppRoute<TParams>): AppRoute<TParams> => {
  const prefix = `${inputPrefix && !inputPrefix.startsWith("/") ? "/" : ""}${inputPrefix}`;
  const path = (...args: any[]) => `${prefix}${route.path(...args)}`;
  return {
    ...route,
    path,
  };
};

export const prefixRoutes = <TRoutes extends AppRoutePrefixInput>(inputPrefix: string, routes: TRoutes): TRoutes => {
  const routesList = Array.isArray(routes) ? routes : Object.values(routes);
  const hasHomeRoute = !!routesList.find((r) => r.isHome);
  if (!hasHomeRoute) {
    throw new Error("No default route is defined, please set one of your routes as route.isHome = true");
  }
  if (Array.isArray(routes)) {
    return routes.map((route) => prefixRoute(inputPrefix, route)) as TRoutes;
  } else {
    return Object.entries(routes).reduce(
      (acc, [key, route]) => ({ ...acc, [key]: prefixRoute(inputPrefix, route) }),
      {}
    ) as TRoutes;
  }
};

export const findMatch = <T extends AppRoutePrefixInput>(path: string, routeInput: T): AppRoute => {
  const routes: AppRoute[] = Array.isArray(routeInput) ? routeInput : Object.values(routeInput);
  let result = routes.find((r) => r.isHome);
  if (!result) {
    throw new Error("No default route is defined, please set one of your routes as route.isHome = true");
  }

  routes.forEach((route) => {
    if (path.match(new RegExp(`^${route.path(...SLUG_REGEX_ARGS)}$`))) {
      result = route;
    }
  });

  return result;
};
