import type { IToasterProps } from "@blueprintjs/core";
import { Intent, Toast as BPToast, Toaster } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { cx } from "@emotion/css";
import shallow from "zustand/shallow";
import { useToasts } from "./hooks";

const TIMEOUT_DEFAULT = 5000;

const DEFAULT_TOASTER_PROPS: IToasterProps = {};

const Toast = styled(BPToast)`
  word-break: break-word;
`;

export interface ToastManagerProps {
  /**
   * props to pass through to the blueprint toaster instance
   */
  toasterProps?: IToasterProps;
}

/**
 * ToastManager provides rendering of blueprint Toaster and ToasterManager of toasts stored in the Zustand store.
 */
export const ToastManager = ({ toasterProps = DEFAULT_TOASTER_PROPS }: ToastManagerProps) => {
  const { dismiss, toasts } = useToasts((store) => ({ dismiss: store.dismiss, toasts: store.toasts }), shallow);
  if (toasts.length === 0) {
    return null;
  }

  const toast = toasts[0];
  const onDismiss = (_isExpired: boolean) => dismiss(toast);
  const { action, icon, intent, message, timeout = TIMEOUT_DEFAULT } = toast;
  return (
    <Toaster {...toasterProps}>
      {toast && (
        <Toast
          action={action}
          className={cx("toast", toast.className)}
          icon={icon}
          intent={intent || Intent.NONE}
          onDismiss={onDismiss}
          message={message}
          timeout={timeout}
        />
      )}
    </Toaster>
  );
};
