import { WidgetSettingsContext } from "../context/WidgetSettingsContext";
import { createStoredValueHook } from "./createStoredValueHook";

/**
 * A Widget Setting is state specific to the configuration of a widget
 *
 * Widget settings are stored within a workspace.
 *
 * Some examples of widget settings:
 *  - Column arrangement within ag-grid
 *  - Draft form state
 *  - Layout of widgets within a dashboard
 */
export const useWidgetSetting = createStoredValueHook(WidgetSettingsContext);
