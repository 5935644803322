import type { ReportIssueConfig } from "../types/config";
import type { Report } from "../types/Report";
import type { DataAdapterBase } from "./dataAdapterBase";
import { EmailAdapter } from "./emailAdapter";
import { SlackAdapter } from "./slackAdapter";

const adapters = {
  [SlackAdapter.service]: SlackAdapter,
  [EmailAdapter.service]: EmailAdapter,
};

export function getAdapters(config: ReportIssueConfig, report: Report) {
  let values: DataAdapterBase[] = [];

  for (const key in config) {
    const adapter: typeof DataAdapterBase = adapters[key];
    if (adapter) {
      values.push(new adapter(config, report));
    }
  }
  return values;
}
