import { useHistory, useParams } from "react-router-dom";
import { useStartAssessmentMutation } from "../../_generated/graphql";
import { routes } from "../../constants/routes";
import { globalActions, useGlobal } from "../../stores/global/global-store";
import { BaseDialog } from "./base-dialog";

interface TutorialParamTypes {
  token: string;
}

export const StartAssessmentDialog = () => {
  const {
    state: { isStartAssessmentDialogOpen },
    dispatch,
  } = useGlobal();

  const history = useHistory();
  const { token } = useParams<TutorialParamTypes>();
  const [startAssessment] = useStartAssessmentMutation();

  // Use real IDs
  const goToAssessment = async () => {
    // I think we have to await here to make sure the expiresAt timestamp is set
    // in the DB when we make the next call to fetch the data on the next page
    await startAssessment({ variables: { input: { token, timestamp: Date.now() } } });

    history.push(
      routes.assessment.path({
        token,
      })
    );
  };

  const onClose = () => {
    dispatch({ type: globalActions.SET_IS_START_ASSESSMENT_DIALOG_OPEN, payload: false });
  };

  return (
    <BaseDialog
      title="Start Assessment"
      onClose={onClose}
      isOpen={isStartAssessmentDialogOpen}
      isCloseButtonShown={false}
      cancelButtonText="Not Yet"
      rightButtonText="Start"
      rightButtonOnClick={goToAssessment}
    >
      <p>
        You will have <b>45 minutes</b> to submit your completed spreadsheet solution.
      </p>
      <p>Start the Citadel Financial Concepts Assessment Test?</p>
    </BaseDialog>
  );
};
