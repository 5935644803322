import { Global, css } from "@emotion/react";
import { Colors } from "@blueprintjs/core";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

const globalCss = css`
  body {
    --cdx-ui-state-channel-blue: ${Colors.BLUE3};
    --cdx-ui-state-channel-lime: ${Colors.LIME3};
    --cdx-ui-state-channel-none: ${Colors.GRAY3};
    --cdx-ui-state-channel-orange: ${Colors.ORANGE3};
    --cdx-ui-state-channel-purple: ${Colors.VIOLET3};
    --cdx-ui-state-channel-red: ${Colors.RED3};
    --cdx-ui-state-channel-yellow: ${Colors.GOLD3};
  }
  .bp3-dark {
    --cdx-ui-state-channel-blue: ${Colors.BLUE5};
    --cdx-ui-state-channel-lime: ${Colors.LIME5};
    --cdx-ui-state-channel-none: ${Colors.GRAY5};
    --cdx-ui-state-channel-orange: ${Colors.ORANGE5};
    --cdx-ui-state-channel-purple: ${Colors.VIOLET5};
    --cdx-ui-state-channel-red: ${Colors.RED5};
    --cdx-ui-state-channel-yellow: ${Colors.GOLD5};
  }
  [data-cdx-channel="purple"] {
    --cdx-channel-color: var(--cdx-ui-state-channel-purple);
  }
  [data-cdx-channel="yellow"] {
    --cdx-channel-color: var(--cdx-ui-state-channel-yellow);
  }
  [data-cdx-channel="lime"] {
    --cdx-channel-color: var(--cdx-ui-state-channel-lime);
  }
  [data-cdx-channel="red"] {
    --cdx-channel-color: var(--cdx-ui-state-channel-red);
  }
  [data-cdx-channel="blue"] {
    --cdx-channel-color: var(--cdx-ui-state-channel-blue);
  }
  [data-cdx-channel="orange"] {
    --cdx-channel-color: var(--cdx-ui-state-channel-orange);
  }
  [data-cdx-channel="none"] {
    --cdx-channel-color: var(--cdx-ui-state-channel-none);
  }
`;

export function GlobalStyle() {
  return <Global styles={globalCss} />;
}
