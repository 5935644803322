// https://stackoverflow.com/a/2117523/2800218
// LICENSE: https://creativecommons.org/licenses/by-sa/4.0/legalcode

function getMethod(
  randomValuesMethod: <
    T extends
      | Int8Array
      | Int16Array
      | Int32Array
      | Uint8Array
      | Uint16Array
      | Uint32Array
      | Uint8ClampedArray
      | Float32Array
      | Float64Array
      | DataView
      | null
  >(
    array: T
  ) => T
) {
  return function randomUUID() {
    // @ts-expect-error
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (randomValuesMethod(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
  };
}

if (typeof window === "undefined") {
  // In Node environments, mutate crypto with the necessary method.
  // @ts-ignore
  var crypto = require("crypto");
  if (!("randomUUID" in crypto)) {
    crypto.randomUUID = getMethod(crypto.getRandomValues);
  }
} else if (window.crypto && !("randomUUID" in window.crypto)) {
  window.crypto.randomUUID = getMethod(window.crypto.getRandomValues);
}

// TypeScript is set to `isolatedModules` for some parts of the codebase.
// This requires all code to be a "module" per TypeScript's definition.
// Adding an empty export causes this to be considered a module.
// @see https://www.typescriptlang.org/tsconfig#isolatedModules
export {};
