// TODO[CDX-1399]: Remove ga security dependencies
import type { ReactElement } from "react";
import { useMemo, useEffect } from "react";
import type { RumInitConfiguration } from "@datadog/browser-rum";
import { datadogRum } from "@datadog/browser-rum";
import { getCdxSessionData } from "@citadel/cdx-lib-auth-browser";
import type { Environment } from "@citadel/cdx-environment";
import { getEnvironment } from "@citadel/cdx-environment";

/**
 * sampleRate defaults to 100
 * site defaults to datadoghq.com
 * trackInteractions defaults to true
 *
 * inferred keys: env, allowedTracingOrigins
 */
export interface UserMonitoringConfig extends RumInitConfiguration {
  applicationId: string;
  clientToken: string;
  service: string;
  version: string;
}

const initializeDatadog = (input: UserMonitoringConfig, config: Environment, enabledForLocal: Boolean) => {
  datadogRum.init({
    site: "datadoghq.com",
    env: enabledForLocal ? "local" : config.CITADEL_ENV,
    sampleRate: 100,
    trackInteractions: true,
    allowedTracingOrigins: [window.origin],
    ...input,
    beforeSend: (event) => {
      // redact gasec token from url for https://gasec-*
      // TODO: CTOTECH-1777
      // eslint-disable-next-line no-param-reassign
      event.view.url = event.view.url.replace(/https:\/\/gasec-[^&]*/, "https://gasec-REDACTED");
    },
  });

  const {
    authenticateData,
    username = authenticateData?.sAMAccountName ?? "",
    firstname,
    lastname,
  } = getCdxSessionData();
  if (username) {
    datadogRum.setUser({ id: username, name: `${firstname} ${lastname}` });
  }
};
export interface UserMonitoringProviderProps {
  children: ReactElement;
  datadogConfig: UserMonitoringConfig;
  enabledForLocal?: boolean;
}

const UserMonitoringProviderImpl = ({
  children,
  datadogConfig,
  enabledForLocal,
}: UserMonitoringProviderProps): ReactElement => {
  // perform on-load session verify
  useEffect(() => {
    const appConfig = getEnvironment();
    if (enabledForLocal || !appConfig.isLocal) {
      initializeDatadog(datadogConfig, appConfig, !!enabledForLocal);
    }
  }, [datadogConfig, enabledForLocal]);

  return children;
};

export const UserMonitoringProvider = ({
  children,
  datadogConfig,
  ...props
}: UserMonitoringProviderProps): ReactElement => {
  // we want memoized config if unchanged using deep equals
  // eslint-disable-next-line
  const config: UserMonitoringConfig = useMemo(() => datadogConfig, [JSON.stringify(datadogConfig)]);

  // skip if we don't have datadog config
  if (!config.applicationId || !config.clientToken || "Cypress" in window) {
    return children;
  }

  return <UserMonitoringProviderImpl children={children} datadogConfig={config!} {...props} />;
};
