// WARNING: these types are used as part of the global logger interface
//
// This means it is very important to make them backwards and forwards compatible,
// as it will be much harder to catch errors at runtime.
//
// Think: will an old logger version accept new data? Will a new logger version accept old data?
//
// Some rules to abide by:
// - All new properties should be optional
// - Do not change the type of an existing property,
//   aside from adding new optional properties to objects. This INCLUDES:
//   - Making an existing property optional or required
//   - Adding/removing to union types

/**
 * The object passed to a raw logger
 *
 * @internal
 */
export interface RawLogOptions {
  level: LogLevel;
  data?: unknown;
  time?: number;
  context?: string;
}

/**
 * Minimal logging implementation - this is where what we store as the global logger.
 *
 * See the rules for modifying RawLogOptions, and apply them here as well.
 *
 * @internal
 */
export interface RawLogger {
  /**
   * Log a raw entry to the underlying logger
   *
   * @param options - log entry
   */
  raw(options: RawLogOptions): void;
}

/**
 * The interface for log lines passed to log adaptors
 *
 * @public
 */
export interface LogLine {
  level: LogLevel;
  time?: number;
  pid?: number;
  hostname?: string;
  msg?: {
    context?: string;
    data: any[];
  };
}

/**
 * Log adapters are notified by the logger when an entry has been added to the
 * log.
 *
 * Log adapters are used for purposes such as:
 * - Observe log entries
 * - Write log data to a storage location
 *
 * @public
 */
export type LogAdaptor = (logLine: LogLine) => void;

/**
 * Indicates the severity of a log entry
 *
 * @public
 */
export enum LogLevel {
  TRACE = 10,
  DEBUG = 20,
  INFO = 30,
  WARN = 40,
  ERROR = 50,
  FATAL = 60,
}

/**
 * A mapping of {@link LogLevel} to name
 *
 * @public
 */
export const logLevelToName: Record<LogLevel, string> = {
  [LogLevel.TRACE]: "trace",
  [LogLevel.DEBUG]: "debug",
  [LogLevel.INFO]: "info",
  [LogLevel.WARN]: "warn",
  [LogLevel.ERROR]: "error",
  [LogLevel.FATAL]: "fatal",
};

// these types are not used as part of the global logger

/**
 * The logging interface
 *
 * @public
 */
export interface Logger extends RawLogger {
  trace(...data: any[]): void;
  debug(...data: any[]): void;
  log(...data: any[]): void;
  info(...data: any[]): void;
  warn(...data: any[]): void;
  error(...data: any[]): void;
  fatal(...data: any[]): void;

  child(options?: ChildFromLoggerOptions): Logger;
}

/**
 * The options when splitting off a child logging context
 *
 * @public
 */
export interface ChildFromLoggerOptions {
  /**
   * The context to append for this child logger
   */
  context?: string;
}
