import { nanoid } from "nanoid";
import type { QueryObserverResult, UseQueryOptions } from "react-query";
import { useQuery, useQueries } from "react-query";

export type AsyncResult<T> = {
  loading: boolean;
  error?: any;
  data: T;
};

export type ApiQueryResult<TResult> = AsyncResult<TResult | undefined> &
  Pick<QueryObserverResult<TResult | undefined>, "refetch">;

type OtherOptions<TResult> = Omit<UseQueryOptions<TResult, any, TResult, any>, "queryKey" | "queryFn">;

type Handler<TArgs extends any[] = any, TResult = any> = (...args: TArgs) => Promise<TResult>;

const fnToQueryKey = new Map<Handler, string>();
const getQueryKey = (handler: Handler): string => {
  if (!fnToQueryKey.has(handler)) {
    fnToQueryKey.set(handler, nanoid());
  }
  return fnToQueryKey.get(handler)!;
};

const errorQueries: Map<string, Error> = new Map();

/**
 * Generates options to be passed to useApiQuery and useApiQueries to disable a query after it has failed to fetch
 *
 * @param handler function of an OpenAPI service endpoint
 * @param args    array of arguments for the OpenAPI service function
 * @param options React Query options
 */
function getStopErrorRefetchMixin<TArgs extends any[], TResult>(
  handler: Handler<TArgs, TResult>,
  args: TArgs,
  options?: OtherOptions<TResult>
): OtherOptions<TResult> {
  const queryKey = getQueryKey(handler);
  const queryKeyStr = JSON.stringify([queryKey, ...args]);

  return {
    ...options,
    enabled: !errorQueries.has(queryKeyStr) && (options?.enabled === undefined || options?.enabled),
    onError: (err: Error) => {
      errorQueries.set(queryKeyStr, err);
      if (options?.onError) {
        options.onError(err);
      }
    },
  };
}

/**
 * Hook to call an OpenAPI service endpoint.
 *
 * @param handler function of an OpenAPI service endpoint
 * @param args    array of arguments for the OpenAPI service function
 * @param options React Query options
 *
 * @example
 * import { BusinessesService } from "@citadel/pse-lib-ui";
 * const YourFunctionComponent = () => {
 *   const { data, loading, error } = useApiQuery(BusinessesService.getBusiness, ["OCFO"], { refetchInterval: 5000 });
 *   // ...
 * }
 *
 * @see https://react-query.tanstack.com/reference/useQuery
 * @see https://github.com/ferdikoomen/openapi-typescript-codegen
 */
export function useApiQuery<TArgs extends any[], TResult>(
  handler: Handler<TArgs, TResult>,
  args: TArgs,
  options?: OtherOptions<TResult>
): ApiQueryResult<TResult> {
  const { data, isError, isLoading, error, refetch } = useQuery(
    [getQueryKey(handler), ...args],
    async () => await handler(...args),
    getStopErrorRefetchMixin(handler, args, options)
  );

  return {
    data,
    loading: isLoading,
    error: isError && error,
    refetch,
  };
}

export type ApiQueryParams<TArgs extends any[] = any, TResult = any> = {
  handler: Handler<TArgs, TResult>;
  args: TArgs;
  options?: OtherOptions<TResult>;
};

export function useApiQueries<TArgs extends any[], TResult>(queries: ApiQueryParams<TArgs, TResult>[]) {
  return useQueries(
    queries.map(({ handler, args, options }) => {
      return {
        queryKey: [getQueryKey(handler), ...args],
        queryFn: async () => await handler(...args),
        ...getStopErrorRefetchMixin(handler, args, options),
      };
    })
  );
}
