import { createPackageLogger, setGlobalLogger } from "@citadel/cdx-logger";
import { createBrowserLogger } from "@citadel/cdx-logger-browser";

export const initLogger = () => setGlobalLogger(createBrowserLogger());

export const packageLogger = createPackageLogger({
  rootDirectory: __dirname,
  rootContext: "cdx-auth-browser",
});
export const logger = packageLogger.logger;
