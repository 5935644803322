import type { Media } from "../types/Media";
import type { AdapterStep } from "./dataAdapterBase";
import { DataAdapterBase } from "./dataAdapterBase";

// Skynet API is used by the Slack Data Adapter.
// https://skynetapi.citadelgroup.com/docs/#post-/messaging/slack/upload

export class SlackAdapter extends DataAdapterBase {
  public static service: string = "Slack";

  protected operations = [
    { description: "Describe Issue", method: this.describeIssue },
    { description: "Upload Media", method: this.postMedia },
    { description: "Upload Logs", method: this.postLogs },
  ];

  /**
   * First Step for Slack Reporting is to post the description on behalf of the configured username.
   * @returns {Promise<AdapterStep>} Each step will return a common shape.
   */
  async describeIssue(): Promise<AdapterStep> {
    if (!this.config.Slack) {
      return {
        timestamp: new Date(),
        success: false,
        description: "Slack was not properly configured for error reporting. Please inform the development team.",
      };
    }

    const { Slack: config } = this.config;
    try {
      const response = await fetch("https://skynetapi.citadelgroup.com/messaging/slack", {
        credentials: "include",
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          workspaceName: config.workspace,
          target: config.channel,
          message: [
            `Application: ${this.report.application}`,
            `Environment: ${this.report.environment}`,
            `URL: ${this.report.url}`,
            `Description: ${this.report.description}`,
          ].join("\n"),
          userName: `${this.report.user}, via ${config.user}` || config.user,
          iconUrl: config.iconUrl || "https://www.citadel.com/favicon.ico?v2",
        }),
      });

      const json = await response.json();
      let description: string | undefined;

      // To get messages in you need to ensure the `Skynet API` user is invited to the channel.
      // Otherwise you get a cryptic error about the channel not being found.
      /*
      {
        "ok": false,
        "error": "channel_not_found",
        "warning": "missing_charset"
      }
      */
      if (
        json?.error === "channel_not_found" ||
        json?.responseStatus?.message === "Caller isn't in target channel/group or the channel/group doesn't exist"
      ) {
        description =
          "Channel was not known by the 'Skynet API' slack user. Updates sometimes take up to an hour to propagate. Has the 'Skynet API' user been added to the channel over an hour ago?";
      }

      if (!response.ok) {
        description =
          (description === undefined && json?.responseStatus?.message) ||
          description ||
          "Creating a thread in Slack failed.";
        return {
          timestamp: new Date(),
          success: false,
          description,
        };
      }

      const success = json.ok || false;
      return {
        timestamp: new Date(),
        success,
        description: success ? undefined : description || "Creating a thread in Slack failed.",
        fields: {
          channel: json.channel,
          ts: json.ts,
        },
      };
    } catch (e: any) {
      return {
        timestamp: new Date(),
        success: false,
        description: "Creating a thread in Slack failed. Is the Skynet API reachable?",
        error: e,
      };
    }
  }

  /**
   * Posting media needs to be the first item in a Slack thread, since it provides the most value.
   * @param previousAdapterResult {AdapterStep|null} To post the screenshot a previous step must have returned a `ts` from Slack API.
   * @returns {Promise<AdapterStep>} Each step will return a common shape.
   */
  async postMedia(previousAdapterResult: AdapterStep | null): Promise<AdapterStep> {
    const usableMedia: Array<Media & { blob: Blob }> | undefined = this.report.media?.filter(
      (mediaItem) => mediaItem.usable && mediaItem.blob
    ) as unknown as Array<Media & { blob: Blob }>;
    if (usableMedia === undefined || usableMedia.length === 0) {
      // Without valid media, return early to continue publishing the report.
      return {
        timestamp: new Date(),
        success: true,
        fields: previousAdapterResult?.fields,
      };
    }

    const blobUploads: Promise<AdapterStep>[] = [];
    for (const media of usableMedia) {
      try {
        let description = "Shared Screenshot";
        if (media.annotations.length > 0) {
          for (let index = 0; index < media.annotations.length; index++) {
            const annotation = media.annotations[index];
            description += `#${index + 1} – ${annotation.color} ${annotation.fill ? "Redact" : "Annotation"} '${
              annotation.text || "No Description"
            }'`;
          }
        }
        blobUploads.push(this.uploadBlob(previousAdapterResult?.fields?.ts, description, media.blob));
      } catch (e) {
        // Ignore errors and exclude media from report.
      }
    }

    const allUploads = await SlackAdapter.handleMultipleBlobs(blobUploads);
    return {
      ...allUploads,
      fields: previousAdapterResult?.fields,
    };
  }

  /**
   * With a ts from previous Slack API access, concurrently include logs into a Slack thread.
   * @param previousAdapterResult Previous Slack API thread.
   * @returns {Promise<AdapterStep>} indicating success of all logs to upload.
   */
  async postLogs(previousAdapterResult: AdapterStep | null): Promise<AdapterStep> {
    const blobUploads: Promise<AdapterStep>[] = [];
    const logsToInclude = this.logsWithStringifiedValue();
    for (const log of logsToInclude) {
      try {
        const blob = new Blob([log.content], { type: "text/plain" });
        const description = `Log: ${log.name}`;
        const uploadPromise = this.uploadBlob(previousAdapterResult?.fields?.ts, description, blob);
        blobUploads.push(uploadPromise);
      } catch (e) {
        // Ignore errors and exclude the logs from report.
      }
    }

    return await SlackAdapter.handleMultipleBlobs(blobUploads);
  }

  /**
   * Given AdapterStep[], normalize the overall AdapterStep response.
   * @param toHandle Many parallel AdapterStep(s).
   * @returns {AdapterStep} Normalized, returning false for any individual AdapterStep that fails.
   */
  static async handleMultipleBlobs(toHandle: Promise<AdapterStep>[]): Promise<AdapterStep> {
    const allSettledLogs = await Promise.allSettled(toHandle);
    const anyUnsuccessful = allSettledLogs.some((settledLog) => {
      if (settledLog.status === "fulfilled") {
        return !settledLog.value.success;
      }
      return true;
    });
    if (anyUnsuccessful) {
      return {
        timestamp: new Date(),
        success: false,
        description: "Blobs could not be uploaded to Slack API, did the Skynet API return a valid ts and channel?",
      };
    }

    return {
      timestamp: new Date(),
      success: true,
    };
  }

  /**
   * Helper method to upload a value in Blob format to a known thread via Skynet API to Slack.
   * @param ts {string|null|undefined} The Slack `ts` value instructs which thread to upload into.
   * @param message {string} required message to add in while uploading.
   * @param value {Blob} represents the item to upload.
   * @returns {Promise<AdapterStep>} Each upload will return a common shape.
   */
  async uploadBlob(ts: string | null | undefined, message: string, value: Blob): Promise<AdapterStep> {
    if (ts === null || ts === undefined || !this.config.Slack) {
      return {
        timestamp: new Date(),
        success: false,
        description: `Upload of '${message}' failed, an invalid thread was returned from Slack API.`,
      };
    }

    const { Slack: config } = this.config;
    try {
      const formData = new FormData();
      formData.append("WorkspaceName", config.workspace);
      formData.append("Target", config.channel);
      formData.append("Message", message);
      formData.append("Ts", ts);
      formData.append("File", value);

      const response = await fetch("https://skynetapi.citadelgroup.com/messaging/slack/upload", {
        credentials: "include",
        method: "POST",
        headers: {
          accept: "application/json",
        },
        body: formData,
      });
      const json = await response.json();
      return {
        timestamp: new Date(),
        success: json.ok || false,
      };
    } catch (e: any) {
      return {
        timestamp: new Date(),
        success: false,
        description: `Upload of '${message}' failed, an error was returned from Slack API.`,
        error: e,
      };
    }
  }
}
