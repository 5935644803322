import { globalLogger } from "./globalLogger";
import type { Logger } from "./Logger";

/**
 * The options passed to {@link createPackageLogger}
 *
 * @public
 */
export interface PackageLoggerOptions {
  /**
   * The root directory for source files of the package
   */
  rootDirectory: string;

  /**
   * The root context is prepended to the context for all file loggers within
   * a package
   *
   * The standard convention is to set this to the name of the package
   */
  rootContext?: string;
}

/**
 * Options object which can be passed to `PackageLogger.createFileLogger`
 *
 * @public
 */
export interface FileLoggerOptions {
  /**
   * The absolute path to the filename. Should be `__filename`
   */
  filename: string;
}

/**
 * Scope for logging entries within the scope of a package
 *
 * @public
 * @see {@link createPackageLogger}
 */
export interface PackageLogger {
  /**
   * Create a logging context for a source file using the absolute filename
   *
   * @example
   * ```ts
   * import { packageLogger } from '../logger';
   *
   * const logger = packageLogger.createFileLogger(__filename);
   *
   * // ... use like this:
   * logger.info('this is an informational log');
   * ```
   * @param filename - the filename, should usually be `__filename`
   */
  createFileLogger(filename: string): Logger;

  /**
   * Create a logging context for a source file using options
   *
   * @remarks Prefer to use the string form of this function
   * @example
   * ```ts
   * import { packageLogger } from '../logger';
   *
   * const logger = packageLogger.createFileLogger({
   *   filename: __filename
   * });
   *
   * // ... use like this:
   * logger.info('this is an informational log');
   * ```
   * @param options - the options for configuring this file logger
   */
  createFileLogger(options: FileLoggerOptions): Logger;

  /**
   * The package logging scope
   */
  readonly logger: Logger;
}

/**
 * Creates a logger which can be used within a package
 *
 * @example
 * ```ts
 * // src/logger.ts
 * import { createPackageLogger } from "@citadel/cdx-logger";
 *
 * export const packageLogger = createPackageLogger({
 *   rootContext: '@citadel/my-example-package',
 *   rootDirectory: __dirname
 * });
 * ```
 *
 * @public
 * @param options - configuration of the package logger
 * @returns A package logger
 */
export const createPackageLogger = ({ rootContext, rootDirectory }: PackageLoggerOptions): PackageLogger => {
  const logger = globalLogger.child({ context: rootContext });

  return {
    createFileLogger(opts: FileLoggerOptions | string) {
      const filename = typeof opts === "string" ? opts : opts.filename;
      return logger.child({ context: filename.replace(rootDirectory, "").replace(/^(\\|\/){1}/, "") });
    },
    logger,
  };
};
