/// TODO: CTOTECH-1777
/* eslint-disable no-param-reassign */

// Adapted from splunk-logging, minimizing effort required
// https://github.com/splunk/splunk-javascript-logging/blob/master/utils.js
// https://www.npmjs.com/package/@types/splunk-logging
// not necessarily in line with Citadel code expectations
// This has been adapted because splunk is using request rather than axios
// which is both deprecated and has a ton of polyfill requirements.
// Under Apache License 2.0 we are free to use however
// @ts-nocheck

/**
 * Utility functions.
 * @exports utils
 */
var utils = {};

/**
 * Formats the time for Splunk Enterprise or Splunk Cloud as a the epoch time in seconds.
 *
 * @param {(string|number|date)} time - A date string, timestamp, or <code>Date</code> object.
 * @returns {number|null} Epoch time in seconds, or <code>null</code> if <code>time</code> is malformed.
 * @static
 */
utils.formatTime = function (time) {
  var cleanTime;

  // If time is a Date object, return its value.
  if (time instanceof Date) {
    time = time.valueOf();
  }

  if (!time || time === null) {
    return null;
  }

  // Values with decimals
  if (time.toString().indexOf(".") !== -1) {
    cleanTime = parseFloat(time).toFixed(3); // Clean up the extra decimals right away.

    // A perfect time in milliseconds, with the decimal in the right spot.
    if (cleanTime.toString().indexOf(".") >= 10) {
      cleanTime = parseFloat(cleanTime.toString().substring(0, 14)).toFixed(3);
    }
  }
  // Values without decimals
  else {
    // A time in milliseconds, no decimal (ex: Date.now()).
    if (time.toString().length === 13) {
      cleanTime = (parseFloat(time) / 1000).toFixed(3);
    }
    // A time with fewer than expected digits.
    else if (time.toString().length <= 12) {
      cleanTime = parseFloat(time).toFixed(3);
    }
    // Any other value has more digits than the expected time format, get the first 14.
    else {
      cleanTime = parseFloat(time.toString().substring(0, 13) / 1000).toFixed(3);
    }
  }
  return cleanTime;
};

/**
 * Converts an iterable into to an array.
 *
 * @param {(Array|Object)} iterable - Thing to convert to an <code>Array</code>.
 * @returns {Array}
 * @static
 */
utils.toArray = function (iterable) {
  return Array.prototype.slice.call(iterable);
};

/**
 * Asynchronous while loop.
 *
 * @param {function} [condition] - A function returning a boolean, the loop condition.
 * @param {function} [body] - A function, the loop body.
 * @param {function} [callback] - Final callback.
 * @static
 */
utils.whilst = function (condition, body, callback) {
  condition =
    condition ||
    function () {
      return false;
    };
  body =
    body ||
    function (done) {
      done();
    };
  callback = callback || function () {};

  var wrappedCallback = function (err) {
    if (err) {
      callback(err);
    } else {
      utils.whilst(condition, body, callback);
    }
  };

  if (condition()) {
    body(wrappedCallback);
  } else {
    callback(null);
  }
};

/**
 * Waits using exponential backoff.
 *
 * @param {object} [opts] - Settings for this function. Expected keys: attempt, rand.
 * @param {function} [callback] - A callback function: <code>function(err, timeout)</code>.
 */
utils.expBackoff = function (opts, callback) {
  callback = callback || function () {};
  if (!opts || typeof opts !== "object") {
    callback(new Error("Must send opts as an object."));
  } else if (opts && !opts.hasOwnProperty("attempt")) {
    callback(new Error("Must set opts.attempt."));
  } else {
    var min = 10;
    var max = 1000 * 60 * 2; // 2 minutes is a reasonable max delay

    var rand = Math.random();
    if (opts.hasOwnProperty("rand")) {
      rand = opts.rand;
    }
    rand++;

    var timeout = Math.round(rand * min * Math.pow(2, opts.attempt));

    timeout = Math.min(timeout, max);
    setTimeout(function () {
      callback(null, timeout);
    }, timeout);
  }
};

/**
 * Binds a function to an instance of an object.
 *
 * @param {object} [self] - An object to bind the <code>fn</code> function parameter to.
 * @param {object} [fn] - A function to bind to the <code>self</code> argument.
 * @returns {function}
 * @static
 */
utils.bind = function (self, fn) {
  return function () {
    return fn.apply(self, arguments);
  };
};

/**
 * Copies all properties into a new object which is returned.
 *
 * @param {object} [obj] - Object to copy properties from.
 */
utils.copyObject = function (obj) {
  var ret = {};
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      ret[key] = obj[key];
    }
  }
  return ret;
};

/**
 * Copies all elements into a new array, which is returned.
 *
 * @param {array} [arr] - Array to copy elements from.
 * @returns {array}
 * @static
 */
utils.copyArray = function (arr) {
  var ret: any[] = [];
  for (var i = 0; arr && i < arr.length; i++) {
    ret[i] = arr[i];
  }
  return ret;
};

/**
 * Takes a property name, then any number of objects as arguments
 * and performs logical OR operations on them one at a time
 * Returns true as soon as a truthy
 * value is found, else returning false.
 *
 * @param {string} [prop] - property name for other arguments.
 * @returns {boolean}
 * @static
 */
utils.orByProp = function (prop) {
  var ret = false;
  for (var i = 1; !ret && i < arguments.length; i++) {
    if (arguments[i]) {
      ret = ret || arguments[i][prop];
    }
  }
  return ret;
};

/**
 * Like <code>utils.orByProp()</code> but for a falsey property.
 * The first argument after <code>prop</code> with that property
 * defined will be returned.
 * Useful for Booleans and numbers.
 *
 * @param {string} [prop] - property name for other arguments.
 * @returns {boolean}
 * @static
 */
utils.orByFalseyProp = function (prop) {
  var ret = null;
  // Logic is reversed here, first value wins
  for (var i = arguments.length - 1; i > 0; i--) {
    if (arguments[i] && arguments[i].hasOwnProperty(prop)) {
      ret = arguments[i][prop];
    }
  }
  return ret;
};

/**
 * Tries to validate the <code>value</code> parameter as a non-negative
 * integer.
 *
 * @param {number} [value] - Some value, expected to be a positive integer.
 * @param {number} [label] - Human readable name for <code>value</code>
 * for error messages.
 * @returns {number}
 * @throws Will throw an error if the <code>value</code> parameter cannot by parsed as an integer.
 * @static
 */
utils.validateNonNegativeInt = function (value, label) {
  value = parseInt(value, 10);
  if (isNaN(value)) {
    throw new Error(label + " must be a number, found: " + value);
  } else if (value < 0) {
    throw new Error(label + " must be a positive number, found: " + value);
  }
  return value;
};

export default utils;
