import { Icon } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { isNil } from "lodash";
import type { AdminAssessmentItem } from "../../types/assessment";
import classes from "./bd-grid.less";

export type BDGridSolutionTooltipProps = {
  /** If true, tooltip will display a header indicating that the cell's value is correct.\
   * If false, tooltip will display a header indicating that the cell's value is incorrect.\
   * If undefined, no header will be displayed.
   */
  correct?: boolean;
  solutions: AdminAssessmentItem["solutions"];
};

/** Component rendering an icon that when hovered will display a tooltip containing the possible solutions */
export const BDGridSolutionTooltip = ({ correct, solutions }: BDGridSolutionTooltipProps) => {
  if (!solutions) {
    return null;
  }

  const tooltipContent = (
    <>
      {!isNil(correct) && (
        <div className={classes.correctnessHeader}>
          {correct && (
            <>
              <Icon icon="tick" />
              &nbsp;Correct
            </>
          )}
          {!correct && (
            <>
              <Icon icon="cross" />
              &nbsp;Incorrect
            </>
          )}
        </div>
      )}
      {solutions && solutions.length > 0 && (
        <div className={classes.solutions}>
          <div>Solutions:</div>
          {solutions.map((solution) => (
            <div key={solution.id}>{solution.value}</div>
          ))}
        </div>
      )}
    </>
  );

  return (
    <Tooltip2
      className={classes.solutionTooltip}
      content={tooltipContent}
      placement="bottom"
      popoverClassName={classes.solutionTooltipPopover}
    >
      <Icon icon="lightbulb" />
    </Tooltip2>
  );
};
