import "./app.less";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { UserMonitoringProvider } from "@citadel/cdx-provider-user-monitoring";
import { ApolloProvider } from "@citadel/cdx-provider-apollo";
import { GlobalStateProvider } from "../../stores/global/global-store";
import { routesList } from "../../constants/routes";
import { pkg } from "../../constants/app";
import { client } from "../../graphql/client";

export const App = () => (
  <UserMonitoringProvider
    datadogConfig={{
      applicationId: "25f4b349-cc23-43e7-a307-f6abd328d17f",
      clientToken: "pub2ed87fa04d92119b1ebf0ec55e5c75dc",
      service: pkg?.name,
      version: pkg?.version || "0.0.0",
    }}
    enabledForLocal={true}
  >
    <ApolloProvider client={client}>
      <GlobalStateProvider>
        <BrowserRouter>
          <Switch>
            {routesList.map((route) => (
              <Route key={route.path()} path={route.path()} component={route.component} />
            ))}
          </Switch>
        </BrowserRouter>
      </GlobalStateProvider>
    </ApolloProvider>
  </UserMonitoringProvider>
);
