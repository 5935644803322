import { Icon, Colors } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { Tooltip2 } from "@blueprintjs/popover2";
import { useChannel } from "../../hooks/useChannel";
import { NONE_CHANNEL } from "../../constants/standardChannels";

export const Button = styled.button`
  margin: 0;
  border: 0;
  background: transparent;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const NoneIcon = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 3px;
  border: 1px dashed ${Colors.GRAY3};

  .bp3-dark & {
    border-color: ${Colors.GRAY3};
  }
`;

export const IconLink = styled(Icon)`
  color: var(--cdx-channel-color);
`;

export function ChannelTrigger() {
  const [channel] = useChannel();

  return (
    <Button data-cdx-channel={channel}>
      {channel === NONE_CHANNEL ? (
        <Tooltip2 content="No channel selected" placement="bottom">
          <NoneIcon data-testid="channel-selector" />
        </Tooltip2>
      ) : (
        <IconLink
          icon="link"
          iconSize={12}
          className="current-channel-icon"
          aria-label={`Widget is on channel ${channel}`}
          data-testid="channel-selector"
        />
      )}
    </Button>
  );
}
