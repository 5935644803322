import { createLogger } from "./createLogger";
import { consoleLogAdaptor } from "./consoleLogAdaptor";
import { ChildLogger } from "./childLogger";
import type { Logger, RawLogger, RawLogOptions } from "./Logger";

/**
 * GlobalRawLogger is shared between versions.
 *
 * Make sure updates to the public API are forwards and backwards
 * compatible according to the rules in ./logger.ts
 */
class GlobalRawLogger implements RawLogger {
  private _logger: RawLogger;

  constructor(logger: RawLogger) {
    this._logger = logger;
  }

  raw(options: RawLogOptions) {
    this._logger.raw(options);
  }

  setLogger(logger: RawLogger) {
    this._logger = logger;
  }
}

/**
 * Indexing by a symbol means that it is harder to access this value accidentally
 * when bypassing the type system (e.g. if globalThis is logged).
 *
 * NB: it is still possible to access it by using Symbol.for -- that is
 * why we use it, so multiple instances of this module use the same global logger.
 */
const globalLoggerSymbol = Symbol.for("__citadelCdxGlobalLogger");

/**
 * Using a custom interface here means we will not pollute the global type
 * outside of this module.
 */
interface GlobalWithLogger {
  [globalLoggerSymbol]?: GlobalRawLogger | undefined;
}

const getGlobalWithLogger = (): GlobalWithLogger => {
  // @ts-ignore TODO: don't want to have to redefine these globals when in fact we want to softly type check here
  if (typeof globalThis !== "undefined") return globalThis as GlobalWithLogger;
  // @ts-ignore TODO: don't want to have to redefine these globals when in fact we want to softly type check here
  // eslint-disable-next-line no-restricted-globals
  if (typeof self !== "undefined") return self as GlobalWithLogger;
  // @ts-ignore TODO: don't want to have to redefine these globals when in fact we want to softly type check here
  if (typeof window !== "undefined") return window as GlobalWithLogger;
  // @ts-ignore TODO: don't want to have to redefine these globals when in fact we want to softly type check here
  if (typeof global !== "undefined") return global as GlobalWithLogger;

  console.warn("@citadel/cdx-logger: Could not find global object. Logging may not work as expected.");
  return {};
};

const getGlobalRawLogger = (): GlobalRawLogger => {
  /**
   * The global logger is stored in the global object so that multiple instances of the
   * module use the same global logger. It does mean that we need to be extra
   * careful with breaking changes though.
   */
  const globalWithLogger: GlobalWithLogger = getGlobalWithLogger();

  if (!globalWithLogger[globalLoggerSymbol]) {
    globalWithLogger[globalLoggerSymbol] = new GlobalRawLogger(
      createLogger({
        adaptors: [consoleLogAdaptor],
      })
    );
  }

  return globalWithLogger[globalLoggerSymbol]!;
};

const globalRawLogger = getGlobalRawLogger();

/**
 * Shared logger between applications and libraries. This allows you to configure
 * adaptors once, and then have all libraries use that same adaptors.
 *
 * Use {@link setGlobalLogger} to update the global logger.
 *
 * @internal
 */
export const globalLogger: Logger = new ChildLogger({ parentLogger: globalRawLogger });

/**
 * Sets the root logging context
 *
 * You can use this to set the logging context based on the environment you're
 * logging from (i.e. NodeJS vs Browser)
 *
 * @public
 * @param logger - The logger to set as the root logging context
 */
export const setGlobalLogger = (logger: RawLogger) => {
  globalRawLogger.setLogger(logger);
};
