import type { StateCreator } from "zustand";
import type { Toast } from "./types";

export interface ToastState {
  clear: () => void;
  dismiss: (toast: Toast) => void;
  show: (toast: Toast) => void;
  toasts: Toast[];
}

type ToastSetState = {
  (state: Partial<ToastState> | ((fullState: ToastState) => Partial<ToastState>), replace?: boolean): void;
};

export const toastStore: StateCreator<ToastState, ToastSetState> = (set) => ({
  toasts: [],
  clear: () => set(() => ({ toasts: [] })),
  dismiss: (toast) =>
    set((state) => {
      const toastIndex = state.toasts.findIndex((lookupToast) => lookupToast.id === toast.id);
      if (toastIndex === -1) {
        return state;
      }
      state.toasts[toastIndex].onDismiss?.();
      return {
        toasts: [...state.toasts.slice(0, toastIndex), ...state.toasts.slice(toastIndex + 1)],
      };
    }),
  show: (toast) =>
    set((state) => {
      const toastIndex = state.toasts.findIndex((lookupToast) => lookupToast.id === toast.id);
      const newToasts = [...state.toasts];
      if (toastIndex === -1) {
        return { toasts: [...newToasts, toast] };
      }
      newToasts[toastIndex] = toast;
      return { toasts: newToasts };
    }),
});
