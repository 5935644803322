import { HeaderBar } from "../header-bar";

export const PostSubmissionView = () => {
  return (
    <>
      <HeaderBar label="Successfully Submitted" />
      <h1>Thank you for taking the assessment.</h1>
      <h3>Your recruiting contact will be in touch soon.</h3>
    </>
  );
};
