import { AppStateContext } from "../context/AppStateContext";
import { createStoredValueHook } from "./createStoredValueHook";

/**
 * The useAppState hook provides a binding into a piece of app state
 *
 * @param key the app setting to use
 * @param defaultValue to store in the state when there is no value or the value is undefined
 */
export const useAppState = createStoredValueHook(AppStateContext);
