import { isFinite } from "lodash";

/**
 * Convert the specified number to a comma-separated number with optional currency symbol and precision.
 * @param num A number.
 * @param currency An optional currency, e.g., "USD", "EUR".
 * @param precision Precision of the resulting formatted number, i.e., the number of digits after the decimal place.
 * @returns A number formatted in the specified currency with the specified precision, e.g., "€543.21"
 */
export const toNumeric = (num: number | null | undefined | boolean, currency?: string, precision: number = 0) => {
  if (!isFinite(num)) {
    return "";
  }

  const formatter = new Intl.NumberFormat(undefined, {
    ...(currency && { style: "currency", currency, currencySign: "accounting" }),
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

  return formatter.format(num as number);
};
