import { AnchorButton, Collapse, Dialog, Divider, H4, Icon, Pre } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { Fragment, useState, useEffect, useCallback } from "react";
import { DateTime } from "luxon";
import type { CdxUiReportIssueConfig } from "@citadel/cdx-config";
import type { AdapterStep } from "./data-adapter/dataAdapterBase";
import { dataAdapterErrors } from "./data-adapter/dataAdapterBase";

// Magic constant, caps the number of errors to display in the UI.
const MAX_DISPLAYABLE_DATA_ADAPTER_ERRORS = 4;

const StyledDialog = styled(Dialog)`
  min-width: 60vw;
`;

const StyledPre = styled(Pre)`
  white-space: pre-wrap;
`;

const StyledAnchorButton = styled(AnchorButton)`
  margin: 0 -10px 10px;
  display: block;

  .bp3-button-text {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p,
    span {
      margin: 0;
    }
  }
`;

const StyledParagraph = styled.p`
  max-width: calc(30vw + 30px);
  display: block;
  text-align: left;
`;

const StyledDescription = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30vw;
  display: block;
  float: left;
`;

const StyledIcon = styled(Icon)`
  margin: 0 0 0 5px !important;
`;

const PaneWrapper = styled.div`
  margin: 20px 20px 0;
  min-height: 150px;
`;

interface DeveloperConfigurationProps {
  onClose: () => void;
  config?: CdxUiReportIssueConfig;
}
export const DeveloperConfiguration = ({ config, onClose }: DeveloperConfigurationProps) => {
  const [adapterErrors, setAdapterErrors] = useState<AdapterStep[]>([]);
  const [openError, setOpenError] = useState<number | null>(null);
  const { length: adapterErrorCount } = adapterErrors;

  const handleClick = useCallback(
    (index: number) => {
      if (openError === index) {
        setOpenError(null);
        return;
      }
      setOpenError(index);
    },
    [openError]
  );

  useEffect(() => {
    if (dataAdapterErrors.length > adapterErrorCount) {
      setAdapterErrors(dataAdapterErrors.reverse());
      if (openError === null) {
        setOpenError(dataAdapterErrors.length - 1);
      }
    }
  }, [adapterErrorCount, openError]);

  return (
    <StyledDialog isOpen={true} icon="info-sign" title="Error Reporting Developer Info" onClose={onClose}>
      <PaneWrapper>
        <H4>Configuration</H4>
        <StyledPre>{JSON.stringify(config, null, 2)}</StyledPre>
        <Divider />
        <H4>Session Errors</H4>
        <AdapterErrors adapterErrors={adapterErrors} openError={openError} onClick={handleClick} />
      </PaneWrapper>
    </StyledDialog>
  );
};

interface AdapterErrorsProps {
  adapterErrors: AdapterStep[];
  openError: number | null;
  onClick: (index: number) => void;
}
function AdapterErrors({ adapterErrors, openError, onClick }: AdapterErrorsProps) {
  const visibleAdapterErrors = adapterErrors.slice(0, MAX_DISPLAYABLE_DATA_ADAPTER_ERRORS);
  const adapterCount = visibleAdapterErrors.length;

  if (adapterCount === 0) {
    return <Fragment>No errors reported during this session.</Fragment>;
  }

  return (
    <Fragment>
      {visibleAdapterErrors.map((error, index) => {
        const timestamp: string | null = DateTime.fromJSDate(new Date(error.timestamp)).toRelative();
        const isOpen = openError === index;
        return [
          <StyledAnchorButton
            key={index + "button"}
            minimal
            onClick={() => onClick(index)}
            disabled={adapterCount === 1}
          >
            <StyledParagraph>
              <StyledDescription>{error.description || "Unknown issue"}</StyledDescription>
              {adapterCount > 1 ? <StyledIcon icon={isOpen ? "chevron-up" : "chevron-down"} /> : null}
            </StyledParagraph>
            <span>{timestamp || "Unknown timeline"}</span>
          </StyledAnchorButton>,
          <Collapse isOpen={isOpen} key={index + "collapse"}>
            <StyledPre>{JSON.stringify(error, null, 2)}</StyledPre>
          </Collapse>,
        ];
      })}
    </Fragment>
  );
}
