import styled from "@emotion/styled";
import type { CSSProperties, ReactElement } from "react";
import { useMemo, useState } from "react";
import type { IconName } from "@blueprintjs/core";
import { Colors, Icon } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { Box } from "@citadel/cdx-ui";
// import { declareCurrentUserGatekeeper } from "@citadel/cdx-ui-lib-gatekeeper";
import { Colors as PSEColors } from "../../constants/styled";

/*
Gatekeeper disabled - gatekeeper isn't falling back to the fallback when run in an envionment
without our auth provider, so fails when trying to hit oauth
const { useGk: useFakeProfilePics } = declareCurrentUserGatekeeper({
  gkName: "pse.manager-dashboard.use-fake-profile-pics",
  fallback: false,
});
*/

type CornersOption = "rounded" | "round" | "square";
interface ImageDimensions {
  size: number;
  corners: CornersOption;
}

const cornersToBorderRadius = ({ size, corners }: ImageDimensions) => {
  if (corners === "round") {
    return size / 2;
  } else if (corners === "rounded") {
    return size / 4;
  }
  return 0;
};

const sizeFromProps = ({ size }: ImageDimensions) => size;

const PhotoWrapper = styled.div`
  position: relative;
`;

const TooltipWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 11px;
  margin-top: -11px;
  color: ${PSEColors.CITADEL_BRAND_ROYAL};
`;

const Img = styled.img<ImageDimensions>`
  width: ${sizeFromProps}px;
  height: ${sizeFromProps}px;
  border-radius: ${cornersToBorderRadius}px;
  object-fit: cover;
`;

const UserPlaceholder = styled(Box)`
  width: ${sizeFromProps}px;
  height: ${sizeFromProps}px;
  border-radius: ${cornersToBorderRadius}px;
  background-color: ${Colors.GRAY5};
  color: ${Colors.LIGHT_GRAY3};
  overflow: hidden;
`;

export interface EmployeePhotoProps {
  employeeId?: string;
  overlay?: {
    icon: IconName | JSX.Element;
    tooltip?: string | JSX.Element;
  };
  size?: number;
  corners?: CornersOption;
  className?: string;
  style?: CSSProperties;
  resolution?: "SMALL" | "MEDIUM" | "LARGE";
}

export const EmployeePhoto = ({
  employeeId,
  overlay,
  size = 128,
  corners = "rounded",
  className,
  style,
  resolution = "SMALL", // about 100x100px
}: EmployeePhotoProps): ReactElement => {
  const [isError, setIsError] = useState(false);
  const fakePictures = false; // useFakeProfilePics(); gatekeeper disabled

  const imgElement = useMemo(() => {
    const usePlaceholder = !fakePictures && (!employeeId || isError);

    return usePlaceholder ? (
      <UserPlaceholder alignItems="end" justifyContent="center" size={size} corners={corners}>
        <Icon icon="person" iconSize={size} />
      </UserPlaceholder>
    ) : (
      <Img
        size={size}
        corners={corners}
        src={
          fakePictures
            ? `https://i.pravatar.cc/60?u=${employeeId}`
            : `https://pse-api-prod.citadelgroup.com/api/v1/people/${employeeId}/avatar?size=${resolution}`
        }
        onError={() => setIsError(true)}
      />
    );
  }, [corners, employeeId, fakePictures, isError, resolution, size]);

  const overlayElement = useMemo(() => {
    if (!overlay) {
      return undefined;
    }

    const icon = typeof overlay.icon === "string" ? <Icon icon={overlay.icon} iconSize={size / 4} /> : overlay.icon;

    const tooltip = overlay.tooltip ? (
      <Tooltip2 placement="top" content={overlay.tooltip}>
        {icon}
      </Tooltip2>
    ) : (
      icon
    );

    return <TooltipWrapper>{tooltip}</TooltipWrapper>;
  }, [overlay, size]);

  return (
    <PhotoWrapper className={className} style={style}>
      {imgElement}
      {overlayElement}
    </PhotoWrapper>
  );
};
