import axios from "axios";
import { logger } from "../logger";
import type { PingFederateTokens } from "./types";
import { getConfig } from "./env";

const AXIOS_OPTIONS = {
  headers: {
    accept: "application/json",
    "content-type": "application/x-www-form-urlencoded",
  },
};

interface CodeExhangeParams {
  code: string | null;
  codeVerifier: string | null;
  redirectUri: string | null;
}

export const validateAccessTokenFromPingFederate = async (accessToken: string | undefined): Promise<unknown | null> => {
  if (!accessToken) {
    return null;
  }

  const { url } = getConfig();
  try {
    const { data } = await axios.get(url.user, {
      ...AXIOS_OPTIONS,
      headers: { ...AXIOS_OPTIONS.headers, Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    logger.error("Unable to validate access token", e);
    return null;
  }
};

export const exchangeCodeForTokensFromPingFederate = async ({
  code,
  codeVerifier,
  redirectUri,
}: CodeExhangeParams): Promise<PingFederateTokens | null> => {
  const { clientId, url } = getConfig();
  try {
    const { data } = await axios.post<PingFederateTokens>(
      url.token,
      new URLSearchParams({
        grant_type: "authorization_code",
        code: code || "",
        redirect_uri: redirectUri || "",
        client_id: clientId,
        code_verifier: codeVerifier || "",
      }),
      AXIOS_OPTIONS
    );
    return data;
  } catch (e) {
    logger.error(`Unable to authenticate while exchanging authorization code`, e);
    return null;
  }
};

export const refreshTokensFromPingFederate = async (
  refreshToken: string | null
): Promise<PingFederateTokens | null> => {
  if (!refreshToken) {
    return null;
  }
  const { url } = getConfig();
  try {
    const { data } = await axios.post<PingFederateTokens>(
      url.token,
      new URLSearchParams({
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      }),
      AXIOS_OPTIONS
    );
    return data;
  } catch (e) {
    logger.error(`Unable to authenticate while refreshing access token`, e);
    return null;
  }
};
