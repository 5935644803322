// TODO[CDX-1399]: Retire cdx-lib-auth-api
// https://jira.citadelgroup.com/browse/CDX-1472
import { getCdxSessionCookies, redirectToAuthenticate } from "@citadel/cdx-lib-auth-browser";

const isCGDomain = (): boolean => window.location.host.includes("citadelgroup.com");

/**
 * Checks to see if the client has a ga session cookie,
 * and optionally redirects to authenticate the client if not.
 *
 * NOTE: This does not verify that the session cookie is valid.
 *       See verify-session.ts for a sounder authentication check.
 *
 * NOTE: The redirect behavior here is intentionally simple.
 *       We don't want each app to have complex redirect handling,
 *       rather based on what's available in a given env the auth
 *       client will handle the redirects appropriately
 *
 * @param {boolean} [requiresAuthenticate] -
 *   whether or not authenticate is required
 *   default: true
 * @returns {boolean} - whether or not the client has a session cookie
 *
 * @internal
 */
export const isAuthenticated = (requiresAuthenticate: boolean = true): boolean => {
  const { authenticateJwt, gaSessionJwt } = getCdxSessionCookies();

  // get bools
  const hasAuthenticateJwt = !!authenticateJwt;
  const hasSessionJwt = !!gaSessionJwt;

  return (hasAuthenticateJwt || !requiresAuthenticate) && hasSessionJwt;
};

/**
 * @param authJwt
 * @param requiresAuthenticate
 * @param authenticateUrl
 * returns true if redirected to authenticate
 *
 * @internal
 */
export const redirectAuthenticateIfNeeded = (authJwt: string | null, requiresAuthenticate: boolean): boolean => {
  if (!authJwt && requiresAuthenticate && isCGDomain()) {
    redirectToAuthenticate();
    return true;
  }

  return false;
};
