import type { Operation } from "@apollo/client";
// TODO[CDX-1399]: GA Security Retirement - https://wiki/x/v0QoIg
// eslint-disable-next-line no-restricted-imports
import { getAuthHeaders } from "@citadel/cdx-lib-auth-browser";

/**
 * NOTE: For alternative origins, the browser will check aggessively according to the following
 *
 * 'Access-Control-Allow-Origin': 'citadelgroup.com',
 * 'Access-Control-Allow-Methods': '*',
 * 'Access-Control-Allow-Headers': 'authorization, ...other headers',
 * 'Access-Control-Allow-Credentials': true
 */
export const addAuthHeaders = (operation: Operation) => {
  operation.setContext(({ headers = [], ...context } = {}) => ({
    ...context,
    headers: {
      ...headers,
      ...getAuthHeaders(),
    },
  }));
};
