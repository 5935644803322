import { useCallback, useMemo } from "react";
import type { HotkeyConfig } from "@blueprintjs/core";
import { useHotkeys } from "@blueprintjs/core";
import { flatMap } from "lodash";
import { useDispatch } from "react-redux";
import { assessmentActions } from "../stores/assessment/assessment-actions";
import { mapKeyToDirection } from "../utils/assessment";

const hotkeyDefaults = {
  global: true,
  preventDefault: true,
  stopPropagation: true,
  allowInInput: true,
};

export const useGridHotkeys = () => {
  const dispatch = useDispatch();

  const handleMoveKey = useCallback(
    (multiSelect: boolean, multiMove: boolean) => (evt: KeyboardEvent) => {
      dispatch({
        type: assessmentActions.MOVE_CELL_SELECTION,
        payload: { direction: mapKeyToDirection(evt.key), multiSelect, multiMove },
      });
    },
    [dispatch]
  );

  const generateMoveHotkeys = useCallback(() => {
    return flatMap(
      [
        { modifiers: [], actionName: "Move" },
        { modifiers: ["mod"], actionName: "Jump" },
        { modifiers: ["shift"], actionName: "Expand" },
        { modifiers: ["shift", "mod"], actionName: "Extend" },
      ],
      ({ modifiers, actionName }) =>
        ["up", "down", "left", "right"].map((dir) => {
          return {
            ...hotkeyDefaults,
            combo: `${modifiers.join("+")}${modifiers.length ? "+" : ""}${dir}`,
            label: `${actionName} selection ${dir}`,
            group: `Selection (${actionName})`,
            onKeyDown: handleMoveKey(modifiers.includes("shift"), modifiers.includes("mod")),
          };
        })
    );
  }, [handleMoveKey]);

  const hotkeys = useMemo<HotkeyConfig[]>(
    () => [
      ...generateMoveHotkeys(),
      {
        ...hotkeyDefaults,
        // 'mod' is 'cmd' on Mac and 'ctrl' on Win/Linux
        combo: "mod+c",
        label: "Copy",
        onKeyDown: () => {
          dispatch({ type: assessmentActions.SET_COPY_CELL });
        },
      },
      {
        ...hotkeyDefaults,
        // 'mod' is 'cmd' on Mac and 'ctrl' on Win/Linux
        combo: "mod+v",
        label: "Paste",
        onKeyDown: () => {
          dispatch({ type: assessmentActions.PASTE_CELL });
        },
      },
      {
        ...hotkeyDefaults,
        combo: "backspace",
        label: "Clear Cell",
        allowInInput: false,
        onKeyDown: () => {
          dispatch({ type: assessmentActions.CLEAR_CELLS });
        },
      },
      {
        ...hotkeyDefaults,
        combo: "del",
        label: "Clear Cell",
        allowInInput: false,
        onKeyDown: () => {
          dispatch({ type: assessmentActions.CLEAR_CELLS });
        },
      },
    ],
    [dispatch, generateMoveHotkeys]
  );

  useHotkeys(hotkeys);
};
