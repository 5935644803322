import { Colors, Menu, MenuItem } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { useChannel } from "../../hooks/useChannel";
import { useChannelDefinitions } from "../../hooks/useChannelDefinitions";
import { NONE_CHANNEL } from "../../constants/standardChannels";

const ColoredMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;

  .bp3-icon.bp3-icon-link,
  .bp3-icon.bp3-icon-link:hover {
    color: var(--cdx-channel-color);

    .bp3-dark & {
      color: var(--cdx-channel-color);
    }
  }
`;

const NoneIcon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px dashed ${Colors.GRAY3};

  .bp3-dark & {
    border-color: ${Colors.GRAY3};
  }

  &.bp3-active {
    border-color: ${Colors.WHITE};
  }
`;

export function ChannelList() {
  const channels = useChannelDefinitions();
  const [currentChannel, setChannel] = useChannel();

  return (
    <Menu data-testid="cdx-channel-menu">
      {Object.keys(channels).map((channel, i) => {
        const selected = channel === currentChannel;
        const isNoneChannel = channel === NONE_CHANNEL;

        const msg = isNoneChannel ? "No Channel" : `Channel ${i}`;

        return (
          <ColoredMenuItem
            icon={isNoneChannel ? <NoneIcon /> : "link"}
            active={selected}
            key={channel}
            text={msg}
            onClick={() => setChannel(channel)}
            data-cdx-channel={channel}
          />
        );
      })}
    </Menu>
  );
}
