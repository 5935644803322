import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

import ReactDOM from "react-dom";
import { App } from "./components/app";
import { initLogger } from "./logger";

async function main() {
  initLogger();
  ReactDOM.render(<App />, document.getElementById("root"));
}

main();
