import { getBrowserEnvironment, getBrowserRawEnvironment } from "./browser";
import { computeEnvironment } from "./computeEnvironment";
import { enrichEnvironmentWithAuthBackwardsCompatibility } from "./enrichEnvironmentWithAuthBackwardsCompatibility";
import { getProcess, getProcessEnv } from "./process";
import type { CdxFutureEnvironment, Environment } from "./types";
import { getEnvironmentVariable } from "./variables";

function isBrowser() {
  const tmpWindow = (globalThis as any).window;
  const processTitle = getProcess()?.title ?? "";
  return !processTitle.endsWith("node") && typeof tmpWindow !== "undefined";
}

function isTest() {
  // if we are not actually running tests; false
  if (getProcessEnv()?.JEST_WORKER_ID === undefined || getProcessEnv()?.NODE_ENV !== "test") {
    return false;
  }
  // only test is we haven't adusted the env for testing
  return !getEnvironmentVariable("CITADEL_ENV") && !getBrowserRawEnvironment();
}

export function getEnvironmentInternal(): CdxFutureEnvironment {
  if (!isBrowser()) {
    return computeEnvironment();
  }
  return getBrowserEnvironment();
}

/**
 * Resolves the current environment for both Node.js and the browser
 *
 * Node.js resolves based on environment variables
 * The browser resolves based on a custom config.js retrieval that leverages the asset server's environment
 */
export function getEnvironment(): Environment {
  if (isTest()) {
    return enrichEnvironmentWithAuthBackwardsCompatibility({
      citadelEnv: "stabledev",
      // NOTE: This is not ideal, but aligns with now tests were running previously
      //       we should probably change this value so the default behavior reflects
      //       our actual runtime(s)
      isLocal: true,
      target: "stabledev",
    });
  }
  const env = getEnvironmentInternal();
  return enrichEnvironmentWithAuthBackwardsCompatibility(env);
}
