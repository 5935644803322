import type { JsonWebToken } from "../api/JsonWebToken";
import type { PingFederateTokens, PingFederateCodeRequestState, SessionData } from "./types";
import { parseJwtToken } from "./parseToken";
import type { AcccessTokenData } from "./AccessTokenData";

type LocalStorageKey = keyof SessionData | keyof PingFederateCodeRequestState | keyof CurrentLeaderInformation;

function prefixKey(key: LocalStorageKey) {
  return `oauth2__${key}`;
}

const getLocalStorageValue = (key: LocalStorageKey): string | null => {
  return localStorage.getItem(prefixKey(key));
};

const setLocalStorageValue = (key: LocalStorageKey, value: string | null) => {
  const storageKey = prefixKey(key);
  if (value === null) {
    localStorage.removeItem(storageKey);
  } else {
    localStorage.setItem(storageKey, value);
  }
};

// session data

export const getSessionData = (): SessionData | null => {
  const accessToken = getLocalStorageValue("accessToken") as JsonWebToken | null;
  const idToken = getLocalStorageValue("idToken") as JsonWebToken | null;
  const refreshToken = getLocalStorageValue("refreshToken");

  // if we don't have a refresh token, we cannot re-authenticate and need a full redirect
  if (!refreshToken) {
    return null;
  }

  return { accessToken: parseJwtToken(accessToken), idToken: parseJwtToken(idToken), refreshToken };
};

export const parseAndSaveSessionData = (tokens: PingFederateTokens | null): SessionData | null => {
  if (!tokens) {
    return null;
  }

  const { access_token, id_token, refresh_token } = tokens;

  setLocalStorageValue("accessToken", access_token);
  setLocalStorageValue("idToken", id_token);
  setLocalStorageValue("refreshToken", refresh_token);

  return {
    accessToken: parseJwtToken<AcccessTokenData>(access_token),
    idToken: parseJwtToken(id_token),
    refreshToken: refresh_token,
  };
};

export function clearSessionData() {
  setLocalStorageValue("accessToken", null);
  setLocalStorageValue("idToken", null);
  setLocalStorageValue("refreshToken", null);
}

// code request data

export const getPingFederateCodeRequestState = (): PingFederateCodeRequestState | null => {
  const codeVerifier = getLocalStorageValue("codeVerifier");
  const nonce = getLocalStorageValue("nonce");
  const state = getLocalStorageValue("state");
  if (!codeVerifier || !nonce || !state) {
    return null;
  }

  return { codeVerifier, nonce, state };
};

export const storePingFederateCodeRequestState = (requestState: PingFederateCodeRequestState) => {
  const { codeVerifier, nonce, state } = requestState;
  setLocalStorageValue("codeVerifier", codeVerifier);
  setLocalStorageValue("nonce", nonce);
  setLocalStorageValue("state", state);
};

// request initialization deduping

// if a leader has been assigned for > 25s, then we consider it to have failed and elect new
const LEADER_EXPIRATION = 25_000;

export interface CurrentLeaderInformation {
  /**
   * Randomly generated id so that we can validate if we are indeed the leader since
   * there is not a proper locking mechanism to do a R,W guarantee on local storage
   */
  leaderId: string;
  /**
   * When the leader initiated their request
   */
  leaderStartedAsOf: Date;
}

export function getCurrentLeader(): CurrentLeaderInformation | null {
  const leaderId = getLocalStorageValue("leaderId");
  const leaderStartedAsOf = getLocalStorageValue("leaderStartedAsOf");

  if (!leaderId || !leaderStartedAsOf) {
    return null;
  }

  const leaderStartedAsOfDate = new Date(leaderStartedAsOf);
  if (Date.now() - leaderStartedAsOfDate.getTime() > LEADER_EXPIRATION) {
    return null;
  }

  return {
    leaderId,
    leaderStartedAsOf: new Date(leaderStartedAsOf),
  };
}

export function clearCurrentLeader() {
  setLocalStorageValue("leaderId", null);
  setLocalStorageValue("leaderStartedAsOf", null);
}

export function setCurrentLeader(leaderId: string, startedAsOf: string = new Date().toISOString()) {
  setLocalStorageValue("leaderId", leaderId);
  setLocalStorageValue("leaderStartedAsOf", startedAsOf);
}
