import { datadogRum } from "@datadog/browser-rum";
import type { Context as DatadogContext } from "@datadog/browser-core";

export interface Context extends DatadogContext {}

/**
 * Generate user actions when you want to monitor specific interactions on your application pages or measure custom timings
 * e.g. the below would collect the amount of items within a cart, what they are, and how much the cart is worth overall.
 *
 * @example
 * ```typescript
 * addAction('checkout', { cart: {
 *     amount: 42,
 *     currency: '$',
 *     nb_items: 2,
 *     items: ['socks', 't-shirt'],
 *   },
 * });
 * ```
 *
 * @param name - name of the custom action
 * @param context - context for the action, i.e. what extra parameters to log
 */
export const addAction = (name: string, context?: Context) => datadogRum.addAction(name, context);

/**
 * Monitor handled exceptions, handled promise rejections and other errors not tracked automatically
 * e.g. the below would send a handled exception error
 *
 * @example
 * ```typescript
 * try {
 *     // some code logic
 * } catch (err) {
 *   addError(err, undefined, 'source')
 * }
 * ```
 *
 * @param name - name of the custom error
 * @param context - extra parameters to log alongside the error
 */
export const addError = (name: unknown, context?: Context) => datadogRum.addError(name, context);

/**
 * Set the global context for DataDog
 *
 * @param newContext - the new global context
 */
export const setRumGlobalContext = (newContext: Context) => datadogRum.setRumGlobalContext(newContext);
