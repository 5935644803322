import type { Tracer } from "dd-trace";
import ddTrace from "dd-trace";
import type { LogAdaptor } from "@citadel/cdx-logger";

/**
 * Creates a log adapter which provides datadog with tracing information
 */
export function createDatadogTraceLogAdaptor(tracer: Tracer = ddTrace): LogAdaptor {
  return (line) => {
    const span = tracer.scope().active();

    // Don't add tracing information if we don't have an active scope
    if (!span) return;

    tracer.inject(span.context(), "log", line);
  };
}
