/**
 * prod, stabledev are the two widely used environments at Citadel
 *
 * ssa is a mirror of prod, stabledev but is only available for external (e.g. horizon) applications
 */

/** official prod value for CITADEL_ENV */
export const CITADEL_ENV_PROD = "prod";
/** official stabledev value for CITADEL_ENV */
export const CITADEL_ENV_STABLEDEV = "stabledev";
/** official ssa prod value for CITADEL_ENV */
export const CITADEL_ENV_SSA_PROD = "ssa";

/**
 * CDX standard env value for local
 * this is NOT a standard value for CITADEL_ENV across Citadel
 */
export const CITADEL_ENV_LOCAL = "";

export const CDX_BROWSER_ENVIRONMENT_KEY = "cdxBrowserEnvironment";
