import { CDX_BROWSER_ENVIRONMENT_KEY } from "./constants";
import { getProcessEnv } from "./process";
import type { CdxFutureEnvironment } from "./types";

/**
 * index.html is expected to load our environment by including the following
 * <script type="text/javascript" src="/app/path/.well-known/environment.js"></script>
 */

const EXAMPLE_ENVIRONMENT: CdxFutureEnvironment = {
  citadelEnv: "stabledev",
  target: "local",
  isLocal: true,
};
const ERROR_MESSAGE = [
  "",
  "Environment being accessed but is not loaded",
  "In public/index.html, make sure you have included a script tag to load the environment config:",
  '<script type="text/javascript" src="/app/path/.well-known/environment.js"></script>',
  "",
  "If you are in a non-citadel environment:",
  "The environment config may not be supported, you should not be invoking this function.",
  "If a mistake in a library is causing this to be executed, as a workaround,",
  "add a custom script that defines the required values; eg.",
  '<script type="text/javascript">',
  `  window.${CDX_BROWSER_ENVIRONMENT_KEY} = ${JSON.stringify(EXAMPLE_ENVIRONMENT, null, 2)};`,
  "</script>",
  "",
].join("\n");

const isTestEnvironment = (): boolean => {
  return getProcessEnv()?.JEST_WORKER_ID !== undefined && getProcessEnv()?.NODE_ENV === "test";
};

export const getBrowserRawEnvironment = (): CdxFutureEnvironment | undefined => {
  try {
    // only usage of window so just ignoring here
    // @ts-ignore
    return ((window || {}) as any)[CDX_BROWSER_ENVIRONMENT_KEY];
  } catch (e) {
    return undefined;
  }
};

/**
 * See error message above - this is preloaded via the authentication/config.js script
 */
export const getBrowserEnvironment = (): CdxFutureEnvironment => {
  const env = getBrowserRawEnvironment();
  if (!env && !isTestEnvironment()) {
    throw new Error(ERROR_MESSAGE);
  }
  return env || EXAMPLE_ENVIRONMENT;
};
