import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import type { DivProps } from "./types";

export const Div = styled("div", {
  shouldForwardProp: isPropValid,
})<DivProps>(
  {},
  ({
    alignSelf,
    justifySelf,
    fill,
    fillHeight = fill,
    fillWidth = fill,
    flex,
    flexBasis,
    flexGrow,
    flexShrink,
    height = fillHeight ? "100%" : undefined,
    width = fillWidth ? "100%" : undefined,
    padding,
    margin,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
    overflowX,
    overflowY,
    overflow,
  }) => {
    return {
      alignSelf,
      justifySelf,
      flex,
      flexBasis,
      flexGrow,
      flexShrink,
      height,
      width,
      minHeight,
      minWidth,
      maxWidth,
      maxHeight,
      padding,
      margin,
      overflowX,
      overflowY,
      overflow,
    };
  }
);
