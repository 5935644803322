import { cloneDeep, isEqual, uniqWith } from "lodash";
import type {
  AssessmentStoreAction,
  AssessmentStoreItemUpdate,
  AssessmentStoreState,
} from "../../types/stores/assessment-store";
import { findItemInAssessment, getAssessmentLookup, moveSelection, translateFormula } from "../../utils/assessment";
import { calcEquation, parseCellReferences } from "../../utils/equations";
import type {
  Assessment,
  AssessmentGroupLookup,
  AssessmentItem,
  AssessmentItemCoords,
  CellSelectionEvent,
  CellMoveEvent,
} from "../../types/assessment";
import { assessmentActions } from "./assessment-actions";

const initialState: AssessmentStoreState = {
  assessment: undefined as unknown as Assessment,
  assessmentGroupLookup: undefined as unknown as AssessmentGroupLookup,
  selectedCell: undefined,
  editingCell: undefined,
  copyCell: undefined,
  cellReference: undefined,
  referencedCells: [],
  cellUpdateBatchActive: true,
  pasteableRange: [],
};

export const reducer = (
  state: AssessmentStoreState = initialState,
  action: AssessmentStoreAction
): AssessmentStoreState => {
  let itemUpdates: AssessmentStoreItemUpdate[];
  let findItem: ReturnType<typeof findItemInAssessment>;
  let newAssessment: Assessment;
  let item: AssessmentItem;
  let cell: AssessmentItemCoords | undefined;
  let copyCell: AssessmentItemCoords;
  let isPasteableCell: boolean;
  let prevState: AssessmentStoreState;
  let newState: AssessmentStoreState;
  let selectionEvent: CellSelectionEvent;
  let cells: AssessmentItemCoords[];

  switch (action.type) {
    case assessmentActions.SET_ASSESSMENT:
      newAssessment = action.payload as Assessment;
      if (isEqual(state.assessment, newAssessment)) {
        break;
      }
      // onAssessmentChange(newAssessment);
      return { ...state, assessment: newAssessment, assessmentGroupLookup: getAssessmentLookup(newAssessment) };

    case assessmentActions.SET_SELECTED_CELL:
      selectionEvent = action.payload as CellSelectionEvent;
      cell = selectionEvent.cell;

      item = findItemInAssessment(state.assessment)(cell);
      isPasteableCell = item?.editable ?? false;
      // get the new selected item into pasteableRange
      cells = isPasteableCell ? [cell] : [];
      // expand pasteableRange into the previous state's pasteableRange
      cells = selectionEvent.multiSelect ? uniqWith([...state.pasteableRange, ...cells], isEqual) : cells;

      newState = { ...state, selectedCell: cell, pasteableRange: cells };

      if (state.editingCell) {
        newState = reducer(newState, {
          type: assessmentActions.SET_CELL_REFERENCE,
          payload: cell,
        });
      }

      if (isEqual(state, newState)) {
        break;
      }
      return newState;

    case assessmentActions.MOVE_CELL_SELECTION:
      if (!state.selectedCell) {
        break;
      }

      cell = moveSelection(state.assessment, state.assessmentGroupLookup)(
        state.selectedCell,
        (action.payload as CellMoveEvent).direction
      );

      // if moving to a non-existent of unselectable cell, break
      if (!cell) {
        break;
      }

      newState = reducer(state, {
        type: assessmentActions.SET_SELECTED_CELL,
        payload: { cell, multiSelect: (action.payload as CellMoveEvent).multiSelect },
      });

      if (!(action.payload as CellMoveEvent).multiMove) {
        return newState;
      }

      prevState = state;
      while (!isEqual(prevState, newState)) {
        prevState = newState;
        newState = reducer(prevState, {
          type: assessmentActions.MOVE_CELL_SELECTION,
          // top level call is marked as multiMove, so sub ones should be marked as single moves to reduce redundent calls
          payload: { ...(action.payload as CellMoveEvent), multiMove: false },
        });
      }

      return newState;

    case assessmentActions.SET_EDITING_CELL:
      if (isEqual(state.editingCell, action.payload)) {
        break;
      }
      cell = action.payload as AssessmentItemCoords;

      // if cell is not provided, clear editing selection
      if (!cell) {
        newState = { ...state, editingCell: undefined };
        return reducer(newState, {
          type: assessmentActions.SET_REFERENCED_CELLS,
          payload: [],
        });
      }

      item = findItemInAssessment(state.assessment)(cell);
      // if cell is not editable, clear editing selection
      if (!item || !item.editable) {
        newState = { ...state, editingCell: undefined };
        return reducer(newState, {
          type: assessmentActions.SET_REFERENCED_CELLS,
          payload: [],
        });
      }

      newState = { ...state, editingCell: cell };

      // set the reference cells
      cells = parseCellReferences(state.assessment, state.assessmentGroupLookup)(item.formula ?? "");
      return reducer(newState, {
        type: assessmentActions.SET_REFERENCED_CELLS,
        payload: cells,
      });

    case assessmentActions.SET_COPY_CELL:
      return {
        ...state,
        copyCell: state.selectedCell,
      };

    case assessmentActions.PASTE_CELL:
      // check if there is a copy cell
      cells = state.pasteableRange;
      if (!state.copyCell || !cells?.length) {
        break;
      }

      // lookup the formula of the copy cell
      item = findItemInAssessment(state.assessment)(state.copyCell);

      copyCell = state.copyCell as AssessmentItemCoords;
      // TODO: CTOTECH-2046 Fix this the next time this file is edited.
      // eslint-disable-next-line @typescript-eslint/no-shadow
      itemUpdates = cells.map((cell) => {
        // map formula relative to new cell coords
        return {
          coords: cell,
          formula: translateFormula(item.formula ?? "", copyCell, cell, state.assessmentGroupLookup),
        };
      });

      newState = reducer(state, {
        type: assessmentActions.UPDATE_VALUES,
        payload: itemUpdates,
      });

      if (isEqual(state, newState)) {
        break;
      }
      return newState;

    case assessmentActions.CLEAR_CELLS:
      // check if there is a pasteableRange
      cells = state.pasteableRange;
      if (!cells?.length) {
        break;
      }

      // TODO: CTOTECH-2046 Fix this the next time this file is edited.
      // eslint-disable-next-line @typescript-eslint/no-shadow
      itemUpdates = cells.map((cell) => {
        // set formula of each cell to empty string
        return {
          coords: cell,
          formula: "",
        };
      });

      newState = reducer(state, {
        type: assessmentActions.UPDATE_VALUES,
        payload: itemUpdates,
      });

      if (isEqual(state, newState)) {
        break;
      }
      return newState;

    case assessmentActions.SET_CELL_REFERENCE:
      if (isEqual(state.cellReference, action.payload)) {
        break;
      }
      return { ...state, cellReference: action.payload as AssessmentItemCoords };

    case assessmentActions.SET_REFERENCED_CELLS:
      if (isEqual(state.referencedCells, action.payload)) {
        break;
      }
      return { ...state, referencedCells: action.payload as AssessmentItemCoords[] };

    case assessmentActions.UPDATE_VALUES:
      itemUpdates = action.payload as AssessmentStoreItemUpdate[];
      newAssessment = cloneDeep(state.assessment);
      findItem = findItemInAssessment(newAssessment);

      // go through the updates and set the formulas
      itemUpdates.forEach((update) => {
        item = findItem(update.coords);

        if (isEqual(item.formula, update.formula)) {
          return;
        }
        item.formula = update.formula ?? "";
      });

      // now that all the formulas are set,
      // go through and calcEquation to update any cells that may be referenced
      itemUpdates.forEach((update) => {
        item = findItem(update.coords);

        try {
          // since the new assessment is a copy of the old with a new value, we can use the previous groupLookup
          calcEquation(update.coords, newAssessment, state.assessmentGroupLookup);
        } catch {}
      });

      return { ...state, assessment: newAssessment };

    case assessmentActions.SET_UPDATE_BATCH_ACTIVE:
      if (state.cellUpdateBatchActive === action.payload) {
        break;
      }
      return { ...state, cellUpdateBatchActive: action.payload as boolean };
    default:
      break;
  }

  return state;
};
