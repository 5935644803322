import type { ReactElement } from "react";
import { css, keyframes } from "@emotion/css";
import styled from "@emotion/styled";
import { useIsDarkMode } from "@citadel/cdx-lib-react";

const margin: number = 3;
const perspective: number = 800;

const rotate = keyframes`
  100% {
    transform: rotateY(-360deg);
  }
`;

type DepthProp = { depth: number };
const depthProp = ({ depth }: DepthProp) => depth;
const negativeDepthProp = ({ depth }: DepthProp) => -depth;
const FrontFace = styled.div`
  transform: translate3d(0, 0, ${depthProp}px);
`;
const BackFace = styled.div`
  transform: translate3d(0, 0, ${negativeDepthProp}px);
`;
const LeftFace = styled.div`
  transform: rotateY(-90deg) translate3d(0, 0, ${depthProp}px);
  filter: brightness(50%);
`;
const RightFace = styled.div`
  transform: rotateY(90deg) translate3d(0, 0, ${depthProp}px);
  filter: brightness(50%);
`;
const TopFace = styled.div`
  transform: rotateX(90deg) translate3d(0, 0, ${depthProp}px);
`;
const BottomFace = styled.div`
  transform: rotateX(-90deg) translate3d(0, 0, ${depthProp}px);
`;

type BlockProps = {
  translateX: number;
  translateY: number;
  scaleX: number;
  size: number;
};

const Block = ({ translateX, translateY, scaleX, size }: BlockProps): ReactElement => {
  const isDarkMode = useIsDarkMode();

  const depth = size / 2;
  const scaleY = 0.3;
  const scaleZ = 0.3;

  return (
    <div
      className={css`
        transform-style: preserve-3d;
        transform: translate3d(${translateX}px, ${translateY}px, 0) scale3d(${scaleX}, ${scaleY}, ${scaleZ});
        > div {
          position: absolute;
          width: ${size}px;
          height: ${size}px;
          text-align: center;
          line-height: 0;
          font-size: ${size}px;
          opacity: 0.8;
          background-color: ${isDarkMode ? "white" : "#002f6c"};
        }
      `}
    >
      <FrontFace depth={depth} />
      <BackFace depth={depth} />
      <LeftFace depth={depth} />
      <RightFace depth={depth} />
      <TopFace depth={depth} />
      <BottomFace depth={depth} />
    </div>
  );
};

const OuterDiv = styled.div`
  perspective: ${perspective}px;
  perspective-origin: 0 0;
  transform-style: preserve-3d;
  overflow: inherit;
`;
const Blocks = styled.div(
  ({ size }: { size: number }) => `
    width: ${size * 2}px;
    height: ${size}px;
    transform-style: preserve-3d;
    transform-origin: ${size}px ${size / 2}px 0;
    margin: 0 ${margin}px;
    animation: ${rotate} 8s ease-in-out infinite;`
);

interface CitadelSpinnerProps {
  className?: string;
  size?: number;
}
export const CitadelSpinner = ({ className, size = 32 }: CitadelSpinnerProps): ReactElement => {
  return (
    <OuterDiv className={className}>
      <Blocks size={size}>
        {/* Bottom block */}
        <Block scaleX={2} translateX={size} translateY={(size * 2) / 3} size={size} />

        {/* Middle 2 blocks */}
        <Block scaleX={0.95} translateX={size * -0.05} translateY={size / 3} size={size} />
        <Block scaleX={0.95} translateX={size} translateY={size / 3} size={size} />

        {/* Top 3 blocks: */}
        <Block scaleX={0.5} translateX={size * -0.5} translateY={0} size={size} />
        <Block scaleX={0.5} translateX={(size * 0.5) / 2} translateY={0} size={size} />
        <Block scaleX={0.5} translateX={size} translateY={0} size={size} />
      </Blocks>
    </OuterDiv>
  );
};
CitadelSpinner.displayName = "CitadelSpinner";
