import { useState } from "react";
import { Drawer, Button } from "@blueprintjs/core";
import type { InfoSectionData } from "../info-section";
import { InfoSection, InfoSubsection } from "../info-section";
import helpSections from "./help-sections.json";

export type HelpButtonProps = {
  iconOnly?: boolean;
};

export const HelpButton = ({ iconOnly }: HelpButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Button text={!iconOnly && "Help"} icon="help" outlined onClick={handleOpen} />
      <Drawer icon="help" onClose={handleClose} title="Help" isOpen={isOpen} size={400}>
        {(helpSections as InfoSectionData[]).map(({ header, sections }) => (
          <InfoSection key={header} header={header}>
            {sections.map(({ subheader, text }) => (
              <InfoSubsection key={subheader} subheader={subheader}>
                {text}
              </InfoSubsection>
            ))}
          </InfoSection>
        ))}
      </Drawer>
    </>
  );
};
