import type { Context } from "react";
import { useContext } from "react";
import type { BehaviorSubject } from "rxjs";
import type { Change } from "@citadel/cdx-lib-react";
import { useStoredValue } from "./useStoredValue";
import { useStateWithDefault } from "./useStateWithDefault";

const RESERVED_KEY_PREFIX = "@";

export interface StoredValueOptions<T> {
  defaultValue?: T;
}

export interface ContextStoredValueHook {
  <T>(key: string): [T | undefined, (change: Change<T | undefined>) => void];
  <T>(
    key: string,
    options: StoredValueOptions<T> & {
      defaultValue: T;
    }
  ): [T, (change: Change<T>) => void];
  <T>(key: string, options: StoredValueOptions<T>): [T | undefined, (change: Change<T | undefined>) => void];
}

export const createStoredValueHook =
  (context: Context<BehaviorSubject<Record<string, any>>>, typeLabel = "store"): ContextStoredValueHook =>
  <T>(key: string, { defaultValue }: StoredValueOptions<T> = {}) => {
    if (key.startsWith(RESERVED_KEY_PREFIX)) {
      throw new Error(
        `Invalid ${typeLabel} key (${key}): keys starting with ${RESERVED_KEY_PREFIX} are reserved for use by the platform`
      );
    }

    const store$ = useContext(context);
    return useStateWithDefault(useStoredValue(store$, key), defaultValue);
  };
