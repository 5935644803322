export function getShallow<T, K extends keyof T>(value: T, key: K): T[K];
export function getShallow<T, K extends string | number>(value: T, key: K): undefined;
export function getShallow(value: any, key: string | number): any | undefined {
  if (!value) return undefined;
  if (typeof value !== "object") return undefined;

  switch (typeof key) {
    case "number":
      return Array.isArray(value) ? value[key] : undefined;
    case "string":
      return !Array.isArray(value) ? value[key] : undefined;

    default:
      return undefined;
  }
}
