import type { ApolloClient } from "@apollo/client";
import { ApolloLink } from "@apollo/client";
import { useEffect, useState } from "react";
import type { AuthenticationSession } from "@citadel/cdx-auth-browser";
import { AuthenticationSessionLink } from "../links/authenticationSessionLink";

/**
 * Props for {@link AttachAuthenticationSessionLink}
 */
export interface AttachAuthenticationSessionLinkProps {
  /**
   * The {@link ApolloClient} to attach to
   */
  apolloClient: ApolloClient<any>;

  /**
   * An authentication session to attach to the specified apollo client
   */
  authenticationSession: AuthenticationSession;
}

/**
 * Use this to attaches an {@link AttachAuthenticationSessionLink} to the apollo client. Automatically detaches the link
 * when unmounted.
 *
 * @param props - {@link AttachAuthenticationSessionLinkProps}
 */
export function AttachAuthenticationSessionLink({
  apolloClient,
  authenticationSession,
}: AttachAuthenticationSessionLinkProps): null {
  const [sessionLink] = useState(() => new AuthenticationSessionLink(authenticationSession));

  // Attach the authorization link to the apollo client, detach if this component is no longer in the render tree
  useEffect(() => {
    const link = apolloClient.link;
    apolloClient.setLink(ApolloLink.concat(sessionLink, link));

    return () => {
      apolloClient.setLink(link);
    };
  }, [sessionLink, apolloClient]);

  // Update the session on the link incase it ever changes
  useEffect(() => {
    sessionLink.setSession(authenticationSession);
  }, [sessionLink, authenticationSession]);

  // This is not really a component, more of an optional hook
  return null;
}
