import type { FocusEventHandler, KeyboardEventHandler } from "react";
import { useState, useCallback, Fragment, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { Button, ButtonGroup } from "@blueprintjs/core";
import { EditingState } from "../types/Media";
import type { Annotation, onEditUpdate, onFillUpdate, onRemoveUpdate, onTextUpdate } from "../types/Media";
import { ANNOTATION_BORDER_SIZE } from "./annotations";
import { EditRegionTextarea } from "./EditRegionTextarea";

const Z_INDEX_FOR_DURABLE_EDIT_REGION = 100;

export interface MediaRegionProps {
  disabled: boolean;
  annotation: Annotation;
  editing: EditingState;
  index?: number;
  onTextUpdate?: onTextUpdate;
  onEditUpdate?: onEditUpdate;
  onFillUpdate?: onFillUpdate;
  onRemoveUpdate?: onRemoveUpdate;
}

const Region = styled.div(({ annotation, editing }: Pick<MediaRegionProps, "annotation" | "editing">) => ({
  background: annotation.fill ? "#000" : "transparent",
  border: `${ANNOTATION_BORDER_SIZE}px solid ${annotation.color}`,
  position: "absolute",
  top: annotation.top + "px",
  left: annotation.left + "px",
  width: annotation.width + "px",
  height: annotation.height + "px",
  zIndex: editing === EditingState.DURABLE ? Z_INDEX_FOR_DURABLE_EDIT_REGION : "auto",
}));

const StyledButtonGroup = styled(ButtonGroup)`
  position: absolute;
  bottom: -60px;
  width: 100%;
  padding: 15px 0;
  display: flex;
  justify-content: center;
`;

const StyledCounter = styled.div(({ annotation }: Pick<MediaRegionProps, "annotation">) => ({
  position: "absolute",
  backgroundColor: annotation.color,
  color: "#fff",
  padding: "0.33em 0.5em",
  display: "flex",
}));

const StyledAnnotationPreview = styled.span`
  :before {
    content: "- ";
  }
  padding-left: 0.25em;
  display: block;
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export function EditRegion({
  disabled,
  annotation,
  editing,
  onTextUpdate,
  onEditUpdate,
  onFillUpdate,
  onRemoveUpdate,
  index,
}: MediaRegionProps) {
  const [text, setText] = useState<string>(annotation.text || "");
  const [showComments, setShowComments] = useState<boolean>(false);
  const regionElement = useRef<HTMLDivElement>(null);
  const mouseEnterRegionHandler = () => {
    if (editing === EditingState.NONE) {
      onEditUpdate?.(EditingState.VISIBLE, index);
    }
  };
  const mouseLeaveRegionHandler = () => {
    if (editing === EditingState.VISIBLE) {
      onEditUpdate?.(EditingState.NONE, index);
    }
  };
  const clickRegionHandler = useCallback(() => onEditUpdate?.(EditingState.DURABLE, index), [index, onEditUpdate]);
  const clickFillHandler = useCallback(
    () => onFillUpdate?.(!annotation.fill, index),
    [annotation.fill, index, onFillUpdate]
  );
  const clickDeleteHandler = useCallback(() => onRemoveUpdate?.(true, index), [index, onRemoveUpdate]);
  const clickCommentHandler = () => {
    setShowComments(true);
  };
  const blurRegionHandler: FocusEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (regionElement.current?.contains(event.relatedTarget)) {
        return;
      }

      if (editing !== EditingState.NONE) {
        onEditUpdate?.(EditingState.NONE, index);
      }
      if (showComments && editing !== EditingState.DURABLE) {
        setShowComments(false);
      }
    },
    [editing, index, onEditUpdate, showComments]
  );
  const keyDownRegionHandler: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === "Escape") {
      if (showComments) {
        event.preventDefault();
        event.stopPropagation();
        setShowComments(false);
        regionElement.current?.focus();
      } else if (editing === EditingState.DURABLE) {
        event.preventDefault();
        event.stopPropagation();
        onEditUpdate?.(EditingState.NONE, index);
      }
    }

    if (event.key === "Backspace" && editing !== EditingState.NONE && !showComments) {
      onRemoveUpdate?.(true, index);
    }
  };

  useEffect(() => {
    if (editing !== EditingState.DURABLE) {
      setShowComments(false);
    }
  }, [editing]);

  return (
    <Region
      annotation={annotation}
      editing={editing}
      ref={regionElement}
      onClick={!disabled ? clickRegionHandler : undefined}
      onMouseEnter={!disabled ? mouseEnterRegionHandler : undefined}
      onMouseLeave={!disabled ? mouseLeaveRegionHandler : undefined}
      onBlur={!disabled ? blurRegionHandler : undefined}
      // Without an explicit tabIndex, it's not possible to focus this element.
      tabIndex={0}
      onKeyDown={!disabled ? keyDownRegionHandler : undefined}
      data-testid={`screenshot-edit-region-${index}`}
    >
      {index !== undefined ? (
        <StyledCounter annotation={annotation}>
          {index + 1}
          {annotation.text ? <StyledAnnotationPreview>{annotation.text}</StyledAnnotationPreview> : null}
        </StyledCounter>
      ) : null}
      <Fragment>
        {!showComments ? (
          <StyledButtonGroup style={{ visibility: editing ? "visible" : "hidden" }}>
            <Button
              icon={annotation.fill ? "eye-open" : "eye-off"}
              text={annotation.fill ? "Highlight" : "Redact"}
              onClick={clickFillHandler}
            />
            <Button icon="comment" onClick={clickCommentHandler} />
            <Button icon="delete" onClick={clickDeleteHandler} data-testid="screenshot-edit-region-delete" />
          </StyledButtonGroup>
        ) : (
          <EditRegionTextarea
            index={index}
            text={text}
            width={annotation.width}
            setText={setText}
            onTextUpdate={onTextUpdate}
          />
        )}
      </Fragment>
    </Region>
  );
}
