import { nanoid } from "nanoid";
import { getCurrentLeader, setCurrentLeader } from "./localStorage";
import { sleep } from "./util";

// Leader Flow Explanation
//
// Each individual window needs to verify it has the correct token
// the problem is that we either need each individual window to scope
// its own sso flow (performing auth many many times) or we need to dedupe
// auth across windows. We need the latter currently to not magnify
// the appearance of an issue in PingFederate which prevents a single user
// from performing concurrent SSO sessions
//
// Enter the leader flow
//
// When we hit the last case of auth checks (invalid tokens, no current request state, need to do full redirect)
// we check if another window is already performing the SSO redirect
// if it is, we just wait
// if none are, we try to "become the leader" and perform the redirect
// because localStorage does not have a lock, we have a basic mechanism for simulating
// a lock so that only one window becomes the leader and performs SSO
// this is important both for deduping and to prevent overwriting SSO request state
//
// When the leader redirects back, they will populate a valid token for the session
// other windows will have been waiting for the leader to perform SSO
// on completion, the leader clears itself as the leader and then other windows will
// exit their wait flow and retry token validation
//

// How long we should wait for the leader to perform SSO
// before we elect a new leader
// To get the duration for this, multiple the count against WAIT_BETWEEN_CHECKS_DURATION
const MAX_WAIT_COUNT = 15;

// How long we wait to check if we now have a valid token
const WAIT_BETWEEN_CHECKS_DURATION = 1_000;

// How long we wait after attempting to become the leader to
// verify we were actually "last" (because there is no locking, another
// tab could have raced us to the write and we'd end up both being the leader)
const WAIT_AFTER_ATTEMPT_TO_CLAIM_LEADER = 100;

async function waitForLeaderToFinish() {
  for (let i = MAX_WAIT_COUNT; i > 0; i++) {
    await sleep(WAIT_BETWEEN_CHECKS_DURATION);
    if (getCurrentLeader() === null) {
      return;
    }
  }
}

export async function shouldBecomeLeaderForRefresh(): Promise<boolean> {
  const currentLeader = getCurrentLeader();
  if (currentLeader) {
    await waitForLeaderToFinish();
    return false;
  }

  const id = nanoid();
  setCurrentLeader(id);
  // wait to mitigate against another window overwriting us as leader due to no locking support
  // what this means is our only risk of two windows thinking they are both the leader is the code
  // execution between `getCurrentLeader` and `setCurrentLeader` takes > WAIT_AFTER_ATTEMPT_TO_CLAIM_LEADER
  // even in this scenario, we recover fine except that PingFederate currently has a single
  // sso flow/user limitation
  // in the future this will be solved and so we just are a bit less optimal in this case, but have
  // no real risk of failed sso flows
  await sleep(WAIT_AFTER_ATTEMPT_TO_CLAIM_LEADER);

  const leaderAfterAttemptingClaim = getCurrentLeader();
  if (leaderAfterAttemptingClaim?.leaderId !== id) {
    await waitForLeaderToFinish();
    return false;
  }

  return true;
}
