import { Switch, Button, Tabs, Tab, Colors } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { Fragment, useCallback } from "react";
import type * as React from "react";
import type {
  onRequestScreenshot,
  onRegionRemove,
  onIndexChange,
  onMediaUsableChange,
  onMediaRemove,
  onRegionsChange,
  onEditorChange,
} from "../types/Media";
import type { Report } from "../types/Report";
import { Preview } from "./Preview";

const HeaderWrapper = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: space-between;
`;

const StyledSwitch = styled(Switch)`
  margin-bottom: 0;
`;

const StyledTabs = styled(Tabs)(({ capturable }: { capturable: boolean }) => ({
  flexGrow: 2,
  maxWidth: capturable ? "calc(100% - 150px)" : "100%",
  overflow: "scroll",
  marginBottom: "-3px",

  "&::-webkit-scrollbar": {
    display: "none",
  },

  ".bp3-tab": {
    display: "flex",
    alignItems: "center",
  },

  ".bp3-tab > span.tab-text": {
    display: "block",
    maxWidth: "150px",
    direction: "rtl",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  ".bp3-tab > .bp3-icon": {
    color: Colors.LIGHT_GRAY3,
  },
}));

const StyledSmallButtonWrapper = styled.div(({ dropping }: Pick<MediaElementProps, "dropping">) => ({
  opacity: dropping ? 0 : undefined,
  transition: "opacity 200ms ease-out",
}));

const StyledSmallButton = styled(Button)`
  margin-left: 3px;
  & > .bp3-button-text {
    font-size: 12px !important;
  }
`;

const StyledParagraph = styled.p(({ dropping }: Pick<MediaElementProps, "dropping">) => ({
  flexGrow: "2",
  display: "block",
  fontWeight: "normal",
  fontSize: "12px",
  lineHeight: "24px",
  marginBottom: "0",
  opacity: dropping ? 0.2 : undefined,
  transition: "opacity 200ms ease-out",
}));

interface MediaElementProps {
  dropping: boolean;
  report: Report;
  index: number;
  onMediaUsableChange: onMediaUsableChange;
  onMediaRemove: onMediaRemove;
  onRequestScreenshot: onRequestScreenshot;
  onRegionRemove: onRegionRemove;
  onRegionsChange: onRegionsChange;
  onIndexChange: onIndexChange;
  onEditorChange: onEditorChange;
}

export const AllMedia = ({
  dropping,
  report,
  index,
  onMediaUsableChange,
  onMediaRemove,
  onRequestScreenshot,
  onRegionRemove,
  onRegionsChange,
  onIndexChange,
  onEditorChange,
}: MediaElementProps) => {
  const handleMediaUsableChange: React.FormEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      onMediaUsableChange(report.id, (event.target as HTMLInputElement).checked);
    },
    [onMediaUsableChange, report.id]
  );

  const mediaCount = (report.media || []).length;
  const anyUsableMedia = report.media?.some((media) => media.usable) || false;
  const selectedMedia = (report.media || [])[index];
  const includeMediaChecked = report.media === null || anyUsableMedia;
  return (
    <Fragment>
      <StyledSwitch
        checked={includeMediaChecked}
        label="Include Media"
        onChange={handleMediaUsableChange}
        disabled={report.completed}
      />
      {includeMediaChecked
        ? [
            <HeaderWrapper key="header-wrapper-key">
              {mediaCount > 1 ? (
                <StyledTabs
                  selectedTabId={index}
                  capturable={report.media?.[index].captureable || false}
                  onChange={(selected) => onIndexChange(selected)}
                >
                  {(report.media || []).map((media, i) => {
                    const key = media.name || `Image ${i + 1}`;
                    return (
                      <Tab id={i} key={key}>
                        <span className="tab-text">{key}</span>
                        {!media.captureable ? (
                          <Button
                            icon="small-cross"
                            minimal={true}
                            small={true}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onMediaRemove(report.id, i);
                            }}
                          />
                        ) : null}
                      </Tab>
                    );
                  })}
                </StyledTabs>
              ) : (
                <StyledParagraph className="bp3-text-muted" dropping={dropping}>
                  Click and drag to highlight or redact areas of the media.
                </StyledParagraph>
              )}
              {report.media === null || (selectedMedia && selectedMedia.captureable) ? (
                <StyledSmallButtonWrapper dropping={dropping}>
                  <StyledSmallButton
                    text="Retake Screenshot"
                    small
                    disabled={!selectedMedia?.usable || report.completed}
                    icon="camera"
                    onClick={() => onRequestScreenshot(report.id)}
                  />
                </StyledSmallButtonWrapper>
              ) : null}
            </HeaderWrapper>,
            <Preview
              key="preview-key"
              dropping={dropping}
              report={report}
              mediaIndex={index}
              onRegionsChange={onRegionsChange}
              onRegionRemove={onRegionRemove}
              onEditorChange={onEditorChange}
            />,
          ]
        : null}
    </Fragment>
  );
};
