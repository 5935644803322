import { logger } from "../logger";

const pkceReservedKeywordList = [
  "client_id",
  "response_type",
  "redirect_uri",
  "state",
  "nonce",
  "scope",
  "code",
  "code_challenge",
  "code_challenge_method",
] as const;

const FRAGMENT_KEY = "authFlowRedirectOriginalFragment";

export function cleanUpSearchParams(
  urlSearchParams: URLSearchParams,
  keyListToRemove = [...pkceReservedKeywordList, FRAGMENT_KEY]
): string {
  const cleanSearchParams = new URLSearchParams(urlSearchParams);

  (keyListToRemove || []).forEach((key) => cleanSearchParams.delete(key));

  return cleanSearchParams.toString();
}

function appendWith(delimiter: string) {
  return (base: string, extension: string) => {
    let concatenated = base || "";

    if (extension) {
      concatenated += extension.startsWith(delimiter) ? extension : `${delimiter}${extension}`;
    }

    return concatenated;
  };
}

const appendWithPathname = appendWith("/");
const appendWithSearch = appendWith("?");
const appendWithHash = appendWith("#");

export function constructUrl({
  origin = window.location.origin,
  pathname = window.location.pathname,
  search = window.location.search,
  hash = window.location.hash,
} = {}): string {
  return appendWithHash(appendWithSearch(appendWithPathname(origin, pathname), search), hash);
}

export function constructRedirectUrl({
  origin = window.location.origin,
  pathname = window.location.pathname,
  search = window.location.search,
  hash = window.location.hash,
} = {}): string {
  const searchParams = new URLSearchParams(search);

  if (hash) {
    searchParams.append(FRAGMENT_KEY, hash);
  }

  return constructUrl({ origin, pathname, search: searchParams.toString(), hash: "" });
}

export function replaceHistoryEntry(url: string): void {
  window.location.replace(url);
}

export function replaceHistoryState(state: unknown, path: string): void {
  window.history.replaceState(state, document.title, path);
}

export function pluckHash(urlParams: URLSearchParams): string {
  return urlParams.get(FRAGMENT_KEY) || "";
}

export function getLatestAuthStateFromUrl(searchParams: URLSearchParams): { state: string; code: string } | undefined {
  if (!searchParams.has("state") || !searchParams.has("code")) return;

  const allStateParams = searchParams.getAll("state");
  const allCodeParams = searchParams.getAll("code");

  if (allStateParams.length > 1) {
    logger.warn("Multiple `state` detected, attempt the latest");
  }
  if (allCodeParams.length > 1) {
    logger.warn("Multiple `code` detected, attempt the latest");
  }

  return { state: allStateParams[allStateParams.length - 1], code: allCodeParams[allCodeParams.length - 1] };
}
