import type { ReactElement, ReactNode } from "react";
import { Pre, NonIdealState } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { getErrorDescriptor } from "../../utils";

export interface ErrorDetailsProps {
  title?: ReactNode;
  error: any;
  children?: ReactNode;
}

export const ErrorDetails = ({ title, error, children }: ErrorDetailsProps): ReactElement<any, any> | null => {
  const { title: automaticTitle, description, text } = getErrorDescriptor(error);

  return (
    <NonIdealState title={title ?? automaticTitle} description={description} icon="error">
      {text ? (
        <ErrorBoundaryDetails data-testid="error-details" className="error-boundary-details">
          <Pre>{text}</Pre>
        </ErrorBoundaryDetails>
      ) : null}
      {children}
    </NonIdealState>
  );
};

const ErrorBoundaryDetails = styled.details`
  max-width: 100%;
  text-align: left;
  pre {
    overflow: auto;
    max-height: 50vh;
    text-align: start;
  }
`;
