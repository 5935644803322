import { useEffect, useState } from "react";
import { Icon, Intent } from "@blueprintjs/core";
import classnames from "classnames";
import { isEqual, isFinite, last, some } from "lodash";
import { useSelector } from "react-redux";
import { Tooltip2 } from "@blueprintjs/popover2";
import {
  getAssessmentGroupLookup,
  getColumns,
  getEditingCell,
  getReferencedCells,
  getSelectedCell,
  getPasteableRange,
} from "../../stores/assessment/assessment-selectors";
import type {
  AdminAssessmentGroup,
  AdminAssessmentItem,
  AssessmentGroup,
  AssessmentItem,
  AssessmentItemCoords,
  AssessmentItemFormatter,
} from "../../types/assessment";
import classes from "./bd-grid.less";
import { BDGridItem } from "./bd-grid-item";
import { BDGridRowColLabel } from "./bd-grid-row-col-label";

type SparseItemArray = (AdminAssessmentItem | AssessmentItem | undefined)[];

export type BDGridLineProps = {
  group: AssessmentGroup | AdminAssessmentGroup;
  coords: AssessmentItemCoords;
  depth?: number;
};

export const BDGridLine = ({
  group: { label, entries, comment, labelStyle = "D", formatter = "numeric", precision = 0 },
  coords,
  depth = 0,
}: BDGridLineProps) => {
  const [fullItems, setFullItems] = useState([] as SparseItemArray);

  const columns = useSelector(getColumns);
  const assessmentGroupLookup = useSelector(getAssessmentGroupLookup);
  const selectedCell = useSelector(getSelectedCell);
  const editingCell = useSelector(getEditingCell);
  const referencedCells = useSelector(getReferencedCells);
  const pasteableRange = useSelector(getPasteableRange);

  useEffect(() => {
    setFullItems(entries?.length ? columns.map((column, idx) => entries.find((item) => item.column === idx)) : []);
  }, [columns, entries]);

  const isEmpty = !fullItems?.length;

  const lineNumber = assessmentGroupLookup.toLineNumber[coords.join(":")];

  const linePrecision = precision ?? 0;

  return (
    <tr
      className={classnames({ [classes.groupHeader]: isEmpty })}
      data-depth={depth}
      data-child-index={last(coords)}
      data-formatter={formatter}
      data-precision={linePrecision}
      data-line-style={labelStyle}
    >
      <td className={classes.labelCell} {...(isEmpty && { colSpan: columns.length + 1 })}>
        <div className={classes.innerCell}>
          <div className={classes.rowColLabel}>
            {isFinite(lineNumber) && <BDGridRowColLabel>{lineNumber}</BDGridRowColLabel>}
          </div>
          <div className={classes.rowColText}>
            {label}
            {comment && (
              <Tooltip2 className={classes.rowLabelIcon} content={comment}>
                <Icon className={classes.rowLabelIcon} icon="info-sign" iconSize={12} intent={Intent.PRIMARY} />
              </Tooltip2>
            )}
          </div>
        </div>
      </td>
      {fullItems.map((item, idx) => {
        if (!item) {
          return <td key={idx} />;
        }
        const itemCoords = [...coords, item.column];
        const selected = isEqual(selectedCell, itemCoords);
        const editing = isEqual(editingCell, itemCoords);
        // item is focused if it is the editing cell, or it is selected and there is no editing cell
        const focused = editing || (selected && !editingCell);
        const referencedIndex = referencedCells?.findIndex((referencedCoords) => isEqual(referencedCoords, itemCoords));
        // TODO: CTOTECH-2046 Fix this the next time this file is edited.
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const pasteable = some(pasteableRange, (coords) => {
          return isEqual(coords, itemCoords);
        });
        return (
          <BDGridItem
            key={item.id}
            item={item}
            formatter={formatter as AssessmentItemFormatter}
            precision={linePrecision}
            coords={[...coords, item.column]}
            selected={selected}
            editing={editing}
            focused={focused}
            referencedIndex={referencedIndex}
            pasteable={pasteable}
            solutions={(item as AdminAssessmentItem)?.solutions}
          />
        );
      })}
    </tr>
  );
};
