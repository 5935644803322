import { useEffect, useMemo } from "react";
import { HotkeysProvider, HTMLTable } from "@blueprintjs/core";
import classnames from "classnames";
import { noop } from "lodash";
import { Provider, useSelector } from "react-redux";
import { addError } from "@citadel/cdx-provider-user-monitoring";
import { useUpdateAssessmentEntryMutation } from "../../_generated/graphql";
import { useAssessmentWithScratch } from "../../hooks/use-assessment-with-scratch";
import { useGridHotkeys } from "../../hooks/use-grid-hotkeys";
import { getAssessment, getEditingCell } from "../../stores/assessment/assessment-selectors";
import { buildAssessmentStore } from "../../stores/assessment/assessment-store";
import type { Assessment, AssessmentItemFormatter } from "../../types/assessment";
import { toAlpha } from "../../utils/assessment";
import classes from "./bd-grid.less";
import { BDGridGroup } from "./bd-grid-group";
import { BDGridRowColLabel } from "./bd-grid-row-col-label";

type ContextedBDGridProps = {
  onChange?: (assessment: Assessment) => void;
};

const ROW_HEADER_SPAN = 4;

const ContextedBDGrid = ({ onChange = noop }: ContextedBDGridProps) => {
  const assessment = useSelector(getAssessment);
  const editingCell = useSelector(getEditingCell);
  // all of the grid level hotkeys have been extracted into useGridHotkeys hooks
  useGridHotkeys();

  const { columns, groups } = assessment || { columns: [], groups: [] };

  const colWidthPct = 100 / (columns.length + ROW_HEADER_SPAN);

  useEffect(() => {
    onChange(assessment);
  }, [assessment, onChange]);

  return (
    <HTMLTable className={classnames({ [classes.table]: true, [classes.editing]: !!editingCell })}>
      <colgroup>
        <col style={{ width: `${colWidthPct * ROW_HEADER_SPAN}%` }} />
        {columns.map((column) => (
          <col key={column} style={{ width: `${colWidthPct}%` }} />
        ))}
      </colgroup>

      <thead>
        <tr>
          <th className={classes.shadedCell} />
          {columns.map((column, idx) => (
            <th key={column} className={classes.shadedCell}>
              <div className={classes.innerCell}>
                <div className={classes.rowColLabel}>
                  <BDGridRowColLabel>{toAlpha(idx)}</BDGridRowColLabel>
                </div>
              </div>
            </th>
          ))}
        </tr>
        <tr>
          <th />
          {columns.map((column) => (
            <th key={column}>
              <div className={classes.innerCell}>
                <div className={classes.rowColText}>{column}</div>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {groups.map((group, idx) => (
          <BDGridGroup key={group.id} group={group} coords={[idx]} />
        ))}
      </tbody>
    </HTMLTable>
  );
};

export type BDGridProps = {
  assessment: Assessment;
  disableUpdates?: boolean;
  scratchLines?: AssessmentItemFormatter[];
  onChange?: (assessment: Assessment) => void;
};

export const BDGrid = ({ assessment, disableUpdates, scratchLines = [], onChange = noop }: BDGridProps) => {
  const [updateEntry, { error }] = useUpdateAssessmentEntryMutation();

  const updateFn = disableUpdates ? noop : updateEntry;

  const assessmentWithScratch = useAssessmentWithScratch(assessment, scratchLines);

  const store = useMemo(() => buildAssessmentStore(assessmentWithScratch, updateFn), [assessmentWithScratch, updateFn]);

  useEffect(() => addError("BD Grid error", { message: error?.message }), [error]);

  return (
    <>
      {store && (
        <Provider store={store}>
          <HotkeysProvider>
            <ContextedBDGrid onChange={onChange} />
          </HotkeysProvider>
        </Provider>
      )}
    </>
  );
};
