import type { GlobalStoreAction, GlobalStoreState } from "../../types/stores/global-store";
import { globalActions } from "./global-store";

export const middleware =
  (setIsDisclaimerAcknowledged: (value: boolean) => void) =>
  (state: GlobalStoreState, dispatch: React.Dispatch<GlobalStoreAction>) =>
  async (action: GlobalStoreAction) => {
    switch (action.type) {
      case globalActions.SET_IS_DISCLAIMER_ACKNOWLEDGED:
        setIsDisclaimerAcknowledged(action.payload as boolean);
        break;

      default:
        break;
    }

    return dispatch(action);
  };
