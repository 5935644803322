import { SaveIndicator, SaveState } from "@citadel/pse-lib-ui";
import { useGlobal } from "../../stores/global/global-store";
import classes from "./global-save-indicator.less";

export const GlobalSaveIndicator = () => {
  const {
    state: { isSaving, saveError },
  } = useGlobal();

  return (
    <div className={classes.saveIndicatorWrapper}>
      <SaveIndicator
        placement="bottom-end"
        saveState={saveError ? SaveState.ERROR : isSaving ? SaveState.SAVING : SaveState.SAVED}
      />
    </div>
  );
};
