import { useEffect } from "react";
import { useLocalStorage } from "./use-local-storage";
import { useQueryParam } from "./use-query-param";

export const useSystemConfigs = (configKeys: string[] = [], configDefaults: unknown[] = []) => {
  const [systemConfigs, setSystemConfigs] = useLocalStorage("systemConfigs", {});
  const [configs] = useQueryParam("configs", "{}");

  useEffect(() => {
    let urlSystemConfigs;
    try {
      urlSystemConfigs = JSON.parse(configs);
    } catch {
      urlSystemConfigs = {};
    }
    setSystemConfigs({ ...systemConfigs, ...urlSystemConfigs });
    // disabling the eslint rule as we only want to update the local storage configs if url params change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs]);

  return configKeys.map((key, idx) => {
    const config = systemConfigs[key];
    const cDefault = configDefaults.length > idx ? configDefaults[idx] : undefined;
    return config === undefined ? cDefault : config;
  });
};
