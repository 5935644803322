import type { PropsWithChildren } from "react";
import { useMemo, useEffect, useState } from "react";
import { Classes, Colors as BlueprintColors } from "@blueprintjs/core";
import styled from "@emotion/styled";
import classNames from "classnames";
import { AvatarConnected, CitadelLogo } from "@citadel/cdx-ui";
import { getUsername } from "@citadel/cdx-auth-browser";
import type { Environment } from "@citadel/cdx-environment";
import { getEnvironment } from "@citadel/cdx-environment";
import { Colors, FontCSS } from "../../constants/styled";

const EnvironmentTargetToBackgroundColorMap: { [key in Environment["target"]]: string } = {
  nrc: BlueprintColors.ORANGE3,
  staging: BlueprintColors.ORANGE3,
  "staging-nrc": BlueprintColors.ORANGE3,
  stabledev: BlueprintColors.GREEN3,
  "stabledev-nrc": BlueprintColors.GREEN3,
  local: BlueprintColors.BLUE5,
};

const HeaderBarWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const HeaderBarBody = styled.div([
  {
    alignItems: "center",
    color: Colors.CITADEL_BRAND_WHITE,
    display: "flex",
    gap: "8px",
    flexBasis: 0,
    flexGrow: 1,
    justifyContent: "space-between",
    padding: "0 16px",
    width: "100%",
  },
  ({ target = "prod" }: { target?: Environment["target"] }) => ({
    backgroundColor: EnvironmentTargetToBackgroundColorMap[target] ?? Colors.CITADEL_BRAND_ROYAL,
  }),
]);

const Logo = styled.div({
  alignItems: "center",
  display: "flex",
  gap: "10px",
});

const HeaderLogo = styled(CitadelLogo)({
  // By default the color for the SVG logo is CITADEL_BRAND_ROYAL, so we override to be white.
  ["path"]: {
    fill: Colors.CITADEL_BRAND_WHITE,
  },
});

const HeaderBarTitle = styled.div([FontCSS.BRAND, { userSelect: "none" }]);
const HeaderBarEnvironmentTarget = styled(HeaderBarTitle)({
  backgroundColor: "rgba(255, 255, 255, 0.2)",
  borderRadius: "3px",
  fontSize: "18px",
  padding: "4px 8px",
  lineHeight: "20px",
});

const AvatarWrapper = styled.div({
  alignItems: "center",
  display: "flex",
});

export type HeaderBarProps = PropsWithChildren<{
  className?: string;
}>;

/**
 * Component rendering a Citadel-branded header bar for a CDX application.
 *
 * `children` of this component are rendered in the header bar.
 */
export const HeaderBar = ({ className, children }: HeaderBarProps): JSX.Element => {
  const environment = useMemo(() => getEnvironment(), []);

  const [username, setUsername] = useState("");
  useEffect(() => {
    getUsername().then((u) => setUsername(u));
  }, []);

  return (
    <HeaderBarWrapper className={classNames(className, Classes.ELEVATION_2)}>
      <HeaderBarBody target={environment.target}>
        <Logo>
          <HeaderLogo height={20} />
          <HeaderBarTitle>CITADEL</HeaderBarTitle>
          {environment.target !== "prod" && (
            <HeaderBarEnvironmentTarget>{environment.target.toLocaleUpperCase()}</HeaderBarEnvironmentTarget>
          )}
        </Logo>
        {children}
        <AvatarWrapper>
          <AvatarConnected username={username} size={40} />
        </AvatarWrapper>
      </HeaderBarBody>
    </HeaderBarWrapper>
  );
};
