import { TextArea } from "@blueprintjs/core";
import styled from "@emotion/styled";

const StyledTextArea = styled(TextArea)(({ width }: Pick<EditRegionTextareaProps, "width">) => ({
  position: "absolute",
  top: "100%",
  marginTop: "20px",
  minWidth: "200px",
  width: width + "px",
}));

interface EditRegionTextareaProps {
  index?: number;
  text: string;
  width: number;
  setText: (text: string) => void;
  onTextUpdate?: (text: string, index?: number) => void;
}

export function EditRegionTextarea({ index, text, width, setText, onTextUpdate }: EditRegionTextareaProps) {
  return (
    <StyledTextArea
      autoFocus={true}
      value={text}
      onChange={(e) => setText(e.target.value || "")}
      onBlur={() => onTextUpdate?.(text, index)}
      width={width}
    />
  );
}
