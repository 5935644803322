import { useEffect, useState } from "react";
import type { ReactNode, ComponentType } from "react";
import { AuthenticationSessionContext } from "../react/context/AuthenticationSessionContext";
import { PingFederateSession } from "../ping-federate/PingFederateSession";
import { getSessionData } from "../ping-federate/localStorage";
import { getAuthenticationSession } from "./getAuthenticationSession";

const DefaultLoadingComponent = () => <span>Authenticating...</span>;

/**
 * Props of {@link AuthenticationProvider}
 *
 * @public
 */
export interface AuthenticationProviderProps {
  /**
   * Use this to change the component which is rendered while we're waiting for authentication.
   */
  LoadingComponent?: ComponentType<{}>;

  /**
   * Children where this authentication context should be enabled.
   */
  children: ReactNode;
}

const enum AuthenticationStatus {
  Authenticating,
  Authenticated,
  Error,
}

/**
 * Wrap your app within this component to activate the browser authentication. This will delay full app rendering until
 * we have a token.
 *
 * @param props - See {@link AuthenticationProviderProps}
 *
 * @public
 */
export function AuthenticationProvider({
  children,
  LoadingComponent = DefaultLoadingComponent,
}: AuthenticationProviderProps) {
  // Read the initial session state syncronously from local storage. We'll then
  // check this with the results we get back from the PingFederateSession.
  const [status, setStatus] = useState<AuthenticationStatus>(
    getSessionData() === null ? AuthenticationStatus.Authenticating : AuthenticationStatus.Authenticated
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    PingFederateSession.activeSession.waitForInitialized().then(
      (state) => {
        if (state.redirecting) {
          setStatus(AuthenticationStatus.Authenticating);
          setErrorMessage(undefined);
          return;
        }

        if (state.errorMessage) {
          setStatus(AuthenticationStatus.Error);
          setErrorMessage(state.errorMessage);
          return;
        }

        setStatus(AuthenticationStatus.Authenticated);
        setErrorMessage(undefined);
      },
      (error) => {
        setStatus(AuthenticationStatus.Error);
        setErrorMessage(error instanceof Error ? error.message : `${error}`);
      }
    );
  }, []);

  switch (status) {
    case AuthenticationStatus.Authenticating:
      return <LoadingComponent />;

    case AuthenticationStatus.Error:
      return <span>There has been an authentication error: {errorMessage ?? "no error message provided"}</span>;
  }

  return (
    <AuthenticationSessionContext.Provider value={getAuthenticationSession()}>
      {children}
    </AuthenticationSessionContext.Provider>
  );
}
