import type { FetchResult } from "@apollo/client";
import { Observable } from "@apollo/client";
import type { ErrorResponse } from "@apollo/client/link/error";
import { onError as apolloOnError } from "@apollo/client/link/error";
// TODO[CDX-1399]: GA Security Retirement - https://wiki/x/v0QoIg
// eslint-disable-next-line no-restricted-imports
import { verifyAndResetIfNeeded } from "@citadel/cdx-provider-oauth2";
import { isLikelyOAuth2TokenError } from "../utils/isLikelyOauth2TokenError";
import { isUsingOAuth2 } from "./createAuthLink";

type ErrorLinkOptions = {
  onError: (error: string) => any;
};

// Check if the error is due to expired tokens.
// Retry operation once by forcing a token refresh and resuming
function createOAuth2ErrorHandler(
  operation: ErrorResponse["operation"],
  forward: ErrorResponse["forward"]
): Observable<FetchResult> | undefined {
  return new Observable((observer) => {
    (async function verifyAndRefresh() {
      await verifyAndResetIfNeeded();

      forward(operation).subscribe({
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer),
      });
    })();
  });
}

export function createErrorLink({ onError }: ErrorLinkOptions) {
  return apolloOnError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message }: Error) => onError(`[GraphQL error]: ${message}`));

      if (isUsingOAuth2()) {
        for (const gqlError of graphQLErrors) {
          if (isLikelyOAuth2TokenError(gqlError)) {
            return createOAuth2ErrorHandler(operation, forward);
          }
        }
      }
    }

    if (networkError) {
      onError(`[Network error]: ${networkError}`);
    }
  });
}
