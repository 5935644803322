import { useState } from "react";
import { isArgsShallowEqual } from "../utils";
import { useDirectRef } from "./useDirectRef";
import { useDeepEffect } from "./useDeepEffect";

export enum EffectStatus {
  Pending,
  Active,
  Error,
  Done,
}

export type EffectResult<TData> =
  | {
      status: EffectStatus.Pending;
      active: true;
      error?: never;
      data?: never;
    }
  | {
      status: EffectStatus.Active;
      active: true;
      error?: never;
      data?: never;
    }
  | {
      status: EffectStatus.Error;
      active: false;
      error: Error;
      data?: never;
    }
  | {
      status: EffectStatus.Done;
      active: false;
      error?: never;
      data: TData;
    };

type AsyncEffectFn<TArgs extends any[], TData> = (...args: TArgs) => Promise<TData>;

const PENDING_STATE: EffectResult<any> = { status: EffectStatus.Pending, active: true };
const ACTIVE_STATE: EffectResult<any> = { status: EffectStatus.Active, active: true };

export function useAsyncEffect<TArgs extends any[], TData>(
  effect: AsyncEffectFn<TArgs, TData>,
  deps: TArgs,
  isEqual: (prev: TArgs, next: TArgs) => boolean = isArgsShallowEqual
): EffectResult<TData> {
  const effectRef = useDirectRef(effect);
  const lastDeps = useDirectRef(deps);
  const [result, setResult] = useState<EffectResult<TData>>(PENDING_STATE);

  useDeepEffect<TArgs>(
    // TODO: CTOTECH-2046 Fix this the next time this file is edited.
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (...deps: TArgs) => {
      // TODO: CTOTECH-2046 Fix this the next time this file is edited.
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { current: effect } = effectRef;
      lastDeps.current = deps;
      setResult(ACTIVE_STATE);
      effect(...deps).then(
        (data) => setResult({ status: EffectStatus.Done, active: false, data }),
        (error) => setResult({ status: EffectStatus.Error, active: false, error })
      );
    },
    deps,
    isEqual
  );

  if (!isEqual(deps, lastDeps.current)) {
    return PENDING_STATE;
  }

  return result;
}
