import { stitchSchemas } from "@graphql-tools/stitch";
import type { AsyncExecutor, SubschemaConfig } from "@graphql-tools/delegate";
import { RenameRootFields, RenameTypes, introspectSchema } from "@graphql-tools/wrap";
import type { GraphQLSchema } from "graphql";
import { buildClientSchema, buildSchema, graphqlSync, getIntrospectionQuery, printSchema } from "graphql";
import type {
  CdxServiceDependencyGraphQLExecutionConfig,
  CdxServiceDependencyGraphQLResolution,
} from "@citadel/cdx-config";
import { createExecutor, createSubscriber } from "./handlers";
import type { GraphQLStitchingOptions, GraphQLSubschemaBuildResult, GraphQLSchemaBuildResult } from "./types";
import { packageLogger } from "./logger";
import { stripIntrospectionDefaultValues } from "./utils";

const logger = packageLogger.createFileLogger(__filename);

const compact = <T>(array: (T | null | undefined)[]): T[] => {
  return array.filter((el) => el !== null && el !== undefined) as T[];
};

const getGraphQLService = (
  target: string,
  { targets, ...serviceOpts }: CdxServiceDependencyGraphQLResolution
): CdxServiceDependencyGraphQLExecutionConfig | null => {
  if (!targets[target]) {
    return null;
  }
  return {
    ...targets[target]!,
    ...serviceOpts,
    target,
  };
};

/**
 * we apply a strip to any defaulting as it can cause versioning problems unintentionally
 * this is because graphql-tools injects default values into the actual requests rather than treating them as
 * documentation and an enforced default on the api side
 * we will explore removing this and adding remote checks as well as env-specific introspection
 * down the road, but this adjustment provides better stability in the short term
 */
const stripDefaults = (schema: GraphQLSchema | null): GraphQLSchema | null => {
  if (schema) {
    try {
      const { data: introspection } = graphqlSync(schema, getIntrospectionQuery());
      const strippedIntrospection = stripIntrospectionDefaultValues(introspection);
      return buildClientSchema(strippedIntrospection) || schema;
    } catch {
      // ignore error as this is a preventative measure, go ahead and return the original schema
    }
  }
  return schema;
};

/**
 * This is to resolve a local asset or remote to GraphQLSchema
 * Note the differences (object check) based on this
 * https://www.apollographql.com/blog/three-ways-to-represent-your-graphql-schema-a41f4175100d/
 */
const resolveIntrospectSchema = async (
  { introspection, ...service }: CdxServiceDependencyGraphQLExecutionConfig,
  executor: AsyncExecutor
): Promise<[GraphQLSchema | null, Error | null]> => {
  try {
    if (introspection) {
      const schema = typeof introspection === "object" ? buildClientSchema(introspection) : buildSchema(introspection);
      return [stripDefaults(schema), null];
    }
    const remoteSchema = await introspectSchema(executor);
    return [remoteSchema, null];
  } catch (e: unknown) {
    logger.error(`Unable to retrieve service introspection: ${service.name} at ${service.httpUrlOrPath}`, e);
    return [null, e as Error];
  }
};

const createPrefixer = (inputPrefix?: string): ((name: string) => string) => {
  if (!inputPrefix) {
    return (name: string) => name;
  }
  const prefix = `${inputPrefix}${inputPrefix.endsWith("_") ? "" : "_"}`;
  return (name: string) => {
    if (name.match(new RegExp(`^_?${prefix}`))) {
      return name;
    }
    return name.replace(/^_?/, (_) => `${_}${prefix}`);
  };
};

// specific cases of a service used often where we don't yet leverage subscriptions
// skip for this case, but in others go ahead and log to notify devs that subscriptions are available
// as it's easy to miss out on using them or to misconfigure your local and get confused why subscriptions
// are not working
const servicesToIgnoreSubscriptionLogsFor = new Set(["dataframework", "app-state"]);

/**
 * Builds a service subschema
 * If unable to retrieve introspection, we filter this service out and will reattempt on a backoff
 */
export const createGraphQLSubschema = async (
  service: CdxServiceDependencyGraphQLExecutionConfig,
  opts: Omit<GraphQLStitchingOptions, "subschemaConfigs">
): Promise<GraphQLSubschemaBuildResult> => {
  const executor = createExecutor(service, opts);
  const [schema, error] = await resolveIntrospectSchema(service, executor);
  // exit early if we cannot create the schema - required for graphql to resolve it
  // TODO: add retry logic here as beneficial, ideally we wouldn't need to retrieve
  if (!schema) {
    return { error, service };
  }
  const printed = printSchema(schema);
  const isLocal = service.target === "local";
  if (
    printed.includes("type Subscription ") &&
    !service.wsUrlOrPath &&
    isLocal &&
    !servicesToIgnoreSubscriptionLogsFor.has(service.name)
  ) {
    logger.warn(
      `The service ${service.name} supports subscriptions, but no wsUrlOrPath was specified. This is likely a mistake.`
    );
  }

  const addPrefix = createPrefixer(service.prefix);
  return {
    service,
    subschema: {
      batch: true,
      executor,
      schema,
      subscriber: createSubscriber(service, opts),
      transforms: [
        // new RemovePrivateElementsTransform(),
        new RenameTypes(addPrefix, { renameBuiltins: false, renameScalars: false }) as any,
        new RenameRootFields((_operationName, fieldName, _fieldConfig) => addPrefix(fieldName)) as any,
      ],
    },
  };
};

export const createStitchedServicesSchema = async (
  services: CdxServiceDependencyGraphQLExecutionConfig[],
  { subschemaConfigs = [], ...opts }: GraphQLStitchingOptions = {}
): Promise<GraphQLSchemaBuildResult> => {
  const serviceSubschemas = await Promise.all(services.map((service) => createGraphQLSubschema(service, opts)));
  const serviceMap: { [key: string]: SubschemaConfig } = {};
  services.forEach((s, idx) => {
    if (serviceSubschemas[idx].subschema) {
      serviceMap[s.name] = serviceSubschemas[idx].subschema!;
    }
  });
  subschemaConfigs.forEach(({ name, config }) => (serviceMap[name] = config));
  return {
    schema: stitchSchemas({ subschemas: Object.values(serviceMap) }),
    subschemas: serviceSubschemas,
  };
};

export const selectServicesForTarget = (
  target: string,
  serviceOpts: CdxServiceDependencyGraphQLResolution[]
): CdxServiceDependencyGraphQLExecutionConfig[] => {
  return compact(serviceOpts.map((serviceOpt) => getGraphQLService(target, serviceOpt)));
};

export const createStitchedServicesSchemaForTarget = async (
  target: string,
  serviceOpts: CdxServiceDependencyGraphQLResolution[],
  opts?: GraphQLStitchingOptions
): Promise<GraphQLSchemaBuildResult> => {
  const services = selectServicesForTarget(target, serviceOpts);
  return createStitchedServicesSchema(services, opts);
};
