import type { AdapterStep } from "./dataAdapterBase";
import { DataAdapterBase } from "./dataAdapterBase";

// Skynet API is used by the Email Data Adapter.
// https://skynetapi.citadelgroup.com/docs/#post-/messaging/email

export class EmailAdapter extends DataAdapterBase {
  public static service: string = "Email";
  protected operations = [
    { description: "Create", method: this.create },
    { description: "Publish", method: this.publish },
  ];

  create(): AdapterStep {
    if (!this.config.Email) {
      return {
        timestamp: new Date(),
        success: false,
      };
    }

    return {
      timestamp: new Date(),
      success: true,
      fields: {
        body: this.config.Email.body(this.config.Email, this.publishableReport),
      },
    };
  }

  async publish(previousOperationValue: AdapterStep | null): Promise<AdapterStep> {
    if (
      !this.config.Email ||
      previousOperationValue?.fields?.body === null ||
      previousOperationValue?.fields?.body === undefined
    ) {
      return {
        timestamp: new Date(),
        success: false,
        description: "Email was incorrectly configured for reporting issues. Please contact the development team.",
      };
    }

    try {
      const { Email: config } = this.config;
      const response = await fetch("https://skynetapi.citadelgroup.com/messaging/email", {
        credentials: "include",
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          toUserNames: config.toUserNames || [],
          toGroupNames: config.toGroupNames || [],
          ccUserNames: config.ccUserNames || [],
          ccGroupNames: config.ccGroupNames || [],
          bccUserNames: config.bccUserNames || [],
          bccGroupNames: config.bccGroupNames || [],
          subject: this.config.Email.subject
            ? this.config.Email.subject(this.config.Email, this.publishableReport)
            : `${this.publishableReport.user || config.author} Error Report`,
          body: previousOperationValue.fields.body,
          htmlBody: true,
          sender: config.author,
        }),
      });
      const success = response.status === 204;
      if (success) {
        return {
          timestamp: new Date(),
          success,
        };
      }

      const json = await response.json();
      const description = success ? undefined : json?.responseStatus?.message;
      return {
        timestamp: new Date(),
        success,
        description,
      };
    } catch (e: any) {
      return {
        timestamp: new Date(),
        success: false,
        description: "Email was unsuccessful, an error occured contacting the Skynet API.",
        error: e,
      };
    }
  }
}
