// TODO[CDX-1399]: Retire cdx-lib-auth-api
// https://jira.citadelgroup.com/browse/CDX-1476
import type { AsyncExecutor, ExecutionParams, Subscriber } from "@graphql-tools/delegate";
import { observableToAsyncIterable } from "@graphql-tools/utils";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";
import type { GraphQLError } from "graphql";
import { print } from "graphql";
import { createClient as createGraphqlWsClient } from "graphql-ws";
import { throttle } from "lodash";
import { AUTHENTICATE_HEADER_KEY, GA_SESSION_HEADER_KEY, GA_TOKEN_HEADER_KEY } from "@citadel/cdx-lib-auth";
import type { CdxServiceDependencyGraphQLExecutionConfig } from "@citadel/cdx-config";
import { packageLogger } from "./logger";
import type { GraphQLHttpHandlerOptions, GraphQLHttpHandlerRequestOptions, GraphQLWsHandlerOptions } from "./types";

const logger = packageLogger.createFileLogger(__filename);

declare const require: (str: string) => any;

const agent = (() => {
  try {
    const https = require("https");
    return new https.Agent({ rejectUnauthorized: false });
  } catch (e) {
    return undefined;
  }
})();

export const DEFAULT_GRAPHQL_EXECUTOR_AXIOS_HEADERS: AxiosRequestConfig["headers"] = {
  "Content-Type": "application/json",
};

const RECOMMENDED_CORS_HEADERS = [
  "'Access-Control-Allow-Origin': 'citadelgroup.com'",
  "'Access-Control-Allow-Methods': '*'",
  "'Access-Control-Allow-Headers': 'authorization, ...others'",
  "'Access-Control-Allow-Credentials': true",
];

const helpfulAxiosPost = async <T = any>(...args: Parameters<typeof axios.post>): Promise<AxiosResponse<T>> => {
  const [url, body, config = {}, ...opts] = args;
  // first we try our standard request
  // it may fail due to the options request, in this case we try to
  // helpfully direct the devs at to what server config they need to change
  try {
    const res = await axios.post(url, body, config, ...opts);
    return res;
  } catch (e: any) {
    if (typeof e.response !== "undefined") {
      throw e;
    }
  }

  try {
    const { headers = {}, ...baseConfig } = config;
    const { Authorization: _, ...remainingHeaders } = headers;
    const res = await axios.post(url, body, { headers: remainingHeaders, ...baseConfig }, ...opts);
    logger.error(
      [
        "Your initial request has failed. We sent a secondard request that omitted",
        "the Authorization (authenticate jwt)",
        "If you do not need this, then for the given service in your cdx-services-experimental-config.json,",
        "set omitAuthorization to true. If you do need this headers, it is likely",
        "your server does not have the cors policy set correctly.",
        "Please verify you have some (or all) of the following on your service:\n",
        ...RECOMMENDED_CORS_HEADERS.map((el) => `${el}\n`),
      ].join(" ")
    );
    return res;
  } catch (e: any) {
    if (typeof e.response !== "undefined") {
      throw e;
    }

    logger.error(
      [
        `An unknown error occurred. Due to browser restrictions we cannot provide more detail.`,
        `We often see the case of your server being down or not configured properly with CORS.`,
        `We recommend the following CORS configuration for your service:\n`,
        ...RECOMMENDED_CORS_HEADERS.join("\n"),
      ].join(" ")
    );
    throw e;
  }
};

export const createExecutor = (
  service: CdxServiceDependencyGraphQLExecutionConfig,
  opts: GraphQLHttpHandlerOptions
): AsyncExecutor => {
  const getAxiosRequestConfig = async (
    requestOpts: Omit<GraphQLHttpHandlerRequestOptions, "service">,
    params: Omit<ExecutionParams, "document" | "variables">
  ): Promise<AxiosRequestConfig> => {
    // base opts
    let baseOpts: AxiosRequestConfig = {};
    if (opts.httpRequestConfig) {
      if (typeof opts.httpRequestConfig === "function") {
        baseOpts = await opts.httpRequestConfig({ service, ...requestOpts });
      } else {
        baseOpts = opts.httpRequestConfig;
      }
    }

    // agent assignment for tls
    const agentOpt: AxiosRequestConfig = service.httpUrlOrPath.startsWith("https") ? { httpsAgent: agent } : {};

    // including headers can create CORS issues if not expected
    // we allow services to opt in and out of these headers to support current service configuration
    const updatedHeaders = { ...(params?.context?.headers || {}) };
    const headerKeys = [AUTHENTICATE_HEADER_KEY, GA_TOKEN_HEADER_KEY, GA_SESSION_HEADER_KEY];
    const shouldRemoveHeaderKey = [service.omitAuthorization, !service.requireXCigToken, !service.requireXGaSession];
    headerKeys.forEach((k, idx) => {
      if (shouldRemoveHeaderKey[idx]) {
        delete updatedHeaders[k];
        delete updatedHeaders[k.toLowerCase()];
      }
    });

    return {
      ...agentOpt,
      ...baseOpts,
      headers: {
        ...updatedHeaders,
        ...DEFAULT_GRAPHQL_EXECUTOR_AXIOS_HEADERS,
      },
      withCredentials: true,
    };
  };
  return async (params: ExecutionParams) => {
    const { document, variables } = params;
    const query = print(document as any);
    const axiosRequestConfig = await getAxiosRequestConfig({ document, variables }, params);
    const result = await helpfulAxiosPost(service.httpUrlOrPath, { query, variables }, axiosRequestConfig);
    return result.data;
  };
};

const getOrigin = (): string => {
  // Ignoring `process` allows this package to be type-checked without a dependency on `@types/node`.
  // @ts-ignore
  if (process.env?.CIG_ORIGIN) {
    // @ts-ignore
    return process.env.CIG_ORIGIN;
  }
  // @ts-ignore
  if (typeof window !== "undefined" && window?.location?.origin) {
    // @ts-ignore
    return window.location.origin;
  }

  return "http://localhost:50000";
};

/**
 * We just leverage axios tooling here
 */
const getSubscriptionUrl = (urlOrPath: string): string => {
  const httpified = urlOrPath.replace("wss://", "https://").replace("ws://", "http://");
  const httpUri = axios.getUri({ method: "GET", url: httpified });
  const isFullUri = httpUri.startsWith("http");
  const fullUri = `${isFullUri ? "" : getOrigin()}${httpUri}`;

  return fullUri.replace("https://", "wss://").replace("http://", "ws://");
};

declare const window: unknown;

export const createSubscriber = (
  service: CdxServiceDependencyGraphQLExecutionConfig,
  opts: GraphQLWsHandlerOptions
): Subscriber | undefined => {
  if (typeof window === "undefined") {
    // This is invoked in a server-side context for codegen.
    // Our subscription clients check whether a WebSocket implementation exists and error
    // if not found.
    return undefined;
  }

  return createGraphqlWsSubscriber(service, opts);
};

export const createGraphqlWsSubscriber = (
  service: CdxServiceDependencyGraphQLExecutionConfig,
  opts: GraphQLWsHandlerOptions
): Subscriber | undefined => {
  if (!service.wsUrlOrPath) {
    return;
  }
  const url = getSubscriptionUrl(service.wsUrlOrPath);
  const subscriptionClient = createGraphqlWsClient({ url, ...(opts.wsClientOptions || {}) });
  return async ({ document, variables }: ExecutionParams) =>
    observableToAsyncIterable({
      subscribe: (observer) => {
        let bufferedData: any = null;
        const sendNext = throttle(
          (data: any) => {
            bufferedData = null;
            observer.next && observer.next(data);
          },
          opts.wsBufferThrottle,
          { leading: false, trailing: true }
        );
        const next = (data: any) => {
          if (opts.wsBuffer && opts.wsBufferThrottle) {
            bufferedData = opts.wsBuffer(data, bufferedData) ?? null;
            sendNext(bufferedData);
          } else {
            observer.next && observer.next(data);
          }
        };
        return {
          unsubscribe: subscriptionClient.subscribe(
            {
              query: print(document as any),
              variables,
            },
            {
              next,
              error: (err: any) => {
                if (!observer.error) {
                  return;
                } else if (err instanceof Error) {
                  observer.error(err);
                } else if (!Array.isArray(err)) {
                  observer.error(new Error(`Socket closed with event ${err.code}`));
                } else {
                  observer.error(new Error(err.map(({ message }: GraphQLError) => message).join(", ")));
                }
              },
              complete: () => observer.complete && observer.complete(),
            }
          ),
        };
      },
    });
};
