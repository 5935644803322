import type { AssessmentStoreState } from "../../types/stores/assessment-store";

export const getAssessment = (state: AssessmentStoreState) => state?.assessment;
export const getColumns = (state: AssessmentStoreState) => state?.assessment?.columns || [];
export const getGroups = (state: AssessmentStoreState) => state?.assessment?.groups || [];
export const getAssessmentGroupLookup = (state: AssessmentStoreState) => state?.assessmentGroupLookup;
export const getSelectedCell = (state: AssessmentStoreState) => state?.selectedCell;
export const getEditingCell = (state: AssessmentStoreState) => state?.editingCell;
export const getCellReference = (state: AssessmentStoreState) => state?.cellReference;
export const getReferencedCells = (state: AssessmentStoreState) => state?.referencedCells;
export const getPasteableRange = (state: AssessmentStoreState) => state?.pasteableRange;
