import type { ReactNode } from "react";
import classes from "./info-section.less";

export type InfoSubsectionData = { subheader: string; text: string; examples?: string[] };

export type InfoSubsectionProps = {
  children: ReactNode;
  subheader: string;
  examples?: string[];
};

export const InfoSubsection = ({ subheader, examples = [], children }: InfoSubsectionProps) => {
  return (
    <div className={classes.infoSubsection}>
      <div className={classes.infoSubsectionHeader}>{subheader}</div>
      <div>{children}</div>
      {!!examples.length && (
        <ul className={classes.infoSubsectionExamples}>
          {examples.map((example) => (
            <li key={example}>{example}</li>
          ))}
        </ul>
      )}
    </div>
  );
};
