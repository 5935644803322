import { AUTHENTICATE_HEADER_KEY, GA_SESSION_HEADER_KEY, GA_TOKEN_HEADER_KEY } from "./constants";

/**
 * @deprecated {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @public
 */
export interface AuthHeaderOpts {
  authenticateJwt?: string | null;
  gaSessionJwt?: string | null;
  gaToken?: string | null;
}

/**
 * @deprecated Use "getAuthHeaders()" from "\@citadel/cdx-auth-browser".
 * {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @public
 */
export const createAuthorizationHeader = (authenticateJwt?: string | null) => {
  if (!authenticateJwt) {
    return {};
  }
  return { [AUTHENTICATE_HEADER_KEY]: `Bearer ${authenticateJwt}` };
};

/**
 * @deprecated Use "getAuthHeaders()" from "\@citadel/cdx-auth-browser".
 * {@link https://jira.citadelgroup.com/browse/CDX-1399 | CDX-1399}
 * @see {@link https://wiki/x/5iwVHg | GA Security Decom}
 * @see {@link https://wiki/x/v0QoIg | CDX - GA Security Decom}
 *
 * @public
 */
export const createAuthHeaders = ({ authenticateJwt, gaSessionJwt, gaToken }: AuthHeaderOpts) => {
  const gaHeaders = Object.fromEntries(
    [
      [GA_TOKEN_HEADER_KEY, gaToken],
      [GA_SESSION_HEADER_KEY, gaSessionJwt],
    ].filter(([_k, v]) => !!v)
  );
  return {
    ...createAuthorizationHeader(authenticateJwt),
    ...gaHeaders,
  };
};
