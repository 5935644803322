export const assessmentActions = {
  SET_ASSESSMENT: "SET_ASSESSMENT",
  SET_SELECTED_CELL: "SET_SELECTED_CELL",
  MOVE_CELL_SELECTION: "MOVE_CELL_SELECTION",
  SET_EDITING_CELL: "SET_EDITING_CELL",
  SET_COPY_CELL: "SET_COPY_CELL",
  PASTE_CELL: "PASTE_CELL",
  CLEAR_CELLS: "CLEAR_CELLS",
  SET_CELL_REFERENCE: "SET_CELL_REFERENCE",
  SET_REFERENCED_CELLS: "SET_REFERENCED_CELLS",
  UPDATE_VALUES: "UPDATE_VALUES",
  QUEUE_UPDATE_BATCH: "QUEUE_UPDATE_BATCH",
  CLEAR_UPDATE_BATCH: "CLEAR_UPDATE_BATCH",
  SET_UPDATE_BATCH_ACTIVE: "SET_UPDATE_BATCH_ACTIVE",
};
