import type { ApolloError } from "@apollo/client/errors";
import { isEqual } from "lodash";
import type { Assessment } from "../../types/assessment";
import type { GlobalStoreAction, GlobalStoreState } from "../../types/stores/global-store";
import { globalActions } from "./global-store";

export const stateReducer = (state: GlobalStoreState, action: GlobalStoreAction): GlobalStoreState => {
  switch (action.type) {
    case globalActions.SET_IS_DISCLAIMER_OPEN:
      if (isEqual(state.isDisclaimerOpen, action.payload)) {
        break;
      }
      return { ...state, isDisclaimerOpen: action.payload as boolean };

    case globalActions.SET_IS_DISCLAIMER_ACKNOWLEDGED:
      if (isEqual(state.isDisclaimerAcknowledged, action.payload)) {
        break;
      }
      return { ...state, isDisclaimerAcknowledged: action.payload as boolean };

    case globalActions.SET_TUTORIAL_ASSESSMENTS:
      if (isEqual(state.tutorialAssessments, action.payload)) {
        break;
      }
      return { ...state, tutorialAssessments: action.payload as Assessment[] };

    case globalActions.SET_ASSESSMENT:
      if (isEqual(state.assessment, action.payload)) {
        break;
      }
      return { ...state, assessment: action.payload as Assessment };

    case globalActions.SET_IS_SUBMISSION_DIALOG_OPEN:
      if (isEqual(state.isSubmissionDialogOpen, action.payload)) {
        break;
      }
      return { ...state, isSubmissionDialogOpen: action.payload as boolean };

    case globalActions.SET_IS_SAVING:
      if (isEqual(state.isSaving, action.payload)) {
        break;
      }
      return { ...state, isSaving: action.payload as boolean };

    case globalActions.SET_SAVE_ERROR:
      if (isEqual(state.saveError, action.payload)) {
        break;
      }
      return { ...state, saveError: action.payload as ApolloError };

    case globalActions.SET_IS_START_ASSESSMENT_DIALOG_OPEN:
      if (isEqual(state.isStartAssessmentDialogOpen, action.payload)) {
        break;
      }
      return { ...state, isStartAssessmentDialogOpen: action.payload as boolean };

    default:
      break;
  }

  return state;
};
