import type { InMemoryCacheConfig } from "@apollo/client";
import { gql, InMemoryCache } from "@apollo/client";
import { cacheMergeTypePolicy } from "./cacheMerge";

const NEW_LINE = `
`;

const addPadding = (value: string, count: number) => `${new Array(count).fill("  ").join("")}${value}`;

const buildKey = (key: string, data: any, levels: number): string => {
  // determine if we are a node or a leaf
  if (!data || typeof data !== "object") {
    return addPadding(key, levels);
  }
  if (Array.isArray(data) && !data.find((el) => typeof el === "object")) {
    return addPadding(key, levels);
  }

  const base = Array.isArray(data) ? data : [data];
  if (base.find((el) => Array.isArray(el))) {
    throw new Error("Nested arrays are not supported.");
  }
  let pairs = Object.entries(base.reduce((acc, v) => ({ ...acc, ...v }), {}));
  if (pairs.length === 0) {
    return addPadding(key, levels);
  }
  const entries: [string, any][] = pairs.map(([k, v]) => {
    if (!Array.isArray(v)) {
      return [k, v];
    }
    // TODO: CTOTECH-2046 Fix this the next time this file is edited.
    // eslint-disable-next-line @typescript-eslint/no-shadow
    let coalesced = v.reduce((acc, v) => {
      if (!acc || typeof acc !== "object") {
        return v;
      }
      if (Array.isArray(v)) {
        throw new Error("Nested arrays are not supported.");
      }
      if (typeof v !== "object") {
        return acc;
      }
      return { ...acc, ...v };
    }, null);
    return [k, coalesced];
  });

  return [
    addPadding(`${key} {`, levels),
    entries
      .sort((lhs, rhs) => lhs[0].localeCompare(rhs[0]))
      .map(([k, v]) => buildKey(k, v, levels + 1))
      .join(NEW_LINE),
    addPadding("}", levels),
  ].join(NEW_LINE);
};

export const buildQuery = (data: any = {}): string => {
  if (typeof data !== "object") {
    throw new Error("Initial data must be an object.");
  }
  if (Array.isArray(data)) {
    throw new Error("Initial data must be an object, not an array.");
  }
  const entries = Object.entries(data);
  return `query {${NEW_LINE}${entries
    .sort((lhs, rhs) => lhs[0].localeCompare(rhs[0]))
    .map(([k, v]) => buildKey(k, v, 1))
    .join(NEW_LINE)}${NEW_LINE}}`;
};

/**
 * Apollo 3 removes the ability to write data directly, so we need to
 * build a query here
 */
export const createDefaultCache = (initialData: any, config: InMemoryCacheConfig = {}): InMemoryCache => {
  const typePolicies = {
    ...(config.typePolicies || {}),
    ...cacheMergeTypePolicy.typePolicies,
  };
  const configWithTypePolicy: InMemoryCacheConfig = {
    ...config,
    typePolicies,
  };
  const cache = new InMemoryCache(configWithTypePolicy);
  if (initialData && Object.keys(initialData).length > 0) {
    const query = buildQuery(initialData);
    cache.writeQuery({
      query: gql`
        ${query}
      `,
      data: initialData,
    });
  }
  return cache;
};
