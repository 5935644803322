export const isScalar = <T>(value: T): value is Extract<T, string | number | bigint | boolean | symbol> => {
  switch (typeof value) {
    case "string":
    case "number":
    case "bigint":
    case "boolean":
    case "symbol":
      return true;
    default:
      return false;
  }
};
