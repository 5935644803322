import { Classes, Colors as BlueprintColors, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import styled from "@emotion/styled";
import type { SelectInputGroupProps } from "../select-input-group";
import { SelectInputGroup } from "../select-input-group";

const BlueprintOptionSelect = Select.ofType<Option>();

export type Option = {
  value: any;
  label: string;
  description?: string;
};

export type OptionSelectProps = {
  onChange: (option: Option) => void;
  options: Option[];
} & SelectInputGroupProps;

const Description = styled.div`
  .${Classes.ACTIVE} & {
    color: ${BlueprintColors.LIGHT_GRAY4};
  }

  color: ${BlueprintColors.GRAY2};
`;

/**
 * Component rendering a `select` for options.
 * @see https://blueprintjs.com/docs/#select/select-component
 */
export const OptionSelect = ({
  className,
  disabled,
  value,
  onChange,
  options,
  style,
  placeholder,
}: OptionSelectProps): JSX.Element => {
  const activeItem = options.find((option) => option.value === value);
  return (
    <BlueprintOptionSelect
      activeItem={activeItem}
      disabled={disabled}
      items={options}
      itemRenderer={(item, { handleClick, modifiers }) => {
        return (
          <MenuItem
            key={item.label}
            multiline
            text={
              item.description ? (
                <>
                  <b>{item.label}</b>
                  <Description>{item.description}</Description>
                </>
              ) : (
                item.label
              )
            }
            active={modifiers.active}
            onClick={handleClick}
          />
        );
      }}
      noResults={<MenuItem text="No options available." disabled />}
      onItemSelect={onChange}
      filterable={false}
      popoverProps={{ minimal: true }}
    >
      <SelectInputGroup
        disabled={disabled}
        className={className}
        style={style}
        value={activeItem?.label ?? ""}
        placeholder={placeholder}
      />
    </BlueprintOptionSelect>
  );
};
