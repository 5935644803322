import styled from "@emotion/styled";
import type { ReactElement } from "react";
import { EmployeePhoto } from "../employee-photo/employee-photo";

const EMPTY_ARRAY: never[] = [];

const PhotosWrapper = styled.div`
  position: relative;
  flex: 0 0 auto;
`;

const PhotoWrapper = styled.div`
  position: absolute;
`;

export interface EmployeePhotoStackProps {
  employeeIds: string[];
  size?: number;
  emphasizeFront?: boolean;
}

export const EmployeePhotoStack = ({
  employeeIds = EMPTY_ARRAY,
  size = 128,
  emphasizeFront = false,
}: EmployeePhotoStackProps): ReactElement => {
  return (
    <PhotosWrapper style={{ width: size + 5 * ((employeeIds.length || 1) - 1), height: size }}>
      {employeeIds?.map((empId, idx) => (
        <PhotoWrapper key={empId} style={{ left: idx * 5 }}>
          <EmployeePhoto
            employeeId={empId}
            size={emphasizeFront && idx < employeeIds.length - 1 ? (2 * size) / 3 : size}
            corners="round"
          />
        </PhotoWrapper>
      ))}
    </PhotosWrapper>
  );
};
