import type { PropsWithChildren } from "react";
import { useState } from "react";
import type { ButtonProps, CollapseProps } from "@blueprintjs/core";
import { Icon, Button, Collapse } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { Tooltip2 } from "@blueprintjs/popover2";

const CollapseButton = styled(Button)`
  width: 100%;
  border-radius: 0;

  display: flex;
  justify-content: flex-start;

  .bp3-button-text {
    flex: 1;
  }
`;

const CollapseContent = styled.div<{ showBorder?: boolean }>`
  .bp3-collapse > .bp3-collapse-body > * {
    background: #ffffff;
    padding: 8px;

    .bp3-dark & {
      background: #1f2931;
    }
  }

  ${({ showBorder }) => (showBorder ? "border: 1px solid white;" : "")}
`;

const RightItemWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

const PaddedIcon = styled(Icon)`
  padding: 0px 8px;
`;

function RightItem({ buttonProps = {}, info }: CollapsableProps) {
  if (!info && !buttonProps.rightIcon) {
    return null;
  }
  if (buttonProps.rightIcon) {
    if (typeof buttonProps.rightIcon === "string") {
      return <PaddedIcon icon={buttonProps.rightIcon} />;
    }
    return buttonProps.rightIcon;
  }

  return (
    <Tooltip2 content={info}>
      <PaddedIcon icon="info-sign" onClick={(e) => e.stopPropagation()} />
    </Tooltip2>
  );
}

export interface CollapsableProps {
  title: string | React.ReactNode;
  openByDefault?: boolean;

  buttonProps?: Omit<ButtonProps, "children" | "icon" | "onClick" | "text">;
  collapseProps?: Omit<CollapseProps, "children" | "isOpen">;
  info?: string | JSX.Element | undefined;

  showBorder?: boolean;
}

export const Collapsable = (props: PropsWithChildren<CollapsableProps>) => {
  const { title, children, openByDefault = true, info, buttonProps = {}, collapseProps = {}, showBorder } = props;
  const { rightIcon, ...applicableButtonProps } = buttonProps;
  const [isOpen, setIsOpen] = useState(openByDefault);

  return (
    <div>
      <CollapseButton
        onClick={() => setIsOpen(!isOpen)}
        icon={isOpen ? "caret-down" : "caret-right"}
        rightIcon={
          info || buttonProps.rightIcon ? (
            <RightItemWrapper>
              <RightItem {...props} />
            </RightItemWrapper>
          ) : undefined
        }
        {...applicableButtonProps}
      >
        {title}
      </CollapseButton>
      <CollapseContent showBorder={isOpen && showBorder}>
        <Collapse isOpen={isOpen} {...collapseProps}>
          <div>{children}</div>
        </Collapse>
      </CollapseContent>
    </div>
  );
};
