import { Colors } from "@blueprintjs/core";

// Magic constant: The size of a drawn box that is large enough to matter for display and annotations.
export const LARGE_ENOUGH_ANNOTATION_TO_CARE: number = 10;
// Magic constant: Size of the border for any annotation.
export const ANNOTATION_BORDER_SIZE: number = 5;
// Magic constant: Size of the font used for annotation visual marker.
export const ANNOTATION_MARKER_FONT_SIZE: number = 14;
// Magic constant: Width of annotation pre-scaled. TODO(@kbaxter1) – move to calculate this from DOM.
export const ANNOTATION_MARKER_PRE_SCALE_WIDTH: number = 20;
// Magic constant: Height of annotation pre-scaled. TODO(@kbaxter1) – move to calculate this from DOM.
export const ANNOTATION_MARKER_PRE_SCALE_HEIGHT: number = 27;

// Annotations follow an order for their colors.
const ANNOTATION_COLOR_ORDER = [Colors.INDIGO4, Colors.ROSE5, Colors.TURQUOISE5, Colors.VERMILION5];
const ANNOTATION_COLOR_COUNT = ANNOTATION_COLOR_ORDER.length;

/**
 * Given an index, return a color for an annotation. Wrap around the known values
 * to continue the rainbow.
 * @param {number} index position in array to use
 * @returns {string} color value.
 */
export const getAnnotationColor = (index: number) =>
  ANNOTATION_COLOR_ORDER[((index % ANNOTATION_COLOR_COUNT) + ANNOTATION_COLOR_COUNT) % ANNOTATION_COLOR_COUNT];
