import safeJsonStringify from "safe-json-stringify";
import type { LogLine } from "./Logger";

const errorToJson = <T>(e: Error | T): { message: string; stack?: string; originalError?: any } | T => {
  // convert Errors into standard objects so they can be serialised normally.
  // since JSON.stringify(new Error('foo')) returns '{}'
  // since safeJsonStringify.ensureProperties(new Error()) returns {} we have to do this first
  if (e instanceof Error) {
    return {
      ...e,
      message: e.message,
      stack: e.stack,
      originalError: errorToJson((e as any).originalError),
    };
  }
  return e;
};

const unwrapSingleton = (data: any[]) => (data.length > 1 ? data : data[0]);

/**
 * Performs a series of sanitization and normalization actions on a log entry in
 * order to prepare it for JSON serialization
 *
 * - Unwraps `Error` instances
 *   - Extract the message & stack
 *   - Extract the `originalError` property (if present on the error)
 * - Remove circular references from data
 * - Unwrap a single entry from an array to be that entry
 *
 * @public
 * @param logLine - the log entry to sanitize
 * @returns the a copy of the log entry with sanitization
 */
export const sanitizeLog = (logLine: LogLine): LogLine => {
  const rawData = logLine?.msg?.data;

  const data = rawData && unwrapSingleton(rawData.map(errorToJson));
  const sanitizedData = safeJsonStringify.ensureProperties(data) as any; // ensureProperties typings are wrong, thus the need for a cast
  const sanitizedInput: LogLine = {
    ...logLine,
    msg: {
      context: logLine?.msg?.context,
      data: sanitizedData,
    },
  };
  return sanitizedInput;
};

/**
 * {@link sanitizeLog|Sanitizes} a log entry, then serializes it to JSON
 *
 * @public
 * @param logLine - the log entry to serialize
 * @returns A JSON serialized string of the log entry
 */
export const serializeLog = (logLine: LogLine): string => JSON.stringify(sanitizeLog(logLine));
