const pluralRulesSingleton = new Intl.PluralRules("en-US");

/**
 * Uses the Intl.PluralRules native API to express pluralization for simple cases.
 *
 * @param count {number|undefined} number of items to express, defaults to 0.
 * @param singular {string} how to express a singular form for the count, eg 'item'
 * @param plural {string} how to express a plural form for the count, eg 'items'
 * @returns {string} count + plural expression
 */
export function pluralText(count: number | undefined = 0, singular: string, plural: string) {
  const pluralRule = pluralRulesSingleton.select(count);
  switch (pluralRule) {
    case "one":
      return `${count} ${singular}`;
    case "other":
      return `${count} ${plural}`;
    default:
      throw new Error("Unknown pluralRule for count " + count + ", " + pluralRule);
  }
}

/**
 * Uses the Intl.PluralRules native API to express pluralization for the singular or plural string alone.
 *
 * @param count {number|undefined} number of items to express, defaults to 0.
 * @param singular {string} how to express a singular form for the count, eg 'item'
 * @param plural {string} how to express a plural form for the count, eg 'items'
 * @returns {string} plural expression
 */
export function pluralSuffix(count: number | undefined = 0, singular: string, plural: string) {
  const pluralRule = pluralRulesSingleton.select(count);
  switch (pluralRule) {
    case "one":
      return singular;
    case "other":
      return plural;
    default:
      throw new Error("Unknown pluralRule for count " + count + ", " + pluralRule);
  }
}
