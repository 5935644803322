/**
 * File containing constants for use in styling components with Emotion.
 * @see https://emotion.sh/docs/styled
 */

import { Colors as BlueprintColors } from "@blueprintjs/core";
import type { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";

/**
 * Map from Brand color names to hex values.
 * @see https://intranet/citadel/who-we-are/marketing-resources/brand-center/
 */
export const Colors = Object.freeze({
  CITADEL_BRAND_ROYAL: "#002F6C",
  CITADEL_BRAND_SKY: "#00A3E0",
  CITADEL_BRAND_WHITE: "#FFFFFF",
  DARK_TEXT_MUTED: "#A7B6C2",
});

/**
 * Type representing a font.
 * TODO: (gmcdonal) add support for additional `font-` properties.
 */
export type Font = {
  /** Text color of the font. Corresponds to the `color` css attribute. */
  COLOR: string;
  /** Corresponds to the `font-family` css attribute. */
  FAMILY?: string;
  /** Corresponds to the `line-height` css attribute. */
  LINE_HEIGHT: string;
  /** Corresponds to the `font-size` css attribute. */
  SIZE: string;
  /** Corresponds to the `font-weight` css attribute. */
  WEIGHT: string;
};

export const DEFAULT_FONT_SIZE = "14px";
export const DEFAULT_LINE_HEIGHT = "18px";

/** Enum of all available fonts. */
export enum FONT {
  BRAND = "BRAND",
}

/** Map from `FONT` to attributes. */
export const Fonts = Object.freeze<{ [key in FONT]: Font }>({
  [FONT.BRAND]: {
    COLOR: Colors.CITADEL_BRAND_WHITE,
    FAMILY: "Chronicle Display",
    LINE_HEIGHT: "36px",
    SIZE: "24px",
    WEIGHT: "375",
  },
});

/**
 * Map from `FONT` to an Emotion style with attributes of that font.\
 * This map is intended to be used with Emotion's Composition feature to
 * incorporate fonts into component styles.
 *
 * @example
 * import { FontCSS } from "src/constants/styled";
 * const ComponentWithFont = styled.div(FontCSS.SUMMARY_VALUE);
 *
 * @example
 * import { FontCSS } from "src/constants/styled";
 * const ComponentWithFontAndOtherStyle = styled.div`
 *   background: aliceblue;
 *   border: 1px solid black;
 *   ${FontCSS.CARD_HEADER}
 * `;
 *
 * @example
 * // Must add the below line to use the `css` prop.
 * // Must also replace "＊" and "＠" in the below line with their standard character counterparts.
 * /＊ ＠jsxImportSource ＠emotion/react ＊/
 * import React from "react";
 * import { FontCSS } from "src/constants/styled";
 * const ReactNodeWithFont = <div css={FontCSS.BRAND} />;
 *
 * @see https://emotion.sh/docs/composition
 */
export const FontCSS = Object.fromEntries(
  Object.entries(Fonts).map(([key, font]) => [
    key,
    css({
      color: font.COLOR,
      lineHeight: font.LINE_HEIGHT,
      fontFamily: font.FAMILY,
      fontSize: font.SIZE,
      fontWeight: font.WEIGHT,
    }),
  ])
) as { [key in keyof typeof Fonts]: SerializedStyles };

/** Style for links (aka `a` tags). */
export const LinkCSS = css({
  color: BlueprintColors.BLUE2,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
});
