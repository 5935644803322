import type { Middleware } from "redux";
import type { EntryEventInput } from "../../_generated/graphql";
import { assessmentActions } from "./assessment-actions";

type updateEntryOptions = {
  variables: {
    entries: EntryEventInput[];
  };
};
type updateEntryFunction = (options: updateEntryOptions) => void;
let latestUpdateTimeout: number | undefined = undefined;

let updateBatch: EntryEventInput[] = [];

export const buildMiddleware =
  (updateEntry: updateEntryFunction): Middleware =>
  ({
    getState,
    /* dispatch, */
  }) =>
  (next) =>
  (action) => {
    let cellUpdate: EntryEventInput;
    let cellUpdateBatchActive: boolean;

    switch (action.type) {
      case assessmentActions.QUEUE_UPDATE_BATCH:
        // if updateBatche is active, then queue an update
        cellUpdateBatchActive = getState().cellUpdateBatchActive;
        if (cellUpdateBatchActive) {
          cellUpdate = action.payload as EntryEventInput;
          if (updateBatch?.includes(cellUpdate)) {
            break;
          }
          updateBatch = [...updateBatch, cellUpdate];

          if (latestUpdateTimeout !== undefined) {
            window.clearTimeout(latestUpdateTimeout);
          }

          latestUpdateTimeout = window.setTimeout(() => {
            updateEntry({
              variables: {
                entries: updateBatch,
              },
            });

            updateBatch = [];
          }, 100);
        }
        break;

      default:
        break;
    }

    return next(action);
  };
