import { Button, Intent } from "@blueprintjs/core";
import { globalActions, useGlobal } from "../../stores/global/global-store";

export const SubmitButton = () => {
  const { dispatch } = useGlobal();

  return (
    <Button
      intent={Intent.SUCCESS}
      onClick={() => {
        dispatch({ type: globalActions.SET_IS_SUBMISSION_DIALOG_OPEN, payload: true });
      }}
      text="Submit"
      icon="upload"
    />
  );
};
