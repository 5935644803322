import type { ApolloClientOptions, ApolloLink, InMemoryCacheConfig } from "@apollo/client";
import { ApolloClient } from "@apollo/client";
import type { CdxServiceDependencyGraphQLResolution } from "@citadel/cdx-config";
import type { GraphQLStitchingOptions } from "@citadel/cdx-lib-graphql-stitching";
import { getEnvironment } from "@citadel/cdx-environment";
// TODO[CDX-1399]: GA Security Retirement - https://wiki/x/v0QoIg
// eslint-disable-next-line no-restricted-imports
import { getCdxSessionCookies } from "@citadel/cdx-lib-auth-browser";
import { createDefaultLink } from "./links/createDefaultLink";
import { createGatewayLink } from "./links/createGatewayLink";
import type { TypedResolvers } from "./types";
import { createDefaultCache } from "./cache";

// shared options

export interface CreateApolloClientOptions {
  /** if omitted, adds our default error handling */
  overrideOptions?: Partial<ApolloClientOptions<any>>;
  /** if omitted, adds our default error handling */
  errorLink?: ApolloLink;
  /** initial data for local state */
  initialData?: any;
  /** resolvers for local execution */
  resolvers?: TypedResolvers;
  /** custom cache as-needed, defaults to in-memory */
  cacheConfig?: InMemoryCacheConfig;
  /** if omitted, adds our default auth handling  */
  authLink?: ApolloLink;
  /**
   * whether authenticate is required - this will affect authentication validation and redirects
   * default: true
   */
  requiresAuthenticate?: boolean;
  /** type definitions */
  typeDefs?: ApolloClientOptions<any>["typeDefs"];
  /** if provided, old behavior of showing a toast on every error will be done */
  showToast?: (client: ApolloClient<any>, message: string) => void;
}

// service-based gateway

export interface CreateApolloClientServiceGatewayOptions extends CreateApolloClientOptions {
  /** provided transport link must add the required http and health-check subscription values, wrapper method TODO */
  transportLink?: ApolloLink;
}

export const createApolloClient = (options: CreateApolloClientServiceGatewayOptions = {}): ApolloClient<any> => {
  const {
    authLink,
    errorLink,
    initialData = {},
    resolvers,
    overrideOptions = {},
    transportLink,
    showToast,
    typeDefs,
    cacheConfig,
  } = options;
  const cache = createDefaultCache(initialData, cacheConfig);

  let client: ApolloClient<any>;
  const handleError = (message: string) => {
    if (showToast) {
      showToast(client, message);
    }
  };
  const { requiresAuthenticate = true } = options;
  const link = createDefaultLink({ authLink, errorLink, transportLink, onError: handleError, requiresAuthenticate });
  client = new ApolloClient({
    cache,
    connectToDevTools: getEnvironment().isDev,
    link,
    resolvers,
    typeDefs,
    ...overrideOptions,
  });

  return client;
};

// locally-based gateway

export interface CreateApolloClientLocalGatewayOptions extends CreateApolloClientOptions {
  /**
   * Auto loaded from environment, you can override here (mostly for development purposes)
   * or if you need to explicitly define this.
   */
  target?: string;
  services: CdxServiceDependencyGraphQLResolution[];
  stitchingOpts?: GraphQLStitchingOptions;

  /** link to use for subscriptions. If not provided, subscriptions will be handled by our default schema link */
  subscriptionLink?: ApolloLink;
}

const resolveTarget = (target?: string): string => {
  if (target) {
    return target;
  }
  const config = getEnvironment();
  return config.cdxTarget || "local";
};

export const createApolloLocalGatewayClient = async (
  options: CreateApolloClientLocalGatewayOptions
): Promise<ApolloClient<any>> => {
  const {
    initialData = {},
    errorLink,
    resolvers,
    overrideOptions = {},
    typeDefs,
    cacheConfig,
    showToast,
    ...linkOpts
  } = options;
  const {
    authLink,
    subscriptionLink,
    target: inputTarget,
    requiresAuthenticate = true,
    services,
    stitchingOpts = {},
  } = linkOpts;
  const target = resolveTarget(inputTarget);
  const cache = createDefaultCache(initialData, cacheConfig);

  // initialize client
  let client: ApolloClient<any>;

  // error handling
  const handleError = (message: string) => {
    if (showToast) {
      showToast(client, message);
    }
  };
  const link = await createGatewayLink({
    authLink,
    errorLink,
    subscriptionLink,
    target,
    onError: handleError,
    requiresAuthenticate,
    services,
    wsClientOptions: {
      connectionParams: () => {
        const { authenticateJwt, gaSessionJwt } = getCdxSessionCookies();
        return { authenticateJwt, gaSessionJwt };
      },
    },
    ...stitchingOpts,
  });

  return new ApolloClient({
    cache,
    connectToDevTools: getEnvironment().isDev,
    link,
    resolvers,
    typeDefs,
    ...overrideOptions,
  });
};
